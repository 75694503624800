import { action, computed, makeObservable, observable } from "mobx";
import limaStore, { OPENEDFILETYPE_ENUM } from "./limaStore";
import { log } from "missionlog";
import { compareXcopy2XcopyByActaKey, insertDoc } from "../limaCommon/limaInsertDoc";
import { openDialogAsIframe_taskhistory } from "../limaCommon/limaDialogs";
// import { updateTaskRespOK } from "../api/schema/taskUpdateResponse";
import { urlDeleteTask } from "../api/apilinks";
import { urlActaInsertDoc } from "../api/apilinks";
import { TaskData, TASKSTATE } from "../types/Task";
import { httpDeleteDeleteAuth, HttpResponse } from "../api/httpAPI";
import limaLoadeStore, { LoaderItemEnd } from "./limaLoaderStore";
import { LimaDocTypeENUM } from "../types/docxData";
import {
  User2Task_withActaXcopyParasCommentsTasksMessagesAttachements,
  User2Task_withActaXcopyParasCommentsTasksMessagesAttachements_getNull,
} from "../types/User2Task_withActaXcopy";
import { limaLogTag } from "../limaCommon/limaLog";
import { User_getNull } from "../types/User";
import { getActaXcopyFile, storeToXcopy } from "../limaCommon/limaXCopyFile";
import {
  Task_withActaXcopy,
  convert_Task_withActaXcopy2Task_withActaXcopyParasCommentsTasksMessagesAttachements,
  Task_withActaXcopyParasCommentsTasksMessagesAttachements,
  Task_withActaXcopy_compare,
} from "../types/Task_withActaXcopy";
import { TASKFILTERITEM_ENUM, TaskListFilterItem } from "../taskpane/uiv2/Tasks/TaskListV4";
import { updateTaskData } from "../api/calls/updateTaskData";
import { limaQuery_getTaskList } from "../api/calls/limaGetTasksCall";
import { IAttachmentFileWithInfo } from "../types/LimaAttachement";
import moment = require("moment");

//TODO: CHANGE BACK TO TYPE import { EditTaskData, EditTaskDataDef } from "../types/tasks";

export enum TASK_LINKED2ME_ENUM {
  NOTONLYMY = "NOTONLYMY",
  IRESPONSIBLEONLY = "IRESPONSIBLEONLY",
  ICREATEONLY = "ICREATEONLY",
}

class LimaTasksStoreV4 {
  fullTasksList: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements[] | null = null;
  tasksList: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements[] | null = null;
  fullTasksListCount = 0;
  filterString = "";
  filterClosed = true;
  filterLinked = false;
  filterMy: TASK_LINKED2ME_ENUM = TASK_LINKED2ME_ENUM.NOTONLYMY;

  isLoading = false;
  /**
   * Full task object - used for editing as temporary task
   */
  editTask: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements | null = null;
  editTask_withActaXcopy: Task_withActaXcopy | null = null; //current edited task
  editTask_withActaXcopy_files: IAttachmentFileWithInfo[] | null = null; //current edited task
  refresh = false;
  taskDueDate: Date | null = null;

  lastDBReadDate: string | null = null;

  //editTaskListUsers: any[] = [];
  /**
   * Task data
   */
  editCurrentTaskData: TaskData | null = null;
  taskDataDateEnable = false;

  constructor() {
    makeObservable(this, {
      refresh: observable,
      tasksList: observable,
      fullTasksList: observable,
      editTask: observable,
      editTask_withActaXcopy: observable,
      editTask_withActaXcopy_files: observable,
      taskDueDate: observable,
      fullTasksListCount: observable,
      taskDataDateEnable: observable,
      editCurrentTaskData: observable,

      setTasksList: action,
      setFullTasksList: action,
      setFullTaskListCount: action,

      setEditTask: action,
      setEditTask_withActaXcopy: action,
      setTaskId: action,
      setTaskName: action,
      setTaskDescription: action,
      setTaskState: action,
      setResponsible: action,
      setDueDate: action,
      setTaskData: action,
      setTaskCIssuedBy: action,
      setTaskCTimeStamp: action,
      setTaskDataDate: action,
      setEditCurrentTaskData: action,
      setTaskDataDateEnable: action,
      setFilterLinked: action,

      getEditTaskAttchCount: computed,
      getEditLinkedParasCount: computed,
      // getBodyToStore: action,
    });
  }

  /* ------------- editTaskData --------------- */
  getTaskByKey(taskKey: string): User2Task_withActaXcopyParasCommentsTasksMessagesAttachements {
    log.info(limaLogTag.LimaTasksStoreV4, `getTaskByKey: key [${taskKey}]`);
    if (this.tasksList !== null) {
      const taskItem = this.tasksList.filter(
        (task: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements) =>
          task.task_withActaXcopyParasCommentsTasksMessagesAttachements._key === taskKey
      )[0];
      log.info(limaLogTag.LimaTasksStoreV4, `getTaskByKey: key [${taskKey}]`, taskItem);
      return taskItem;
    }
    return null;
  }

  setEditTask(editTask: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements | null) {
    log.info(limaLogTag.LimaTasksStoreV4, "setEditTask: update before", editTask);
    if (
      editTask !== undefined &&
      editTask !== null &&
      editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements !== null
    ) {
      log.info(limaLogTag.LimaTasksStoreV4, "setEditTask: update", JSON.stringify(editTask));
      this.editTask = editTask;
    }
  }

  setEditTask_withActaXcopy(editTask_withActaXcopy: Task_withActaXcopy | null) {
    if (editTask_withActaXcopy !== undefined && editTask_withActaXcopy !== null) {
      log.info(
        limaLogTag.LimaTasksStoreV4,
        "setEditTask_withActaXcopy: update",
        JSON.stringify(editTask_withActaXcopy)
      );
      this.editTask_withActaXcopy = editTask_withActaXcopy;
    }
  }

  setEditTask_withActaXcopy_files(newFiles: IAttachmentFileWithInfo[] | null) {
    this.editTask_withActaXcopy_files = newFiles;
  }

  setTaskId(key: string) {
    if (this.editTask === null)
      this.setEditTask(User2Task_withActaXcopyParasCommentsTasksMessagesAttachements_getNull());
    this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements._key = key;
  }
  setTaskName(taskName: string) {
    if (this.editTask === null)
      this.setEditTask(User2Task_withActaXcopyParasCommentsTasksMessagesAttachements_getNull());
    this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskName = taskName;
  }
  setTaskCIssuedBy(cIssuedBy: string) {
    if (this.editTask == null)
      this.setEditTask(User2Task_withActaXcopyParasCommentsTasksMessagesAttachements_getNull());
    this.editTask.cIssuedBy = cIssuedBy;
  }
  setTaskCTimeStamp(cTimeStamp: string) {
    if (this.editTask == null)
      this.setEditTask(User2Task_withActaXcopyParasCommentsTasksMessagesAttachements_getNull());
    this.editTask.cTimeStamp = cTimeStamp;
  }
  setTaskDescription(taskDescription: string) {
    if (this.editTask == null)
      this.setEditTask(User2Task_withActaXcopyParasCommentsTasksMessagesAttachements_getNull());
    this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskDescription = taskDescription;
  }
  setTaskState(taskState: string) {
    this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskState = taskState;
  }
  setResponsible(responsible: string) {
    if (this.editTask == null)
      this.setEditTask(User2Task_withActaXcopyParasCommentsTasksMessagesAttachements_getNull());
    if (this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskAssignedTo == null)
      this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskAssignedTo = User_getNull();
    this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskAssignedTo._key = responsible;
  }

  setDueDate(dueDate: string) {
    if (this.editTask == null)
      this.setEditTask(User2Task_withActaXcopyParasCommentsTasksMessagesAttachements_getNull());
    this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskDueDate = dueDate;
  }

  setTaskData(taskData: TaskData | null) {
    //this.editTaskData.taskData = taskData;
    log.info(limaLogTag.LimaTasksStoreV4, `setTaskData: setting task data`, taskData);
    this.setEditTask({
      ...this.editTask,
      task_withActaXcopyParasCommentsTasksMessagesAttachements: {
        ...this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements,
        taskData: taskData,
      },
    });
  }

  /* ------------- taskDataDate --------------- */
  setTaskDataDate(dataDueDate: Date | null) {
    if (this.editTask == null)
      this.setEditTask(User2Task_withActaXcopyParasCommentsTasksMessagesAttachements_getNull());
    this.taskDueDate = dataDueDate;
  }

  /* ------------- taskDataDAteEnable --------------- */
  setTaskDataDateEnable(state: boolean) {
    if (this.editTask == null)
      this.setEditTask(User2Task_withActaXcopyParasCommentsTasksMessagesAttachements_getNull());
    this.taskDataDateEnable = state;
  }

  async removeTaskFromList(key: string) {
    if (key === "") {
      log.warn(limaLogTag.LimaTasksStoreV4, `removeTaskFromList: missing key for task to remove task[${key}]`);
    }
    const loaderID = limaLoadeStore.add(`Call remove task visibility`);
    log.info(limaLogTag.LimaTasksStoreV4, `removeTaskFromList: for '${limaStore.userId}' task id[${key}]`);
    let dataReply: HttpResponse<any>; //TODO: change ANY
    try {
      //dataReply = await httpDelete(urlDeleteTask(key), { userId: limaStore.userId }, loaderID.ac);
      dataReply = await httpDeleteDeleteAuth(urlDeleteTask(key), loaderID.ac);
      if (dataReply.status == 200) {
        log.info(limaLogTag.LimaTasksStoreV4, "removeTaskFromList: get response", dataReply);
        this.setFullTasksList(
          this.fullTasksList.filter(
            (item) => item.task_withActaXcopyParasCommentsTasksMessagesAttachements._key !== key
          )
        );
        this.setFullTaskListCount(this.fullTasksList.length);
        this.setTasksList(
          this.tasksList.filter((item) => item.task_withActaXcopyParasCommentsTasksMessagesAttachements._key !== key)
        );
      }
    } catch (err) {
      log.error(limaLogTag.LimaTasksStoreV4, "removeTaskFromList: get erro response from call", err);
    } finally {
      limaLoadeStore.remove(loaderID.k);
    }
  }

  /* -------------- tasksList ---------------- */
  setTasksList(tasks: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements[]) {
    this.tasksList = tasks;
  }

  /* ---------------- fullTasksList ---------------- */
  setFullTasksList(tasks: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements[] | null) {
    this.fullTasksList = tasks;
    if (tasks !== null) {
      this.setFullTaskListCount(tasks.length);
      this.lastDBReadDate = moment().toString();
    }
  }

  setFullTaskListCount(count: number) {
    this.fullTasksListCount = count;
  }

  setEditCurrentTaskData(newCurTaskData: TaskData | null) {
    this.editCurrentTaskData = newCurTaskData;
    // this.editTaskData.taskData = JSON.stringify(this.editCurrentTaskData);
    if (newCurTaskData !== undefined && newCurTaskData !== null) {
      this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskData = this.editCurrentTaskData;
      log.info(limaLogTag.LimaTasksStoreV4, "setEditCurrentTaskData: ", JSON.stringify(newCurTaskData));
    }
  }

  onClear = () => {
    log.info(limaLogTag.LimaTasksStoreV4, "onClear: clearing current task data");
    this.setEditTask(User2Task_withActaXcopyParasCommentsTasksMessagesAttachements_getNull());
  };

  onShowTaskHistory = () => {
    log.info(limaLogTag.LimaTasksStoreV4, "onClickHandler: clicked", this.editTask && this.editTask._key);
    localStorage.setItem("selectedTask", (this.editTask && this.editTask._key) || ""); //TODO: Change
    openDialogAsIframe_taskhistory();
  };

  onComapreActaVersion = async () => {
    log.info(limaLogTag.LimaTasksStoreV4, "compare");

    // let taskDataJSON: taskItemData = map2TaskItem(this.editTaskData.taskData);
    const taskDataJSON: TaskData = this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskData;
    if (
      taskDataJSON.actaKey == null ||
      taskDataJSON.actaKey == "" ||
      taskDataJSON.xcopyKey == null ||
      taskDataJSON.xcopyKey == ""
    ) {
      log.info(limaLogTag.LimaTasksStoreV4, "missing key id");
      return;
    }
    //this.setProgress(true);
    const loaderID = limaLoadeStore.add(`Call to compare acta and xcopy`);
    // await compareDocx_xcopy(null, taskDataJSON.actaKey, taskDataJSON.xcopyKey, limaStore.userId);
    await compareXcopy2XcopyByActaKey(taskDataJSON.actaKey, taskDataJSON.xcopyKey, limaStore.userId, loaderID);
    //this.setProgress(false);
    limaLoadeStore.remove(loaderID.k);
  };

  /**
   * Replace Acta xcopy to document for current task
   *
   */
  onGetActaXcopyFile = async () => {
    //Obosliite not zet refactored
    if (
      this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements.linkedXcopy._key == null &&
      this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements.linkedXcopy._key === ""
    ) {
      log.warn(limaLogTag.LimaTasksStoreV4, "onGetActaXcopyFile: we dont have xcopy in taskData ", this.editTask);
    }
    if (
      this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskData.actaKey == null &&
      this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskData.actaKey === ""
    ) {
      log.warn(limaLogTag.LimaTasksStoreV4, "onGetActaXcopyFile: we dont have actaid in taskData ", this.editTask);
    }

    //let taskDataJSON: taskItemData = map2TaskItem(this.editTaskData.taskData);
    const taskDataJSON: TaskData = this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskData;
    if (taskDataJSON.xcopyKey == null && taskDataJSON.xcopyKey === "") {
      log.warn(limaLogTag.LimaTasksStoreV4, "onGetActaVersionFile: we dont have xcopy in taskDataJSON ", taskDataJSON);
    }
    if (taskDataJSON.actaKey == null && taskDataJSON.actaKey === "") {
      log.warn(limaLogTag.LimaTasksStoreV4, "onGetActaVersionFile: we dont have actaid in taskDataJSON ", taskDataJSON);
    }

    log.info(limaLogTag.LimaTasksStoreV4, "getting xcopy for xcopy", taskDataJSON, this.editTask);
    await getActaXcopyFile(taskDataJSON.actaKey, taskDataJSON.xcopyKey);
  };

  onGetActaFile = async () => {
    if (
      this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskData.actaKey != null &&
      this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskData.actaKey != ""
    ) {
      // limaStore.setActaId(this.editTask.task_withActaXcopy.taskData.actaKey);
      limaStore.setActa(this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskData.actaKey);
      //limaStore.setActaName(this.editTaskData.actaName);
      localStorage.setItem("actaId", limaStore.actaId);
      log.info(limaLogTag.LimaTasksStoreV4, "onGetActaFile: getting acta for acta", limaStore.actaId);
      // this.setProgress(true);
      const loaderID = limaLoadeStore.add(`Call get Acta from server`);
      limaStore.setDocxIsLoading(true);
      await insertDoc(
        limaStore.actaId,
        urlActaInsertDoc(limaStore.actaId, limaStore.userId),
        limaStore.actaName,
        LimaDocTypeENUM.ACTA,
        () => {
          limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
          limaStore.setDocxIsLoading(false);
        },
        () => {
          limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
          limaStore.setDocxIsLoading(false);
        },
        loaderID
      );
      //limaStore.setActiveOpenType(openedfileType.ACTA)
      // limaStore.toActaDoc();
      limaStore.setActiveOpenType(OPENEDFILETYPE_ENUM.ACTA);
      //this.setProgress(false);
    } else {
      log.warn(limaLogTag.LimaTasksStoreV4, "onGetActaFile: looks like missing actaId", limaStore.actaId);
    }
  };

  onUpdate = async () => {
    if (
      this.editTask === null ||
      this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements === null ||
      this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements._key === null ||
      this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements._key === ""
    ) {
      log.warn(limaLogTag.LimaTasksStoreV4, "onUpdate: no task id ", this.editTask);
      return;
    }
    await updateTaskData(this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements, limaStore.userId);
  };

  onStoreXcopy = () => {
    if (
      this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskData.actaKey != null &&
      this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskData.actaKey != ""
    ) {
      if (
        this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskData.actaKey != null &&
        this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskData.actaKey != ""
      ) {
        // let loaderID = limaLoadeStore.add(`Storing xcopy to server`);

        log.info(
          limaLogTag.LimaTasksStoreV4,
          `storing xcopy for xcopy [${limaStore.xcopyId}/${this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskData.xcopyKey}]`
        );
        void storeToXcopy(
          this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskData.actaKey,
          this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskData.xcopyKey
        ).then(() => {
          limaStore.xcopyId = this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskData.xcopyKey;
        });
      }
    }
  };

  /**
   * Read all tasks from DB and set them into FullTasksList,taskList and fulltasklistCOunt
   */
  getTasksListFromDB = async (): Promise<User2Task_withActaXcopyParasCommentsTasksMessagesAttachements[]> => {
    log.info(limaLogTag.LimaTasksStoreV4, "getTasksList: get tasks list call start");
    return limaQuery_getTaskList();
  };

  //onFilterChanged = (_: any, text: string): void => {
  onFilterChanged = (_?: any, text?: string): void => {
    //TODO: move to lima Store
    if (text !== undefined) this.filterString = text;
    //else text = this.filterString;
    if (!this.fullTasksList || this.fullTasksList === null) {
      log.warn(limaLogTag.LimaTasksStoreV4, "onFilterChanged: call onFilterChange on null taskList");
      return;
    }
    if (this.fullTasksList && this.fullTasksList.length === 0) {
      log.warn(limaLogTag.LimaTasksStoreV4, "onFilterChanged: filter nothign - no tasks in full list ");
      return;
    }
    const dontShowClosed = this.filterClosed;
    const filterLinked = this.filterLinked;
    const filterMy = this.filterMy;
    const filterText = this.filterString;

    log.info(
      limaLogTag.LimaTasksStoreV4,
      `onFilterChanged: before changed [${this.tasksList && this.tasksList.length}]`
    );

    this.setTasksList(
      this.fullTasksList.filter(function (item: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements) {
        log.info(
          limaLogTag.LimaTasksStoreV4,
          "onFilterChanged: test filterin ",
          item,
          dontShowClosed,
          filterLinked,
          filterMy,
          filterText
        );

        //Filter nulls
        if (
          item === null ||
          item.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskName === undefined ||
          item.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskName === null ||
          item.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskName === ""
        ) {
          return false;
        }
        //Filter out closed
        else if (
          dontShowClosed === true &&
          item.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskState !== undefined &&
          item.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskState !== null &&
          item.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskState.toLocaleLowerCase() === "close"
        ) {
          return false;
        }
        //Filter out not linked to document
        else if (
          filterLinked === true &&
          item.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskData &&
          (item.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskData.actaKey !== limaStore.actaId ||
            item.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskData.xcopyKey !== limaStore.xcopyId)
        )
          return false;
        //Filter out not my
        else if (
          filterMy === TASK_LINKED2ME_ENUM.IRESPONSIBLEONLY &&
          (item.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskAssignedTo == null ||
            item.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskAssignedTo._key !== limaStore.userId)
        )
          return false;
        else if (
          filterMy === TASK_LINKED2ME_ENUM.ICREATEONLY &&
          (item.task_withActaXcopyParasCommentsTasksMessagesAttachements.cIssuedBy == null ||
            item.task_withActaXcopyParasCommentsTasksMessagesAttachements.cIssuedBy !== limaStore.userId)
        )
          return false;
        //Filter out not linked to document
        else if (
          filterText !== "" &&
          !(
            item.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskName
              .toLowerCase()
              .indexOf(filterText.toLowerCase()) >= 0
          )
        )
          return false;
        return true;
      })
    );
    log.info(limaLogTag.LimaTasksStoreV4, `onFilterChanged: after changed [${this.tasksList.length}]`);
  };

  /**
   * manualz update single task data in full list
   * change task idenfied by task_withActaXcopy._key on FullTaskLIst and TaskList
   *
   * @param updatedTask
   */
  updateSingleTask(updatedTask: Task_withActaXcopyParasCommentsTasksMessagesAttachements) {
    this.setFullTasksList(
      this.fullTasksList.map((item: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements) => {
        if (item.task_withActaXcopyParasCommentsTasksMessagesAttachements._key == updatedTask._key)
          item.task_withActaXcopyParasCommentsTasksMessagesAttachements = updatedTask;
        return item;
      })
    );

    this.setTasksList(
      this.tasksList.map((item: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements) => {
        if (item.task_withActaXcopyParasCommentsTasksMessagesAttachements._key == updatedTask._key)
          item.task_withActaXcopyParasCommentsTasksMessagesAttachements = updatedTask;
        return item;
      })
    );
  }

  updateSingleTask_withTask_withActaXcopy(updatedTask: Task_withActaXcopy) {
    this.setFullTasksList(
      this.fullTasksList.map((item: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements) => {
        if (item.task_withActaXcopyParasCommentsTasksMessagesAttachements._key == updatedTask._key)
          item.task_withActaXcopyParasCommentsTasksMessagesAttachements =
            convert_Task_withActaXcopy2Task_withActaXcopyParasCommentsTasksMessagesAttachements(
              updatedTask,
              item.task_withActaXcopyParasCommentsTasksMessagesAttachements
            );
        return item;
      })
    );

    this.setTasksList(
      this.tasksList.map((item: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements) => {
        if (item.task_withActaXcopyParasCommentsTasksMessagesAttachements._key == updatedTask._key)
          item.task_withActaXcopyParasCommentsTasksMessagesAttachements =
            convert_Task_withActaXcopy2Task_withActaXcopyParasCommentsTasksMessagesAttachements(
              updatedTask,
              item.task_withActaXcopyParasCommentsTasksMessagesAttachements
            );
        //item.task_withActaXcopyParasCommentsTasksMessagesAttachements = updatedTask;
        return item;
      })
    );
  }

  setFilterLinked(linkedToActaOnly: boolean) {
    this.filterLinked = linkedToActaOnly;
    this.onFilterChanged();
  }

  setFilterMy(onlyMy: TASK_LINKED2ME_ENUM) {
    this.filterMy = onlyMy;
    this.onFilterChanged();
  }

  /**
   * new filtering on task list
   */
  getFilterdTasks = (
    usedFilters: TaskListFilterItem[],
    textFilter: string
  ): User2Task_withActaXcopyParasCommentsTasksMessagesAttachements[] => {
    if (this.fullTasksList === null) {
      return [];
    }
    let filterdItems = this.fullTasksList;
    let maxItemsCount = null;
    log.debug(limaLogTag.LimaTasksStoreV4, `getFilterdTasks: before filter start count: ${filterdItems.length}`);
    usedFilters &&
      usedFilters.forEach((filterItem: TaskListFilterItem) => {
        log.debug(limaLogTag.LimaTasksStoreV4, "getFilterdTasks: now filtering on ", filterItem);
        if (
          filterItem.taskItemFilterIdent === TASKFILTERITEM_ENUM.FILTER_TASKCOUNT &&
          filterItem.taksItemFilterValue !== null
        ) {
          maxItemsCount = filterItem.taksItemFilterValue;
          //FILTER COUNT on END
        } else {
          filterdItems = filterdItems.filter((item: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements) => {
            log.info(limaLogTag.LimaTasksStoreV4, "getFilterdTasks: now testing  item ", item);
            //FILTER CREATOR
            if (filterItem.taskItemFilterIdent === TASKFILTERITEM_ENUM.FILTER_CREATOR) {
              if (
                filterItem.taksItemFilterValue === TASK_LINKED2ME_ENUM.ICREATEONLY &&
                item.cIssuedBy === limaStore.userId
              ) {
                return true;
              }
              if (
                filterItem.taksItemFilterValue === TASK_LINKED2ME_ENUM.IRESPONSIBLEONLY &&
                item.task_withActaXcopyParasCommentsTasksMessagesAttachements?.taskAssignedTo?._key === limaStore.userId
              ) {
                return true;
              }

              if (filterItem.taksItemFilterValue === TASK_LINKED2ME_ENUM.NOTONLYMY) return true;
              return false;
            }

            //FILTER LINKED ITEMS
            if (filterItem.taskItemFilterIdent === TASKFILTERITEM_ENUM.FILTER_LINKTOACTIVEDOC) {
              if (
                filterItem.taksItemFilterValue === true &&
                item.task_withActaXcopyParasCommentsTasksMessagesAttachements?.linkedXcopy?._key === limaStore.xcopyId
              ) {
                return true;
              }

              if (filterItem.taksItemFilterValue === false) return true;

              return false;
            }

            //FILTER TASK STATE
            if (filterItem.taskItemFilterIdent === TASKFILTERITEM_ENUM.FILTER_TASKSTATE) {
              if (filterItem.taksItemFilterValue === null) {
                return true;
              }
              if (
                filterItem.taksItemFilterValue ===
                  item.task_withActaXcopyParasCommentsTasksMessagesAttachements?.taskState ||
                item.task_withActaXcopyParasCommentsTasksMessagesAttachements?.taskState == TASKSTATE.NONE
              ) {
                return true;
              }

              return false;
            }
            return false;
          });
        }
        log.debug(limaLogTag.LimaTasksStoreV4, "getFilterdTasks: on filter step finish ", filterdItems);
      });

    //Filter by text
    if (textFilter !== null || textFilter !== "") {
      const newTextFilterSplitted = textFilter.split("");
      filterdItems = filterdItems.filter((item: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements) => {
        return newTextFilterSplitted.every(
          (w: string) =>
            item.task_withActaXcopyParasCommentsTasksMessagesAttachements?.taskName?.includes(w) ||
            item.task_withActaXcopyParasCommentsTasksMessagesAttachements?.taskDescription?.includes(w)
        );
      });
    }
    log.info(limaLogTag.LimaTasksStoreV4, "getFilterdTasks: after text filter finish", filterdItems);

    //Limit count
    if (maxItemsCount !== null) {
      filterdItems = filterdItems.slice(0, maxItemsCount);
    }
    log.info(limaLogTag.LimaTasksStoreV4, "getFilterdTasks: after maxItemsCount filter finish", filterdItems);
    return filterdItems;
  };

  /**
   * Get Edit Task data items
   */
  get getEditTaskAttchCount(): number {
    if (this.editTask === null) return 0;
    return this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskData.attachements.length;
  }

  get getEditLinkedParasCount(): number {
    if (this.editTask === null) return 0;
    return this.editTask.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskData.paraIds.length;
  }

  //---------------------------------------------------
  // Other functions
  //---------------------------------------------------

  /**
   * just simple call to check existence read from DB
   */
  checkLastRead = async () => {
    log.info(
      limaLogTag.LimaTasksStoreV4,
      `checkLastRead: ${String(this.lastDBReadDate === null)} `,
      this.lastDBReadDate
    );
    if (this.lastDBReadDate === null && this.isLoading === false) {
      await this.getTasksListFromDB();
    }
  };

  /**
   * on logout clear
   */
  onLogoutClear(): void {
    this.setFullTasksList(null);
    this.setFullTaskListCount(0);
    this.lastDBReadDate = null;
    this.setTasksList(null);
    this.setTasksList(null);
    this.setTasksList(null);
  }

  /**
   * compare edit task to task
   */
  isSameAsEditTask = (taskToCompare: Task_withActaXcopy): boolean => {
    return Task_withActaXcopy_compare(taskToCompare, this.editTask_withActaXcopy);
  };
}
const limaTasksStoreV4 = new LimaTasksStoreV4();
export default limaTasksStoreV4;
