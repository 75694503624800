import {
  // ActionButton,
  FontIcon,
  getFocusStyle,
  getTheme,
  // IIconProps,
  ITheme,
  mergeStyleSets,
  Stack,
} from "@fluentui/react";
import { log } from "missionlog";
import { FunctionComponent } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import { LimaIconDismiss } from "../../../../../assets/icons/LimaIconDismiss";
import { LimaIconDownload } from "../../../../../assets/icons/LimaIconDownload";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import { FILE_TYPE, getFileType, LimaAttachement } from "../../../../../types/LimaAttachement";
import LimaActionButtonV4 from "../../../Components/LimaActionButtonV4";
import { isNumeric } from "../../../../../limaCommon/limaCommon";

interface IAttachmentItemV4Props {
  attachement: LimaAttachement;
  onDownloadHandler?: () => void;
  enableDownload?: boolean;
  onRemoveAtt?: () => void;
  enableRemove?: boolean;
}

// const getFileIcon: IIconProps = { iconName: "RedEye" };
// const removeFileIcon: IIconProps = { iconName: "Cancel" };

const theme: ITheme = getTheme();
const { palette, semanticColors } = theme;

const classNames = mergeStyleSets({
  stackWrapper: [
    getFocusStyle(theme, { inset: -1 }),
    {
      // flexGrow: 1,
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      // paddingRight: 30,
      minHeight: 30,
      paddingLeft: 10,
      boxSizing: "border-box",
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      verticalAlign: "medium",
      selectors: {
        "&:hover": {
          background: palette.neutralLight,
          cursor: "pointer",
        },
      },
    },
  ],
  stackItemName: {},
  stackItemActions: { marginLeft: 3, display: "flex", flexDirection: "row", justifyContent: "space-around" },
  fileName: {
    fontSize: 14,
    fontWeight: 600,
    marginLeft: 10,
  },
  fileSize: {
    fontSize: 14,
    fontWeight: 400,
    color: "#8E8E93",
    marginLeft: 10,
  },
});

const AttachementItemV4: FunctionComponent<IAttachmentItemV4Props> = (props: IAttachmentItemV4Props) => {
  const { t } = useTranslation();

  const onDownloadClickCallBack = () => {
    log.info(limaLogTag.AttachmentItemV4, "clicked on ", props.attachement);
    if (props.onDownloadHandler !== undefined) props.onDownloadHandler();
  };

  const onRemoveClick = () => {
    log.info(limaLogTag.AttachmentItemV4, "clicked on ", props.attachement);
    if (props.onRemoveAtt !== undefined) props.onRemoveAtt();
  };

  const AttachmentIcon = (fileTypeString: string): JSX.Element => {
    let iconName = "Document";
    switch (getFileType(fileTypeString)) {
      case FILE_TYPE.WORD:
        iconName = "WordDocument";
        break;
      case FILE_TYPE.EXCEL:
        iconName = "ExcelDocument";
        break;
      case FILE_TYPE.TEXT:
        iconName = "TextDocument";
        break;
      case FILE_TYPE.EMAIL:
        iconName = "Mail";
        break;
      case FILE_TYPE.IMAGE:
        iconName = "FileImage";
        break;
      case FILE_TYPE.PDF:
        iconName = "PDF";
        break;
      case FILE_TYPE.OTHER:
    }

    return <FontIcon aria-label={iconName} iconName={iconName} />;
  };

  const title = (): string => {
    return `filename: [${props.attachement.filename}] .. desc: ${props.attachement.description}`;
  };

  const fileSize = (): string => {
    if (!isNumeric(props.attachement.filesize)) return props.attachement.filesize;

    if (Number(props.attachement.filesize) > 1000000) {
      return `${String(Math.floor(Number(props.attachement.filesize) / 100000) / 10)} MB`;
    }
    if (Number(props.attachement.filesize) > 1000) {
      return `${String(Math.floor(Number(props.attachement.filesize) / 100) / 10)} kB`;
    } else {
      return `${props.attachement.filesize} B`;
    }
  };

  return (
    <Stack horizontal className={classNames.stackWrapper}>
      <Stack.Item grow={1} title={title()} className={classNames.stackItemName}>
        {AttachmentIcon(props.attachement.fileType)}
        <span className={classNames.fileName}>{props.attachement.name}</span>
        <span className={classNames.fileSize}>{props.attachement.filesize && `| ${fileSize()}`}</span>
      </Stack.Item>
      <Stack.Item className={classNames.stackItemActions}>
        {(props.enableRemove === undefined || props.enableRemove === true) && (
          <LimaActionButtonV4 onClick={onRemoveClick} toolTipShow={false} toolTipContent="Remove attachement from list">
            <LimaIconDismiss
              title={t("tptasks:taskedit.taskitemV4.attachments.dismissActionTitle")}
              size={18}
              fillColor={"rgb(16, 110, 190)"}
            />
          </LimaActionButtonV4>
        )}
        {(props.enableDownload === undefined || props.enableDownload === true) && (
          <LimaActionButtonV4
            onClick={onDownloadClickCallBack}
            toolTipShow={false}
            toolTipContent="Download attachement"
          >
            <LimaIconDownload
              title={t("tptasks:taskedit.taskitemV4.attachments.downloadActionTitle")}
              size={24}
              fillColor={"rgb(16, 110, 190)"}
            />
          </LimaActionButtonV4>
        )}
      </Stack.Item>
    </Stack>
  );
};

export default AttachementItemV4;
