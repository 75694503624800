/**
 * LoaderStore tkaes care abour loaders, that infor user that something is loading
 *
 * @module limaLoaderStore
 *
 * @category mobx Store
 *
 * @version 0.4
 *
 * @author MS
 *
 * @example
 * ```
 * import limaLoaderStore from "./limaLoaderStore";
 *
 * ...
 * let loaderID = limaLoaderStore.add()
 * ...
 * limaLoaderStore.remove(loaderID)
 * or limaLoaderStore.remove(loaderID, LoaderItemEnd)
 * ...
 *
 * ```
 */
import { action, computed, makeObservable, observable, toJS } from "mobx";
import { log, tag } from "missionlog";
import moment = require("moment");

log.init({ LoaderStore: "LoaderStore" });

/**
 * LoaderStore takes care about loading intems and show then to user
 *
 * @class LoaderStore
 *
 */

export enum LoaderItemEnd {
  OK = "OK",
  FAILED = "FAILED",
  UNKNOWN = "UNKNOWN",
  OK_MESSAGE = "OK_MESSAGE", //NOT YET IMPLEMENTED
}

/**
 * Loader item storing info about loading proccessing
 * k - key identifief
 * t - text identification what is going on
 * f -   moment() start as number (value of),
 * f2 -  on finish shows processing in milliseconds
 * end - LoaderItemEnd type of finish OK, FAILED, UNKNOWN ...
 * ac - abor controller, to stop calling
 */
export interface LoaderListItem {
  k: string;
  t: string;
  f: number;
  f2: number;
  end: LoaderItemEnd;
  ac: AbortController;
}

export class LimaLoaderStore {
  foreverMaxCount = 50;

  isLoading: boolean | undefined = false;
  isFinishSuccess = false;
  loaderListStrings: string[] = [];
  loadersList: LoaderListItem[] = [];
  finishedList: LoaderListItem[] = [];
  finishedForeverList: LoaderListItem[] = [];

  constructor() {
    makeObservable(this, {
      isLoading: observable,
      finishedList: observable,
      finishedForeverList: observable,
      isFinishSuccess: observable,
      loadersList: observable,

      add: action,
      remove: action,
      setIsLoading: action,
      addToFinishedList: action,
      addToFinishedForeverList: action,
      removeFromFinishedList: action,
      setOnFinishSuccess: action,

      getReversedForeverList: computed,
    });
  }

  /**
   * Set isLoading param to true or folse this indicator is linked to frontend action
   * Its not commonly used, better is to use add and remove
   *
   * @param {boolean} isLoading param to set loading to true or false
   * @memberof LoaderStore
   *
   */
  setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  /**
   * Update list of queued items in loading notifier
   * Its not commonly used, better is to use add and remove
   *
   * @param newList new list of loading items in queue
   * @memberof LoaderStore
   */
  private setLoaderList(newList: string[], newListText: LoaderListItem[]) {
    this.loaderListStrings = newList;
    this.loadersList = newListText;

    newList.length > 0 ? this.setIsLoading(true) : this.setIsLoading(false);
  }
  /**
   * Main function for adding and removing list od loaders
   * @returns {string}
   * @memberof loadeStore
   * @example
   * ```
   * let loadirID = loadeStore.add()
   * //leter remove by loaderStore.remove(loaderID)
   * ```
   *
   */
  add(_text?: string): LoaderListItem {
    let outString = "";
    const now: number = moment().valueOf();
    const inOptions = "abcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 10; i++) {
      outString += inOptions.charAt(Math.floor(Math.random() * inOptions.length));
    }

    this.loaderListStrings.push(outString);
    const item: LoaderListItem = {
      k: outString,
      t: _text || "",
      f: now,
      f2: 0,
      ac: new AbortController(),
      end: LoaderItemEnd.UNKNOWN,
    };
    this.loadersList.push(item);
    this.setLoaderList(this.loaderListStrings, this.loadersList);

    //return outString;
    return item;
  }

  /**
   * Main function for adding and removing list od loaders
   * @returns {string} ID of loader that has to be removed from loader queue
   * @memberof loadeStore
   * @example
   * ```
   *  //prior define let loadirID = loadeStore.add()
   *  loaderStore.remove(loaderID)
   * ```
   *
   */
  remove(id: string, end?: LoaderItemEnd): void {
    const item = this.loadersList.filter((itemInner: LoaderListItem) => itemInner.k === id);

    if (item.length >= 1) {
      //set end status
      if (end !== undefined) item[0].end = end;
      //Set final elapsed time
      item[0].f2 = moment().diff(item[0].f);
      this.addToFinishedForeverList(item[0]);
      this.setLoaderList(
        this.loaderListStrings.filter((item2) => item2 !== id),
        this.loadersList.filter((item3) => item3.k !== id)
      );
    } else {
      log.warn(tag.LoaderStore, `remove: missing [${id}] in loader list`);
    }
  }

  /**
   * Cleanup all previous loader data
   * @memberof loadeStore
   * @example
   * ```
   *  //prior define let loadirID = loadeStore.add()
   *  loaderStore.cleanup()
   * ```
   *
   */
  cleanup(): void {
    this.setLoaderList([], []);
  }

  addToFinishedList(newItem: LoaderListItem): void {
    this.finishedList.push(newItem);
    setTimeout(this.removeFromFinishedList, 1000, newItem);
  }

  addToFinishedForeverList(newItem: LoaderListItem): void {
    this.finishedForeverList.push(newItem);
    if (this.finishedForeverList.length > this.foreverMaxCount) {
      this.finishedForeverList.slice(1);
    }
    // setTimeout(this.removeFromFinishedList, 1000, newItem);
  }

  removeFromFinishedList = (removeItem: LoaderListItem): void => {
    this.finishedList = this.finishedList.filter((item: LoaderListItem) => item.k !== removeItem.k);
  };

  abortAllAC = (): void => {
    this.loadersList.forEach((item: LoaderListItem) => {
      item.ac.abort();
    });
  };

  setOnFinishSuccess = (isSuccess: boolean): void => {
    if (isSuccess === true) {
      this.isFinishSuccess = true;
      setTimeout(() => this.setOnFinishSuccess(false), 1500);
    } else {
      this.isFinishSuccess = false;
    }
  };

  get getReversedForeverList(): LoaderListItem[] {
    const outList: LoaderListItem[] = toJS(this.finishedForeverList).reverse();

    return outList;
  }

  /**
   * Cleanup on logout button
   *
   * @autor MS
   */
  onLogoutClear(): void {
    this.foreverMaxCount = 50;

    this.isLoading = false;
    this.isFinishSuccess = false;
    this.loaderListStrings = [];
    this.loadersList = [];
    this.finishedList = [];
    this.finishedForeverList = [];
  }
}

const limaLoadeStore = new LimaLoaderStore();
export default limaLoadeStore;
