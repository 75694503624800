import { Acta } from "./Acta";
import { LimaAttachement } from "./LimaAttachement";
import { LimactaComment } from "./LimactaComment";
import { LimaMessage } from "./Message";
import { Para } from "./Para";
import { Task, TaskData_clone, TaskData_compare, TaskData_getNull, TaskTypeEnum } from "./Task";
import { User_clone, User_compare, User_getNull } from "./User";
import { Xcopy } from "./Xcopy";
import { z } from "zod";

export interface Task_withActaXcopyParasCommentsTasksMessagesAttachements extends Task_withActaXcopy {
  linkedPara: Para[];
  linkedComment: LimactaComment[];
  linkedTasks: Task[];
  linkedMessages?: LimaMessage[];
  linkedMessagesCount: number;
  linkedAttachments?: LimaAttachement[];
  linkedAttachmentsCount: number;
}

export function isTask_withActaXcopyParasCommentsTasksMessagesAttachements(
  item: unknown
): item is Task_withActaXcopyParasCommentsTasksMessagesAttachements {
  return (item as Task_withActaXcopyParasCommentsTasksMessagesAttachements).linkedPara !== undefined;
}

export interface Task_withActaXcopy extends Task {
  linkedActa: Acta; //TODO: CHange when we know
  linkedXcopy: Xcopy; //TODO: Change when we know
}

export const toTask = (item: Task_withActaXcopy): Task => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { linkedActa, linkedXcopy, ...rest } = item;
  const outTask: Task = { ...rest };
  return outTask;
};

export const Task_withActaXcopy_getNull = (): Task_withActaXcopy => {
  return {
    cIssuedBy: null,
    cTimeStamp: null,
    dIssuedBy: null,
    dtimestamp: null,
    _id: null,
    _key: null,
    linkedActa: null,
    linkedXcopy: null,
    luIssuedBy: null,
    luTimeStamp: null,
    taskAssignedTo: User_getNull(),
    taskData: TaskData_getNull(),
    taskDescription: null,
    taskDueDate: null,
    taskName: null,
    taskState: null,
    type: TaskTypeEnum.TASK,
  };
};

export const Task_withActaXcopyParasCommentsTasksMessagesAttachements_getNull =
  (): Task_withActaXcopyParasCommentsTasksMessagesAttachements => {
    return {
      cIssuedBy: null,
      cTimeStamp: null,
      dIssuedBy: null,
      dtimestamp: null,
      _id: null,
      _key: null,
      linkedActa: null,
      linkedXcopy: null,
      luIssuedBy: null,
      luTimeStamp: null,
      taskAssignedTo: User_getNull(),
      taskData: TaskData_getNull(),
      taskDescription: null,
      taskDueDate: null,
      taskName: null,
      taskState: null,
      type: TaskTypeEnum.TASK,
      linkedAttachmentsCount: null,
      linkedAttachments: [],
      linkedMessagesCount: null,
      linkedMessages: [],
      linkedPara: [],
      linkedComment: [],
      linkedTasks: [],
    };
  };

export const validationSchemeTask = z.object({
  taskName: z
    .string({
      required_error: "required",
    })
    .min(10, { message: "Too short" })
    //.min(10, t("tperrormsg:taskedit.tasknameshort"))
    .max(15, { message: "moc dlouhý" }),
  taskDescription: z.string().max(250),
  // taskDueDate: z.string();
  // taskState: z.
  // lastName: z.string().min(2).max(50),
  // phone: z.string().min(5).max(35),
  // email: z.string().email(),
  // testName: z.string().email().min(1).max(50),
});

export const Task_withActaXcopy_clone = (taskToClone: Task_withActaXcopy): Task_withActaXcopy => {
  if (taskToClone == undefined) {
    return Task_withActaXcopy_getNull();
  }
  return {
    _id: taskToClone._id,
    _key: taskToClone._key,
    cIssuedBy: taskToClone.cIssuedBy,
    dIssuedBy: taskToClone.dIssuedBy,
    cTimeStamp: taskToClone.cTimeStamp,
    dtimestamp: taskToClone.dtimestamp,
    linkedActa: taskToClone.linkedActa,
    linkedXcopy: taskToClone.linkedXcopy,
    luIssuedBy: taskToClone.luIssuedBy,
    luTimeStamp: taskToClone.luTimeStamp,
    taskAssignedTo: User_clone(taskToClone.taskAssignedTo),
    taskData: TaskData_clone(taskToClone.taskData),
    taskDescription: taskToClone.taskDescription,
    taskDueDate: taskToClone.taskDueDate,
    taskName: taskToClone.taskName,
    taskState: taskToClone.taskState,
    type: taskToClone.type,
  };
};

export const Task_withActaXcopy_compare = (
  taskWXcopyOrigin: Task_withActaXcopy,
  taskWXcopyToCompare: Task_withActaXcopy
): boolean => {
  window.console.log("COMAPRE Task_withActaXcopy_compare start", taskWXcopyOrigin, taskWXcopyToCompare);
  window.console.log("COMAPRE Task_withActaXcopy_compare start _id", taskWXcopyOrigin._id, taskWXcopyToCompare._id);
  if (taskWXcopyOrigin._id !== taskWXcopyToCompare._id) return false;

  window.console.log("COMAPRE Task_withActaXcopy_compare start _key", taskWXcopyOrigin._key, taskWXcopyToCompare._key);
  if (taskWXcopyOrigin._key !== taskWXcopyToCompare._key) return false;

  window.console.log(
    "COMAPRE Task_withActaXcopy_compare start cIssuedBy",
    taskWXcopyOrigin.cIssuedBy,
    taskWXcopyToCompare.cIssuedBy
  );
  if (taskWXcopyOrigin.cIssuedBy !== taskWXcopyToCompare.cIssuedBy) return false;

  window.console.log(
    "COMAPRE Task_withActaXcopy_compare start cTimeStamp",
    taskWXcopyOrigin.cTimeStamp,
    taskWXcopyToCompare.cTimeStamp
  );
  if (taskWXcopyOrigin.cTimeStamp !== taskWXcopyToCompare.cTimeStamp) return false;

  window.console.log(
    "COMAPRE Task_withActaXcopy_compare start dIssuedBy",
    taskWXcopyOrigin.dIssuedBy,
    taskWXcopyToCompare.dIssuedBy
  );
  if (taskWXcopyOrigin.dIssuedBy !== taskWXcopyToCompare.dIssuedBy) return false;

  window.console.log(
    "COMAPRE Task_withActaXcopy_compare start dtimestamp",
    taskWXcopyOrigin.dtimestamp,
    taskWXcopyToCompare.dtimestamp
  );
  if (taskWXcopyOrigin.dtimestamp !== taskWXcopyToCompare.dtimestamp) return false;

  window.console.log(
    "COMAPRE Task_withActaXcopy_compare start luIssuedBy",
    taskWXcopyOrigin.luIssuedBy,
    taskWXcopyToCompare.luIssuedBy
  );
  if (taskWXcopyOrigin.luIssuedBy !== taskWXcopyToCompare.luIssuedBy) return false;

  window.console.log(
    "COMAPRE Task_withActaXcopy_compare start luTimeStamp",
    taskWXcopyOrigin.luTimeStamp,
    taskWXcopyToCompare.luTimeStamp
  );
  if (taskWXcopyOrigin.luTimeStamp !== taskWXcopyToCompare.luTimeStamp) return false;

  window.console.log(
    "COMAPRE Task_withActaXcopy_compare start taskDescription",
    taskWXcopyOrigin.taskDescription,
    taskWXcopyToCompare.taskDescription
  );
  if (taskWXcopyOrigin.taskDescription !== taskWXcopyToCompare.taskDescription) return false;

  window.console.log(
    "COMAPRE Task_withActaXcopy_compare start taskDueDate",
    taskWXcopyOrigin.taskDueDate,
    taskWXcopyToCompare.taskDueDate
  );
  if (taskWXcopyOrigin.taskDueDate !== taskWXcopyToCompare.taskDueDate) return false;

  window.console.log(
    "COMAPRE Task_withActaXcopy_compare start taskName",
    taskWXcopyOrigin.taskName,
    taskWXcopyToCompare.taskName
  );
  if (taskWXcopyOrigin.taskName !== taskWXcopyToCompare.taskName) return false;

  window.console.log(
    "COMAPRE Task_withActaXcopy_compare start taskState",
    taskWXcopyOrigin.taskState,
    taskWXcopyToCompare.taskState
  );
  if (taskWXcopyOrigin.taskState !== taskWXcopyToCompare.taskState) return false;
  window.console.log("COMAPRE Task_withActaXcopy_compare start type", taskWXcopyOrigin.type, taskWXcopyToCompare.type);
  if (taskWXcopyOrigin.type !== taskWXcopyToCompare.type) return false;

  // window.console.log("COMAPRE Task_withActaXcopy_compare 2", true);
  window.console.log(
    "COMAPRE Task_withActaXcopy_compare start linkedActa",
    taskWXcopyOrigin.linkedActa,
    taskWXcopyToCompare.linkedActa
  );
  if (taskWXcopyOrigin.linkedActa?._key !== taskWXcopyToCompare.linkedActa?._key) return false;
  window.console.log(
    "COMAPRE Task_withActaXcopy_compare start linkedXcopy",
    taskWXcopyOrigin.linkedXcopy,
    taskWXcopyToCompare.linkedXcopy
  );
  if (taskWXcopyOrigin.linkedXcopy?._key !== taskWXcopyToCompare.linkedXcopy?._key) return false;

  //sum structures
  window.console.log(
    "COMAPRE Task_withActaXcopy_compare start taskAssignedTo",
    taskWXcopyOrigin.taskAssignedTo,
    taskWXcopyToCompare.taskAssignedTo
  );
  if (User_compare(taskWXcopyOrigin.taskAssignedTo, taskWXcopyToCompare.taskAssignedTo) !== true) return false;
  window.console.log(
    "COMAPRE Task_withActaXcopy_compare start taskData",
    taskWXcopyOrigin.taskData,
    taskWXcopyToCompare.taskData
  );
  if (TaskData_compare(taskWXcopyOrigin.taskData, taskWXcopyToCompare.taskData) !== true) return false;

  // window.console.log("COMAPRE Task_withActaXcopy_compare 3", true);
  return true;
};

export const convert_Task_withActaXcopyParasCommentsTasksMessagesAttachements = (
  item: Task,
  outBase?: Task_withActaXcopyParasCommentsTasksMessagesAttachements
): Task_withActaXcopyParasCommentsTasksMessagesAttachements => {
  const out = outBase === undefined ? Task_withActaXcopyParasCommentsTasksMessagesAttachements_getNull() : outBase;
  if (item === undefined || item === null) return out;
  window.console.log(`CONVERT`, item);
  out._id = item._id;
  out._key = item._key;
  out.cIssuedBy = item.cIssuedBy;
  out.cTimeStamp = item.cTimeStamp;
  out.dIssuedBy = item.dIssuedBy;
  out.dtimestamp = item.dtimestamp;
  out.luIssuedBy = item.luIssuedBy;
  out.luTimeStamp = item.luTimeStamp;
  out.taskAssignedTo = item.taskAssignedTo;
  out.taskData = item.taskData;
  out.taskDescription = item.taskDescription;
  out.taskDueDate = item.taskDueDate;
  out.taskName = item.taskName;
  out.taskState = item.taskState;
  out.type = item.type;

  return out;
};

export const convert_Task_withActaXcopy2Task_withActaXcopyParasCommentsTasksMessagesAttachements = (
  item: Task_withActaXcopy,
  outBase?: Task_withActaXcopyParasCommentsTasksMessagesAttachements
): Task_withActaXcopyParasCommentsTasksMessagesAttachements => {
  const out = outBase === undefined ? Task_withActaXcopyParasCommentsTasksMessagesAttachements_getNull() : outBase;
  if (item === undefined || item === null) return out;
  window.console.log(`CONVERT`, item);
  out._id = item._id;
  out._key = item._key;
  out.cIssuedBy = item.cIssuedBy;
  out.cTimeStamp = item.cTimeStamp;
  out.dIssuedBy = item.dIssuedBy;
  out.dtimestamp = item.dtimestamp;
  out.linkedActa = item.linkedActa;
  out.linkedXcopy = item.linkedXcopy;
  out.luIssuedBy = item.luIssuedBy;
  out.luTimeStamp = item.luTimeStamp;
  out.taskAssignedTo = item.taskAssignedTo;
  out.taskData = item.taskData;
  out.taskDescription = item.taskDescription;
  out.taskDueDate = item.taskDueDate;
  out.taskName = item.taskName;
  out.taskState = item.taskState;
  out.type = item.type;

  return out;
};

export const convert_Task_withActaXcopyParasCommentsTasksMessagesAttachements2Task_withActaXcopy = (
  item: Task_withActaXcopyParasCommentsTasksMessagesAttachements,
  outBase?: Task_withActaXcopy
): Task_withActaXcopy => {
  const out = outBase === undefined ? Task_withActaXcopy_getNull() : outBase;
  if (item === undefined || item === null) return out;
  window.console.log(`CONVERT`, item);
  out._id = item._id;
  out._key = item._key;
  out.cIssuedBy = item.cIssuedBy;
  out.cTimeStamp = item.cTimeStamp;
  out.dIssuedBy = item.dIssuedBy;
  out.dtimestamp = item.dtimestamp;
  out.linkedActa = item.linkedActa;
  out.linkedXcopy = item.linkedXcopy;
  out.luIssuedBy = item.luIssuedBy;
  out.luTimeStamp = item.luTimeStamp;
  out.taskAssignedTo = item.taskAssignedTo;
  out.taskData = item.taskData;
  out.taskDescription = item.taskDescription;
  out.taskDueDate = item.taskDueDate;
  out.taskName = item.taskName;
  out.taskState = item.taskState;
  out.type = item.type;

  return out;
};
