/* eslint-disable no-async-promise-executor */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { log } from "missionlog";
import { urlActaListGet } from "../api/apilinks";
import { httpGetAuth, HttpResponse } from "../api/httpAPI";
import { actaListOK, actaListResponse } from "../api/schema/getActaList";
import limaActasStore from "../store/limaActasStore";
import limaLoadeStore, { LoaderItemEnd } from "../store/limaLoaderStore";
import { limaLogTag } from "./limaLog";

export const limaActaListGet = (limit?: number): Promise<actaListOK[]> => {
  return new Promise(async (resolve, reject): Promise<void> => {
    let response: HttpResponse<actaListResponse>;
    log.info(limaLogTag.LimaStore, "limaActaListGet: starts");
    const loaderID = limaLoadeStore.add("lima actalist get");

    try {
      // response = await httpGet<actaListResponse>(urlActaListGet(userId, limit), loaderID.ac);
      response = await httpGetAuth<actaListResponse>(urlActaListGet(limit), loaderID.ac);
      if ("parsedBody" in response && "actalist" in response.parsedBody) {
        log.info(limaLogTag.LimaStore, "limaActaListGet: looks like get some data ", response.parsedBody);
        limaActasStore.setActaList(response.parsedBody["actalist"]);
        limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
        resolve(response.parsedBody["actalist"]);
      } else {
        log.error(limaLogTag.LimaStore, "limaActaListGet: no data ", response);
        limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
        resolve([]);
      }
    } catch (error) {
      log.error(limaLogTag.LimaActaList, "limaActaListGet: some error ", error);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      reject;
    } finally {
      // limaLoadeStore.remove(loaderID.k);
    }
  });
};
