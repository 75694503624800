import { getTheme, IconButton, IIconProps, ITheme, mergeStyleSets, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { log } from "missionlog";
import { FC, useEffect, useState } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import { limaLogTag } from "../limaCommon/limaLog";
import { LimaEditItemBaseV4 } from "../taskpane/uiv2/Tasks/LimaEditItemBaseV4";
import { TaskListFilterItem } from "../taskpane/uiv2/Tasks/TaskListV4";
import { LIMAENUM_AccesLicPermIdent } from "../limaCommon/limaPermissions";
import limaPermissionStore from "../store/limaPermissionStore";

// export default LimaHideableSection;
type LimaHideableSection2Props = {
  name: string;
  addEnabled?: boolean;
  addCallback?: () => void;

  headerInfo?: JSX.Element;
  headerCount?: string;
  headerCountIsLoading?: boolean;
  headerDefaultContentOpen?: boolean;
  passShowHideToContent?: boolean;
  isDisabled?: boolean;
  onHideVisibleElements?: React.ReactNode;
  editDetail?: React.ReactNode;
  editDetailOpen?: boolean;
  editDetailEnableStore?: boolean;
  editPanelhideFooter?: boolean;
  editDetailSaveLbl?: string;
  editDetailCancelLbl?: string;
  onDetailBackCallBack?: () => void;
  onDetialSaveUpdateCallBack?: () => void;
  editPanelName?: string;

  children?: React.ReactNode;
  setVisible?: boolean;

  filters?: TaskListFilterItem[];

  //Access
  accLicPermIdent?: LIMAENUM_AccesLicPermIdent;
  dontShowOnNoLic?: boolean;
};

const theme: ITheme = getTheme();
// const { palette, semanticColors, fonts } = theme;
const { fonts } = theme;

const classNames = mergeStyleSets({
  contentWrapper: {
    width: "100%",
    // backgroundColor: "#f5f7fa",
    paddingTop: 10,
    borderTop: "solid 1px #ddd",
    paddingBottom: 10,
    borderBottom: "solid 1px #ddd",
  },
  contentHeader: {
    // flexFlow: "row",
    alignItems: "center",
  },
  contentHeaderShowHide: {
    float: "left",
  },
  contentHeaderName: {
    float: "left",
    height: 32,
    lineHeight: 32,
  },
  contentHeaderInfo: {
    float: "left",
  },
  contentHeaderCount: {
    float: "left",
    /*backgroundColor: "#d8d8d8",*/
    height: "18px !IMPORTANT",
    lineHeight: "18PX !IMPORTANT",
    textAlign: "center",
    minWidth: 20,
    margin: "5px !IMPORTANT",
    marginLeft: "5px !IMPORTANT",
    /*"&& span": {
      color: "#FFFFFF",
    },*/
    font: "normal normal 600 14px/21px Segoe UI",
    /*border: "0.5px solid var(--primary-medium-emphasis) #B2CCE1",*/
    border: "0.5px solid #B2CCE1",
    /*border: "0.5px solid #B2CCE1",*/
    borderRadius: 9,
    opacity: 1,
  },
  contentHeaderAdd: {
    // float: "right",
    marginLeft: "auto !IMPORTANT",
  },
  contentContent: {},
  hidablenamewrapper: {
    paddingLeft: 5,
    paddingRight: 5,
    cursor: "pointer",
  },
  hidablenameIcon: {
    marginRight: 10,
    marginLeft: 3,
    width: 24,
    height: 24,
    verticalAlign: "text-bottom",
    icon: { color: "#1f88d8", fontSize: 36 },
    border: "0.5px solid #0078D44D",
    borderRadius: "2px",
    opacity: "1",
  },
  addIcon: {
    marginRight: 10,
    marginLeft: 10,
    verticalAlign: "text-bottom",
    width: 24,
    height: 24,
    icon: { color: "#1f88d8", fontSize: 36 },
  },
  hidablenameName: [
    fonts.large,
    {
      //color: "#333333",
      /*fontWeight: "600",*/
      //font: "normal normal 600 14px/21px Segoe UI",
      //letterSpacing: 0,
    },
  ],
  hidablenameState: {
    marginLeft: 6,
    color: "darkgray",
  },
});

const showIcon: IIconProps = { iconName: "ChevronDown" };
const hideIcon: IIconProps = { iconName: "ChevronUp" };
// const backIcon: IIconProps = { iconName: "ChevronLeftMed" };
const addIcon: IIconProps = { iconName: "Add" };

export const LimaHideableSection2: FC<LimaHideableSection2Props> = ({
  name,
  headerDefaultContentOpen,
  addEnabled,
  children,
  passShowHideToContent,
  onHideVisibleElements,
  editDetailOpen,
  editPanelName,
  editDetail: detail,
  editDetailEnableStore,
  editDetailSaveLbl,
  editDetailCancelLbl,
  editPanelhideFooter,
  onDetailBackCallBack,
  onDetialSaveUpdateCallBack,
  headerInfo,
  // setVisible,
  headerCount,
  headerCountIsLoading,
  addCallback,
  filters,
  isDisabled,
  //acess items
  accLicPermIdent,
  dontShowOnNoLic,
}: LimaHideableSection2Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showContent, setShowContent] = useState<boolean>(headerDefaultContentOpen ? true : false);
  const [showEditDetail, setShowEditDetail] = useState<boolean>(false);
  // const [showEditSaveLbl, setShowEditSaveLbl] = useState<string>("");
  const { t } = useTranslation(["tpmain"]);
  const isAccesable =
    accLicPermIdent === undefined ? true : limaPermissionStore.checkAccLicenceAndPermissions(accLicPermIdent);
  //---------------
  // Reactios
  //----------------
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    log.debug(limaLogTag.LimaHideableSection2, "UEF on mount");
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    log.info(limaLogTag.LimaHideableSection2, "UEF change detailOpen", editDetailOpen, filters);
    if (editDetailOpen !== undefined && showEditDetail !== editDetailOpen) {
      log.info(limaLogTag.LimaHideableSection2, "UEF change detailOpen to ", editDetailOpen);
      setShowEditDetail(editDetailOpen);
    }
  }, [editDetailOpen]);

  //----------------
  // Handlers
  //-----------------

  //----------------
  // Renders
  //-----------------

  if (isAccesable === false) {
    if (dontShowOnNoLic !== undefined && dontShowOnNoLic === true) {
      return <></>;
    } else {
      return (
        <Stack className={classNames.contentWrapper}>
          <Stack.Item key={"item_header"}>
            <span className={classNames.hidablenameName}>{name}</span>
          </Stack.Item>
        </Stack>
      );
    }
  }

  return (
    <Stack className={classNames.contentWrapper}>
      <Stack.Item key={"item_header"}>
        <Stack horizontal className={classNames.contentHeader}>
          <Stack.Item key={"item_header_showhide"} className={classNames.contentHeaderShowHide}>
            <IconButton
              // iconProps={showEditDetail ? backIcon : !showContent ? showIcon : hideIcon}
              iconProps={!showContent ? showIcon : hideIcon}
              className={classNames.hidablenameIcon}
              title={
                showContent ? `(${t("tpmain:hidablesection.tohideLbl")})` : `(${t("tpmain:hidablesection.toshowLbl")})`
              }
              disabled={isDisabled}
              onClick={() => {
                log.info(limaLogTag.LimaHideableSection2, "showHide On click: ", showContent);
                log.info(
                  limaLogTag.LimaHideableSection2,
                  "showHide On click: ",
                  passShowHideToContent !== undefined && passShowHideToContent === true
                );
                log.info(
                  limaLogTag.LimaHideableSection2,
                  "showHide On click: ",
                  (passShowHideToContent === undefined || passShowHideToContent === false) && showContent == true
                );
                setShowContent(!showContent);
              }}
            />
          </Stack.Item>
          <Stack.Item key={"item_header_name"} className={classNames.contentHeaderName}>
            <span className={classNames.hidablenameName}>{name}</span>
          </Stack.Item>
          {headerInfo !== undefined && (
            <Stack.Item key={"item_header_info"} className={classNames.contentHeaderInfo}>
              info
            </Stack.Item>
          )}
          {headerCount !== undefined &&
            (headerCountIsLoading !== undefined && headerCountIsLoading === true ? (
              <Spinner size={SpinnerSize.xSmall} />
            ) : (
              <Stack.Item key={"item_header_count"} className={classNames.contentHeaderCount}>
                <span>{headerCount}</span>
              </Stack.Item>
            ))}
          {addEnabled !== undefined && addEnabled === true && (
            <Stack.Item key={"item_header_add"} className={classNames.contentHeaderAdd}>
              <IconButton
                iconProps={addIcon}
                className={classNames.addIcon}
                title={t("tpmain:hidablesection.addBtnLbl")}
                onClick={() => {
                  log.info(limaLogTag.LimaHideableSection2, "addCallback On click: ");
                  if (addCallback !== undefined) addCallback();
                }}
              />
            </Stack.Item>
          )}
        </Stack>
      </Stack.Item>
      {onHideVisibleElements !== undefined && showContent === false && (
        <Stack.Item key={"item_Content_visibleOnHide"} className={classNames.contentContent}>
          {React.Children.map(onHideVisibleElements, (child) => {
            if (React.isValidElement(child)) {
              return {
                ...child,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                props: {
                  ...child.props,
                  showComponent: showContent,
                },
              };
            }
            return child;
          })}
        </Stack.Item>
      )}
      {((passShowHideToContent !== undefined && passShowHideToContent === true) ||
        ((passShowHideToContent === undefined || passShowHideToContent === false) && showContent === true)) && (
        <Stack.Item key={"item_Content"} className={classNames.contentContent}>
          {React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
              return {
                ...child,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                props: {
                  ...child.props,
                  showComponent: showContent,
                },
              };
            }
            return child;
          })}
        </Stack.Item>
      )}
      <LimaEditItemBaseV4
        detail={detail}
        open={showEditDetail}
        panelName={editPanelName}
        editDetailEnableStore={editDetailEnableStore}
        hideFooter={editPanelhideFooter}
        saveLbl={editDetailSaveLbl}
        cancelLbl={editDetailCancelLbl}
        onSaveUpdateCallBack={() => {
          log.info(limaLogTag.LimaHideableSection2, "LimaEditItemBaseV4 onSaveUpdateCallBack ....", filters);
          if (onDetialSaveUpdateCallBack !== undefined) onDetialSaveUpdateCallBack();
        }}
        onDetialDismissCallBack={() => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          log.info(limaLogTag.LimaHideableSection2, "LimaEditItemBaseV4 onDetialDismissCallBack ....", filters);
          setShowEditDetail(false);
          if (onDetailBackCallBack !== undefined) onDetailBackCallBack();
        }}
      />
    </Stack>
  );
};
