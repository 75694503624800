import * as React from "react";
//import { Component } from 'react';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { CommandBar } from "office-ui-fabric-react";
//import { PropertiesComponent } from './whichever-file-or-module';
import FileOpen from "./FileOpen";
import FileSave from "./FileSave";
import Routes from "./routes";
import CommandBarItems from "./commandBarItems";

const InfoMain = (): JSX.Element => {
  //class App extends Component {

  return (
    <div>
      <Router>
        <React.Fragment>
          <CommandBar items={CommandBarItems.menu} farItems={CommandBarItems.farItems} />
          <Switch>
            <Route path={`${Routes.FileMenuOpenRoute}`} component={FileOpen} />
            <Route path={`${Routes.FileMenuImportRoute}`} component={ImportFile} />
            <Route
              path={`${Routes.FileMenuSaveRoute}`}
              render={(props) => <FileSave {...props} asNew={true} message="toto je test msg" />}
            />
            <Route path={`${Routes.userMenuListRoute}`} component={ListUsers} />
            <Route path={`${Routes.userMenuAddRoute}`} component={AddUser} />
          </Switch>
        </React.Fragment>
      </Router>
    </div>
  );
};
//<Route path={`/${Routes.Properties}`} component={PropertiesComponent} />

function ImportFile() {
  return <h2>Import File</h2>;
}

function ListUsers() {
  return <h2>List Users</h2>;
}

function AddUser() {
  return <h2>Add Users</h2>;
}

export default InfoMain;
