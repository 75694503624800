import * as React from 'react';
import { FunctionComponent } from 'react';


type ItemProp = {
    message: string,
    asNew: boolean
}
const FileSave: FunctionComponent<ItemProp> = ({ asNew, message}: ItemProp) => {

    return (
        <section className="ms-welcome__progress ms-u-fadeIn500">
            <h2>Save File</h2><br/>
                 {message}
                 {asNew?"SAVE as NEW":"SAVE Into orogin"}
        </section>
    );

}
export default FileSave;