import * as React from "react";
import { FunctionComponent, useEffect } from "react";
// import { Separator } from "office-ui-fabric-react";

// import { log } from "missionlog";
//import JudgeItem from "./JudgeItem";
import Keycloak from "keycloak-js";
import { KCTokens } from "../../../types/AccesTokens";
import { DIALOGMESSAGETYPE_ENUM, DialogParentMessage, DIALOGTYPE_ENUM } from "../../../types/dialogData";

// log.init({ JudgeHolder: "JudgeHolder" });

const _kc = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL, //"http://localhost:8180/auth",
  realm: process.env.REACT_APP_KEYCLOAK_REALM, //"master",
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT, //"limacta-client",
});

//.init({ onLoad: "login-required" })
// onLoad: "check-sso",
// silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
// pkceMethod: "S256",
const initKeycloak = (onAuthenticatedCallback) => {
  window.console.log(`initKeycloak: keycloak init`);
  _kc
    .init({
      onLoad: "login-required",
      checkLoginIframe: false,

      // onLoad: "check-sso",
      // enableLogging: true,
      // pkceMethod: "S256",
      // silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
    })
    .then((authenticated) => {
      window.console.log("initKeycloak: Then", authenticated);
      if (!authenticated) {
        window.console.log("initKeycloak: user is not authenticated..!");
        // window.location.reload();
      } else {
        window.console.log("initKeycloak: user is authenticated..!");
      }

      window.console.log("initKeycloak: callback");
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      onAuthenticatedCallback(_kc);
    })
    .catch((reason) => {
      window.console.log("initKeycloak: catch", reason);
    })
    .finally(() => {
      window.console.log("initKeycloak: finally");
    });
};

const LoginHolder: FunctionComponent = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  // const [keycloak, setKeycloak] = useState(null);
  // const [authenticated, setAuthenticated] = useState(false);

  window.console.log(`starting...... `);

  // const keycloak = new Keycloak({
  //   url: "http://localhost:8180/auth",
  //   realm: "master",
  //   clientId: "limacta-client",
  // });

  const authenticatedCallBack = (keycloakClient: Keycloak) => {
    window.console.log(`base keycloak test: authenticated`);
    window.console.log(`hey ref:`, keycloakClient.refreshToken);
    window.console.log(`hey ath:`, keycloakClient.token);
    // window.console.log(`hey ath:`, keycloakClient.profile.firstName);

    const tokenToSend: KCTokens = {
      refreshToken: keycloakClient.refreshToken,
      token: keycloakClient.token,
      idtoken: keycloakClient.idToken,
      // clientID: keycloakClient.clientId,
      // userEmail: "",
      // userEmail: keycloakClient.   .profile.email ? keycloakClient.profile.email : "",
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      userID: keycloakClient.idTokenParsed.limactauserid,
    };
    const dataTosend: DialogParentMessage = {
      dialogType: DIALOGTYPE_ENUM.LOGIN,
      messageType: DIALOGMESSAGETYPE_ENUM.DATA,
      messageData: tokenToSend,
    };
    // Office.context.ui.messageParent(true.toString());
    Office.context.ui.messageParent(JSON.stringify(dataTosend));
  };

  useEffect(() => {
    // const keycloak = new Keycloak("/keycloak.json");
    window.console.log(`starting...... UEF`);
    window.console.log(`UEF: keycloak init`);
    initKeycloak(authenticatedCallBack);
  }, []);

  return (
    <>
      Login processing
      {/* <Separator>Login</Separator> */}
      {/* <> */}
      {/* <JudgeItem actParItem={ActParDetail} /> */}
      {/* <JudgeItem /> */}
      {/* </> */}
    </>
  );
};

export default LoginHolder;
