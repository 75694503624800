import { log } from "missionlog";
import { makeObservable, observable, action, computed } from "mobx";
import moment = require("moment");
import { WORDITEMTYPE } from "../api/apilinks";
import { actaListOK } from "../api/schema/getActaList";
import { limaActaSetFavorite } from "../limaCommon/limaActaCallHandler";
import { limaActaListGet } from "../limaCommon/limaActaList";
import { limaLogTag } from "../limaCommon/limaLog";
import { udpateVisilibity, UpdateVisibilityTypes } from "../limaCommon/limaVisibilityCalls";
// import { XCOPY_TYPE } from "../types/Task";
import limaLoadeStore, { LoaderItemEnd } from "./limaLoaderStore";
import limaStore, { OPENEDFILETYPE_ENUM } from "./limaStore";
import { XCOPY_TYPE } from "../types/Task";
// import { XCOPY_TYPE } from "../types/Task";
// import { ActaShort } from "../types/Acta";

class LimaActasStore {
  actaList: actaListOK[] = [];
  lastDBReadDate: string | null = null;
  isLoading: boolean = false;
  //constructor(private actaList: actaListOK[] = []) {}

  constructor() {
    makeObservable(this, {
      actaList: observable,

      setActaList: action,
      setActaListItem: action,
      addActa: action,
      resetList: action,
      removeFromList: action,

      getSharedActasCount: computed,
      getFavoritActasCount: computed,
      getActasCount: computed,
    });
  }

  addActa(actaItem: actaListOK) {
    this.actaList.push(actaItem);
  }

  setActaList(actaItem: actaListOK[]) {
    this.actaList = actaItem;
  }

  setActaListItem(newActaListItem: actaListOK) {
    log.info(limaLogTag.LimaActasStore, `setActaListItem: update`, this.actaList);
    this.actaList = this.actaList.map((actaListItem) =>
      actaListItem.id === newActaListItem.id ? newActaListItem : actaListItem
    );
    this.lastDBReadDate = moment().toString();
    log.info(limaLogTag.LimaActasStore, `setActaListItem: update`, this.actaList);
  }

  resetList() {
    this.actaList = [];
  }

  /**
   * Get act for specified actaId
   * @param actaId
   * @returns {actaListOK | null} null if no acta found
   */
  getActa(actaId: string): actaListOK | null {
    const acta = this.actaList.filter((item: actaListOK) => item.id === actaId);
    if (acta.length == 0) return null;
    else return acta[0];
  }

  async loadActas() {
    const loaderID = limaLoadeStore.add("getting acta list");
    this.isLoading = true;
    // limaActaListGet(limaStore.userId)
    return limaActaListGet()
      .then((data: actaListOK[] | void) => {
        log.info(limaLogTag.FileOpen, "getActaList: limaActaListGet finish", data);
        if (data) {
          this.setActaList(data);
          // setFileItems(data);
        }
        limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      })
      .catch(() => {
        log.error(limaLogTag.FileOpen, "getActaList: limaActaListGet finish with error");
        limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  setFavorite(acta: actaListOK, favorite: boolean) {
    // limaActaListGet(limaStore.userId)
    limaActaSetFavorite(acta.id, favorite)
      .then((data: boolean) => {
        log.info(limaLogTag.LimaActasStore, "setFavorite: finish", data);
        //acta.favorite = bool;
        const updateItem: actaListOK = { ...acta, favorite: favorite };
        this.setActaListItem(updateItem);
      })
      .catch(() => {
        log.error(limaLogTag.LimaActasStore, "getActaList: limaActaListGet finish with error");
      });
  }

  removeFromList = async (acta: actaListOK) => {
    log.info(limaLogTag.LimaActasStore, `remove: actaId['${acta.id}'] edgeId ['${acta.edgeId}']`);
    await udpateVisilibity(
      "dUser/" + limaStore.userId,
      "dActa/" + acta.id,
      null,
      limaStore.userId,
      null,
      new Date().toISOString(),
      WORDITEMTYPE.acta,
      UpdateVisibilityTypes.COMMON,
      acta.edgeId
    ).then(() => {
      // const updatedItems = fileItems.filter((i) => i.edgeId !== edgeId);
      //setActas(updatedItems);
      //setFileItems(updatedItems);
      this.actaList = this.actaList.filter((item) => item.id !== acta.id);
    });
  };

  //---------------------------------------------------
  // Computed functions
  //---------------------------------------------------

  get getActasCount(): number {
    return this.actaList.length;
  }

  get getFavoritActasCount(): number {
    if (this.actaList.length === 0) return 0;
    return this.actaList.filter((item) => item.favorite === true).length;
  }

  get getSharedActasCount(): number {
    if (this.actaList.length === 0) return 0;
    return this.actaList.filter((item) => item.isOwner !== true).length;
  }

  //---------------------------------------------------
  // Other functions
  //---------------------------------------------------

  /**
   * just simple call to check existence read from DB
   */
  checkLastRead = async () => {
    log.info(limaLogTag.LimaUserStore, `checkLastRead: ${String(this.lastDBReadDate === null)} `, this.lastDBReadDate);
    if (this.lastDBReadDate === null && this.isLoading === false) {
      await this.loadActas();
    }
  };

  /**
   * on logout clear
   */
  onLogoutClear(): void {
    this.setActaList([]);
    this.lastDBReadDate = null;
  }

  isOwnerActaId(actaId: string) {
    log.info(limaLogTag.LimaActasStore, `isOwner: am I ownere for [${actaId}] `, this.actaList, limaStore.userId);
    if (actaId != null) {
      const found = this.actaList.find((item: actaListOK) => item.id === actaId && item.ownerKey === limaStore.userId);
      // log.info(limaLogTag.LimaActasStore, `isOwner:  search`, found);

      if (found !== undefined) {
        log.info(limaLogTag.LimaActasStore, `isOwner:  I am ownere for [${actaId}] - TRUE`);
        return true;
      }
    }
    log.info(limaLogTag.LimaActasStore, `isOwner:  I am NOT ownere for [${actaId}] - FALSE`);
    return false;
  }

  isOwnerMainXcopyId(mainXcopyId: string) {
    log.info(limaLogTag.LimaActasStore, `isOwnerMainXcopyId: am I ownere for [${mainXcopyId}] `, this.actaList);
    if (mainXcopyId != null) {
      const found = this.actaList.find((item: actaListOK) => item.mainXcopy === mainXcopyId);
      // log.info(limaLogTag.LimaActasStore, `isOwner:  search`, found);

      if (found !== undefined) {
        log.info(limaLogTag.LimaActasStore, `isOwnerMainXcopyId:  I am ownere for [${mainXcopyId}] - TRUE`);
        return true;
      }
    }
    log.info(limaLogTag.LimaActasStore, `isOwnerMainXcopyId:  I am NOT ownere for [${mainXcopyId}] - FALSE`);
    return false;
  }

  getMainCopyIdForActaId(actaId: string) {
    const foundAtcas = this.actaList.filter((item) => item.id === actaId);
    if (foundAtcas.length > 0) return foundAtcas[0].mainXcopy;
    else return null;
  }

  getActaIdFocUnknowXcopy(xcopyId: string) {
    let foundAtcaId: string | null = null;
    foundAtcaId = this.getActaIdForXcopyIdAndXcopyType(xcopyId, OPENEDFILETYPE_ENUM.MAIN_COPY);
    if (foundAtcaId !== null) return foundAtcaId;
    foundAtcaId = this.getActaIdForXcopyIdAndXcopyType(xcopyId, OPENEDFILETYPE_ENUM.COPY);
    if (foundAtcaId !== null) return foundAtcaId;
    return null;
  }

  getActaIdForXcopyIdAndXcopyType(xcopyId: string, xcopyType: OPENEDFILETYPE_ENUM) {
    let foundAtcas: actaListOK[] = [];
    if (xcopyType === OPENEDFILETYPE_ENUM.MAIN_COPY) {
      foundAtcas = this.actaList.filter((item) => item.id === xcopyId);
    } else if (xcopyType === OPENEDFILETYPE_ENUM.COPY) {
      foundAtcas = this.actaList.filter((item) => item.copiesList.includes(xcopyId));
    }
    if (foundAtcas.length > 0) return foundAtcas[0].id;
    else return null;
  }

  /**
   * Return actaId for XcopyID and type
   *
   * @param xcopyId
   * @param xcopyType
   * @param returnFull optional definition to get back full ActaInfo
   * @returns {string | null | actaListOK} actaId of xcopy or null if not found anything
   */
  getActaIdForXcopyIdAndXcopyType2(
    xcopyId: string,
    xcopyType: XCOPY_TYPE,
    returnFull?: boolean
  ): string | null | actaListOK {
    let foundAtcas: actaListOK[] = [];
    if (xcopyType === XCOPY_TYPE.MAINXCOPY) {
      foundAtcas = this.actaList.filter((item) => item.id === xcopyId);
    } else if (xcopyType === XCOPY_TYPE.FULLXCOPY || xcopyType === XCOPY_TYPE.TRIMXCOPY) {
      foundAtcas = this.actaList.filter((item) => item.copiesList.includes(xcopyId));
    }
    if (foundAtcas.length > 0) {
      if (returnFull !== undefined && returnFull === true) {
        return foundAtcas[0];
      }
      return foundAtcas[0].id;
    } else return null;
  }

  /**
   * Return Acta name for actaId
   * @param actaId
   * @returns
   */
  getActaNameActaId(actaId: string) {
    const foundAtcas = this.actaList.filter((item) => item.id === actaId);
    if (foundAtcas.length > 0) return foundAtcas[0].name;
    else return null;
  }
}

const limaActasStore = new LimaActasStore();
export default limaActasStore;
