import {
  FontIcon,
  getFocusStyle,
  getTheme,
  IconButton,
  IIconProps,
  IStackTokens,
  ITheme,
  mergeStyleSets,
  Persona,
  PersonaSize,
  Stack,
} from "@fluentui/react";
import { FunctionComponent, useState } from "react";
import React = require("react");
import { UseMutateAsyncFunction } from "react-query";
import { getInitialsGlued } from "../../../../../limaCommon/limaStringTools";
// import { LimaNameInitals } from "../../../../../limaComponents/LimaNameInitals";
import { User2User, USER2USER_STATE } from "../../../../../types/User2User";
import { AcceptLinkingObject, CALLUSERLINK, TOorFROM } from "../../../../Menu/Users/UserLinking/User2UserLinkComponent";

interface User2UserLinkItemProps {
  item: User2User;
  type: TOorFROM;
  onCallBack?: UseMutateAsyncFunction<User2User[], unknown, AcceptLinkingObject, { id: number }>;
}

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 0,
  // maxWidth: "95%",
};

const theme: ITheme = getTheme();
// const { palette, semanticColors, fonts } = theme;
const { palette, fonts } = theme;

const classNames = mergeStyleSets({
  u2uListItemWraper: [
    fonts.medium,
    getFocusStyle(theme, { inset: -1 }),
    {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: 8,
      paddingBottom: 4,
      paddingTop: 4,
      // marginLeft: 8,
      // marginBottom: 8,
      fontWeight: 600,
      fontColor: "#8E8E93",
      selectors: {
        "&:hover": { background: palette.neutralLight },
      },
    },
  ],
  u2uName: [
    fonts.medium,
    {
      // marginLeft: 10,
      flexGrow: 1,
      // width: "50%",
      // fontWeight: 600,
      // fontColor: "#8E8E93",
      // flexGrow: 1,
    },
  ],
  u2uState: {
    // width: "15%",
    flexGrow: 1,
    // font: fonts.medium,
  },
  u2uActions: {
    // width: "25%",
    marginRight: 10,
    // font: fonts.medium,
  },
});

const AcceptIcon: IIconProps = { iconName: "AddFriend" };
const RejectIcon: IIconProps = { iconName: "BlockContact" };
const RepeatIcon: IIconProps = { iconName: "MailRepeat" };
const CancelIcon: IIconProps = { iconName: "Cancel" };

const User2UserLinkItem: FunctionComponent<User2UserLinkItemProps> = ({
  item,
  type,
  onCallBack,
}: User2UserLinkItemProps) => {
  const [error, setError] = useState<boolean>(false);
  const [solved, setSolved] = useState<boolean>(false);

  const onCallCallBack = (type: CALLUSERLINK) => {
    onCallBack({ user2user: item, type: type })
      .then(() => {
        error && setError(false);
        setSolved(true);
      })
      .catch(() => {
        !error && setError(true);
      });
  };

  //------------------
  // Renders
  //------------------

  const LinkingState = (): JSX.Element => {
    let iconName = "MailTentative";
    if (item.state === USER2USER_STATE.ACCEPTED) iconName = "MailCheck";
    else if (item.state === USER2USER_STATE.NONE) iconName = "MailTentative";
    else if (item.state === USER2USER_STATE.OFFERED) iconName = "MailSchedule";
    else if (item.state === USER2USER_STATE.REJECTED) iconName = "MailUndelivered";

    return (
      <FontIcon
        title={`State: ${String(item.state)}${
          item.invitemessage !== undefined && ` with message: "${String(item.invitemessage)}"`
        }`}
        aria-label={`${String(item.state)}`}
        iconName={iconName}
        style={{ cursor: "default" }}
        // className={classNames.iconBasic}
      />
    );
  };

  const ActionButtons = () => {
    if (type === TOorFROM.FROM) {
      return (
        <Stack horizontal>
          <Stack.Item>
            <IconButton iconProps={RepeatIcon} title={"Resend"} disabled />
          </Stack.Item>
          <Stack.Item>
            <IconButton
              // className={classNames.itemCellIcon}
              iconProps={CancelIcon}
              title={"Cancel"}
              onClick={() => onCallCallBack(CALLUSERLINK.CANCEL)}
            />
          </Stack.Item>
        </Stack>
      );
    } else {
      return (
        <Stack horizontal>
          <Stack.Item>
            <IconButton
              // className={classNames.itemCellIcon}
              iconProps={AcceptIcon}
              title={"Accept"}
              onClick={() => onCallCallBack(CALLUSERLINK.ACCEPT)}
            />
          </Stack.Item>
          <Stack.Item>
            <IconButton
              // className={classNames.itemCellIcon}
              iconProps={RejectIcon}
              title={"Reject"}
              onClick={() => onCallCallBack(CALLUSERLINK.REJECT)}
            />
          </Stack.Item>
        </Stack>
      );
    }
  };

  return (
    <>
      {!solved && (
        <Stack horizontal tokens={stackTokens} className={classNames.u2uListItemWraper}>
          {error && <Stack.Item>EROR</Stack.Item>}
          <Stack.Item className={classNames.u2uName}>
            {type === TOorFROM.FROM ? (
              <Persona
                imageInitials={getInitialsGlued(item.userto.userName)}
                text={item.userto.userName}
                size={PersonaSize.size24}
                imageAlt={item.userto.userName}
              />
            ) : (
              // <LimaNameInitals>{item.user.userName}</LimaNameInitals>
              //Its other person
              <Persona
                imageInitials={getInitialsGlued(item.user.userName)}
                text={item.user.userName}
                size={PersonaSize.size24}
                imageAlt={item.user.userName}
              />
            )}
          </Stack.Item>
          <Stack.Item className={classNames.u2uState}>
            <LinkingState />
            {/* {"state:" + item.state} */}
          </Stack.Item>
          <Stack.Item className={classNames.u2uActions}>
            <ActionButtons />
          </Stack.Item>
        </Stack>
      )}
    </>
  );
};

export default User2UserLinkItem;
