import { ITextFieldProps, Label, mergeStyleSets, Stack, TextField } from "@fluentui/react";
// import { forwardRef, ReactElement } from "react";
import React = require("react");

interface ILimaSearchTextListProps {
  limaLabel?: string;
  horizontal?: boolean;
  onChangeSecCallBack?: (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void;
  // minSearchLenght?: number;
}

const classNames = mergeStyleSets({
  inputTextField: {
    selectors: {
      " .ms-TextField-fieldGroup": {
        border: "solid 1px #d6d6d6",
      },
    },
  },
});

// const LimaSearchTextList_inner = (
export const LimaSearchTextList: React.FunctionComponent<ITextFieldProps & ILimaSearchTextListProps> = (
  // export const LimaSimpleTextField = (
  props: ITextFieldProps & ILimaSearchTextListProps
  // ref: React.Ref<ITextField>
) => {
  //const [searchValue, setSearchValue] = useState<string>(props.defaultValue);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
    if (props.onChange !== undefined) {
      props.onChange(event, newValue);
    }
    if (props.onChangeSecCallBack !== undefined) {
      props.onChangeSecCallBack(event, newValue);
    }
    //setSearchValue(newValue);
  };

  // const SearchLenghtMinimalNotice = useMemo(() => {
  //   if (searchValue?.length >= props.minSearchLenght) return <></>;
  //   return <>Lenght is not long enought to start searching</>;
  // }, [searchValue]);

  return (
    <>
      <Stack horizontal={props.horizontal ? true : false}>
        {props.limaLabel && (
          <Stack.Item>
            <Label>{props.limaLabel} </Label>
          </Stack.Item>
        )}
        <Stack.Item grow={1}>
          <TextField
            {...props}
            // componentRef={ref}
            className={classNames.inputTextField}
            onChange={onChangeHandler}
            //value={props.value?}
          />
          {/* <TextField {...props} componentRef={ref} className={classNames.inputTextField} onChange={onChangeHandler} /> */}
        </Stack.Item>
      </Stack>
      {/* <>{props.minSearchLenght !== undefined && SearchLenghtMinimalNotice}</> */}
    </>
  );
};

// LimaSearchTextList_inner.displayName = "LimaSearchTextList";
// export const LimaSearchTextList = forwardRef(LimaSearchTextList_inner);
