import { IconButton, IIconProps, Stack, TextField } from "@fluentui/react";
import { FunctionComponent, useState } from "react";
import React = require("react");
import { keyPressHandler } from "../../../../limaCommon/keyboardHandler";
import limaStore from "../../../../store/limaStore";
import { LIMA_ITEM_TYPE } from "../../../../types/BASE_Enums";
import { LimaMessage } from "../../../../types/Message";
import { useTranslation } from "react-i18next";

interface MessageInputProps {
  linkedId: string;
  callBack: (message: LimaMessage) => void;
}

const sendIcon: IIconProps = { iconName: "Send" };

const MessageInput: FunctionComponent<MessageInputProps> = ({ callBack, linkedId }: MessageInputProps) => {
  const [myMessage, setMyMessage] = useState<string>("");
  const { t } = useTranslation();

  const sendMessage = () => {
    window.console.log(myMessage);
    callBack({
      cTimeStamp: null,
      _id: null,
      _key: null,
      linkedItemId: linkedId,
      linkedItemType: LIMA_ITEM_TYPE.TASK,
      message: myMessage,
      writerUserId: limaStore.userId,
    });
    setMyMessage("");
  };

  return (
    <Stack horizontal>
      <Stack.Item grow={11}>
        <TextField
          underlined
          value={myMessage}
          placeholder={t("tptasks:taskedit.taskitemV4.messages.messagePlhld")}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
            setMyMessage(ev.target.value);
          }}
          onKeyPress={(ev: React.KeyboardEvent<HTMLInputElement>) => keyPressHandler(ev, "Enter", sendMessage)}
        />
      </Stack.Item>
      <Stack.Item grow={1}>
        <IconButton
          iconProps={sendIcon}
          title={t("tptasks:taskedit.taskitemV4.messages.sendLbl")}
          ariaLabel={t("tptasks:taskedit.taskitemV4.messages.sendLbl")}
          onClick={sendMessage}
        />
      </Stack.Item>
    </Stack>
  );
};

export default MessageInput;
