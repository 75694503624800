/**
 * Generic method change all items[changeValueIdent]=changeNewValue in array identified bye changeIdentKey and changeIdentVal
 * its generic
 * @param array
 * @param changeIdentKey
 * @param changeIdentVal
 * @param changeValueIdent
 * @param changeNewValue
 * @returns
 */

export function changeOnArray<Ta, Ti, Tv>(
  array: Ta[],
  changeIdentKey: string,
  changeIdentVal: Ti,
  changeValueIdent: string,
  changeNewValue: Tv
): Ta[] {
  array.forEach((item: Ta) => {
    if (item[changeIdentKey] === changeIdentVal) {
      item[changeValueIdent] = changeNewValue;
    }
  });

  return array;
}

export const is_SameStringArray = (array1: string[], array2: string[]): boolean => {
  if (array1 === null && array1 === null) return true; //Both are null
  if (array1 === null || array1 === null) return false; //only one is null
  //both are not null
  return array1.length == array2.length && array1.every((element: string) => array2.indexOf(element) != -1);
};
