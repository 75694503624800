import React = require("react");
import { LimaIconProps } from "./DefLimaIconType";

export const LimaIconSaveCopy = ({
  size = 32, // or any default size of your choice
  color = "black", // or any color of your choice
  fillColor = "black", // or any color of your choice
  title = "", //title
}: LimaIconProps): JSX.Element => {
  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 24 24"
    //   width={size} // added size here
    //   height={size} // added size here
    //   fill={color} // added color here
    //   aria-labelledby="title"
    // >
    //   <title id="title">{title}</title>
    //   <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
    //   <path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0 1 30.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1 0 80 0 40 40 0 1 0-80 0z" />
    // </svg>

    <svg
      viewBox="0 0 210 297"
      xmlns="http://www.w3.org/2000/svg"
      width={size} // added size here
      height={size} // added size here
      fill={fillColor} // added color here
      aria-labelledby="title"
    >
      <title id="title">{title}</title>
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            display: "inline",
            fill: "none",
            // stroke: "#000",
            stroke: color,
            strokeWidth: 14,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
          d="m243.471 129.737 131.928-.32a8.487 8.487 134.93 0 0 8.466-8.487V25.407a20.293 20.293 67.353 0 0-6.017-14.423l-23.288-23.05a20.59 20.59 22.353 0 0-14.484-5.956h-96.604a8.467 8.467 135 0 0-8.467 8.467V121.29a8.446 8.446 44.93 0 0 8.466 8.446z"
        />
        <path
          style={{
            fill: "none",
            // stroke: "#000",
            stroke: color,
            strokeWidth: 10,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
          d="M273.572-11.174v26.496a8.467 8.467 45 0 0 8.466 8.467H328.9a8.467 8.467 135 0 0 8.467-8.467v-26.856M263.21 124.354V69.136a7.387 7.387 135.081 0 1 7.408-7.387l78.582.222a7.43 7.43 45.081 0 1 7.408 7.43v56.011"
        />
        <path
          style={{
            display: "inline",
            fill: "none",
            // stroke: "#000",
            stroke: color,
            strokeWidth: 13.9355,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
          d="M162.402 93.87V42.945L127.477 7.911H15.217v148.088l76.293-.187"
        />
        <path
          style={{
            fill: "none",
            // stroke: "#000",
            stroke: color,
            strokeWidth: 10,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
          d="M46.83 13.791v26.496a8.467 8.467 45 0 0 8.466 8.467h46.861a8.467 8.467 135 0 0 8.467-8.467V13.431"
        />
        <path
          style={{
            fill: "none",
            // stroke: "#000",
            stroke: color,
            strokeWidth: 9.40969,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
          d="M41.344 150.302v-54.59c0-4.677 3.79-8.48 8.466-8.491 37.479-.088 56.087.123 65.208.258 4.67.07 8.458 2.711 8.458 6.016"
        />
      </g>
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          d="m109.065 116.324-.001 53.36c0 8.51 6.898 15.408 15.409 15.408l35.096.001a9.704 9.704 0 0 1-8.844 5.706h-26.252c-11.662 0-21.116-9.454-21.116-21.116v-44.515a9.704 9.704 0 0 1 5.708-8.845zm32.546-12.272a9.702 9.702 0 0 1 6.86 2.842L169 127.422a9.702 9.702 0 0 1 2.841 6.86v35.401a9.701 9.701 0 0 1-9.702 9.702h-37.666a9.701 9.701 0 0 1-9.702-9.702v-55.929c0-5.358 4.343-9.702 9.702-9.702zm-1.73 5.707h-15.408a3.995 3.995 0 0 0-3.995 3.995v55.93a3.995 3.995 0 0 0 3.995 3.994h37.666a3.995 3.995 0 0 0 3.995-3.995v-33.671h-16.55c-5.2 0-9.446-4.092-9.691-9.232l-.011-.47zm23.93 20.546-18.222-18.222v14.227a3.995 3.995 0 0 0 3.667 3.981l.328.014z"
          style={{
            // fill: "#212121",
            fill: color,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 2.28282,
          }}
        />
      </g>
    </svg>
  );
};
