/* eslint-disable @typescript-eslint/no-unsafe-call */
/**
 * USED FOR INSERTING DOCUMENT INTO WORD BODY
 */
import { log } from "missionlog";
import { updateURL } from "../api/httpAPI";
import { removeEvalParas, removeTechnicalParas } from "./limaToolWordLoader";
import { LimaDocData, LimaDocTypeENUM } from "../types/docxData";
import { limaLogTag } from "./limaLog";
import limaStore from "../store/limaStore";
import { urlStoreDocx3 } from "../api/apilinks";
import limaActasStore from "../store/limaActasStore";
import { LoaderListItem } from "../store/limaLoaderStore";
import limaLogsStore, { ENUM_LogstoreLogType } from "../store/limaLogsStore";

log.init({ limaInsertDoc: "limaCommon/limaInsertDoc" });
/* Main function for insert document to WORD */

/**
 * Method called to insert Doc to document
 * @param actaid string
 * @param url /apilinks/urlActaInsertDoc()
 * @param filename /apilinks/urlActaInsertDoc()
 * @param type enum LimaDocTypeENUM
 * @param callBack (data: LimaDocData) => void callback after finishing of processing
 * @param loaderID: LoaderListItem loader item incluein abort controller
 * @param insertIntoNewWordWindow if true, new Word is open
 */

export const insertDoc = async (
  actaid: string,
  url: string,
  fileName: string,
  type: LimaDocTypeENUM,
  callBack: (data: LimaDocData) => void,
  errorCallBack: () => void,
  loaderID: LoaderListItem,
  insertIntoNewWordWindow?: boolean
): Promise<unknown> => {
  log.info(limaLogTag.LimaInsertDoc, `insertDoc() type [${type}] name [${fileName}]`, url);

  return new Promise(function (resolve, reject) {
    //TODO: WHY NOT WORKING
    getBase642url(url, true, loaderID)
      .then((my_file_as_base64) => {
        log.info(limaLogTag.LimaInsertDoc, `insertDoc() get file`);
        resolve(InsertDocument2Word(my_file_as_base64, actaid, type, fileName, callBack, insertIntoNewWordWindow));
      })
      .catch(() => {
        log.info(limaLogTag.LimaInsertDoc, `insertDoc() catch file error`);
        if (callBack !== undefined) {
          callBack({ limactaDocID: "", limactaDocName: "", limactaDocType: LimaDocTypeENUM.none });
        }
        if (errorCallBack !== undefined) {
          errorCallBack();
        }
        reject();
      });
  });
};

/**
 * Method used for inserting xcopy into Word
 * @param xcopyId xcopy
 * @param url url to call
 * @param xcopyName? Name fo Acta
 * @param insertIntoNewWordWindow if true, new Word is open
 *
 * TODO: in future unite inserDoc and insertDocVeriosn
 */
export const InsertDocument_xcopy = async (
  xcopyId: string,
  url: string,
  loaderID: LoaderListItem,
  xcopyName?: string,
  insertIntoNewWordWindow?: boolean
): Promise<void> => {
  log.info(limaLogTag.LimaInsertDoc, "InsertDocument_xcopy()");
  const urlDoc = url; //urlActaInsertDoc(actaid, userid)
  const promise = getBase642url(urlDoc, true, loaderID);
  const my_file_as_base64 = await promise;
  InsertDocument2Word(
    my_file_as_base64,
    xcopyId,
    LimaDocTypeENUM.XCOPY,
    xcopyName !== undefined ? xcopyName : "",
    () => {},
    insertIntoNewWordWindow
  );
};

// /**
//  *  Method is used to comapre xcopy and acta
//  * @param file
//  * @param actaId acta ID
//  * @param xcopyId xcopyId
//  * @param userId user id
//  *
//  *
//  */
// // export const compareDocx_xcopy = async (file, actaId, xcopyId, userId: string) => {
// //   log.info(limaLogTag.LimaInsertDoc, "compareDocx_xcopy start");
// //   const promise = compareBase642File(file, actaId, LimaDocTypeENUM.ACTA, xcopyId, LimaDocTypeENUM.XCOPY, userId);
// //   const my_file_as_base64 = await promise;
// //   log.info(limaLogTag.LimaInsertDoc, "compareDocx_xcopy return data - > insert 2 doc");
// //   InsertDocument2Word(my_file_as_base64, actaId);
// // };

/**
 *  Method is used to comapre xcopy and xcopy
 * @param actaKey base Xcopy item
 * @param xcopyNewKey new Xcopy Item
 * @param userId user id
 * @param loaderID loader item
 */
export const compareXcopy2XcopyByActaKey = async (
  actaKey: string,
  xcopyNewKey: string,
  userId: string,
  loaderID: LoaderListItem,
  fileName?: string,
  cbCallBack?: (data: LimaDocData) => void,
  insertIntoNewWordWindow?: boolean
): Promise<void> => {
  log.info(limaLogTag.LimaInsertDoc, "compareXcopy2XcopyByActaKey start");

  const acta = limaActasStore.getActa(actaKey);

  if (acta === null) {
    log.error(limaLogTag.LimaInsertDoc, `compareXcopy2XcopyByActaKey: Missing xcopy for Acta [${actaKey}]`);
    return null;
  }

  if (acta.mainXcopy === undefined || acta.mainXcopy === null) {
    log.error(limaLogTag.LimaInsertDoc, `compareXcopy2XcopyByActaKey: Missing mainXcopy for Acta [${actaKey}]`);
    return null;
  }

  const promise = compareBase642File(
    null,
    acta.mainXcopy,
    LimaDocTypeENUM.XCOPY,
    xcopyNewKey,
    LimaDocTypeENUM.XCOPY,
    userId,
    loaderID
  );
  const my_file_as_base64 = await promise;
  log.info(limaLogTag.LimaInsertDoc, "compareDocx_xcopy return data - > insert 2 doc");
  InsertDocument2Word(my_file_as_base64, actaKey, LimaDocTypeENUM.ACTA, fileName, cbCallBack, insertIntoNewWordWindow);
};

/**
 *  Method is used to add one para into bookmark
 * @param bookmark bookmark that will be placed to text
 * @param url url to call to get docx
 */
export const compareParaVersion2Doc = async (
  bookmark: string,
  url: string,
  loaderID: LoaderListItem
): Promise<void> => {
  log.info(limaLogTag.LimaInsertDoc, "compareParaVersion2Doc start with: ", bookmark);
  const urlDoc = url; //urlActaInsertDoc(actaid, userid)
  const promise = getBase642url(urlDoc, false, loaderID);
  const my_file_as_base64 = await promise;
  log.info(limaLogTag.LimaInsertDoc, "compareParaVersion2Doc return data - > insert 2 doc");
  InsertDocument2WordAsPara(my_file_as_base64, bookmark);
};

//TODO: REWRITE TO apilink
function getBase642url(url, updateGlobalBkms: boolean, loaderID: LoaderListItem) {
  log.info(limaLogTag.LimaInsertDoc, "getBase642url()");
  return new Promise(function (resolve, reject) {
    const h = new Headers({
      Authorization: `Bearer ${limaStore.access_token}`,
      "cache-control": "no-cache, must-revalidate, post-check=0, pre-check=0, max-age=0",
    });

    const req = new Request(updateURL(url), {
      //TODO: change to httpAPI
      method: "GET",
      mode: "cors",
      headers: h,
    });

    fetch(req, loaderID.ac)
      .then((response) => {
        log.info(limaLogTag.LimaInsertDoc, "getBase642url() response:", response);
        void response.blob().then((blob) => {
          log.info(limaLogTag.LimaInsertDoc, "getBase642url() blob:", blob);
          log.info(limaLogTag.LimaInsertDoc, "getBase642url - ok");
          resolve(getBase64(blob, parseInt(response.headers.get("content-data")), updateGlobalBkms));
        });
      })
      .catch((err) => {
        reject(log.error(limaLogTag.LimaInsertDoc, "getBase642url::err:: ", err));
      });
  });
}

/**
 * Method that store file to server for compare
 *
 * @param file if provided
 * @param baseItemId item id of BaseItem
 * @param baseType base item type - LimaDocTypeENUM
 * @param baseItemId item id of NewItem
 * @param baseType new item type - LimaDocTypeENUM
 * @param userId String userid
 * @param loaderID loader
 */
function compareBase642File(
  file,
  baseItemId: string,
  baseType: LimaDocTypeENUM,
  newItemId: string,
  newItemType: LimaDocTypeENUM,
  userId: string,
  loaderID: LoaderListItem
) {
  log.info(limaLogTag.LimaInsertDoc, "compareBase642File()");
  return new Promise(function (resolve, reject) {
    const dataOut = new FormData();
    dataOut.append("actaid", baseItemId);
    dataOut.append("userid", userId);
    //dataOut.append("actaname", actaName);

    dataOut.append("store", "false");
    dataOut.append("baseid", baseItemId);
    dataOut.append("basetype", baseType.toUpperCase());
    dataOut.append("newid", newItemId);
    dataOut.append("newtype", newItemType.toUpperCase());
    // if (onBehalf !== undefined) dataOut.append("onbehalf", onBehalf);
    // //if (xcopyId != null) dataOut.append("xcopyid", xcopyId);
    // dataOut.append("file", blob, "output.docx");

    if (file !== null) {
      const blob = new Blob([file], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      dataOut.append("file", blob, "output.docx");
    } else {
      // dataOut.append("xcopyid", xcopyId);
    }
    log.info(limaLogTag.LimaInsertDoc, "compareBase642File xcopyId check finish");
    dataOut.append("store", "false");

    //const url = urlServerActacontroller + "storeDocx3"; //TODO:rewrrite to apilink
    const url = urlStoreDocx3();
    const h = new Headers({
      "cache-control": "no-cache, must-revalidate, post-check=0, pre-check=0, max-age=0",
      Authorization: `Bearer ${limaStore.access_token}`,
      "Content-Disposition": "form-data",
    });
    const req = new Request(updateURL(url), {
      //TODO: change to httpAPI
      method: "POST",
      mode: "cors",
      redirect: "follow",
      headers: h,
      body: dataOut,
    });

    fetch(req, loaderID.ac)
      .then((response) => {
        void response.blob().then((blob) => {
          // resolve(getBase64(blob));
          resolve(getBase64(blob, parseInt(response.headers.get("content-data")), true));
        });
      })
      .catch((err) => {
        reject(log.error(limaLogTag.LimaInsertDoc, "compareBase642File: ", err));
      });
  });
}

/**
 * Base getBase64 blob file to get document
 * @param file
 * @param data
 * @param updateGlobalBkms
 * @returns Resolve - document, Reject - error
 */
export function getBase64(file: Blob, data: number, updateGlobalBkms: boolean): Promise<unknown> {
  log.info(limaLogTag.limaUploadDoc, "getBase64 start ");
  return new Promise(function (resolve, reject) {
    const reader = new FileReader();
    // log.info(limaLogTag.limaUploadDoc, "getBase64 start 2");
    reader.readAsDataURL(file);
    reader.onload = () => {
      let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
      if (encoded.length % 4 > 0) {
        encoded += "=".repeat(4 - (encoded.length % 4));
      }
      if (data !== undefined) {
        const textFile2 = window.atob(encoded);
        const textData = textFile2.slice(data * -1);
        updateGlobalBkms && limaStore.setActaBmks(textData.split(","));
        const textOut = textFile2.slice(0, textFile2.length - data);
        encoded = window.btoa(textOut);
      }
      resolve(encoded);
    };
    reader.onerror = (error) => reject(error);
  });
}

/**
 * Insert Document from base64file into Word document
 * @param my_file_as_base64
 * @param actaid acata ID
 * @param type type default is "acta"
 * @param fileName ? filen name
 * @param cbCallBack ? (data:LimaDocData)=>void callback after inserting
 * @param insertIntoNewWordWindow ? if true, new Word is open
 */
export function InsertDocument2Word(
  my_file_as_base64,
  actaid: string,
  type: LimaDocTypeENUM = LimaDocTypeENUM.ACTA,
  fileName?: string,
  cbCallBack?: (data: LimaDocData) => void,
  insertIntoNewWordWindow?: boolean
): void {
  log.info(
    limaLogTag.LimaInsertDoc,
    `InsertDocument2Word calling word to action into new winodw '${insertIntoNewWordWindow}'`
  );

  void Word.run(async function (context) {
    //insert into new WINDOW
    if (insertIntoNewWordWindow !== undefined && insertIntoNewWordWindow === true) {
      log.info(limaLogTag.LimaInsertDoc, "InsertDocument2Word calling word to insert file into new word document");
      var newWordDoc = context.application.createDocument(my_file_as_base64);
      context.load(newWordDoc);
      // window.console.log("LOADED");
      log.debug(limaLogTag.LimaInsertDoc, "InsertDocument2Word: into new doc -remove paras step 0 - START");
      return context
        .sync()
        .then(function () {
          // REMOVED TECHNICAL PARAS
          //-----------------------
          //REMOVE PARAS STEP 1
          //-----------------------
          // window.console.log("REMOVE PARAS");
          log.debug(limaLogTag.LimaInsertDoc, "InsertDocument2Word: into new doc -remove paras step 1");

          //var pars = context.document.body.paragraphs;
          var pars = newWordDoc.body.paragraphs;
          pars.load();

          // var secs = newWordDoc.sections;
          // secs.load("items");

          return context
            .sync()
            .then(function () {
              //TODO: here stays last Section created by me for technal data but don know how to remove
              //-----------------------
              //REMOVE PARAS STEP 2
              //-----------------------
              //window.console.log("REMOVE PARAS 3");
              log.debug(limaLogTag.LimaInsertDoc, "InsertDocument2Word: into new doc -remove paras step 2");
              // if (secs.items.length > 1) {
              //   var nrSecs = secs.items.length - 1;
              //   var sect = secs.items[nrSecs];
              //   var secPrev = secs.items[nrSecs - 1];
              //   var sectRng = sect.body.getRange("Content");
              //   var secStart = secPrev.body.getRange("End");
              //   var sectAll = secStart.expandTo(sectRng);
              //   sectAll["delete"]();
              // }
              for (var i = 0; i < pars.items.length; i++) {
                if (pars.items[i].text.startsWith(">>>LIM_HELP>>>")) {
                  pars.items[i].delete();
                  console.log("removeTechnicalParas: ", i);
                }
              }
              return context
                .sync()
                .then(function () {
                  window.console.log("OPEN");
                  newWordDoc.open();
                  context.sync();
                  log.info(limaLogTag.LimaInsertDoc, "InsertDocument2Word - into new doc return context - ok");

                  if (cbCallBack !== undefined) {
                    if (fileName === undefined) fileName = "";
                    cbCallBack({ limactaDocID: actaid, limactaDocName: fileName, limactaDocType: type });
                  }
                })
                .catch(function (myError) {
                  // window.console.log("ERROR", myError);
                  log.error(
                    limaLogTag.LimaInsertDoc,
                    "InsertDocument2Word: into new doc - REMOVE PARAS 2 error",
                    myError
                  );
                  limaLogsStore.add(
                    "Error insert doc",
                    "There was an error on inserting doc into  new document",
                    ENUM_LogstoreLogType.ERROR,
                    limaLogTag.LimaInsertDoc
                  );
                  //showNotification("Error");
                });
            })
            .catch(function (myError) {
              //window.console.log("ERROR", myError);
              log.error(limaLogTag.LimaInsertDoc, "InsertDocument2Word: into new doc - REMOVE PARAS 1 error", myError);
              limaLogsStore.add(
                "Error insert doc",
                "There was an error on inserting doc into new document",
                ENUM_LogstoreLogType.ERROR,
                limaLogTag.LimaInsertDoc
              );
              //showNotification("Error");
            });
        })
        .catch(function (myError) {
          // window.console.log("ERROR", myError);
          log.error(limaLogTag.LimaInsertDoc, "InsertDocument2Word: REMOVE PARAS 0 error", myError);
          limaLogsStore.add(
            "Error insert doc",
            "There was an error on inserting doc into new  document",
            ENUM_LogstoreLogType.ERROR,
            limaLogTag.LimaInsertDoc
          );
          // showNotification("Error");
        });
    } else {
      const body = context.document.body;
      log.debug(limaLogTag.LimaInsertDoc, "InsertDocument2Word: currnt doc - remove paras step 0 - STRT");
      await context.sync();
      log.info(limaLogTag.LimaInsertDoc, "InsertDocument2Word calling word to insert file into current docuemnt");

      // body.insertFileFromBase64(my_file_as_base64, Word.InsertLocation.start);
      body.insertFileFromBase64(my_file_as_base64, Word.InsertLocation.replace);

      context.document.properties.customProperties.add("limactaDocID", actaid);
      if (type !== undefined && type !== null) {
        context.document.properties.customProperties.add("limactaDocType", type);
      } else {
        context.document.properties.customProperties.add("limactaDocType", "");
      }
      if (fileName !== undefined && fileName !== null)
        context.document.properties.customProperties.add("limactaDocName", fileName);
      else {
        context.document.properties.customProperties.add("limactaDocName", "");
      }
      context.document.properties.title = "Limacta document";
      context.document.properties.author = "Limacta document author";
      log.info(limaLogTag.LimaInsertDoc, "InsertDocument2Word calling word to sync");
      return context
        .sync()
        .then(() => {
          //-----------------------
          //REMOVE PARAS STEP 1
          //-----------------------
          // window.console.log("REMOVE PARAS");
          log.debug(limaLogTag.LimaInsertDoc, "InsertDocument2Word: into curr doc -remove paras step 1");
          //removeLastPara(); //TODO: HELPER FUNCITON TO REMOVE LAST TWO PARAGRAPHS
          // REMOVED TECHNICAL PARAS
          void removeTechnicalParas();
          void removeEvalParas();

          Word.run(function (context) {
            var doc = context.application.createDocument(my_file_as_base64);

            log.info(limaLogTag.LimaInsertDoc, "InsertDocument2Word - return context 2a - ok");

            return context.sync().then(async () => {
              //-----------------------
              //REMOVE PARAS STEP 2
              //-----------------------
              // window.console.log("REMOVE PARAS");
              log.debug(limaLogTag.LimaInsertDoc, "InsertDocument2Word: into curr doc -remove paras step 2");
              //TODO: this is just from firs section
              var primaryHeaderXml = doc.sections.getFirst().getHeader(Word.HeaderFooterType.primary).getOoxml();
              var firstHeaderXml = doc.sections.getFirst().getHeader(Word.HeaderFooterType.firstPage).getOoxml();
              var evenHeaderXml = doc.sections.getFirst().getHeader(Word.HeaderFooterType.evenPages).getOoxml();
              var primaryFooterXml = doc.sections.getFirst().getFooter(Word.HeaderFooterType.primary).getOoxml();
              var firstFooterXml = doc.sections.getFirst().getFooter(Word.HeaderFooterType.firstPage).getOoxml();
              var evenFooterXml = doc.sections.getFirst().getFooter(Word.HeaderFooterType.evenPages).getOoxml();
              //var sections = doc.sections;

              await context.sync();
              log.info(limaLogTag.LimaInsertDoc, "InsertDocument2Word - return context 2b - ok");
              Word.run(async function (context) {
                // if (sections != null) {
                //   var itemsCount = sections.items.length;
                //   if(itemsCount>1){
                //     sections.items[itemsCount-1];
                //     sections.

                //   }

                // }
                if (primaryHeaderXml.value !== null) {
                  context.document.sections
                    .getFirst()
                    .getHeader(Word.HeaderFooterType.primary)
                    .insertOoxml(primaryHeaderXml.value, Word.InsertLocation.replace);
                }
                if (firstHeaderXml.value !== null) {
                  context.document.sections
                    .getFirst()
                    .getHeader(Word.HeaderFooterType.firstPage)
                    .insertOoxml(firstHeaderXml.value, Word.InsertLocation.replace);
                }
                if (evenHeaderXml.value !== null) {
                  context.document.sections
                    .getFirst()
                    .getHeader(Word.HeaderFooterType.evenPages)
                    .insertOoxml(evenHeaderXml.value, Word.InsertLocation.replace);
                }
                if (primaryFooterXml.value !== null) {
                  context.document.sections
                    .getFirst()
                    .getFooter(Word.HeaderFooterType.primary)
                    .insertOoxml(primaryFooterXml.value, Word.InsertLocation.replace);
                }
                if (firstFooterXml.value !== null) {
                  context.document.sections
                    .getFirst()
                    .getFooter(Word.HeaderFooterType.firstPage)
                    .insertOoxml(firstFooterXml.value, Word.InsertLocation.replace);
                }
                if (evenFooterXml.value !== null) {
                  context.document.sections
                    .getFirst()
                    .getFooter(Word.HeaderFooterType.evenPages)
                    .insertOoxml(evenFooterXml.value, Word.InsertLocation.replace);
                }
                context.sync();
                if (cbCallBack !== undefined) {
                  if (fileName === undefined) fileName = "";
                  cbCallBack({ limactaDocID: actaid, limactaDocName: fileName, limactaDocType: type });
                }
              });
            });
          }).catch(function (myError) {
            // handleError(error);
            // showNotification(error.message);
            // window.console.log("ERROR", myError);
            log.error(limaLogTag.LimaInsertDoc, "InsertDocument2Word: Curr doc REMOVE PARAS 2 error", myError);
            limaLogsStore.add(
              "Error insert doc",
              "There was an error on inserting doc into curr document",
              ENUM_LogstoreLogType.ERROR,
              limaLogTag.LimaInsertDoc
            );
            // showNotification("Error");
          });

          if (cbCallBack !== undefined) {
            if (fileName === undefined) fileName = "";
            cbCallBack({ limactaDocID: actaid, limactaDocName: fileName, limactaDocType: type });
          }
        })
        .catch(function (myError) {
          // showNotification("Error", myError.message);
          // showNotification(myError.message);
          log.error(limaLogTag.LimaInsertDoc, "InsertDocument2Word: Curr doc REMOVE PARAS 1 error", myError);
          limaLogsStore.add(
            "Error insert doc",
            "There was an error on inserting doc into curr document",
            ENUM_LogstoreLogType.ERROR,
            limaLogTag.LimaInsertDoc
          );
          // window.console.log("ERROR", myError);
          // showNotification("Error");
        });
    }
  }).catch(function (myError) {
    // showNotification("Error", myError.message);
    // showNotification(myError.message);
    log.error(limaLogTag.LimaInsertDoc, "InsertDocument2Word: Curr doc REMOVE PARAS 0 error", myError);
    limaLogsStore.add(
      "Error insert doc",
      "There was an error on inserting doc into curr document",
      ENUM_LogstoreLogType.ERROR,
      limaLogTag.LimaInsertDoc
    );
    // window.console.log("ERROR", myError);
    // showNotification("Error");
  });
}

function InsertDocument2WordAsPara(my_file_as_base64, bookmark: string) {
  log.info(limaLogTag.LimaInsertDoc, "InsertDocument2WordAsPara bkm:", bookmark);
  Word.run(async function (context) {
    //var range = context.document.getSelection();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const range = context.document.getBookmarkRangeOrNullObject(bookmark);
    if (range === null) {
      alert("not selected anything");
      log.warn(limaLogTag.LimaInsertDoc, "InsertDocument2WordAsPara not found anything anything");
      //error-handling code
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      range.clear();
      await context.sync().then(function () {
        console.log("Cleared the selection (range object)");
        log.info(limaLogTag.LimaInsertDoc, "InsertDocument2WordAsPara: Cleared the selection (range object)");
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        range.insertFileFromBase64(my_file_as_base64, Word.InsertLocation.start).insertBookmark(bookmark);
        //range.insertText(newText, Word.InsertLocation.start).insertBookmark(bookmark);
      });
    }

    return context.sync().then(function () {
      void removeEvalParas();
      void removeTechnicalParas(false);
      console.log("Cleared the selection (range object) technical");
    });
  }).catch(function (error) {
    console.log("Error: " + JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      console.log("Debug info: " + JSON.stringify(error.debugInfo));
    }
  });
}
