import { log } from "missionlog";
import { urlActaPostSetfavorite } from "../api/apilinks";
import { httpPostAuth, HttpResponse } from "../api/httpAPI";
import { setFavoriteCall } from "../api/schema/setFavorite";
import limaLoadeStore, { LoaderItemEnd } from "../store/limaLoaderStore";
import { limaLogTag } from "./limaLog";

export const limaActaSetFavorite = async (actaId: string, favorite: boolean): Promise<boolean> => {
  // return new Promise(async (resolve, reject): Promise<boolean | null> => {
  let response: HttpResponse<setFavoriteCall>;
  log.info(limaLogTag.LimaStore, "limaActaSetFavorite: starts");
  const loaderID = limaLoadeStore.add("lima actalist get");
  const postData = { setfavorite: favorite };
  //postData.setfavorite = favorite;

  try {
    // response = await httpGet<actaListResponse>(urlActaListGet(userId, limit), loaderID.ac);
    response = await httpPostAuth(urlActaPostSetfavorite(actaId), postData, loaderID.ac);
    if ("parsedBody" in response && "setfavorite" in response.parsedBody) {
      log.info(limaLogTag.LimaStore, "limaActaSetFavorite: looks like get some data ", response.parsedBody);

      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return Promise.resolve(response.parsedBody.setfavorite);
    } else {
      log.error(limaLogTag.LimaStore, "limaActaSetFavorite: no data ", response);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);

      return Promise.reject();
    }
  } catch (error) {
    log.error(limaLogTag.LimaActaList, "limaActaSetFavorite: some error ", error);
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    return Promise.reject();
  } finally {
    // limaLoadeStore.remove(loaderID.k);
  }
  // });
};
