/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import * as React from "react";
import { useEffect, FunctionComponent, useMemo } from "react"; //useContext,
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { useDebounceFn } from "../../../../limaCommon/customHooks/useDebounce";
import { IStackTokens, Stack } from "@fluentui/react";
// import { LimaSimpleTextField } from "../../../../limaComponents/LimaSimpleTextField";
import { log } from "missionlog";
import { limaLogTag } from "../../../../limaCommon/limaLog";
// import { LimaControlledTextField } from "../../../../limaComponents/LimaControllSimpleTextField";
import { LimaControledTextFieldV4, LimaTextFiledTitlePlace } from "../../../../limaComponents/LimaControledTextFieldV4";
import { LimaControledDropDownFieldV4 } from "../../../../limaComponents/LimaControledDropDownFieldV4";
import { dropdownControlledTaskStateOptions } from "../../../../types/Task";
import limaUsersStore from "../../../../store/limaUsersStore";
import { LimaControledDatePickerFieldV4 } from "../../../../limaComponents/LimaControledDatePickerFieldV4";
import { Task_withActaXcopy } from "../../../../types/Task_withActaXcopy";
import limaTasksStoreV4 from "../../../../store/limaTasksStoreV4";
// import limaStore from "../../../../store/limaStore";

export type TaskItemBasicV4Props = {
  task: Task_withActaXcopy;
  //setTempDocInfo?: () => void;
};

const themedSmallStackTokens: IStackTokens = {
  childrenGap: "s1",
  padding: "s1",
};

const TaskItemBasicV4: FunctionComponent<TaskItemBasicV4Props> = () => {
  // const classes = useStyles();
  const { t } = useTranslation();
  // const [taskName_temp, setTaskName_temp] = useState<string>(task.task_withActaXcopy.taskName);
  // const [taskDescription_temp, setTaskDescription_temp] = useState<string>(task.task_withActaXcopy.taskDescription);

  const {
    // register,
    // setValue,
    trigger,
    setFocus,
    control,
    watch,
    // formState: { errors },
  } = useFormContext();

  const valueOfTest = watch();

  const assignableUsersOptions = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    () => limaUsersStore.getUsersListForAssignIncludeMe,
    [limaUsersStore.fullList]
  );
  // const calculation = useMemo(() => expensiveCalculation(count), [count]);

  //-------------------------
  //---- REACTIONS     ------
  //-------------------------

  useEffect(() => {
    setFocus("firstName");
  }, [setFocus]);

  //-------------------------
  //---- DEBAUNCERS    ------
  //-------------------------
  // useDebounceFn(
  //   taskName_temp,
  //   () => {
  //     log.info(limaLogTag.TaskEditPageV4, `useDebounceFn taskName_temp: taskName_temp ${String(taskName_temp)}`);
  //     setValue("taskName2", "aaaa", { shouldValidate: true });

  //     setValue("taskName2", String(taskName_temp));
  //     void trigger(["taskName2"]);
  //   },
  //   500
  // );

  useDebounceFn(
    valueOfTest.taskName, //getValues("taskName"),
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    () => {
      // window.console.log(`trigger to taskName`, valueOfTest.taskName);
      log.debug(limaLogTag.TaskEditPageV4, `useDebounceFn taskName: trigger to taskName: `, valueOfTest.taskName);
      void trigger("taskName");

      limaTasksStoreV4.setEditTask_withActaXcopy({
        ...limaTasksStoreV4.editTask_withActaXcopy,
        taskName: String(valueOfTest.taskName),
      });
    },
    500
  );

  useDebounceFn(
    valueOfTest.taskDescription, //getValues("taskName"),
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    () => {
      // window.console.log(`trigger to taskName`, valueOfTest.taskName);
      log.debug(
        limaLogTag.TaskEditPageV4,
        `useDebounceFn taskDescription: trigger to taskName: `,
        valueOfTest.taskDescription
      );
      void trigger("taskDescription");

      limaTasksStoreV4.setEditTask_withActaXcopy({
        ...limaTasksStoreV4.editTask_withActaXcopy,
        taskDescription: String(valueOfTest.taskDescription),
      });
    },
    500
  );

  //-------------------------
  //---- HANDLERS    --------
  //-------------------------

  // const onTaskNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   // window.console.log("call me 2 onTaskNameChange");
  //   const { value: nextValue, name: inputName } = event.target;
  //   log.info(limaLogTag.TaskEditPageV4, "onTaskNameChange taskName_temp: taskName_temp", nextValue, inputName);
  //   // window.console.log("call me 3 onTaskNameChange", nextValue);
  //   setTaskName_temp(nextValue);
  // };

  return (
    <Stack tokens={themedSmallStackTokens}>
      <Stack.Item>
        <LimaControledTextFieldV4
          control={control}
          labelPosition={LimaTextFiledTitlePlace.VERTICAL}
          name="taskName"
          limaLabel={t("tptasks:taskedit.taskitem2.tasknameLbl")}
        />
      </Stack.Item>
      <Stack.Item>
        <LimaControledDropDownFieldV4
          control={control}
          name="taskState"
          limaLabel={t("tptasks:taskedit.taskitem2.taskstateLbl")}
          placeholder={t("tptasks:taskedit.taskitem2.taskstatePlchld")}
          options={dropdownControlledTaskStateOptions}
        />
      </Stack.Item>
      <Stack.Item>
        <LimaControledDropDownFieldV4
          control={control}
          name="taskAssigned"
          limaLabel={t("tptasks:taskedit.taskitem2.taskassignedLbl")}
          // selectedKey={
          //   limaTasksStoreV4.editTask_withActaXcopy?.taskAssignedTo?._key
          //     ? limaTasksStoreV4.editTask_withActaXcopy.taskAssignedTo._key
          //     : limaStore.userId
          // }
          options={assignableUsersOptions}
        />
      </Stack.Item>
      <Stack.Item>
        <LimaControledDatePickerFieldV4
          limaLabel={t("tptasks:taskedit.taskitem2.taskduedateLbl")}
          control={control}
          showDatePickerPopup={() => {
            return;
          }}
          showToggle={true}
          //toggleChacked={}
          name="taskDuedate"
          focus={() => {
            console.log("focus");
          }}
          reset={() => {
            console.log("reset");
          }}
        />
      </Stack.Item>
      <Stack.Item>
        <LimaControledTextFieldV4
          // limaLabel={t("tptasks:taskedit.taskitem2.taskdescrLbl")}
          // id="taskdescription"
          name="taskDescription"
          multiline
          // required={false}
          control={control}
          // underlined
        />
      </Stack.Item>
    </Stack>
  );
};

export default TaskItemBasicV4;
