import { log, tag } from "missionlog";
import { action, makeObservable, observable } from "mobx";
import { urlGetKeywordsAdvice, urlGetKeywordslist } from "../api/apilinks";
import { httpGet, httpPost, HttpResponse } from "../api/httpAPI";
import { advice_textsearch } from "../types/advice";
import {
  adviceKeyword,
  adviceKeywordStringList,
  getAdviceKeywordList,
  getAdviceKeywordsJudi,
  judgeData,
} from "../types/keywords";
import limaLoadeStore from "./limaLoaderStore";

import limaTagsStore from "./limaTagsStore";

log.init({ LimaKeywordsStore: "LimaKeywordsStore" });

class LimaKeywordsStore {
  isInlineSearchActive = false;
  actId = "";
  parId = "";

  list: judgeData[] = []; //TODO: Change to type
  fullList: judgeData[] = []; //TODO: Change to type
  loadingData = false;
  totalItemCount = 0;
  keywordsList: string[] = [];
  keywordsListString: adviceKeywordStringList[] = [];

  searchKeyword: string[] = []; //TODO: Change to type

  wordTextStrings: any[] = [];

  constructor() {
    makeObservable(this, {
      actId: observable,
      parId: observable,
      isInlineSearchActive: observable,
      list: observable,
      loadingData: observable,
      totalItemCount: observable,
      keywordsListString: observable,
      wordTextStrings: observable,

      //setActVal: action,
      setLoadingData: action,
      getDataFromDB2: action,
      setInlinSearch: action,
      setList: action,
      onFilterChanged: action,
      setTotalItemCount: action,
      getKeywordsList: action,
      setSearchKeywords: action,
    });
  }

  /**
   * geting actapara fromDB
   * OBSOLETE
   * @returns none
   */
  // async getKeywordsFromDB() {
  //   if (this.searchKeyword.length <= 0) {
  //     log.warn(tag.LimaKeywordsStore, `getKeywordsFromDB: no searchKeyword`);
  //     return;
  //   }
  //   this.setLoadingData(true);
  //   let listData: HttpResponse<any[]>;
  //   try {
  //     this.setTotalItemCount(0);
  //     log.info(tag.LimaKeywordsStore, `getKeywordsFromDB: searchActPar`, this.searchKeyword);
  //     listData = await httpGet<any[]>(
  //       urlGetAdviceJudgeSinglePara2(this.searchKeyword[0].actID, this.searchKeyword[0].parID)
  //     );
  //     log.info(tag.LimaKeywordsStore, "getKeywordsFromDB: asketo to get something");
  //     if (listData.parsedBody !== undefined) {
  //       log.info(tag.LimaKeywordsStore, "getKeywordsFromDB: parsedBody is not null");
  //       this.setList(listData.parsedBody);
  //       this.setFullList(listData.parsedBody);
  //       this.setTotalItemCount(listData.parsedBody.length);
  //       log.info(tag.LimaKeywordsStore, "getKeywordsFromDB: load finish", listData);
  //     }
  //     this.setLoadingData(false);
  //   } catch (response) {
  //     log.error(tag.LimaKeywordsStore, "getKeywordsFromDB Error call get User", response);
  //     this.setLoadingData(false);
  //   }
  // }

  /**
   * Get from AI api list of all judicates including for more acta/par at once
   *
   * @returns nothing
   */
  async getDataFromDB2() {
    if (this.searchKeyword.length <= 0) {
      log.warn(tag.LimaKeywordsStore, `getDataFromDB2: no searchActPar`);
      return;
    }

    //setup call item
    let callParams: any = { count: 20, sentanceDetail: false, keywordsIds: this.searchKeyword }; //TODO: change type

    log.info(tag.LimaKeywordsStore, `getDataFromDB2:call params`, callParams);

    this.setLoadingData(true);
    let listData: HttpResponse<getAdviceKeywordsJudi[]>; //TODO: change type
    const loaderID = limaLoadeStore.add("get data from DB");
    try {
      this.setTotalItemCount(0);
      log.info(tag.LimaKeywordsStore, `getDataFromDB2: searchActPar`, this.searchKeyword);
      listData = await httpPost(urlGetKeywordsAdvice(), callParams, loaderID.ac);
      log.info(tag.LimaKeywordsStore, "getDataFromDB2: asketo to get something");
      if (listData.parsedBody !== undefined) {
        log.info(tag.LimaKeywordsStore, "getDataFromDB2: parsedBody is not null");
        let regexSearchList: advice_textsearch[] = []; //TODO: change type
        let regexListUniq: advice_textsearch[] = []; //TODO: change type

        let finalList: judgeData[] = []; //TODO: change type
        log.info(tag.LimaKeywordsStore, "getDataFromDB2: parsedBody is not null", listData.parsedBody);
        listData.parsedBody.map((item: getAdviceKeywordsJudi) => {
          //TODO: change type

          item.judi.map((judi: judgeData) => {
            //TODO: change type
            log.info(tag.LimaKeywordsStore, "getDataFromDB2: processing judi ", judi);
            // let judiLinksUniq: any[] = []; //TODO: change type

            log.info(tag.LimaKeywordsStore, "getDataFromDB2: processing judi judiLinks ", judi);
            //Prepare regegex search
            regexSearchList.findIndex(
              (
                arrayItem: advice_textsearch //TODO: change type
              ) => arrayItem.keywordBase === judi.keywordBase
            ) === -1 &&
              regexSearchList.push({ actID: "", parID: "", adviceTag: "", keywordBase: judi.keywordBase }) &&
              regexListUniq.push({ actID: "", parID: "", adviceTag: "", keywordBase: judi.keywordBase });

            //unique judi links
            // judiLinksUniq.findIndex(
            //   (
            //     curentJudiLinksItem: any //TODO: change type
            //   ) =>
            //     curentJudiLinksItem.actID === itemJudiLinks.actID && curentJudiLinksItem.parID === itemJudiLinks.parID
            // ) === -1 && judiLinksUniq.push(itemJudiLinks);

            // judi.judiLinks.forEach((itemJudiLinks: any) => {
            //   //TODO: change type
            //   log.info(tag.LimaKeywordsStore, "getDataFromDB2: processing judi judiLinks ", itemJudiLinks);
            //   //Prepare regegex search
            //   regexSearchList.findIndex(
            //     (
            //       arrayItem: any //TODO: change type
            //     ) => arrayItem.keywordBase === itemJudiLinks.actID && arrayItem.parID === itemJudiLinks.parID
            //   ) === -1 &&
            //     regexSearchList.push({actID:"",parID:"",adviceTag:"",keywordBase:item.}) &&
            //     regexListUniq.push({actID:"",parID:"",adviceTag:"",keywordBase:item.});

            //   //unique judi links
            //   judiLinksUniq.findIndex(
            //     (
            //       curentJudiLinksItem: any //TODO: change type
            //     ) =>
            //       curentJudiLinksItem.actID === itemJudiLinks.actID && curentJudiLinksItem.parID === itemJudiLinks.parID
            //   ) === -1 && judiLinksUniq.push(itemJudiLinks);
            // });
            //judiLinksUniq.forEach((item:advice_textsearch)=> item.parAlternatives.push("o. s. ř.")) //TODO change from server
            judi.judiLinks = [{ actID: "", parID: "", adviceTag: "", keywordBase: judi.keywordBase }]; //TODO ADD MORE KEYWORDS TO ONE JUDI
            finalList.push(judi);
          });
        });
        //console.log("Final list",finalList)
        this.setList(finalList);
        this.setFullList(finalList);
        this.setTotalItemCount(finalList.length);

        //TODO: remove all linking
        limaTagsStore.clearHighLightedTagsR(); //TODO: place better in future

        limaTagsStore.colorizeHighLightedR(regexListUniq);
        //test
        limaTagsStore.dumpHighLigtedR();
        log.info(tag.LimaKeywordsStore, "getDataFromDB2: load finish", listData);
      } else {
        this.clearLists();
      }
      this.setLoadingData(false);
    } catch (response) {
      log.error(tag.LimaKeywordsStore, "getDataFromDB2: Error call get User", response);
      this.clearLists();
      this.setLoadingData(false);
    } finally {
      limaLoadeStore.remove(loaderID.k);
    }
  }

  setLoadingData(trueOrfalse: boolean) {
    this.loadingData = trueOrfalse;
  }

  setInlinSearch(val: boolean) {
    this.isInlineSearchActive = val;
  }

  setList(newList: any[]) {
    //TODO: change type
    //log.info(tag.LimaJudgeStore, "setList: setting  finish", newList)
    this.list = newList;
  }

  setTotalItemCount(newCount: number) {
    this.totalItemCount = newCount;
  }

  setFullList(newList: any[]) {
    //TODO: change type
    this.fullList = newList;
  }

  clearLists() {
    this.setFullList([]);
    this.setTotalItemCount(0);
    this.setList([]);
  }

  onFilterChanged = (_: any, text: string): void => {
    log.info(tag.LimaJudgeStore, "onFilterChanged: filter change", text.toLowerCase());
    this.setList(
      this.fullList.filter(function (item: any) {
        //TODO: change type
        if (item.sentences[0] === undefined || item.sentences[0] == null || item.sentences[0] == "") return false;
        else if (
          item.sentences.every(function (singleSentace) {
            return singleSentace.toLowerCase().indexOf(text.toLowerCase()) >= 0;
          })
        )
          //TODO: asi nefunguje spravne
          return true;
        return false;
      })
    );
  };

  setKeywordsList(items: string[]) {
    this.keywordsList = items;
  }

  async getKeywordsList() {
    //TODO: cover error or no response

    this.setLoadingData(true);
    let listData: HttpResponse<getAdviceKeywordList>; //TODO: convert to type
    const loaderID = limaLoadeStore.add("get keyword list");
    try {
      listData = await httpGet(urlGetKeywordslist(), loaderID.ac);
      log.info(tag.LimaKeywordsStore, "getKeywordsList: asketo to get something");
      if (listData.parsedBody !== undefined) {
        log.info(tag.LimaKeywordsStore, "getKeywordsList: parsedBody is not null");
        this.setKeywordsList(listData.parsedBody.outRegex.map((item: adviceKeyword) => item.base));

        limaTagsStore.signAlts = listData.parsedBody.signsStruct;
        this.keywordsListString = [];
        listData.parsedBody.outRegex.forEach(
          //(item: adviceKeyword) => (this.keywordsListString += "|" + item.regexOffice+"")
          (item: adviceKeyword) =>
            this.keywordsListString.push({
              key: item.key,
              base: item.base,
              officeStringSearch: item.regexOffice,
              jsRegEx: item.regex,
            })
        );
        //this.keywordsListString = this.keywordsListString.substring(1);

        log.info(tag.LimaKeywordsStore, "getKeywordsList: load finish", listData);
        log.info(tag.LimaKeywordsStore, "getKeywordsList: keywordsList", this.keywordsList);
        log.info(tag.LimaKeywordsStore, "getKeywordsList: keywordsListString2", this.keywordsListString);
        log.info(tag.LimaKeywordsStore, "getKeywordsList: limaTagsStore.signAlts", limaTagsStore.signAlts);
      }
      this.setLoadingData(false);
    } catch (response) {
      log.error(tag.LimaKeywordsStore, "getKeywordsList Error call get User", response);
      this.setLoadingData(false);
    } finally {
      limaLoadeStore.remove(loaderID.k);
    }
  }

  setSearchKeywords(items: any[]) {
    //TODO: change type
    this.searchKeyword = items;
  }

  /**
   * when found by select handler in document and store tham as unique strings list
   * @param item founded item
   */
  addSearchKeyword(item: string) {
    //TODO: change type
    this.searchKeyword.findIndex(
      (arrayItem: string) => arrayItem == item //TODO: change type
    ) === -1 && this.searchKeyword.push(item);
    //this.searchKeyword.push(item)
  }

  clearSearchKeywords() {
    this.searchKeyword = [];
  }

  setWordTextStrings(searchedString: any[]) {
    //TODO: Change type
    this.wordTextStrings = searchedString;
  }

  /**
   * Cleanup on logout button
   *
   * @autor MS
   */
  onLogoutClear() {
    this.isInlineSearchActive = false;
    this.actId = "";
    this.parId = "";

    this.list = []; //TODO: Change to type
    this.fullList = []; //TODO: Change to type
    this.loadingData = false;
    this.totalItemCount = 0;
    this.keywordsList = [];
    this.keywordsListString = [];

    this.searchKeyword = []; //TODO: Change to type

    this.wordTextStrings = [];
  }
}

const limaKeywordsStore = new LimaKeywordsStore();
export default limaKeywordsStore;
