import { urlStoreDocx3 } from "../api/apilinks";
import { httpPostMulipartAuth, updateURL } from "../api/httpAPI";
import { log } from "missionlog";
import limaTagsStore from "./../store/limaTagsStore";
import { LimaDocTypeENUM } from "../types/docxData";
import { actaDocOK } from "../api/schema/getActaDoc";
import limaLoadeStore, { LoaderItemEnd } from "../store/limaLoaderStore";
import { XcopyHistory, xcopyHistory_null } from "../types/XcopyHistory";
import { limaLogTag } from "./limaLog";
import limaLogsStore, { ENUM_LogstoreLogType } from "../store/limaLogsStore";
import { StatusCodes } from "http-status-codes";

export const saveNewActaToLimacta = async (
  // url: string,
  actaName: string,
  userId: string,
  callback: () => void,
  callbackError: () => void
  // callback2
): Promise<unknown> => {
  log.info(limaLogTag.LimaSaveDoc, "getNewActaUrl()");
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  return new Promise(() => {
    void getFileAsyncInternal(
      null,
      null,
      LimaDocTypeENUM.ACTA,
      null,
      LimaDocTypeENUM.ACTA,
      actaName,
      userId,
      xcopyHistory_null(), //version
      callback,
      callbackError
    );
  });
};

/**
 * Storing new document to Limacta as XCopy
 * @param actaName acta name
 * @param userId user who process
 * @param callback callBack
 * @param callbackError onErrorCallback
 * @param onBehalf on Behalf
 *
 * @returns
 *
 * @author MS
 */
export const saveNewActaToLimactaXcopy = async (
  // url: string,
  actaName: string,
  userId: string,
  callback: () => void,
  callbackError: () => void,
  onBehalf?: string
): Promise<unknown> => {
  log.info(limaLogTag.LimaSaveDoc, "saveNewActaToLimactaXcopy start");
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  if (onBehalf === null) onBehalf = undefined;

  const historyData = xcopyHistory_null();
  historyData.versionMinor = Number(process.env.REACT_APP_NEWFILESAVE_MINOR_VERSIONSTART);
  historyData.versionMajor = Number(process.env.REACT_APP_NEWFILESAVE_MAJOR_VERSIONSTART);

  log.info(
    limaLogTag.LimaSaveDoc,
    "saveNewActaToLimactaXcopy historyData",
    historyData,
    process.env.REACT_APP_NEWFILESAVE_MINOR_VERSIONSTART,
    process.env.REACT_APP_NEWFILESAVE_MAJOR_VERSIONSTART
  );
  return new Promise(() => {
    void getFileAsyncInternal(
      null,
      null,
      LimaDocTypeENUM.XCOPY,
      null,
      LimaDocTypeENUM.XCOPY,
      actaName,
      userId,
      historyData, //version
      callback,
      callbackError,
      undefined,
      onBehalf
    );
  });
};

/**
 * storing file
 * @param actaId actaID where to strore
 * @param baseId Id of document that is going to be store Xcopy/Acta
 * @param baseType type of origin document
 * @param newId Id of new document - mainly for compare
 * @param newType type of new document
 * @param actaName name of acta
 * @param userId user id
 * @param historyData data for storing to history
 * @param callback callback after finishig
 * @param callbackError callback after finishig
 * @param xcopyId xcopyId, can be null
 * @param {string} onBehalf when storing as another user, can be null
 */
export const getFileAsyncInternal = async (
  actaId: string,
  baseId: string,
  baseType: LimaDocTypeENUM,
  newId: string,
  newType: LimaDocTypeENUM,
  actaName: string,
  userId: string,
  historyData: XcopyHistory,
  callback: { (): void; (): void },
  callbackError: { (): void; (): void },
  xcopyId?: string,
  onBehalf?: string
): Promise<unknown> => {
  await limaTagsStore.removeHighlight();
  log.info(limaLogTag.LimaSaveDoc, "after removeHighlight");

  if (xcopyId === undefined) xcopyId = null;

  Office.context.document.getFileAsync(Office.FileType.Compressed, { sliceSize: 10240 }, function (asyncResult) {
    log.info(limaLogTag.LimaSaveDoc, "getFileAsyncInternal()");
    if (asyncResult.status == Office.AsyncResultStatus.Failed) {
      log.error(limaLogTag.LimaSaveDoc, "getFileAsyncInternal() - ", JSON.stringify(asyncResult));
    } else {
      getAllSlices(
        asyncResult.value,
        actaId,
        baseId,
        baseType,
        newId,
        newType,
        actaName,
        userId,
        historyData,
        callback,
        callbackError,
        onBehalf
      );
    }
  });
  return null;
};

export const getAllSlices = (
  file: Office.File,
  actaId: string,
  baseId: string,
  baseType: LimaDocTypeENUM,
  newId: string,
  newType: LimaDocTypeENUM,
  actaName: string,
  userId: string,
  historyData: XcopyHistory,
  callback: (arg0: boolean) => void,
  callbackError: (arg0: boolean) => void,
  onBehalf: string
): void => {
  log.info(limaLogTag.LimaSaveDoc, "getAllSlices()");
  const sliceCount = file.sliceCount;
  let sliceIndex = 0;
  let docdata = [];
  const getSlice = function () {
    file.getSliceAsync(sliceIndex, function (asyncResult) {
      if (asyncResult.status == Office.AsyncResultStatus.Succeeded) {
        docdata = docdata.concat(asyncResult.value.data);
        sliceIndex++;
        if (sliceIndex == sliceCount) {
          file.closeAsync();
          void onGetAllSlicesSucceeded(
            docdata,
            actaId,
            baseId,
            baseType,
            newId,
            newType,
            actaName,
            userId,
            historyData,
            callback,
            callbackError,
            onBehalf
          );
        } else {
          getSlice();
        }
      } else {
        file.closeAsync();
      }
    });
  };
  getSlice();
};

// export const onGetAllSlicesSucceeded = async (
//   docxData: any[],
//   actaId: string,
//   baseId: string,
//   baseType: LimaDocTypeENUM,
//   newId: string,
//   newType: LimaDocTypeENUM,
//   actaName: string,
//   userId: string,
//   callback: (arg0: boolean) => void,
//   callbackError: (arg0: boolean) => void,
//   onBehalf?: string
// ) => {
//   log.info(tag.limaSaveDoc, "onGetAllSlicesSucceeded()");

//   //const url = updateURL(urlStoreDocx2());
//   const url = updateURL(urlStoreDocx3());

//   const blob = new Blob([encodeBase64(docxData)], {
//     type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
//   });

//   const dataOut = new FormData();

//   //TODO: CHECK IF USED SOMEWHERE ELSE ALSO
//   dataOut.append("actaid", actaId);
//   dataOut.append("actaname", actaName);
//   dataOut.append("userid", userId);
//   dataOut.append("store", "true");
//   dataOut.append("baseid", baseId);
//   dataOut.append("basetype", baseType.toUpperCase());
//   dataOut.append("newid", newId);
//   dataOut.append("newtype", newType.toUpperCase());
//   if (onBehalf !== undefined) dataOut.append("onbehalf", onBehalf);
//   //if (xcopyId != null) dataOut.append("xcopyid", xcopyId);
//   dataOut.append("file", blob, "output.docx");

//   //log.info(tag.limaSaveDoc, "onGetAllSlicesSucceeded Store xcopyid -", xcopyId);
//   log.info(tag.limaSaveDoc, "onGetAllSlicesSucceeded Store actaid -", actaId);
//   log.info(tag.limaSaveDoc, "onGetAllSlicesSucceeded Store Start");

//   await httpPostMulipart<any>(url, dataOut)
//     .then(() => {
//       log.info(tag.limaSaveDoc, "onGetAllSlicesSucceeded Store finished");
//       if (callback !== undefined && callback !== null) callback(false);
//     })
//     .catch(() => {
//       log.info(tag.limaSaveDoc, "onGetAllSlicesSucceeded Some error");
//       if (callbackError !== undefined && callbackError !== null) callbackError(false);
//     });
// };

export const onGetAllSlicesSucceeded = async (
  docxData: any[],
  actaId: string,
  baseId: string,
  baseType: LimaDocTypeENUM,
  newId: string,
  newType: LimaDocTypeENUM,
  actaName: string,
  userId: string,
  historyData: XcopyHistory,
  callback: (arg0: boolean) => void,
  callbackError: (arg0: boolean) => void,
  onBehalf?: string
): Promise<unknown> => {
  log.info(limaLogTag.LimaSaveDoc, "onGetAllSlicesSucceeded()");

  //const url = updateURL(urlStoreDocx2());
  // const url = updateURL(urlStoreDocx3());

  const blob = new Blob([encodeBase64(docxData)], {
    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  });
  const dataOut = new FormData();

  //TODO: CHECK IF USED SOMEWHERE ELSE ALSO
  dataOut.append("actaid", actaId);
  dataOut.append("actaname", actaName);
  dataOut.append("userid", userId);
  dataOut.append("store", "true");
  dataOut.append("historydata", JSON.stringify(historyData)); //historyData: XcopyHistory,
  dataOut.append("baseid", baseId);
  dataOut.append("basetype", baseType.toUpperCase());
  dataOut.append("newid", newId);
  dataOut.append("newtype", newType.toUpperCase());
  if (onBehalf !== undefined) dataOut.append("onbehalf", onBehalf);
  //if (xcopyId != null) dataOut.append("xcopyid", xcopyId);
  dataOut.append("file", blob, "output.docx");

  await uploadBase64FileToLima(dataOut, callback, callbackError);
  return null;
};

export const encodeBase64 = (docData: string | any[]): string => {
  log.info(limaLogTag.LimaSaveDoc, "encodeBase64()");
  let s = "";
  for (let i = 0; i < docData.length; i++) {
    s += String.fromCharCode(docData[i]);
  }
  return window.btoa(s);
};

/**
 * Basic data sending to limacta
 *
 * @param dataOut Formdata to be send to LImacta
 * @param callback
 * @param callbackError
 * @returns
 */
export const uploadBase64FileToLima = async (
  dataOut: FormData,
  callback: (arg0: boolean) => void,
  callbackError: (arg0: boolean) => void
): Promise<boolean> => {
  log.info(limaLogTag.LimaSaveDoc, "uploadBase64FileToLima START");

  // const url = updateURL(urlStoreDocx3());
  const loaderID = limaLoadeStore.add("lima store document");
  try {
    const response = await httpPostMulipartAuth<actaDocOK>(updateURL(urlStoreDocx3()), dataOut, loaderID.ac, false);
    if (response === null || !response.ok) {
      log.info(limaLogTag.LimaSaveDoc, "uploadBase64FileToLima Some error");
      if (callbackError !== undefined && callbackError !== null) callbackError(false);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      limaLogsStore.add(
        "Error storing document",
        "there was an error storing document",
        ENUM_LogstoreLogType.ERROR,
        limaLogTag.LimaSaveDoc
      );
      return false;
    }
    if (response.parsedBody !== undefined || (response.status !== undefined && response.status === StatusCodes.OK)) {
      log.info(limaLogTag.LimaSaveDoc, "uploadBase64FileToLima Store finished");
      if (callback !== undefined && callback !== null) callback(false);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return true;
    }
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error("unknown reposnes error");
    // await httpPostMulipartAuth<actaDocOK>(url, dataOut, loaderID.ac, false)
    //   .then(() => {
    //     log.info(tag.limaSaveDoc, "uploadBase64FileToLima Store finished");
    //     if (callback !== undefined && callback !== null) callback(false);
    //     limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
    //     return true;
    //   })
    //   .catch(() => {
    //     log.info(tag.limaSaveDoc, "uploadBase64FileToLima Some error");
    //     if (callbackError !== undefined && callbackError !== null) callbackError(false);
    //     limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    //     return false;
    //   });
    // limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
    // return true;
  } catch (error) {
    log.error(limaLogTag.LimaUserCalls, "ERRORR: fetching data createUser response", error);
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error(error);
  } finally {
    // limaLoadeStore.remove(loaderID.k);
  }
};
