import { log } from "missionlog";
import { makeObservable, observable, action } from "mobx";
import { limaLogTag } from "../limaCommon/limaLog";
import { advice_textsearch } from "../types/advice";
import { signsAlternatives, HighLightedTagsNew, HighLightedTagsSingle, HLSTATE } from "../types/highlighted";
import { adviceKeywordStringList } from "../types/keywords";
import * as colors from "./../api/schema/tags/colors";
import { LimSelTemp } from "./../api/schema/tags/colors";
import limaKeywordsStore from "./limaKeywordStore";
//import * as regex from "./../api/schema/tags/regEx";

// log.init({ limaTagsStore: "limaTagsStore" });

/**
 * https://docs.xbench.net/user-guide/regular-expressions/
 * https://www3.ntu.edu.sg/home/ehchua/programming/howto/PowerUser_MSOffice.html
 */

class LimaTagsStore {
  highlightOption: boolean = false;
  highlightColor: colors.HIGHLIGHT = null;
  highlightColorRecovery: colors.HIGHLIGHT = null;
  tagsHighLighted: advice_textsearch[] | string[] = [];

  // tagsHighLightedR: highLightedTags[] = null;
  tagsHighLightedHoverStore: advice_textsearch[] = [];
  allContextTags: advice_textsearch[] = [];

  signAlts: signsAlternatives[] = [];

  tagsHighLightedR2: HighLightedTagsNew[] = [];

  constructor() {
    log.info(limaLogTag.LimaTagsStore, "Instance of LimaTagsStore - created");

    makeObservable(this, {
      highlightOption: observable,
      highlightColor: observable,
      highlightColorRecovery: observable,
      tagsHighLighted: observable,

      //getTags: computed,
      //clearHighlightedItems: action
      clearHighlightedItemsNew: action,
      //setHighlightOption: action,
      //setHighlightColor: action,
      //setHighlightColorRecovery: action,
      //setTagsHighLighted: action
    });
  }

  //1. step
  clearHighLightedTagsR() {
    // this.tagsHighLightedR = null; //TODO: remove after move to another version
    this.tagsHighLightedR2 = null;
  }

  //2. step
  colorizeHighLightedR(preparingListTags: advice_textsearch[]) {
    // var newList: highLightedTags[] = []; //TODO: remove after move to another version
    var newList2: HighLightedTagsNew[] = [];

    preparingListTags.forEach((tagItem: advice_textsearch, index: number) => {
      // newList.push({
      //   regex: regexItem,
      //   styleOrigins: [],
      //   returnOrigStyles: [],
      //   highlightedStyle: colors.LIMselTemp[(index % 5) + 1],
      //   regexAlternatives: this.getJudiRegexAlternatives(regexItem),
      //   textContextValues: this.getJudiRegexAlternatives(regexItem)
      // }); //TODO: remove after move to another version

      newList2.push({
        tag: this.judgeItemIdent(tagItem),
        isHighlighted: false,
        state: HLSTATE.UNHIGHLIGHTED,
        highlightedStyle: colors.LIMselTemp[(index % 5) + 1],
        textContextValues: [],
        contextTags: this.getJudiRegexAlternatives(tagItem, colors.LIMselTemp[(index % 5) + 1]), //TODO: here will go all matching values -> this.getJudiRegexAlternatives(regexItem)
      });
    });

    //set new tag store
    // this.tagsHighLightedR = newList;
    this.tagsHighLightedR2 = newList2;
  }

  getJudiRegexAlternatives(tagToSearch: advice_textsearch, style?: LimSelTemp) {
    //return [regex, regex.replace("99/1963", "o. s. ř."), regex.replace("99/1963", "o.s.ř.")];
    //return this.allContextTags
    log.info(limaLogTag.LimaTagsStore, "getJudiRegexAlternatives: for", tagToSearch);
    log.info(limaLogTag.LimaTagsStore, "getJudiRegexAlternatives: all list", this.allContextTags);

    let out: HighLightedTagsSingle[] = [];
    this.allContextTags.forEach((item: advice_textsearch) => {
      if (tagToSearch.keywordBase !== undefined && tagToSearch.keywordBase !== null && tagToSearch.keywordBase !== "") {
        if (item.keywordBase === tagToSearch.keywordBase) {
          out.push({
            contextTag: item.adviceTag,
            returnOrigStyles: [],
            styleOrigins: [],
            highlightedStyle: style,
          });
        }
      } else {
        if (
          (item.parID === tagToSearch.parID && item.actID === tagToSearch.actID) ||
          (item.parID === tagToSearch.parID &&
            this.signAlts.filter(
              (itemAlts: signsAlternatives) =>
                itemAlts.mainSign === item.actID && itemAlts.altSigns.includes(tagToSearch.actID)
            )) //TODO: Correct
        ) {
          out.push({
            contextTag: item.adviceTag,
            returnOrigStyles: [],
            styleOrigins: [],
            highlightedStyle: style,
          });
        } else {
          log.info(limaLogTag.LimaTagsStore, "getJudiRegexAlternatives: not same", tagToSearch, item);
          log.info(
            limaLogTag.LimaTagsStore,
            "getJudiRegexAlternatives: not same parid",
            item.parID === tagToSearch.parID
          );
          log.info(
            limaLogTag.LimaTagsStore,
            "getJudiRegexAlternatives: not same actaid",
            item.actID === tagToSearch.actID
          );
          log.info(
            limaLogTag.LimaTagsStore,
            "getJudiRegexAlternatives: not same acta alternative",
            item.actID === "o.s.ř." && tagToSearch.actID === "99/1963"
          );
        }
      }
    });
    return out;
  }

  //3. step

  /**
   * change 2Unhli and 2Highlihg
   * @param listof2UnHighligted list of tags to unhighligh
   * @param listof2Highlighted list of tags to highlight
   */
  updateHighLightedR(listof2UnHighligted: string[], listof2Highlighted: string[]) {
    // var newList: highLightedTags[] = []; //TODO: remove after move to another version
    var newList2: HighLightedTagsNew[] = [];
    // check exist and if exist move slected to
    //log.info(limaLogTag.LimaTagsStore, "updateHighLightedR: unhighlighting",regexListofUnHighligted);

    //TODO: remove after move to another version --- from here
    // this.tagsHighLightedR.forEach((itemStored: highLightedTags) => {
    //   let findItems: string[] = regexListofUnHighligted.filter(
    //     (regUnHighlItem: string) => regUnHighlItem === itemStored.regex
    //   );
    //   if (findItems.length > 0) {
    //     newList.push({
    //       regex: itemStored.regex,
    //       styleOrigins: [],
    //       returnOrigStyles: itemStored.styleOrigins,
    //       highlightedStyle: itemStored.highlightedStyle,
    //       regexAlternatives: this.getJudiRegexAlternatives(itemStored.regex),
    //       textContextValues: this.getJudiRegexAlternatives(itemStored.regex)
    //     });
    //   } else {
    //     newList.push({
    //       regex: itemStored.regex,
    //       styleOrigins: itemStored.styleOrigins,
    //       returnOrigStyles: itemStored.returnOrigStyles,
    //       highlightedStyle: itemStored.highlightedStyle,
    //       regexAlternatives: this.getJudiRegexAlternatives(itemStored.regex),
    //       textContextValues: this.getJudiRegexAlternatives(itemStored.regex)
    //     });
    //   }
    // });
    //TODO: remove after move to another version --- to here

    this.tagsHighLightedR2.forEach((itemStored: HighLightedTagsNew) => {
      //Search if is in uhhighlighted
      // let findUHItems: string[] = regexListofUnHighligted.filter(
      //   (regUnHighlItem: string) => regUnHighlItem === itemStored.tag
      // );
      let isInUnhighlightted: boolean = listof2UnHighligted.includes(itemStored.tag);

      //Search if is in highlighted
      let isInHighlighted: boolean = listof2Highlighted.includes(itemStored.tag);

      if (isInUnhighlightted) {
        let newSubList2 = [];
        //take care of all inner tags
        itemStored.contextTags.forEach((item: HighLightedTagsSingle) => {
          newSubList2.push({
            contextTag: item.contextTag,
            returnOrigStyles: item.styleOrigins,
            highlightedStyle: item.highlightedStyle,
            styleOrigins: [],
          });
        });

        newList2.push({
          tag: itemStored.tag,
          isHighlighted: false,
          state: HLSTATE.TOREMOVEHL,
          highlightedStyle: itemStored.highlightedStyle,
          textContextValues: itemStored.textContextValues,
          contextTags: newSubList2,
        });
      } else if (isInHighlighted) {
        let newSubList2 = [];
        //take care of all inner tags
        itemStored.contextTags.forEach((item: HighLightedTagsSingle) => {
          newSubList2.push({
            contextTag: item.contextTag,
            returnOrigStyles: item.returnOrigStyles,
            highlightedStyle: item.highlightedStyle,
            styleOrigins: [],
          });
        });

        newList2.push({
          tag: itemStored.tag,
          isHighlighted: false,
          state: HLSTATE.TOADDHL,
          highlightedStyle: itemStored.highlightedStyle,
          textContextValues: itemStored.textContextValues,
          contextTags: newSubList2,
        });
      } else {
        let newSubList2 = [];
        //take care of all inner tags
        itemStored.contextTags.forEach((item: HighLightedTagsSingle) => {
          newSubList2.push({
            contextTag: item.contextTag,
            returnOrigStyles: item.returnOrigStyles,
            highlightedStyle: item.highlightedStyle,
            styleOrigins: item.styleOrigins,
          });
        });

        newList2.push({
          tag: itemStored.tag,
          isHighlighted: itemStored.isHighlighted,
          state: itemStored.state,
          highlightedStyle: itemStored.highlightedStyle,
          textContextValues: itemStored.textContextValues,
          contextTags: newSubList2,
        });
      }
    });
    //set new tag store
    // this.tagsHighLightedR = newList;
    this.tagsHighLightedR2 = newList2;
  }

  dumpHighLigtedR() {
    // log.info(limaLogTag.LimaTagsStore, "dumpHighLigtedR: object", this.tagsHighLightedR); //TODO: remove after move to another version
    log.info(limaLogTag.LimaTagsStore, "dumpHighLigtedR2: object", this.tagsHighLightedR2);
    //log.info(limaLogTag.LimaTagsStore, "dumpHighLigtedR: json",JSON.stringify(this.tagsHighLightedR));
  }

  // //TODO: remove after move to another version --- from here
  // storeHighlightedOrig(regexOfSelected: string, index: number, styleName: string) {
  //   //log.info(limaLogTag.LimaTagsStore, `storeHighlightedOrig: storing for regex [${regexOfSelected}], index[${index}], styleName[${styleName}]`);
  //   this.tagsHighLightedR.filter((tagItem: highLightedTags) => regexOfSelected === tagItem.regex)[0].styleOrigins[
  //     index
  //   ] = styleName;
  // }
  // //TODO: remove after move to another version --- to here

  storeHighlightedOrigTag(tagsHighLightedR: HighLightedTagsSingle, index: number, styleName: string) {
    //log.info(limaLogTag.LimaTagsStore, `storeHighlightedOrig: storing for regex [${regexOfSelected}], index[${index}], styleName[${styleName}]`);
    tagsHighLightedR.styleOrigins[index] = styleName;
  }

  // //TODO: remove after move to another version --- from here
  // checkIsRegexSelected(regexToCheck: string): number {
  //   let out: highLightedTags = this.tagsHighLightedR.filter(
  //     (tagItem: highLightedTags) => regexToCheck === tagItem.regex
  //   )[0];
  //   if (out !== undefined) return out.styleOrigins.length;
  //   else return null;
  //   //return this.tagsHighLightedR.filter((tagItem: highLightedTags) =>regexToCheck===tagItem.regex)[0].styleOrigins.length
  // }
  // //TODO: remove after move to another version --- to here

  checkIsRegexState(tagToCheck: string): HLSTATE | null {
    let out: HighLightedTagsNew = this.tagsHighLightedR2.filter(
      (tagItem: HighLightedTagsNew) => tagToCheck === tagItem.tag
    )[0];
    if (out !== undefined) return out.state;
    else return null;
    //return this.tagsHighLightedR.filter((tagItem: highLightedTags) =>regexToCheck===tagItem.regex)[0].styleOrigins.length
  }

  // //TODO: remove after move to another version --- from here
  // getTagsHighLightedRHLStyle(regexToCheck: string): colors.LimSelTemp {
  //   if (this.tagsHighLightedR === undefined || this.tagsHighLightedR === null) return null;
  //   else {
  //     let out: highLightedTags = this.tagsHighLightedR.filter(
  //       (tagItem: highLightedTags) => regexToCheck === tagItem.regex
  //     )[0];
  //     if (out !== undefined) return out.highlightedStyle;
  //     else return null;
  //   }
  // }
  // //TODO: remove after move to another version --- to here

  getTagsHighLightedRHLStyleNew(tagToCheck: string): colors.LimSelTemp {
    if (this.tagsHighLightedR2 === undefined || this.tagsHighLightedR2 === null) return null;
    else {
      let out: HighLightedTagsNew = this.tagsHighLightedR2.filter(
        (tagItem: HighLightedTagsNew) => tagToCheck === tagItem.tag
      )[0];
      if (out !== undefined) return out.highlightedStyle;
      else return null;
    }
  }

  //4. step loop
  // //TODO: remove after move to another version --- FROM here
  // async updateHighLitedInDoc(regexListofItemToHighligt: string[]) {
  //   log.info(limaLogTag.LimaTagsStore, "updateHighLitedInDoc: processing ", regexListofItemToHighligt);
  //   if (this.tagsHighLightedR === undefined || this.tagsHighLightedR === null) {
  //     log.info(limaLogTag.LimaTagsStore, "updateHighLitedInDoc: tagsHighLightedR is undefined or null");
  //     return;
  //   }

  //   this.tagsHighLightedR.forEach((itemHightLigt: highLightedTags) => {
  //     //if has something to hide
  //     log.info(limaLogTag.LimaTagsStore, "updateHighLitedInDoc: for item", itemHightLigt);
  //     if (
  //       itemHightLigt.returnOrigStyles !== undefined &&
  //       itemHightLigt.returnOrigStyles !== [] &&
  //       itemHightLigt.returnOrigStyles.length > 0
  //     ) {
  //       //I have something to clear
  //       log.info(
  //         limaLogTag.LimaTagsStore,
  //         `updateHighLitedInDoc: returnOrig not null for [${itemHightLigt.regex}] return style [${itemHightLigt.returnOrigStyles}]`
  //       );
  //       //this.highlightWithoutIdsRestore(itemHightLigt.regex,itemHightLigt.returnOrigStyles,30)
  //       this.highlightWithoutIdsRestore(itemHightLigt, 30);
  //       /*itemHightLigt.returnOrigStyles.forEach((item:string)=>{
  //         this.highlightWithoutIdsRestore(itemHightLigt.regex,item,true,true)
  //       })*/
  //     } else if (regexListofItemToHighligt.includes(itemHightLigt.regex)) {
  //       //I have something to set
  //       log.info(limaLogTag.LimaTagsStore, "updateHighLitedInDoc: is in highlihgt");
  //       //this.highlightWithoutIdsSet(itemHightLigt.regex,itemHightLigt.highlightedStyle.style,true,true)
  //       //this.highlightWithoutIdsSet(itemHightLigt.regex,itemHightLigt.highlightedStyle.style,30)
  //       this.highlightWithoutIdsSet(itemHightLigt, 30);
  //       //this.highlightWithoutIdsSet(regex.REGEX_HIGHLIGHT.paragraph,itemHightLigt.highlightedStyle.style)
  //       //this.highlightWithoutIds(regex.REGEX_HIGHLIGHT.paragraph,colors.HIGHLIGHT.blue)
  //     } else {
  //       log.info(limaLogTag.LimaTagsStore, "updateHighLitedInDoc: nothing to do");
  //     }
  //     //if has something to show
  //   });
  // }
  // //TODO: remove after move to another version --- to here

  async updateHighLitedInDocTags() {
    //async updateHighLitedInDocTags(listofItemToHighligt: string[]) {
    //log.info(limaLogTag.LimaTagsStore, "updateHighLitedInDocTags: processing ", listofItemToHighligt);
    if (this.tagsHighLightedR2 === undefined || this.tagsHighLightedR2 === null) {
      log.info(limaLogTag.LimaTagsStore, "updateHighLitedInDocTags: tagsHighLightedR is undefined or null");
      return;
    }

    this.tagsHighLightedR2.forEach((itemHightLigt: HighLightedTagsNew) => {
      //if has something to hide
      log.info(limaLogTag.LimaTagsStore, "updateHighLitedInDocTags: for item", itemHightLigt);
      if (itemHightLigt.state === HLSTATE.TOREMOVEHL) {
        //I have something to clear
        log.info(limaLogTag.LimaTagsStore, `updateHighLitedInDocTags: remove highlihgt on`, itemHightLigt);
        itemHightLigt.contextTags.forEach((items: HighLightedTagsSingle) => {
          this.highlightWithoutIdsRestoreTag(items);
        });
        itemHightLigt.state = HLSTATE.UNHIGHLIGHTED;
      } else if (itemHightLigt.state === HLSTATE.TOADDHL) {
        //I have something to set
        log.info(limaLogTag.LimaTagsStore, "updateHighLitedInDocTags: highlight item", itemHightLigt);
        itemHightLigt.contextTags.forEach((items: HighLightedTagsSingle) => {
          this.highlightWithoutIdsSetTag(items);
        });
        itemHightLigt.state = HLSTATE.HIGHLIGHTED;
      } else {
        log.info(limaLogTag.LimaTagsStore, "updateHighLitedInDocTags: nothing to do");
      }
      //if has something to show
    });
  }

  // //TODO: remove after move to another version --- from here
  // highlightWithoutIdsRestore(itemHightLigt: highLightedTags, maxLenght?: number) {
  //   OfficeExtension.config.extendedErrorLogging = true;

  //   return Word.run(context => {
  //     const searchResults = context.document.body.search(itemHightLigt.regex, {
  //       ignoreSpace: true,
  //       matchWildcards: true
  //     });
  //     //log.info(limaLogTag.LimaTagsStore, "highlightWithoutIdsRestore: highlihg result",searchResults);
  //     //context.load(searchResults,'font'); //TODO: remove fotn
  //     context.load(searchResults);

  //     return context.sync().then(() => {
  //       //log.info(limaLogTag.LimaTagsStore, "highlightWithoutIdsRestore: count",searchResults.items.length);
  //       // Queue a set of commands to change the font for each found item.
  //       for (let i = 0; i < searchResults.items.length; i++) {
  //         if (maxLenght !== undefined && searchResults.items[i].text.length > maxLenght) continue;
  //         log.info(limaLogTag.LimaTagsStore, "highlightWithoutIdsRestore: styleOrigins", itemHightLigt.styleOrigins);
  //         searchResults.items[i].style =
  //           itemHightLigt.styleOrigins.length > 0 ? itemHightLigt.styleOrigins.pop() : "Normální"; //TODO: "Normal to env"
  //       }
  //       return context.sync();
  //     });
  //   }).catch(function(error) {
  //     console.log("Error: " + JSON.stringify(error));
  //     if (error instanceof OfficeExtension.Error) {
  //       console.log("Debug info: " + JSON.stringify(error.debugInfo));
  //     }
  //   });
  // }
  // //TODO: remove after move to another version --- to here

  /**
   * Reseting new hightlights in document exact tag when regext is not working
   *
   * @param itemTagHightLigt single tag that is goign to be searched and unhighlighted in document
   * @returns
   *
   * @author MS
   */

  highlightWithoutIdsRestoreTag(itemHightLigt: HighLightedTagsSingle) {
    OfficeExtension.config.extendedErrorLogging = true;

    return Word.run((context) => {
      const searchResults = context.document.body.search(itemHightLigt.contextTag, {
        ignoreSpace: true,
        matchWildcards: false,
      });
      context.load(searchResults);

      return context.sync().then(() => {
        // Queue a set of commands to change the font for each found item.
        for (let i = 0; i < searchResults.items.length; i++) {
          // log.info(limaLogTag.LimaTagsStore, "highlightWithoutIdsRestoreTag: styleOrigins", itemHightLigt.styleOrigins); //TODO: HERE IS PROBLEM DO A LOT OF TIMES
          searchResults.items[i].style =
            itemHightLigt.styleOrigins.length > 0 ? itemHightLigt.styleOrigins.pop() : "Normální"; //TODO: "Normal to env"
        }
        return context.sync();
      });
    }).catch(function (error) {
      console.log("Error: " + JSON.stringify(error));
      if (error instanceof OfficeExtension.Error) {
        console.log("Debug info: " + JSON.stringify(error.debugInfo));
      }
    });
  }

  // //TODO: remove after move to another version --- from here
  // /**
  //  * seting new hightlights in document
  //  *
  //  * @param regEx regext to search for
  //  * @param newStyle setting new style for searched
  //  * @returns
  //  */
  // highlightWithoutIdsSet(itemHightLigt: highLightedTags, maxLenght?: number) {
  //   return Word.run(async context => {
  //     //Word.run(async context => {
  //     //itemHightLigt.regexAlternatives.forEach(async (singleItemRegex: string) => {
  //     log.info(limaLogTag.LimaTagsStore, "highlightWithoutIdsSet: search for regex to setHighlight", itemHightLigt.regex);
  //     const searchResults = context.document.body.search(itemHightLigt.regex, {
  //       ignoreSpace: true,
  //       matchWildcards: true
  //     });
  //     //const searchResults = context.document.body.search('§170*{1,30}99', {ignoreSpace: true, matchWildcards: true});
  //     //const searchResults = context.document.body.search('172*{1;15}99/1963', {ignoreSpace: true, matchWildcards: true});
  //     //const searchResults = context.document.body.search(regEx,{ignoreSpace:ignoreSpace,matchWildCards: matchWildCards});
  //     log.info(limaLogTag.LimaTagsStore, "highlightWithoutIdsSet: highlihg result", searchResults);
  //     //context.load(searchResults);
  //     context.load(searchResults);

  //     //return context.sync().then( async () =>{
  //     return context.sync().then(async () => {
  //       log.info(limaLogTag.LimaTagsStore, "highlightWithoutIdsSet: count", searchResults.items.length);
  //       // Queue a set of commands to change the font for each found item.
  //       for (let i = 0; i < searchResults.items.length; i++) {
  //         log.info(limaLogTag.LimaTagsStore, "highlightWithoutIdsSet: wakt hrought searresults ", i);
  //         //COREECT long distance
  //         if (maxLenght !== undefined && searchResults.items[i].text.length > maxLenght) {
  //           log.info(
  //             limaLogTag.LimaTagsStore,
  //             "highlightWithoutIdsSet: maxLenght limit ",
  //             searchResults.items[i].text.length,
  //             searchResults.items[i].text
  //           );
  //         } else {
  //           //TODO:STORE ORIGN
  //           // log.info(limaLogTag.LimaTagsStore, "highlightWithoutIdsSet: item",searchResults.items[i], newStyle);
  //           let origStyle = searchResults.items[i].style;
  //           log.info(limaLogTag.LimaTagsStore, "highlightWithoutIdsSet: origin style", origStyle);
  //           //this.storeHighlightedOrig(regEx,i,typeof origStyle==="string"? origStyle:"Normální") //TODO: "Normal to env"
  //           this.storeHighlightedOrig(itemHightLigt.regex, i, origStyle);
  //           log.info(
  //             limaLogTag.LimaTagsStore,
  //             "highlightWithoutIdsSet: stored",
  //             searchResults.items[i],
  //             itemHightLigt.highlightedStyle
  //           );
  //           // searchResults.items[i].font.highlightColor = colors.HIGHLIGHT.blue;
  //           // searchResults.items[i].style = "Normal";
  //           searchResults.items[i].style = itemHightLigt.highlightedStyle.style;
  //           // log.info(limaLogTag.LimaTagsStore, "highlightWithoutIdsSet: highlight set");
  //           // searchResults.items[i].style = "Strong";
  //         }
  //       }
  //       return context.sync();
  //     });
  //   }).catch(function(error) {
  //     console.log("Error: " + JSON.stringify(error));
  //     if (error instanceof OfficeExtension.Error) {
  //       console.log("Debug info: " + JSON.stringify(error.debugInfo));
  //     }
  //   });
  // }
  // //TODO: remove after move to another version --- to here

  /**
   * seting new hightlights in document exact tag when regext is not working
   *
   * @param itemTagHightLigt single tag that is goign to be searched and highlighted in document
   * @returns
   *
   * @author MS
   */
  highlightWithoutIdsSetTag(itemTagHightLigt: HighLightedTagsSingle) {
    return Word.run(async (context) => {
      log.info(limaLogTag.LimaTagsStore, "highlightWithoutIdsSet: search for regex to setHighlight", itemTagHightLigt);
      const searchResults = context.document.body.search(itemTagHightLigt.contextTag, {
        ignoreSpace: true,
        matchWildcards: false,
      });

      log.info(limaLogTag.LimaTagsStore, "highlightWithoutIdsSet: highlihg result", searchResults);
      context.load(searchResults);

      return context.sync().then(async () => {
        log.info(limaLogTag.LimaTagsStore, "highlightWithoutIdsSet: count", searchResults.items.length);
        // Queue a set of commands to change the font for each found item.
        for (let i = 0; i < searchResults.items.length; i++) {
          log.info(limaLogTag.LimaTagsStore, "highlightWithoutIdsSet: wakt hrought searresults ", i);

          let origStyle = searchResults.items[i].style;
          log.info(limaLogTag.LimaTagsStore, "highlightWithoutIdsSet: origin style", origStyle);
          //this.storeHighlightedOrig(regEx,i,typeof origStyle==="string"? origStyle:"Normální") //TODO: "Normal to env"
          this.storeHighlightedOrigTag(itemTagHightLigt, i, origStyle);

          log.info(
            limaLogTag.LimaTagsStore,
            "highlightWithoutIdsSet: stored",
            searchResults.items[i],
            itemTagHightLigt
          );

          searchResults.items[i].style = itemTagHightLigt.highlightedStyle.style;
        }
        return context.sync();
      });
    }).catch(function (error) {
      console.log("Error: " + JSON.stringify(error));
      if (error instanceof OfficeExtension.Error) {
        console.log("Debug info: " + JSON.stringify(error.debugInfo));
      }
    });
  }

  // //TODO: remove after move to another version --- from here
  // clearHighlightedItems() {
  //   log.info(limaLogTag.LimaTagsStore, "clearHighlightedItems:cleaning highligter START");
  //   let currentItemsRegExList: string[] = [];
  //   this.tagsHighLightedR.forEach((tagItem: highLightedTags) => currentItemsRegExList.push(tagItem.regex));

  //   this.updateHighLightedR(currentItemsRegExList);
  //   this.updateHighLitedInDoc([]);
  //   this.tagsHighLightedHoverStore = [];
  //   limaTagsStore.dumpHighLigtedR();
  //   this.tagsHighLightedR.forEach((itemStored: highLightedTags) => {
  //     itemStored.returnOrigStyles = [];
  //   });
  //   log.info(limaLogTag.LimaTagsStore, "clearHighlightedItems:cleaning highligter FINISH");
  // }
  // //TODO: remove after move to another version --- to here

  clearHighlightedItemsNew() {
    log.info(limaLogTag.LimaTagsStore, "clearHighlightedItemsNew:cleaning highligter START");
    let currentItemsTagExList: string[] = [];
    this.tagsHighLightedR2.forEach((tagItem: HighLightedTagsNew) => currentItemsTagExList.push(tagItem.tag));

    this.updateHighLightedR(currentItemsTagExList, []);
    this.updateHighLitedInDocTags();
    this.tagsHighLightedHoverStore = [];
    limaTagsStore.dumpHighLigtedR();
    this.tagsHighLightedR2.forEach((itemStored: HighLightedTagsNew) => {
      itemStored.state = HLSTATE.UNHIGHLIGHTED;
    });
    log.info(limaLogTag.LimaTagsStore, "clearHighlightedItems:cleaning highligter FINISH");
  }

  judgeItemIdent(adviceText: advice_textsearch) {
    //return "?xx?*{1;30}?yy?".replace("?xx?", adviceText.parID).replace("?yy?", adviceText.actID);
    if (adviceText.keywordBase !== undefined && adviceText.keywordBase !== null && adviceText.keywordBase !== "")
      return adviceText.keywordBase;
    return "?xx?*?yy?".replace("?xx?", adviceText.parID).replace("?yy?", adviceText.actID);
  }
  //---------------------------------------------------------------------------------------------------
  //--------------------------------------------- vvvvv OLD one not neeeded vvvv -----------------------

  // setHighlightOption(highlight: boolean){
  //   this.highlightOption = highlight;
  // }

  // setHighlightColor(color: colors.HIGHLIGHT){
  //   this.highlightColor = color;
  // }

  // setHighlightColorRecovery(color: colors.HIGHLIGHT){
  //   this.highlightColorRecovery = color;
  // }

  // Add highlight to array of selectedIds
  // async setHighlight(selectedIds: Array<string>, regEx: regex.REGEX_HIGHLIGHT, color: colors.HIGHLIGHT){
  //   this.setHighlightColorRecovery(color)
  //   if(selectedIds.length < 1 || selectedIds == undefined || selectedIds == null){
  //     this.highlightWithoutIds(regEx, color)
  //   } else if(regEx == null){
  //     this.highlightWithoutRegEx(selectedIds, color)
  //   } else {
  //     this.highlightWithIds(selectedIds, regEx, color)
  //   }
  // }

  // highlightWithoutRegEx(selectedIds: Array<string>, color: colors.HIGHLIGHT){
  //   return Word.run(async context => {
  //     const paragraphs: Word.ParagraphCollection = context.document.body.paragraphs;
  //     context.load(paragraphs)
  //     await context.sync()

  //     // iterate all paragraphs
  //     for(let x = 0; x < paragraphs.items.length; x++){
  //       await context.sync()
  //       .then(async () => {

  //         const myPar: Word.Paragraph = paragraphs.items[x]
  //         const range: Word.Range = myPar.getRange();
  //         //@ts-ignore
  //         const bkmrks = range.getBookmarks(true, true);

  //         context.load(myPar);
  //         await context.sync()
  //         .then(() => {
  //           if(bkmrks.value.length>0){
  //             let paraId = bkmrks.value[0].split('_');
  //             selectedIds.forEach( sId => {
  //               if(sId == paraId[1]){
  //                 myPar.font.highlightColor = color
  //               }
  //             })
  //           }
  //         })
  //       })
  //     }
  //   })
  // }

  // highlightWithIds(selectedIds: Array<string>, regEx: regex.REGEX_HIGHLIGHT, color: colors.HIGHLIGHT) {
  //   return Word.run(async context => {
  //     const paragraphs: Word.ParagraphCollection = context.document.body.paragraphs;
  //     context.load(paragraphs);
  //     await context.sync();

  //     // iterate all paragraphs
  //     for (let x = 0; x < paragraphs.items.length; x++) {
  //       await context.sync().then(async () => {
  //         const myPar: Word.Paragraph = paragraphs.items[x];
  //         const range: Word.Range = myPar.getRange();
  //         //@ts-ignore
  //         const bkmrks = range.getBookmarks(true, true);

  //         context.load(myPar);
  //         await context.sync().then(() => {
  //           if (bkmrks.value.length > 0) {
  //             let paraId = bkmrks.value[0].split("_");
  //             selectedIds.forEach(sId => {
  //               if (sId == paraId[1]) {
  //                 let result = myPar.search(regEx, { ignoreSpace: true, matchWildcards: true });
  //                 context.load(result, "font");
  //                 context.sync().then(() => {
  //                   for (let i = 0; i < result.items.length; i++) {
  //                     result.items[i].font.highlightColor = color;
  //                   }
  //                 });
  //               }
  //             });
  //           }
  //         });
  //       });
  //     }
  //   });
  // }

  // highlightWithoutIds(regEx: regex.REGEX_HIGHLIGHT | string, color: colors.HIGHLIGHT) {
  //   return Word.run(context => {
  //     // Queue a command to search the document
  //     console.log("search for", regEx);
  //     const searchResults = context.document.body.search(regEx, { ignoreSpace: true, matchWildcards: true });
  //     //const searchResults = context.document.body.search(regEx,{ignoreSpace:ignoreSpace,matchWildCards: matchWildCards});
  //     console.log("highlihg result", searchResults);
  //     context.load(searchResults, "font");
  //     return context.sync().then(function() {
  //       console.log("Found count: " + searchResults.items.length);
  //       // Queue a set of commands to change the font for each found item.
  //       for (let i = 0; i < searchResults.items.length; i++) {
  //         searchResults.items[i].font.highlightColor = color;
  //       }
  //       return context.sync();
  //     });
  //   }).catch(function(error) {
  //     console.log("Error: " + JSON.stringify(error));
  //     if (error instanceof OfficeExtension.Error) {
  //       console.log("Debug info: " + JSON.stringify(error.debugInfo));
  //     }
  //   });
  // }

  async removeHighlight() {
    return Word.run(async (context) => {
      const paragraphs: Word.ParagraphCollection = context.document.body.paragraphs;
      context.load(paragraphs);
      await context.sync();

      return;
      //TODO: remove highlight better weay
      // iterate all paragraphs
      for (let x = 0; x < paragraphs.items.length; x++) {
        await context.sync().then(async () => {
          const myPar: Word.Paragraph = paragraphs.items[x];
          const range: Word.Range = myPar.getRange();
          //@ts-ignore
          const bkmrks = range.getBookmarks(true, true);

          context.load(myPar);
          await context.sync().then(() => {
            myPar.font.highlightColor = null;
          });
        });
      }
    });
  }

  // setTagsHighLighted(newHighLighedList: advice_textsearch[] | string[]) {
  //   this.tagsHighLighted = newHighLighedList;
  // }

  // get getTags() {
  //   return this.tagsHighLighted;
  // }

  addContextTag(newTag: advice_textsearch) {
    log.info(limaLogTag.LimaTagsStore, `addContextTag: storing new tag to data`, newTag);
    this.allContextTags.push(newTag);
    log.info(limaLogTag.LimaTagsStore, `addContextTag: now is`, this.allContextTags);
  }

  clearContextTag() {
    log.info(limaLogTag.LimaTagsStore, `clearContextTag:`);
    this.allContextTags = [];
    log.info(limaLogTag.LimaTagsStore, `clearContextTag: now is`, this.allContextTags);
  }

  /*
  //TODO: infuture prepare checkandupdate
  checkAndUpdateTagsHighLigted(checkHighLighedList: advice_textsearch[] | string[]){
    if(typeof this.tagsHighLighted[0] === "string" && typeof checkHighLighedList[0] === "string")
      let added = checkHighLighedList.filter((item: string) => this.tagsHighLighted.includes(item)))

    }
  }*/

  getRegexActParJS(actPar: advice_textsearch) {
    //log.info(limaLogTag.LimaTagsStore, `getRegexActParJS: Start`);
    let signsFiltered = this.signAlts.filter((item: signsAlternatives) => item.mainSign === actPar.actID);
    let altSignsReg = signsFiltered[0].altSigns.reduce((altSignsReg, item: string) => {
      // if (altSignsReg !== "") altSignsReg += "|";
      // altSignsReg += item;
      altSignsReg += "|" + item;
      return altSignsReg;
    });
    //log.info(limaLogTag.LimaTagsStore, `getRegexActParJS: altSignsReg [${altSignsReg}]`);
    const searchString = "(§.{0,1}?" + actPar.parID + ".{0,30}?(" + altSignsReg + "))";
    log.info(limaLogTag.LimaTagsStore, `getRegexActParJS: searchString [${searchString}]`);
    return searchString;
  }
  /**
   * Method generate JS regex for KeyWord
   * @param actPar advice_textsearch with item to search
   * @returns string
   * @author MS
   */
  getRegexKeyWordsJS(actPar: advice_textsearch) {
    //log.info(limaLogTag.LimaTagsStore, `getRegexKeyWordsJS: Start`);
    //log.info(limaLogTag.LimaTagsStore, `getRegexKeyWordsJS:  signAlts`, this.signAlts);
    let signsFiltered = limaKeywordsStore.keywordsListString.filter(
      (item: adviceKeywordStringList) => item.base === actPar.keywordBase
    );
    let altSignsReg = signsFiltered[0].jsRegEx;
    //log.info(limaLogTag.LimaTagsStore, `getRegexKeyWordsJS: altSignsReg [${altSignsReg}]`);
    const searchString = "(" + altSignsReg + ")";
    log.info(limaLogTag.LimaTagsStore, `getRegexKeyWordsJS: searchString [${searchString}]`);
    return searchString;
  }

  /**
   * Cleanup on logout button
   *
   * @autor MS
   */
  onLogoutClear() {
    this.highlightOption = false;
    this.highlightColor = null;
    this.highlightColorRecovery = null;
    this.tagsHighLighted = [];
    this.tagsHighLightedHoverStore = [];
    this.allContextTags = [];
    this.signAlts = [];
    this.tagsHighLightedR2 = [];
  }
}

const limaTagsStore = new LimaTagsStore();

// autorun( async() => {
//   if(!limaTagsStore.highlightOption){
//       await limaTagsStore.removeHighlight()
//       limaTagsStore.setHighlightColor(null)
//   } else {
//     limaTagsStore.setHighlightColor(limaTagsStore.highlightColorRecovery)
//   }
// })

export default limaTagsStore;
