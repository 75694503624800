import { IDropdownOption } from "@fluentui/react";
import { log } from "missionlog";
import { action, makeObservable, observable, computed } from "mobx";
import moment = require("moment");
import { urlUserAdd, urlUserGet, urlUserUpdate, userLinkingState, urlGetAllLinkedUsers } from "../api/apilinks";
import { httpGetAuth, httpPost, HttpResponse } from "../api/httpAPI";
import { edgeVisibilityOK, userGetOK } from "../api/schema/user/getUserRes";
// import userAccListDataType, {} from "../dataModels/userAccListDataType";
import { limaLogTag } from "../limaCommon/limaLog";
import { deduplicateArray } from "../limaCommon/utils";
import { IUser, User_getNull } from "../types/User";
import limaLoadeStore, { LoaderItemEnd } from "./limaLoaderStore";
import limaStore from "./limaStore";

//log.init({ LimaUserStore: "LimaUserStore" });

class LimaUsersStore {
  // limaStore: LimaStore;
  list: IUser[] = [];
  fullList: IUser[] = [];
  //currentSelectedItem: User = UserDef;
  isLoading = false;

  currentUser: IUser = User_getNull();
  loadingData = false;
  totalItemCount = 0;
  lastDBReadDate: string | null = null;

  constructor() {
    makeObservable(this, {
      list: observable,
      fullList: observable,
      totalItemCount: observable,
      loadingData: observable,
      currentUser: observable,

      setLoadingData: action,
      setTotalItemCount: action,
      // setCurrentSelectedItem: action,
      setCurrentUser: action,
      getDataFromDB: action,
      setList: action,
      setFullList: action,
      onFilterChanged: action,
      updListSingleItem: action,
      addListSingleItem: action,

      getUsersListForAssign: computed,
      getUsersListForAssignIncludeMe: computed,
      getAllUsersCount: computed,
    });
    // this.limaStore = limaStore;
  }

  /**
   * on logout clear
   */
  onLogoutClear(): void {
    this.setList([]);
    this.setFullList([]);
    this.lastDBReadDate = null;
    this.totalItemCount = 0;
    this.loadingData = false;
    this.setCurrentUser(User_getNull());
  }

  setLoadingData(trueOrfalse: boolean) {
    this.loadingData = trueOrfalse;
  }

  setList(newList: IUser[]) {
    this.list = newList;
  }

  setFullList(newList: IUser[]) {
    this.fullList = newList;
    this.lastDBReadDate = moment().toString();
  }

  setTotalItemCount(newCount: number) {
    this.totalItemCount = newCount;
  }

  // setCurrentSelectedItem(selectedItem: User) {
  //   this.currentSelectedItem = selectedItem;
  // }

  updListSingleItem(listItemId: string, newItem: IUser) {
    log.info(limaLogTag.LimaUserStore, `updListSingleItem:  itemid ${listItemId} to change with `, newItem);
    //var foundIndex = this.fullList.findIndex(item => item.userId == listItemId);
    const updatedItems = this.fullList.map((el) => (el._key === listItemId ? newItem : el));
    this.setFullList(updatedItems);
    this.setList(updatedItems);
  }

  addListSingleItem(newItem: IUser) {
    log.info(limaLogTag.LimaUserStore, `addListSingleItem:  add new  `, newItem);
    this.fullList.push(newItem);
    this.list.push(newItem);
  }

  removeListSingleItem(listItemId: string) {
    log.info(limaLogTag.LimaUserStore, `removeListSingleItem:  itemid ${listItemId} to remove`);
    const updatedItems = this.fullList.filter((el: IUser) => el._key !== listItemId);
    this.setFullList(updatedItems);
    this.setList(updatedItems);
    this.setTotalItemCount(updatedItems.length);
  }

  /**
   * just simple call to check existence read from DB
   */
  checkLastRead = async () => {
    log.info(limaLogTag.LimaUserStore, `checkLastRead: ${String(this.lastDBReadDate === null)} `, this.lastDBReadDate);
    if (this.lastDBReadDate === null && this.isLoading === false) {
      await this.getDataFromDB();
    }
  };

  /**
   *
   * @returns
   */
  async getDataFromDB() {
    if (limaStore.userId == null || limaStore.userId == "") {
      log.warn(limaLogTag.LimaUserStore, `getDataFromDB:  userId ${limaStore.userId} is null/""`);
      return null;
    }
    log.info(limaLogTag.LimaUserStore, `getDataFromDB:  userId ${limaStore.userId}`);

    const loaderID = limaLoadeStore.add("Getting users from DB");
    let allUsers: HttpResponse<IUser[]>;
    try {
      this.isLoading = true;

      allUsers = await httpGetAuth<IUser[]>(urlGetAllLinkedUsers(), loaderID.ac);
      log.info(limaLogTag.LimaUserStore, "getDataFromDB: asketo to get something", allUsers);

      if (allUsers.parsedBody !== undefined) {
        log.info(limaLogTag.LimaUserStore, "getDataFromDB: parsedBody is not null");
        //Deduplicate users
        const deduplUsers = deduplicateArray(allUsers.parsedBody, "_key");

        this.setFullList(deduplUsers);
        this.setList(deduplUsers);
        limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      } else {
        log.error(limaLogTag.LimaUserStore, "getDataFromDB: no data", allUsers);
        limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      }
    } catch (response) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      log.error(limaLogTag.LimaUserStore, "getDataFromDB: Error call get User", allUsers);
    } finally {
      this.setTotalItemCount(this.fullList.length);
      this.isLoading = false;
    }
  }

  /**
   * Used for share edit user data
   * @param new user
   *
   */
  setCurrentUser(user: IUser) {
    this.currentUser = user;
  }

  /**
   * Wrapped getUserFromDB
   * @param userId userID
   * @author MS
   *
   * @deprecated no better in compoent by react query
   */
  // async getCurrentUserFromDB(userId: string) {
  //   log.info(limaLogTag.LimaUserStore, "getCurrentUserFromDB: ask to get current user from DB");
  //   const user = await this.getUserFromDB(userId);
  //   if (user !== null) this.setCurrentUser(user);
  // }

  /**
   * Method retrieve from DB user data for provided ID
   * @param {string} userID userID
   * @returns User or Null
   * @deprecated now better in compoent by react query
   *
   * @author MS
   */
  async getUserFromDB(userID: string): Promise<IUser | null> {
    log.info(limaLogTag.LimaUserStore, "getUserFromDB: ask to get user from DB");
    if (userID === "") {
      log.warn(limaLogTag.LimaUserStore, "getUserFromDB: no Id provided");
      return null;
    }
    const loaderID = limaLoadeStore.add("getUserFromDB: Get from DB");
    let docUseusersDatarsData: HttpResponse<IUser>;
    try {
      docUseusersDatarsData = await httpGetAuth<IUser>(urlUserGet(userID), loaderID.ac);
      log.info(limaLogTag.LimaUserStore, "getUserFromDB: call success");
      //this.setCurrentUser(docUseusersDatarsData.parsedBody);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return docUseusersDatarsData.parsedBody;
      //TODO: update in both list
    } catch (response) {
      log.error(limaLogTag.LimaUserStore, "getUserFromDB: call error", docUseusersDatarsData);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      return null;
    } finally {
      // limaLoadeStore.remove(loaderID.k);
      //this.setLoadingData(false);
    }
  }

  // /**
  //  * Wrapper for update user in DB
  //  * @author MS
  //  * @deprecated  no better in compoent by react query
  //  */
  // async updateCurrentUserToDB() {
  //   await this.updateSomeUserToDB(this.currentUser);
  // }

  /**
   * Metod that update data of user to DB
   * @param user user data to store to DB
   *
   * @author MS
   * @deprecated no better in compoent by react query
   */
  async updateSomeUserToDB(user: userGetOK) {
    const loaderID = limaLoadeStore.add("Updating user in DB");
    log.info(limaLogTag.LimaUserStore, `updateSomeUserToDB: i have user key [${user._key}]`);
    let dataReply: HttpResponse<userGetOK>; //TODO: change ANY
    try {
      const postData: userGetOK = {
        _id: user._id,
        _key: user._key,
        userName: user.userName,
        userEmail: user.userEmail,
        cTimeStamp: user.cTimeStamp,
        cIssuedBy: user.cIssuedBy,
        dTimeStamp: user.dTimeStamp,
        dIssuedBy: user.dIssuedBy,
        state: user.state,
      };

      dataReply = await httpPost(urlUserUpdate(user._key), postData, loaderID.ac);
      log.info(limaLogTag.LimaUserStore, "updateSomeUserToDB: call success", dataReply);
      if ("parsedBody" in dataReply) {
        this.updListSingleItem(user._key, {
          ...this.fullList.find((item) => item._key == user._key),
          userName: user.userName,
        });
      } else {
        log.info(limaLogTag.LimaUserStore, "updateSomeUserToDB: missing parsedBody");
      }
    } catch (response) {
      log.error(limaLogTag.LimaUserStore, "updateSomeUserToDB: call error", dataReply);
    } finally {
      limaLoadeStore.remove(loaderID.k);
    }
  }

  /**
   *
   * @param creatorId
   * @deprecated  no better in compoent by react query
   */
  async storeNewUser(creatorId: string) {
    // this.setLoadingData(true);
    log.info(limaLogTag.LimaUserStore, `storeNewUser: creator id [${creatorId}]`);
    let dataReply: HttpResponse<edgeVisibilityOK>; //TODO: change ANY
    const loaderID = limaLoadeStore.add("Store new user");
    try {
      const postData: userGetOK = {
        _id: null,
        _key: null,
        userName: this.currentUser.userName,
        userEmail: this.currentUser.userEmail,
        cIssuedBy: creatorId,
        cTimeStamp: new Date().toISOString(),
        dTimeStamp: "",
        state: userLinkingState.ACCEPTED,
      };
      dataReply = await httpPost(urlUserAdd(), postData, loaderID.ac); //TODO: change ANY
      log.info(limaLogTag.LimaUserStore, "storeNewUser: call success", dataReply);
      if ("parsedBody" in dataReply) {
        // console.log("lenght" + String(dataReply.parsedBody));
        // postData._id = dataReply.parsedBody._to;
        // postData._key = dataReply.parsedBody._to.replace("dUser/", "");
        // this.setCurrentUser(postData);
        // //Add to end of list
        // const item: User = UserDef;
        // item.docEdgeId = "";
        // item.edgeId = dataReply.parsedBody.key;
        // item.userId = postData._key;
        // item.userName = postData.userName;
        // this.addListSingleItem(item);
        //TODO: add to both list list
      } else {
        console.log("some error in reply");
      }
    } catch (response) {
      log.error(limaLogTag.LimaUserStore, "storeNewUser: call error", dataReply);
    } finally {
      //this.setLoadingData(false);
      limaLoadeStore.remove(loaderID.k);
    }
  }

  onFilterChanged = (_: never, text: string): void => {
    log.info(limaLogTag.LimaUserStore, "onFilterChanged: filter change", text.toLowerCase());

    this.setList(
      this.fullList.filter((item: IUser) => {
        if (item.userName === undefined || item.userName == null || item.userName == "") return false;
        else if (item.userName.toLowerCase().indexOf(text.toLowerCase()) >= 0) return true;
        return false;
      })
    );
  };

  /**
   * Return user name or "uknown" based on userId
   * @param id id of user
   * @returns string with user name or "uknown"
   */
  getUserName = (id: string, dontUseUnknow?: boolean): string => {
    log.info(limaLogTag.LimaUserStore, `getUserName: search for ${id} - check loged`);
    if (id == limaStore.userId) return limaStore.userName;

    log.info(limaLogTag.LimaUserStore, `getUserName: search for ${id}`, this.fullList);
    const found = this.fullList.filter((item: IUser) => item._key === id);
    log.info(limaLogTag.LimaUserStore, `getUserName: found for ${found.length}`, found);
    if (found && found[0]) return found[0].userName;
    if (dontUseUnknow === undefined || dontUseUnknow === false) return "*unknown*[" + id + "]";
    else return null;
  };

  getUserInitialsAndName = (id: string): string[] => {
    let name: string = null;
    let initialilesOut: string = null;
    log.info(limaLogTag.LimaUserStore, `getUserInitials: search for ${id} - check loged`);
    name = this.getUserName(id, true);

    if (name !== null) {
      const initialiles: string[] = name.split(" ");
      initialilesOut = initialiles[0].substring(0, 1);

      if (initialiles.length > 1) initialilesOut += initialiles[1].substring(0, 1);
    }

    return [initialilesOut, name];
  };

  /**
   * THis heals to create options list in dropdowns
   */
  get getUsersListForAssignIncludeMe(): IDropdownOption<{ key: string; text: string }>[] | null {
    log.info(limaLogTag.LimaUserStore, `getUsersListForAssignIncludeMe: `);

    const out = this.getUsersListForAssign.filter((item) => item.key !== limaStore.userId);
    out.unshift({ key: limaStore.userId, text: "Me" });

    log.info(limaLogTag.LimaUserStore, `getUsersListForAssignIncludeMe: `, out);
    return out;
  }

  get getUsersListForAssign(): IDropdownOption<{ key: string; text: string }>[] | null {
    log.info(limaLogTag.LimaUserStore, `getUsersListForAssign: `);
    const out = this.fullList.map((item: IUser) => {
      return { key: item._key, text: item.userName };
    });
    return out;
  }

  get getAllUsersCount(): number {
    return this.fullList.length;
  }
}

const limaUsersStore = new LimaUsersStore();
export default limaUsersStore;
