import { Task_clone, Task_getNull } from "./Task";
import {
  Task_withActaXcopy,
  Task_withActaXcopyParasCommentsTasksMessagesAttachements,
  Task_withActaXcopyParasCommentsTasksMessagesAttachements_getNull,
  Task_withActaXcopy_clone,
  Task_withActaXcopy_getNull,
} from "./Task_withActaXcopy";
import { IUser, User_clone, User_getNull } from "./User";
import { User2Task } from "./User2Task";

export interface User2Task_withActaXcopyParasCommentsTasksMessagesAttachements extends User2Task {
  _id: string;
  _key: string;
  task_withActaXcopyParasCommentsTasksMessagesAttachements: Task_withActaXcopyParasCommentsTasksMessagesAttachements;
  user: IUser;
  actaOwner: boolean;
}
export interface User2Task_withActaXcopy extends User2Task {
  _id: string;
  _key: string;
  task_withActaXcopy: Task_withActaXcopy;
  user: IUser;
  actaOwner: boolean;
}

export const User2Task_withActaXcopy_getNull = (): User2Task_withActaXcopy => {
  return {
    _id: null,
    _key: null,
    task_withActaXcopy: Task_withActaXcopy_getNull(),
    cIssuedBy: null,
    cTimeStamp: null,
    dIssuedBy: null,
    dtimestamp: null,
    task: Task_getNull(),
    user: User_getNull(),
    actaOwner: false,
  };
};

export const User2Task_withActaXcopyParasCommentsTasksMessagesAttachements_getNull =
  (): User2Task_withActaXcopyParasCommentsTasksMessagesAttachements => {
    return {
      _id: null,
      _key: null,
      task_withActaXcopyParasCommentsTasksMessagesAttachements:
        Task_withActaXcopyParasCommentsTasksMessagesAttachements_getNull(),
      cIssuedBy: null,
      cTimeStamp: null,
      dIssuedBy: null,
      dtimestamp: null,
      task: Task_getNull(),
      user: User_getNull(),
      actaOwner: false,
    };
  };

export const User2Task_withActaXcopy_clone = (user2taskToClone: User2Task_withActaXcopy): User2Task_withActaXcopy => {
  if (user2taskToClone == undefined) {
    return User2Task_withActaXcopy_getNull();
  }
  return {
    _id: user2taskToClone._id,
    _key: user2taskToClone._key,
    task_withActaXcopy: Task_withActaXcopy_clone(user2taskToClone.task_withActaXcopy),
    cIssuedBy: user2taskToClone.cIssuedBy,
    cTimeStamp: user2taskToClone.cTimeStamp,
    dIssuedBy: user2taskToClone.dIssuedBy,
    dtimestamp: user2taskToClone.dtimestamp,
    task: Task_clone(user2taskToClone.task),
    user: User_clone(user2taskToClone.user),
    actaOwner: user2taskToClone.actaOwner,
  };
};
