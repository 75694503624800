import { log, tag } from "missionlog";
import limaJudgeStore from "../store/limaJudgeStore";
import limaKeywordsStore from "../store/limaKeywordStore";
import limaTagsStore from "../store/limaTagsStore";
import { adviceKeywordStringList } from "../types/keywords";

log.init({ searchcontextHandler: "searchcontextHandler" });

/**
 * This method prepare current text dor context search by searchout all context tags according to list
 * @returns nothing
 */
export enum searchContextHandlerType {
  JUDGE = "JUDGE",
  KEYWORD = "KEYWORD",
  ACTPAR = "ACTPAR",
}

export function searchcontextHandler(type: searchContextHandlerType) {
  log.info(tag.searchcontextHandler, "searchcontextHandler: just start search");

  return Word.run(async (context) => {
    const wordBody: Word.Body = context.document.body;

    context.load(wordBody);
    context.sync().then(() => {
      const wordText: string = wordBody.text;
      log.info(tag.searchcontextHandler, "searchcontextHandler: ", wordText);
      let searchString = "";
      limaTagsStore.clearContextTag();
      switch (type) {
        case searchContextHandlerType.JUDGE:
        case searchContextHandlerType.ACTPAR:
          searchString = "(§.{0,1}?([0-9a-z]+).{0,30}?(" + limaJudgeStore.actSignListString + "))";
          searchTagJudi(searchString, wordText);
          break;
        case searchContextHandlerType.KEYWORD:
          limaKeywordsStore.keywordsListString.forEach((item: adviceKeywordStringList) =>
            searchTagKeyword("(" + item.officeStringSearch + ")", item.base, wordText)
          );
          searchString.substring(1);
          break;
      }

      // while ((result = myregexp.exec(wordText)) !== null) {
      //   log.info(tag.searchcontextHandler, `searchcontextHandler: found`, result);
      //   //TODO: check if already exists #38
      //   switch (type) {
      //     case searchContextHandlerType.JUDGE:
      //     case searchContextHandlerType.ACTPAR:

      //       break;
      //     case searchContextHandlerType.KEYWORD:
      //       limaTagsStore.addContextTag({
      //         actID: "",
      //         parID: "",
      //         keywordBase: result[1],
      //         adviceTag: result[1]
      //       });
      //       break;
      //   }

      // }
    });
    //await context.sync()
  });
}

function searchTagJudi(searchString: string, wordText: string) {
  log.info(tag.searchcontextHandler, `searchcontextHandler: '${searchString}'`);
  var myregexp = new RegExp(searchString, "g");
  console.log(myregexp);
  let result;

  while ((result = myregexp.exec(wordText)) !== null) {
    limaTagsStore.addContextTag({
      actID: result[3],
      parID: result[2],
      adviceTag: result[1], //,
      //searchStringRegEx: searchString,
    });
  }
}

function searchTagKeyword(searchString: string, base: string, wordText: string) {
  log.info(tag.searchcontextHandler, `searchcontextHandler: '${searchString}'`);
  var myregexp = new RegExp(searchString, "g");
  console.log(myregexp);
  let result;

  while ((result = myregexp.exec(wordText)) !== null) {
    limaTagsStore.addContextTag({
      actID: "",
      parID: "",
      keywordBase: base,
      adviceTag: result[1], //,
      //searchStringRegEx: searchString,
    });
  }
}
