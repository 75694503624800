import { BASE_LimaData } from "./BASE_LimaData";

/**
 * Basic set of permissions
 */
export interface ILimaPermission {
  general_permissions: limaPermissions[];
  company_permissions: limaPermissionsCompany[];
  acta_permissions: limaPermissionsActa[];
  isSet: boolean;
}

export interface limaPermissions extends BASE_LimaData {
  permissionIdent: string;
  actualState: boolean;
  userKey: string;
}

export interface limaPermissionsCompany extends limaPermissions {
  companykey: string;
}

export function is_limaPermissionsCompany(item: unknown): item is limaPermissionsCompany {
  return (item as limaPermissionsCompany).companykey !== undefined;
}

export interface limaPermissionsActa extends limaPermissions {
  actaKey: string;
}

export function is_limaPermissionsActa(item: unknown): item is limaPermissionsActa {
  return (item as limaPermissionsActa).actaKey !== undefined;
}

export interface UserPermissions {
  general_permissions: limaPermissions[];
  company_permissions: limaPermissionsCompany[];
  acta_permissions: limaPermissionsActa[];
}
