/**
 * Update task in DB
 * @param task task store to DB
 * @returns promise with taskData
 *
 * @author MS
 */

import { log } from "missionlog";
import { sanitizeJSON } from "../../limaCommon/limaJSONsendsUtils";
import { limaLogTag } from "../../limaCommon/limaLog";
import limaLoadeStore, { LoaderItemEnd } from "../../store/limaLoaderStore";
import { IAttachmentFileWithInfo } from "../../types/LimaAttachement";
import { Task } from "../../types/Task";
import { Task_withActaXcopy, toTask } from "../../types/Task_withActaXcopy";
import { urlCreateSingleTask2Full, urlStoreNewTask, urlUpdateSingleTask2 } from "../apilinks";
import { httpPostAuth, httpPostMulipartAuth, httpPutAuth, HttpResponse } from "../httpAPI";

export async function updateTaskData(task: Task_withActaXcopy, userId: string): Promise<Task_withActaXcopy> {
  //const [, { taskid, userid }] = params.queryKey;
  log.info(limaLogTag.CallUpdateTaskData, "updateTaskData: update task", task);
  if (!task || !userId) {
    log.warn(limaLogTag.CallUpdateTaskData, "updateTaskData: no task or userid");
    return new Promise((reject) => {
      reject(null);
    });
  }
  if (!task._id || !task._key) {
    log.warn(limaLogTag.CallUpdateTaskData, "updateTaskData: no task id or key -> new");
  }
  // return new Promise((resolve) => {
  //   resolve(task);
  // });
  const loaderID = limaLoadeStore.add("Update task data to DB");
  let response: HttpResponse<Task>;
  try {
    log.info(limaLogTag.CallUpdateTaskData, `updateTaskData: ready to send 1:`, task);
    log.info(limaLogTag.CallUpdateTaskData, `updateTaskData:  ready to send:`, sanitizeJSON(task));

    const taskToStore: Task = toTask(task);
    // window.console.log("TASK TO STORE", taskToStore);
    log.info(limaLogTag.CallUpdateTaskData, "updateTaskData: TASK TO STORE", taskToStore);
    //NEW
    if (!task._id || !task._key) {
      response = await httpPostAuth(urlStoreNewTask(), sanitizeJSON(taskToStore), loaderID.ac);
    }
    //UPDATE
    else {
      response = await httpPutAuth(urlUpdateSingleTask2(taskToStore._key), sanitizeJSON(taskToStore), loaderID.ac);
    }
    log.info(limaLogTag.CallUpdateTaskData, "updateTaskData: asketo to get something", response);

    if ("parsedBody" in response) {
      log.info(limaLogTag.CallUpdateTaskData, "updateTaskData: get response", response.parsedBody);
      const taskReturn: Task = response.parsedBody;
      task = { ...task, ...taskReturn };

      log.info(limaLogTag.CallUpdateTaskData, "updateTaskData: actual task", task);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return new Promise((resolve) => {
        resolve(task);
      });
    } else {
      //TODO: error handling
      log.warn(limaLogTag.CallUpdateTaskData, "onStore: no ParseBody ", response);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw new Error("onStore: ...Some error on store...");
    }
  } catch (response) {
    log.error(limaLogTag.CallUpdateTaskData, "getTaskData Error call get User", response);
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error("Problem geting data");
  } finally {
    //this.setLoadingData(false);
  }
}

export async function createOrUpadteTaskWithAtt(
  task: Task_withActaXcopy,
  files: IAttachmentFileWithInfo[]
): Promise<Task_withActaXcopy> {
  //const [, { taskid, userid }] = params.queryKey;
  log.info(limaLogTag.CallUpdateTaskData, "updateTaskData: update task", task);
  if (!task) {
    log.warn(limaLogTag.CallUpdateTaskData, "updateTaskData: no task or userid");
    return new Promise((reject) => {
      reject(null);
    });
  }
  if (!task._id || !task._key) {
    log.warn(limaLogTag.CallUpdateTaskData, "updateTaskData: no task id or key -> new");
  }
  // return new Promise((resolve) => {
  //   resolve(task);
  // });
  const loaderID = limaLoadeStore.add("Update task data to DB");
  let response: HttpResponse<Task>;
  try {
    log.info(limaLogTag.CallUpdateTaskData, `updateTaskData: ready to send 1:`, task);
    log.info(limaLogTag.CallUpdateTaskData, `updateTaskData:  ready to send:`, sanitizeJSON(task));

    const taskToStore: Task = toTask(task);
    // window.console.log("TASK TO STORE", taskToStore);
    log.info(limaLogTag.CallUpdateTaskData, "updateTaskData: TASK TO STORE", taskToStore);
    //NEW
    if (!task._id || !task._key) {
      const formData: FormData = new FormData();
      formData.append("task", JSON.stringify(taskToStore));
      files.forEach((singleFile: IAttachmentFileWithInfo) => {
        formData.append("file", singleFile.file);
      });
      files.forEach((singleFile: IAttachmentFileWithInfo) => {
        formData.append("fileDescs", singleFile.filedescription);
      });
      files.forEach((singleFile: IAttachmentFileWithInfo) => {
        formData.append("fileNames", singleFile.filename);
      });

      //response = await httpPostAuth(urlStoreNewTask(), sanitizeJSON(taskToStore), loaderID.ac);
      response = await httpPostMulipartAuth(urlCreateSingleTask2Full(), formData, loaderID.ac, true);
    }
    //UPDATE
    else {
      response = await httpPutAuth(urlUpdateSingleTask2(taskToStore._key), sanitizeJSON(taskToStore), loaderID.ac);
    }
    log.info(limaLogTag.CallUpdateTaskData, "updateTaskData: asketo to get something", response);

    if ("parsedBody" in response) {
      log.info(limaLogTag.CallUpdateTaskData, "updateTaskData: get response", response.parsedBody);
      const taskReturn: Task = response.parsedBody;
      task = { ...task, ...taskReturn };

      log.info(limaLogTag.CallUpdateTaskData, "updateTaskData: actual task", task);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return new Promise((resolve) => {
        resolve(task);
      });
    } else {
      //TODO: error handling
      log.warn(limaLogTag.CallUpdateTaskData, "onStore: no ParseBody ", response);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw new Error("onStore: ...Some error on store...");
    }
  } catch (response) {
    log.error(limaLogTag.CallUpdateTaskData, "getTaskData Error call get User", response);
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error("Problem geting data");
  } finally {
    //this.setLoadingData(false);
  }
}
