import React = require("react");
import { LimaIconProps } from "./DefLimaIconType";

export const LimaIconCompareActa = ({
  size = 32, // or any default size of your choice
  color = "black", // or any color of your choice
  fillColor = "black", // or any color of your choice
  title = "", //title
}: LimaIconProps): JSX.Element => {
  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 24 24"
    //   width={size} // added size here
    //   height={size} // added size here
    //   fill={color} // added color here
    //   aria-labelledby="title"
    // >
    //   <title id="title">{title}</title>
    //   <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
    //   <path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0 1 30.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1 0 80 0 40 40 0 1 0-80 0z" />
    // </svg>

    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      width={size} // added size here
      height={size} // added size here
      fill={fillColor} // added color here
      stroke={color}
      aria-labelledby="title"
    >
      <title id="title">{title}</title>

      <path
        d="M17.25 2A2.75 2.75 0 0 1 20 4.75v6.748a6.452 6.452 0 0 0-1.5-.422V4.75c0-.69-.56-1.25-1.25-1.25H4.75c-.69 0-1.25.56-1.25 1.25v12.5c0 .69.56 1.25 1.25 1.25h6.326c.081.523.224 1.026.422 1.5H4.75A2.75 2.75 0 0 1 2 17.25V4.75A2.75 2.75 0 0 1 4.75 2h12.5ZM9 13.44l6.47-6.47a.75.75 0 0 1 1.133.976l-.073.084-7 7a.75.75 0 0 1-.976.073l-.084-.073-3-3a.75.75 0 0 1 .976-1.133l.084.073L9 13.44Zm14 4.06a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0Zm-8.5-.5a.5.5 0 0 0 0 1h4.793l-1.647 1.646a.5.5 0 0 0 .708.708l2.5-2.5a.5.5 0 0 0 0-.708l-2.5-2.5a.5.5 0 0 0-.708.708L19.293 17H14.5Z"
        fill="#212121"
      />
    </svg>
  );
};
