import { mergeStyleSets, Stack } from "@fluentui/react";
import { FunctionComponent, useState } from "react";
import React = require("react");
import Moment from "react-moment";
import limaStore from "../../../../../../store/limaStore";
import { History_comments, LimactaComment } from "../../../../../../types/LimactaComment";
import CommentGroup from "./CommentGroup";

interface CommentTimeGroupProps {
  item: History_comments;
  hidden?: boolean;
}

const classNames = mergeStyleSets({
  groupwrapper: {
    cursor: "pointer",
    marginTop: 5,
  },
});

const CommentTimeGroup: FunctionComponent<CommentTimeGroupProps> = ({ item, hidden }: CommentTimeGroupProps) => {
  const [hideComments, setHideComments] = useState<boolean>(hidden !== undefined && hidden === false ? false : true);

  // useEffect(() => {
  //   window.console.log(">>>>>>>>", hidden, hideComments);
  // }, []);

  const onClick = () => {
    setHideComments(!hideComments);
  };

  return (
    <Stack onClick={onClick} className={classNames.groupwrapper}>
      <Stack.Item>
        State at: <Moment format={limaStore.userDateTimeFormat}>{item.timestamp}</Moment> count - [
        {item.comments.length}] {hideComments && " click to show"}
        {/* {String(hideComments)} */}
      </Stack.Item>
      {/* <Stack.Item>{hideComments}</Stack.Item> */}
      {hideComments !== true && (
        <Stack.Item>
          {item.comments.map((items: LimactaComment[], index: number) => (
            <CommentGroup key={"_comG_" + String(index)} commentGrp={items}></CommentGroup>
          ))}
        </Stack.Item>
      )}
    </Stack>
  );
};

export default CommentTimeGroup;
