import { KCTokens } from "./AccesTokens";

export interface actPar {
  actid: string;
  actyear: string;
  actname: string;
  parid: string;
  supparid: string;
  partext: string;
}

export enum DIALOGTYPE_ENUM {
  ACTPAR = "ACTPAR",
  JUDGE = "JUDGE",
  LOGIN = "LOGIN",
}

export enum DIALOGMESSAGETYPE_ENUM {
  STATE = "STATE",
  DATA = "DATA",
}

export enum DIALOGSTATETYPE_ENUM {
  OPEN = "OPEN",
  DATA_RECIEVED = "DATA_RECIEVED",
  DATA_REQUESTED = "DATA_REQUESTED",
}
export interface DialogParentMessage {
  dialogType: DIALOGTYPE_ENUM;
  messageType: DIALOGMESSAGETYPE_ENUM;
  messageData: DIALOGSTATETYPE_ENUM | KCTokens | string;
}
