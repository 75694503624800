import { log, tag } from "missionlog";
import { action, makeObservable, observable } from "mobx";
import { httpGet, httpPost, httpPostMulipart, HttpResponse } from "../api/httpAPI";

import {
  urlAddAttach2Note,
  urlAddNote,
  urlGetNotes4Acta,
  urlRemoveAttachFromNote,
  urlUpdateNote,
} from "../api/apilinks";
import { defaultNoteItem, getNoteItemOK, noteAttachement } from "../api/schema/notes/getNotes4ActaResp";
import { storeNewNoteAttOK } from "../api/schema/notes/storeNewNoteAttResp";
import limaLoadeStore from "./limaLoaderStore";

log.init({ LimaNotesStore: "LimaNotesStore" });

class LimaNotesStore {
  actId = "";
  userId = "";
  list: getNoteItemOK[] = [];
  fullList: getNoteItemOK[] = [];
  loadingData = false;
  storingData = false;
  totalItemCount = 0;
  currentNote: getNoteItemOK = defaultNoteItem;

  constructor() {
    makeObservable(this, {
      actId: observable,
      list: observable,
      loadingData: observable,
      totalItemCount: observable,
      currentNote: observable,
      storingData: observable,

      setActVal: action,
      setLoadingData: action,
      setNoteStoring: action,
      getDataFromDB: action,
      setList: action,
      onFilterChanged: action,
      setTotalItemCount: action,
      setCurrentNote: action,
      onAttStore: action,
    });
  }

  setActVal(actId: string, userId: string) {
    this.actId = actId;
    this.userId = userId;

    if (this.userId == null || this.userId == "") {
      this.list = [];
      this.fullList = [];
      this.totalItemCount = 0;
      return;
    } else void this.getDataFromDB().then(() => this.setTotalItemCount(this.fullList.length));
  }

  async getDataFromDB() {
    if (this.actId == null || this.actId == "") {
      log.warn(tag.LimaNotesStore, `getDataFromDB: actId ${this.actId} is null/"" - I get All`);
    }
    this.setLoadingData(true);
    let listData: HttpResponse<getNoteItemOK[]>;
    const loaderID = limaLoadeStore.add("getting data from DB");
    try {
      listData = await httpGet<getNoteItemOK[]>(urlGetNotes4Acta(this.actId, this.userId), loaderID.ac);
      log.info(tag.LimaNotesStore, "getDataFromDB: asketo to get something");
      if (listData.parsedBody !== undefined) {
        log.info(tag.LimaNotesStore, "getDataFromDB: parsedBody is not null");
        log.info(tag.LimaNotesStore, "getDataFromDB: data");
        this.setList(listData.parsedBody);
        this.setFullList(listData.parsedBody);

        log.info(tag.LimaNotesStore, "getDataFromDB: load finish", listData);
      }
      this.setLoadingData(false);
    } catch (response) {
      log.error(tag.LimaNotesStore, "getDataFromDB Error call get User", listData);
      this.setLoadingData(false);
    } finally {
      limaLoadeStore.remove(loaderID.k);
    }
  }

  setCurrentNote(note: getNoteItemOK) {
    this.currentNote = note;
  }

  setCurrentNoteShort(noteShort: string) {
    this.setCurrentNote({ ...this.currentNote, noteShort: noteShort });
  }

  setLoadingData(trueOrfalse: boolean) {
    this.loadingData = trueOrfalse;
  }
  setNoteStoring(trueOrFalse: boolean) {
    this.storingData = trueOrFalse;
  }

  setList(newList: getNoteItemOK[]) {
    this.list = newList;
  }

  setTotalItemCount(newCount: number) {
    this.totalItemCount = newCount;
  }

  setFullList(newList: getNoteItemOK[]) {
    this.fullList = newList;
  }

  onFilterChanged = (_: any, text: string): void => {
    log.info(tag.LimaNotesStore, "onFilterChanged: filter change", text.toLowerCase());

    this.setList(
      this.fullList.filter(function (item) {
        if (item.content === undefined || item.content == null || item.content == "") return false;
        else if (item.content.toLowerCase().indexOf(text.toLowerCase()) >= 0) return true;
        return false;
      })
    );
  };

  //TODO: when is no attached yet, it not store at all
  async onAttStore(file: File) {
    this.setNoteStoring(true);
    const attachments: noteAttachement[] = [
      {
        fileName: file.name,
        description: file.name,
        fileUri: "",
      },
    ];

    const formdata = new FormData();
    formdata.append("key", this.currentNote._key.toString());
    formdata.append("attachments", new Blob([JSON.stringify(attachments)], { type: "application/json" }));
    formdata.append("files", file);

    //formdata.append('key', keyOfNote, { type: "application/json" });
    //formdata.append('attachments', new Blob([attach], { type: "application/json" }));

    let responseData: HttpResponse<storeNewNoteAttOK>;
    const loaderID = limaLoadeStore.add("onAttStore");
    try {
      responseData = await httpPostMulipart<storeNewNoteAttOK>(urlAddAttach2Note(), formdata, loaderID.ac);
      log.info(tag.LimaNotesStore, "onAttStore: asketo to get something attachement");
      if (responseData.parsedBody !== undefined) {
        log.info(tag.LimaNotesStore, "onAttStore: parsedBody is not null");
        log.info(tag.LimaNotesStore, "onAttStore: data");
        //var att = JSON.parse(this.currentNote.noteAttachment)
        //console.log(att)
        //log.info(tag.LimaNotesStore, "onAttStore: data", att)

        console.log(responseData.parsedBody); //TODO: now we expect just one file upload at once
        //att.push(responseData.parsedBody);
        log.info(tag.LimaNotesStore, "onAttStore: data", responseData.parsedBody);
        this.setCurrentNote({
          ...this.currentNote,
          noteAttachment: JSON.stringify(responseData.parsedBody),
        });
      } else {
        log.info(tag.LimaNotesStore, "onAttStore: parsedBody exist but is null");
      }
      this.setNoteStoring(false);
    } catch (response) {
      log.info(tag.LimaNotesStore, "getDataFromDB: Error on storing attachement");
      this.setNoteStoring(false);
    } finally {
      limaLoadeStore.remove(loaderID.k);
    }
  }

  //BUG: after remove remove also from lists
  async onAttRemove(singleAtt: noteAttachement) {
    this.setNoteStoring(true);
    const attachments: noteAttachement[] = [
      {
        fileName: singleAtt.fileName,
        description: singleAtt.description,
        fileUri: singleAtt.fileUri,
      },
    ];

    //const raw = JSON.stringify();
    const removeAttachment = singleAtt.fileName.split("_");
    //formdata.append('attachments', new Blob([attach], { type: "application/json" }));
    const loaderID = limaLoadeStore.add("Remove attachement");
    let responseData: HttpResponse<storeNewNoteAttOK>;
    try {
      responseData = await httpPost<storeNewNoteAttOK>(urlRemoveAttachFromNote(), attachments, loaderID.ac, {
        key: this.currentNote._key.toString(),
      });
      log.info(tag.LimaNotesStore, "onAttRemove: asketo to get something");
      if (responseData.parsedBody !== undefined) {
        log.info(tag.LimaNotesStore, `onAttRemove: parsedBody is not null - looks we remove att ->`, removeAttachment);

        const attList = JSON.parse(this.currentNote.noteAttachment);
        console.log(attList);
        const outAttList = attList.filter(function (att: noteAttachement) {
          return att.fileName != singleAtt.fileName;
        });
        console.log(outAttList);
        log.info(tag.LimaNotesStore, "onAttRemove: start updating list");
        this.setCurrentNote({
          ...this.currentNote,
          noteAttachment: JSON.stringify(outAttList),
        });
        log.info(tag.LimaNotesStore, "onAttRemove: list should go to update");
      }
      this.setNoteStoring(false);
    } catch (response) {
      log.info(tag.LimaNotesStore, "onAttRemove: Error on remnoving attachement");
      this.setNoteStoring(false);
    }
  }

  //TODO: update or store new note
  async onNoteUpdate() {
    log.info(tag.LimaNotesStore, "onNoteUpdate: Start updating note");

    const data = {
      id: this.currentNote._id,
      content: this.currentNote.content, //TODO: in future udpate to editor
      lcIssuedBy: this.userId,
      actaId: this.actId,
      shortNote: this.currentNote.noteShort,
    };

    let responseData: HttpResponse<any>; //TODO: change to correct type
    const loaderID = limaLoadeStore.add("onNoteUpdate");
    try {
      responseData = await httpPost<any>(urlUpdateNote(), data, loaderID.ac, {
        key: this.currentNote._key.toString(),
      }); //TODO: Change to correct type
      log.info(tag.LimaNotesStore, "onNoteUpdate: asketo to get something");
      if (responseData.parsedBody !== undefined) {
        log.info(tag.LimaNotesStore, `onNoteUpdate: parsedBody is not null`);

        console.log(responseData.parsedBody);
        log.info(tag.LimaNotesStore, "onAttRemove: start updating list");
        //Already updated sou not need to update
        /*this.setCurrentNote({
                    ...this.currentNote,
                    noteAttachment: JSON.stringify(responseData.parsedBody)
                });*/

        log.info(tag.LimaNotesStore, "onNoteUpdate: edit shoud be updated");

        //TODO: Update list
      }
      this.setLoadingData(false);
    } catch (response) {
      log.info(tag.LimaNotesStore, "onNoteUpdate: Error on updateing note attachement");
      this.setLoadingData(false);
    } finally {
      limaLoadeStore.remove(loaderID.k);
    }
  }

  addListSingleItem(newItem: getNoteItemOK) {
    log.info(tag.LimaUserStore, `addListSingleItem:  add new  `, newItem);
    this.fullList.push(newItem);
    this.list.push(newItem);
  }

  removeListSingleItem(noteItemId: string) {
    log.info(tag.LimaUserStore, `removeListSingleItem:  itemid ${noteItemId} to remove`);
    const updatedItems = this.fullList.filter((el: getNoteItemOK) => el._key !== noteItemId);
    this.setFullList(updatedItems);
    this.setList(updatedItems);
  }

  async onNoteStore(attFiles: File[]) {
    this.setNoteStoring(true);
    const attachments: noteAttachement[] = [];

    log.info(tag.LimaNotesStore, `onNoteStore: stonring`);
    console.log(attFiles);
    console.log(attFiles.length);
    for (let i = 0; i < attFiles.length; i++) {
      const newAttachment = {
        fileName: attFiles[i].name,
        description: attFiles[i].name,
        fileUri: "",
      };
      console.log(newAttachment);
      attachments.push(newAttachment);
      //myFiles.push(files);
    }

    console.log(attachments);
    const attachmentsJson = JSON.stringify(attachments);

    const fnote = JSON.stringify({
      actaId: this.actId,
      userId: this.userId,
      cIssuedBy: this.userId,
      lcIssuedBy: this.userId, //TODO: LU or LC?
      dIssuedBy: "",
      cTimeStamp: new Date(Date.now()).toISOString().replace("T", " "), //"2020-01-01 10:00:00"
      dTimeStamp: "",
      lcTimeStamp: "",
      content: this.currentNote.content,
      noteShort: this.currentNote.noteShort,
      noteAttachment: "",
    });

    const formData = new FormData();

    formData.append("fnote", new Blob([fnote], { type: "application/json" }));
    formData.append("attachments", new Blob([attachmentsJson], { type: "application/json" }));

    attFiles.forEach((file) => {
      formData.append("files", file);
    });

    let responseData: HttpResponse<any>; //TODO: change to correct type
    const loaderID = limaLoadeStore.add("onNoteStore");
    try {
      responseData = await httpPostMulipart<any>(urlAddNote(), formData, loaderID.ac, { redirect: "follow" }); //TODO: change to correct type
      log.info(tag.LimaNotesStore, "onNoteStore: asketo to get something attachement");
      if (responseData.parsedBody !== undefined) {
        log.info(tag.LimaNotesStore, "onNoteStore: parsedBody is not null");
        log.info(tag.LimaNotesStore, "onNoteStore: data");

        console.log(responseData.parsedBody); //TODO: now we expect just one file upload at once

        log.info(tag.LimaNotesStore, "onNoteStore: data", responseData.parsedBody);
        //TODO: update list
        void this.getDataFromDB();
      } else {
        log.info(tag.LimaNotesStore, "onNoteStore: parsedBody exist but is null");
      }
      this.setNoteStoring(false);
    } catch (response) {
      log.info(tag.LimaNotesStore, "onNoteStore: Error on storing attachement");
      this.setNoteStoring(false);
    } finally {
      limaLoadeStore.remove(loaderID.k);
    }
  }

  /**
   * Cleanup on logout button
   *
   * @autor MS
   */
  onLogoutClear() {
    this.actId = "";
    this.userId = "";
    this.list = [];
    this.fullList = [];
    this.loadingData = false;
    this.storingData = false;
    this.totalItemCount = 0;
    this.currentNote = defaultNoteItem;
  }
}

const limaNotesStore = new LimaNotesStore();
export default limaNotesStore;
