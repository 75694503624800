export interface advice_judicates {
  //TODO: prepare judicates
  act: string;
  ecli: string;
}

export interface advice_textsearch {
  actID: string; //act signs
  parID: string; //act paragraph number
  keywordBase?: string; //keyword
  parAlternatives?: string[]; //actParAlternatives
  adviceTag?: string;
  searchStringRegEx?: string;
}

export const advice_textsearchDEF: advice_textsearch = {
  actID: "",
  parID: "",
};

export interface WordTextActs {
  actID: string;
  parID: string;
  actID_base: string;
  wordText: string;
}
