import { getTheme, IStackTokens, ITheme, mergeStyleSets, Shimmer, Stack } from "@fluentui/react";
import React = require("react");

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 5,
  maxWidth: "95%",
};

const theme: ITheme = getTheme();
// const { palette, semanticColors, fonts } = theme;
const { fonts } = theme;

const classNames = mergeStyleSets({
  limaLoaderWrapper_hidable: {
    borderTop: "1px solid rgb(221, 221, 221)",
    borderBottom: "1px solid rgb(221, 221, 221)",
  },
  loaderIdent: [
    fonts.medium,
    {
      position: "relative",
      top: 5,
      left: 20,
      // background: palette.themeLight,
      transformOrigin: "0 0",
      transform: "rotate(90deg)",
    },
  ],
  loaderShimmer: {
    flexGrow: 1,
  },
});

export const LimaLoadingComponnet = (count = 3, ident?: string, hidableWrapper?: boolean): JSX.Element => {
  return (
    <Stack horizontal className={hidableWrapper === true ? classNames.limaLoaderWrapper_hidable : ""}>
      {ident !== undefined && (
        <Stack.Item>
          <div className={classNames.loaderIdent}>{`${ident}`}</div>
        </Stack.Item>
      )}
      <Stack.Item className={classNames.loaderShimmer}>
        <Stack tokens={stackTokens}>
          {new Array(count).fill(0).map((_a, index: number): JSX.Element => {
            return (
              <Stack.Item key={ident ? `${ident}_i_${String(index)}` : `load_i_${String(index)}`}>
                <Shimmer width="80%" />
              </Stack.Item>
            );
          })}
        </Stack>
      </Stack.Item>
    </Stack>
  );
};
