import {
  // BaseButton,
  // getTheme,
  // Icon,
  IconButton,
  IIconProps,
  // ITheme,
  ITooltipHostStyles,
  // mergeStyleSets,
  TooltipHost,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { Stack } from "office-ui-fabric-react";
import * as React from "react";
import { useMemo, useState } from "react";

export interface ITaskListAction {
  action_icon: IIconProps;
  action_ClickClb: () => void;
  tooltip: string;
  label: string;
  disabled: boolean;
}

interface ITaskListActionsV4Props {
  detailShow?: boolean;
  onDetailDisabled?: boolean;
  onDetailClickClb?: () => void;
  removeShow?: boolean;
  onRemoveClickClb?: () => void;
  favoriteShow?: boolean;
  isFavorite?: boolean;
  onFavoriteClickClb?: () => void;
  actionsList?: ITaskListAction[];
}

//TODO: rewrite to TaskItemList[]

const calloutProps = { gapSpace: 0 };
const hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block" } };
const favoriteNotIcon: IIconProps = { iconName: "FavoriteStar" };
const favoriteAddIcon: IIconProps = { iconName: "AddFavorite" };
const favoriteYesIcon: IIconProps = { iconName: "FavoriteStarFill" };
const favoriteRemoveIcon: IIconProps = { iconName: "Unfavorite" };

const TaskListActionsV4: React.FunctionComponent<ITaskListActionsV4Props> = (props: ITaskListActionsV4Props) => {
  // const tooltipId = useId("tooltip");
  const favoriteId = useId("fav");
  // const removeId = useId("rem");
  const [favoritHover, setFavoriteHover] = useState<boolean>(false);

  //-----------------------
  // REACTIONS
  //--------------------

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const favoritFinalIcon = useMemo((): IIconProps => {
    if (props.isFavorite === undefined || props.isFavorite === false) {
      if (favoritHover === false) return favoriteNotIcon;
      else return favoriteAddIcon;
    } else {
      if (favoritHover === false) return favoriteYesIcon;
      else return favoriteRemoveIcon;
    }
  }, [props.isFavorite, favoritHover]);

  //-------------------
  // HANDLERS
  //-------------------

  const onFavoriteClick = () => {
    if (props.onFavoriteClickClb !== undefined) props.onFavoriteClickClb();
  };

  //-------------------
  // functions
  //-------------------

  return (
    <Stack horizontal>
      {props.favoriteShow === true && (
        <Stack.Item>
          <TooltipHost
            content="Set or reset Favourite"
            id={favoriteId}
            calloutProps={calloutProps}
            styles={hostStyles}
            setAriaDescribedBy={false}
          >
            <IconButton
              iconProps={favoritFinalIcon}
              aria-label="Favourite"
              onClick={onFavoriteClick}
              onMouseEnter={() => setFavoriteHover(true)}
              onMouseLeave={() => setFavoriteHover(false)}
            />
          </TooltipHost>
        </Stack.Item>
      )}

      {props.actionsList !== undefined &&
        props.actionsList.map((item: ITaskListAction) => {
          return (
            <Stack.Item>
              {/*<TooltipHost content={item.tooltip} id={tooltipId} calloutProps={calloutProps} styles={hostStyles}>*/}
              <IconButton
                iconProps={item.action_icon}
                aria-label="Detail show"
                disabled={item.disabled}
                onClick={item.action_ClickClb}
              />
              {/*</TooltipHost>*/}
            </Stack.Item>
          );
        })}
    </Stack>
  );
};

export default TaskListActionsV4;
