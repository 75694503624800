export interface LimaCompLicActive {
  companyKey: string;
  companyName: string;
  currentLicUsed: ICompanyLicenceItem[];
  compLicences: ICompanyLicenceItem[];
  youAreLogged: boolean;
  currentUsersActiveList: LimaCompLicUsers[];
  yourLicences: ILimaUserLicences[];
}

export interface LimaCompLicUsers {
  userName: string;
  userKey: string;
  userEmail: string;
  company2userKey: string;
  licenceType: LIMA_LICENCE_TYPE;
  licenceusedid: string;
  dtimestamp: string;
}

export interface ILimaUserLicences {
  companyKey: string;
  licenceid: string;
  licenceType: LimaEnum_licenceType;
}

export enum LIMA_LICENCE_TYPE {
  PER_USER = "PER_USER",
  CONCURENT = "CONCURENT",
  FREEUSER = "FREEUSER",
  USER_SUPERADMIN = "USER_SUPERADMIN",
  NONE = "NONE",
  NULL = "null",
}

export enum LimaEnum_licenceType {
  BASIC = "BASIC",
  ADVANCED = "ADVANCED",
  FREEUSER = "FREEUSER",
  NONE = "NONE",
}

export interface ICompanyLicenceItem {
  licenceType: LimaEnum_licenceType;
  concurent: boolean;
  licenceid: string;
  licencecount: number;
}
