export enum LANGUAGES {
  CZ = "cs-CZ",
  EN = "en",
}

export interface IUser {
  _id: string | null;
  id?: string | null;
  _key: string | null;
  key?: string | null;
  cissuedBy: string | null;
  ctimeStamp: string | null;
  dissuedBy: string | null;
  dtimestamp: string | null;
  userEmail: string | null;
  userName: string | null;
  lang: LANGUAGES | null;
}

export interface IUserInvite extends IUser {
  invitemessage: string | null;
}

// export type UserNew = {
//   cIssuedBy: string;
//   cTimeStamp: string;
//   dIssuedBy: string;
//   dTimestamp: string;
//   _id: string;
//   _key: string;
//   userEmail: string;
//   userName: string;
// };

export const User_getNull = (): IUser => {
  return {
    _id: null,
    _key: null,
    cissuedBy: null,
    ctimeStamp: null,
    dissuedBy: null,
    dtimestamp: null,
    userName: null,
    userEmail: null,
    lang: null,
  };
};

export const User_clone = (userToClone: IUser): IUser => {
  if (userToClone == undefined) {
    return User_getNull();
  }
  return {
    _id: userToClone._id,
    _key: userToClone._key,
    cissuedBy: userToClone.cissuedBy,
    ctimeStamp: userToClone.ctimeStamp,
    dissuedBy: userToClone.dissuedBy,
    dtimestamp: userToClone.dtimestamp,
    userName: userToClone.userName,
    userEmail: userToClone.userEmail,
    lang: userToClone.lang,
  };
};

export const User_compare = (userOrigin: IUser, userToCompare: IUser): boolean => {
  window.console.log("COMAPRE User_compare start IUser", userOrigin, userToCompare);
  // window.console.log("COMAPRE User_compare start _id", userOrigin._id, userToCompare._id);
  if (userOrigin._id !== userToCompare._id) return false;
  // window.console.log("COMAPRE User_compare start _key", userOrigin._key, userToCompare._key);
  if (userOrigin._key !== userToCompare._key) return false;
  // window.console.log("COMAPRE User_compare start cissuedBy", userOrigin.cissuedBy, userToCompare.cissuedBy);
  if (userOrigin.cissuedBy !== userToCompare.cissuedBy) return false;
  // window.console.log("COMAPRE User_compare start ctimeStamp", userOrigin.ctimeStamp, userToCompare.ctimeStamp);
  if (userOrigin.ctimeStamp !== userToCompare.ctimeStamp) return false;
  // window.console.log("COMAPRE User_compare start dissuedBy", userOrigin.dissuedBy, userToCompare.dissuedBy);
  if (userOrigin.dissuedBy !== userToCompare.dissuedBy) return false;
  // window.console.log("COMAPRE User_compare start dtimestamp", userOrigin.dtimestamp, userToCompare.dtimestamp);
  if (userOrigin.dtimestamp !== userToCompare.dtimestamp) return false;
  // window.console.log("COMAPRE User_compare start userName", userOrigin.userName, userToCompare.userName);
  if (userOrigin.userName !== userToCompare.userName) return false;
  // window.console.log("COMAPRE User_compare start userEmail", userOrigin.userEmail, userToCompare.userEmail);
  if (userOrigin.userEmail !== userToCompare.userEmail) return false;
  // window.console.log("COMAPRE User_compare start lang", userOrigin.lang, userToCompare.lang);
  if (userOrigin.lang !== userToCompare.lang) return false;
  //arrays
  // window.console.log("COMAPRE User_compare", true);
  return true;
};

export type UserForm = {
  username: string;
  useremail: string;
  invitemessage: string;
};

export function isUser(item: unknown): item is IUser {
  return (item as IUser).userName !== undefined;
}

export interface ICallUser {
  userEmail: string;
  userName: string;
  userId: string;
  lang: LANGUAGES;
}

export interface ICallUser_withInvite extends ICallUser {
  invitemessage: string;
  companyKey: string;
}

export interface ICallUserInvite {
  otherUserId: string;
  invitemessage: string;
}
