import { LimSelTemp } from "../api/schema/tags/colors";

export interface highLightedTags {
  regex: string;
  highlightedStyle: LimSelTemp;
  styleOrigins: string[];
  returnOrigStyles: string[];
  regexAlternatives?: string[];
  textContextValues?: string[];
  contextTags?: highLightedTags[];
}

export enum HLSTATE {
  HIGHLIGHTED = "highlighted",
  UNHIGHLIGHTED = "unhighlighted",
  TOREMOVEHL = "2removehighlight",
  TOADDHL = "2addhighlight",
}
export interface HighLightedTagsNew {
  tag: string;
  highlightedStyle: LimSelTemp;
  isHighlighted: boolean;
  state: HLSTATE;
  textContextValues?: string[];
  contextTags?: HighLightedTagsSingle[];
}

export interface HighLightedTagsSingle {
  contextTag: string;

  highlightedStyle: LimSelTemp;
  styleOrigins: string[];
  returnOrigStyles: string[];
}

export interface signsAlternatives {
  mainSign: string;
  altSigns: string[];
}
