import keycloak from "../taskpane/keycloak";
import limaStore from "../store/limaStore";
import { log } from "missionlog";
import { limaLogTag } from "./limaLog";
import limaTimerStore from "../store/limaTimerStore";
import limaLoadeStore, { LoaderItemEnd } from "../store/limaLoaderStore";
import limaTasksStore from "../store/limaTasksStore";
import limaTasksStoreV4 from "../store/limaTasksStoreV4";
import { HttpResponse, httpPostAuth, httpPutAuth } from "../api/httpAPI";
import { urlPostLoginUser, urlPutLoginUser, urlPutLogoutUser } from "../api/apilinks";
import { restStateInfoOut } from "../api/schema/user/restoutUserInfo";

/**
 * Procesing logon
 */
export const onLogged = async (): Promise<void> => {
  log.info(limaLogTag.LimaUser, `processLoginData - user authenticated:  ${String(keycloak.authenticated)}`);
  limaStore.setLogged(keycloak.authenticated);
  try {
    log.info(limaLogTag.LimaUser, `processLoginData - open user profile`);
    const profile = await keycloak.loadUserProfile();
    log.info(limaLogTag.LimaUser, `processLoginData - user profile:  ${JSON.stringify(profile)}`);
    limaStore.setLoginUser(
      //messageFromDialogData.userID,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      profile["attributes"]["limactauserid"][0],
      // profile.username ? profile.username : "",
      // profile.email ? profile.email : "",
      "",
      "",
      profile.firstName,
      profile.lastName
    );
    limaStore.setUserEmail(profile.email ? profile.email : "");
  } catch {
    log.error(limaLogTag.LimaUser, `processLoginData - user profile: expected login data but dont get`);
  }
  //get Timers from DB
  limaTimerStore.getTodayTimers();
};

export const onLogged_withCompany = async (): Promise<void> => {
  //Store login info into server sessions
  await limactaCallLoginLogout(CALLLOGINTYPE.LOGIN, limaStore.userCompanyKey, limaStore.userLicenceUsedId);
  limaStore.setUserGetPerm(limaStore.userId);
};

/**
 * Procesing logout
 */
export const onLoggedOut = async (): Promise<void> => {
  // limaStore.controller.abort();
  await limactaCallLoginLogout(CALLLOGINTYPE.LOGOUT, limaStore.userCompanyKey, limaStore.userLicenceUsedId);
  limaLoadeStore.abortAllAC();
  //STORE CLEANUP
  limaLoadeStore.cleanup();
  limaStore.cleanUp();
  limaTasksStore.onClear();
  limaTasksStoreV4.onClear();
  limaStore.selectionUnSubscribe();
  limaStore.wordDocIdentReplaced = false;
  limaStore.setUserLicenced(false);
  limaStore.setUserCompanyKey(null);
  //TODO: acta cleanup

  limaStore.setLogged(false);

  return;
};

/**
 * Processing Sessin update
 */
export const onLoggedSessionUpdate = async (): Promise<void> => {
  limaStore.setAccessToken(keycloak.token);
  log.info(limaLogTag.LimaUser, `onLoggedSessionUpdate: update`);
  //limactaCallLoginLogout(CALLLOGINTYPE.LOGIN, limaStore.userCompanyId);
  if (limaStore.logged === true) {
    await limactaCallLoginLogout(CALLLOGINTYPE.LOGINUPDATE, limaStore.userCompanyKey, limaStore.userLicenceUsedId);
  } else {
    await limactaCallLoginLogout(CALLLOGINTYPE.LOGIN, limaStore.userCompanyKey, limaStore.userLicenceUsedId);
    limaStore.setLogged(true);
  }
};

export enum CALLLOGINTYPE {
  LOGIN = "Login",
  LOGINUPDATE = "LoginUpdate",
  LOGOUT = "Logout",
}

/**
 * merged methid used to call session info for user Login/Logout/LoginUpdateSession
 * @param type
 * @param companyKey
 * @returns
 */
export const limactaCallLoginLogout = (
  type: CALLLOGINTYPE,
  companyKey: string,
  licenceusedid: string,
  userKey?: string
): Promise<string> => {
  return new Promise(async (resolve, reject): Promise<void> => {
    let response: HttpResponse<restStateInfoOut>;
    log.info(
      limaLogTag.LimaUser,
      `limactaCallLogin: starts [${String(type)}] company id '${companyKey}'`,
      companyKey,
      licenceusedid,
      userKey
    );

    if (companyKey === undefined || companyKey === null || companyKey === "null") {
      log.warn(
        limaLogTag.LimaUser,
        `limactaCallLogin: unable to start [${String(type)}] company is missing`,
        companyKey
      );
      if (type === CALLLOGINTYPE.LOGOUT) {
        resolve("notLogoutOnServer");
      }
      reject;
    }
    const loaderID = limaLoadeStore.add("lima login session processing");
    try {
      if (type === CALLLOGINTYPE.LOGIN) {
        response = await httpPostAuth(urlPostLoginUser(companyKey), { licenceusedid: licenceusedid }, loaderID.ac);
      } else if (type === CALLLOGINTYPE.LOGINUPDATE) {
        response = await httpPutAuth(urlPutLoginUser(companyKey), { licenceusedid: licenceusedid }, loaderID.ac);
      } else if (type === CALLLOGINTYPE.LOGOUT) {
        response = await httpPutAuth(
          urlPutLogoutUser(companyKey),
          { userId: userKey === undefined ? limaStore.userId : userKey, licenceusedid: licenceusedid },
          loaderID.ac
        );
      } else {
        log.error(limaLogTag.LimaUser, `limactaCallLogin:[${String(type)}] not defined call type`);
        limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
        reject;
      }
      if (response && "parsedBody" in response && "state" in response.parsedBody) {
        log.info(
          limaLogTag.LimaUser,
          `limactaCallLogin:[${String(type)}]  looks like get some data `,
          response.parsedBody
        );
        limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
        resolve(response.parsedBody.state);
      } else {
        log.error(limaLogTag.LimaUser, `limactaCallLogin:[${String(type)}] no data `, response);
        limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
        resolve(response.parsedBody.state);
      }
    } catch (error) {
      log.error(limaLogTag.LimaUser, `limactaCallLogin:[${String(type)}] some error `, error);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      reject;
    } finally {
      // limaLoadeStore.remove(loaderID.k);
    }
  });
};
