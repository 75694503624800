import FileSaver = require("file-saver");
import { log } from "missionlog";
import { limaLogTag } from "../../limaCommon/limaLog";
import limaLoadeStore, { LoaderItemEnd } from "../../store/limaLoaderStore";
import { XcopyHistory } from "../../types/XcopyHistory";
import { urlGetAttachement, urlGetXcopyHistoryListForActa } from "../apilinks";
import { httpGetAuth, httpGetAuth_noParse, HttpResponse } from "../httpAPI";

/**
 * Call to get history versions of XCOPY
 * @param xcopyId
 * @param isDescOrderHistory
 * @returns
 */
export const limaQuery_fetchFileVersions = async (
  xcopyId: string,
  isDescOrderHistory: boolean
): Promise<XcopyHistory[]> => {
  log.info(limaLogTag.LimaFileCalls, "fetchFileVersion data");
  const loaderID = limaLoadeStore.add("Getting fetchFileVersion from DB");
  try {
    // const response: HttpResponse<ActaHistory[]> = await httpGet(
    //   urlGetActaHistList(xcopyId, isDescOrderHistory ? "desc" : "asc", limaStore.userId, "", "")
    // );
    const response: HttpResponse<XcopyHistory[]> = await httpGetAuth(
      // urlGetXcopyHistoryListForActa(xcopyId, isDescOrderHistory ? "desc" : "asc", limaStore.userId, "", ""),
      urlGetXcopyHistoryListForActa(xcopyId, isDescOrderHistory ? "desc" : "asc", "", ""),
      loaderID.ac
    );

    if (!response.ok) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw response;
    }
    log.info(limaLogTag.FileListVersions, "getHistoryLinks: asketo to get something", response);
    if (response.parsedBody !== undefined) {
      log.info(limaLogTag.FileListVersions, "getHistoryLinks: parsedBody is not null", response.parsedBody);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return response.parsedBody;
    }
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error("no data");
  } catch (error) {
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error(error);
  } finally {
  }
};

export const getAtachement = async (itemKey: string, name: string): Promise<Blob> => {
  log.info(limaLogTag.AttachementComponentV4, "fetching data getAtachement");
  // const [, { patientID }] = params.queryKey;
  const loaderID = limaLoadeStore.add("Getting attachement from server");
  try {
    // const response:HttpResponse<any> =  await httpGetExtToken(urlGetSurveyAnamnesis(patientID,2))
    const response: Response = await httpGetAuth_noParse(urlGetAttachement(itemKey), loaderID.ac);
    if (!response.ok) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw response;
    }
    log.info(limaLogTag.AttachementComponentV4, "fetching data fetchAtachements response respo", response);
    const respBlob = await response.blob();
    log.info(limaLogTag.AttachementComponentV4, "fetching data fetchAtachements response blob", respBlob);
    //const response = httpGetAuth().then
    FileSaver.saveAs(respBlob, name);
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
    return respBlob;
  } catch (error) {
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error(error);
  } finally {
    // limaLoadeStore.remove(loaderID.k);
  }
};
