import { log } from "missionlog";
import { limaLogTag } from "../../limaCommon/limaLog";
import limaLoadeStore, { LoaderItemEnd } from "../../store/limaLoaderStore";
import { ITimerPartion } from "../../types/Timer";
import { urlGetAllTimers, urlGetAllTimersBetween, urlPostTimers } from "../apilinks";
import { httpGetAuth, httpPostAuth, HttpResponse } from "../httpAPI";

/**
 * Call to store timers
 * @param timers2store timers to store to db
 * @returns
 */
export const limaQuery_storeTimers = async (timers2store: ITimerPartion[]): Promise<ITimerPartion[]> => {
  log.info(limaLogTag.limaTimersCalls, "limaQuery_storeTimers: store timers to DB");
  const loaderID = limaLoadeStore.add("Storing timers to DB");
  try {
    const response: HttpResponse<ITimerPartion[]> = await httpPostAuth(urlPostTimers(), timers2store, loaderID.ac);

    if (!response.ok) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw response;
    }
    log.info(limaLogTag.limaTimersCalls, "limaQuery_storeTimers: asketo to get something", response);
    if (response.parsedBody !== undefined) {
      log.info(limaLogTag.limaTimersCalls, "limaQuery_storeTimers: parsedBody is not null", response.parsedBody);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return response.parsedBody;
    }
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error("no data");
  } catch (error) {
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error(error);
  } finally {
  }
};

/**
 * Call to get timers
 * @param timers2store timers to get from db
 * @returns
 */
export const limaQuery_getTimers = async (from?: string, to?: string): Promise<ITimerPartion[]> => {
  log.info(limaLogTag.limaTimersCalls, "limaQuery_getTimers: get timers from DB");
  const loaderID = limaLoadeStore.add("Getting timers from DB");
  let response: HttpResponse<ITimerPartion[]> = null;
  try {
    if (from === undefined) {
      response = await httpGetAuth(urlGetAllTimers(), loaderID.ac);
    } else {
      response = await httpGetAuth(urlGetAllTimersBetween(from, to), loaderID.ac);
    }

    if (!response.ok) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw response;
    }
    log.info(limaLogTag.limaTimersCalls, "limaQuery_getTimers: asketo to get something", response);
    if (response.parsedBody !== undefined) {
      log.info(limaLogTag.limaTimersCalls, "limaQuery_getTimers: parsedBody is not null", response.parsedBody);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return response.parsedBody;
    }
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error("no data");
  } catch (error) {
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error(error);
  }
};
