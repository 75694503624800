import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { XCOPY_TYPE } from "../../../../types/Task";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IChooseXcopyTypV4 {
  defaultSelected: XCOPY_TYPE;
  chooseXcopyLabel?: string;
  chooseXcopyOnChange: (type: XCOPY_TYPE) => void;
}

export const ChooseXcopyTypV4: React.FunctionComponent<IChooseXcopyTypV4> = (props: IChooseXcopyTypV4) => {
  const { t } = useTranslation();

  return (
    <ChoiceGroup
      key="genXcopy"
      defaultSelectedKey={props.defaultSelected}
      // disabled={linkedTypeFixed}
      // value={itemData.xcopytype}
      options={[
        {
          key: XCOPY_TYPE.FULLXCOPY,
          text: t("tpmain:component.linking.createcopytypeFullLbl"),
          defaultChecked: props.defaultSelected === XCOPY_TYPE.FULLXCOPY ? true : false,
        },
        {
          key: XCOPY_TYPE.TRIMXCOPY,
          text: t("tpmain:component.linking.createcopytypeTrimLbl"),
          defaultChecked: props.defaultSelected === XCOPY_TYPE.TRIMXCOPY ? true : false,
        },
      ]}
      onChange={(_ev, option: IChoiceGroupOption) => props.chooseXcopyOnChange(XCOPY_TYPE[option.key])}
      label={props.chooseXcopyLabel}
      required={true}
    />
  );
};
