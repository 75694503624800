import { mergeStyleSets, Text } from "@fluentui/react";
import { ReactElement, ReactNode } from "react";
import React = require("react");
// const { palette, semanticColors, fonts } = theme;
// const font = fonts.large;

type LimeBreadCrumbProps = {
  children?: ReactNode;
  bradCrumbsStr?: string[];
  // bradCrumbs?: IBreadcrumbItem[];
};

// const theme: ITheme = getTheme();

const classNames = mergeStyleSets({
  breadcrumbWrapper: {
    marginLeft: 10,
    marginBottom: 5,
    cursor: "default",
  },
  breadcrumbLast: {
    weight: "bold",
    color: "black",
    paddingLeft: 10,
  },
  breadcrumb: {
    color: "darkgray",
    paddingLeft: 10,
  },
});

export const LimeBreadCrumb = ({ children, bradCrumbsStr }: LimeBreadCrumbProps): ReactElement => {
  // let items: IBreadcrumbItem[] = [];

  // if (bradCrumbs != undefined) {
  //   items = bradCrumbs;
  // } else if (bradCrumbs == undefined && bradCrumbsStr != undefined) {
  //   let index = 0;
  //   for (const item of bradCrumbsStr) {
  //     items.push({ text: item, key: `bc_${index}` });
  //     index++;
  //   }
  // }

  return (
    <div className={classNames.breadcrumbWrapper}>
      {bradCrumbsStr.map((itemName: string, index: number, array: string[]) => {
        if (index == array.length - 1) {
          return (
            <Text variant="medium" key={`bc_${index}`} className={classNames.breadcrumbLast}>{`${itemName}`}</Text>
          );
        }
        return <Text variant="medium" key={`bc_${index}`} className={classNames.breadcrumb}>{`${itemName} >`}</Text>;
      })}
      {children}
      {/* <Breadcrumb
        items={items}
        maxDisplayedItems={10}
        className={classNames.breadcrumb}
        // ariaLabel="Breadcrumb with items rendered as buttons"
        // overflowAriaLabel="More links"
      /> */}
    </div>
  );
};
