import React = require("react");
import { LimaIconProps } from "./DefLimaIconType";

export const LimaIconOpenCopy = ({
  size = 32, // or any default size of your choice
  color = "black", // or any color of your choice
  fillColor = "black", // or any color of your choice
  title = "", //title
}: LimaIconProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 210 297"
      xmlns="http://www.w3.org/2000/svg"
      fill={fillColor} // added color here
      stroke={color} // added color here
      aria-labelledby="title"
    >
      <title id="title">{title}</title>
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            // fill: "#212121",
            fill: color,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 33.3333,
          }}
          d="m376.441 24.057-3.39.23c-12.203 1.656-21.608 12.115-21.608 24.772l.227 3.392c1.655 12.203 12.115 21.608 24.771 21.608h156.293L292.13 314.819l-2.42 2.804c-7.26 9.789-6.448 23.677 2.43 32.55l2.802 2.42c9.79 7.26 23.678 6.45 32.551-2.427l240.643-240.773.025 156.462.227 3.393c1.655 12.203 12.115 21.607 24.771 21.607 13.807 0 25.002-11.192 25.002-25l-.015-217.751-.315-3.14-.451-2.128-.64-2.14-1.29-3.01-1.806-3.004-2.791-3.287-2.313-2.09a23.492 23.492 0 0 0-5.068-3.028l-1.895-.802-3.36-.94-3.318-.459zm-216.6 49.957-6.638.17C89.46 77.49 38.35 128.59 35.013 192.348l-.146 6.75.074 283.279.176 6.639c3.353 63.75 54.468 114.838 118.219 118.162l6.777.17 162.748-.225v-50l-162.9.223-5.135-.174c-39.027-2.629-69.874-35.113-69.885-74.809l-.093-284.353.252-5.297c3.187-38.485 35.43-68.718 74.728-68.7h116.658l3.393-.228c12.202-1.655 21.607-12.115 21.607-24.771 0-13.807-11.193-25-25-25zM543.13 340.08c-9.899 0-18.428 5.768-22.479 14.113h44.963c-4.05-8.346-12.584-14.113-22.484-14.113z"
          transform="scale(.26458)"
        />
      </g>
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          d="m109.065 116.324-.001 53.36c0 8.51 6.898 15.408 15.409 15.408l35.096.001a9.704 9.704 0 0 1-8.844 5.706h-26.252c-11.662 0-21.116-9.454-21.116-21.116v-44.515a9.704 9.704 0 0 1 5.708-8.845zm32.546-12.272a9.702 9.702 0 0 1 6.86 2.842L169 127.422a9.702 9.702 0 0 1 2.841 6.86v35.401a9.701 9.701 0 0 1-9.702 9.702h-37.666a9.701 9.701 0 0 1-9.702-9.702v-55.929c0-5.358 4.343-9.702 9.702-9.702zm-1.73 5.707h-15.408a3.995 3.995 0 0 0-3.995 3.995v55.93a3.995 3.995 0 0 0 3.995 3.994h37.666a3.995 3.995 0 0 0 3.995-3.995v-33.671h-16.55c-5.2 0-9.446-4.092-9.691-9.232l-.011-.47zm23.93 20.546-18.222-18.222v14.227a3.995 3.995 0 0 0 3.667 3.981l.328.014z"
          style={{
            // fill: "#212121",
            fill: color,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 2.28282,
          }}
        />
      </g>
    </svg>
  );
};
