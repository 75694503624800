import { IBreadcrumbItem } from "@fluentui/react";
import { makeObservable, observable, action, computed } from "mobx";
import limaStore, { OPENEDFILETYPE_ENUM } from "./limaStore";

export enum LimaBreadCrumbACTIONTYPE {
  MAIN_COPY_SAVE = "MAIN_COPY_SAVE",
  COPY_SAVE = "COPY_SAVE",
  FILE_OPEN = "FILE_OPEN",
  NONE = "NONE",
}

export interface LimaIBreadcrumbItemAction extends LimaIBreadcrumbItemActionBase {
  onid: string;
  isVisible: (breadCrumbCurrentPage_xcopyId: string) => boolean;
}

/**
 * interface
 */
export interface LimaIBreadcrumbItemActionBase {
  actionType: LimaBreadCrumbACTIONTYPE;
  actionAction: () => void | null;
  /**
   * used by Breadcrub to idenfiry if ActionItem si enables
   * @param {string} currentOpenFileId sending current xcopyId opended in wod
   * @param {OPENEDFILETYPE_ENUM} currentOpenDoctType sending curent xcopy docuemnt type
   * @param {string} currentPath current path in react router
   * @returns {boolean} if show or not show specific action item
   */
  isActionEnabled: (
    currentOpenFileId: string,
    currentOpenDoctType: OPENEDFILETYPE_ENUM,
    currentPath: string
  ) => boolean;
}

export interface LimaIBreadcrumbItem extends IBreadcrumbItem {
  lima_itemid: string;
  lima_Actions: LimaIBreadcrumbItemAction[];
}

export enum LimaBreadCrumbPageENUM {
  FILE_MAINCOPY = "FILE_MAINCOPY",
  FILE_COPY = "FILE_COPY",
  OTHER = "OTHER",
}
export interface ILimaBreadcrumPageIdent {
  pageType: LimaBreadCrumbPageENUM;
  pageId: string;
}

class LimaBreadCrumbStore {
  //breadCrumb: actaListOK[] = [];
  breadCrumbExt: LimaIBreadcrumbItem[] = [];
  breadCrumb: IBreadcrumbItem[] = [];
  actionIconType: LimaBreadCrumbACTIONTYPE = LimaBreadCrumbACTIONTYPE.NONE;
  actionAction: () => void | null = null;
  actionIsVisible = false;
  currentPage: ILimaBreadcrumPageIdent = { pageType: LimaBreadCrumbPageENUM.OTHER, pageId: "" };
  currentActions: LimaIBreadcrumbItemActionBase[] = [];

  constructor() {
    makeObservable(this, {
      //v2
      breadCrumbExt: observable,
      currentPage: observable,
      currentActions: observable,

      getBreadCrumbExt: computed,
      getActionsExt: computed,
      getActionsExt_visible: computed,

      setBreadCrumbListExt: action,
      addNewBreadCrumbExt: action,
      setCurrentPage: action,
      //v1
      breadCrumb: observable,
      actionIconType: observable,
      actionIsVisible: observable,

      getBreadCrumb: computed,
      getAction: computed,

      // getActionIsVisible: computed,

      setAction: action,
      setActionIsVisible: action,
      setBreadCrumbList: action,
      // addNewBreadCrumb: action,
      setBreadCumberCount: action,
      setCurrentActions: action,
      addToCurrentActions: action,
    });
  }

  // addNewBreadCrumb(item: IBreadcrumbItem) {
  //   //dont add to null -> use set
  //   if (this.breadCrumb.length == 0) return;
  //   //add only different
  //   window.console.log(">>>> breadcrumb add", item, this.breadCrumb);

  //   if (this.breadCrumb[this.breadCrumb.length - 1].key !== item.key) this.breadCrumb.push(item);
  //   else {
  //     this.breadCrumb.splice(this.breadCrumb.length - 1, 1, item);
  //   }
  // }

  addNewBreadCrumbExt(item: LimaIBreadcrumbItem) {
    //dont add to null -> use set
    if (this.breadCrumbExt.length == 0) return;
    //add only different
    window.console.log(">>>> breadcrumbExt add", item, this.breadCrumbExt);

    if (this.breadCrumbExt[this.breadCrumbExt.length - 1].key !== item.key) this.breadCrumbExt.push(item);
    else {
      this.breadCrumbExt.splice(this.breadCrumbExt.length - 1, 1, item);
    }
  }

  setBreadCrumbList(items: IBreadcrumbItem[]) {
    this.breadCrumb = items;
  }

  setBreadCrumbListExt(items: LimaIBreadcrumbItem[]) {
    this.breadCrumbExt = items;
  }

  setCurrentPage(currentPageNew: ILimaBreadcrumPageIdent) {
    this.currentPage = currentPageNew;
  }
  setBreadCumberCount(count: number) {
    this.breadCrumb = this.breadCrumb.filter((_item, index) => index <= count);
    this.breadCrumbExt = this.breadCrumbExt.filter((_item, index) => index <= count);
  }

  setAction(type: LimaBreadCrumbACTIONTYPE, action: () => void) {
    this.actionIconType = type;
    this.actionAction = action;
  }

  setActionIsVisible(visibility: boolean) {
    window.console.log("SET ACTION VISIBLE", visibility);
    this.actionIsVisible = visibility;
  }

  setCurrentActions(newActions: LimaIBreadcrumbItemActionBase[]) {
    this.currentActions = newActions;
  }

  addToCurrentActions(newAction: LimaIBreadcrumbItemActionBase) {
    this.currentActions = [...this.currentActions, newAction];
  }

  get getBreadCrumb() {
    return this.breadCrumb;
  }

  get getBreadCrumbExt() {
    return this.breadCrumbExt;
  }

  get getAction() {
    return this.actionAction;
  }

  get getActionsExt() {
    const currentItem = this.breadCrumbExt.filter((item: LimaIBreadcrumbItem) => item.isCurrentItem === true);
    if (currentItem.length >= 1) return currentItem[0].lima_Actions;
    return null;
    //return this.actionAction;
  }

  get getActionsExt_visible(): LimaIBreadcrumbItemAction[] | null {
    window.console.log("CRUMB >>> getActionsExt_visible", this.currentPage);

    if (this.currentPage.pageType === LimaBreadCrumbPageENUM.OTHER) return null;
    const currentItem = this.breadCrumbExt.filter(
      (item: LimaIBreadcrumbItem) => item.lima_itemid === this.currentPage.pageId
    );

    window.console.log("CRUMB >>> getActionsExt_visible", currentItem);
    if (currentItem.length >= 1) {
      const out = currentItem[0].lima_Actions.filter((item: LimaIBreadcrumbItemAction) => {
        window.console.log("CRUMB >>> testing visibility", currentItem, limaStore.xcopyId, currentItem.toString());
        return item.isVisible(limaStore.menu_fileId) === true;
      });
      window.console.log("CRUMB >>> getActionsExt_visible call", out);
      return out;
    }
    return null;
    //return this.actionAction;
  }

  setActionCrumb(crumbid: string) {
    // window.console.log("CRUMB >>> setActionCrumb", crumbid);
    const breadCrumbExt_temp = this.breadCrumbExt.map((item: LimaIBreadcrumbItem) => {
      if (item.lima_itemid === crumbid) return { ...item, isCurrentItem: true };
      else if (item.isCurrentItem === true) return { ...item, isCurrentItem: false };
      else return item;
    });

    // window.console.log("CRUMB >>> setActionCrumb", breadCrumbExt_temp);

    this.setBreadCrumbListExt(breadCrumbExt_temp);
  }

  onLogoutClear() {
    this.breadCrumbExt = [];
    this.breadCrumb = [];
    this.actionIconType = LimaBreadCrumbACTIONTYPE.NONE;
    this.actionAction = null;
    this.actionIsVisible = false;
    this.currentPage = { pageType: LimaBreadCrumbPageENUM.OTHER, pageId: "" };
    this.currentActions = [];
  }
}

const limaBreadCrumbStore = new LimaBreadCrumbStore();
export default limaBreadCrumbStore;
