import { log } from "missionlog";
import { limaLogTag } from "../../limaCommon/limaLog";
import limaLoadeStore, { LoaderItemEnd } from "../../store/limaLoaderStore";
import limaStore from "../../store/limaStore";
import limaUsersStore from "../../store/limaUsersStore";
import {
  AcceptLinkingObject,
  CALLUSERLINK,
  TOorFROM,
} from "../../taskpane/Menu/Users/UserLinking/User2UserLinkComponent";
import { LIMA_ITEM_TYPE } from "../../types/BASE_Enums";
import { IUser } from "../../types/User";
import { User2User } from "../../types/User2User";
import { Visibilitor } from "../../types/Visibilitor";
import { urlGetUser2UserJoinList, urlPATCHorDELETEUser2UserJoin } from "../apilinks";
import { httpDeleteByPUTAuth, httpGetAuth, httpPutAuth, HttpResponse } from "../httpAPI";
import { getUser2UserInvitationsOK } from "../schema/user/getUser2UserCall";

/**
 * Update or delete user 2 user linking
 * @param param0
 * @returns
 */
export const limaQuery_putUser2UserLinkinQuery = async ({
  user2user,
  type,
}: AcceptLinkingObject): Promise<User2User[]> => {
  const visibilitor: Visibilitor = {
    create: null,
    userid: limaStore.userId, //not used by authentication
    foruser: null,
    itemid: null,
    itemtype: LIMA_ITEM_TYPE.USER,
    state: type,
  };
  if (type === CALLUSERLINK.ACCEPT || type == CALLUSERLINK.REJECT) {
    visibilitor.itemid = user2user.user.key ? user2user.user.key : user2user.user._key;
    visibilitor.foruser = user2user.user.key ? user2user.user.key : user2user.user._key;
  } else {
    visibilitor.itemid = user2user.userto.key ? user2user.userto.key : user2user.userto._key;
    visibilitor.foruser = user2user.userto.key ? user2user.userto.key : user2user.userto._key;
  }

  log.info(limaLogTag.LimaUse2UserCalls, "limaQuery_putUser2UserLinkinQuery: ", user2user, type, visibilitor);
  const loaderID = limaLoadeStore.add(`Updating user2user DB ${type}`);
  let response: HttpResponse<User2User[]>;
  try {
    if (type === CALLUSERLINK.ACCEPT) {
      // response = await httpPut<User2User[]>(urlPATCHorDELETEUser2UserJoin(), visibilitor, loaderID.ac);
      response = await httpPutAuth<User2User[]>(urlPATCHorDELETEUser2UserJoin(), visibilitor, loaderID.ac);
    } else if (type === CALLUSERLINK.REJECT) {
      // response = await httpDelete<User2User[]>(urlPATCHorDELETEUser2UserJoin(), visibilitor, loaderID.ac);
      response = await httpDeleteByPUTAuth<User2User[]>(urlPATCHorDELETEUser2UserJoin(), visibilitor, loaderID.ac);
    } else if (type === CALLUSERLINK.CANCEL) {
      // response = await httpDelete<User2User[]>(urlPATCHorDELETEUser2UserJoin(), visibilitor, loaderID.ac);
      response = await httpDeleteByPUTAuth<User2User[]>(urlPATCHorDELETEUser2UserJoin(), visibilitor, loaderID.ac);
    }

    log.info(limaLogTag.LimaUse2UserCalls, "fetching data limaQuery_putUser2UserLinkinQuery response", response);
    if (!response.ok) {
      throw response;
    }
    if (response.parsedBody !== undefined) {
      if (Array.isArray(response.parsedBody))
        log.info(
          limaLogTag.User2UserLinkComponent,
          "fetching data limaQuery_putUser2UserLinkinQuery response.parsedBody",
          response.parsedBody
        );
      //response.parsedBody.forEach((item:any)=>assertIsDB_Survey(item))
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return response.parsedBody;
    }
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error("no data");
  } catch (error) {
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error(error);
  }
};

/**
 * Get list of linking requests
 * @param userId
 * @param type
 * @returns
 */
export const limaQuery_getUser2UserList = async (
  userId: string,
  type: TOorFROM
): Promise<getUser2UserInvitationsOK> => {
  log.info(limaLogTag.LimaUse2UserCalls, "fetching data fetchAccessList", userId, type);
  // const [, { patientID }] = params.queryKey;
  const loaderID = limaLoadeStore.add(`Getting acceslist from DB ${type}`);

  try {
    // const response = await httpGet<User2User[]>(urlGetUser2UserJoinList(type), loaderID.ac);
    const response = await httpGetAuth<getUser2UserInvitationsOK>(urlGetUser2UserJoinList(type), loaderID.ac);
    log.info(limaLogTag.LimaUse2UserCalls, "fetching data fetchAccessList response", response);
    if (!response.ok) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw response;
    }
    if (response.parsedBody !== undefined) {
      if (Array.isArray(response.parsedBody))
        log.info(
          limaLogTag.LimaUse2UserCalls,
          "fetching data fetchAccessList response.parsedBody",
          response.parsedBody
        );
      //response.parsedBody.forEach((item:any)=>assertIsDB_Survey(item))
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return response.parsedBody;
    }
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error("no data");
  } catch (error) {
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error(error);
  }
};

/**
 * Remove user linking
 * @param userId
 * @param type
 * @returns
 */
export const limaQuery_removeUserLinking = async (user: IUser): Promise<User2User[]> => {
  log.info(limaLogTag.UsersList, "fetching data fetchAccessList", user);

  const loaderID = limaLoadeStore.add(`Remove user linking`);

  const visibilitor: Visibilitor = {
    create: null,
    userid: limaStore.userId, //not used by authentication
    foruser: limaStore.userId,
    itemid: user._key,
    itemtype: LIMA_ITEM_TYPE.USER,
    state: CALLUSERLINK.DELETE,
  };

  try {
    const response = await httpDeleteByPUTAuth<User2User[]>(urlPATCHorDELETEUser2UserJoin(), visibilitor, loaderID.ac);
    log.info(limaLogTag.UsersList, "fetching data fetchAccessList response", response);
    if (!response.ok) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw response;
    }
    // return response.parsedBody;
    if (response.parsedBody !== undefined) {
      limaUsersStore.removeListSingleItem(user._key);
      // //response.parsedBody.forEach((item:any)=>assertIsDB_Survey(item))
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return response.parsedBody;
    }
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error("no data");
  } catch (error) {
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error(error);
  }
};
