import * as React from "react";
import { FunctionComponent } from "react";
import { Separator } from "office-ui-fabric-react";

import { log, tag } from "missionlog";
//import { ActParaItemOK } from "../../../api/schema/getActsParaResp";
import ActParItem from "./ActParItem";
// import { ActParaItemOK } from "../../../api/schema/getActsParaResp";

log.init({ ActParHolder: "ActParHolder" });

export interface ActParHolderProps {}
//({ logo, message, title }: HistItemProps)

const ActParHolder: FunctionComponent<ActParHolderProps> = ({}: ActParHolderProps) => {
  log.info(tag.ActParHolder, "ActParHolder: Mounted");
  //const ActParDetail: ActParaItemOK = JSON.parse(localStorage.getItem("actPar"));

  return (
    <>
      <Separator>Search Act par text Detail</Separator>
      {/* {limaDialogStore.isLoading == true ? "LOADING" : <ActParItem />} */}
      <ActParItem />
    </>
  );
};

export default ActParHolder;
