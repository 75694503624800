const Routes = {
    FileMenuSub: 'fileMenuRt',
    FileMenuOpenRoute: 'fileMenuOpenRt',
    FileMenuImportRoute: 'fileMenuImportRt',
    FileMenuSaveRoute: 'fileMenuSaveRt',
    UserMenuSub: 'userMenuRt',
    userMenuListRoute: 'userMenuListRt',
    userMenuAddRoute: 'userMenuAddRt'
 }

 export default Routes;