/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import * as React from "react";
import { FunctionComponent } from "react";

import { log, tag } from "missionlog";

import { IStackTokens, Stack, StackItem, Separator } from "office-ui-fabric-react";
//import { judgeActParaItemOK } from "../../../api/schema/getJudgeActParaResp";
import { observer } from "mobx-react-lite";
import limaDialogStore from "../../../store/limaDialogStore";

log.init({ JudgeItem: "JudgeItem" });

//export interface JudgeItemProps {}
//({ logo, message, title }: HistItemProps)

const numericalSpacingStackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 0,
  maxWidth: "95%",
};

//const JudgeItem: FunctionComponent<JudgeItemProps> = () => {
const JudgeItem: FunctionComponent = () => {
  log.info(tag.JudgeItem, "ActParItem: Mounted");

  function renderString(str) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    str = str.replace(/(\r\n|\n|\r)/gm, "<br>");
    //str.replace(/(§.{0,1}actParItem.parid.{0,30}?o. s. ř.)/gm, "<b>$1</b>")

    //string = string.replace(actParItem.parid, "<b>" + actParItem.parid + "</b>");
    return { __html: str };
  }

  const RenderSentances = observer((): JSX.Element => {
    if (
      limaDialogStore.judge === null ||
      limaDialogStore.judge.presentText === undefined ||
      limaDialogStore.judge.presentText === null
    ) {
      return <></>;
    } else {
      console.log(limaDialogStore.judge.presentText);
      return (
        <>
          {/* TODO: IN future whe we have sentaces
          {actParItem.sentences.map((singleSentence, i) => (
            <StackItem key={"sent_" + i}>
              <div dangerouslySetInnerHTML={renderString(singleSentence)} />
            </StackItem>
          ))} */}
          <StackItem key={"sent_pres"}>
            <div dangerouslySetInnerHTML={renderString(limaDialogStore.judge.presentText)} />
          </StackItem>
        </>
      );
    }
  });

  return (
    <>
      <Stack tokens={numericalSpacingStackTokens}></Stack>

      <Separator></Separator>

      <Stack tokens={numericalSpacingStackTokens}>
        <RenderSentances />
      </Stack>
    </>
  );
};

export default JudgeItem;
