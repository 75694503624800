/**
 *
 * @param {string} name String text
 * @param {?boolean} glue if true it will be returned as one string
 * @returns {string|string[]} glued or not initials
 */
export const getInitials = (name: string, glue?: boolean): string | string[] => {
  if (glue === undefined) {
    glue = true;
  }

  const initials = name.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g);

  if (glue) {
    return initials.join("");
  }

  return initials;
};
export const getInitialsGlued = (name: string): string => {
  const initials = getInitials(name);

  if (typeof initials !== "string") {
    return initials.join("");
  }

  return initials;
};

/**
 * this utility return capitalized string
 * @param text any string text
 * @returns CAPITALIZED TEXT
 */
export const capitalize = (text: string): string => {
  return text.toLowerCase().replace(/\b\w/g, function (m) {
    return m.toUpperCase();
  });
};
