import * as React from "react";
import { FunctionComponent } from "react";
import { Separator } from "office-ui-fabric-react";

import { log } from "missionlog";
import JudgeItem from "./JudgeItem";

// import { judgeActParaItemOK } from "../../../api/schema/getJudgeActParaResp";

log.init({ JudgeHolder: "JudgeHolder" });

// export interface JudgeHolderProps {}
//({ logo, message, title }: HistItemProps)

// const JudgeHolder: FunctionComponent<JudgeHolderProps> = ({}: JudgeHolderProps) => {
const JudgeHolder: FunctionComponent = () => {
  // const ActParDetail: judgeActParaItemOK = JSON.parse(localStorage.getItem("judge"));
  // log.info(tag.JudgeHolder, "JudgeHolder: Mounted");

  return (
    <>
      <Separator>Search Judge par text Detail</Separator>
      <>
        {/* <JudgeItem actParItem={ActParDetail} /> */}
        <JudgeItem />
      </>
    </>
  );
};

export default JudgeHolder;
