import { log } from "missionlog";
import { ActParaItemOK } from "../api/schema/getActsParaResp";
import { judgeActParaItemOK } from "../api/schema/getJudgeActParaResp";
import limaStore from "../store/limaStore";
// import limaTimerStore from "../store/limaTimerStore";
import keycloak from "../taskpane/keycloak";
import { isKCTokens, KCTokens } from "../types/AccesTokens";
import {
  DIALOGMESSAGETYPE_ENUM,
  DialogParentMessage,
  DIALOGSTATETYPE_ENUM,
  DIALOGTYPE_ENUM,
} from "../types/dialogData";
import { judgeData } from "../types/keywords";
import { defDialogHeight, defDialogWidth } from "./limaEnv";
import { limaLogTag } from "./limaLog";
import { onLogged } from "./limaUser";
// import limaTimerStore from "../store/limaTimerStore";
// import { onLogged } from "./limaUser";

let DialogDataToSend: ActParaItemOK | judgeActParaItemOK | judgeData | null;
// var ActiveDialogJudge: Office.Dialog | null = null;
// var ActiveDialogActa: Office.Dialog | null = null;
let ActiveDialog: Office.Dialog | null = null;

export const openDialogAsIframe_actPar = (dataToSend: ActParaItemOK): void => {
  // let dialog: Office.Dialog;
  DialogDataToSend = dataToSend;
  DisplayDialogAsyncCall(DIALOGTYPE_ENUM.ACTPAR);
};

export const openDialogAsIframe_judge2 = (_dataToSend: judgeActParaItemOK | judgeData): void => {
  DialogDataToSend = _dataToSend;
  DisplayDialogAsyncCall(DIALOGTYPE_ENUM.JUDGE);
};

export const openDialogAsIframe_login = (_dataToSend: null): void => {
  DialogDataToSend = _dataToSend;
  DisplayDialogAsyncCall(DIALOGTYPE_ENUM.LOGIN);
};

/**
 * Get Active dialog for specific type
 * @param {DIALOGTYPE_ENUM} dialogType identify dialog type
 * @returns {Office.Dialog} return dialog item
 *
 * @author MS
 */
const GetActiveDialog = (dialogType: DIALOGTYPE_ENUM): Office.Dialog | null => {
  if (dialogType === DIALOGTYPE_ENUM.ACTPAR) return ActiveDialog;
  else if (dialogType === DIALOGTYPE_ENUM.JUDGE) return ActiveDialog;
  else if (dialogType === DIALOGTYPE_ENUM.LOGIN) return ActiveDialog;
  return ActiveDialog;
};

/**
 * Set Active dialog for specific type
 * @param {DIALOGTYPE_ENUM} dialogType identify dialog type
 * @param {Office.Dialog} dialogItem dialog item
 *
 * @author MS
 */
const SetActiveDialog = (dialogType: DIALOGTYPE_ENUM, dialogItem: Office.Dialog) => {
  if (dialogType === DIALOGTYPE_ENUM.ACTPAR) ActiveDialog = dialogItem;
  else if (dialogType === DIALOGTYPE_ENUM.JUDGE) ActiveDialog = dialogItem;
  else if (dialogType === DIALOGTYPE_ENUM.LOGIN) ActiveDialog = dialogItem;
};

/**
 * Get Active dialog processing event for specific type
 * @param {DIALOGTYPE_ENUM} dialogType identify dialog type
 * @returns {(arg:any)=>void} return processing event
 *
 * @author MS
 */
const GetActiveDialogProcessingEvent = (dialogType: DIALOGTYPE_ENUM): ((arg: any) => void | null) => {
  if (dialogType === DIALOGTYPE_ENUM.ACTPAR) return processDialogEventActa;
  else if (dialogType === DIALOGTYPE_ENUM.JUDGE) return processDialogEventJudge;
  else if (dialogType === DIALOGTYPE_ENUM.LOGIN) return processDialogEventLogin;
  return processDialogEvent;
};

const DisplayDialogAsyncCall = (dialogType: DIALOGTYPE_ENUM) => {
  // window.console.log(`AAAAAA `);
  if (dialogType === DIALOGTYPE_ENUM.ACTPAR) {
    if (ActiveDialog !== null) {
      sendCurrentDataToDialog(ActiveDialog);
      return;
    } else {
      OpenActiveDialog(dialogType, "/dactpar.html");
      // dialogLocation ="/dactpar.html";
    }
  } else if (dialogType === DIALOGTYPE_ENUM.JUDGE) {
    if (ActiveDialog !== null) {
      sendCurrentDataToDialog(ActiveDialog);
      return;
    } else {
      OpenActiveDialog(dialogType, "/djudge.html");
      // dialogLocation ="/djudge.html";
    }
  } else if (dialogType === DIALOGTYPE_ENUM.LOGIN) {
    if (ActiveDialog !== null) {
      sendCurrentDataToDialog(ActiveDialog);
      return;
    } else {
      OpenActiveDialog(dialogType, "/dlogin.html", 50, 30);
    }
  }
};

/**
 * Open active Dialog interface
 * @param {Office.Dialog | null} ActiveDialog Item
 * @param {string} dialogLocation location of dialog ".html"
 *
 * @author MS
 */
const OpenActiveDialog = (
  dialogType: DIALOGTYPE_ENUM,
  dialogLocation: string,
  dialogHeight?: number,
  dialogWidth?: number
) => {
  // window.console.log(`BBBBBB `);
  Office.context.ui.displayDialogAsync(
    window.location.origin + dialogLocation,
    {
      height: dialogHeight !== undefined ? dialogHeight : defDialogHeight,
      width: dialogWidth !== undefined ? dialogWidth : defDialogWidth,
      displayInIframe: false,
    },
    function (_asyncResult) {
      if (_asyncResult.status === Office.AsyncResultStatus.Failed) {
        log.error(
          limaLogTag.LimaDialog,
          `OpenActiveDialog: asyncResult failed: ${_asyncResult.error.code} : ${_asyncResult.error.message}`
        );
        if (_asyncResult.error.code === 12007) {
          log.error(limaLogTag.LimaDialog, "OpenActiveDialog: asyncResult failed: ", _asyncResult);
          //GetActiveDialog(dialogType) = _asyncResult.value;
          sendCurrentDataToDialog(GetActiveDialog(dialogType));
        }
      } else {
        log.info(limaLogTag.LimaDialog, `OpenActiveDialog: register handler`);
        // GetActiveDialog(dialogType) = _asyncResult.value;
        SetActiveDialog(dialogType, _asyncResult.value);
        GetActiveDialog(dialogType).addEventHandler(Office.EventType.DialogMessageReceived, processMessageFromChild);
        if (dialogType === DIALOGTYPE_ENUM.ACTPAR)
          GetActiveDialog(dialogType).addEventHandler(
            Office.EventType.DialogEventReceived,
            GetActiveDialogProcessingEvent(dialogType)
          );
        else
          GetActiveDialog(dialogType).addEventHandler(
            Office.EventType.DialogEventReceived,
            GetActiveDialogProcessingEvent(dialogType)
          );
      }
    }
  );
};

/**
 * Processing message from child - Dialog
 * @param arg message from child
 *
 * acutally works only messageFromDialog.messageType === DIALOGMESSAGETYPE_ENUM.STATE &&  messageFromDialog.messageData === DIALOGSTATETYPE_ENUM.DATA_REQUESTED
 *
 * @author MS
 */
function processMessageFromChild(arg: any) {
  log.info(limaLogTag.LimaDialog, `processMessageFromChild: recieved data from dialog`, arg);
  // log.info(limaLogTag.LimaDialog, `processMessageFromChild: async send data`, arg.message);
  const messageFromDialog: DialogParentMessage = JSON.parse(arg.message);
  log.info(limaLogTag.LimaDialog, `processMessageFromChild: recieved data from dialog message `, messageFromDialog);

  //IF EQEST TO GET DATA.. send them.
  if (
    messageFromDialog.messageType === DIALOGMESSAGETYPE_ENUM.STATE &&
    messageFromDialog.messageData === DIALOGSTATETYPE_ENUM.DATA_REQUESTED
  ) {
    log.info(limaLogTag.LimaDialog, `processMessageFromChild: processing send data reuest`);
    if (messageFromDialog.dialogType === DIALOGTYPE_ENUM.ACTPAR) sendCurrentDataToDialog(ActiveDialog);
    else if (messageFromDialog.dialogType === DIALOGTYPE_ENUM.JUDGE) sendCurrentDataToDialog(ActiveDialog);
  } else if (messageFromDialog.messageType === DIALOGMESSAGETYPE_ENUM.DATA) {
    log.info(limaLogTag.LimaDialog, `processMessageFromChild: processing send data from child - DATA`);
    if (messageFromDialog.dialogType === DIALOGTYPE_ENUM.LOGIN) {
      if (isKCTokens(messageFromDialog.messageData)) {
        log.info(limaLogTag.LimaDialog, `processMessageFromChild: processing send data from child - DATA - LOGIN`);
        processLoginData(messageFromDialog.messageData);
      } else {
        log.error(
          limaLogTag.LimaDialog,
          `processMessageFromChild: expected login data but dont get`,
          messageFromDialog
        );
      }
    }
  } else {
    log.info(
      limaLogTag.LimaDialog,
      `processMessageFromChild: processing send data from child ${messageFromDialog.messageType}`
    );
  }
}

/**
 * Sending message to Current open dialog
 * @param {Office.Dialog} ActiveDialog Dialog is Either ActiveDialogActa / ActiveDialogJudge
 *
 * @author MS
 */
const sendCurrentDataToDialog = (ActiveDialog: Office.Dialog) => {
  log.info(limaLogTag.LimaDialog, `sendCurrentDataToDialog: sending data to Dialog`, DialogDataToSend, ActiveDialog);
  if (ActiveDialog !== undefined && ActiveDialog !== null && DialogDataToSend !== null)
    ActiveDialog.messageChild(JSON.stringify(DialogDataToSend));
  else log.error(limaLogTag.LimaDialog, `processMessageFromChild: Dialog seams not to be acitve`);
};

/**
 * Process tokens from login
 */
const processLoginData = (messageFromDialogData: KCTokens) => {
  // if (isKCTokens(messageFromDialogData)) {
  // const token = messageFromDialogData.token;
  // log.info(limaLogTag.LimaDialog, `processLoginData: strart processing logn dialog close dialog`);
  // closeActiveDialog(DIALOGTYPE_ENUM.LOGIN);
  log.info(limaLogTag.LimaDialog, `processLoginData: strart processing logn dialog`, messageFromDialogData);
  // keycloak.clearToken();
  // keycloak.token = messageFromDialogData.token;
  // keycloak.idToken = messageFromDialogData.idtoken;
  // keycloak.refreshToken = messageFromDialogData.refreshToken;
  //keycloak.init({enableLogging:true, })
  if (keycloak.authenticated === true) {
    log.info(limaLogTag.LimaDialog, `processLoginData: authenticated ok`, messageFromDialogData);
    log.info(limaLogTag.LimaDialog, `processLoginData: close login dialog`);
    closeActiveDialog(DIALOGTYPE_ENUM.LOGIN);
    log.debug(limaLogTag.LimaDialog, `processLoginData: continue logging process`);
    onLogged();
    // limaStore.setLogged(keycloak.authenticated);
    // //get Timers from DB
    // limaTimerStore.getTodayTimers();
  } else {
    log.info(limaLogTag.LimaDialog, `processLoginData: authenticated false`, messageFromDialogData);
    keycloak
      .init({
        token: messageFromDialogData.token,
        refreshToken: messageFromDialogData.refreshToken,
        idToken: messageFromDialogData.idtoken,
        checkLoginIframe: false,
        enableLogging: true,
        //checkLoginIframeInterval: 1,
        // messageReceiveTimeout: 1000,
        //onLoad: "check-sso",
      })
      .then(async (authenticated: boolean) => {
        log.info(limaLogTag.LimaDialog, `processLoginData: authenticated`, authenticated);
        if (!authenticated) {
          return;
        }

        log.info(limaLogTag.LimaDialog, `processLoginData - user id:  ${messageFromDialogData.userID}`);
        //void i18n.changeLanguage(userLogin.parsedBody.lang);
        limaStore.setAccessToken(messageFromDialogData.token);

        log.info(limaLogTag.LimaDialog, `processLoginData: close login dialog`);
        onLogged();
        closeActiveDialog(DIALOGTYPE_ENUM.LOGIN);
        //REST WILL COVER AUTHENTICATION IN KEYCLOAK ON AUTH...
      })
      .catch((error) => {
        log.error(limaLogTag.LimaDialog, `processLoginData: expected login data but dont get`, error);
      })
      .finally(() => {
        log.info(limaLogTag.LimaDialog, `processLoginData: finaly`);
      });
  }
};

/**
 * Processing events from dialog
 * @param arg
 */
function processDialogEvent(arg: any) {
  log.info(limaLogTag.LimaDialog, `processDialogEvent: async send data`, arg);
  switch (arg.error) {
    case 12002:
      log.info(
        limaLogTag.LimaDialog,
        "processDialogEvent:The dialog box has been directed to a page that it cannot find or load, or the URL syntax is invalid."
      );
      break;
    case 12003:
      log.info(
        limaLogTag.LimaDialog,
        "processDialogEvent:The dialog box has been directed to a URL with the HTTP protocol. HTTPS is required."
      );
      break;
    case 12006:
      log.info(limaLogTag.LimaDialog, "processDialogEvent:Dialog closed.");
      closeActiveDialog();
      break;
    default:
      log.info(limaLogTag.LimaDialog, "processDialogEvent:Unknown error in dialog box.");
      break;
  }
}
function processDialogEventActa(arg: any) {
  log.info(limaLogTag.LimaDialog, `processDialogEventActa: async send data`, arg);
  switch (arg.error) {
    case 12002:
      log.info(
        limaLogTag.LimaDialog,
        "processDialogEventActa:The dialog box has been directed to a page that it cannot find or load, or the URL syntax is invalid."
      );
      break;
    case 12003:
      log.info(
        limaLogTag.LimaDialog,
        "processDialogEventActa:The dialog box has been directed to a URL with the HTTP protocol. HTTPS is required."
      );
      break;
    case 12006:
      log.info(limaLogTag.LimaDialog, "processDialogEventActa:Dialog closed.");
      closeActiveDialog(DIALOGTYPE_ENUM.ACTPAR);
      break;
    default:
      log.info(limaLogTag.LimaDialog, "processDialogEventActa:Unknown error in dialog box.");
      break;
  }
}
function processDialogEventJudge(arg: any) {
  log.info(limaLogTag.LimaDialog, `processDialogEventJudge: async send data`, arg);
  switch (arg.error) {
    case 12002:
      log.info(
        limaLogTag.LimaDialog,
        "processDialogEventJudge:The dialog box has been directed to a page that it cannot find or load, or the URL syntax is invalid."
      );
      break;
    case 12003:
      log.info(
        limaLogTag.LimaDialog,
        "processDialogEventJudge:The dialog box has been directed to a URL with the HTTP protocol. HTTPS is required."
      );
      break;
    case 12006:
      log.info(limaLogTag.LimaDialog, "processDialogEventJudge:Dialog closed.");
      closeActiveDialog(DIALOGTYPE_ENUM.JUDGE);
      break;
    default:
      log.info(limaLogTag.LimaDialog, "processDialogEventJudge:Unknown error in dialog box.");
      break;
  }
}

/**
 * Processing callback from dialog window
 * @param arg
 */
function processDialogEventLogin(arg: any) {
  log.info(limaLogTag.LimaDialog, `processDialogEventLogin: async send data`, arg);
  switch (arg.error) {
    case 12002:
      log.info(
        limaLogTag.LimaDialog,
        "processDialogEventLogin:The dialog box has been directed to a page that it cannot find or load, or the URL syntax is invalid."
      );
      break;
    case 12003:
      log.info(
        limaLogTag.LimaDialog,
        "processDialogEventLogin:The dialog box has been directed to a URL with the HTTP protocol. HTTPS is required."
      );
      break;
    case 12006:
      log.info(limaLogTag.LimaDialog, "processDialogEventLogin:Dialog closed.");
      closeActiveDialog(DIALOGTYPE_ENUM.LOGIN);
      break;
    default:
      log.info(limaLogTag.LimaDialog, "processDialogEventLogin:Unknown error in dialog box.");
      break;
  }
}

/**
 * Close all active Dialogss
 *
 * @param {DIALOGTYPE_ENUM} type optional, if wnat close just one
 *
 * @author MS
 */
export const closeActiveDialog = (type?: DIALOGTYPE_ENUM): void => {
  log.info(limaLogTag.LimaDialog, "closeActiveDialog: closing Dialogs", type);
  if ((type === undefined || type === DIALOGTYPE_ENUM.ACTPAR) && ActiveDialog !== undefined && ActiveDialog !== null) {
    ActiveDialog.close();
    ActiveDialog = null;
  }
  if ((type === undefined || type === DIALOGTYPE_ENUM.JUDGE) && ActiveDialog !== undefined && ActiveDialog !== null) {
    ActiveDialog.close();
    ActiveDialog = null;
  }
  if ((type === undefined || type === DIALOGTYPE_ENUM.LOGIN) && ActiveDialog !== undefined && ActiveDialog !== null) {
    ActiveDialog.close();
    ActiveDialog = null;
  }
  DialogDataToSend = null;
};

export const openDialogAsIframe_taskhistory = (): void => {
  //IMPORTANT: IFrame mode only works in Online (Web) clients. Desktop clients (Windows, IOS, Mac) always display as a pop-up inside of Office apps.
  Office.context.ui.displayDialogAsync(window.location.origin + "/commtaskhist.html", {
    height: defDialogHeight,
    width: defDialogWidth,
    displayInIframe: true,
  });
};
