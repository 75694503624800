import { log, tag } from "missionlog";
import { action, makeObservable, observable } from "mobx";
import { urlGetTasksAndWordsForPara } from "../api/apilinks";
import { httpGet, HttpResponse } from "../api/httpAPI";
import { tasksXcopy } from "../types/tasksXcopy";
import limaLoadeStore from "./limaLoaderStore";
import limaStore from "./limaStore";

log.init({ LimaTasksVersionsStore: "LimaTasksVersionsStore" });

class LimaTasksVersionsStore {
  tasksVersions: tasksXcopy | null = null;

  constructor() {
    makeObservable(this, {
      tasksVersions: observable,

      //setActVal: action,
      setTasksVersions: action,
    });
  }

  setTasksVersions(newTasksVersions: tasksXcopy) {
    log.info(tag.limaTasksStore, "setTasksVersions: update", newTasksVersions);
    this.tasksVersions = newTasksVersions;
  }

  getTasksVersionsFromDB = async (paraId: string) => {
    //Obosliite not zet refactored
    if (paraId == null && paraId === "") {
      log.warn(tag.LimaTasksVersionsStore, "getTasksVersionsFromDB: we dont have paraId ");
    }
    if (limaStore.actaId == null && limaStore.actaId === "") {
      log.warn(tag.LimaTasksVersionsStore, "getTasksVersionsFromDB: we dont have actaid");
    }
    if (limaStore.userId == null && limaStore.userId === "") {
      log.warn(tag.LimaTasksVersionsStore, "getTasksVersionsFromDB: we dont have userId");
    }

    // this.setProgress(true);
    let tasksVersionsData: HttpResponse<tasksXcopy>;
    const loaderID = limaLoadeStore.add("get tasks version DB");
    try {
      tasksVersionsData = await httpGet<tasksXcopy>(
        urlGetTasksAndWordsForPara(paraId, limaStore.userId, limaStore.actaId),
        loaderID.ac
      );

      log.info(tag.LimaTasksVersionsStore, "I get Data from call tasks info");
      if (tasksVersionsData.parsedBody !== undefined) {
        this.setTasksVersions(tasksVersionsData.parsedBody);
      }
      log.info(tag.LimaTasksVersionsStore, "tasksList: ", this.tasksVersions);
    } catch (error) {
      log.error(tag.LimaTasksVersionsStore, "Error call get User", error);
    } finally {
      // this.setProgress(false);
      limaLoadeStore.remove(loaderID.k);
    }
  };

  /**
   * Cleanup on logout button
   *
   * @autor MS
   */
  onLogoutClear() {
    this.tasksVersions = null;
  }
}

const limaTasksVersionsStore = new LimaTasksVersionsStore();
export default limaTasksVersionsStore;
