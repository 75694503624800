import { LimaEnum_licenceType } from "../types/LimaLicences";
import { limaPermissions, limaPermissionsActa, limaPermissionsCompany } from "../types/UserPermissions";

/**
 * USER_LINKING - enable option to link new user
 * USER_SUPERADMIN - not implemented yet
 */
export enum LIMAUSER_GENERAL_PERMISSIONS {
  default = "default",
  USER_LINKING = "USER_LINKING", //Enable link new user
  USER_SUPERADMIN = "USER_SUPERADMIN", //user is super admin
  USER_TESTER = "USER_TESTER", //user is tester
}

export enum LIMAUSER_COMPANY_PERMISSIONS {
  USER_ADD = "USER_ADD",
  USER_REMOVE = "USER_REMOVE",
  USER_PERMISSIONS_ADD_REMOVE = "USER_PERMISSIONS_ADD_REMOVE",
  USER_STATISTIC = "USER_STATISTIC",
  USER_LICENCE_MANAGER = "USER_LICENCE_MANAGER",
  USER_LICENCE_USAGE_CLEAN = "USER_LICENCE_USAGE_CLEAN",
  USER_COMPANY_MANAGER = "USER_COMPANY_MANAGER",
  USER_TOOL_COMPARE_IDENT = "USER_TOOL_COMPARE_IDENT",
  USER_TOOL_ADVISORS = "USER_TOOL_ADVISORS",
  default = "default",
}

export enum LIMAUSER_ACTA_PERMISSIONS {
  ACTA_SAVE = "ACTA_SAVE",
  ACTA_SAVE_AS_NEW = "ACTA_SAVE_AS_NEW",
  ACTA_SAVE_TO_COPY = "ACTA_SAVE_TO_COPY",
  ACTA_SAVE_CREATE_COPY = "ACTA_SAVE_CREATE_COPY",
  default = "default",
}

export enum LIMAUSER_PERMISSIONS_TYPE {
  GENERAL = "GENERAL",
  COMPANY = "COMPANY",
  ACTA = "ACTA",
}

/**
 * basic class for processing user permission
 *
 * @example
 * ```
 * {limaStore.userPermissons.checkPermissionIdent(
 *   LIMAUSER_PERMISSIONS_TYPE.COMPANY,
 *   LIMAUSER_COMPANY_PERMISSIONS.USER_PERMISSIONS_ADD_REMOVE
 *   ) && (
 *   <Stack.Item>
 *     <UserPermissionEditPage userId={item.userId} />
 *    </Stack.Item>
 * )}
 * ```
 */

export class Lima_Permissions {
  //field
  general_permissions: limaPermissions[];
  company_permissions: limaPermissionsCompany[];
  acta_permissions: limaPermissionsActa[];
  isSet: boolean;

  //constructor
  constructor(
    general_permissions: limaPermissions[],
    company_permissions: limaPermissionsCompany[],
    acta_permissions: limaPermissionsActa[],
    isSet = false
  ) {
    this.general_permissions = general_permissions;
    this.company_permissions = company_permissions;
    this.acta_permissions = acta_permissions;
    this.isSet = isSet;
  }

  //function
}
//const limaUserPermissions = new Lima_Permissions([], [], []);
export const limaUserPermissions = new Lima_Permissions([], [], []);

export interface IReqPerm {
  permType: LIMAUSER_PERMISSIONS_TYPE;
  permIdent: LIMAUSER_GENERAL_PERMISSIONS | LIMAUSER_COMPANY_PERMISSIONS | LIMAUSER_ACTA_PERMISSIONS;
}

export interface IAccesLicPerm {
  ident: LIMAENUM_AccesLicPermIdent;
  accesLicPerm: {
    reqlicenceType: LimaEnum_licenceType;
    reqPermissionTypes: IReqPerm[] | null;
  }[];
}

export enum LIMAENUM_AccesLicPermIdent {
  ACC_superadmin = "acc_superadmin",
  ACC_toolsAdvisorsAll = "acc_toolsAdvisorsAll",
  ACC_toolsActionsIdentParas = "acc_toolsActionsIdentParas",
  ACC_toolsUserAddLinkUser = "acc_toolsUserAddLinkUser",
  ACC_funcUserStatistic = "acc_funcUserStatistic",
  ACC_funcUserPermissions = "acc_funcUserPermissions",
  ACC_toolsUserLicenceManager = "acc_toolsUserLicenceManager",
  ACC_toolsUserLogoutLicence = "acc_toolsUserLogoutLicence",
  ACC_toolsUserCompanyManager = "acc_toolsUserCompanyManager",
  ACC_toolsUserRemoveLinkUser = "acc_toolsUserRemoveLinkUser",
  ACC_toolsOtherTester = "acc_toolsOtherTester",
  ACC_funcActaSaveCreateCopy = "ACC_funcActaSaveCreateCopy",
}

/**
 * licence and permission
 * or
 * licence and permission and permission
 * or
 * licence
 *
 */
export const identPermission: IAccesLicPerm[] = [
  {
    ident: LIMAENUM_AccesLicPermIdent.ACC_superadmin,
    accesLicPerm: [
      {
        reqlicenceType: LimaEnum_licenceType.BASIC,
        reqPermissionTypes: [
          { permType: LIMAUSER_PERMISSIONS_TYPE.GENERAL, permIdent: LIMAUSER_GENERAL_PERMISSIONS.USER_SUPERADMIN },
        ],
      },
      {
        reqlicenceType: LimaEnum_licenceType.ADVANCED,
        reqPermissionTypes: [
          { permType: LIMAUSER_PERMISSIONS_TYPE.GENERAL, permIdent: LIMAUSER_GENERAL_PERMISSIONS.USER_SUPERADMIN },
        ],
      },
    ],
  },
  {
    ident: LIMAENUM_AccesLicPermIdent.ACC_toolsAdvisorsAll,
    accesLicPerm: [
      // {
      //   reqlicenceType: LimaEnum_licenceType.BASIC,
      //   reqPermissionTypes: [
      //     { permType: LIMAUSER_PERMISSIONS_TYPE.GENERAL, permIdent: LIMAUSER_GENERAL_PERMISSIONS.USER_SUPERADMIN },
      //   ],
      // },
      // {
      //   reqlicenceType: LimaEnum_licenceType.BASIC,
      //   reqPermissionTypes: [
      //     { permType: LIMAUSER_PERMISSIONS_TYPE.COMPANY, permIdent: LIMAUSER_COMPANY_PERMISSIONS.USER_TOOL_ADVISORS },
      //   ],
      // },
      // {
      //   reqlicenceType: LimaEnum_licenceType.ADVANCED,
      //   reqPermissionTypes: [
      //     { permType: LIMAUSER_PERMISSIONS_TYPE.GENERAL, permIdent: LIMAUSER_GENERAL_PERMISSIONS.USER_SUPERADMIN },
      //   ],
      // },
      {
        reqlicenceType: LimaEnum_licenceType.ADVANCED,
        reqPermissionTypes: [
          { permType: LIMAUSER_PERMISSIONS_TYPE.COMPANY, permIdent: LIMAUSER_COMPANY_PERMISSIONS.USER_TOOL_ADVISORS },
        ],
      },
    ],
  },
  {
    ident: LIMAENUM_AccesLicPermIdent.ACC_toolsActionsIdentParas,
    accesLicPerm: [
      {
        reqlicenceType: LimaEnum_licenceType.BASIC,
        reqPermissionTypes: [
          {
            permType: LIMAUSER_PERMISSIONS_TYPE.COMPANY,
            permIdent: LIMAUSER_COMPANY_PERMISSIONS.USER_TOOL_COMPARE_IDENT,
          },
        ],
      },
      {
        reqlicenceType: LimaEnum_licenceType.ADVANCED,
        reqPermissionTypes: [
          {
            permType: LIMAUSER_PERMISSIONS_TYPE.COMPANY,
            permIdent: LIMAUSER_COMPANY_PERMISSIONS.USER_TOOL_COMPARE_IDENT,
          },
        ],
      },
    ],
  },
  {
    ident: LIMAENUM_AccesLicPermIdent.ACC_toolsUserAddLinkUser,
    accesLicPerm: [
      {
        reqlicenceType: LimaEnum_licenceType.BASIC,
        reqPermissionTypes: [
          { permType: LIMAUSER_PERMISSIONS_TYPE.COMPANY, permIdent: LIMAUSER_COMPANY_PERMISSIONS.USER_ADD },
        ],
      },
      {
        reqlicenceType: LimaEnum_licenceType.ADVANCED,
        reqPermissionTypes: [
          { permType: LIMAUSER_PERMISSIONS_TYPE.COMPANY, permIdent: LIMAUSER_COMPANY_PERMISSIONS.USER_ADD },
        ],
      },
    ],
  },
  {
    ident: LIMAENUM_AccesLicPermIdent.ACC_toolsUserLicenceManager,
    accesLicPerm: [
      {
        reqlicenceType: LimaEnum_licenceType.BASIC,
        reqPermissionTypes: [
          { permType: LIMAUSER_PERMISSIONS_TYPE.COMPANY, permIdent: LIMAUSER_COMPANY_PERMISSIONS.USER_LICENCE_MANAGER },
        ],
      },
      {
        reqlicenceType: LimaEnum_licenceType.ADVANCED,
        reqPermissionTypes: [
          { permType: LIMAUSER_PERMISSIONS_TYPE.COMPANY, permIdent: LIMAUSER_COMPANY_PERMISSIONS.USER_LICENCE_MANAGER },
        ],
      },
    ],
  },

  {
    ident: LIMAENUM_AccesLicPermIdent.ACC_toolsUserLogoutLicence,
    accesLicPerm: [
      {
        reqlicenceType: LimaEnum_licenceType.BASIC,
        reqPermissionTypes: [
          {
            permType: LIMAUSER_PERMISSIONS_TYPE.COMPANY,
            permIdent: LIMAUSER_COMPANY_PERMISSIONS.USER_LICENCE_USAGE_CLEAN,
          },
        ],
      },
      {
        reqlicenceType: LimaEnum_licenceType.ADVANCED,
        reqPermissionTypes: [
          {
            permType: LIMAUSER_PERMISSIONS_TYPE.COMPANY,
            permIdent: LIMAUSER_COMPANY_PERMISSIONS.USER_LICENCE_USAGE_CLEAN,
          },
        ],
      },
    ],
  },
  {
    ident: LIMAENUM_AccesLicPermIdent.ACC_toolsUserCompanyManager,
    accesLicPerm: [
      {
        reqlicenceType: LimaEnum_licenceType.BASIC,
        reqPermissionTypes: [
          { permType: LIMAUSER_PERMISSIONS_TYPE.GENERAL, permIdent: LIMAUSER_GENERAL_PERMISSIONS.USER_SUPERADMIN },
        ],
      },
      {
        reqlicenceType: LimaEnum_licenceType.ADVANCED,
        reqPermissionTypes: [
          { permType: LIMAUSER_PERMISSIONS_TYPE.GENERAL, permIdent: LIMAUSER_GENERAL_PERMISSIONS.USER_SUPERADMIN },
        ],
      },
      {
        reqlicenceType: LimaEnum_licenceType.BASIC,
        reqPermissionTypes: [
          { permType: LIMAUSER_PERMISSIONS_TYPE.COMPANY, permIdent: LIMAUSER_COMPANY_PERMISSIONS.USER_COMPANY_MANAGER },
        ],
      },
      {
        reqlicenceType: LimaEnum_licenceType.ADVANCED,
        reqPermissionTypes: [
          { permType: LIMAUSER_PERMISSIONS_TYPE.COMPANY, permIdent: LIMAUSER_COMPANY_PERMISSIONS.USER_COMPANY_MANAGER },
        ],
      },
    ],
  },
  {
    ident: LIMAENUM_AccesLicPermIdent.ACC_toolsUserRemoveLinkUser,
    accesLicPerm: [
      // {
      //   reqlicenceType: LimaEnum_licenceType.BASIC,
      //   reqPermissionTypes: [
      //     { permType: LIMAUSER_PERMISSIONS_TYPE.GENERAL, permIdent: LIMAUSER_GENERAL_PERMISSIONS.USER_SUPERADMIN },
      //   ],
      // },
      {
        reqlicenceType: LimaEnum_licenceType.BASIC,
        reqPermissionTypes: [
          { permType: LIMAUSER_PERMISSIONS_TYPE.COMPANY, permIdent: LIMAUSER_COMPANY_PERMISSIONS.USER_REMOVE },
        ],
      },
      {
        reqlicenceType: LimaEnum_licenceType.ADVANCED,
        reqPermissionTypes: [
          { permType: LIMAUSER_PERMISSIONS_TYPE.COMPANY, permIdent: LIMAUSER_COMPANY_PERMISSIONS.USER_REMOVE },
        ],
      },
    ],
  },
  {
    ident: LIMAENUM_AccesLicPermIdent.ACC_toolsOtherTester,
    accesLicPerm: [
      // {
      //   reqlicenceType: LimaEnum_licenceType.BASIC,
      //   reqPermissionTypes: [
      //     { permType: LIMAUSER_PERMISSIONS_TYPE.GENERAL, permIdent: LIMAUSER_GENERAL_PERMISSIONS.USER_SUPERADMIN },
      //   ],
      // },
      {
        reqlicenceType: LimaEnum_licenceType.BASIC,
        reqPermissionTypes: [
          { permType: LIMAUSER_PERMISSIONS_TYPE.GENERAL, permIdent: LIMAUSER_GENERAL_PERMISSIONS.USER_TESTER },
        ],
      },
      {
        reqlicenceType: LimaEnum_licenceType.ADVANCED,
        reqPermissionTypes: [
          { permType: LIMAUSER_PERMISSIONS_TYPE.GENERAL, permIdent: LIMAUSER_GENERAL_PERMISSIONS.USER_TESTER },
        ],
      },
    ],
  },
  {
    ident: LIMAENUM_AccesLicPermIdent.ACC_funcUserStatistic,
    accesLicPerm: [
      // {
      //   reqlicenceType: LimaEnum_licenceType.BASIC,
      //   reqPermissionTypes: [
      //     { permType: LIMAUSER_PERMISSIONS_TYPE.GENERAL, permIdent: LIMAUSER_GENERAL_PERMISSIONS.USER_SUPERADMIN },
      //   ],
      // },
      {
        reqlicenceType: LimaEnum_licenceType.BASIC,
        reqPermissionTypes: [
          { permType: LIMAUSER_PERMISSIONS_TYPE.COMPANY, permIdent: LIMAUSER_COMPANY_PERMISSIONS.USER_STATISTIC },
        ],
      },
      {
        reqlicenceType: LimaEnum_licenceType.ADVANCED,
        reqPermissionTypes: [
          { permType: LIMAUSER_PERMISSIONS_TYPE.COMPANY, permIdent: LIMAUSER_COMPANY_PERMISSIONS.USER_STATISTIC },
        ],
      },
    ],
  },
  {
    ident: LIMAENUM_AccesLicPermIdent.ACC_funcUserPermissions,
    accesLicPerm: [
      {
        reqlicenceType: LimaEnum_licenceType.BASIC,
        reqPermissionTypes: [
          {
            permType: LIMAUSER_PERMISSIONS_TYPE.GENERAL,
            permIdent: LIMAUSER_GENERAL_PERMISSIONS.USER_SUPERADMIN,
          },
        ],
      },
      {
        reqlicenceType: LimaEnum_licenceType.ADVANCED,
        reqPermissionTypes: [
          {
            permType: LIMAUSER_PERMISSIONS_TYPE.GENERAL,
            permIdent: LIMAUSER_GENERAL_PERMISSIONS.USER_SUPERADMIN,
          },
        ],
      },
      {
        reqlicenceType: LimaEnum_licenceType.BASIC,
        reqPermissionTypes: [
          {
            permType: LIMAUSER_PERMISSIONS_TYPE.COMPANY,
            permIdent: LIMAUSER_COMPANY_PERMISSIONS.USER_PERMISSIONS_ADD_REMOVE,
          },
        ],
      },
      {
        reqlicenceType: LimaEnum_licenceType.ADVANCED,
        reqPermissionTypes: [
          {
            permType: LIMAUSER_PERMISSIONS_TYPE.COMPANY,
            permIdent: LIMAUSER_COMPANY_PERMISSIONS.USER_PERMISSIONS_ADD_REMOVE,
          },
        ],
      },
    ],
  },
  {
    ident: LIMAENUM_AccesLicPermIdent.ACC_funcActaSaveCreateCopy,
    accesLicPerm: [
      {
        reqlicenceType: LimaEnum_licenceType.BASIC,
        reqPermissionTypes: [
          {
            permType: LIMAUSER_PERMISSIONS_TYPE.ACTA,
            permIdent: LIMAUSER_ACTA_PERMISSIONS.ACTA_SAVE_CREATE_COPY,
          },
        ],
      },
      {
        reqlicenceType: LimaEnum_licenceType.ADVANCED,
        reqPermissionTypes: [
          {
            permType: LIMAUSER_PERMISSIONS_TYPE.ACTA,
            permIdent: LIMAUSER_ACTA_PERMISSIONS.ACTA_SAVE_CREATE_COPY,
          },
        ],
      },
    ],
  },
];
