import React = require("react");
import { LimaIconProps } from "./DefLimaIconType";

export const LimaIconOpenActa = ({
  size = 32, // or any default size of your choice
  color = "black", // or any color of your choice
  fillColor = "black", // or any color of your choice
  title = "", //title
}: LimaIconProps): JSX.Element => {
  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 24 24"
    //   width={size} // added size here
    //   height={size} // added size here
    //   fill={color} // added color here
    //   aria-labelledby="title"
    // >
    //   <title id="title">{title}</title>
    //   <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
    //   <path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0 1 30.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1 0 80 0 40 40 0 1 0-80 0z" />
    // </svg>

    <svg
      width={size}
      height={size}
      viewBox="0 0 210 297"
      xmlns="http://www.w3.org/2000/svg"
      fill={fillColor} // added color here
      stroke={color}
      aria-labelledby="title"
    >
      <title id="title">{title}</title>
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            // fill: "#212121",
            fill: color,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 33.3333,
          }}
          d="m376.441 24.057-3.39.23c-12.203 1.656-21.608 12.115-21.608 24.772l.227 3.392c1.655 12.203 12.115 21.608 24.771 21.608h156.293L292.13 314.819l-2.42 2.804c-7.26 9.789-6.448 23.677 2.43 32.55l2.802 2.42c9.79 7.26 23.678 6.45 32.551-2.427l240.643-240.773.025 156.462.227 3.393c1.655 12.203 12.115 21.607 24.771 21.607 13.807 0 25.002-11.192 25.002-25l-.015-217.751-.315-3.14-.451-2.128-.64-2.14-1.29-3.01-1.806-3.004-2.791-3.287-2.313-2.09a23.492 23.492 0 0 0-5.068-3.028l-1.895-.802-3.36-.94-3.318-.459zm-216.6 49.957-6.638.17C89.46 77.49 38.35 128.59 35.013 192.348l-.146 6.75.074 283.279.176 6.639c3.353 63.75 54.468 114.838 118.219 118.162l6.777.17 162.748-.225v-50l-162.9.223-5.135-.174c-39.027-2.629-69.874-35.113-69.885-74.809l-.093-284.353.252-5.297c3.187-38.485 35.43-68.718 74.728-68.7h116.658l3.393-.228c12.202-1.655 21.607-12.115 21.607-24.771 0-13.807-11.193-25-25-25zM543.13 340.08c-9.899 0-18.428 5.768-22.479 14.113h44.963c-4.05-8.346-12.584-14.113-22.484-14.113z"
          transform="scale(.26458)"
        />
      </g>
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          d="M167.865 180.105c0 1.672-1.336 3.029-2.984 3.029h-50.236c-1.645 0-2.985-1.357-2.985-3.03v-64.616c0-1.673 1.34-3.032 2.985-3.032h25.035v21.876c0 3.72 2.97 6.731 6.632 6.731l21.553-.003zm-23.211-65.89 10.74 10.899 10.74 10.898-19.822.003c-.597 0-.992-.307-1.174-.492a1.705 1.705 0 0 1-.484-1.191zm25.854 19.1-23.192-23.538c-.228-.232-.49-.418-.739-.616-.982-.784-2.109-1.346-3.33-1.596a7.773 7.773 0 0 0-1.561-.158h-27.041c-4.394 0-7.959 3.618-7.959 8.081v64.617c0 4.463 3.565 8.077 7.959 8.077h50.236c4.397 0 7.958-3.614 7.958-8.077v-41.078a8.137 8.137 0 0 0-2.331-5.711z"
          style={{
            // fill: "#212121",
            fill: color,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 10,
            strokeMiterlimit: 4,
            strokeDasharray: "none",
          }}
        />
      </g>
    </svg>
  );
};
