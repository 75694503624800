import { FC } from "react";
import { Controller } from "react-hook-form";
import { IDropdownProps, Dropdown, IDropdownOption, Stack, Label } from "@fluentui/react";
import * as React from "react";
import { HookFormProps } from "./HookFormProps";
import { LimaNotice, LIMA_TEXTNOTICETYPE } from "./LimaNotice";
import { useTranslation } from "react-i18next";

type LimaControledDropDownFieldV4Type = {
  // values: IDropdownOption[];
  // selectedItem: string;
  limaLabel?: string;
};

export const LimaControledDropDownFieldV4: FC<HookFormProps & IDropdownProps & LimaControledDropDownFieldV4Type> = (
  props: HookFormProps & IDropdownProps & LimaControledDropDownFieldV4Type
) => {
  const newLocal: string = typeof props.defaultValue === "string" ? props.defaultValue : "";
  const { t } = useTranslation();

  return (
    <Stack horizontal>
      {props.limaLabel && (
        <Stack.Item style={{ width: "60%" }}>
          <Label>{props.limaLabel}</Label>
        </Stack.Item>
      )}
      <Stack.Item style={{ width: "40%" }}>
        <Controller
          name={props.name}
          control={props.control}
          rules={props.rules}
          defaultValue={newLocal}
          //          render={({ field: { onChange, onBlur, name: fieldName, value }, fieldState: { error } }) => (
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <>
              <Dropdown
                {...props}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                selectedKey={value}
                onBlur={onBlur}
                onChange={(_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
                  //window.console.log(" BBBBBBBBBBBBBBB Dropdown onchange to ", option);
                  onChange(option.key);
                  return option.key;
                }}
                options={props.options}
              />
              {error && (
                <LimaNotice noticeType={LIMA_TEXTNOTICETYPE.ERROR}>{t(`tpmain:error.${error.type}`)}</LimaNotice>
              )}
            </>
          )}
        />
      </Stack.Item>
    </Stack>
  );
};
