import {
  FocusZone,
  FocusZoneDirection,
  getTheme,
  ITheme,
  List,
  mergeStyleSets,
  Separator,
  Stack,
} from "@fluentui/react";
import { log } from "missionlog";
import { FunctionComponent, useRef } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import { IAttachmentFileWithInfo, validTypes } from "../../../../../types/LimaAttachement";
import AttachmentDDFileItemV4 from "./AttachmentDDFileItemV4";

interface IAttachementAddV4Props {
  fileUploaddHandler?: (file: File, name: string, descr: string) => void;
  validFiles: IAttachmentFileWithInfo[];
  //setValidFiles: React.Dispatch<React.SetStateAction<IAttachmentFileWithInfo[]>>;
  setValidFiles: (files: IAttachmentFileWithInfo[]) => void;
  unsupportedFiles: IAttachmentFileWithInfo[];
  setUnsupportedFiles: React.Dispatch<React.SetStateAction<IAttachmentFileWithInfo[]>>;
  enableUploadSingleFile: boolean;
  onlyDD?: boolean;
  dropdownCleanup: number;
}

const theme: ITheme = getTheme();
const { palette } = theme;

const classNames = mergeStyleSets({
  container: {
    color: "red",
    display: "flex",
  },
  dropContainer: {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    margin: 5,
    height: 75,
    width: "75%",
    borderColor: "#4aa1f3",
    borderStyle: "dashed",
    borderWidth: 1,
  },
  fileInput: {
    display: "none",
  },
  containerButton: {
    alignItems: "center",
    justifyContent: "center",
    margin: 5,
    height: 100,
    flexShrink: 0,
    minWidth: 40,
  },
  containerButtonUpload: {
    height: 100,
    selectors: {
      "&:hover": {
        cursor: "pointer",
        background: palette.neutralLight,
      },
    },
  },
  containerButtonSorry: {
    height: 100,
    backgroundColor: "red",
  },
  containerButtonNone: {
    display: "none",
  },
  fileUploadLine: {
    display: "flex",
  },
  validAttFile: {},
  invalidAttFile: {
    color: "red",
    textDecoration: "underline",
  },
  remFileUpload: {
    selectors: {
      ":hover": {
        cursor: "pointer",
      },
    },
  },
});

const AttachementAddV4: FunctionComponent<IAttachementAddV4Props> = ({
  fileUploaddHandler,
  validFiles,
  setValidFiles,
  unsupportedFiles,
  setUnsupportedFiles,
  enableUploadSingleFile,
  onlyDD,
  dropdownCleanup,
}: IAttachementAddV4Props) => {
  // const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  // const [validFiles, setValidFiles] = useState<File[]>([]);
  // const [unsupportedFiles, setUnsupportedFiles] = useState<File[]>([]);
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>();

  React.useEffect(() => {
    fileInputRef.current.value = null;
  }, []);

  React.useEffect(() => {
    log.info(limaLogTag.AttachementAddV4, `UEF: dropdownCleanup`, dropdownCleanup);
    if (fileInputRef !== undefined) fileInputRef.current.value = null;
  }, [dropdownCleanup]);

  const fileInputClicked = () => {
    log.info(limaLogTag.AttachementAddV4, `fileInputClicked:`, fileInputRef.current.files);
    if (fileInputRef !== undefined) fileInputRef.current.click();
  };

  const filesSelected = () => {
    log.info(limaLogTag.AttachementAddV4, `filesSelected:`, fileInputRef.current.files);
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files);
    }
  };

  const dragOver = (e: React.DragEvent<HTMLDivElement>) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    e.preventDefault();
  };

  const dragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    e.preventDefault();
  };

  const dragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const fileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    // window.console.log(files);
    log.info(limaLogTag.AttachementAddV4, `fileDrop:`, files);
    if (files.length) {
      handleFiles(files);
    }
  };

  const handleFiles = (files: FileList) => {
    let validatedCorrFiles: IAttachmentFileWithInfo[] = [];
    let validatedFailFiles: IAttachmentFileWithInfo[] = [];
    for (let i = 0; i < files.length; i++) {
      log.info(limaLogTag.AttachementAdd, `handleFiles: processing file id ${i} name ${files[i].name}`);
      if (validateFile(files[i])) {
        // setSelectedFiles((prevArray: File[]) => [...prevArray, files[i]]);
        log.info(limaLogTag.AttachementAdd, `handleFiles: valid file id ${i} name ${files[i].name}`);
        validatedCorrFiles.push({ file: files[i], filedescription: "", filename: files[i].name });
      } else {
        log.info(limaLogTag.AttachementAdd, `handleFiles: unsupported file id ${i} name ${files[i].name}`);
        validatedFailFiles.push({ file: files[i], filedescription: "", filename: files[i].name });
      }
    }
    //setValidFiles((prevArray: IAttachmentFileWithInfo[]) => [...prevArray, ...validatedCorrFiles]);
    setValidFiles(validatedCorrFiles);
    setUnsupportedFiles((prevArray: IAttachmentFileWithInfo[]) => [...prevArray, ...validatedFailFiles]);
    fileInputRef.current.files = null;
  };

  const removeFileFromList = (
    fileToRemove: IAttachmentFileWithInfo,
    filesList: IAttachmentFileWithInfo[],
    setAction: (list: IAttachmentFileWithInfo[]) => void
  ) => {
    log.info(limaLogTag.AttachementAdd, "removeFileFromList file type", fileToRemove.file.name);
    filesList = filesList.filter((item) => item !== fileToRemove);
    log.info(limaLogTag.AttachementAdd, "removeFileFromList list", filesList);
    setAction(filesList);
  };

  const onRenderCell = (item: IAttachmentFileWithInfo, index: number | undefined): JSX.Element => {
    return (
      <AttachmentDDFileItemV4
        key={"f_" + String(index)}
        file={item}
        removeCallBack={() => removeFileFromList(item, validFiles, setValidFiles)}
        uploadCallBack={() => onUploadHandler(item, validFiles, setValidFiles)}
        enableUploadSingleFile={enableUploadSingleFile}
        onUpdateNameDesc={(fileName: string, fileDesc: string) => {
          item.filename = fileName;
          item.filedescription = fileDesc;
        }}
      />
    );
  };

  const onRenderCellUnsuported = (item: IAttachmentFileWithInfo, index: number | undefined): JSX.Element => {
    return (
      <AttachmentDDFileItemV4
        key={"f_" + String(index)}
        file={item}
        removeCallBack={() => removeFileFromList(item, unsupportedFiles, setUnsupportedFiles)}
        enableUploadSingleFile={false}
      />
    );
  };

  const onUploadHandler = (
    item: IAttachmentFileWithInfo,
    filesList: IAttachmentFileWithInfo[],
    setAction: (list: IAttachmentFileWithInfo[]) => void
  ) => {
    fileUploaddHandler(item.file, item.filename, item.filedescription);
    removeFileFromList(item, filesList, setAction);
  };

  return (
    <Stack>
      <Stack.Item>
        {/* <Separator>{t("tptasks:taskedit.taskitem2.attachment.insertSeparatorLbl")}</Separator> */}
        <>{`files: ${fileInputRef !== undefined && fileInputRef.current?.files?.length}`}</>
        <input ref={fileInputRef} type="file" className={classNames.fileInput} multiple onChange={filesSelected} />
        <div className={classNames.container}>
          <div
            className={classNames.dropContainer}
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={fileDrop}
            onClick={fileInputClicked}
          >
            {t("tptasks:taskedit.taskitem2.attachment.drofilesLbl")}
          </div>
        </div>
      </Stack.Item>
      {(onlyDD === undefined || onlyDD === false) && validFiles && validFiles.length > 0 && (
        <Stack.Item>
          <Separator>{t("tptasks:taskedit.taskitem2.attachment.validFilesLbl")}</Separator>
          <FocusZone direction={FocusZoneDirection.vertical}>
            <List items={validFiles} onRenderCell={onRenderCell} />
          </FocusZone>
        </Stack.Item>
      )}
      {(onlyDD === undefined || onlyDD === false) && unsupportedFiles && unsupportedFiles.length > 0 && (
        <Stack.Item>
          <Separator>{t("tptasks:taskedit.taskitem2.attachment.unsupportedFilesLbl")}</Separator>
          <FocusZone direction={FocusZoneDirection.vertical}>
            <List items={unsupportedFiles} onRenderCell={onRenderCellUnsuported} />
          </FocusZone>
          <>
            {unsupportedFiles.map((item: IAttachmentFileWithInfo, index: number) => (
              <AttachmentDDFileItemV4
                key={"f_" + String(index)}
                file={item}
                removeCallBack={() => removeFileFromList(item, unsupportedFiles, setUnsupportedFiles)}
                enableUploadSingleFile={enableUploadSingleFile}
              />
            ))}
          </>
        </Stack.Item>
      )}
    </Stack>
  );
};

export default AttachementAddV4;

//---------------
// FILE PROCESSING
//----------------

const validateFile = (file: File): boolean => {
  if (validTypes.indexOf(file.type) === -1) {
    log.warn(limaLogTag.AttachementAdd, "Unsuported file type", file.type);
    return false;
  }
  return true;
};

// const fileType = (fileName: string): string => {
//   return fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length) || fileName;
// };

// const fileSize = (size: number): string => {
//   if (size === 0) return "0 Bytes";
//   const k = 1024;
//   const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
//   const i = Math.floor(Math.log(size) / Math.log(k));
//   return String(parseFloat((size / Math.pow(k, i)).toFixed(2))) + " " + sizes[i];
// };
