import { FC } from "react";
import { Controller } from "react-hook-form";
import { DatePicker, IDatePicker, Label, Stack, Toggle } from "@fluentui/react";
import * as React from "react";
import { HookFormProps } from "./HookFormProps";

type LimaControledDatePickerFieldV4Type = {
  limaLabel?: string;
  showToggle: boolean;
  toggleChacked?: boolean;
};

export const LimaControledDatePickerFieldV4: FC<HookFormProps & IDatePicker & LimaControledDatePickerFieldV4Type> = (
  props: HookFormProps & IDatePicker & LimaControledDatePickerFieldV4Type
) => {
  const [showDate, setShowDate] = React.useState<boolean>(props.toggleChacked ? props.toggleChacked : true);

  return (
    <Stack horizontal>
      {props.limaLabel && (
        <Stack.Item style={{ width: "30%" }}>
          <Label>{props.limaLabel} </Label>
        </Stack.Item>
      )}
      {props.showToggle && (
        <Stack.Item style={{ width: "30%" }}>
          <Toggle
            key="showDueDateSwtch"
            // onText="switch to set due date"
            // offText=""
            checked={showDate}
            onChange={(_ev, state) => setShowDate(state)}
            role="checkbox"
          />
        </Stack.Item>
      )}
      <Stack.Item style={{ width: "40%" }}>
        {showDate && (
          <Controller
            name={props.name}
            control={props.control}
            rules={props.rules}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            defaultValue={props.defaultValue || ""}
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            render={({ field: { onChange, onBlur, name: _fieldName, value }, fieldState: { error } }) => (
              // render={({ field }) => (
              <>
                {/* <>AAA: {String(value)}</> */}
                <DatePicker
                  // DatePicker uses English strings by default. For localized apps, you must override this prop.
                  {...props}
                  onSelectDate={(date) => {
                    console.log("Date change to ", date);
                    onChange(date);
                    return date;
                  }}
                  onChange={onChange}
                  onBlur={onBlur}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  value={value}
                />
                {error && error.type === "required" && (
                  //if you want to show an error message
                  <span>{error.message} - required</span>
                )}
                {error && error.type === "validate" && (
                  //if you want to show an error message
                  <span>{error.message} - Validate</span>
                )}
              </>
            )}
          />
        )}
      </Stack.Item>
    </Stack>
  );
};
