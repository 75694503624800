import { Toggle } from "@fluentui/react";
import { log } from "missionlog";
import { Moment } from "moment";
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { urlHistoryGetParaHistoryTasks, WORDITEMTYPE } from "../../../../../api/apilinks";
import { httpGetAuth, HttpResponse } from "../../../../../api/httpAPI";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import { LimaHideableSection } from "../../../../../limaComponents/LimaHideableSection";
import { LimaLoadingComponnet } from "../../../../../limaComponents/LimaLoadingComponnet";
import { LimaNotice } from "../../../../../limaComponents/LimaNotice";
import limaLoadeStore from "../../../../../store/limaLoaderStore";
import limaStore from "../../../../../store/limaStore";
import { User2Task_withActaXcopyParasCommentsTasksMessagesAttachements } from "../../../../../types/User2Task_withActaXcopy";
import { SelectedInWord } from "../../../../../types/wordObjects";
import HistParaItemParaTaskRow2 from "./HistParaItemParaTaskRow2";

type HistParaItemTaskItemsProps = {
  dateFrom: Moment;
  dateTo: Moment;
  selectedItem: SelectedInWord;
};
/**
 * Show tasks linked to Para
 * !!! we dont have .task in response for User2Task_withActaXcopy[] use task_withActaXcopy
 *
 */

const HistParaItemTaskItems: FunctionComponent<HistParaItemTaskItemsProps> = ({
  dateFrom,
  dateTo,
  selectedItem,
}: HistParaItemTaskItemsProps) => {
  const { t } = useTranslation(["tpshow"]);
  const [parHistTasks, setParHistTasks] = useState<User2Task_withActaXcopyParasCommentsTasksMessagesAttachements[]>();
  const [showHighLight, setShowHighLight] = useState<boolean>(false);

  const { isLoading, isFetching, error, refetch } = useQuery<
    User2Task_withActaXcopyParasCommentsTasksMessagesAttachements[],
    Error
  >(
    [
      "tasksForPara",
      {
        itemid: (selectedItem && selectedItem.id) || null,
        itemtype: (selectedItem && selectedItem.type) || null,
        userid: limaStore.userId || null,
        datefrom: (dateFrom && dateFrom.toISOString()) || null,
        dateto: (dateTo && dateTo.toISOString()) || null,
      },
    ],
    () =>
      getTasksForPara(
        (selectedItem && selectedItem.id) || null,
        (selectedItem && selectedItem.type) || null,
        // limaStore.userId || null,
        (dateFrom && dateFrom.toISOString()) || null,
        (dateTo && dateTo.toISOString()) || null
      ),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onError: (err) => {
        log.error(limaLogTag.HistParaItemParaTaskItems, "useQuery get attachements ", err);
      },
      onSuccess: (data: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements[]) => {
        log.info(limaLogTag.HistParaItemParaTaskItems, "useQuery success ", data);
        setParHistTasks(data);
      },
    }
  );

  useEffect(() => {
    // queryFinish && setQueryFinish(false);
    void refetch();
  }, [dateFrom, dateTo, selectedItem]);

  if (isLoading || isFetching) {
    log.info(limaLogTag.HistParaItemParaTaskItems, "searching for Tasks");
    return <LimaHideableSection name={`${t("tpshow:history.tasksLbl")}`}>{LimaLoadingComponnet()}</LimaHideableSection>;
  }
  if (error) {
    return (
      <LimaHideableSection name={`${t("tpshow:history.tasksLbl")}`}>
        <LimaNotice>{t("tpshow:history.errorloadingLbl")}</LimaNotice>
      </LimaHideableSection>
    );
  }

  let sectionName = `${t("tpshow:history.tasksLbl")} `;
  if (parHistTasks && parHistTasks.length > 0) sectionName += `[${parHistTasks.length}]`;
  //`${t("tpshow:history.tasksLbl")} [${parHistTasks && parHistTasks.length}]`

  return (
    <LimaHideableSection name={sectionName}>
      {parHistTasks && parHistTasks.length > 0 ? (
        <Toggle
          label={t("tpshow:history.tasks.highlightLbl")}
          inlineLabel
          checked={showHighLight}
          onClick={() => {
            setShowHighLight(!showHighLight);
          }}
        />
      ) : (
        <></>
      )}
      {parHistTasks &&
        parHistTasks.map(
          (paraHistoryItem: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements, index: number) => (
            <HistParaItemParaTaskRow2
              key={paraHistoryItem._key}
              itemKey={paraHistoryItem._key}
              index={index}
              showHighlight={showHighLight}
              paraHistoryTaskRow={paraHistoryItem.task_withActaXcopyParasCommentsTasksMessagesAttachements}
            />
          )
        )}
    </LimaHideableSection>
  );
};
export default HistParaItemTaskItems;

//----------------------
//   REACT QUERY
//----------------------

async function getTasksForPara(
  itemid: string,
  _itemtype: WORDITEMTYPE,
  // userid: string,
  datefrom: string,
  dateto: string
) {
  // const [, { itemid, userid, datefrom, dateto }] = params.queryKey;

  if (itemid === null) {
    log.info(limaLogTag.HistParaItemParaText, "getTasksForPara: itemid is null");
    return null;
  }
  if (datefrom === null || dateto === null) {
    log.info(limaLogTag.HistParaItemParaText, "getTasksForPara: datefrom is null or dateto is null");
    return null;
  }
  const loaderID = limaLoadeStore.add("Getting getTasksForPara from DB");
  let response: HttpResponse<User2Task_withActaXcopyParasCommentsTasksMessagesAttachements[]>;
  try {
    response = await httpGetAuth<User2Task_withActaXcopyParasCommentsTasksMessagesAttachements[]>(
      urlHistoryGetParaHistoryTasks(itemid, true, true),
      loaderID.ac
    );
    log.info(limaLogTag.HistParaItemParaText, "getTasksForPara: asketo to get something", response);
    if (response.parsedBody !== undefined) {
      log.info(limaLogTag.HistParaItemParaText, "getTasksForPara: parsedBody is not null", response.parsedBody);
      //   const userListCopies = response.parsedBody;
      return response.parsedBody;
    } else {
      log.error(limaLogTag.HistParaItemParaText, "getTasksForPara Error no data", response);
      // throw new Error("...No data...");
      return null;
    }
  } catch (response) {
    log.error(limaLogTag.HistParaItemParaText, "getTasksForPara Error call get data", response);
    // throw new Error("Problem geting data");
    return null;
  } finally {
    //this.setLoadingData(false);
    limaLoadeStore.remove(loaderID.k);
  }
}
