import { action, computed, makeObservable, observable } from "mobx";
import limaStore, { OPENEDFILETYPE_ENUM } from "./limaStore";
import { log } from "missionlog";
import { compareXcopy2XcopyByActaKey, insertDoc } from "../limaCommon/limaInsertDoc";
import { openDialogAsIframe_taskhistory } from "../limaCommon/limaDialogs";
// import { updateTaskRespOK } from "../api/schema/taskUpdateResponse";
import { urlDeleteTask, urlTasksGetList } from "../api/apilinks";
import { urlActaInsertDoc } from "../api/apilinks";
import { Task, TaskData, TASKSTATE } from "../types/Task";
import { httpDeleteDeleteAuth, httpGet, HttpResponse } from "../api/httpAPI";
import limaLoadeStore, { LoaderItemEnd } from "./limaLoaderStore";
import { LimaDocTypeENUM } from "../types/docxData";
import { User2Task_withActaXcopy, User2Task_withActaXcopy_getNull } from "../types/User2Task_withActaXcopy";
import { limaLogTag } from "../limaCommon/limaLog";
import { User_getNull } from "../types/User";
import { getActaXcopyFile, storeToXcopy } from "../limaCommon/limaXCopyFile";
import { Task_withActaXcopy, Task_withActaXcopy_compare } from "../types/Task_withActaXcopy";
import { TASKFILTERITEM_ENUM, TaskListFilterItem } from "../taskpane/uiv2/Tasks/TaskListV4";
import { updateTaskData } from "../api/calls/updateTaskData";

//TODO: CHANGE BACK TO TYPE import { EditTaskData, EditTaskDataDef } from "../types/tasks";

export enum TASK_LINKED2ME_ENUM {
  NOTONLYMY = "NOTONLYMY",
  IRESPONSIBLEONLY = "IRESPONSIBLEONLY",
  ICREATEONLY = "ICREATEONLY",
}

class LimaTasksStore {
  fullTasksList: User2Task_withActaXcopy[] | null = null;
  tasksList: User2Task_withActaXcopy[] | null = null;
  fullTasksListCount = 0;
  filterString = "";
  filterClosed = true;
  filterLinked = false;
  filterMy: TASK_LINKED2ME_ENUM = TASK_LINKED2ME_ENUM.NOTONLYMY;
  lastDBReadDate: string | null = null;

  /**
   * Full task object - used for editing as temporary task
   */
  editTask: User2Task_withActaXcopy | null = null;
  refresh = false;
  taskDueDate: Date | null = null;
  //editTaskListUsers: any[] = [];
  /**
   * Task data
   */
  editCurrentTaskData: TaskData | null = null;
  taskDataDateEnable = false;

  constructor() {
    makeObservable(this, {
      refresh: observable,
      tasksList: observable,
      fullTasksList: observable,
      editTask: observable,
      taskDueDate: observable,
      fullTasksListCount: observable,
      taskDataDateEnable: observable,
      editCurrentTaskData: observable,
      lastDBReadDate: observable,

      setTasksList: action,
      setFullTasksList: action,
      setFullTaskListCount: action,

      setEditTask: action,
      setTaskId: action,
      setTaskName: action,
      setTaskDescription: action,
      setTaskState: action,
      setResponsible: action,
      setDueDate: action,
      setTaskData: action,
      setTaskCIssuedBy: action,
      setTaskCTimeStamp: action,
      setTaskDataDate: action,
      setEditCurrentTaskData: action,
      setTaskDataDateEnable: action,
      setFilterLinked: action,

      getEditTaskAttchCount: computed,
      getEditLinkedParasCount: computed,
      // getBodyToStore: action,
    });
  }
  /**
   * on logout clear
   */
  onLogoutClear = (): void => {
    this.setFullTasksList(null);
    this.setFullTaskListCount(0);
    this.setTasksList(null);
    this.setTasksList(null);
    this.setTasksList(null);
  };

  /* ------------- editTaskData --------------- */
  getTaskByKey(taskKey: string): User2Task_withActaXcopy {
    log.info(limaLogTag.LimaTasksStore, `getTaskByKey: key [${taskKey}]`);
    if (this.tasksList !== null) {
      const taskItem = this.tasksList.filter(
        (task: User2Task_withActaXcopy) => task.task_withActaXcopy._key === taskKey
      )[0];
      log.info(limaLogTag.LimaTasksStore, `getTaskByKey: key [${taskKey}]`, taskItem);
      return taskItem;
    }
    return null;
  }

  setEditTask(editTask: User2Task_withActaXcopy | null) {
    log.info(limaLogTag.LimaTasksStore, "setEditTask: update before", editTask);
    if (editTask !== undefined && editTask !== null && editTask.task_withActaXcopy !== null) {
      log.info(limaLogTag.LimaTasksStore, "setEditTask: update", JSON.stringify(editTask));
      this.editTask = editTask;
    }
  }

  setTaskId(key: string) {
    if (this.editTask === null) this.setEditTask(User2Task_withActaXcopy_getNull());
    this.editTask.task_withActaXcopy._key = key;
  }
  setTaskName(taskName: string) {
    if (this.editTask === null) this.setEditTask(User2Task_withActaXcopy_getNull());
    this.editTask.task_withActaXcopy.taskName = taskName;
  }
  setTaskCIssuedBy(cIssuedBy: string) {
    if (this.editTask == null) this.setEditTask(User2Task_withActaXcopy_getNull());
    this.editTask.cIssuedBy = cIssuedBy;
  }
  setTaskCTimeStamp(cTimeStamp: string) {
    if (this.editTask == null) this.setEditTask(User2Task_withActaXcopy_getNull());
    this.editTask.cTimeStamp = cTimeStamp;
  }
  setTaskDescription(taskDescription: string) {
    if (this.editTask == null) this.setEditTask(User2Task_withActaXcopy_getNull());
    this.editTask.task_withActaXcopy.taskDescription = taskDescription;
  }
  setTaskState(taskState: string) {
    this.editTask.task_withActaXcopy.taskState = taskState;
  }
  setResponsible(responsible: string) {
    if (this.editTask == null) this.setEditTask(User2Task_withActaXcopy_getNull());
    if (this.editTask.task_withActaXcopy.taskAssignedTo == null)
      this.editTask.task_withActaXcopy.taskAssignedTo = User_getNull();
    this.editTask.task_withActaXcopy.taskAssignedTo._key = responsible;
  }

  setDueDate(dueDate: string) {
    if (this.editTask == null) this.setEditTask(User2Task_withActaXcopy_getNull());
    this.editTask.task_withActaXcopy.taskDueDate = dueDate;
  }

  setTaskData(taskData: TaskData | null) {
    //this.editTaskData.taskData = taskData;
    log.info(limaLogTag.LimaTasksStore, `setTaskData: setting task data`, taskData);
    this.setEditTask({
      ...this.editTask,
      task_withActaXcopy: { ...this.editTask.task_withActaXcopy, taskData: taskData },
    });
  }

  /* ------------- taskDataDate --------------- */
  setTaskDataDate(dataDueDate: Date | null) {
    if (this.editTask == null) this.setEditTask(User2Task_withActaXcopy_getNull());
    this.taskDueDate = dataDueDate;
  }

  /* ------------- taskDataDAteEnable --------------- */
  setTaskDataDateEnable(state: boolean) {
    if (this.editTask == null) this.setEditTask(User2Task_withActaXcopy_getNull());
    this.taskDataDateEnable = state;
  }

  async removeTaskFromList(key: string) {
    if (key === "") {
      log.warn(limaLogTag.LimaTasksStore, `removeTaskFromList: missing key for task to remove task[${key}]`);
    }
    const loaderID = limaLoadeStore.add(`Call remove task visibility`);
    log.info(limaLogTag.LimaTasksStore, `removeTaskFromList: for '${limaStore.userId}' task id[${key}]`);
    let dataReply: HttpResponse<Task>; //TODO: change ANY
    try {
      // dataReply = await httpDelete(urlDeleteTask(key), { userId: limaStore.userId }, loaderID.ac);
      // dataReply = await httpDeleteAuth(urlDeleteTask(key), {}, loaderID.ac);
      dataReply = await httpDeleteDeleteAuth(urlDeleteTask(key), loaderID.ac);
      if (dataReply.status == 200) {
        log.info(limaLogTag.LimaTasksStore, "removeTaskFromList: get response", dataReply);
        this.setFullTasksList(this.fullTasksList?.filter((item) => item.task_withActaXcopy._key !== key));
        this.setFullTaskListCount(this.fullTasksList?.length);
        this.setTasksList(this.tasksList.filter((item) => item.task_withActaXcopy._key !== key));
      }
    } catch (err) {
      log.error(limaLogTag.LimaTasksStore, "removeTaskFromList: get erro response from call", err);
    } finally {
      limaLoadeStore.remove(loaderID.k);
    }
  }

  /* -------------- tasksList ---------------- */
  setTasksList(tasks: User2Task_withActaXcopy[]) {
    this.tasksList = tasks;
  }

  /* ---------------- fullTasksList ---------------- */
  setFullTasksList(tasks: User2Task_withActaXcopy[] | null) {
    this.fullTasksList = tasks;
    if (tasks !== null) {
      this.setFullTaskListCount(tasks.length);
    }
  }

  setFullTaskListCount(count: number) {
    this.fullTasksListCount = count;
  }

  setEditCurrentTaskData(newCurTaskData: TaskData | null) {
    this.editCurrentTaskData = newCurTaskData;
    // this.editTaskData.taskData = JSON.stringify(this.editCurrentTaskData);
    if (newCurTaskData !== undefined && newCurTaskData !== null) {
      this.editTask.task_withActaXcopy.taskData = this.editCurrentTaskData;
      log.info(limaLogTag.LimaTasksStore, "setEditCurrentTaskData: ", JSON.stringify(newCurTaskData));
    }
  }

  onClear = () => {
    log.info(limaLogTag.LimaTasksStore, "onClear: clearing current task data");
    this.setEditTask(User2Task_withActaXcopy_getNull());
  };

  onShowTaskHistory = () => {
    log.info(limaLogTag.LimaTasksStore, "onClickHandler: clicked", this.editTask && this.editTask._key);
    localStorage.setItem("selectedTask", (this.editTask && this.editTask._key) || ""); //TODO: Change
    openDialogAsIframe_taskhistory();
  };

  onComapreActaVersion = async () => {
    log.info(limaLogTag.LimaTasksStore, "compare");

    // let taskDataJSON: taskItemData = map2TaskItem(this.editTaskData.taskData);
    const taskDataJSON: TaskData = this.editTask.task_withActaXcopy.taskData;
    if (
      taskDataJSON.actaKey == null ||
      taskDataJSON.actaKey == "" ||
      taskDataJSON.xcopyKey == null ||
      taskDataJSON.xcopyKey == ""
    ) {
      log.info(limaLogTag.LimaTasksStore, "missing key id");
      return;
    }
    //this.setProgress(true);
    const loaderID = limaLoadeStore.add(`Call to compare acta and xcopy`);
    // await compareDocx_xcopy(null, taskDataJSON.actaKey, taskDataJSON.xcopyKey, limaStore.userId);
    await compareXcopy2XcopyByActaKey(taskDataJSON.actaKey, taskDataJSON.xcopyKey, limaStore.userId, loaderID);
    //this.setProgress(false);
    limaLoadeStore.remove(loaderID.k);
  };

  /**
   * Replace Acta xcopy to document for current task
   *
   */
  onGetActaXcopyFile = async () => {
    //Obosliite not zet refactored
    if (
      this.editTask.task_withActaXcopy.linkedXcopy._key == null &&
      this.editTask.task_withActaXcopy.linkedXcopy._key === ""
    ) {
      log.warn(limaLogTag.LimaTasksStore, "onGetActaXcopyFile: we dont have xcopy in taskData ", this.editTask);
    }
    if (
      this.editTask.task_withActaXcopy.taskData.actaKey == null &&
      this.editTask.task_withActaXcopy.taskData.actaKey === ""
    ) {
      log.warn(limaLogTag.LimaTasksStore, "onGetActaXcopyFile: we dont have actaid in taskData ", this.editTask);
    }

    //let taskDataJSON: taskItemData = map2TaskItem(this.editTaskData.taskData);
    const taskDataJSON: TaskData = this.editTask.task_withActaXcopy.taskData;
    if (taskDataJSON.xcopyKey == null && taskDataJSON.xcopyKey === "") {
      log.warn(limaLogTag.LimaTasksStore, "onGetActaVersionFile: we dont have xcopy in taskDataJSON ", taskDataJSON);
    }
    if (taskDataJSON.actaKey == null && taskDataJSON.actaKey === "") {
      log.warn(limaLogTag.LimaTasksStore, "onGetActaVersionFile: we dont have actaid in taskDataJSON ", taskDataJSON);
    }

    log.info(limaLogTag.LimaTasksStore, "getting xcopy for xcopy", taskDataJSON, this.editTask);
    await getActaXcopyFile(taskDataJSON.actaKey, taskDataJSON.xcopyKey);
  };

  onGetActaFile = async () => {
    if (
      this.editTask.task_withActaXcopy.taskData.actaKey != null &&
      this.editTask.task_withActaXcopy.taskData.actaKey != ""
    ) {
      // limaStore.setActaId(this.editTask.task_withActaXcopy.taskData.actaKey);
      limaStore.setActa(this.editTask.task_withActaXcopy.taskData.actaKey);
      //limaStore.setActaName(this.editTaskData.actaName);
      localStorage.setItem("actaId", limaStore.actaId);
      log.info(limaLogTag.LimaTasksStore, "onGetActaFile: getting acta for acta", limaStore.actaId);
      // this.setProgress(true);
      const loaderID = limaLoadeStore.add(`Call get Acta from server`);
      limaStore.setDocxIsLoading(true);
      await insertDoc(
        limaStore.actaId,
        urlActaInsertDoc(limaStore.actaId, limaStore.userId),
        limaStore.actaName,
        LimaDocTypeENUM.ACTA,
        () => {
          limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
          limaStore.setDocxIsLoading(false);
        },
        () => {
          limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
          limaStore.setDocxIsLoading(false);
        },
        loaderID
      );
      //limaStore.setActiveOpenType(openedfileType.ACTA)
      // limaStore.toActaDoc();
      limaStore.setActiveOpenType(OPENEDFILETYPE_ENUM.ACTA);
      //this.setProgress(false);
    } else {
      log.warn(limaLogTag.LimaTasksStore, "onGetActaFile: looks like missing actaId", limaStore.actaId);
    }
  };

  onUpdate = async () => {
    if (
      this.editTask === null ||
      this.editTask.task_withActaXcopy === null ||
      this.editTask.task_withActaXcopy._key === null ||
      this.editTask.task_withActaXcopy._key === ""
    ) {
      log.warn(limaLogTag.LimaTasksStore, "onUpdate: no task id ", this.editTask);
      return;
    }
    await updateTaskData(this.editTask.task_withActaXcopy, limaStore.userId);
    // const loaderID = limaLoadeStore.add(`Update task`);
    // this.setProgress(true);
    // let dataReply: HttpResponse<updateTaskRespOK>; //TODO: change ANY
    // try {
    //   dataReply = await httpPost(
    //     urlUpdateTask(this.editTask.task_withActaXcopy._key, limaStore.userId),
    //     this.editTask.task_withActaXcopy,
    //     loaderID.ac
    //   );
    //   if ("parsedBody" in dataReply) {
    //     log.info(limaLogTag.LimaTasksStore, "onUpdate:looks like we update it");
    //     //Update task
    //   } else {
    //     log.warn(limaLogTag.LimaTasksStore, "onUpdate: no ParseBody ", dataReply);
    //   }
    // } catch (error) {
    //   log.error(limaLogTag.LimaTasksStore, "onUpdate: Error", error);
    //   //TODO: error handling
    // } finally {
    //   // this.setProgress(false);
    //   limaLoadeStore.remove(loaderID.k);
    // }
  };

  onStoreXcopy = () => {
    if (
      this.editTask.task_withActaXcopy.taskData.actaKey != null &&
      this.editTask.task_withActaXcopy.taskData.actaKey != ""
    ) {
      if (
        this.editTask.task_withActaXcopy.taskData.actaKey != null &&
        this.editTask.task_withActaXcopy.taskData.actaKey != ""
      ) {
        // let loaderID = limaLoadeStore.add(`Storing xcopy to server`);

        log.info(
          limaLogTag.LimaTasksStore,
          `storing xcopy for xcopy [${limaStore.xcopyId}/${this.editTask.task_withActaXcopy.taskData.xcopyKey}]`
        );
        void storeToXcopy(
          this.editTask.task_withActaXcopy.taskData.actaKey,
          this.editTask.task_withActaXcopy.taskData.xcopyKey
        ).then(() => {
          limaStore.xcopyId = this.editTask.task_withActaXcopy.taskData.xcopyKey;
        });
      }
    }
  };

  /**
   * Read all tasks from DB and set them into FullTasksList,taskList and fulltasklistCOunt
   */
  getTasksListFromDB = async () => {
    log.info(limaLogTag.LimaTasksStore, "getTasksList: get tasks list call start");
    let tasksListData: HttpResponse<User2Task_withActaXcopy[]>;

    const loaderID = limaLoadeStore.add(`Getting Tasks from server`);
    try {
      tasksListData = await httpGet<User2Task_withActaXcopy[]>(urlTasksGetList("", limaStore.userId, ""), loaderID.ac);

      log.info(limaLogTag.LimaTasksStore, "getTasksList: I get Data from call:", tasksListData);
      if (tasksListData.parsedBody !== undefined) {
        this.setFullTasksList(tasksListData.parsedBody);
        this.setTasksList(tasksListData.parsedBody);
        this.setFullTaskListCount(tasksListData.parsedBody.length);
      }
      log.info(limaLogTag.LimaTasksStore, "getTasksList: now have tasksList ", this.tasksList);
      log.info(limaLogTag.LimaTasksStore, "getTasksList: now have fullTasksList: ", this.fullTasksList);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
    } catch (error) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      log.error(limaLogTag.LimaTasksStore, "Error call get User", error);
    } finally {
      //this.setProgress(false);
    }
  };

  //onFilterChanged = (_: any, text: string): void => {
  onFilterChanged = (_?: any, text?: string): void => {
    //TODO: move to lima Store
    if (text !== undefined) this.filterString = text;
    //else text = this.filterString;
    if (!this.fullTasksList || this.fullTasksList === null) {
      log.warn(limaLogTag.LimaTasksStore, "onFilterChanged: call onFilterChange on null taskList");
      return;
    }
    if (this.fullTasksList && this.fullTasksList.length === 0) {
      log.warn(limaLogTag.LimaTasksStore, "onFilterChanged: filter nothign - no tasks in full list ");
      return;
    }
    const dontShowClosed = this.filterClosed;
    const filterLinked = this.filterLinked;
    const filterMy = this.filterMy;
    const filterText = this.filterString;

    log.info(limaLogTag.LimaTasksStore, `onFilterChanged: before changed [${this.tasksList && this.tasksList.length}]`);

    this.setTasksList(
      this.fullTasksList.filter(function (item: User2Task_withActaXcopy) {
        log.info(
          limaLogTag.LimaTasksStore,
          "onFilterChanged: test filterin ",
          item,
          dontShowClosed,
          filterLinked,
          filterMy,
          filterText
        );

        //Filter nulls
        if (
          item === null ||
          item.task_withActaXcopy.taskName === undefined ||
          item.task_withActaXcopy.taskName === null ||
          item.task_withActaXcopy.taskName === ""
        ) {
          return false;
        }
        //Filter out closed
        else if (
          dontShowClosed === true &&
          item.task_withActaXcopy.taskState !== undefined &&
          item.task_withActaXcopy.taskState !== null &&
          item.task_withActaXcopy.taskState.toLocaleLowerCase() === "close"
        ) {
          return false;
        }
        //Filter out not linked to document
        else if (
          filterLinked === true &&
          item.task_withActaXcopy.taskData &&
          (item.task_withActaXcopy.taskData.actaKey !== limaStore.actaId ||
            item.task_withActaXcopy.taskData.xcopyKey !== limaStore.xcopyId)
        )
          return false;
        //Filter out not my
        else if (
          filterMy === TASK_LINKED2ME_ENUM.IRESPONSIBLEONLY &&
          (item.task_withActaXcopy.taskAssignedTo == null ||
            item.task_withActaXcopy.taskAssignedTo._key !== limaStore.userId)
        )
          return false;
        else if (
          filterMy === TASK_LINKED2ME_ENUM.ICREATEONLY &&
          (item.task_withActaXcopy.cIssuedBy == null || item.task_withActaXcopy.cIssuedBy !== limaStore.userId)
        )
          return false;
        //Filter out not linked to document
        else if (
          filterText !== "" &&
          !(item.task_withActaXcopy.taskName.toLowerCase().indexOf(filterText.toLowerCase()) >= 0)
        )
          return false;
        return true;
      })
    );
    log.info(limaLogTag.LimaTasksStore, `onFilterChanged: after changed [${this.tasksList.length}]`);
  };

  /**
   * manualz update single task data in full list
   * change task idenfied by task_withActaXcopy._key on FullTaskLIst and TaskList
   *
   * @param updatedTask
   */
  updateSingleTask(updatedTask: Task_withActaXcopy) {
    this.setFullTasksList(
      this.fullTasksList.map((item: User2Task_withActaXcopy) => {
        if (item.task_withActaXcopy._key == updatedTask._key) item.task_withActaXcopy = updatedTask;
        return item;
      })
    );

    this.setTasksList(
      this.tasksList.map((item: User2Task_withActaXcopy) => {
        if (item.task_withActaXcopy._key == updatedTask._key) item.task_withActaXcopy = updatedTask;
        return item;
      })
    );
  }

  // addSingleTaskToFull(taskToAdd: Task_withActaXcopy) {
  //   this.setFullTasksList({ ...this.fullTasksList });
  // }

  setFilterLinked(linkedToActaOnly: boolean) {
    this.filterLinked = linkedToActaOnly;
    this.onFilterChanged();
  }

  setFilterMy(onlyMy: TASK_LINKED2ME_ENUM) {
    this.filterMy = onlyMy;
    this.onFilterChanged();
  }

  /**
   * new filtering on task list
   */
  getFilterdTasks = (usedFilters: TaskListFilterItem[], textFilter: string): User2Task_withActaXcopy[] => {
    if (this.fullTasksList === null) {
      return [];
    }
    let filterdItems = this.fullTasksList;

    usedFilters &&
      usedFilters.forEach((filterItem: TaskListFilterItem) => {
        log.debug(limaLogTag.LimaTasksStore, "getFilterdTasks: now filtering on ", filterItem);

        filterdItems = filterdItems.filter((item: User2Task_withActaXcopy) => {
          log.debug(limaLogTag.LimaTasksStore, "getFilterdTasks: now testing  item ", item);
          //FILTER CREATOR
          if (filterItem.taskItemFilterIdent === TASKFILTERITEM_ENUM.FILTER_CREATOR) {
            if (
              filterItem.taksItemFilterValue === TASK_LINKED2ME_ENUM.ICREATEONLY &&
              item.cIssuedBy === limaStore.userId
            )
              return true;
            if (
              filterItem.taksItemFilterValue === TASK_LINKED2ME_ENUM.IRESPONSIBLEONLY &&
              item.task_withActaXcopy?.taskAssignedTo?._key === limaStore.userId
            )
              return true;
            if (filterItem.taksItemFilterValue === TASK_LINKED2ME_ENUM.NOTONLYMY) return true;
            return false;
          }

          //FILTER LINKED ITEMS
          if (filterItem.taskItemFilterIdent === TASKFILTERITEM_ENUM.FILTER_LINKTOACTIVEDOC) {
            if (
              filterItem.taksItemFilterValue === true &&
              item.task_withActaXcopy?.linkedXcopy?._key === limaStore.xcopyId
            )
              return true;
            if (filterItem.taksItemFilterValue === false) return true;

            return false;
          }

          //FILTER TASK STATE
          if (filterItem.taskItemFilterIdent === TASKFILTERITEM_ENUM.FILTER_TASKSTATE) {
            if (filterItem.taksItemFilterValue === null) return true;
            if (
              filterItem.taksItemFilterValue === item.task_withActaXcopy?.taskState ||
              item.task_withActaXcopy?.taskState == TASKSTATE.NONE
            )
              return true;

            return false;
          }
          return false;
        });
      });

    //Filter by text
    const newTextFilterSplitted = textFilter.split("");
    filterdItems = filterdItems.filter((item: User2Task_withActaXcopy) => {
      return newTextFilterSplitted.every(
        (w: string) =>
          item.task_withActaXcopy?.taskName.includes(w) || item.task_withActaXcopy?.taskDescription.includes(w)
      );
    });
    return filterdItems;
  };

  /**
   * compare edit task to task
   */
  isChangedEditTask = (taskToCompare: User2Task_withActaXcopy): boolean => {
    return Task_withActaXcopy_compare(taskToCompare.task_withActaXcopy, this.editTask.task_withActaXcopy);
  };

  /**
   * Get Edit Task data items
   */
  get getEditTaskAttchCount(): number {
    if (this.editTask === null) return 0;
    return this.editTask.task_withActaXcopy.taskData.attachements.length;
  }

  get getEditLinkedParasCount(): number {
    if (this.editTask === null) return 0;
    return this.editTask.task_withActaXcopy.taskData.paraIds.length;
  }

  // @computed get userIdent(): string {
  //   return `${this.userName}[${this.userId}](email:${this.userEmail})`;
  // }
}
const limaTasksStore = new LimaTasksStore();
export default limaTasksStore;
