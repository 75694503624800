import { log } from "missionlog";
import { LimaDocData, LimaDocParamsENUM, LimaDocTypeENUM } from "../types/docxData";
import { limaLogTag } from "./limaLog";

/**
 * Get true if word contains some text or not
 * check body,
 *
 * TODO: check header and footer
 */
export async function getWordHaveSomeText(): Promise<boolean> {
  return Word.run(function (context) {
    var body = context.document.body;
    body.load("text");
    log.info(limaLogTag.LimaWordInfo, `getWordHaveSomeText: start`);
    return context.sync().then(function () {
      log.info(limaLogTag.LimaWordInfo, `getWordHaveSomeText: after sync`, body);
      if (body.text.trim().length) {
        log.info(limaLogTag.LimaWordInfo, `getWordHaveSomeText: return true`);
        return true;
      } else return false;
    });
  });
}

/**
 * Get true if word is saved
 * check body,
 *
 */
export async function getWordIsSaved(): Promise<boolean> {
  return Word.run(function (context) {
    var thisDocument = context.document;
    thisDocument.load("saved");

    return context.sync().then(function () {
      if (thisDocument.saved === true) {
        return true;
      } else return false;
    });
  });
}

/**
 * Get current parameters of wordDocucment
 * {@link LimaDocData}
 *
 * @returns {LimaDocData} data from current doucment
 *
 * @author MS
 */
export async function getWordDocParams(): Promise<LimaDocData> {
  const out: LimaDocData = { limactaDocID: null, limactaDocName: "", limactaDocType: LimaDocTypeENUM.none };

  await Word.run(async (context) => {
    const properties = context.document.properties.customProperties;
    properties.load("key,type,value");

    log.info(limaLogTag.LimaWordInfo, `getWordParams: get params`);

    await context.sync();
    for (let i: number = 0; i < properties.items.length; i++) {
      log.info(
        limaLogTag.LimaWordInfo,
        `getWordParams: Property Name: '${properties.items[i].key}' ; Type='${properties.items[i].type}' Property Value='${properties.items[i].value}'`
      );

      if (properties.items[i].key === LimaDocParamsENUM.limactaDocID) {
        out.limactaDocID = properties.items[i].value;
      }
      if (properties.items[i].key === LimaDocParamsENUM.limactaDocName) {
        out.limactaDocName = properties.items[i].value;
      }
      if (properties.items[i].key === LimaDocParamsENUM.limactaDocType) {
        out.limactaDocType = properties.items[i].value;
      }
    }
  });
  return out;
}
