/**
 * object for document ident
 */
export interface LimaDocData {
  limactaDocID: string | null;
  limactaDocType: LimaDocTypeENUM;
  limactaDocName: string;
}

/**
 * List of common parameters in Docx used by limacta
 */
export enum LimaDocParamsENUM {
  limactaDocID = "limactaDocID",
  limactaDocType = "limactaDocType",
  limactaDocName = "limactaDocName",
}

export enum LimaDocTypeENUM {
  ACTA = "acta",
  APPENDIX = "appendix",
  VERSION = "version",
  XCOPY = "xcopy",
  USER = "user",
  none = "",
}

export const LimaDocDefault: LimaDocData = {
  limactaDocID: "",
  limactaDocType: LimaDocTypeENUM.none,
  limactaDocName: "",
};
