import { log } from "missionlog";
import { computed, action, makeObservable, observable } from "mobx";
import { Moment } from "moment";
import moment = require("moment");
import { limaQuery_getTimers, limaQuery_storeTimers } from "../api/calls/limaTimersCalls";
import { limaLogTag } from "../limaCommon/limaLog";
import { LimaObjectTypesEnum } from "../types/BASE_Enums";
import { XCOPY_TYPE } from "../types/Task";
import { ITimerPartionBase, ITimePerActa, ITimerPartion } from "../types/Timer";
import limaStore from "./limaStore";

class LimaTimerStore {
  timerIsActive = false;
  currentTimerValue = 0;
  currentTimerPartions: ITimerPartion[] = []; //all currentTimers
  currentActivePartion: ITimerPartion | null = null; //Active timer
  innerItervalTickerStartTime: number | null = null;
  lastTimerPartions: ITimerPartionBase[] = []; //list of past partitions
  innerIntervalFn: ReturnType<typeof setInterval>;
  innerUpdateDBFn: ReturnType<typeof setInterval>;

  constructor() {
    makeObservable(this, {
      timerIsActive: observable,
      currentTimerValue: observable,
      currentTimerPartions: observable,

      setTimerIsActive: action,
      setCurrentTimerAddValue: action,
      setCurrentActivePartion: action,
      getFromDB: action,

      getLimactaSpendTime: computed,
      getActasList: computed,
      getPartitionPerAct: computed,
    });
  }

  /**
   * Start and stop watch
   * @param state
   */
  setTimerIsActive = (state: boolean) => {
    // log.debug(limaLogTag.LimaTimerStore, `setTimerIsActive `, state, this.timerIsActive);
    if (state === true && this.timerIsActive === false) {
      log.info(limaLogTag.LimaTimerStore, `setTimerIsActive `, state, this.timerIsActive);
      this.timerIsActive = state;

      if (limaStore.xcopyType === XCOPY_TYPE.MAINXCOPY) {
        log.info(limaLogTag.LimaTimerStore, `setTimerIsActive MAINCOY`, limaStore.xcopyId, limaStore.actaId);
        this.setCurrentActivePartion({
          itemId: limaStore.actaId,
          itemMainCopyId: limaStore.actaId,
          type: LimaObjectTypesEnum.ACTA,
          userKey: limaStore.userId,
          _key: null,
        });
      } else if (limaStore.xcopyType === XCOPY_TYPE.FULLXCOPY || limaStore.xcopyType === XCOPY_TYPE.TRIMXCOPY) {
        log.info(limaLogTag.LimaTimerStore, `setTimerIsActive XCOPY`, limaStore.xcopyId, limaStore.actaId);
        this.setCurrentActivePartion({
          itemId: limaStore.xcopyId,
          itemMainCopyId: limaStore.actaId,
          type: LimaObjectTypesEnum.XCOPY,
          userKey: limaStore.userId,
          _key: null,
        });
      } else {
        log.info(limaLogTag.LimaTimerStore, `setTimerIsActive OTHER`, limaStore.xcopyId, limaStore.actaId);
        // this.setCurrentActivePartion({
        //   itemId: "",
        //   itemMainCopyId: "",
        //   type: null,
        //   userKey: limaStore.userId,
        //   _key: null,
        // });
      }

      this.innerItervalTickerStartTime = new Date().getTime();
      this.innerIntervalFn = setInterval(this.inner_TimoutTicker, 1000);
      this.innerUpdateDBFn = setInterval(this.store2DB_update, 5 * 1000);
    } else if (state === false && this.timerIsActive === true) {
      this.innerItervalTickerStartTime = null;
      this.timerIsActive = false;
      clearInterval(this.innerIntervalFn);
      clearInterval(this.innerUpdateDBFn);
      this.store2DB_update();
    }
  };

  /**
   * innerTimeTicker
   */
  inner_TimoutTicker = () => {
    // log.debug(limaLogTag.LimaTimerStore, `timoutTicker`, new Date().getTime(), this.innerItervalTickerStartTime);
    const duration = (new Date().getTime() - this.innerItervalTickerStartTime) / 1000;
    // log.debug(limaLogTag.LimaTimerStore, `timoutTicker valtoadd:`, duration);
    this.setCurrentTimerAddValue(duration);
    this.innerItervalTickerStartTime = new Date().getTime();
  };

  /**
   * setter for add duration
   * @param durationInSec
   */
  setCurrentTimerAddValue = (durationInSec: number) => {
    // log.info(limaLogTag.LimaTimerStore, `setCurrentTimerAddValue dur: ${durationInSec}`);
    // window.console.info("CCC-> addding", durationInSec);
    log.debug(limaLogTag.LimaTimerStore, `setCurrentTimerAddValue addding`, limaStore.xcopyId, limaStore.actaId);
    this.currentTimerValue = this.currentTimerValue + durationInSec;
    // log.info(limaLogTag.LimaTimerStore, `setCurrentTimerAddValue currentTimerValue: ${this.currentTimerValue}`);

    //Add to currentPartion
    if (this.currentActivePartion !== null) {
      this.currentActivePartion.timerValue = this.currentActivePartion.timerValue + durationInSec;
      this.setCurrentTimerAddInPartionsList(durationInSec);
    }
  };

  setCurrentTimerAddInPartionsList = (durationInSec: number) => {
    this.currentTimerPartions = this.currentTimerPartions.map((item: ITimerPartion) => {
      if (item.itemId === this.currentActivePartion.itemId) {
        return { ...item, timerValue: item.timerValue + durationInSec };
      }
      return item;
    });
  };

  /**
   * Set current partion active
   * if set to null .. it will go to no acta
   */
  setCurrentActivePartion = (param: ITimerPartionBase | null) => {
    log.info(limaLogTag.LimaTimerStore, `setCurrentActivePartion:`, param?.itemId);
    if (param === null || param.itemId === null) {
      log.info(limaLogTag.LimaTimerStore, `setCurrentActivePartion: is null `, param);
      this.currentActivePartion = null;
    }
    if (this.timerIsActive === false) {
      log.info(limaLogTag.LimaTimerStore, `setCurrentActivePartion: timerIsActive is false `, this.timerIsActive);
      return null;
    }

    //check exist in list
    const filteredPartions = this.currentTimerPartions.filter((item: ITimerPartion) => {
      // log.debug(limaLogTag.LimaTimerStore, `filteredPartions :`, actaidOrNull,item);
      return item.itemId === param.itemId;
    });
    // window.console.log(`AAAAA>>>>>>>>`, actaidOrNull, filteredPartions, this.currentTimerPartions);
    log.info(limaLogTag.LimaTimerStore, `setCurrentActivePartion: filteredPartions `, filteredPartions);
    if (filteredPartions.length > 0) {
      this.currentActivePartion = filteredPartions[0];
    } else {
      const newTimerPartion: ITimerPartion = {
        itemId: param.itemId,
        type: param.type,
        itemMainCopyId: param.itemMainCopyId,
        timerValue: 0,
        userKey: limaStore.userId,
        _key: null,
        timerDateStart: new Date(),
      };
      this.currentTimerPartions = [...this.currentTimerPartions, newTimerPartion];
      this.currentActivePartion = newTimerPartion;
    }
    this.lastTimerPartions = [...this.lastTimerPartions, this.currentActivePartion];
    log.info(limaLogTag.LimaTimerStore, `setCurrentActivePartion: current`, this.currentActivePartion);
  };

  setPreviousActivePartion = () => {
    log.info(limaLogTag.LimaTimerStore, `setPreviousActivePartion:`);
    if (this.lastTimerPartions.length > 1) {
      this.setCurrentActivePartion(this.lastTimerPartions[this.lastTimerPartions.length - 2]);
    } else {
      this.setCurrentActivePartion(null);
      log.warn(limaLogTag.LimaTimerStore, `setPreviousActivePartion: unable to go back`);
    }
  };

  //
  store2DB_update = () => {
    log.info(limaLogTag.LimaTimerStore, `store2DB: storing to DB`, this.currentActivePartion);
    void limaQuery_storeTimers(this.currentTimerPartions).then((timersReturned) => {
      //return timers where was originaly null
      log.info(limaLogTag.LimaTimerStore, `store2DB: return of stored`, timersReturned);
      timersReturned.forEach((itemRetTimer: ITimerPartion) => {
        this.currentTimerPartions.forEach((currTimer: ITimerPartion) => {
          if (currTimer.itemId == itemRetTimer.itemId) currTimer._key = itemRetTimer._key;
        });
      });
    });
  };

  getTodayTimers = () => {
    this.getFromDB(moment().startOf("day"), undefined, () => {
      this.currentTimerPartions.forEach((item: ITimerPartion) => {
        // window.console.info("BBB-> addding", item);
        this.setCurrentTimerAddValue(item.timerValue);
      });
      // window.console.info("CCC-> addding", this.currentTimerValue);
    });
  };

  getFromDB = (from?: Moment, to?: Moment, callBack?: () => void) => {
    if (from === undefined) {
      // void limaQuery_getTimers().then((timersGet: ITimerPartion[])=>{
      //   this.setCurrentActivePartion()
      // });
    } else {
      void limaQuery_getTimers(from.toISOString(), to ? to.toISOString() : undefined).then(
        (timersGet: ITimerPartion[]) => {
          //this.currentTimerPartions.push(timersGet );
          this.currentTimerPartions = timersGet;
          // timersGet.forEach((itemFromDB:ITimerPartion)=>{
          //   this.setCurrentActivePartion(itemFromDB);
          // });
          if (callBack !== undefined) callBack();
        }
      );
    }
  };

  /**
   * return seconds spend in limacta from login
   */
  get getLimactaSpendTime(): number {
    return this.currentTimerValue;
  }

  get getActasList(): string[] {
    const timers = this.currentTimerPartions.filter((item: ITimerPartion, i, arr: ITimerPartion[]) => {
      return arr.indexOf(arr.find((t) => t.itemMainCopyId === item.itemMainCopyId)) === i;
    });
    return timers.map((item: ITimerPartion) => item.itemMainCopyId);
  }

  get getActasTotalTime(): ITimerPartion[] {
    const actasList: ITimerPartion[] = this.getActasList.map((actaId: string): ITimerPartion => {
      return {
        timerValue: 0,
        itemId: "",
        itemMainCopyId: actaId,
        type: LimaObjectTypesEnum.ACTA,
        userKey: limaStore.userId,
        _key: null,
        timerDateStart: new Date(),
      };
    });
    actasList.map((actaItem: ITimerPartion) => {
      this.currentTimerPartions.forEach((itemPartion: ITimerPartion) => {
        if (itemPartion.itemMainCopyId === actaItem.itemMainCopyId) actaItem.timerValue += itemPartion.timerValue;
      });
    });
    return actasList;
  }

  get getPartitionPerAct() {
    const timers = this.currentTimerPartions.reduce(
      (finalList: ITimePerActa[], item: ITimerPartion): ITimePerActa[] => {
        const { itemMainCopyId, itemId, timerValue } = item;
        const nameIndex = finalList.findIndex((listItem: ITimePerActa) => listItem.itemMainCopyId === itemMainCopyId);
        if (nameIndex === -1) {
          finalList.push({ itemMainCopyId: itemMainCopyId, actaTimerValue: item.timerValue, timerPartitions: [item] });
        } else {
          const partIndex = finalList[nameIndex].timerPartitions.findIndex(
            (partItem: ITimerPartion) => partItem.itemId === itemId
          );
          finalList[nameIndex].actaTimerValue += item.timerValue;
          if (partIndex === -1) {
            finalList[nameIndex].timerPartitions.push(item);
          } else {
            finalList[nameIndex].timerPartitions[partIndex].timerValue += timerValue;
          }
        }
        return finalList;
      },
      []
    );

    return timers;
  }
}

const limaTimerStore = new LimaTimerStore();
export default limaTimerStore;
