/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { mergeStyleSets } from "@fluentui/react";
import { ReactNode, ReactElement } from "react";
import React = require("react");
import { onlyText } from "react-children-utilities";
import { getInitials } from "../limaCommon/limaStringTools";

type LimaNameInitalsProps = {
  children?: ReactNode;
};

const classNames = mergeStyleSets({
  initials: {
    cursor: "pointer",
  },
});

export const LimaNameInitals = ({ children }: LimaNameInitalsProps): ReactElement => {
  const [short, setShort] = React.useState<boolean>(true);

  return (
    <span
      className={classNames.initials}
      onMouseEnter={() => short && setShort(false)}
      onMouseLeave={() => !short && setShort(true)}
    >
      {short ? getInitials(onlyText(children), true) : onlyText(children)}
    </span>
  );
};
