import { BASE_User2 } from "./BASE_User2";
import { IUser } from "./User";

export interface User2User extends BASE_User2 {
  userto: IUser;
  state: USER2USER_STATE;
  invitemessage: string;
}

export enum USER2USER_STATE {
  NONE = "NONE",
  OFFERED = "OFFERED",
  ACCEPTED = "ACCEPT",
  REJECTED = "REJECTED",
}
