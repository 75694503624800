import * as React from 'react';
import { FunctionComponent } from 'react';


export interface ItemProp {
    message: string;
}
const FileOpen: FunctionComponent<ItemProp> = ({  message}: ItemProp) => {

    return (
        <section className="ms-welcome__progress ms-u-fadeIn500">
            <h2>Open File</h2><br/>
             {message}
        </section>
    );

}
export default FileOpen;