import { BASE_LimaData } from "./BASE_LimaData";

export interface XcopyHistory extends BASE_LimaData {
  //TODO: prepare judicates
  _id: string;
  xcopyId: string;
  actaId: string;
  historyName: string;
  historyTimeStamp: string;
  cTimeStamp: string;
  versionMajor?: number;
  versionMinor?: number;
  historyNote?: string;
}

export const xcopyHistory_null = (): XcopyHistory => {
  return {
    _id: "",
    xcopyId: "",
    actaId: "",
    historyName: "",
    historyTimeStamp: "",
    cTimeStamp: "",
    _key: "",
    cIssuedBy: "",
    dIssuedBy: "",
    dtimestamp: "",
    versionMajor: 0,
    versionMinor: 0,
    historyNote: "",
  };
};

export const xcopyhistoryDEF: XcopyHistory = {
  _id: "",
  xcopyId: "",
  actaId: "",
  historyName: "",
  historyTimeStamp: "",
  cTimeStamp: "",
  _key: "",
  cIssuedBy: "",
  dIssuedBy: "",
  dtimestamp: "",
  versionMajor: 0,
  versionMinor: 0,
  historyNote: "",
};
