import {
  WORDITEMTYPE,
  linkFullActa2user,
  // urlAccessAddisibilityParaAndWords,
  urlAccessAddVisibility,
  // urlAccessAddVisibilityWordsAndActa,
  urlAccessUpdVisibility,
  urlAccessUpdVisibilityCommentParaAndWords,
  urlAccessUpdVisibilityParaAndWords,
} from "../api/apilinks";
import { httpPost, httpPostAuth, HttpResponse } from "../api/httpAPI";
import { edgeVisibilityOK } from "../api/schema/user/getUserRes";

import { log } from "missionlog";
// import limaUsersStore from "../store/limaUsersStore";
import limaStore from "../store/limaStore";
import limaLoadeStore from "../store/limaLoaderStore";
import { limaLogTag } from "./limaLog";
//log.init({ limaVisibilityCalls: "limaVisibilityCalls" });

export enum UpdateVisibilityTypes {
  COMMON = "COMMON",
  PARAandWORDS = "PARAandWORDS",
  COMMENTSandPARAandWORDS = "COMMENTSandPARAandWORDS",
}

//TODO: rewrite
/**
 * Base Update visibility call
 * @param fromItem in case of PARAandWords Key is enought
 * @param toItem in case of PARAandWords Key is enought
 * @param cIssuedBy IssuedBy in case of create or null in case of delete
 * @param dIssuedBy IssuedBy in case of delete or ""  in case of create
 * @param cTimeStamp TimeStamp in case of create or null in case of delete
 * @param dTimeStamp TimeStamp in case of delete or ""  in case of create
 * @param itemType
 * @param callType
 * @param edgeId
 * @param actaId
 * @returns
 */
export const udpateVisilibity = async (
  fromItem: string, //mainlz user id
  toItem: string,
  cIssuedBy: string | null,
  dIssuedBy: string,
  cTimeStamp: string | null,
  dTimeStamp: string,
  itemType: WORDITEMTYPE,
  callType?: UpdateVisibilityTypes,
  edgeId?: string //TODO: not neccesary now
  // actaId?: string
): Promise<string> => {
  log.info(
    limaLogTag.LimaVisibilityCalls,
    `handleChangeVisibility: fromItem:[${fromItem}] toItem:[${toItem}]` +
      `cIssuedBy:[${cIssuedBy}] dIssuedBy:[${dIssuedBy}] cTimeStamp:[${cTimeStamp}] dTimeStamp:[${dTimeStamp}]` +
      `itemType:[${itemType}] callType:[${callType}] edgeId:[${edgeId}]`
  );
  // actaId:[${actaId}]
  let dataReply: HttpResponse<edgeVisibilityOK>; //TODO: change ANY
  const loaderID = limaLoadeStore.add("update visibility");
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const postData: { [k: string]: any } = {};
    postData._from = fromItem;
    postData._to = toItem;
    postData.type = itemType;
    postData.dIssuedBy = dIssuedBy;
    postData.dTimeStamp = dTimeStamp;

    if (cIssuedBy !== null && cTimeStamp !== null) {
      postData.cIssuedBy = cIssuedBy;
      postData.cTimeStamp = cTimeStamp;
    }
    if (edgeId !== undefined && edgeId !== null && edgeId !== "") {
      postData._key = edgeId;
    }

    log.info(limaLogTag.LimaVisibilityCalls, "handleChangeVisibility: call");
    //limaStore.setLoader(true);
    if (callType !== undefined) {
      if (callType === UpdateVisibilityTypes.COMMON)
        dataReply = await httpPostAuth(urlAccessUpdVisibility(edgeId), postData, loaderID.ac);
      else if (callType === UpdateVisibilityTypes.PARAandWORDS) {
        dataReply = await httpPostAuth(urlAccessUpdVisibilityParaAndWords(), postData, loaderID.ac);
      } else if (callType === UpdateVisibilityTypes.COMMENTSandPARAandWORDS) {
        dataReply = await httpPostAuth(urlAccessUpdVisibilityCommentParaAndWords(), postData, loaderID.ac);
        /*if (edgeId === undefined || edgeId === null || edgeId === "")
          dataReply = await httpPost(urlAccessUpdVisibilityParaAndWords(), postData);
        else dataReply = await httpPost(urlAccessUpdVisibilityParaAndWords(), postData);*/
      }
    } else {
      dataReply = await httpPostAuth(urlAccessUpdVisibility(edgeId), postData, loaderID.ac);
    }

    log.info(limaLogTag.LimaVisibilityCalls, "handleChangeVisibility: get response ", dataReply);
    if ("parsedBody" in dataReply) {
      log.info(
        limaLogTag.LimaVisibilityCalls,
        "handleChangeVisibility: get response parsed body ",
        dataReply.parsedBody
      );
      if (dataReply.parsedBody.key == null && dataReply.parsedBody._key !== null)
        dataReply.parsedBody.key = dataReply.parsedBody._key;
      return Promise.resolve(dataReply.parsedBody.key);
    } else {
      log.error(limaLogTag.LimaVisibilityCalls, "handleChangeVisibility: response - no parsedBody");
      return Promise.reject("");
    }
  } catch (response) {
    log.error(limaLogTag.LimaVisibilityCalls, "handleChangeVisibility: Error", dataReply);
    return Promise.reject("");
  } finally {
    limaLoadeStore.remove(loaderID.k);
  }
};

export const addVisibility = async (
  fromItem: string,
  toItem: string,
  cIssuedBy: string,
  itemType: WORDITEMTYPE
): Promise<string> => {
  let dataReply: HttpResponse<edgeVisibilityOK>; //TODO: change ANY
  const loaderID = limaLoadeStore.add("add Acta Visibility");
  try {
    const postData = {
      _from: fromItem,
      _to: toItem,
      type: itemType,
      cIssuedBy: cIssuedBy,
      dIssuedBy: "",
      dTimeStamp: "",
      cTimeStamp: new Date().toISOString(),
    };

    log.info(limaLogTag.LimaVisibilityCalls, "handleChangeVisibility: ADD call ");
    //limaStore.setLoader(true);
    dataReply = await httpPostAuth(urlAccessAddVisibility(), postData, loaderID.ac);
    log.info(limaLogTag.LimaVisibilityCalls, "handleChangeVisibility: ADD getting response ");
    console.log("response" + String(dataReply));
    console.log(dataReply);
    if ("parsedBody" in dataReply) {
      log.info(limaLogTag.LimaVisibilityCalls, "handleChangeVisibility: ADD response", dataReply.parsedBody);
      return Promise.resolve(dataReply.parsedBody.key);
    } else {
      log.warn(limaLogTag.LimaVisibilityCalls, "handleChangeVisibility: ADD response - no parsedBody");
      return Promise.reject("");
    }
  } catch (response) {
    console.log("Error", dataReply);

    return Promise.reject("");
  } finally {
    limaLoadeStore.remove(loaderID.k);
  }
};

export const addActaFullVisibility = async (forUserId: string): Promise<boolean> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let dataReply: HttpResponse<any>; //TODO: change ANY
  const loaderID = limaLoadeStore.add("add Acta FullVisibility");
  try {
    const postData = {
      userId: limaStore.userId,
      user2link: forUserId,
    };

    log.info(limaLogTag.LimaVisibilityCalls, "addActaFullVisibility: call started");
    //limaStore.setLoader(true);

    dataReply = await httpPost(linkFullActa2user(limaStore.actaId), postData, loaderID.ac);
    log.info(limaLogTag.LimaVisibilityCalls, "addActaFullVisibility: call finishe ", dataReply);
    return true;
  } catch (response) {
    log.error(limaLogTag.LimaVisibilityCalls, "addActaFullVisibility: Error", dataReply);
    return false;
  } finally {
    //limaStore.setLoader(false);
    limaLoadeStore.remove(loaderID.k);
  }
};
