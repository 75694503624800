import { log } from "missionlog";
import { FunctionComponent, useEffect, useState } from "react";
import React = require("react");
import { useMutation, useQuery } from "react-query";

// import { useTranslation } from "react-i18next";
import { IAttachmentFileWithInfo, LimaAttachement } from "../../../../types/LimaAttachement";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import limaStore from "../../../../store/limaStore";
import limaLoadeStore from "../../../../store/limaLoaderStore";
import { httpGetAuth, httpPostMulipartAuth, HttpResponse } from "../../../../api/httpAPI";
import { urlGetAttachementsList, urlStoreAttachement } from "../../../../api/apilinks";
// import { LimaHideableSection } from "../../../../limaComponents/LimaHideableSection";
import { LimaLoadingComponnet } from "../../../../limaComponents/LimaLoadingComponnet";
import AttachementAddV4 from "./components/AttachementAddV4";
import AttachementItemV4 from "./components/AttachementItemV4";
import { getAtachement } from "../../../../api/calls/limaFileCalls";
import _ = require("lodash");
// import FileSaver = require("file-saver");

interface IAttachementComponentV4Props {
  attKeys: string[];
  setTaskDataAttCallBack: (newAtts: string[]) => void;
  onTaskAttCountClb?: (count: number) => void;
  setUnsavedChanges?: (state: boolean) => void;
  onValidFilesUpdateClbk?: (files: IAttachmentFileWithInfo[]) => void;
  onOpenValidFiles?: IAttachmentFileWithInfo[];
  minimal?: boolean;
  enableUploadSingleFile: boolean;
  dropdownCleanup: number;
}

export type storeAttachment = {
  file: File;
  descr: string;
  name: string;
};

const AttachementComponentV4: FunctionComponent<IAttachementComponentV4Props> = (
  props: IAttachementComponentV4Props
) => {
  const [attachements, setAttachements] = useState<LimaAttachement[]>([]);

  const [validFiles, setValidFiles] = useState<IAttachmentFileWithInfo[]>([]);
  const [unsupportedFiles, setUnsupportedFiles] = useState<IAttachmentFileWithInfo[]>([]);
  // const { t } = useTranslation();

  const { isLoading, error, refetch } = useQuery(
    ["attachements", props.attKeys],
    () => fetchAtachements(props.attKeys),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: (err) => {
        log.error(limaLogTag.AttachementComponentV4, "useQuery get attachements ", err);
      },
      onSuccess: (data) => {
        log.info(limaLogTag.AttachementComponentV4, "useQuery get attachements ", data);
        setAttachements(data);
      },
    }
  );

  const { mutate } = useMutation((item: storeAttachment) => storeAttachment(item), {
    onMutate: (variables: storeAttachment) => {
      // A mutation is about to happen!
      log.info(limaLogTag.AttachementComponentV4, "call mutate ", variables);
      return { id: 1 };
    },
    onSuccess: (data: LimaAttachement) => {
      log.info(limaLogTag.AttachementComponentV4, "uploaded succes ", data);
      const newAtt: LimaAttachement[] = [...attachements, data];
      setAttachements(newAtt);
      props.setTaskDataAttCallBack(newAtt.map((item: LimaAttachement) => item._key));
    },
  });

  //--------------
  // Reaction
  //-------------
  useEffect(() => {
    log.info(limaLogTag.AttachementComponentV4, "UEF: STARTING");
  }, []);

  useEffect(() => {
    log.info(limaLogTag.AttachementComponentV4, "UEF attKeys: mounted ", props.attKeys);
    if (props.attKeys !== undefined && props.attKeys !== null && props.attKeys.length > 0) {
      setAttachements([]);
      void refetch();
    } else {
      log.info(limaLogTag.AttachementComponentV4, "UEF: missing attechmentIds ");
    }
  }, [props.attKeys]);

  useEffect(() => {
    log.info(limaLogTag.AttachementComponentV4, "UEF: dropdownCleanup", props.dropdownCleanup);
  }, [props.dropdownCleanup]);

  useEffect(() => {
    log.info(limaLogTag.AttachementComponentV4, "UEF: merging validFiles", props.onOpenValidFiles);
    if (props.onOpenValidFiles !== undefined && props.onOpenValidFiles.length !== 0) {
      log.info(limaLogTag.AttachementComponentV4, "UEF: merging validFiles", validFiles, props.onOpenValidFiles);
      const arr = _.uniqBy([...validFiles, ...props.onOpenValidFiles], "filename");
      log.info(limaLogTag.AttachementComponentV4, "UEF: merging validFiles out ", arr);
      setValidFiles(arr);
    }
  }, [props.onOpenValidFiles]);

  useEffect(() => {
    if (props.setUnsavedChanges !== undefined) props.setUnsavedChanges(true);
    if (props.onTaskAttCountClb !== undefined) props.onTaskAttCountClb(attachements.length);
  }, [attachements]);

  useEffect(() => {
    log.info(limaLogTag.AttachementComponentV4, "UEF: validFiles ", validFiles);
    //if (props.onValidFilesUpdateClbk !== undefined) props.onValidFilesUpdateClbk(validFiles);
  }, [validFiles]);
  //-----------
  // Handlers
  //-----------
  if (isLoading == true) {
    //return <>Loading</>;
    return LimaLoadingComponnet(3);
  }

  if (error) {
    log.error(limaLogTag.AttachementComponentV4, "loaded error: ", error);
    return <>Error Getting data </>;
  }

  const onDownloadHandler = (key: string, name: string) => {
    log.info(limaLogTag.AttachementComponentV4, "start getting item ", key);
    void getAtachement(key, name);
  };

  const onUpload = (file: File, name: string, descr: string): boolean => {
    log.info(limaLogTag.AttachementComponentV4, "uploading ", file);
    mutate({ file: file, name: name, descr: descr });
    return true;
  };

  const onRemoveAtt = (attId: string) => {
    const newAttList: LimaAttachement[] = attachements.filter((item: LimaAttachement) => item._key !== attId);
    setAttachements(newAttList);
    props.setTaskDataAttCallBack(newAttList.map((item: LimaAttachement) => item._key));
  };

  const setValidFilesFromComponent = (files: IAttachmentFileWithInfo[]) => {
    log.info(limaLogTag.AttachementComponentV4, "setValidFilesFromComponent ", files);
    setValidFiles(files);

    if (props.onValidFilesUpdateClbk !== undefined) props.onValidFilesUpdateClbk(files);
  };
  //-------------------
  //  Reneders
  //--------------------

  return (
    <>
      {props.minimal !== undefined && props.minimal === true ? (
        <AttachementAddV4
          fileUploaddHandler={onUpload}
          validFiles={validFiles}
          setValidFiles={setValidFilesFromComponent}
          unsupportedFiles={unsupportedFiles}
          setUnsupportedFiles={setUnsupportedFiles}
          enableUploadSingleFile={false}
          onlyDD={props.minimal}
          dropdownCleanup={props.dropdownCleanup}
        />
      ) : (
        <>
          <AttachementAddV4
            fileUploaddHandler={onUpload}
            validFiles={validFiles}
            setValidFiles={setValidFilesFromComponent}
            unsupportedFiles={unsupportedFiles}
            setUnsupportedFiles={setUnsupportedFiles}
            enableUploadSingleFile={props.enableUploadSingleFile}
            dropdownCleanup={props.dropdownCleanup}
          />
          <>
            {attachements.map((item: LimaAttachement) => (
              <AttachementItemV4
                key={item._key}
                attachement={item}
                onDownloadHandler={() => onDownloadHandler(item._key, item.name)}
                onRemoveAtt={() => onRemoveAtt(item._key)}
              />
            ))}
          </>
        </>
      )}
    </>
  );
};

export default AttachementComponentV4;

//----------------------
//   REACT QUERY
//----------------------

const fetchAtachements = async (attKeys: string[]): Promise<LimaAttachement[]> => {
  log.info(limaLogTag.AttachementComponentV4, "fetching data fetchAtachements");
  // const [, { patientID }] = params.queryKey;
  const loaderID = limaLoadeStore.add("Getting attachements from DB");
  try {
    // const response:HttpResponse<any> =  await httpGetExtToken(urlGetSurveyAnamnesis(patientID,2))
    const response: HttpResponse<LimaAttachement[]> = await httpGetAuth(urlGetAttachementsList(attKeys), loaderID.ac);
    log.info(limaLogTag.AttachementComponentV4, "fetching data fetchAtachements response", response);
    if (!response.ok) {
      throw response;
    }
    if (response.parsedBody !== undefined) {
      if (Array.isArray(response.parsedBody))
        log.info(
          limaLogTag.AttachementComponentV4,
          "fetching data fetchAtachements response.parsedBody",
          response.parsedBody
        );
      //response.parsedBody.forEach((item:any)=>assertIsDB_Survey(item))
      return response.parsedBody;
    }
    throw new Error("no data");
  } catch (error) {
    throw new Error(error);
  } finally {
    limaLoadeStore.remove(loaderID.k);
  }
};

const storeAttachment = async (vars: storeAttachment) => {
  const { name, file, descr } = vars;

  const formData: FormData = new FormData();
  // formData.append("attachments", new Blob([JSON.stringify(attachments)], { type: "application/json" }));
  formData.append("userid", limaStore.userId);
  // formData.append("linkedid", null); //NOT IMPLEMETED YET
  // formData.append("linkedtype", null);//NOT IMPLEMETED YET
  formData.append("description", descr);
  formData.append("name", name);
  formData.append("file", file);

  const loaderID = limaLoadeStore.add("Storing attachement");
  try {
    const response: HttpResponse<LimaAttachement> = await httpPostMulipartAuth<LimaAttachement>(
      urlStoreAttachement(),
      formData,
      loaderID.ac,
      true
    );
    if (!response.ok) {
      log.error(limaLogTag.AttachementComponentV4, "useMutation post message ", response);
      throw response;
    }
    log.info(limaLogTag.AttachementComponentV4, "fetching post message response.parsedBody", response.parsedBody);

    const newMessage = response.parsedBody;
    log.info(limaLogTag.AttachementComponentV4, "fetching post message ", newMessage);

    return newMessage;
  } finally {
    limaLoadeStore.remove(loaderID.k);
  }
};
