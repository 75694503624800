import { log } from "missionlog";
import { makeObservable, observable, action } from "mobx";
import { urlGetAllTags, urlPatchTagsOnXcopy, urlPostTag } from "../api/apilinks";
import { httpGetAuth, httpPostAuth, HttpResponse } from "../api/httpAPI";
import { limaLogTag } from "../limaCommon/limaLog";
import { LIMA_ITEM_TYPE } from "../types/BASE_Enums";
import { Tags } from "../types/Tags";
import limaLoadeStore, { LoaderItemEnd } from "./limaLoaderStore";

class LimaObjTagsStore {
  tags: Tags[] | null = null;
  isLoading = false;

  constructor() {
    makeObservable(this, {
      tags: observable,
      isLoading: observable,
      getTagsFirstLoad: action,
      setIsLoading: action,
      addSingleTag: action,
      removeTag: action,
    });
  }

  async getTagsFirstLoad() {
    log.info(limaLogTag.LimaObjTagsStore, "fetching data fetchTags");
    const loaderID = limaLoadeStore.add("Getting tags from DB");
    try {
      const response: HttpResponse<Tags> = await httpGetAuth(urlGetAllTags(), loaderID.ac);
      log.info(limaLogTag.TagsComponent, "fetching data fetchTags response", response);
      if (!response.ok) {
        // throw response;
        return;
      }
      if (response.parsedBody !== undefined) {
        if (Array.isArray(response.parsedBody)) {
          log.info(limaLogTag.TagsComponent, "fetching data fetchTags response.parsedBody", response.parsedBody);
          this.tags = response.parsedBody;
        }
        // return response.parsedBody;
        limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
        return;
      }
      // return;
      // throw new Error("no data");
    } catch (error) {
      // throw new Error(error);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    }
  }

  setIsLoading(state: boolean) {
    this.isLoading = state;
  }

  addSingleTag(newTag: Tags) {
    if (this.tags === null) {
      this.tags = [];
    }
    this.tags.push(newTag);
    log.info(limaLogTag.TagsComponent, "addSingleTag: actual tags list", this.tags);
  }

  async addNewTagCall(tag: Tags): Promise<Tags> {
    log.info(limaLogTag.LimaObjTagsStore, "adding tags to Tags");
    const loaderID = limaLoadeStore.add("Adding new tag to DB");

    const tagToStore: Tags = {
      ...tag,
    };
    log.info(limaLogTag.TagsComponent, "adding tag to db data", tagToStore);

    try {
      const response: HttpResponse<Tags> = await httpPostAuth(urlPostTag(), tagToStore, loaderID.ac);
      log.info(limaLogTag.TagsComponent, "adding data fetchTags response", response);
      if (!response.ok) {
        throw response;
      }
      if (response.parsedBody !== undefined) {
        this.addSingleTag(response.parsedBody);
        log.info(limaLogTag.TagsComponent, "adding tag to db data", tagToStore);
        return response.parsedBody;
      }
      throw new Error("no data");
    } catch (error) {
      throw new Error(error);
    } finally {
      limaLoadeStore.remove(loaderID.k);
    }
  }

  removeTag() {
    log.info(limaLogTag.LimaObjTagsStore, "remove tags to Tags");
  }

  getTagByKey = (tagKey: string): Tags => {
    if (this.tags == null) return null;
    const filteredtags = this.tags.filter((item: Tags) => item._key === tagKey);
    return filteredtags[0];
  };

  getTagByName = (tagName: string): Tags => {
    if (this.tags == null) return null;
    const filteredtags = this.tags.filter((item: Tags) => item.tagname.toLowerCase() === tagName.toLowerCase());
    return filteredtags[0];
  };

  async updateTagsOnItem(itemtype: LIMA_ITEM_TYPE, itemKey: string, tags: string[]) {
    log.info(limaLogTag.LimaObjTagsStore, `updateTagsOnItem: updateing tags on [${String(itemtype)}]/${itemKey}`);
    const loaderID = limaLoadeStore.add("Updating tags on item DB");

    let callString = "";
    if (itemtype === LIMA_ITEM_TYPE.XCOPY) {
      callString = urlPatchTagsOnXcopy(itemKey);
    } else {
      log.info(limaLogTag.LimaObjTagsStore, `updateTagsOnItem:Not implemnted tagging for [${String(itemtype)}]`);
      return null;
    }
    try {
      const response: HttpResponse<string[]> = await httpPostAuth(callString, { tags: tags }, loaderID.ac);
      log.info(limaLogTag.TagsComponent, "updateTagsOnItem: response", response);
      if (!response.ok) {
        throw response;
      }
      if (response.parsedBody !== undefined) {
        //this.addSingleTag(response.parsedBody);
        return response.parsedBody;
      }
      throw new Error("no data");
    } catch (error) {
      throw new Error(error);
    } finally {
      limaLoadeStore.remove(loaderID.k);
    }
  }

  onLogoutClear() {
    this.tags = null;
    this.isLoading = false;
  }
}

const limaObjTagsStore = new LimaObjTagsStore();

export default limaObjTagsStore;
