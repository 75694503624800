import { log, tag } from "missionlog";
import { action, makeObservable, observable } from "mobx";
import { searchTypeENUM, urlSearch4Text } from "../api/apilinks";

import { httpGetAuth, HttpResponse } from "../api/httpAPI";
import { searchResDefault, searchResult } from "../api/schema/search/searchResp";
import limaLoadeStore from "./limaLoaderStore";

log.init({ LimaSearchStore: "LimaSearchStore" });

class LimaSearchStore {
  searchType: searchTypeENUM = searchTypeENUM.STRICT;
  list: searchResult[] = searchResDefault;
  fullList: searchResult[] = searchResDefault;
  // loadingData = false;
  totalItemCount = 0;

  constructor() {
    makeObservable(this, {
      searchType: observable,
      list: observable,
      // loadingData: observable,
      totalItemCount: observable,

      setSearchType: action,
      // setLoadingData: action,
      getListFromDB: action,
      setList: action,
      setTotalItemCount: action,
    });
  }

  setSearchType(searchType: searchTypeENUM, txt: string) {
    log.info(tag.LimaSearchStore, `setSearchType: search set to ${txt}`, searchType);
    this.searchType = searchType;
  }

  async getListFromDB(
    // userId: string,
    searchWords: string[],
    actaId?: string,
    timeStampFrom?: string,
    timeStampTo?: string
  ) {
    log.warn(tag.LimaSearchStore, `getListFromDB: search `, searchWords);
    if (searchWords.length == 0) {
      log.warn(tag.LimaSearchStore, `getListFromDB: non words in search""`);
      return;
    }
    // this.setLoadingData(true);
    const loaderID = limaLoadeStore.add(`searching for: '${searchWords.join("-")}'`);
    let listData: HttpResponse<searchResult[]>;
    try {
      listData = await httpGetAuth<searchResult[]>(
        urlSearch4Text(this.searchType, searchWords, timeStampFrom, timeStampTo, actaId),
        loaderID.ac
      );
      log.info(tag.LimaSearchStore, "getListFromDB: asketo to get something");
      if (listData.parsedBody !== undefined) {
        log.info(tag.LimaSearchStore, "getListFromDB: parsedBody is not null");
        listData.parsedBody.sort((a: searchResult, b: searchResult) => (a.aId > b.aId ? 1 : b.aId > a.aId ? -1 : 0));
        this.setList(listData.parsedBody);
        this.setFullList(listData.parsedBody);
        this.setTotalItemCount(listData.parsedBody.length);

        log.info(tag.LimaSearchStore, "getListFromDB: load finish", listData);
      }
    } catch (response) {
      log.error(tag.LimaSearchStore, "getListFromDB: Error call get User", listData);
    } finally {
      // this.setLoadingData(false);
      limaLoadeStore.remove(loaderID.k);
    }
  }

  // setLoadingData(trueOrfalse: boolean) {
  //   this.loadingData = trueOrfalse;
  // }

  setList(newList: searchResult[]) {
    this.list = newList;
  }

  setTotalItemCount(newCount: number) {
    this.totalItemCount = newCount;
  }

  setFullList(newList: searchResult[]) {
    this.fullList = newList;
  }

  /**
   * Cleanup on logout button
   *
   * @autor MS
   */
  onLogoutClear() {
    this.searchType = searchTypeENUM.STRICT;
    this.list = searchResDefault;
    this.fullList = searchResDefault;
    this.totalItemCount = 0;
  }
}

const limaSearchStore = new LimaSearchStore();
export default limaSearchStore;
