import { advice_textsearch } from "../../types/advice";

export interface judgeActParaFailed {
  msg: string;
}

export interface judgeActParaItemOK {
  court: string;
  date: string;
  ident: string;
  title: string;
  word: string;
  type: string;
  sentences: string[];
  presentText?: string;

  judiLinks: advice_textsearch[];

  actid: string;
  parid: string;
}

export const judgeActParaItemOKDef: judgeActParaItemOK = {
  court: "",
  date: "",
  ident: "",
  title: "",
  word: "",
  type: "",
  sentences: [],

  judiLinks: [],

  actid: "",
  parid: ""
};

export interface callJudiListMulti {
  judiIds: advice_textsearch[];
  count?: number;
  sentanceDetail?: boolean;
}

export interface judgeActParaItemMultiOK {
  parID: string;
  actID: string;
  actIDsigns: string[];
  judi: judgeActParaItemOK[];
}
