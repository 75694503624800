import {
  getFocusStyle,
  getTheme,
  // IconButton,
  // IIconProps,
  IStackTokens,
  ITheme,
  mergeStyles,
  mergeStyleSets,
  Persona,
  PersonaSize,
  Stack,
} from "@fluentui/react";
import { log } from "missionlog";
import { StackItem } from "office-ui-fabric-react";
import { FunctionComponent, useEffect } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { LimaIconTaskCheck } from "../../../../assets/icons/LimaIconTaskCheck";
import { LimaIconTaskInprogress } from "../../../../assets/icons/LimaIconTaskInprogress";
import { LimaIconTaskUnknown } from "../../../../assets/icons/LimaIconTaskUnknown";
import { limaLogTag } from "../../../../limaCommon/limaLog";
// import { LimaActionIconRemove } from "../../../../limaComponents/LimaActionIcons/LimaActionIconRemove";
import LimaInfoBit, { LIMAINFOBITTYPE } from "../../../../limaComponents/LimaInfoBit";
import limaStore from "../../../../store/limaStore";
import limaUsersStore from "../../../../store/limaUsersStore";
import { TaskTypeEnum } from "../../../../types/Task";
import { Task_withActaXcopyParasCommentsTasksMessagesAttachements } from "../../../../types/Task_withActaXcopy";
import TaskListActionsV4, { ITaskListAction } from "./TaskListActions";
//const RemoveLinkIcon: IIconProps = { iconName: "RemoveLink" };

export enum E_TASKPARAMS {
  CREATEDATE,
  DUEDATE,
  DETAILINFOBITS,
  DESCRIPTION,
}

interface TaskListItemV4Props {
  item: Task_withActaXcopyParasCommentsTasksMessagesAttachements;
  //onDetailClickHandler?: () => void; //(task: Task) => void;
  //onRemoveAction?: () => void; //(taskId: string) => void;
  onStateClick?: () => void;
  // actionIcon?: IIconProps;
  actionEnabled?: boolean;
  actionsList?: ITaskListAction[];
  hideTaskParams: E_TASKPARAMS[];
  onMouseEnterTask?: (taskParaIds: string[]) => void;
  onMouseLeaveTask?: (taskParaIds: string[]) => void;
}

const taskStateIconClass = mergeStyles({
  fontSize: 25,
  height: 25,
  width: 25,
  // margin: "0 25px",
});

const theme: ITheme = getTheme();
const { palette, semanticColors, fonts } = theme;

// const sectionStackTokens: IStackTokens = { childrenGap: 10 };
const numericalSpacingStackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const classNames = mergeStyleSets({
  itemRowWrapper: {
    display: "flex",
    // flexDirection: "row",
    // justifyContent: "space-between",
    width: "100%",
    padding: 0,
    selectors: {
      "&:hover": {
        background: palette.neutralLight,
        cursor: "pointer",
      },
    },
    borderBottom: `1px solid ${semanticColors.bodyDivider}`,
    minHeight: 54,
    paddingBottom: 5,
    paddingTop: 10,
  },
  itemRowTaskNameActionPartContentWrapper: {
    flexDirection: "row",
  },
  itemRowTaskStateIconsPart: {
    marginTop: 5,
    // display: "flex",
    // flexDirection: "row",
    // marginLeft: 10,
    // width: 35,
    // justifyContent: "space-around",
  },
  itemRowTaskDataPart: {
    flexGrow: 1,
  },
  itemRowTaskActionsPart: {
    marginLeft: 3,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  itemRowTaskResponsPart: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    // width: 35,
  },
  itemRowTaskPartContentWrapper: {
    marginLeft: 10,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block",
    flexGrow: 4,
    // flexGrow: 1,
  },
  iconGreen: [{ color: "green" }, taskStateIconClass],
  iconBlue: [{ color: "blue" }, taskStateIconClass],
  iconGrey: [{ color: "grey" }, taskStateIconClass],
  iconBasic: [{ color: "grey" }, taskStateIconClass],
  itemActionIcon: [
    getFocusStyle(theme),
    {
      minHeight: 30,
      verticalAlign: "left",
    },
  ],
  taskName: [
    fonts.medium,
    {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: "bold",
      flexGrow: 1,
      alignSelf: "center",
    },
  ],
  taskTerms: [
    fonts.smallPlus,
    {
      marginBottom: 4,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  ],
  taskInfo: {
    paddingRight: 25,
  },
  createLabel: {
    color: "#AEAEB2",
  },
  tillInfo: {
    color: "#FF8C00",
  },
  taskDescription: [
    fonts.medium,
    {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "60%",
      color: "#AEAEB2",
    },
  ],
});

const TaskListItemV4: FunctionComponent<TaskListItemV4Props> = (props: TaskListItemV4Props) => {
  const { t } = useTranslation();

  const TaskStateIcon = (): JSX.Element => {
    if (props.item.type === TaskTypeEnum.NOTE) return <></>;

    if (props.item.taskState && props.item.taskState.toLowerCase() === "close") {
      return <LimaIconTaskCheck title={t("tptasks:taskList.taskItem.stateClosedLbl")} size={24} />;
    } else if (props.item.taskState && props.item.taskState.toLowerCase() === "open") {
      return <LimaIconTaskInprogress title={t("tptasks:taskList.taskItem.stateOpenLbl")} size={24} />;
    } else {
      return <LimaIconTaskUnknown title={t("tptasks:taskList.taskItem.stateUnknwLbl")} size={24} />;
    }
  };

  const Responsible = (): JSX.Element => {
    if (
      !props.item ||
      props.item.type === TaskTypeEnum.POINT ||
      !props.item.taskAssignedTo ||
      !props.item.taskAssignedTo._key
    ) {
      // item.taskAssignedTo.userName === undefined || item.taskAssignedTo.userName === null) {
      return <></>;
    }
    const initials: string[] | null = limaUsersStore.getUserInitialsAndName(props.item.taskAssignedTo._key);
    if (initials === null) return <></>;

    return (
      <Persona imageInitials={initials[0]} size={PersonaSize.size24} title={initials[1]} hidePersonaDetails={true} />
    );
  };

  useEffect(() => {
    log.info(limaLogTag.TasksListItem, `useEffect: rendering item - `, props.item);
    log.info(limaLogTag.TasksListItem, `useEffect: rendering item - `, JSON.stringify(props.item?.taskData));
  }, []);

  //------------
  // Handlers
  //-------------
  const onMouseEnterTask = () => {
    if (props.onMouseEnterTask !== undefined) props.onMouseEnterTask(props.item.taskData.paraIds);
  };

  const onMouseLeaveTask = () => {
    if (props.onMouseLeaveTask !== undefined) props.onMouseLeaveTask(props.item.taskData.paraIds);
  };

  //-----------------
  // Renders
  //------------------
  const descriptionShort = () => {
    //{item.taskDescription != undefined && item.taskDescription}
    if (props.item.taskDescription === undefined) return "";
    const trimmedString =
      props.item.taskDescription.length === 0
        ? "..."
        : props.item.taskDescription.length > 20
        ? props.item.taskDescription.substring(0, 100 - 3) + "..."
        : props.item.taskDescription;
    return trimmedString;
  };

  const DueDate = (): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    log.debug(limaLogTag.TasksListItem, `DueDate: rendering item - `, props.item);
    if (props.item.type !== TaskTypeEnum.NOTE && props.item.taskDueDate) {
      return (
        <div className={classNames.tillInfo}>
          {`${t("tptasks:taskList.taskItem.duedateLbl")} `}
          <Moment format={limaStore.userDateFormat}>{props.item.taskDueDate}</Moment>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const CreateDate = (): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    log.debug<string>(limaLogTag.TasksListItem, `CreateDate: rendering item - `, props.item);
    if (props.item.type !== TaskTypeEnum.NOTE && props.item.cTimeStamp) {
      return (
        <div className={classNames.taskInfo}>
          <span className={classNames.createLabel}>{`${t("tptasks:taskList.taskItem.createdateLbl")} `}</span>
          <Moment format={limaStore.userDateFormat}>{props.item.cTimeStamp}</Moment>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const TaskNameActionPart = (): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    log.debug<string>(limaLogTag.TasksListItem, `TaskMainPart: rendering item - `, props.item);

    return (
      <Stack key="tasknameaction" className={classNames.itemRowTaskNameActionPartContentWrapper} horizontal>
        <StackItem key="label" className={classNames.taskName}>
          {props.item.taskName != undefined && props.item.taskName}
        </StackItem>
        <Stack.Item className={classNames.itemRowTaskResponsPart}>
          <Responsible />
        </Stack.Item>
        <Stack.Item key="unliactionsnk" className={classNames.itemRowTaskActionsPart}>
          <TaskListActionsV4 actionsList={props.actionsList} />
        </Stack.Item>
      </Stack>
    );
  };

  const TaskInfoPart = (): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    log.debug<string>(limaLogTag.TasksListItem, `TaskMainPart: rendering item - `, props.item);

    return (
      <Stack key="taskbase" className={classNames.itemRowTaskPartContentWrapper} title={descriptionShort()}>
        {!props.hideTaskParams.includes(E_TASKPARAMS.CREATEDATE) &&
          !props.hideTaskParams.includes(E_TASKPARAMS.DUEDATE) && (
            <StackItem key="terms" className={classNames.taskTerms}>
              <Stack horizontal>
                {!props.hideTaskParams.includes(E_TASKPARAMS.CREATEDATE) && (
                  <StackItem>
                    <CreateDate />
                  </StackItem>
                )}
                {!props.hideTaskParams.includes(E_TASKPARAMS.DUEDATE) && (
                  <StackItem>
                    <DueDate />
                  </StackItem>
                )}
              </Stack>
            </StackItem>
          )}
        {!props.hideTaskParams.includes(E_TASKPARAMS.DESCRIPTION) && (
          <StackItem key="descr" className={classNames.taskDescription}>
            {props.item.taskDescription != undefined && descriptionShort()}
          </StackItem>
        )}
        {!props.hideTaskParams.includes(E_TASKPARAMS.DETAILINFOBITS) && (
          <StackItem key="info">
            <Stack horizontal>
              <StackItem title="Messages">
                <LimaInfoBit type={LIMAINFOBITTYPE.MESSAGE} count={props.item.linkedMessagesCount} />
              </StackItem>
              <StackItem title="Attachements">
                <LimaInfoBit type={LIMAINFOBITTYPE.ATTACHMENT} count={props.item.linkedAttachmentsCount} />
              </StackItem>
              <StackItem title="Links into document">
                <LimaInfoBit
                  type={LIMAINFOBITTYPE.POINTINGTO}
                  count={props.item.linkedPara.length + props.item.linkedComment.length}
                />
              </StackItem>
              <StackItem title="Linked tasks">
                <LimaInfoBit type={LIMAINFOBITTYPE.LINKEDITEM} count={props.item.linkedTasks.length} />
              </StackItem>
            </Stack>
          </StackItem>
        )}
      </Stack>
    );
  };

  if (props.item === undefined || props.item === null) return <></>;
  else
    return (
      <Stack
        horizontal
        className={classNames.itemRowWrapper}
        // onMouseEnter={onMouseEnterTask}
        // onMouseLeave={onMouseLeaveTask}
        tokens={numericalSpacingStackTokens}
        data-is-focusable={true}
        // onClick={() => {
        //   log.info(limaLogTag.TasksListItem, `onClick: clicked on task `, item);
        //   if (props.onClickHandler !== undefined) props.onClickHandler();
        // }}
        id={props.item._key}
        onMouseEnter={onMouseEnterTask}
        onMouseLeave={onMouseLeaveTask}
      >
        <Stack.Item
          key="taskState"
          className={classNames.itemRowTaskStateIconsPart}
          onClick={() => {
            if (props.onStateClick !== undefined) props.onStateClick();
          }}
        >
          <TaskStateIcon />
        </Stack.Item>
        <Stack.Item className={classNames.itemRowTaskDataPart}>
          <Stack>
            <Stack.Item>
              <TaskNameActionPart />
              <Stack.Item></Stack.Item>
              <TaskInfoPart />
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
    );
};

export default TaskListItemV4;
