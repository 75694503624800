import * as React from "react";
import { FunctionComponent } from "react";

import { ActivityItem, getTheme, ITheme, mergeStyleSets } from "office-ui-fabric-react";

import { paraHistoryOK, paraHistTestTypeEnum } from "../../../api/schema/getParaHistoryResp";

export interface HistItemTextRowProps {
  index?: number;
  showChanges: boolean;
  paraHistoryRow: paraHistoryOK;
}

const theme: ITheme = getTheme();
const { palette } = theme;

//TODO: show only around changes
// textDecoration: "strikeout",
// backgrounColor: "lightred",
const classNames = mergeStyleSets({
  taskitemRow: {
    flexGrow: 1,

    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 5,
    paddingTop: 5,
    selectors: {
      "&:hover": {
        background: palette.neutralLight,
        cursor: "pointer",
      },
    },
  },
  taskitemRowAdd: {
    backgroundColor: "lightgreen",
  },
  taskitemRowDel: {
    backgrounColor: "coral",
    textDecoration: "line-through",
  },
  taskItemRowItem: {},
  taskItemRowText: {},
});

const HistParaItemTextRow: FunctionComponent<HistItemTextRowProps> = ({
  index,
  showChanges,
  paraHistoryRow,
}: HistItemTextRowProps) => {
  console.log("HistParaItemTextRow");
  console.log(paraHistoryRow);

  let paraHistText = "";

  //let lastType: paraHistTestTypeEnum = paraHistTestTypeEnum.ORIGIN;
  let lastType = paraHistTestTypeEnum.ORIGIN;
  // let space: boolean = false;
  let space = false;
  // let open: boolean = false;
  let open = false;

  for (const singleTextItem of paraHistoryRow.text) {
    console.log(singleTextItem.t);
    if (lastType != singleTextItem.t && showChanges) {
      if (open) paraHistText += "</span>";
      switch (singleTextItem.t) {
        case paraHistTestTypeEnum.NEW:
          paraHistText += `<span class='${classNames.taskitemRowAdd}'>`; //
          open = true;
          break;
        case paraHistTestTypeEnum.ORIGIN:
          open = false;
          break;
        case paraHistTestTypeEnum.DELETE:
          paraHistText += `<span class='${classNames.taskitemRowDel}' >`; //
          open = true;
          break;
        default:
      }
    }

    if (space) paraHistText += " ";

    paraHistText += singleTextItem.w;
    space = true;
    lastType = singleTextItem.t;
  }
  if (open) paraHistText += "</span>";

  console.log("paraHist text", paraHistText);
  const date = new Date(paraHistoryRow.ts);

  const activityItemComment = {
    key: paraHistoryRow.order,
    activityDescription: [
      <span key={1}> Changed on </span>,
      <span key={2} className="classTest">
        {date.toLocaleDateString("en-GB")}
      </span>,
    ],
    comments: <div className={classNames.taskItemRowText} dangerouslySetInnerHTML={{ __html: paraHistText }} />,
  };

  return (
    <section key={index} className={classNames.taskitemRow}>
      <ActivityItem {...activityItemComment} key={paraHistoryRow.order} className={classNames.taskItemRowItem} />
    </section>
  );
};
export default HistParaItemTextRow;
