import { getTheme, Icon, IIconProps, ITheme, mergeStyleSets } from "@fluentui/react";
// import { Icon, IIconProps } from "office-ui-fabric-react";
import * as React from "react";
import { useMemo } from "react";

interface ILimaInfoBitProps {
  type: LIMAINFOBITTYPE;
  count: number;
}

export enum LIMAINFOBITTYPE {
  MESSAGE,
  ATTACHMENT,
  LINKEDITEM,
  POINTINGTO,
}

const messageIcon: IIconProps = { iconName: "Message" };
const attachIcon: IIconProps = { iconName: "Attach" };
const linkedIcon: IIconProps = { iconName: "MiniLink" };
const pontingIcon: IIconProps = { iconName: "FileSymlink" };
// const unknowIcon: IIconProps = { iconName: "Attach" };

const theme: ITheme = getTheme();
const { fonts } = theme;
const classNames = mergeStyleSets({
  infoBitBase: [
    fonts.smallPlus,
    {
      // paddingLeft: 10,
      paddingRight: 5,
      cursor: "pointer",
    },
  ],
  infoBitText: {},
  infoBitIcon: { color: "#8E8E93", padding: 5 },
});

const LimaInfoBit: React.FunctionComponent<ILimaInfoBitProps> = ({ type, count }: ILimaInfoBitProps) => {
  const iconProps = useMemo((): IIconProps => {
    switch (type) {
      case LIMAINFOBITTYPE.MESSAGE:
        return messageIcon;
      case LIMAINFOBITTYPE.ATTACHMENT:
        return attachIcon;
      case LIMAINFOBITTYPE.LINKEDITEM:
        return linkedIcon;
      case LIMAINFOBITTYPE.POINTINGTO:
        return pontingIcon;
    }
  }, [type]);

  return (
    <div className={classNames.infoBitBase}>
      <Icon
        {...iconProps}
        className={classNames.infoBitIcon}
        // iconName="Home"
        // iconProps={{ iconName: "Home" }}
        // style={{ paddingLeft: 10, paddingRight: 10, cursor: "pointer", color: "#8E8E93" }}
        // disabled={!isItemOwner}}
      />
      <span className={classNames.infoBitText}>{count}</span>
    </div>
  );
};

export default LimaInfoBit;
