import React = require("react");
import { ReactElement, useEffect, useState, useMemo } from "react";
import { IStackTokens, Stack } from "@fluentui/react";
import { FormProvider, useForm } from "react-hook-form";
import { limaLogTag } from "../../../limaCommon/limaLog";
import { log } from "missionlog";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import limaUsersStore from "../../../store/limaUsersStore";
import moment = require("moment");
import TaskItemBasicV4 from "./components/TaskItemBasicV4";
import { TaskData, XCOPY_TYPE, dropdownControlledTaskStateOptions } from "../../../types/Task";
import ItemLinkingV4 from "./components/ItemLinkingV4";
import limaStore from "../../../store/limaStore";
import { TaskItemDebugInfo } from "../../Menu/Edit/Tasks/components/TaskItemDebugInfo";
import { isTaskCreator } from "../../../limaCommon/limaTaskHelpers";
import MessageComponent from "../../CommonComponents/Message/MessageComponent";
import { LimaObjectTypesEnum, LIMA_ITEM_TYPE } from "../../../types/BASE_Enums";
import { LimaHideableSection2 } from "../../../limaComponents/LimaHideableSection2";
import TaskHistoryComponent from "../../Menu/Edit/Tasks/components/TaskHistoryComponent";
import AccessComponentV4 from "../Components/Access/AccessComponentV4";
import { observer } from "mobx-react-lite";
import { Task_withActaXcopy, Task_withActaXcopy_clone } from "../../../types/Task_withActaXcopy";
import limaTasksStoreV4 from "../../../store/limaTasksStoreV4";
import { reaction } from "mobx";
import limaTimerStore from "../../../store/limaTimerStore";
import limaActasStore from "../../../store/limaActasStore";
import AttachementComponentV4 from "./Attachment/AttachementComponentV4";
import Task2TaskComponentV4 from "./components/Task2TaskComponentV4";
import { useTranslation } from "react-i18next";

export interface TaskItemV4Props {
  task: Task_withActaXcopy;
  onCallBack?: (task: Task_withActaXcopy) => void;
  enableStore?: (enableStore: boolean) => void;
}

const themedSmallStackTokens: IStackTokens = {
  childrenGap: "s1",
  padding: "s1",
};

const schemaTask = yup.object().shape({
  taskName: yup.string().min(5, "tperrormsg:taskedit.tasknameshort"),
  // .test("warn_checkDuplTaskName", "tperrormsg:taskedit.tasknamealreadyexist", async function (value) {
  //   window.console.log("START TESTIN -------- checkDuplTaskName = ", value);
  //   if (value !== undefined && value !== null && value !== "") {
  //     window.console.log("checkDuplTaskName start");
  //     const isDuplicateExists = await checkDuplicate(value);
  //     window.console.log("isDuplicateExists = ", isDuplicateExists);
  //     return !isDuplicateExists;
  //   }
  //   // WHEN THE VALUE IS EMPTY RETURN `true` by default
  //   return true;
  // }),
});

// function checkDuplicate(valueToCheck) {
//   return new Promise((resolve) => {
//     let isDuplicateExists;
//     window.console.log("checkDuplicate fucntion = ", valueToCheck);
//     setTimeout(() => {
//       // CHECK HERE FOR DUPLICATE
//       if (valueToCheck === "test") {
//         isDuplicateExists = true;
//       } else {
//         isDuplicateExists = false;
//         // resolve(isDuplicateExists);
//       }
//       window.console.log("checkDuplicate finish = ", isDuplicateExists);
//       resolve(isDuplicateExists);
//     }, 3000);
//   });
// }

//------------------------
// INFO:
// Data are stored in MobX temporary task data
//------------------------

export const TaskItemV4 = ({ task, enableStore }: TaskItemV4Props): ReactElement => {
  const { t } = useTranslation();
  const [dataWasChanged, setDataWasChanged] = useState<boolean>(null);
  const isCreator = useMemo(() => isTaskCreator(task), [task]);
  const taskID = useMemo(() => task._key, [task]);
  // const taskDataInner = useMemo(() => task.task_withActaXcopy.taskData, [task.task_withActaXcopy]);
  const [attachementsCount, setAttachementsCount] = useState<number | null>(null);
  const [messageCount, setMessagesCount] = useState<number | null>(null);
  const [linkedTaskCount, setLinkedTaskCount] = useState<number | null>(null);
  const [linkedParasCount, setLinkedParasCount] = useState<number | null>(null);

  //--------------
  // Forms
  //--------------
  const methods = useForm({
    // resolver: zodResolver(validationSchemeTask),
    defaultValues: {
      taskName: task.taskName ? task.taskName : "",
      taskState: task.taskState
        ? task.taskState
        : dropdownControlledTaskStateOptions.find((item) => item.key === "open").key,
      taskAssigned: task.taskAssignedTo?._key ? task.taskAssignedTo._key : limaStore.userId,
      taskDuedate: task.taskDueDate ? moment(task.taskDueDate).toDate() : "",
      taskDescription: task.taskDescription ? task.taskDescription : "",
    },
    resolver: yupResolver(schemaTask),
    mode: "onSubmit",
    //reValidateMode: "onChange",
    // criteriaMode: "all",
  });
  const {
    // register,
    // handleSubmit,
    // control,
    setValue,
    // trigger,
    // getValues,
    // setError,
    watch,
    formState: { errors, isValid },
  } = methods;

  const valueOfTest = watch();
  //--------------
  // Reactions
  //--------------

  useEffect(() => {
    log.info(limaLogTag.TaskItem, `UEF taskItem: `, task);

    //CORRECTIONS
    if (task.taskState === undefined || task.taskState === null || task.taskState === "") task.taskState = "open";
    //setup new temp edit data
    limaTasksStoreV4.setEditTask_withActaXcopy(Task_withActaXcopy_clone(task));
    //set default values
    setDataWasChanged(false);
    // if (enableStore !== undefined) enableStore(false);
    //update usersListIfNeeded
    void limaUsersStore.checkLastRead().then(() => {
      log.info(limaLogTag.TaskItem, `check last readed user list: `, task, limaTasksStoreV4.editTask_withActaXcopy);
      if (limaTasksStoreV4.editTask_withActaXcopy.taskAssignedTo._key !== null) {
        setValue("taskAssigned", limaTasksStoreV4.editTask_withActaXcopy.taskAssignedTo._key);
      } else {
        setValue("taskAssigned", limaStore.userId);
      }
    });
    void limaActasStore.checkLastRead().then(() => {
      log.info(limaLogTag.TaskItem, `check last readed acta acta loaded checked: `, task);
    });

    if (limaTasksStoreV4.editTask_withActaXcopy !== null) {
      setAttachementsCount(limaTasksStoreV4.editTask_withActaXcopy.taskData.attachements.length);
      setLinkedParasCount(limaTasksStoreV4.editTask_withActaXcopy.taskData.paraIds.length);
    }

    //setAssignedUserOptions(limaUsersStore.getUsersListForAssignIncludeMe);

    const updateTaskStoreReactionDisposer = reaction(
      () => limaTasksStoreV4.editTask_withActaXcopy,
      () => {
        log.info(limaLogTag.TaskItem, `UEF: reaction on LimaTaskStrore Start`, isValid);
        log.info(limaLogTag.TaskItem, `UEF: reaction on LimaTaskStrore Edit`, limaTasksStoreV4.editTask_withActaXcopy);
        // checkIsSame();
        if (limaTasksStoreV4.isSameAsEditTask(task)) {
          //do nothing
          log.info(limaLogTag.TaskItem, `UEF: reaction on LimaTaskStrore Editast: are same`, task);
          //console.log("EDIT TASK Are same", editTask);
          if (dataWasChanged !== false) setDataWasChanged(false);
        } else {
          log.info(
            limaLogTag.TaskItem,
            `MobxReact: reaction on LimaTaskStrore Editast: are not same`,
            limaTasksStoreV4.editTask_withActaXcopy
          );
          if (dataWasChanged !== true) setDataWasChanged(true);
          //console.log("I'm not hungry!");
        }
      },
      { delay: 500 }
    );

    return () => {
      updateTaskStoreReactionDisposer();
    };
  }, []);

  useEffect(() => {
    if (task?.taskData?.actaKey !== null && task?.taskData?.actaKey !== "") {
      log.info(limaLogTag.TaskItem, `UEF: Set active timer to actakez from task ${task.taskData.actaKey}`, task);
      limaTimerStore.setCurrentActivePartion({
        itemId: task._key,
        type: LimaObjectTypesEnum.TASK,
        itemMainCopyId: task.taskData.actaKey,
        userKey: limaStore.userId,
        _key: null,
      });
    }
    return () => {
      limaTimerStore.setPreviousActivePartion();
    };
  }, [task._key]);

  //TODO: Future validation on erros
  // useEffect(() => {
  //   // window.console.log(`UEF errors`, errors);
  //   log.debug(limaLogTag.TaskItem, `UEF errors `, errors);
  //   if (isValid) setIsValidWarns(true);
  //   else {
  //     errors.taskName &&
  //       Object.entries(errors.taskName.message).forEach(([type, message]: [string, string]) => {
  //         window.console.log(`UEF errors mm: '${type}'-${message}`, errors);
  //       });
  //     errors.taskName && setIsValidWarns(false);
  //   }
  // }, [valueOfTest.taskName]);

  useEffect(() => {
    // window.console.log(`UEF errors`, errors);
    log.debug(limaLogTag.TaskItem, `UEF taskDueDate `, valueOfTest.taskDuedate);
    limaTasksStoreV4.setEditTask_withActaXcopy({
      ...limaTasksStoreV4.editTask_withActaXcopy,
      taskDueDate: moment(valueOfTest.taskDuedate).toISOString(),
    });
  }, [valueOfTest.taskDuedate]);

  useEffect(() => {
    // window.console.log(`UEF errors`, errors);
    log.debug(limaLogTag.TaskItem, `UEF taskAssigned `, valueOfTest.taskDuedate);
    limaTasksStoreV4.setEditTask_withActaXcopy({
      ...limaTasksStoreV4.editTask_withActaXcopy,
      taskAssignedTo: {
        ...limaTasksStoreV4.editTask_withActaXcopy.taskAssignedTo,
        _key: valueOfTest.taskAssigned,
      },
    });
  }, [valueOfTest.taskAssigned]);

  useEffect(() => {
    // window.console.log(`UEF errors`, errors);
    log.debug(limaLogTag.TaskItem, `UEF taskAssigned `, valueOfTest.taskDuedate);
    limaTasksStoreV4.setEditTask_withActaXcopy({
      ...limaTasksStoreV4.editTask_withActaXcopy,
      taskState: valueOfTest.taskState,
    });
  }, [valueOfTest.taskState]);

  useEffect(() => {
    // window.console.log(`UEF errors`, errors);
    log.debug(limaLogTag.TaskItem, `UEF updateReady change `, dataWasChanged, isValid, errors);
    if (enableStore !== undefined) {
      if (isValid) {
        enableStore(dataWasChanged);
      } else {
        enableStore(false);
      }
    }

    // if(updateReady! == isChanged)  enableStoreState(isChanged);
  }, [dataWasChanged, isValid]);

  //-------------------------
  //---- DEBAUNCERS    ------
  //-------------------------

  //-------------------------
  //----HANDLERS-------------
  //-------------------------

  const updateTaskData = (newTaskData: TaskData) => {
    limaTasksStoreV4.setEditTask_withActaXcopy({
      ...limaTasksStoreV4.editTask_withActaXcopy,
      taskData: newTaskData,
    });
  };

  //-------------------------
  //----OBSERVERS-------------
  //-------------------------

  const LinkingObserver = observer(() => (
    <ItemLinkingV4
      itemData={limaTasksStoreV4.editTask_withActaXcopy?.taskData && limaTasksStoreV4.editTask_withActaXcopy.taskData}
      itemKey={limaTasksStoreV4.editTask_withActaXcopy?._key && limaTasksStoreV4.editTask_withActaXcopy._key}
      isItemOwner={limaTasksStoreV4.editTask_withActaXcopy?.cIssuedBy == limaStore.userId}
      // enableXcopySetup={taskCreator}
      // enableDocLinking={taskCreator}
      // disableNoCopy={false}
      enableAlwaysLinkParts={task.taskData.xcopytype !== XCOPY_TYPE.TRIMXCOPY ? true : false}
      onlyCreateXcopy={false}
      itemsLinkingCallBack={(newTaskData: TaskData) => {
        log.info(limaLogTag.TaskItem, "TaskLinking setTask callback :SetData ", newTaskData);
        updateTaskData(newTaskData);
        //setTaskData({ ...taskData, taskData: { ...newTaskData } });
      }}
    />
  ));

  const TaskItemDebuf_inner = observer(() => {
    if (limaTasksStoreV4.editTask_withActaXcopy) {
      return <TaskItemDebugInfo taskData={limaTasksStoreV4.editTask_withActaXcopy} taskCreator={isCreator} />;
    } else {
      return <></>;
    }
  });

  return (
    <FormProvider {...methods}>
      {/* <form onSubmit={handleSubmit(onStoreTaskClick)}> */}
      <form>
        {/* {dataWasChanged ? "update" : "dissable"}
        {isValid ? "VALID" : "SOM ERRO"} */}
        <Stack tokens={themedSmallStackTokens}>
          <Stack.Item>
            <TaskItemDebuf_inner />
          </Stack.Item>
          <Stack.Item>
            <TaskItemBasicV4 task={limaTasksStoreV4.editTask_withActaXcopy} />
          </Stack.Item>
          <Stack.Item>
            {/* <Stack.Item> */}
            <LimaHideableSection2 headerCount={String(linkedParasCount)} name="Linking" addEnabled={false}>
              <LinkingObserver />
            </LimaHideableSection2>
            <LimaHideableSection2
              headerCount={messageCount !== null ? String(messageCount) : "-"}
              name={t("tptasks:taskedit.taskitem.messagesPivotLbl")}
              passShowHideToContent={true}
              addEnabled={false}
              isDisabled={taskID === null}
            >
              {/* {limaTasksStoreV4.editTask_withActaXcopy && ( */}
              <MessageComponent
                hidable={false}
                linkedId={taskID}
                linkedType={LIMA_ITEM_TYPE.TASK}
                //onAddMessageClb={() => setMessagesCount(messagesCount + 1)}
                onMsgCountClb={(count: number) => setMessagesCount(count)}
              />
            </LimaHideableSection2>
            <LimaHideableSection2
              headerCount={attachementsCount !== null ? String(attachementsCount) : "-"}
              name={t("tptasks:taskedit.taskitem.attachmentsPivotLbl")}
              addEnabled={false}
              isDisabled={taskID === null}
            >
              {limaTasksStoreV4.editTask_withActaXcopy && (
                <AttachementComponentV4
                  attKeys={limaTasksStoreV4.editTask_withActaXcopy.taskData.attachements}
                  // setUnsavedChanges={setUnsavedChanges}
                  onTaskAttCountClb={(attCount: number) => {
                    setAttachementsCount(attCount);
                  }}
                  setTaskDataAttCallBack={(newAtts: string[]) => {
                    log.info(limaLogTag.TaskItem, "setTaskDataAttCallBack: ", newAtts);
                    updateTaskData({
                      ...limaTasksStoreV4.editTask_withActaXcopy.taskData,
                      attachements: newAtts,
                    });
                  }}
                  enableUploadSingleFile={true}
                  dropdownCleanup={0}
                />
              )}
            </LimaHideableSection2>
            <LimaHideableSection2
              //name="Connected tasks"
              name={t("tptasks:taskedit.taskitem.tasksConnectedPivotLbl")}
              headerCount={linkedTaskCount !== null && linkedTaskCount !== undefined ? String(linkedTaskCount) : "-"}
              passShowHideToContent={true}
              addEnabled={false}
              isDisabled={taskID === null}
            >
              {limaTasksStoreV4.editTask_withActaXcopy && (
                <Task2TaskComponentV4
                  taskId={limaTasksStoreV4.editTask_withActaXcopy._key}
                  updateTaskLinkedCount={(count: number) => {
                    log.info(limaLogTag.TaskItem, "updateTaskLinkedCount: ", count);
                    setLinkedTaskCount(count);
                  }}
                />
              )}
            </LimaHideableSection2>
            <LimaHideableSection2
              // name="Access"
              name={t("tptasks:taskedit.taskitem.accessPivotLbl")}
              addEnabled={false}
              isDisabled={taskID === null}
            >
              {limaTasksStoreV4.editTask_withActaXcopy && (
                <AccessComponentV4 itemId={limaTasksStoreV4.editTask_withActaXcopy._key} type={LIMA_ITEM_TYPE.TASK} />
              )}
            </LimaHideableSection2>
            <LimaHideableSection2
              //name="History"
              name={t("tptasks:taskedit.taskitem.historyPivotLbl")}
              addEnabled={false}
              isDisabled={taskID === null}
            >
              {limaTasksStoreV4.editTask_withActaXcopy && (
                <TaskHistoryComponent taskId={limaTasksStoreV4.editTask_withActaXcopy._key} />
              )}
            </LimaHideableSection2>
          </Stack.Item>
        </Stack>
      </form>
    </FormProvider>
  );
};
