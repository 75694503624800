import { log } from "missionlog";
import {
  urlActaInsertDoc,
  urlActaInsertDocTS,
  urlActaInsertDocVersion,
  urlCheckActaOwnerGet,
  urlCheckXcopyUserGet,
} from "../api/apilinks";
import { httpGetAuth, HttpResponse } from "../api/httpAPI";
import limaLoadeStore, { LoaderItemEnd, LoaderListItem } from "../store/limaLoaderStore";
import limaStore, { OPENEDFILETYPE_ENUM } from "../store/limaStore";
import { CheckActaOwner } from "../types/Acta";
import { LimaDocData, LimaDocTypeENUM } from "../types/docxData";
import { XCOPY_TYPE } from "../types/Task";
import { insertDoc, InsertDocument_xcopy } from "./limaInsertDoc";
import { limaLogTag } from "./limaLog";
import { getXMLPartsInNamespace } from "./limaWordInteract";
// import { getActaXcopyFile } from "./limaXCopyFile";

/**
 * Base LimactaFileOpenHandler used for opening files from limacta
 *
 *
 * @param fileId Id of opended Acta
 * @param fileName Name of opened Acta .. not needed
 * @param xCopyId Xcopy ID
 * @param timeStamp timestamp
 * @param xcopyName
 * @param xcopyType
 * @param withoutLoadingFile if TRUE than olny set is processed but not loaded
 * @param setMenuToFile update menu for current file
 * @param openInNewWindow identify if document should be open in new word window
 * @param runLimaOnOpenNewWindow NYI if true, limacta plugin will be autimaticalz opened
 */
export const onFileOpenHandlerWrapper = async (
  fileId: string,
  fileName: string,
  xCopyId?: string,
  timeStamp?: string,
  xcopyName?: string,
  xcopyType?: XCOPY_TYPE,
  withoutLoadingFile?: boolean,
  setMenuToFile?: boolean,
  openInNewWindow?: boolean,
  runLimaOnOpenNewWindow?: boolean
): Promise<void> => {
  log.info(
    limaLogTag.LimaFileOpen,
    `onFileClickHandler: start loading actaid[${fileId}] xcopyId[${xCopyId}]`,
    timeStamp,
    xcopyType,
    openInNewWindow,
    runLimaOnOpenNewWindow
  );
  if (withoutLoadingFile !== undefined && withoutLoadingFile === true) {
    log.info(limaLogTag.LimaFileOpen, `onFileClickHandler: withoutLoadingFile`);
    if (openInNewWindow !== undefined && openInNewWindow === false) {
      limaStore.setActa(fileId);
      limaStore.setXcopyType(xcopyType);
      limaStore.setActiveOpenType_setup(fileId, xCopyId, xcopyType, timeStamp);
      limaStore.setIsOwner();
    }
  } else {
    //await FileOpenHandler(fileId, fileName, limaStore.userId, xCopyId, timeStamp, xcopyName, xcopyType).then(
    await FileOpenHandler(
      fileId,
      fileName,
      limaStore.userId,
      xCopyId,
      timeStamp,
      xcopyType,
      openInNewWindow,
      runLimaOnOpenNewWindow
    ).then((dataFromLoading: LimaDocData) => {
      log.info(limaLogTag.LimaFileOpen, `onFileClickHandler: finish loadeng`);
      log.info(limaLogTag.LimaFileOpen, `onFileClickHandler: finish xcopyType:`, xcopyType);
      log.info(limaLogTag.LimaFileOpen, `onFileClickHandler: finish xCopyId:`, xCopyId);
      log.info(limaLogTag.LimaFileOpen, `onFileClickHandler: finish timestamp:`, timeStamp);
      if (openInNewWindow !== undefined && openInNewWindow === false) {
        limaStore.setActa(dataFromLoading.limactaDocID);
        limaStore.setXcopyType(xcopyType);
        limaStore.setActiveOpenType_setup(fileId, xCopyId, xcopyType, timeStamp);
        limaStore.setIsOwner();
      }
    });
  }
  limaStore.setWordIdentReplaced(true);
  //Process setting after loadin
  //  IF loading XCOPY
  if (xCopyId !== undefined && xCopyId !== null && xCopyId !== "") {
    log.info(limaLogTag.LimaFileOpen, `onFileClickHandler: looks like its copy`);
    if (openInNewWindow !== undefined && openInNewWindow === false) {
      limaStore.setXcopyId(xCopyId);
      limaStore.setDocTimeStamp(timeStamp);
      log.info(limaLogTag.LimaFileOpen, `onFileClickHandler: looks like its copy`, limaStore.xcopyId);
      xcopyName && limaStore.setXcopyName(xcopyName);
      log.info(limaLogTag.LimaFileOpen, `onFileClickHandler: looks like its copy`, limaStore.xcopyName);
      if (xcopyType === XCOPY_TYPE.MAINXCOPY) {
        limaStore.setActiveOpenType(OPENEDFILETYPE_ENUM.MAIN_COPY);
      } else {
        limaStore.setActiveOpenType(OPENEDFILETYPE_ENUM.COPY);
      }
      log.info(limaLogTag.LimaFileOpen, `onFileClickHandler: looks like its copy`, limaStore.xcopyType);
    }
  }
  //IF loading VERSION
  else if (timeStamp !== undefined) {
    log.info(limaLogTag.LimaFileOpen, `onFileClickHandler: looks like its version or ACTA with timestamp`);
    if (openInNewWindow !== undefined && openInNewWindow === false) {
      limaStore.setDocTimeStamp(timeStamp);
      if (xcopyType != undefined && xcopyType === XCOPY_TYPE.MAINXCOPY) {
        limaStore.setActiveOpenType(OPENEDFILETYPE_ENUM.ACTA);
      } else {
        limaStore.setActiveOpenType(OPENEDFILETYPE_ENUM.VERSION);
      }
    }
  }
  //IF loading FILE
  else {
    log.info(limaLogTag.LimaFileOpen, `onFileClickHandler: looks like its ACTA`);
    if (openInNewWindow !== undefined && openInNewWindow === false) {
      limaStore.setDocTimeStamp("");
      limaStore.setActiveOpenType(OPENEDFILETYPE_ENUM.ACTA);
    }
  }
  //void onFileClickHandler(fileitemid, actaName, mainXcopyId, null, null, XCOPY_TYPE.MAINXCOPY, false, );
  if (setMenuToFile !== undefined && setMenuToFile === true) {
    log.info(limaLogTag.LimaFileOpen, `onFileClickHandler: setMenuToFile `, fileId, fileName, xcopyType, xCopyId);
    limaStore.setMenuFile(xCopyId, `${fileName}`, xcopyType);
  }
};

/**
 * Processing open document
 * @param actaId
 * @param actaName
 * @param userId
 * @param xCopyId
 * @param timeStamp
 * @param xcopyName
 * @param xcopyType
 * @param openInNewWindow identify if document should be open in new word window
 */
export const FileOpenHandler = async (
  actaId: string,
  actaName: string,
  userId?: string,
  xCopyId?: string,
  timeStamp?: string,
  xcopyType?: XCOPY_TYPE,
  openInNewWindow?: boolean,
  runLimaOnOpenNewWindow?: boolean
): Promise<LimaDocData> => {
  if (limaStore.docxIsLoading) {
    log.warn(limaLogTag.LimaFileOpen, "FileOpenHandler: another doc is still loading ");
  }

  const loaderID = limaLoadeStore.add(`getting acta item '${actaName}'[${actaId}]`);
  const out: LimaDocData = { limactaDocID: actaId, limactaDocName: actaName, limactaDocType: LimaDocTypeENUM.none };
  log.info(
    limaLogTag.LimaFileOpen,
    `FileOpenHandler: acta item '${actaName}'[${actaId}] and userId'${userId}'`,
    userId,
    xCopyId,
    timeStamp
  );
  limaStore.setDocxIsLoading(true);
  try {
    //IF loading XCOPY
    window.console.log("TEST >>>>> LOADING COPY >>>>>>>>>>>>>>>>>>");
    if (xCopyId !== undefined && xCopyId !== null && xCopyId !== "") {
      await InsertDocument_xcopy(
        xCopyId,
        urlActaInsertDocVersion(
          actaId,
          xCopyId,
          userId !== undefined ? userId : limaStore.userId,
          timeStamp,
          runLimaOnOpenNewWindow
        ),
        loaderID,
        "",
        openInNewWindow
      ).then(() => {
        out.limactaDocType = LimaDocTypeENUM.XCOPY;

        log.info(limaLogTag.LimaFileOpen, "FileOpenHandler: getXMLPartsInNamespace start");
        void getXMLPartsInNamespace().then(() => {
          log.info(limaLogTag.LimaFileOpen, "FileOpenHandler: getXMLPartsInNamespace procesed");
        });
        limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      });
    }
    //IF loading VERSION
    else if (timeStamp !== undefined) {
      window.console.log("TEST >>>>> LOADING VERSION >>>>>>>>>>>>>>>>>>");
      await insertDoc(
        actaId,
        urlActaInsertDocTS(actaId, userId !== undefined ? userId : limaStore.userId, timeStamp),
        actaName,
        LimaDocTypeENUM.ACTA,
        (data: LimaDocData) => {
          out.limactaDocType = LimaDocTypeENUM.VERSION;
          out.limactaDocID = data.limactaDocID;
          // limaStore.setActaId(data.limactaDocID);
          // limaStore.setActaName(fileName);
          // // limaStore.setActa(data.limactaDocID);
          // limaStore.setDocTimeStamp(timeStamp);
          if (openInNewWindow !== undefined && openInNewWindow === false) {
            if (xcopyType != undefined && xcopyType === XCOPY_TYPE.MAINXCOPY) {
              limaStore.setActiveOpenType(OPENEDFILETYPE_ENUM.ACTA);
            } else {
              limaStore.setActiveOpenType(OPENEDFILETYPE_ENUM.VERSION);
            }
          }
          log.info(limaLogTag.LimaFileOpen, "FileOpenHandler: getXMLPartsInNamespace start");
          void getXMLPartsInNamespace().then(() => {
            log.info(limaLogTag.LimaFileOpen, "FileOpenHandler: getXMLPartsInNamespace procesed");
          });
        },
        () => {
          limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
        },
        loaderID,
        openInNewWindow
      );
    }
    //IF loading FILE
    else {
      window.console.log("TEST >>>>> LOADING FILE >>>>>>>>>>>>>>>>>>");
      await insertDoc(
        actaId,
        urlActaInsertDoc(actaId, userId !== undefined ? userId : limaStore.userId),
        actaName,
        LimaDocTypeENUM.ACTA,
        (data: LimaDocData) => {
          out.limactaDocType = LimaDocTypeENUM.ACTA;
          out.limactaDocID = data.limactaDocID;
          // limaStore.setActa(data.limactaDocID);
          // limaStore.setDocTimeStamp("");
          // limaStore.setActiveOpenType(OPENEDFILETYPE_ENUM.ACTA);
          log.info(limaLogTag.LimaFileOpen, "FileOpenHandler: getXMLPartsInNamespace start");
          void getXMLPartsInNamespace().then(() => {
            log.info(limaLogTag.LimaFileOpen, "FileOpenHandler: getXMLPartsInNamespace procesed");
          });
          limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
        },
        () => {
          limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
        },
        loaderID,
        openInNewWindow
      );
    }
  } catch (error) {
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    log.error(limaLogTag.LimaFileOpen, "FileOpenHandler: getting error", error);
  } finally {
    limaStore.setDocxIsLoading(false);
    return out;
  }
};

export const checkDocOwner = async (
  actaId: string,
  userId: string,
  xcopyId: string,
  loaderID: LoaderListItem
): Promise<CheckActaOwner> => {
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  // return new Promise<CheckActaOwner>(async (resolve, reject) => {
  if (userId === "") {
    log.warn(limaLogTag.LimaFileOpen, `checkDocOwner: userid is "" [${userId}] - not working`);
    // throw new Error('No reason but to reject');
    return Promise.reject();
  }
  if (actaId === "" && xcopyId === "") {
    log.warn(
      limaLogTag.LimaFileOpen,
      `checkDocOwner: actaID and xcopyIS are both "" [${actaId}] [${xcopyId}]- not working`
    );
    // throw new Error('No reason but to reject');
    return Promise.reject();
  }
  let response: HttpResponse<CheckActaOwner>;
  try {
    if (actaId !== "") response = await httpGetAuth<CheckActaOwner>(urlCheckActaOwnerGet(actaId), loaderID.ac);
    else response = await httpGetAuth<CheckActaOwner>(urlCheckXcopyUserGet(xcopyId), loaderID.ac);
    if ("parsedBody" in response) {
      log.info(limaLogTag.LimaFileOpen, "checkDocOwner: looks like get some data ", response.parsedBody);
      // let taskDataJSON: taskItemData = map2TaskItem(this.editTaskData.taskData);
      return Promise.resolve(response.parsedBody);
    } else {
      log.error(limaLogTag.LimaServerVersion, `checkDocOwner: no data`);
      return Promise.reject();
    }
  } catch (error) {
    log.error(limaLogTag.LimaServerVersion, `checkDocOwner: Error call get versions`, error);
    return Promise.reject();
  }
  // });
  // urlCheckActaOwnerGet
};
