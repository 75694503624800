/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { log } from "missionlog";
import { WORDITEMTYPE } from "../api/apilinks";
import limaStore from "../store/limaStore";
import { SelectedInWord } from "../types/wordObjects";
import { DEF_addBkmID, DEF_ccPrefix, DEF_comBkmID, DEF_delBkmID, DEF_parBkmID } from "./limaEnv";
import { limaLogTag } from "./limaLog";

/**
 * Method that highlight or hide highligt on selected bookmarks
 * @param bkmrks list of bookmarks to highlight
 * @param show boolena show or hide highligts
 *
 * @author MS
 */
// export async function highlightTaskLinks(bkmrks: string[], show: boolean) {
//   Word.run(function (context) {
//     const bookmarkRange = [];

//     for (let i = 0; i < bkmrks.length; i++) {
//       //@ts-ignore
//       bookmarkRange[i] = context.document.getBookmarkRangeOrNullObject(bkmrks[i]);
//       // console.log("The bookmarks selection " + bkmrks[i]);
//       log.info(limaLogTag.LimaWordInteract, "highlightTaskLinks: The bookmarks selection " + bkmrks[i]);

//       if (show) {
//         // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
//         bookmarkRange[i].font.highlightColor = "#FFFF00";
//         //bookmarkRange[i].font.bold = true;
//       } else {
//         // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
//         bookmarkRange[i].font.highlightColor = null;
//         //bookmarkRange[i].font.bold = false;
//       }
//     }
//     // eslint-disable-next-line @typescript-eslint/no-empty-function
//     return context.sync().then(function () {});
//   }).catch(function (error) {
//     // console.log("Error: " + JSON.stringify(error));
//     log.error(limaLogTag.LimaWordInteract, "highlightTaskLinks: Error info :", JSON.stringify(error));
//     if (error instanceof OfficeExtension.Error) {
//       // console.log("Debug info: " + JSON.stringify(error.debugInfo));
//       log.error(limaLogTag.LimaWordInteract, "highlightTaskLinks: Debug info :", JSON.stringify(error.debugInfo));
//     }
//   });
// }

/**
 * Method that highlight or hide highligt on selected bookmarks
 * @param bkmrks list of bookmarks to highlight
 * @param show boolena show or hide highligts
 *
 * @author MS
 */
// export async function highlightTaskLinks2(items: SelectedInWord[], show: boolean) {
//   const bkmrks: string[] = items.map((item: SelectedInWord) => {
//     if (item.type === WORDITEMTYPE.para) return DEF_parBkmID + "_" + item.id;
//     else if (item.type === WORDITEMTYPE.comm) return DEF_comBkmID + "_" + item.id;
//     return null;
//   });

//   Word.run(function (context) {
//     const bookmarkRange = [];
//     OfficeExtension.config.extendedErrorLogging = true;
//     const documentWhole = context.document.body.getRange("Whole");
//     console.log("The bookmarks ");
//     //@ts-ignore
//     const bookmarks = context.document.Bookmarks;
//     console.log("The bookmarks 2");
//     if (show) {
//       documentWhole.font.highlightColor = "#000000";
//     } else {
//       documentWhole.font.highlightColor = null;
//     }
//     return context
//       .sync()
//       .then(() => {
//         console.log("The bookmarks ", bookmarks);
//         for (let i = 0; i < bkmrks.length; i++) {
//           //@ts-ignore
//           bookmarkRange[i] = context.document.getBookmarkRangeOrNullObject(bkmrks[i]);
//           // console.log("The bookmarks selection " + bkmrks[i]);
//           log.info(limaLogTag.LimaWordInteract, "highlightTaskLinks: The bookmarks selection " + bkmrks[i]);

//           if (show) {
//             bookmarkRange[i].font.highlightColor = "#C0C0C0";
//           } else {
//             bookmarkRange[i].font.highlightColor = null;
//           }
//         }

//         // eslint-disable-next-line @typescript-eslint/no-empty-function
//         return context.sync().then(function () {});
//       })
//       .catch(function (error) {
//         // console.log("Error: " + JSON.stringify(error));
//         log.error(limaLogTag.LimaWordInteract, "highlightTaskLinks - inner: Error info :", JSON.stringify(error));
//         if (error instanceof OfficeExtension.Error) {
//           // console.log("Debug info: " + JSON.stringify(error.debugInfo));
//           log.error(
//             limaLogTag.LimaWordInteract,
//             "highlightTaskLinks - inner: Debug info :",
//             JSON.stringify(error.debugInfo)
//           );
//         }
//       });
//   }).catch(function (error) {
//     // console.log("Error: " + JSON.stringify(error));
//     log.error(limaLogTag.LimaWordInteract, "highlightTaskLinks: Error info :", JSON.stringify(error));
//     if (error instanceof OfficeExtension.Error) {
//       // console.log("Debug info: " + JSON.stringify(error.debugInfo));
//       log.error(limaLogTag.LimaWordInteract, "highlightTaskLinks: Debug info :", JSON.stringify(error.debugInfo));
//     }
//   });
// }

/**
 * Method that highlight or hide highligt on selected bookmarks
 * @param bkmrks list of bookmarks to highlight
 * @param show boolena show or hide highligts
 *
 * @author MS
 */
export enum HIGHLIGHT_TYPE {
  HIGHLIGHT,
  TRIM,
}
export async function highlightTaskLinks3(
  items: SelectedInWord[],
  show: boolean,
  type: HIGHLIGHT_TYPE,
  scrollToFirst?: boolean
) {
  log.info(limaLogTag.LimaWordInteract, "highlightTaskLinks3: The bookmarks selection 1 ", limaStore.bmksList);
  const bkmrksToShow: string[] = items.map((item: SelectedInWord) => {
    if (item.type === WORDITEMTYPE.para) return DEF_parBkmID + "_" + item.id;
    else if (item.type === WORDITEMTYPE.comm) return DEF_comBkmID + "_" + item.id;
    return null;
  });

  log.info(limaLogTag.LimaWordInteract, "highlightTaskLinks3: The bookmarks selection 2 ", bkmrksToShow);

  let scrolled = false;

  await Word.run(function (context) {
    const bookmarkRange = [];
    log.info(limaLogTag.LimaWordInteract, "highlightTaskLinks3: The bookmarks selection 3 ", bkmrksToShow);
    for (let i = 0; i < limaStore.bmksList.length; i++) {
      log.info(limaLogTag.LimaWordInteract, "highlightTaskLinks3: The bookmarks selection 4a ", bkmrksToShow);
      //@ts-ignore
      bookmarkRange[i] = context.document.getBookmarkRangeOrNullObject(limaStore.bmksList[i]);
      log.info(limaLogTag.LimaWordInteract, "highlightTaskLinks3: The bookmarks selection 4b ", limaStore.bmksList[i]);

      if (show) {
        if (type == HIGHLIGHT_TYPE.TRIM && bkmrksToShow.indexOf(limaStore.bmksList[i]) == -1)
          bookmarkRange[i].font.highlightColor = "#000000";
        else if (type == HIGHLIGHT_TYPE.HIGHLIGHT && bkmrksToShow.indexOf(limaStore.bmksList[i]) != -1)
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          bookmarkRange[i].font.highlightColor = "#FFFF00";
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        else bookmarkRange[i].font.highlightColor = null;

        if (
          scrolled != true &&
          scrollToFirst != undefined &&
          scrollToFirst == true &&
          bkmrksToShow.indexOf(limaStore.bmksList[i]) != -1
        ) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          bookmarkRange[i].select(Word.SelectionMode.start);
          scrolled = true;
        }
      } else {
        bookmarkRange[i].font.highlightColor = null;
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return context.sync().then(function () {});
  }).catch(function (error) {
    // console.log("Error: " + JSON.stringify(error));
    log.error(limaLogTag.LimaWordInteract, "highlightTaskLinks3: Error info :", JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      // console.log("Debug info: " + JSON.stringify(error.debugInfo));
      log.error(limaLogTag.LimaWordInteract, "highlightTaskLinks3: Debug info :", JSON.stringify(error.debugInfo));
    }
  });
}

/**
 * Method to cahnge bookmark text
 * @param bookmark identification of bookmark
 * @param newText new text string
 *
 * @author MS
 */
export async function changeParaText(bookmark: string, newText: string) {
  Word.run(async function (context) {
    //var range = context.document.getSelection();

    //@ts-ignore
    const range = context.document.getBookmarkRangeOrNullObject(bookmark);
    if (range === null) {
      alert("not existing bookmark");
      //error-handling code
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      range.clear();
      await context.sync().then(function () {
        // console.log("Cleared the selection (range object)");
        log.info(limaLogTag.LimaWordInteract, "changeParaText: Cleared the selection (range object)");
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        range.insertText(newText, Word.InsertLocation.start).insertBookmark(bookmark);
      });
    }

    return context.sync().then(function () {
      // console.log("Cleared the selection (range object)");
      log.info(limaLogTag.LimaWordInteract, "changeParaText: Inserted (range object)");
    });
  }).catch(function (error) {
    // console.log("Error: " + JSON.stringify(error));
    log.error(limaLogTag.LimaWordInteract, "changeParaText: Error info :", JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      log.error(limaLogTag.LimaWordInteract, "changeParaText: Debug info :", JSON.stringify(error.debugInfo));
      // console.log("Debug info: " + JSON.stringify(error.debugInfo));
    }
  });
}

/**
 * Get word object CC by its name and return context or null
 * @param name CC name
 * @returns null if found nothing or string with cc text
 *
 * @author MS
 */
export async function getWordCCByName(name: string | null): Promise<string | null> {
  log.info(limaLogTag.LimaWordInteract, `getWordCCByName: name [${name}]`);

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  return new Promise(async (resolve, reject) => {
    Word.run(async (context) => {
      const myContentControls = context.document.contentControls.getByTag(DEF_ccPrefix + name);
      if (myContentControls) {
        context.load(myContentControls, "text");
        void context.sync().then(function () {
          if (myContentControls.items.length === 0) {
            log.info(limaLogTag.LimaWordInteract, `getWordCCByName: found nithing for cc [${name}]`);
            resolve(null);
          } else {
            log.info(
              limaLogTag.LimaWordInteract,
              `getWordCCByName: found cc [${name}] :`,
              myContentControls.items[0].text
            );
            resolve(myContentControls.items[0].text);
          }
        });
      } else {
        log.warn(limaLogTag.LimaWordInteract, `navigateToCC: CC [${name}] DOnt found any`);
        resolve(null);
      }
    }).catch((error) => {
      log.error(limaLogTag.LimaWordInteract, `navigateToCC: error  CC [${name}] `, error);
      reject();
    });
  });

  // return new Promise(async (resolve, reject) => {
  //   await Word.run(async (context) => {
  //     var contentControls = context.document.contentControls.getByTag(DEF_ccPrefix + name);
  //     contentControls.load("text");
  //     return context.sync().then(function () {
  //       if (contentControls.items.length === 0) {
  //         log.info(limaLogTag.LimaWordInteract, `getWordCCByName: found nithing for cc [${name}]`);
  //         resolve(null);
  //       } else {
  //         log.info(limaLogTag.LimaWordInteract, `getWordCCByName: found cc [${name}] :`, contentControls.items[0].text);
  //         resolve(contentControls.items[0].text);
  //       }
  //     });
  //   }).catch((error) => {
  //     //console.log(error);
  //     log.error(limaLogTag.LimaWordInteract, "getWordCCByName: error", error);
  //     reject();
  //     //log.error(tag.FileData, "Action failed. Error: ", error);
  //   });
  // });
  /*    for (let i = 0; i < contentControls.items.length; i++) {
    contentControls.items[i].insertText("Fabrikam", "Replace");
  }

  await context.sync();*/
}

/**
 * Convert selected word part into CC
 * @param name name of cc
 * @author MS
 */
export async function convertSelecedToCC(name: string): Promise<string | null> {
  //setSelected("OJHAL");
  log.info(limaLogTag.LimaWordInteract, `convertToCC: convertin selected to CC [${name}]`);
  return new Promise(async (resolve, reject) => {
    await Word.run(async (context) => {
      const range = context.document.getSelection();
      const wordContentControl = range.insertContentControl();
      wordContentControl.tag = DEF_ccPrefix + name;
      wordContentControl.title = name;
      wordContentControl.cannotEdit = false;
      wordContentControl.appearance = "BoundingBox";
      const html = range.getHtml();
      // const textout = range.text;
      return context.sync().then(function () {
        const text = html.value.replace(/<[^>]*>/g, "");
        // log.info(limaLogTag.LimaWordInteract, `convertSelecedToCC: convertin ofCC [${name}] DONE`, html.value);
        log.info(limaLogTag.LimaWordInteract, `convertSelecedToCC: convertin ofCC [${name}] DONE`, text);
        resolve("");
      });
      // await context.sync();
    }).catch((error) => {
      // console.log(error);
      // log.error(tag.FileData, "Action failed. Error: ", error);
      log.error(limaLogTag.LimaWordInteract, "convertToCC: error", error);
      reject();
    });
  });

  // Office.context.document.getSelectedDataAsync(Office.CoercionType.Text, function(asyncResult) {
  //   if (asyncResult.status == Office.AsyncResultStatus.Failed) {
  //     log.error(tag.FileData, "Action failed. Error: " + asyncResult.error.message);
  //   } else {
  //     log.info(tag.FileData, "Selected data: " + asyncResult.value);
  //   }
  // });
}

/**
 * Convert cc by name back to orfing
 * @param name name of cc
 * @author MS
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function convertBackCCToOrigin(name: string) {
  log.info(limaLogTag.LimaWordInteract, `convertBackCCToOrigin: convertin of CC [${name}]`);
  Word.run(function (context) {
    const myContentControls = context.document.contentControls.getByTag(DEF_ccPrefix + name);
    if (myContentControls) {
      //context.load(myContentControls, "text");
      context.load(myContentControls, "ooxml,html");

      return context.sync().then(async function () {
        const CCrange = myContentControls.items[0].getRange();
        // log.info(
        //   limaLogTag.LimaWordInteract,
        //   `convertBackCCToOrigin: convertin of CC [${name}]`,
        //   myContentControls.items[0].text
        // );
        // log.info(
        //   limaLogTag.LimaWordInteract,
        //   `convertBackCCToOrigin: convertin of CC [${name}] getHtml`,
        //   myContentControls.items[0].getHtml()
        // );
        // log.info(
        //   limaLogTag.LimaWordInteract,
        //   `convertBackCCToOrigin: convertin of CC [${name}] getReviewedText`,
        //   myContentControls.items[0].getReviewedText()
        // );
        // log.info(
        //   limaLogTag.LimaWordInteract,
        //   `convertBackCCToOrigin: convertin of CC [${name}] getRange getHtml`,
        //   myContentControls.items[0].getRange().getHtml()
        // );
        // log.info(
        //   limaLogTag.LimaWordInteract,
        //   `convertBackCCToOrigin: convertin of CC [${name}] getRange getHtml`,
        //   myContentControls.items[0].getRange().getHtml()
        // );
        // log.info(
        //   limaLogTag.LimaWordInteract,
        //   `convertBackCCToOrigin: convertin of CC [${name}] paragraphs getFirst toString`,
        //   myContentControls.items[0].getOoxml()
        // );
        // eslint-disable-next-line prefer-const
        // Queue a set of commands to get the HTML of the first paragraph.
        // eslint-disable-next-line prefer-const
        let html = myContentControls.items[0].getHtml();

        // Synchronize the document state by executing the queued commands,
        // and return a promise to indicate task completion.
        await context.sync();
        log.info(limaLogTag.LimaWordInteract, "Paragraph HTML: " + html.value);
        // eslint-disable-next-line prefer-const
        // let ccOoxml = myContentControls.items[0].getOoxml();
        // await context.sync();
        // log.info(limaLogTag.LimaWordInteract, "Paragraph ccOOXML: " + ccOoxml.value);

        // CCrange.insertHtml(ccOoxml.value, "Before");
        CCrange.insertHtml(html.value, "Before");
        //});

        myContentControls.items[0].delete(true);

        return context.sync().then(function () {
          log.info(limaLogTag.LimaWordInteract, `convertBackCCToOrigin: convertin ofCC [${name}] DONE`);
          // log.info(limaLogTag.LimaWordInteract, "Content control OOXML: ", ccOoxml.value);
          // console.log("Done!");
        });

        // return context.sync().then(function () {
        //   console.log("Content control OOXML: " + ccOoxml.value);
        //   //myContentControls.items[0].insertOoxml(ccOoxml.value, "Before");
        // });
      });
    } else {
      log.warn(limaLogTag.LimaWordInteract, `convertBackCCToOrigin: convertin of CC [${name}] DOnt found any`);
      return null;
    }
  }).catch((error) => {
    log.error(limaLogTag.LimaWordInteract, `convertBackCCToOrigin: error convertin back CC [${name}] `, error);
  });
}

/**
 * Navigate to CC
 * @param name name of cc
 * @author MS
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function navigateToCC(name: string) {
  log.info(limaLogTag.LimaWordInteract, `navigateToCC: convertin of CC [${name}]`);
  Word.run(function (context) {
    const myContentControls = context.document.contentControls.getByTag(DEF_ccPrefix + name);
    if (myContentControls) {
      context.load(myContentControls, "text");
      return context.sync().then(function () {
        log.info(limaLogTag.LimaWordInteract, `navigateToCC: navigate to CC [${name}] DONE`);
        myContentControls.items[0].select();
      });
    } else {
      log.warn(limaLogTag.LimaWordInteract, `navigateToCC: CC [${name}] DOnt found any`);
      return null;
    }
  }).catch((error) => {
    log.error(limaLogTag.LimaWordInteract, `navigateToCC: error  CC [${name}] `, error);
  });
}

/**
 * get document url
 * @returns name of document in type of {name, fullUrl}
 */

export type officeFile = {
  name: string;
  fullUrl: string;
};

export const loadFileName = async (): Promise<officeFile | null> => {
  return new Promise((resolve) => {
    Office.context.document.getFilePropertiesAsync(null, (res) => {
      if (res && res.value && res.value.url) {
        const name = res.value.url.substr(res.value.url.lastIndexOf("\\") + 1);
        resolve({ name: name, fullUrl: res.value.url });
      }
      resolve(null);
    });
  });
};

/**
 * Get custom XMLS from Document
 *
 * not able to use...
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getXMLPartsInNamespace = async () => {
  // eslint-disable-next-line @typescript-eslint/await-thenable
  await Office.context.document.customXmlParts.getByNamespaceAsync("http://limacta.com", function (eventArgs) {
    //Office.context.document.customXmlParts.getByIdAsync("{971ad0f1-bd3b-4b14-92d5-2190a55e26ac}", function (eventArgs) {
    log.info(limaLogTag.LimaWordInteract, "Found " + String(eventArgs.value.length) + " parts with this namespace");
    log.info(limaLogTag.LimaWordInteract, `getXMLPartsInNamespace: data`, eventArgs);
    write("Found " + String(eventArgs.value.length) + " parts with this namespace");
  });
};
// Function that writes to a div with id='message' on the page.
function write(message) {
  // limaStore.setActaBmks(eventArgs.value);
  log.info(limaLogTag.LimaWordInteract, "Found: ", message);
}

// const HIGHLIGHTSTARTCHAR = "\u200E";
// const HIGHLIGHTENDCHAR = "\u200F";
// /**
//  * Select same State
//  * @author MS
//  */
// export async function selectSameState_ORIG(acceptOrReject: boolean): Promise<boolean | null> {
//   //setSelected("OJHAL");
//   log.info(limaLogTag.LimaWordInteract, `selectSameState: selecting same state`);
//   // eslint-disable-next-line @typescript-eslint/no-misused-promises
//   return new Promise(async (resolve, reject) => {
//     Word.run(async function (context) {
//       // context.load(context.document.body, 'text')
//       // var selectedRange = context.document.getSelection().getTextRanges(["\uFEFF"], false);
//       const selectedRange = context.document
//         .getSelection()
//         .getTextRanges([HIGHLIGHTSTARTCHAR, HIGHLIGHTENDCHAR], false);

//       //var selectedRange = context.document.getSelection().getTextRanges([" "], false);
//       context.load(selectedRange, ["text", "font"]);
//       log.info(
//         limaLogTag.LimaWordInteract,
//         `selectSameState: DELETE COLOR >>`,
//         process.env.REACT_APP_STATE_FONT_DELETED_COLOR
//       );
//       log.info(
//         limaLogTag.LimaWordInteract,
//         `selectSameState: IDELETE STRIKE >>`,
//         process.env.REACT_APP_STATE_FONT_DELETED_STRIKE === "true"
//       );
//       log.info(
//         limaLogTag.LimaWordInteract,
//         `selectSameState: ADDED COLOR >>`,
//         process.env.REACT_APP_STATE_FONT_ADDED_COLOR
//       );
//       log.info(
//         limaLogTag.LimaWordInteract,
//         `selectSameState: ADDED STRIKE >>`,
//         process.env.REACT_APP_STATE_FONT_ADDED_STRIKE === "true"
//       );
//       await context.sync().then(async function () {
//         let doSyncOnFinish = false;
//         for (let i = 0; i < selectedRange.items.length; i++) {
//           // console.log("I have something >> ", selectedRange.items[i].text);
//           log.info(
//             limaLogTag.LimaWordInteract,
//             `selectSameState: Search for state on text >>[${selectedRange.items[i].text}]-`,
//             selectedRange.items[i].text
//           );
//           // console.log("I have something >> ", selectedRange.items[i].font.highlightColor);
//           log.info(
//             limaLogTag.LimaWordInteract,
//             `selectSameState: Search for state HL color >>`,
//             selectedRange.items[i].font.highlightColor
//           );
//           // console.log("I have something >> ", selectedRange.items[i].font.strikeThrough);
//           log.info(
//             limaLogTag.LimaWordInteract,
//             `selectSameState: Search for state StrikeThrough >>`,
//             selectedRange.items[i].font.strikeThrough
//           );

//           const text = selectedRange.items[i].text;
//           text.replace(HIGHLIGHTSTARTCHAR, "").replace(HIGHLIGHTENDCHAR, "");

//           //Check Deleted
//           if (
//             selectedRange.items[i].font.highlightColor == process.env.REACT_APP_STATE_FONT_DELETED_COLOR &&
//             selectedRange.items[i].font.strikeThrough == (process.env.REACT_APP_STATE_FONT_DELETED_STRIKE === "true")
//           ) {
//             log.info(limaLogTag.LimaWordInteract, `selectSameState: Text is Deleted`);
//             if (acceptOrReject === true) {
//               log.info(limaLogTag.LimaWordInteract, `selectSameState: Going to remove`);
//               doSyncOnFinish = true;
//               selectedRange.items[i].delete();
//             } else {
//               log.info(limaLogTag.LimaWordInteract, `selectSameState: Going to Let live`);
//               doSyncOnFinish = true;
//               selectedRange.items[i].font.highlightColor = null;
//               selectedRange.items[i].font.strikeThrough = false;
//               //let text = selectedRange.items[i].text.replace(HIGHLIGHTSTARTCHAR, "").replace(HIGHLIGHTENDCHAR, "");
//               selectedRange.items[i].insertText(text, "Replace");
//             }
//           }
//           //Check Added
//           else if (
//             selectedRange.items[i].font.highlightColor == process.env.REACT_APP_STATE_FONT_ADDED_COLOR &&
//             selectedRange.items[i].font.strikeThrough == (process.env.REACT_APP_STATE_FONT_ADDED_STRIKE === "true")
//           ) {
//             log.info(limaLogTag.LimaWordInteract, `selectSameState: Text is Added`);
//             if (acceptOrReject === true) {
//               log.info(limaLogTag.LimaWordInteract, `selectSameState: Going to Let live`);
//               doSyncOnFinish = true;
//               selectedRange.items[i].font.highlightColor = null;
//               selectedRange.items[i].font.strikeThrough = false;
//               //let text = selectedRange.items[i].text.replace(HIGHLIGHTSTARTCHAR, "").replace(HIGHLIGHTENDCHAR, "");
//               selectedRange.items[i].insertText(text, "Replace");
//             } else {
//               log.info(limaLogTag.LimaWordInteract, `selectSameState: Going to remove`);
//               doSyncOnFinish = true;
//               selectedRange.items[i].delete();
//             }
//           } else {
//             log.info(limaLogTag.LimaWordInteract, `selectSameState: Text is origin`);
//             selectedRange.items[i].insertText(text, "Replace");
//           }
//         }
//         if (doSyncOnFinish) {
//           await context
//             .sync()
//             .then(() => {
//               resolve(true);
//             })
//             .catch((error) => {
//               log.error(limaLogTag.LimaWordInteract, "selectSameState: error", error);
//               if (error instanceof OfficeExtension.Error) {
//                 console.log("Debug info: " + JSON.stringify(error.debugInfo));
//               }
//               reject();
//             });
//         }
//         resolve(true);
//       });
//     }).catch((error) => {
//       log.error(limaLogTag.LimaWordInteract, "selectSameState: error", error);
//       if (error instanceof OfficeExtension.Error) {
//         console.log("Debug info: " + JSON.stringify(error.debugInfo));
//       }
//       reject();
//     });
//   });
// }

type BoookMarkObjectSameState = {
  range: Word.Range;
  rangeStart: Word.Range;
  rangeEnd: Word.Range;
  rangeContent: Word.Range;
  addRemove: ADDDELETE;
  bkmname: string;
  ooxml: OfficeExtension.ClientResult<string>;
  html: OfficeExtension.ClientResult<string>;
  secondStep: boolean;
};

export enum ADDDELETE {
  ADD = "add",
  DEL = "dell",
}

export enum ACCEPTREJECT {
  ACCEPT = "accept",
  REJECT = "reject",
}

const replacebookmark = (ooxml: string, replace: string): string => {
  var re = /<w:bookmark["Start","End"].[^(><.)]+>/gim;

  const newstr = ooxml.replace(re, replace);
  log.info(limaLogTag.LimaWordInteract, `selectSameState: replace ooxml`, ooxml, newstr, replace);
  return newstr;
};

export async function selectSameState(acceptOrReject: ACCEPTREJECT): Promise<boolean | null> {
  log.info(limaLogTag.LimaWordInteract, `selectSameState: selecting same state [${String(acceptOrReject)}]`);
  // eslint-disable-next-line @typescript-eslint/no-misused-promises

  // eslint-disable-next-line @typescript-eslint/require-await
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  return new Promise(async (resolve, reject) => {
    // eslint-disable-next-line @typescript-eslint/require-await
    Word.run(async function (context) {
      //let selectedBkms = context.document.getSelection().getBookmarks(true, true);
      const range = context.document.getSelection();
      const selectedBkms = range.getBookmarks(true, true);
      let doSomeChanges = false;
      const bookmarkRangeList: BoookMarkObjectSameState[] = [];

      await context.sync();
      log.info(limaLogTag.LimaWordInteract, "selectSameState >> ", selectedBkms.value);
      const addItems = selectedBkms.value.filter((item: string) => item.startsWith(DEF_addBkmID));
      const dellItems = selectedBkms.value.filter((item: string) => item.startsWith(DEF_delBkmID));

      log.info(limaLogTag.LimaWordInteract, `selectSameState: processing ADD, DEL`, addItems, dellItems);

      if (addItems.length > 0 || dellItems.length > 0) doSomeChanges = true;

      addItems.forEach((bkmname: string) => {
        log.info(limaLogTag.LimaWordInteract, `selectSameState: Add start processing`, bkmname);
        const bookmarkRange = context.document.getBookmarkRangeOrNullObject(bkmname);
        bookmarkRange.load();
        bookmarkRangeList.push({
          range: bookmarkRange,
          rangeStart: bookmarkRange.getRange("Start"),
          rangeEnd: bookmarkRange.getRange("End"),
          rangeContent: bookmarkRange.getRange("Content"),
          addRemove: ADDDELETE.ADD,
          bkmname: bkmname,
          ooxml: null,
          html: null,
          secondStep: false,
        });
      });

      dellItems.forEach((bkmname: string) => {
        log.info(limaLogTag.LimaWordInteract, `selectSameState: DEL start processing`, bkmname);
        const bookmarkRange = context.document.getBookmarkRangeOrNullObject(bkmname);
        bookmarkRange.load();
        bookmarkRangeList.push({
          range: bookmarkRange,
          rangeStart: bookmarkRange.getRange("Start"),
          rangeEnd: bookmarkRange.getRange("End"),
          rangeContent: bookmarkRange.getRange("Content"),
          addRemove: ADDDELETE.DEL,
          bkmname: bkmname,
          ooxml: null,
          html: null,
          secondStep: false,
        });
      });

      await context.sync();

      for (let i = 0; i < bookmarkRangeList.length; i++) {
        log.info(
          limaLogTag.LimaWordInteract,
          `selectSameState: get texts`,
          bookmarkRangeList[i].range.text,
          bookmarkRangeList[i].addRemove
        );
        if (
          (bookmarkRangeList[i].addRemove == ADDDELETE.DEL && acceptOrReject == ACCEPTREJECT.ACCEPT) ||
          (bookmarkRangeList[i].addRemove == ADDDELETE.ADD && acceptOrReject == ACCEPTREJECT.REJECT)
        ) {
          log.info(
            limaLogTag.LimaWordInteract,
            `selectSameState: deleting range texts`,
            bookmarkRangeList[i].range.text
          );
          bookmarkRangeList[i].range.delete();
          //Try to remove by adding new bookmakr beforewith zero size
        } else {
          log.info(
            limaLogTag.LimaWordInteract,
            `selectSameState: update color on  range texts`,
            bookmarkRangeList[i].range.text
          );
          //TEST 1 - remove start and end - NOT working
          //TEST 2a - add content - OOXML, then remove
          //TEST 2 - remove and add content - OOXML
          //TEST 2 - remove and add content - HTML
          //TEST 3 - remove and add html
          bookmarkRangeList[i].range.font.highlightColor = null;
          bookmarkRangeList[i].range.font.strikeThrough = false;
          bookmarkRangeList[i].secondStep = true;
          //bookmarkRangeList[i].rangeStart.delete();
          //bookmarkRangeList[i].rangeEnd.delete();
          bookmarkRangeList[i].ooxml = bookmarkRangeList[i].rangeContent.getOoxml();
          // bookmarkRangeList[i].range.insertOoxml(bookmarkRangeList[i].getOoxml()) ;

          // bookmarkRangeList[i].ooxml = bookmarkRangeList[i].range.getRange("Content").getOoxml();
          log.info(limaLogTag.LimaWordInteract, `selectSameState: getXML`, bookmarkRangeList[i].range.text);

          //await context.sync();
        }
        //console.log(bookmarkRangeList[i].range.text);
      }
      if (doSomeChanges) {
        await context.sync();
        let seconStepChanges = 0;
        bookmarkRangeList.forEach((item: BoookMarkObjectSameState) => {
          log.info(
            limaLogTag.LimaWordInteract,
            `selectSameState: check second sttep A`,
            item.secondStep,
            item.range.text,
            item.ooxml.value
          );
          if (item.secondStep) {
            seconStepChanges++;
            item.range.insertOoxml(replacebookmark(item.ooxml.value, ""), "Replace");
            // item.range.clear();
            // item.range.insertOoxml(replacebookmark(item.ooxml.value, ""), "Start");
          }
        });
        bookmarkRangeList.forEach((item: BoookMarkObjectSameState) => {
          log.info(
            limaLogTag.LimaWordInteract,
            `selectSameState: check second sttep`,
            item.secondStep,
            item.range.text
          );
          //FIRST delete all
          //INSERT AFTER
          if (item.secondStep) {
            //seconStepChanges++;
            // item.range.insertOoxml(item.rangeContent.getOoxml(), "Before");
            // item.range.insertOoxml(item.rangeContent.getOoxml(), "After");
            // item.range.insertOoxml(item.ooxml.value, "Before");
            //ORIG item.range.insertOoxml(item.ooxml.value, "After");
            //item.range.insertOoxml(item.ooxml.value, "Replace");
            //item.range.insertOoxml(item.ooxml.value, "After");
            log.info(
              limaLogTag.LimaWordInteract,
              `selectSameState: second sttep insert`,
              item.secondStep,
              item.range.text
            );
            //ORIG item.range.delete();
            // log.info(
            //   limaLogTag.LimaWordInteract,
            //   `selectSameState: second sttep delete`,
            //   item.secondStep,
            //   item.range.text
            // );
          }
        });
        if (seconStepChanges > 0) {
          log.info(limaLogTag.LimaWordInteract, `selectSameState: second sttep processing [${seconStepChanges}]`);
          await context.sync();
        }
        resolve(true);
      } else {
        reject();
      }
      //   dellItems.forEach(async (bkmname: string) => {
      //     // if (doSyncOnFinish !== true) doSyncOnFinish = true;
      //     //doSyncOnFinish = true;
      //     // const selectedRange = context.document.getBookmarkRangeOrNullObject(bkmname);
      //     // eslint-disable-next-line prefer-const
      //     log.info(limaLogTag.LimaWordInteract, `selectSameState: DEL start processing`, bkmname);
      //     const bookmarkRange = context.document.getBookmarkRangeOrNullObject(bkmname);
      //     bookmarkRange.load();
      //     bookmarkRangeList.push(bookmarkRange);
      //     await context
      //       .sync()
      //       .then(() => {
      //         // context.sync().then(function() {
      //         if (bookmarkRange.isNullObject) {
      //           // handle case of null object here
      //           log.info(limaLogTag.LimaWordInteract, `selectSameState: DEL bookmark not found`, bkmname);
      //         } else {
      //           log.info(limaLogTag.LimaWordInteract, `selectSameState: DEL bookmark processing`, bkmname);
      //           if (acceptOrReject === true) {
      //             //ACCEPT

      //             bookmarkRange.delete();
      //           } else {
      //             bookmarkRange.font.highlightColor = null;
      //             bookmarkRange.font.strikeThrough = false;
      //           }
      //           //bookmarkRange.insertText("test data",Word.InsertLocation.replace);
      //         }
      //       })
      //       .then(() => {
      //         log.info(limaLogTag.LimaWordInteract, `selectSameState: DEL contextSycn`, bkmname);
      //         void context.sync();
      //       });
      //   });

      //   console.log("The bookmarks read from the document was: " + selectedBkms.value);
      //   //  if (doSyncOnFinish) {
      //   log.info(limaLogTag.LimaWordInteract, `selectSameState:doSyncOnFinish`);
      //   await context
      //     .sync()
      //     .then(() => {
      //       resolve(true);
      //     })
      //     .catch((error) => {
      //       log.error(limaLogTag.LimaWordInteract, "selectSameState: error", error);
      //       if (error instanceof OfficeExtension.Error) {
      //         console.log("Debug info: " + JSON.stringify(error.debugInfo));
      //       }
      //       reject();
      //     });
      //   //    }
      //   resolve(true);
      // });

      //context.load(selectedRange);
      //context.load();
      // await context.sync();
      // for (let i = 0; i < selectedBkms.value.length; i++) {
      //   log.info(limaLogTag.LimaWordInteract, "selectSameState >> ", acceptOrReject, selectedBkms[i].value);
      //   // const selectedRange = context.document.getBookmarkRangeOrNullObject()
      // }
      // }).catch(function (error) {
      //   // console.log("Error: " + JSON.stringify(error));
      //   log.error(limaLogTag.LimaWordInteract, "highlightTaskLinks: Error info :", JSON.stringify(error));
      //   if (error instanceof OfficeExtension.Error) {
      //     // console.log("Debug info: " + JSON.stringify(error.debugInfo));
      //     log.error(limaLogTag.LimaWordInteract, "highlightTaskLinks: Debug info :", JSON.stringify(error.debugInfo));
      //     reject();
      //   }
      // });
    });
  });
}

export async function openNewWordDocument() {
  void Word.run(async function (context) {
    var newWordDoc = context.application.createDocument();
    context.load(newWordDoc);
    window.console.log("OPEN");
    newWordDoc.open();
    context.sync();
    return context.sync();
  });
}
