/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const equalsArray = (A: any[], B: any[]) => {
  const AsubB = A.every((a) => B.some((b) => JSON.stringify(a) === JSON.stringify(b)));

  // A and B are the same length
  //let sameLength = A.length === B.length;

  // console.log("array eql lenght", A.length,B.length)
  // console.log("array eql arrays", A, B)
  // console.log("array eql sub", AsubB)
  // console.log("array are eqv", AsubB && A.length === B.length)

  // their contents are as equal as previously tested:

  return AsubB && A.length === B.length;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const nameof = <T extends {}>(name: keyof T) => name;

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export function uniqByReduce<T>(array: T[]): T[] {
  return array.reduce((acc: T[], cur: T) => {
    if (!acc.includes(cur)) {
      acc.push(cur);
    }
    return acc;
  }, []);
}

export const deduplicateArray = <Type, Key extends keyof Type>(arr: Type[], key: Key): Type[] => {
  const ids = arr.map((o: Type) => o[key]);
  const filtered = arr.filter((item: Type, index) => !ids.includes(item[key], index + 1));

  return filtered;
};

export const isNotNullOrUndefined = <T>(value: T | null | undefined): boolean => {
  return !(value === null || value === undefined);
};
