import { getFocusStyle, getTheme, ITheme, ITooltipHostStyles, mergeStyleSets, TooltipHost } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { FunctionComponent, ReactNode } from "react";
import React = require("react");

interface ILimaActionButtonV4Props {
  onClick: () => void;
  toolTipShow: boolean;
  toolTipContent?: string;
  children?: ReactNode;
  disabled?: boolean;
}

const calloutProps = { gapSpace: 0 };
const hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block" } };

const theme: ITheme = getTheme();
//const { palette, semanticColors } = theme;
// const { palette } = theme;

const classNames = mergeStyleSets({
  actionButtonActive: [
    getFocusStyle(theme, { inset: -1 }),
    {
      padding: 3,
      borderRadius: 5,
      display: "flex",
      margin: 2,
      minWidth: 24,
      minHeight: 24,
      justifyContent: "center",
      textAlign: "center",
      alignItems: "center",
      selectors: {
        "&:hover": {
          //background: "rgb(243, 242, 241)",
          background: "rgb(231, 231, 231)",
          cursor: "pointer",
        },
      },
    },
  ],
  actionButton: [
    {
      padding: 3,
      borderRadius: 5,
      display: "flex",
      margin: 2,
      minWidth: 24,
      minHeight: 24,
      justifyContent: "center",
      alignItems: "center",
      color: "#a9a9a9",
    },
  ],
});

const LimaActionButtonV4: FunctionComponent<ILimaActionButtonV4Props> = (props: ILimaActionButtonV4Props) => {
  const actionId = useId("rem");

  const onClick = () => {
    if (props.onClick !== undefined) props.onClick();
  };

  return (
    <div onClick={onClick} className={props.disabled ? classNames.actionButton : classNames.actionButtonActive}>
      {props.toolTipShow ? (
        <TooltipHost
          content={props.toolTipContent}
          id={actionId}
          calloutProps={calloutProps}
          styles={hostStyles}
          setAriaDescribedBy={false}
        >
          {props.children}
        </TooltipHost>
      ) : (
        <>{props.children}</>
      )}
    </div>
  );
};

export default LimaActionButtonV4;
