/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { FontIcon, IIconProps, mergeStyleSets, Separator, Stack } from "@fluentui/react";
import { FC, useEffect, useState } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";

// export default LimaHideableSection;
type LimaHideableSectionProps = {
  name: string;
  defaultHidden?: boolean;
  visible?: boolean;
  children?: React.ReactNode;
};

const classNames = mergeStyleSets({
  contentWrapper: {
    width: "100%",
  },
  hidablenamewrapper: {
    paddingLeft: 5,
    paddingRight: 5,
    cursor: "pointer",
  },
  hidablenameIcon: {
    fontSize: "medium",
    // paddingTop: 4px;
    marginRight: 6,
    /* padding-bottom: 0px; */
    verticalAlign: "text-bottom",
    color: "darkgray",
  },
  hidablenameName: {
    color: "#40006d",
    fontWeight: "500",
  },
  hidablenameState: {
    marginLeft: 6,
    color: "darkgray",
  },
});

const showIcon: IIconProps = { iconName: "RedEye" };
const hideIcon: IIconProps = { iconName: "Hide" };

export const LimaHideableSection: FC<LimaHideableSectionProps> = ({
  name,
  defaultHidden,
  children,
  visible,
}: LimaHideableSectionProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [show, setShow] = useState<boolean>(defaultHidden ? false : true);
  const { t } = useTranslation(["tpmain"]);

  useEffect(() => {
    if (visible !== undefined && visible != show) {
      setShow(visible);
    }
  }, [visible]);
  //export const LimaHideableSection:FC< LimaControledDatePickerFieldType> = (props) => {
  return (
    <Stack className={classNames.contentWrapper}>
      <Stack.Item>
        <Separator alignContent="center">
          <span
            className={classNames.hidablenamewrapper}
            onClick={() => {
              setShow(!show);
            }}
          >
            <FontIcon iconName={!show ? hideIcon.iconName : showIcon.iconName} className={classNames.hidablenameIcon} />
            <span className={classNames.hidablenameName}>{name}</span>
            <span className={classNames.hidablenameState}>
              {show ? `(${t("tpmain:hidablesection.tohideLbl")})` : `(${t("tpmain:hidablesection.toshowLbl")})`}
            </span>
          </span>
        </Separator>
      </Stack.Item>
      {show && <Stack.Item>{children}</Stack.Item>}
      {!show && <Stack.Item>...</Stack.Item>}
    </Stack>
  );
};
