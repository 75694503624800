import limaStore from "../store/limaStore";
import limaJudgeStore from "../store/limaJudgeStore";
import { log } from "missionlog";
import limaActParStore from "../store/limaActParStore";
import { searchcontextHandler, searchContextHandlerType } from "./searchContextHandler";
import limaKeywordsStore from "../store/limaKeywordStore";
import { adviceKeywordStringList } from "../types/keywords";
import { limaLogTag } from "./limaLog";
import { getItemTypeFromPrefix, SelectedInWord } from "../types/wordObjects";
import { WORDITEMTYPE } from "../api/apilinks";

//log.init({ selectHandler: "selectHandler" });

export function subscribeToChangeSelection(): void {
  log.info(limaLogTag.SelectHandler, "subscribeToChangeSelection: just do subscription -> start  ");
  if (Office.context.requirements.isSetSupported("WordApi", "1.1")) {
    Office.context.document.addHandlerAsync(Office.EventType.DocumentSelectionChanged, handlerClick);
    log.info(limaLogTag.SelectHandler, "subscribeToChangeSelection: just do subscription -> Store", limaStore);
  } else {
    // Just letting you know that this code will not work with your version of Word.
    log.error(limaLogTag.SelectHandler, "subscribeToChangeSelection: This code requires WordApi 1.1 or greater.");
  }
}

export function unSubscribeToChangeSelection(): void {
  log.info(limaLogTag.SelectHandler, "subscribeToChangeSelection: just do subscription -> start  ");
  if (Office.context.requirements.isSetSupported("WordApi", "1.1")) {
    Office.context.document.removeHandlerAsync(Office.EventType.DocumentSelectionChanged);
    //Office.context.document.addHandlerAsync(Office.EventType.DocumentSelectionChanged, handlerClick);
    log.info(limaLogTag.SelectHandler, "unSubscribeToChangeSelection: just do usubscribe");
  } else {
    // Just letting you know that this code will not work with your version of Word.
    log.error(limaLogTag.SelectHandler, "unSubscribeToChangeSelection: This code requires WordApi 1.1 or greater.");
  }
}

export function handlerClick(): Promise<void> {
  //console.log("click");
  log.info(limaLogTag.SelectHandler, "handlerClick: just clicked on word");

  return Word.run(async (context) => {
    const paragraphs: Word.ParagraphCollection = context.document.getSelection().paragraphs;
    // const handlerIds = [];
    // const handlerTypes = [];
    const handlerItems: SelectedInWord[] = [];

    context.load(paragraphs);
    await context.sync();
    //CLEANUP
    if (limaJudgeStore.isInlineSearchActive == true) limaJudgeStore.clearSearchActPar();
    if (limaActParStore.isInlineSearchActive == true) limaActParStore.clearSearchActPar();
    if (limaKeywordsStore.isInlineSearchActive == true) limaKeywordsStore.clearSearchKeywords();

    // TODO: handle if parId == null
    for (let x = 0; x < paragraphs.items.length; x++) {
      //TODO: is there wai to speedup? instead of sync inside loop
      await context.sync().then(async () => {
        const myPar: Word.Paragraph = paragraphs.items[x];
        const myParText: Word.Paragraph = myPar.load();
        const range: Word.Range = myPar.getRange();

        //console.log("search bookmasrs start")
        const bkmrks = range.getBookmarks(true, true);

        context.load(myPar);
        //TODO: is there wai to speedup? instead of sync inside loop
        await context.sync().then(() => {
          log.info(limaLogTag.SelectHandler, `handlerClick:: bookmarks `, bkmrks);
          //TODO: JUST ONE AT ONCE
          if (myParText.text !== null) {
            if (limaActParStore.isInlineSearchActive == true) getActPar(myParText.text);
            if (limaJudgeStore.isInlineSearchActive == true) getJudgeActPar(myParText.text);
            if (limaKeywordsStore.isInlineSearchActive == true) getKeywords(myParText.text);
          }
          //console.log("Handler",bkmrks )

          //console.log("search bookmasks lenght",bkmrks.length )
          if (bkmrks.value.length > 0) {
            //TODO: now care only on first
            let used = false;
            bkmrks.value.forEach((bkmrksitem: string) => {
              const bkmrkItemParts = bkmrksitem.split("_");
              log.info(limaLogTag.SelectHandler, `handlerClick:: test bookmark `, bkmrkItemParts);
              if (
                used == false &&
                bkmrkItemParts.length > 1 &&
                (getItemTypeFromPrefix(bkmrkItemParts[0]) == WORDITEMTYPE.para ||
                  getItemTypeFromPrefix(bkmrkItemParts[0]) == WORDITEMTYPE.comm) &&
                !handlerItems.includes({ id: bkmrkItemParts[1], type: getItemTypeFromPrefix(bkmrkItemParts[0]) })
              ) {
                log.info(limaLogTag.SelectHandler, `handlerClick:: USED `, bkmrkItemParts);
                handlerItems.push({ id: bkmrkItemParts[1], type: getItemTypeFromPrefix(bkmrkItemParts[0]) });
                used = true;
              } else {
                log.info(limaLogTag.SelectHandler, `handlerClick:: NOT USED `, bkmrkItemParts);
              }
            });

            // if (!handlerIds.includes(bkmrkItemParts[1])) {
            //   handlerIds.push(bkmrkItemParts[1]);
            //   handlerTypes.push(bkmrkItemParts[0]);
            // }

            // if (!handlerTypes.includes(bkmrkItemParts[0])) {
            //   handlerTypes.push(bkmrkItemParts[0]);
            // }
          }
        });
      });
    }
    //log.info(limaLogTag.SelectHandler, `handlerClick:: Final selected parIds `, handlerIds);
    log.info(limaLogTag.SelectHandler, `handlerClick:: Final selected parIds `, handlerItems);
    // limaStore.select(handlerIds, handlerTypes); //update all at once
    limaStore.select(handlerItems);
    if (limaJudgeStore.isInlineSearchActive == true) {
      log.info(limaLogTag.SelectHandler, `handlerClick:: call  limaJudgeStore getActaParaFromDB `);
      //limaJudgeStore.getActaParaFromDB();
      void limaJudgeStore.getActaParaFromDB2();
      void searchcontextHandler(searchContextHandlerType.JUDGE);
    }
    if (limaActParStore.isInlineSearchActive == true) {
      log.info(limaLogTag.SelectHandler, `handlerClick:: call  limaActParStore getActaParaFromDB `);
      void limaActParStore.getActaParaFromDB();
    }
    if (limaKeywordsStore.isInlineSearchActive == true) {
      log.info(limaLogTag.SelectHandler, `handlerClick:: call  limaKeywordsStore getActaParaFromDB `);
      void limaKeywordsStore.getDataFromDB2();
      void searchcontextHandler(searchContextHandlerType.KEYWORD);
    }
  });
}

//TODO: rewrite to get all and then
function getJudgeActPar(text: string) {
  //log.info(limaLogTag.SelectHandler, "getJudgeActPar: Started", text)
  log.info(limaLogTag.SelectHandler, "getJudgeActPar: Started");

  //let parId;
  //let text = myParText.text;

  if (
    limaJudgeStore.actSignListString !== undefined &&
    limaJudgeStore.actSignListString !== null &&
    limaJudgeStore.actSignListString !== "" &&
    text.includes("§")
  ) {
    //TODO: will we care about??
    /*
    let removeSpace = text.replace(/\s/g, '');
    let removeLetters = removeSpace.replace(/[^\§\d+]/g, '');
    let res = removeLetters.split("§");
    res.shift();
    */

    const searchString = "(§.{0,1}?([0-9a-z]+).{0,30}?(" + limaJudgeStore.actSignListString + "))";
    log.info(limaLogTag.SelectHandler, `getJudgeActPar: '${searchString}'`);
    const myregexp = new RegExp(searchString, "g");
    console.log(myregexp);
    //var myregexp = /\d (\w)/g;
    //var match = myregexp.exec(text);
    let result;
    while ((result = myregexp.exec(text)) !== null) {
      log.info(limaLogTag.SelectHandler, `getJudgeActPar: found actId [] - parId []`, result);
      //TODO: check if already exists #38

      limaJudgeStore.addSearchActPat({ actID: result[3], parID: result[2] });
    }
  } else {
    log.info(
      limaLogTag.SelectHandler,
      `getJudgeActPar: no § mark or missing actSigns [${limaJudgeStore.actSignListString}]`
    );
    limaJudgeStore.clearLists();
    return;
  }
}

function getActPar(text: string) {
  log.info(limaLogTag.SelectHandler, "getActPar: Started");

  if (
    limaActParStore.actSignListString !== undefined &&
    limaActParStore.actSignListString !== null &&
    limaActParStore.actSignListString !== "" &&
    text.includes("§")
  ) {
    //TODO: will we care about?? //TODO: care about misssing actsigns

    const searchString = "(§.{0,1}?([0-9a-z]+).{0,30}?(" + limaActParStore.actSignListString + "))";
    log.info(limaLogTag.SelectHandler, `getActPar: '${searchString}'`);
    const myregexp = new RegExp(searchString, "g");
    console.log(myregexp);

    let result;
    while ((result = myregexp.exec(text)) !== null) {
      log.info(limaLogTag.SelectHandler, `getActPar: found actId [] - parId []`, result);
      limaActParStore.addSearchActPat({ actID: result[3], parID: result[2] });
    }
  } else {
    log.info(
      limaLogTag.SelectHandler,
      `getActPar: no § mark or missing actSigns [${limaActParStore.actSignListString}]`
    );
    limaActParStore.clearLists();
    return;
  }
}

function getKeywords(text: string) {
  log.info(limaLogTag.SelectHandler, "getKyewords: Started");

  limaKeywordsStore.keywordsListString.map((item: adviceKeywordStringList) => {
    if (item.officeStringSearch != null && item.officeStringSearch != "") {
      //const searchString = "(" + item.officeStringSearch + ")";
      log.info(limaLogTag.SelectHandler, `getKeywords: '${item.officeStringSearch}'`);
      const myregexp = new RegExp("(" + item.officeStringSearch + ")", "g");
      console.log(myregexp);
      if (myregexp.test(text.toLowerCase())) limaKeywordsStore.addSearchKeyword(item.key);
    } else log.info(limaLogTag.SelectHandler, `getKeywords: no keywords from this keyword [${item.key}]`);
  });
  // if (
  //   limaKeywordsStore.keywordsListString !== undefined &&
  //   limaKeywordsStore.keywordsListString !== null &&
  //   limaKeywordsStore.keywordsListString !== ""
  // ) {
  //   //TODO: will we care about?? //TODO: care about misssing actsigns

  //   const searchString = "(" + limaKeywordsStore.keywordsListString + ")";
  //   log.info(limaLogTag.SelectHandler, `getKeywords: '${searchString}'`);
  //   var myregexp = new RegExp(searchString, "g");
  //   console.log(myregexp);

  //   let result;
  //   while ((result = myregexp.exec(text)) !== null) {
  //     log.info(limaLogTag.SelectHandler, `getKyewords: found`, result);
  //     limaKeywordsStore.addSearchKeyword(result[1]);
  //   }
  // } else {
  //   log.info(limaLogTag.SelectHandler, `getKeywords: no keywords from db [${limaKeywordsStore.keywordsListString}]`);
  //   limaKeywordsStore.clearLists();
  //   return;
  // }
}
