import { DefaultButton, Stack } from "@fluentui/react";
import { log } from "missionlog";
import { FunctionComponent } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import { ACCEPTREJECT, selectSameState } from "../../../../../limaCommon/limaWordInteract";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface EditStatesProps {}

const EditStates: FunctionComponent<EditStatesProps> = () => {
  const { t } = useTranslation();
  const onAcceptState = (acceptOrReject: ACCEPTREJECT): void => {
    log.info(limaLogTag.EditStatesPage, "onAcceptState: clicked");
    void selectSameState(acceptOrReject);
  };

  return (
    <Stack.Item>
      <Stack horizontal key="StatesProcess">
        <Stack.Item>
          <DefaultButton
            text={t("tpfile:filecompare.acceptbtnlbl")}
            onClick={() => onAcceptState(ACCEPTREJECT.ACCEPT)}
          />
        </Stack.Item>
        <Stack.Item>
          <DefaultButton
            text={t("tpfile:filecompare.rejectbtnlbl")}
            onClick={() => onAcceptState(ACCEPTREJECT.REJECT)}
          />
        </Stack.Item>
      </Stack>
    </Stack.Item>
  );
};

export default EditStates;
