import { Stack } from "@fluentui/react";
import { log } from "missionlog";
import { ReactElement, useEffect, useState } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import { limaLogTag } from "../../../limaCommon/limaLog";
import { ControlledSwitchDataItem, LimaControlledTextSwitch } from "../../../limaComponents/LimaControledTextSwitch";
import { TASK_LINKED2ME_ENUM } from "../../../store/limaTasksStoreV4";
// import { TASK_LINKED2ME_ENUM } from "../../../store/limaTasksStore";
import { TASKSTATE } from "../../../types/Task";
import { TASKFILTERITEM_ENUM, TaskListFilterItem } from "./TaskListV4";

export interface TaskListFilterV4Props {
  filter: TaskListFilterItem[];
  onFilterUpdate: (filter: TaskListFilterItem[]) => void;
}

// const spacingStackTokens: IStackTokens = {
//   childrenGap: '10%',
//   padding: 's1 15%',
// };

export const TaskListV4Filter = ({ filter, onFilterUpdate }: TaskListFilterV4Props): ReactElement => {
  const { t } = useTranslation();
  const [filtersInner, setFiltersInner] = useState<TaskListFilterItem[]>([]);

  //--------------
  // Reactions
  //--------------

  useEffect(() => {
    if (filter !== undefined) {
      setFiltersInner(filter);
    } else {
      setFiltersInner([
        {
          taskItemFilterIdent: TASKFILTERITEM_ENUM.FILTER_CREATOR,
          taksItemFilterValue: TASK_LINKED2ME_ENUM.IRESPONSIBLEONLY,
        },
        {
          taskItemFilterIdent: TASKFILTERITEM_ENUM.FILTER_LINKTOACTIVEDOC,
          taksItemFilterValue: false,
        },
        {
          taskItemFilterIdent: TASKFILTERITEM_ENUM.FILTER_TASKSTATE,
          taksItemFilterValue: null,
        },
      ]);
    }
  }, []);

  useEffect(() => {
    // let filterStart = filter;
    log.info(limaLogTag.TasksListFilter, `UEF: base changed `, filter, filtersInner);
    setFiltersInner(filter);
    // }
  }, [filter]);

  useEffect(() => {
    log.info(limaLogTag.TasksListFilter, `UEF: filtersInner udpate `, filtersInner);
  }, [filtersInner]);
  //--------------
  // Handlers
  //--------------

  const getFilterValue = (ident: string): TaskListFilterItem => {
    return filtersInner
      ? filtersInner.filter((item: TaskListFilterItem) => {
          if (item.taskItemFilterIdent === ident) return true;
          return false;
        })[0]
      : undefined;
  };

  const changeFilters = (newFilter: TaskListFilterItem): TaskListFilterItem[] => {
    log.info(limaLogTag.TasksListFilter, `changeFilters: inner `, filtersInner);
    if (filtersInner === undefined) {
      setFiltersInner([newFilter]);
      return [newFilter];
    }
    let used = false;

    const chagneFilters = filtersInner.map((item: TaskListFilterItem) => {
      if (item.taskItemFilterIdent === newFilter.taskItemFilterIdent) {
        used = true;
        return newFilter;
      }
      return item;
    });
    if (used === false) {
      chagneFilters.push(newFilter);
      setFiltersInner(chagneFilters);
    }
    log.info(limaLogTag.TasksListFilter, `changeFilters: update `, chagneFilters);

    return chagneFilters;
  };

  return (
    <Stack>
      <Stack.Item>{t("tptasks:taskList.filterLbl")}</Stack.Item>
      <Stack.Item>
        <Stack horizontal>
          <Stack.Item>{t("tptasks:taskList.filter.assignLbl")}</Stack.Item>
          <Stack.Item>
            <LimaControlledTextSwitch
              values={[
                {
                  key: TASK_LINKED2ME_ENUM.NOTONLYMY,
                  name: t("tptasks:taskList.filter.notonlymytasksLbl"),
                  value: TASK_LINKED2ME_ENUM.NOTONLYMY,
                },
                {
                  key: TASK_LINKED2ME_ENUM.IRESPONSIBLEONLY,
                  name: t("tptasks:taskList.filter.myresponsetasksLbl"),
                  value: TASK_LINKED2ME_ENUM.IRESPONSIBLEONLY,
                },
                {
                  key: TASK_LINKED2ME_ENUM.ICREATEONLY,
                  name: t("tptasks:taskList.filter.onlyimadetasksLbl"),
                  value: TASK_LINKED2ME_ENUM.ICREATEONLY,
                },
              ]}
              defaultValue={
                getFilterValue(TASKFILTERITEM_ENUM.FILTER_CREATOR) &&
                (getFilterValue(TASKFILTERITEM_ENUM.FILTER_CREATOR).taksItemFilterValue as TASK_LINKED2ME_ENUM)
              }
              callBack={(dataItem: ControlledSwitchDataItem) => {
                log.info(limaLogTag.TasksListFilter, `LimaControlledTextSwitch toggle: switch `, dataItem);

                onFilterUpdate(
                  changeFilters({
                    taskItemFilterIdent: TASKFILTERITEM_ENUM.FILTER_CREATOR,
                    taksItemFilterValue: TASK_LINKED2ME_ENUM[dataItem.value as keyof typeof TASK_LINKED2ME_ENUM],
                  })
                );
              }}
            />
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <Stack horizontal>
          <Stack.Item>{t("tptasks:taskList.filter.linkedtodocLbl")}</Stack.Item>
          <Stack.Item>
            <LimaControlledTextSwitch
              values={[
                { key: "alltasks", name: t("tptasks:taskList.filter.alltasksLbl"), value: "alltasks" },
                {
                  key: "currentactaonly",
                  name: t("tptasks:taskList.filter.currentactatasksLbl"),
                  value: "currentactaonly",
                },
              ]}
              defaultValue={
                getFilterValue(TASKFILTERITEM_ENUM.FILTER_LINKTOACTIVEDOC) &&
                (getFilterValue(TASKFILTERITEM_ENUM.FILTER_LINKTOACTIVEDOC).taksItemFilterValue as boolean)
                  ? "currentactaonly"
                  : "alltasks"
              }
              callBack={(dataItem: ControlledSwitchDataItem) => {
                log.info(limaLogTag.TasksListFilter, `LimaControlledTextSwitch toggle: switch `, dataItem);

                onFilterUpdate(
                  changeFilters({
                    taskItemFilterIdent: TASKFILTERITEM_ENUM.FILTER_LINKTOACTIVEDOC,
                    taksItemFilterValue: dataItem.value == "alltasks" ? false : true,
                  })
                );

                // limaTasksStore.onFilterChanged(null);
              }}
            />
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <Stack horizontal>
          <Stack.Item>{t("tptasks:taskList.filter.stateLbl")}</Stack.Item>
          <Stack.Item>
            <LimaControlledTextSwitch
              values={[
                { key: TASKSTATE.OPEN, name: t("tptasks:taskList.filter.onlyopentaskLbl"), value: TASKSTATE.OPEN },
                {
                  key: "includingfinished",
                  name: t("tptasks:taskList.filter.includefinishedLbl"),
                  value: "null",
                },
              ]}
              defaultValue={
                (getFilterValue(TASKFILTERITEM_ENUM.FILTER_TASKSTATE) &&
                  (getFilterValue(TASKFILTERITEM_ENUM.FILTER_TASKSTATE).taksItemFilterValue as string)) === null
                  ? "null"
                  : TASKSTATE.OPEN
              }
              callBack={(dataItem: ControlledSwitchDataItem) => {
                log.info(limaLogTag.TasksListFilter, `LimaControlledTextSwitch toggle: switch `, dataItem);

                onFilterUpdate(
                  changeFilters({
                    taskItemFilterIdent: TASKFILTERITEM_ENUM.FILTER_TASKSTATE,
                    taksItemFilterValue: dataItem.value == TASKSTATE.OPEN ? TASKSTATE.OPEN : null,
                  })
                );

                // limaTasksStore.onFilterChanged(null);
              }}
            />
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};
