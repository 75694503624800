import { log } from "missionlog";
import { Moment } from "moment";
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { urlHistoryGetParaHistory, WORDITEMTYPE } from "../../../../../api/apilinks";
import { httpGetAuth, HttpResponse } from "../../../../../api/httpAPI";
import { paraHistoryOK } from "../../../../../api/schema/getParaHistoryResp";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import { LimaHideableSection } from "../../../../../limaComponents/LimaHideableSection";
import { LimaLoadingComponnet } from "../../../../../limaComponents/LimaLoadingComponnet";
import limaLoadeStore from "../../../../../store/limaLoaderStore";
import limaStore from "../../../../../store/limaStore";
import { SelectedInWord } from "../../../../../types/wordObjects";
import HistParaItemTextRow from "../HistParaItemParaTextRow";

type HistParaItemParaTextItemsProps = {
  dateFrom: Moment;
  dateTo: Moment;
  selectedItem: SelectedInWord | null;
};
//({ logo, message, title }: HistItemProps)
const HistParaItemParaTextItems: FunctionComponent<HistParaItemParaTextItemsProps> = ({
  dateFrom,
  dateTo,
  selectedItem,
}: HistParaItemParaTextItemsProps) => {
  const [parHistTexts, setParHistTexts] = useState<paraHistoryOK[]>();
  const { t } = useTranslation(["tpshow"]);

  const { isLoading, error, refetch } = useQuery<paraHistoryOK[], Error>(
    [
      "userListCopies",
      {
        itemid: (selectedItem && selectedItem.id) || null,
        itemtype: (selectedItem && selectedItem.type) || null,
        userid: limaStore.userId || null,
        datefrom: (dateFrom && dateFrom.toISOString()) || null,
        dateto: (dateTo && dateTo.toISOString()) || null,
      },
    ],
    () =>
      getHistoryOfPara(
        (selectedItem && selectedItem.id) || null,
        (selectedItem && selectedItem.type) || null,
        // limaStore.userId || null,
        (dateFrom && dateFrom.toISOString()) || null,
        (dateTo && dateTo.toISOString()) || null
      ),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onError: (err) => {
        log.error(limaLogTag.HistParaItemParaTextItems, "useQuery get attachements ", err);
      },
      onSuccess: (data: paraHistoryOK[]) => {
        log.info(limaLogTag.HistParaItemParaTextItems, "useQuery success ", data);
        setParHistTexts(data);
      },
    }
  );

  useEffect(() => {
    void refetch();
  }, [dateFrom, dateTo, selectedItem]);

  if (isLoading) {
    log.info(limaLogTag.HistParaItemParaTextItems, "searching for history");
    return (
      <LimaHideableSection name={`${t("tpshow:history.historyLbl")} `}>{LimaLoadingComponnet(3)}</LimaHideableSection>
    );
  }
  if (error) {
    return (
      <LimaHideableSection name={`${t("tpshow:history.historyLbl")} `}>
        <div>{t("tpshow:history.errorloadingLbl")}</div>
      </LimaHideableSection>
    );
  }

  let sectionName = `${t("tpshow:history.historyLbl")} `;
  if (parHistTexts && parHistTexts.length > 0) sectionName += `[${parHistTexts.length}]`;

  return (
    <LimaHideableSection name={sectionName}>
      {parHistTexts &&
        parHistTexts.map((paraHistoryItem, index) => (
          <HistParaItemTextRow
            key={paraHistoryItem.order}
            showChanges={index > 0 ? true : false}
            index={index}
            paraHistoryRow={paraHistoryItem}
          ></HistParaItemTextRow>
        ))}
    </LimaHideableSection>
  );
};
export default HistParaItemParaTextItems;

//----------------------
//   REACT QUERY
//----------------------

// type Params = {
//   queryKey: [string, { itemid: string; itemtype: WORDITEMTYPE; userid: string; datefrom: string; dateto: string }];
// };

async function getHistoryOfPara(
  itemid: string,
  _itemtype: WORDITEMTYPE,
  // userid: string,
  datefrom: string,
  dateto: string
) {
  // const [, { itemid, userid, datefrom, dateto }] = params.queryKey;

  if (itemid === null) {
    log.info(limaLogTag.HistParaItemParaTextItems, "getHistoryOfPara: itemid is null");
    return null;
  }
  if (datefrom === null || dateto === null) {
    log.info(limaLogTag.HistParaItemParaTextItems, "getHistoryOfPara: datefrom is null or dateto is null");
    return null;
  }
  const loaderID = limaLoadeStore.add("Getting history from DB");
  let response: HttpResponse<paraHistoryOK[]>;
  try {
    response = await httpGetAuth<paraHistoryOK[]>(urlHistoryGetParaHistory(itemid, datefrom, dateto), loaderID.ac);

    log.info(limaLogTag.HistParaItemParaTextItems, "getHistoryOfPara: asketo to get something", response);
    if (response.parsedBody !== undefined) {
      log.info(limaLogTag.HistParaItemParaText, "getHistoryOfPara: parsedBody is not null", response.parsedBody);
      //   const userListCopies = response.parsedBody;
      return response.parsedBody;
    } else {
      // throw new Error("...No data...");
      log.error(limaLogTag.HistParaItemParaTextItems, "getHistoryOfPara Error no data");
      return null;
    }
  } catch (response) {
    log.error(limaLogTag.HistParaItemParaTextItems, "getHistoryOfPara Error call get data", response);
    // throw new Error("Problem geting data");
    return null;
  } finally {
    //this.setLoadingData(false);
    limaLoadeStore.remove(loaderID.k);
  }
}
