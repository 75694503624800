export enum DialogType {
  access,
  history,
  info,
  actPar,
  judge,
  taskhistory,
  login,
}

export const LimaLogoDialog = "assets/LimaLogo200.png";
export const LimaTitleHistory = "Limacta Dialog History";
export const LimaTitleAccess = "Limacta Dialog Access control";
export const LimaTitleActPar = "Limacta Act parag detail";
export const LimaTitleJudge = "Limacta Judge detail";
export const LimaTitleLogin = "Limacta Login";
export const LimaTitleTaskHist = "Limacta Task history";
