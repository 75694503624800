import { computed, action, observable, makeAutoObservable } from "mobx";
import { getVersionParallel, LimaVersions, limaVersionsDef } from "../limaCommon/limaServerVersion";
import { log } from "missionlog";
import { UserPlugin } from "../types/UserPlugin";
import { limaLogTag } from "../limaCommon/limaLog";
import { subscribeToChangeSelection, unSubscribeToChangeSelection } from "../limaCommon/selectHandler";

import moment = require("moment");
import limaPermissionStore from "./limaPermissionStore";
import { closeActiveDialog } from "../limaCommon/limaDialogs";
import { SelectedInWord } from "../types/wordObjects";
import { XCOPY_TYPE } from "../types/Task";
import limaActasStore from "./limaActasStore";
import limaObjTagsStore from "./limaObjTagsStore";
import { MenuSelectedENUM } from "../limaComponents/LimaMenu";
import limaTasksStore from "./limaTasksStore";
import limaUsersStore from "./limaUsersStore";
import limaTasksStoreV4 from "./limaTasksStoreV4";
import { IUser } from "../types/User";
import { LimaDocData, LimaDocTypeENUM } from "../types/docxData";
import { getWordDocParams, getWordHaveSomeText } from "../limaCommon/limaWordInfo";
import limaActParStore from "./limaActParStore";
import limaBreadCrumbStore from "./limaBreadCrumbStore";
import limaDialogStore from "./limaDialogStore";
import limaJudgeStore from "./limaJudgeStore";
import limaKeywordsStore from "./limaKeywordStore";
import LimaLogsStore from "./limaLogsStore";
import limaLoadeStore from "./limaLoaderStore";
import limaNotesStore from "./limaNotesStore";
import limaSearchStore from "./limaSearchStore";
import limaTagsStore from "./limaTagsStore";
import limaTasksVersionsStore from "./limaTasksVersionsStore";

export enum OPENEDFILETYPE_ENUM {
  ACTA = "acta",
  VERSION = "version",
  COPY = "copy",
  COPY_TRIM = "copytrim",
  COPY_FULL = "copyfull",
  COPY_VERSION = "copyversion",
  COPY_2_MAINCOPY_COMPARE = "copy2maincopycompare",
  MAIN_COPY = "maincopy",
  MAIN_COPY_VERSION = "maincopyversion",
  APPENDIX = "appendix",
  NONE = "none",
}

export enum UI_VERSION {
  VERSION_2 = "UI_V2",
  VERSION_4 = "UI_V4",
}
//TODO: correct all

export class LimaStore {
  //---------common-------
  language = "";
  showTestingInfo = false; //TODO: for testing only
  locationChangeCallBack: unknown | null;
  versions: LimaVersions = limaVersionsDef;
  firstStart = true;
  // showLoader: boolean = false;

  //----------Select Handler------
  selectedId: string[] = [];
  selectedType: string[] = [];
  selectedInWord: SelectedInWord[] = [];

  //----------Word------
  wordDocIdent: LimaDocData = { limactaDocID: null, limactaDocName: "", limactaDocType: LimaDocTypeENUM.none };
  wordDocIdentReplaced = false;
  wordDocItentOnStart = false;

  wordDocSomeTextOnStart = false;

  //----------Acta------
  actaId = "";
  actaName = "";
  mainXcopyId = "";
  actaIsOpen = false;
  docxIsLoading = false;
  versionIsOpen = false;

  docLocation = "";
  openedDocType: OPENEDFILETYPE_ENUM = OPENEDFILETYPE_ENUM.NONE;

  docTimeStamp = "";
  IamDocumentOwner = false; //forFuture
  isOwner = false;
  isComparedResultOpen = false;

  bmksList: string[] = [];

  openFileNewWindow = false;
  openFileNewWindowConfirmFnc: () => void | undefined = undefined;
  openFileCurrentWindowConfirmFnc: () => void | undefined = undefined;

  //----------Xcopy------
  xcopyId = "";
  xcopyName = "";
  xcopyType: XCOPY_TYPE = XCOPY_TYPE.NOXCOPY;
  xcopyIsOpen = false;

  //--------TASKS ----- ? //TODO:needed??
  openTaskId = "";

  //----------User------
  logged = false;
  userId = "";
  userName = "";
  userEmail = "";
  userDateFormat = "YYYY/MM/DD";
  userDateTimeFormat = "YYYY/MM/DD HH:mm";
  userFirstName = "";
  userLastName = "";
  userCompanyKey: string | null = null;
  userLicenceUsedId: string | null = null;
  userLicenced = false;

  //------KEYCLOAK----
  access_token: string | null = null;

  //---------------------------
  menuOpen: MenuSelectedENUM = MenuSelectedENUM.HOME;
  menu_fileShow = false;
  menu_fileName = "";
  menu_fileType = XCOPY_TYPE.NOXCOPY;
  menu_fileId = "";

  //------TESTING ----
  baseURL = process.env.REACT_APP_SERVER_HOST_START; //"https://app2.limacta.com/";
  loginTypeIAM = false;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  ui_version: UI_VERSION =
    process.env.REACT_APP_UIVERSION === undefined
      ? UI_VERSION.VERSION_2
      : UI_VERSION[String(process.env.REACT_APP_UIVERSION)];

  // //------COMMON------
  // controller:AbortController;

  constructor() {
    makeAutoObservable(this, {
      baseURL: observable,
      language: observable,
      showTestingInfo: observable,
      locationChangeCallBack: observable,
      versions: observable,
      selectedId: observable,
      selectedType: observable,
      wordDocIdent: observable,
      actaId: observable,
      actaName: observable,
      mainXcopyId: observable,
      docLocation: observable,
      actaIsOpen: observable,
      docxIsLoading: observable,
      xcopyId: observable,
      xcopyIsOpen: observable,
      xcopyType: observable,
      openTaskId: observable,
      logged: observable,
      userLicenced: observable,
      userCompanyKey: observable,
      userId: observable,
      userName: observable,
      userEmail: observable,
      IamDocumentOwner: observable,
      ui_version: observable,
      menuOpen: observable,
      isComparedResultOpen: observable,
      menu_fileShow: observable,
      menu_fileName: observable,
      menu_fileType: observable,
      menu_fileId: observable,
      openFileNewWindow: observable,

      // isOwner: observable,

      setShowTestingInfo: action,
      setActiveOpenType: action,
      setLanguage: action,
      setVersions: action,
      setActa: action,
      setWordDocIdent: action,
      setWordIdentReplaced: action,
      setActaId: action,
      setActaName: action,
      setMainXcopyId: action,
      setDocLocation: action,
      setDocxIsLoading: action,
      setUserId: action,
      setUserName: action,
      setUserEmail: action,
      setUserLicenced: action,
      setUserCompanyKey: action,
      setLogged: action,
      setIsOwner: action,
      setXcopyType: action,
      setBaseUrl: action,
      cleanUp: action,
      setUIVersion: action,
      setMenuOpen: action,
      setIsComparedResultOpen: action,
      setMenuFile: action,
      setOpenFileNewWindow: action,
      setWordDocItentOnStart: action,
      setWordDocSomeTextOnStart: action,

      mainCopyIsOpen: computed,
    });

    // this.controller = new AbortController();
    //TODO rewrite to better
  }

  setMenuFile(fileID: string, fileName: string, fileType: XCOPY_TYPE): void {
    this.menu_fileId = fileID;
    this.menu_fileName = fileName;
    this.menu_fileType = fileType;
  }

  setShowTestingInfo(showHide: boolean): void {
    this.showTestingInfo = showHide;
  }

  setUIVersion(uiversion: UI_VERSION): void {
    this.ui_version = uiversion;
  }

  /**
   * Set Limacta document Open TYPE
   * @param _fileId
   * @param xcopyId
   * @param xcopyType
   * @param timeStamp
   */
  setActiveOpenType_setup(
    _fileId: string,
    xcopyId: string | null,
    xcopyType: XCOPY_TYPE | null,
    timeStamp: string | null
  ): void {
    // window.console.log("LOGGGGIIIINNN >>>>  ", xcopyId, xcopyType);
    log.info(limaLogTag.LimaStore, `setActiveOpenType_setup: openedDocType `, xcopyId, xcopyType);
    if (xcopyId !== null) {
      if (xcopyType == XCOPY_TYPE.MAINXCOPY) {
        if (timeStamp !== null) {
          this.setActiveOpenType(OPENEDFILETYPE_ENUM.MAIN_COPY_VERSION);
        } else {
          this.setActiveOpenType(OPENEDFILETYPE_ENUM.MAIN_COPY);
        }
      } else if (xcopyType == XCOPY_TYPE.FULLXCOPY || xcopyType == XCOPY_TYPE.TRIMXCOPY) {
        if (timeStamp !== null) {
          this.setActiveOpenType(OPENEDFILETYPE_ENUM.COPY_VERSION);
        } else {
          this.setActiveOpenType(OPENEDFILETYPE_ENUM.COPY);
        }
      } else {
        this.setActiveOpenType(OPENEDFILETYPE_ENUM.NONE);
      }
    }
  }

  setActiveOpenType(type: OPENEDFILETYPE_ENUM): void {
    this.openedDocType = type;
    log.info(limaLogTag.LimaStore, `setActiveOpenType: openedDocType [${type}] `);

    switch (type) {
      case OPENEDFILETYPE_ENUM.ACTA:
      case OPENEDFILETYPE_ENUM.MAIN_COPY:
        this.actaIsOpen = true;
        this.xcopyIsOpen = false;
        this.versionIsOpen = false;
        this.xcopyType = XCOPY_TYPE.MAINXCOPY;
        void limaPermissionStore.getPermissions(this.actaId);
        break;
      case OPENEDFILETYPE_ENUM.VERSION:
      case OPENEDFILETYPE_ENUM.MAIN_COPY_VERSION:
        this.actaIsOpen = false;
        this.xcopyIsOpen = false;
        this.versionIsOpen = true;
        this.xcopyType = XCOPY_TYPE.MAINXCOPY;
        void limaPermissionStore.getPermissions(this.actaId);
        break;
      case OPENEDFILETYPE_ENUM.COPY:
        this.actaIsOpen = false;
        this.xcopyIsOpen = true;
        this.versionIsOpen = false;
        void limaPermissionStore.getPermissions(this.actaId);
        break;
      case OPENEDFILETYPE_ENUM.COPY_VERSION:
        this.actaIsOpen = false;
        this.xcopyIsOpen = true;
        this.versionIsOpen = true;
        void limaPermissionStore.getPermissions(this.actaId);
        break;
      case OPENEDFILETYPE_ENUM.APPENDIX:
        this.actaIsOpen = false;
        this.xcopyIsOpen = false;
        this.versionIsOpen = false;
        this.xcopyType = XCOPY_TYPE.NOXCOPY;
        void limaPermissionStore.getPermissions(this.actaId);
        break;
      default:
        this.actaIsOpen = this.xcopyIsOpen = this.versionIsOpen = false;
    }
  }

  setLanguage(lng: string): void {
    this.language = lng;
  }

  setVersions(newVersions: LimaVersions): void {
    log.info(limaLogTag.LimaStore, `setVersions: newVersions [${JSON.stringify(newVersions)}] `);
    this.versions = newVersions;
  }

  /**
   * Set ActaID, actaName, mainXcopyId by using actaID
   *
   * @param actaId
   */
  setActa(actaId: string): void {
    const actaItem = limaActasStore.getActa(actaId);
    if (actaItem == null) {
      log.error(limaLogTag.LimaStore, `setActa: unable to find act for actaId [${actaId}]`);
    } else {
      this.actaId = actaId;
      this.actaName = actaItem.name;
      this.mainXcopyId = actaItem.mainXcopy;
    }
  }

  setWordDocIdent(wordDocIdent: LimaDocData): void {
    this.wordDocIdent = wordDocIdent;
  }

  setWordIdentReplaced(replaced: boolean): void {
    if (this.wordDocIdentReplaced !== replaced) this.wordDocIdentReplaced = replaced;
    //window.console.log(replaced);
  }

  setActaId(actaId: string): void {
    log.info(limaLogTag.LimaStore, `setActaId: actaId [${actaId}] `);
    this.actaId = actaId;
  }

  setActaName(actaName: string): void {
    log.info(limaLogTag.LimaStore, `setActaName: actaName [${actaName}] `);
    this.actaName = actaName;
  }

  setMainXcopyId(mainXcopyId: string): void {
    log.info(limaLogTag.LimaStore, `setMainXcopyId: actaName [${mainXcopyId}] `);
    this.mainXcopyId = mainXcopyId;
  }

  setDocLocation(newDocLocation: string): void {
    log.info(limaLogTag.LimaStore, `setDocLocation: location [${newDocLocation}] `);
    this.docLocation = newDocLocation;
  }

  setDocTimeStamp(newTimeStamp: string): void {
    this.docTimeStamp = newTimeStamp;
  }
  /**
   * Takes care to not start loading multiple files at once
   * @param isLoading is loading state
   */
  setDocxIsLoading(isLoading: boolean): void {
    this.docxIsLoading = isLoading;
  }

  setIsOwner(isOwner?: boolean): void {
    log.info(limaLogTag.LimaStore, `setIsOwner: setIsOwner`, isOwner);
    if (isOwner === undefined) {
      this.IamDocumentOwner = limaActasStore.isOwnerActaId(limaStore.actaId);
    } else {
      this.IamDocumentOwner = isOwner;
    }
  }

  setIsComparedResultOpen(isCoparedResultOpen: boolean): void {
    log.info(limaLogTag.LimaStore, `setIsComparedResultOpen: isCoparedResultOpen`, isCoparedResultOpen);
    this.isComparedResultOpen = isCoparedResultOpen;
  }

  setActaBmks(bmkList: string[]): void {
    log.info(limaLogTag.LimaStore, `setActaBmks: data`, bmkList);
    this.bmksList = bmkList;
  }

  @action setXcopyId(xcopyId: string): void {
    log.info(limaLogTag.LimaStore, `setXcopyId: xcopyId [${xcopyId}] `);
    this.xcopyId = xcopyId;
  }

  setXcopyType(xcopyType: XCOPY_TYPE): void {
    log.info(limaLogTag.LimaStore, `setXcopyType: xcopyType [${xcopyType}] `);
    this.xcopyType = xcopyType;
  }

  @action setXcopyName(xcopyName: string): void {
    this.xcopyName = xcopyName;
  }

  @action setXcopyIsOpen(xcopyIsOpen: boolean): void {
    log.info(limaLogTag.LimaStore, `setXcopyIsOpen: xcopyIsOpen `, xcopyIsOpen);
    this.xcopyIsOpen = xcopyIsOpen;
  }

  @action clearSelect(): void {
    this.selectedId = [];
    this.selectedType = [];
    localStorage.setItem("selecteId", JSON.stringify(this.selectedId));
    localStorage.setItem("selectedType", JSON.stringify(this.selectedType));
  }

  //Add to selection
  // @action select(selectId: string[], selectType: string[]) {
  @action select(selectedItems: SelectedInWord[]): void {
    this.selectedInWord = selectedItems;
    this.selectedId = [];
    this.selectedType = [];
    selectedItems.forEach((item: SelectedInWord) => {
      this.selectedId.push(item.id);
      this.selectedType.push(item.type);
    });
    log.info(
      limaLogTag.LimaStore,
      `select: selected Items IDs,Types`,
      this.selectedInWord,
      this.selectedId,
      this.selectedType
    );
  }

  setUser(userId: string, userName: string, userEmail: string): void {
    log.info(limaLogTag.LimaStore, `setUser: userId [${userId}] userName [${userName}] userEmail [${userEmail}] `);
    this.setUserId(userId);
    this.setUserName(userName);
    this.setUserEmail(userEmail);
    // if (userId === undefined || userId === null || userId === "") limaPermissionStore.userPermissons.clearPermissions();
    // else void limaPermissionStore.getPermissions();
  }

  setUserGetPerm(userId: string): void {
    log.info(limaLogTag.LimaStore, `setUserGetPerm: userId [${userId}]  `);
    this.setUserId(userId);
    // this.setUserName(userName);
    // this.setUserEmail(userEmail);
    if (userId === undefined || userId === null || userId === "") limaPermissionStore.clearPermissions();
    else void limaPermissionStore.getPermissions();
  }

  setLoginUser(
    userId: string,
    //userName: string,
    //userEmail: string,
    lang: string,
    userName: string,
    userFirstName?: string,
    userLastName?: string
  ): void {
    //this.setUserAndGetPerm(userId, userName, userEmail);
    console.log(">>>> USER LOG", userId, lang, userName);
    this.setLogged(true);
    this.setUserName(userName);
    this.userFirstName = userFirstName;
    this.userLastName = userLastName;
    this.userId = userId;

    if (lang === "" || userName === null || userName === "") {
      void limaUsersStore.getUserFromDB(userId).then((data: IUser | null) => {
        if (data !== null) {
          if (data.lang !== undefined && data.lang !== null) {
            this.setLanguage(data.lang);
          } else {
            this.setLanguage("en");
          }
          if (data.userName !== undefined && data.userName !== null) {
            this.setUserName(data.userName);
          }
        }
      });
    } else {
      this.setLanguage(lang);
    }

    this.selectionSubscribe();

    //Do processing login
    void limaObjTagsStore.getTagsFirstLoad();

    log.info(limaLogTag.LimaStore, "loginWithEmail: logged", limaStore.getUser());
  }

  setOpenFileNewWindow(newState: boolean): void {
    this.openFileNewWindow = newState;
  }

  @computed getUser(): UserPlugin {
    log.info(
      limaLogTag.LimaStore,
      `getUser: userId [${this.userId}] userName [${this.userName}] userEmail [${this.userEmail}] `
    );
    return { userId: this.userId, userName: this.userName, userEmail: this.userEmail };
  }

  setUserId(userId: string): void {
    log.info(limaLogTag.LimaStore, `setUserId: setUserID [${userId}] `);
    this.userId = userId;
    localStorage.setItem("userId", limaStore.userId);
  }
  setUserName(userName: string): void {
    log.info(limaLogTag.LimaStore, `setUserName: userName [${userName}] `);
    this.userName = userName;
  }
  setUserEmail(userEmail: string): void {
    log.info(limaLogTag.LimaStore, `setUserEmail: userEmail [${userEmail}] `);
    this.userEmail = userEmail;
  }

  setAccessToken(newToken: string): void {
    //log.info(tag.CommonStore, `setAccessToken: token is [${newToken}]`);
    log.info(limaLogTag.LimaStore, `setAccessToken: new token set`);
    this.access_token = newToken;
  }

  setLogged(loggedOrNot: boolean | undefined): void {
    log.info(limaLogTag.LimaStore, `setLogged: is logged [${String(loggedOrNot)}]`);
    this.logged = loggedOrNot;
  }

  setUserLicenced(licenced: boolean): void {
    this.userLicenced = licenced;
  }

  setUserCompanyKey(companyKey: string): void {
    this.userCompanyKey = companyKey;
  }

  setUserLicenceUsedId(newUserLicenceUsedId: string): void {
    this.userLicenceUsedId = newUserLicenceUsedId;
  }

  /**
   * Set inforamtin that current open documetn is base Acta
   */

  @action toActaDoc(): void {
    log.info(limaLogTag.LimaStore, `toActaDoc: start `);
    this.actaIsOpen = true;
    this.xcopyIsOpen = false;
    this.xcopyId = "";
    void limaPermissionStore.getPermissions(this.actaId);
  }

  /**
   * Set inforamtion that current open documetn is XCopy
   */
  @action toXcopyDoc(): void {
    log.info(limaLogTag.LimaStore, `toXcopyDoc: start `);
    this.actaIsOpen = false;
    this.xcopyIsOpen = true;
    void limaPermissionStore.getPermissions("");
  }
  // @action setLoader(load: boolean) {
  //   this.showLoader = load;
  // }

  @computed get userIdent(): string {
    return `${this.userName}[${this.userId}](email:${this.userEmail})`;
  }

  @computed get fileOpenIdent(): string {
    let ident = "";
    if (this.actaIsOpen) ident += `acta:${this.actaName}[${this.actaId}]`;
    if (this.xcopyIsOpen) {
      ident += `xcop:[${this.xcopyId}](onActa:${this.actaName}[${this.actaId}])`;
      ident += `type:${this.xcopyType}`;
    }

    return ident;
  }

  /**
   * For testing only, setu url of Backend
   * @param baseUrl
   */
  setBaseUrl(baseUrl: string): void {
    this.baseURL = baseUrl;
    // void limaServerVersion().then((versions: LimaVersions) => this.setVersions(versions));

    void getVersionParallel().then((versions: LimaVersions) => this.setVersions(versions));
  }

  @action setLoginTypeIAM(IAMlogin: boolean): void {
    this.loginTypeIAM = IAMlogin;
  }

  setLocationChangeCallBack(newCallBack: unknown | null): void {
    this.locationChangeCallBack = newCallBack;
  }

  processLocationChangeCallBack(): void {
    if (this.locationChangeCallBack !== null && typeof this.locationChangeCallBack == "function") {
      this.locationChangeCallBack();
      this.locationChangeCallBack = null;
    }
  }

  selectionSubscribe(): void {
    subscribeToChangeSelection();
  }
  selectionUnSubscribe(): void {
    unSubscribeToChangeSelection();
  }

  /**
   * Method used when user logout
   * Clear all items
   * @author MS
   */
  cleanUp(): void {
    this.setUser("", "", "");
    this.setUserGetPerm("");
    localStorage.setItem("userId", "");
    this.setActaId("");
    this.setActaName("");
    this.selectedId = [];
    this.selectedType = [];
    this.setXcopyId("");
    this.xcopyIsOpen = false;
    this.actaIsOpen = false;
    this.openedDocType = OPENEDFILETYPE_ENUM.NONE;
    this.setIsOwner(false);
    this.setDocxIsLoading(false);
    this.menuOpen = MenuSelectedENUM.HOME;
    this.menu_fileShow = false;
    this.menu_fileName = "";
    this.menu_fileType = XCOPY_TYPE.NOXCOPY;
    this.menu_fileId = "";
    this.userCompanyKey = null;
    this.userLicenced = false;
    this.firstStart = true;
    this.wordDocItentOnStart = false;
    this.wordDocSomeTextOnStart = false;

    closeActiveDialog();

    localStorage.removeItem("userId");
    localStorage.removeItem("actaId");
    localStorage.removeItem("selecteId");
    localStorage.removeItem("selectedType");
    localStorage.removeItem("judge");
    localStorage.removeItem("actPar");

    limaActParStore.onLogoutClear();
    limaActasStore.onLogoutClear();
    limaBreadCrumbStore.onLogoutClear();
    limaObjTagsStore.onLogoutClear();
    limaPermissionStore.onLogoutClear();
    limaTasksStore.onLogoutClear();
    limaTasksStoreV4.onLogoutClear();
    limaUsersStore.onLogoutClear();

    limaDialogStore.onLogoutClear();
    limaJudgeStore.onLogoutClear();
    limaKeywordsStore.onLogoutClear();
    LimaLogsStore.onLogoutClear();
    limaLoadeStore.onLogoutClear();
    limaNotesStore.onLogoutClear();
    limaSearchStore.onLogoutClear();
    limaTagsStore.onLogoutClear();
    limaTasksVersionsStore.onLogoutClear();
  }

  setWordDocItentOnStart(wordOnStart: boolean): void {
    this.wordDocItentOnStart = wordOnStart;
  }

  setWordDocSomeTextOnStart(isWordTextOnStart: boolean): void {
    this.wordDocSomeTextOnStart = isWordTextOnStart;
  }
  /**
   * Get current formated date
   *
   * @returns now formated
   *
   * @author MS
   */
  issueDate(): string {
    const m = moment(); // get "now" as a moment
    return m.format();
  }

  @computed get isLinkableDocumentOpen(): boolean {
    log.info(limaLogTag.LimaStore, `isLinkableDocumentOpen: `, this.openedDocType);
    if (this.isMainCopyOpen() === true || this.isAnyActiveWorkingcopyOpen() === true) {
      log.info(limaLogTag.LimaStore, `isLinkableDocumentOpen: TRUE`, this.openedDocType);
      return true;
    }
    return false;

    // if (this.openedDocType == OPENEDFILETYPE_ENUM.NONE || this.openedDocType == OPENEDFILETYPE_ENUM.APPENDIX)
    //   return false;
    // else return true;
  }

  isMainCopyOpen(): boolean {
    if (this.openedDocType == OPENEDFILETYPE_ENUM.MAIN_COPY && (this.docTimeStamp === null || this.docTimeStamp === ""))
      return true;
    else return false;
  }

  get mainCopyIsOpen(): boolean {
    if (this.openedDocType == OPENEDFILETYPE_ENUM.MAIN_COPY && (this.docTimeStamp === null || this.docTimeStamp === ""))
      return true;
    else return false;
  }

  isVersionOpen(): boolean {
    if (this.openedDocType == OPENEDFILETYPE_ENUM.COPY_VERSION || OPENEDFILETYPE_ENUM.MAIN_COPY_VERSION) return true;
    if (this.openedDocType == OPENEDFILETYPE_ENUM.MAIN_COPY && this.docTimeStamp !== null && this.docTimeStamp !== "")
      return true;
    else return false;
  }

  isAnyXcopyOpen(): boolean {
    //if (this.openedDocType == OPENEDFILETYPE_ENUM.MAIN_COPY) return false;
    if (this.openedDocType == OPENEDFILETYPE_ENUM.NONE) return false;
    return true;
  }

  isAnyActiveWorkingcopyOpen(): boolean {
    // if (
    //   this.openedDocType == OPENEDFILETYPE_ENUM.MAIN_COPY ||
    //   this.openedDocType == OPENEDFILETYPE_ENUM.MAIN_COPY_VERSION ||
    //   this.openedDocType == OPENEDFILETYPE_ENUM.APPENDIX ||
    //   this.openedDocType == OPENEDFILETYPE_ENUM.ACTA ||
    //   this.openedDocType == OPENEDFILETYPE_ENUM.NONE ||
    //   this.openedDocType == OPENEDFILETYPE_ENUM.COPY_VERSION
    // )
    if (this.openedDocType === OPENEDFILETYPE_ENUM.COPY) return true;
    return false;
  }

  isFirstStart(): boolean {
    log.info(limaLogTag.LimaStore, `isFirstStart: test`);
    if (this.firstStart === true) {
      //Check some text
      log.info(limaLogTag.LimaStore, `isFirstStart: check some text`);
      void getWordHaveSomeText().then((isSomeText: boolean) => {
        log.info(limaLogTag.LimaStore, `isFirstStart: check some text result`, isSomeText);
        this.setWordDocSomeTextOnStart(isSomeText);
      });
      //Check wordDocParams
      log.info(limaLogTag.LimaStore, `isFirstStart: check some doc params`);
      void getWordDocParams().then((data) => {
        log.info(limaLogTag.LimaStore, `isFirstStart: check some doc params result`, data);
        if (data.limactaDocType !== LimaDocTypeENUM.none && data.limactaDocID !== null) {
          this.setWordDocItentOnStart(true);
        }
        this.setWordDocIdent(data);
      });
      this.firstStart = false;
      return true;
    }
    return false;
  }

  @computed isWordContainsSomethingOnStart(): boolean {
    log.info(
      limaLogTag.LimaStore,
      `isWordContainsSomethingOnStart: current state is `,
      this.wordDocItentOnStart,
      this.wordDocSomeTextOnStart
    );
    if (this.wordDocSomeTextOnStart === true || this.wordDocItentOnStart === true) return true;
    return false;
  }

  @computed isWordDocIdentInDocument(): boolean {
    log.info(limaLogTag.LimaStore, `isWordDocIdentInDocument: current state is `, this.wordDocItentOnStart);
    if (this.wordDocIdent.limactaDocID === null && this.wordDocIdent.limactaDocType === LimaDocTypeENUM.none) {
      return false;
    }
    return true;
  }

  @computed isWordDocIdentIsOpenInLima(): boolean {
    log.info(
      limaLogTag.LimaStore,
      `isWordDocIdentIsOpenInLima: actaId [${this.actaId}] xcopyId [${this.xcopyId}] wodDocID [${this.wordDocIdent.limactaDocID}] type [${this.openedDocType}] wordType [${this.wordDocIdent.limactaDocType}]`
    );
    if (this.wordDocIdentReplaced === true) return true;

    if (this.wordDocIdent.limactaDocID === this.xcopyId) {
      return true;
    } else if (this.xcopyIsOpen === false && this.actaIsOpen === false && this.wordDocIdent.limactaDocID === null) {
      return true;
    }

    return false;
  }

  setMenuOpen(currentMenuOpen: MenuSelectedENUM): void {
    if (currentMenuOpen !== this.menuOpen) {
      this.menuOpen = currentMenuOpen;
    }
  }
}

const limaStore = new LimaStore();

export default limaStore;
