export enum LimaObjectTypesEnum {
  ACTA = "ACTA",
  XCOPY = "XCOPY",
  VERSION = "VERSION",
  PARA = "PARA",
  WORD = "WORD",
  COMMENT = "COMMENT",
  TASK = "TASK",
}
export enum LIMA_ITEM_TYPE {
  ACTA = "ACTA",
  ACTA2PARA = "ACTA2PARA",
  ACTA2OWNER = "ACTA2OWNER",
  ACTAHISTORY = "ACTAHISTORY",
  PARA = "PARA",
  PARA2WORD = "PARA2WORD",
  PARA2PARA = "PARA2PARA",
  WORD = "WORD",
  WORD2WORD = "WORD2WORD",
  WORDSTYLE = "WORDSTYLE",
  COMMENT = "COMMENT",
  COMPANY = "COMPANY",
  USER = "USER",
  USER2TASK = "USER2TASK",
  USER2XCOPY = "USER2XCOPY",
  TASK = "TASK",
  TASK2ACTA = "TASK2ACTA",
  TASK2PARA = "TASK2PARA",
  TASK2COMMENT = "TASK2COMMENT",
  TASK2XCOPY = "TASK2XCOPY",
  TASKHISTORY = "TASKHISTORY",
  NOTE = "NOTE",
  DATAITEM = "DATAITEM",
  XCOPY = "XCOPY",
  XCOPY2ACTA = "XCOPY2ACTA",
  XCOPY2PARA = "XCOPY2PARA",
  XCOPY2COMM = "XCOPY2COMM",
  XCOPY2WORD = "XCOPY2WORD",
  UNKNOWN = "UNKNOWN",
  COMM2ITEM = "COMM2ITEM",
  COMM2COMM = "COMM2COMM",
  FIELDREF = "FIELDREF",
  BOOKMARK_START = "BOOKMARK_START",
  BOOKMARK_END = "BOOKMARK_END",
  FOOTNOTE = "FOOTNOTE",
}
