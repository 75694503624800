import { IStackTokens, Stack } from "@fluentui/react";
import { log } from "missionlog";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { urlAccessGetVisibility2, urlAccessPostVisibility2, urlAccessPutVisibility2 } from "../../../../api/apilinks";
import { httpDeleteByPUTAuth, httpGetAuth, httpPostAuth, HttpResponse } from "../../../../api/httpAPI";
import { userAccListDataTypeLinked } from "../../../../dataModels/userAccListDataType";
import { changeOnArray } from "../../../../limaCommon/limaArrayHelpers";
import { limaLogTag } from "../../../../limaCommon/limaLog";
// import { LimaHideableSection } from "../../../../limaComponents/LimaHideableSection";
import { LimaLoadingComponnet } from "../../../../limaComponents/LimaLoadingComponnet";
import { LimaNotice, LIMA_TEXTNOTICETYPE } from "../../../../limaComponents/LimaNotice";
import limaLoadeStore, { LoaderItemEnd } from "../../../../store/limaLoaderStore";
import limaStore from "../../../../store/limaStore";
import limaUsersStore from "../../../../store/limaUsersStore";
import { LIMA_ITEM_TYPE } from "../../../../types/BASE_Enums";
import { IUser } from "../../../../types/User";
import { User2Task } from "../../../../types/User2Task";
import { User2User } from "../../../../types/User2User";
import { User2Xcopy } from "../../../../types/User2Xcopy";
import { Visibilitor, VISIBILITOR_ACCESS_TYPE } from "../../../../types/Visibilitor";
import { CALLUSERLINK } from "../../Users/UserInitations/components/User2UserLinksComponentV4";
// import AccessItemChooser from "../../../CommonComponents/Access/components/AccessItemChooser";
import AccessItemV4 from "./Leaf/AccessItemV4";
import { AccessFilterV4 } from "./Leaf/AcessFilterV4";
import { Company2User } from "../../../../types/LimaCompany2User";

interface AccessComponentV4Props {
  itemId?: string;
  type?: LIMA_ITEM_TYPE;
  showLinkedItem?: boolean;
  enableChange?: boolean;
}

const themedSmallStackTokens: IStackTokens = {
  childrenGap: "s1",
  padding: "s1",
};

const AccessComponentV4: FunctionComponent<AccessComponentV4Props> = ({ itemId, type }: AccessComponentV4Props) => {
  const { t } = useTranslation(["tpshow"]);
  const [usersAccessFull, setUsersAccessFull] = useState<userAccListDataTypeLinked[]>([]);
  const [usersAccess, setUsersAccess] = useState<userAccListDataTypeLinked[]>([]);
  const [filterTxt, setFilterTxt] = useState<string>("");
  const [filterLbl, setFilterLbl] = useState<string>("Filter");

  const innerType = useMemo(() => {
    return type ? type : LIMA_ITEM_TYPE.USER;
  }, [type]);

  const { isLoading, error, refetch } = useQuery(
    ["messages", {}],
    // () => fetchAccessList<User2Task | User2Xcopy | User2User>(innerItemId, innerType),
    () => fetchAccessList<User2Task | User2Xcopy | User2User | Company2User>(itemId, innerType),
    {
      enabled: false,
      onError: (err) => {
        log.error(limaLogTag.AccessComponent, "stat loaded error: ", err);
        setUsersAccess([]);
      },
      onSuccess: (data: (User2Task | User2Xcopy | User2User)[]) => {
        log.info(limaLogTag.AccessComponent, "useQuery/onSuccess data,type ", data, innerType);
        log.info(limaLogTag.AccessComponent, "useQuery/onSuccess  access ", usersAccess);
        let userList = limaUsersStore.fullList.map((item: IUser): userAccListDataTypeLinked => {
          return { docEdgeId: "", edgeId: "", position: "", userId: item._key, userName: item.userName };
        });

        data.forEach((item) => {
          userList = changeOnArray<userAccListDataTypeLinked, string, string>(
            userList,
            "userId",
            item.user._key,
            "edgeId",
            item._key
          );
        });
        log.info(limaLogTag.AccessComponent, "useQuery get access ", userList);
        setUsersAccessFull(userList);
        //setUsersAccess(userList);
      },
    }
  );

  const { mutate } = useMutation(
    async (message: Visibilitor) => {
      if (message.itemtype === LIMA_ITEM_TYPE.TASK) return addRemoveLinking<User2Task>(message);
      else if (message.itemtype === LIMA_ITEM_TYPE.USER) return addRemoveLinking<User2User>(message);
      else if (message.itemtype === LIMA_ITEM_TYPE.XCOPY) return addRemoveLinking<User2Xcopy>(message);
      else if (message.itemtype === LIMA_ITEM_TYPE.COMPANY) return addRemoveLinking<Company2User>(message);
      else return null;
    },

    {
      onSuccess: (data: User2Task) => {
        //setMessages([...messages, data]);
        window.console.log("Message>> useMutation onSuccess", data);
        setUsersAccess(
          changeOnArray<userAccListDataTypeLinked, string, string>(
            usersAccess,
            "userId",
            data.user._key,
            "edgeId",
            data._key
          )
        );
      },
    }
  );

  //-------------------------
  //---- REACTIONS     ------
  //-------------------------

  useEffect(() => {
    void limaUsersStore.checkLastRead();
  }, []);

  useEffect(() => {
    if (itemId !== undefined && itemId !== null) {
      void refetch();
    }
  }, [itemId]);

  useEffect(() => {
    filterChangeHandler(filterTxt);
  }, [usersAccessFull, filterTxt]);

  useEffect(() => {
    setFilterLbl(`Filter [${usersAccess.length}/${usersAccessFull.length}]`);
  }, [usersAccess]);
  //-------------------------
  //---- Handlers    ------
  //-------------------------

  const filterChangeHandler = (filterTxt: string) => {
    setUsersAccess(
      usersAccessFull.filter((item: userAccListDataTypeLinked) => {
        if (item.userName === undefined || item.userName == null || item.userName == "") return false;
        else if (item.userName.toLowerCase().indexOf(filterTxt.toLowerCase()) >= 0) return true;
        return false;
      })
    );
  };

  const handleClickAccess = (userId: string, edgeId: string, accessType: VISIBILITOR_ACCESS_TYPE): void => {
    log.info(limaLogTag.AccessComponent, "clicked on user and edge", userId, edgeId, accessType);

    if (accessType === VISIBILITOR_ACCESS_TYPE._REMOVE) {
      // mutate({ userid: limaStore.userId, foruser: userId, itemid: innerItemId, itemtype: innerType, create: false });
      mutate({
        userid: limaStore.userId,
        foruser: userId,
        itemid: itemId,
        itemtype: innerType,
        create: false,
        state: CALLUSERLINK.OTHER,
      });
      log.info(limaLogTag.AccessComponent, "clicked remove on user and edge", userId, edgeId);
    } else if (accessType === VISIBILITOR_ACCESS_TYPE.CAN_EDIT || VISIBILITOR_ACCESS_TYPE.COMPANY_LINK) {
      // mutate({ userid: limaStore.userId, foruser: userId, itemid: innerItemId, itemtype: innerType, create: true });
      mutate({
        userid: limaStore.userId,
        foruser: userId,
        itemid: itemId,
        itemtype: innerType,
        create: true,
        state: CALLUSERLINK.OTHER,
      });
      log.info(limaLogTag.AccessComponent, "clicked create on user", userId);
    } else {
      log.warn(limaLogTag.AccessComponent, "clicked on not covered state", userId, accessType);
    }
  };

  return (
    <Stack>
      <Stack.Item>
        {usersAccess && (
          <LimaNotice noticeType={LIMA_TEXTNOTICETYPE.INFO}>
            {t("tpshow:access.uniaccess.notifyimmediatechangeLbl")}
          </LimaNotice>
        )}
      </Stack.Item>
      <Stack.Item>{usersAccess && <AccessFilterV4 onChangeClb={setFilterTxt} label={filterLbl} />}</Stack.Item>
      {itemId === undefined || itemId === null ? (
        <Stack.Item>{t("tpshow:access.uniaccess.userslist.notifyitemfirstLbl")}</Stack.Item>
      ) : isLoading === true ? (
        <Stack.Item>{LimaLoadingComponnet(3, "accComp")}</Stack.Item>
      ) : error === true ? (
        <Stack.Item>{t("tpshow:access.uniaccess.userslist.errorgettindataLbl")}</Stack.Item>
      ) : usersAccess === null || usersAccess.length === 0 ? (
        <Stack.Item>{t("tpshow:access.uniaccess.userslist.nousersLbl")}</Stack.Item>
      ) : (
        <Stack.Item>
          {usersAccess && (
            <Stack tokens={themedSmallStackTokens}>
              {usersAccess.map((item: userAccListDataTypeLinked, index: number) => (
                <AccessItemV4
                  key={`${item.userId}_${index}`}
                  userItem={item}
                  onClickCallback={handleClickAccess}
                  accessTypes={[VISIBILITOR_ACCESS_TYPE.COMPANY_LINK, VISIBILITOR_ACCESS_TYPE._REMOVE]}
                />
              ))}
            </Stack>
          )}
        </Stack.Item>
      )}
    </Stack>
  );
};

export default AccessComponentV4;

//----------------------
//   REACT QUERY
//----------------------

async function fetchAccessList<T>(linkedId: string, type: LIMA_ITEM_TYPE): Promise<T[]> {
  log.info(limaLogTag.AccessComponent, "fetching data fetchAccessList");
  // const [, { patientID }] = params.queryKey;
  const loaderID = limaLoadeStore.add("Getting acceslist from DB");
  let response: HttpResponse<T[]>;
  try {
    if (
      type === LIMA_ITEM_TYPE.TASK ||
      type === LIMA_ITEM_TYPE.USER ||
      type === LIMA_ITEM_TYPE.XCOPY ||
      type === LIMA_ITEM_TYPE.COMPANY
    )
      response = await httpGetAuth<T[]>(urlAccessGetVisibility2(linkedId, type), loaderID.ac);
    else {
      log.warn(limaLogTag.AccessComponent, "not implemented type - ", type);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw response;
    }
    log.info(limaLogTag.AccessComponent, "fetching data fetchAccessList response", response);
    if (!response.ok) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw response;
    }
    if (response.parsedBody !== undefined) {
      if (Array.isArray(response.parsedBody))
        log.info(limaLogTag.AccessComponent, "fetching data fetchAccessList response.parsedBody", response.parsedBody);
      //response.parsedBody.forEach((item:any)=>assertIsDB_Survey(item))
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return response.parsedBody;
    }
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error("no data");
  } catch (error) {
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error(error);
  }
}

async function addRemoveLinking<T>(item: Visibilitor): Promise<T> {
  let response: HttpResponse<T>;
  // const user2Item: User2Task | User2Xcopy | User2User;
  if (item.itemid === null || item.itemid === undefined) {
    log.error(limaLogTag.AccessComponent, "useMutation post/put missing id ", item);
    throw null;
  }

  if (
    item.itemtype === LIMA_ITEM_TYPE.TASK ||
    item.itemtype === LIMA_ITEM_TYPE.USER ||
    item.itemtype === LIMA_ITEM_TYPE.XCOPY ||
    item.itemtype === LIMA_ITEM_TYPE.COMPANY
  ) {
    const loaderID = limaLoadeStore.add("add/change visilibtility on item");
    try {
      log.info(limaLogTag.AccessComponent, "useMutation post/delete visibility item type", item.itemtype);
      if (item.create === true) {
        log.info(limaLogTag.AccessComponent, "useMutation POST call");
        response = await httpPostAuth<T>(urlAccessPostVisibility2(), item, loaderID.ac);
      } else {
        log.info(limaLogTag.AccessComponent, "useMutation DELETE call");
        response = await httpDeleteByPUTAuth<T>(urlAccessPutVisibility2(), item, loaderID.ac);
      }
    } finally {
      limaLoadeStore.remove(loaderID.k);
    }
  } else {
    log.error(limaLogTag.AccessComponent, "useMutation post/put visibility item unknown type", item.itemtype);
    return null;
  }

  if (!response.ok) {
    log.error(limaLogTag.AccessComponent, "useMutation post/put visibility item ", response);
    throw response;
  }
  log.info(limaLogTag.AccessComponent, "fetching post visibility item response.parsedBody", response.parsedBody);

  const itemOut = response.parsedBody;
  if (item.create === false) itemOut["_key"] = "";

  return itemOut;
}
