export interface LimaAttachement {
  _key: string;
  _id: string;
  cTimeStamp: string;
  cIssuedBy: string;
  name: string;
  filename: string;
  filesize: string;
  fileuri: string;
  description: string;
  fileType: string;
}

//http://www.java2s.com/Code/Java/Network-Protocol/MapfileextensionstoMIMEtypesBasedontheApachemimetypesfile.htm
export const validTypes = [
  //images
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/gif",
  "image/x-icon",
  //text
  "text/plain",
  //Word
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  //Excel
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //emails
  "message/rfc822",
  //pdf
  "application/pdf",
];

export const enum FILE_TYPE {
  IMAGE,
  EXCEL,
  WORD,
  PDF,
  EMAIL,
  TEXT,
  OTHER,
}
export const getFileType = (fileTypeString: string): FILE_TYPE => {
  if (fileTypeString === undefined || fileTypeString === null || fileTypeString === "") return FILE_TYPE.OTHER;

  if (fileTypeString.startsWith("image/")) return FILE_TYPE.IMAGE;

  if (fileTypeString === "message/rfc822") return FILE_TYPE.EMAIL;

  if (fileTypeString === "application/pdf") return FILE_TYPE.PDF;

  if (
    fileTypeString === "application/msword" ||
    fileTypeString === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  )
    return FILE_TYPE.WORD;

  if (
    fileTypeString === "application/vnd.ms-excel" ||
    fileTypeString === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  )
    return FILE_TYPE.WORD;

  if (fileTypeString.startsWith("text/")) return FILE_TYPE.TEXT;

  return FILE_TYPE.OTHER;
};

export interface IAttachmentFileWithInfo {
  file: File;
  filename: string;
  filedescription: string;
}
