import { IconButton, IIconProps, IStackTokens, Stack } from "@fluentui/react";
import { FunctionComponent, useState } from "react";
import React = require("react");
import { UseMutateAsyncFunction } from "react-query";
import { LimaNameInitals } from "../../../../../limaComponents/LimaNameInitals";
import { User2User } from "../../../../../types/User2User";
import { AcceptLinkingObject, CALLUSERLINK, TOorFROM } from "../User2UserLinkComponent";

interface User2UserLinkItemProps {
  item: User2User;
  type: TOorFROM;
  onCallBack?: UseMutateAsyncFunction<User2User[], unknown, AcceptLinkingObject, { id: number }>;
}

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 0,
  maxWidth: "95%",
};

const AcceptIcon: IIconProps = { iconName: "AddFriend" };
const RejectIcon: IIconProps = { iconName: "BlockContact" };
const CancelIcon: IIconProps = { iconName: "ChevronDown" };

const User2UserLinkItem: FunctionComponent<User2UserLinkItemProps> = ({
  item,
  type,
  onCallBack,
}: User2UserLinkItemProps) => {
  const [error, setError] = useState<boolean>(false);
  const [solved, setSolved] = useState<boolean>(false);

  const onCallCallBack = (type: CALLUSERLINK) => {
    onCallBack({ user2user: item, type: type })
      .then(() => {
        error && setError(false);
        setSolved(true);
      })
      .catch(() => {
        !error && setError(true);
      });
  };

  return (
    <>
      {!solved && (
        <Stack horizontal tokens={stackTokens}>
          {error && <Stack.Item>EROR</Stack.Item>}
          <Stack.Item>
            {type === TOorFROM.FROM ? (
              //Its mee
              <LimaNameInitals>{item.user.userName}</LimaNameInitals>
            ) : (
              //Its other person
              <>
                {item.user.userName}({item.user.userEmail})
              </>
            )}
          </Stack.Item>
          <Stack.Item>{" --> "}</Stack.Item>
          <Stack.Item>
            {type === TOorFROM.FROM ? (
              <>
                {item.userto.userName}({item.userto.userEmail})
              </>
            ) : (
              <LimaNameInitals>{item.userto.userName}</LimaNameInitals>
            )}
          </Stack.Item>
          <Stack.Item>{"state:" + item.state}</Stack.Item>
          <Stack.Item>
            {type === TOorFROM.FROM ? (
              <Stack horizontal>
                <Stack.Item>?? Resend</Stack.Item>
                <Stack.Item>
                  <IconButton
                    // className={classNames.itemCellIcon}
                    iconProps={CancelIcon}
                    title={"Cancel"}
                    onClick={() => onCallCallBack(CALLUSERLINK.CANCEL)}
                  />
                </Stack.Item>
              </Stack>
            ) : (
              <Stack horizontal>
                <Stack.Item>
                  <IconButton
                    // className={classNames.itemCellIcon}
                    iconProps={AcceptIcon}
                    title={"Accept"}
                    onClick={() => onCallCallBack(CALLUSERLINK.ACCEPT)}
                  />
                </Stack.Item>
                <Stack.Item>
                  <IconButton
                    // className={classNames.itemCellIcon}
                    iconProps={RejectIcon}
                    title={"Reject"}
                    onClick={() => onCallCallBack(CALLUSERLINK.REJECT)}
                  />
                </Stack.Item>
              </Stack>
            )}
          </Stack.Item>
        </Stack>
      )}
    </>
  );
};

export default User2UserLinkItem;
