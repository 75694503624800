import { IStackProps, mergeStyleSets, Stack } from "@fluentui/react";
// import { observer } from "mobx-react-lite";
import { ReactElement, ReactNode } from "react";
import React = require("react");
import limaStore from "../../../../store/limaStore";

type TaskItemDebugInfoProps = {
  children?: ReactNode;
};

const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 0 },
  styles: { root: { width: "100%" } },
};

const classNames = mergeStyleSets({
  wrapper: {
    background: "lightgrey",
  },
});

export const ItemDebugInfoV4 = (props: TaskItemDebugInfoProps): ReactElement => {
  return (
    <>
      {limaStore.showTestingInfo && (
        <Stack horizontalAlign="start" {...columnProps} className={classNames.wrapper}>
          {props.children}
        </Stack>
      )}
    </>
  );
};
