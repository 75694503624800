import { urlLimaAIVersion, urlLimaBEVersion } from "../api/apilinks";
import { httpGet, HttpResponse } from "../api/httpAPI";
import { aiVersionInfoOK, beVersionInfoOK } from "../types/LimactaVersions";
import { log } from "missionlog";
import { limaFEversion } from "./limaEnv";
import { limaLogTag } from "./limaLog";
import limaLoadeStore, { LoaderItemEnd } from "../store/limaLoaderStore";
// log.init({ LimaServerVersion: "LimaServerVersion" });

export interface LimaVersions {
  limaBEVersionId: string;
  limaFEVersionId: string;
  limaAIVersionId: string;
}

export const limaVersionsDef: LimaVersions = {
  limaBEVersionId: "--",
  limaFEVersionId: "--",
  limaAIVersionId: "--",
};

export const getVersionParallel = async (): Promise<LimaVersions> => {
  log.info(limaLogTag.LimaServerVersion, `limaServerVersion: call get versions`);
  // const aiVersion = await Promise.allSettled([versionBE(), versionAI()]);
  const versionsOUt = {
    limaAIVersionId: "",
    limaBEVersionId: "",
    limaFEVersionId: limaFEversion,
  };
  try {
    await versionBE().then((resultOfBE) => (versionsOUt.limaBEVersionId = resultOfBE)); // the `return` is implied since it's a one-liner
    await versionAI().then((resultOfBE) => (versionsOUt.limaAIVersionId = resultOfBE));
  } catch (error) {
    log.info(limaLogTag.LimaServerVersion, "getVersionParallel: call get versions", error);
  }
  // result[0].status
  return Promise.resolve(versionsOUt);
};

const versionBE = () =>
  new Promise<string>((resolve, reject) => {
    const loaderID = limaLoadeStore.add("Getting BE version");

    try {
      void httpGet<beVersionInfoOK>(urlLimaBEVersion(), loaderID.ac).then(
        (responseInner: HttpResponse<beVersionInfoOK>) => {
          log.info(limaLogTag.LimaServerVersion, "versionBE: call get versions", responseInner);
          if (responseInner && responseInner.parsedBody !== undefined) {
            log.info(limaLogTag.LimaServerVersion, "versionBE: parsedBody is not null", responseInner.parsedBody);
            limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
            resolve(responseInner.parsedBody.appVersion);
          } else {
            // throw new Error("...No data...");
            limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
            reject();
          }
        }
      );
    } catch (response) {
      log.error(limaLogTag.LimaServerVersion, "versionBE Error call get data", response);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw new Error("Problem geting data");
    }
  });

const versionAI = () =>
  new Promise<string>((resolve, reject) => {
    const loaderID = limaLoadeStore.add("Getting AI version");

    try {
      void httpGet<aiVersionInfoOK>(urlLimaAIVersion(), loaderID.ac).then(
        (responseInner: HttpResponse<aiVersionInfoOK>) => {
          log.info(limaLogTag.LimaServerVersion, "versionAI: call get versions", responseInner);
          if (responseInner && responseInner.parsedBody !== undefined) {
            log.info(limaLogTag.LimaServerVersion, "versionAI: parsedBody is not null", responseInner.parsedBody);
            limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
            resolve(responseInner.parsedBody.version);
          } else {
            // throw new Error("...No data...");
            limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
            reject();
          }
        }
      );
    } catch (response) {
      log.error(limaLogTag.LimaServerVersion, "versionBE Error call get data", response);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw new Error("Problem geting data");
    }
  });

export const limaServerVersion = async (): Promise<LimaVersions> => {
  return new Promise((resolve, reject) => {
    const loaderID = limaLoadeStore.add("Getting versions");
    try {
      Promise.all([
        httpGet<beVersionInfoOK>(urlLimaBEVersion(), loaderID.ac),
        httpGet<aiVersionInfoOK>(urlLimaAIVersion(), loaderID.ac),
      ])
        .then((results) => {
          log.info(limaLogTag.LimaServerVersion, `limaServerVersion: call get versions`, results);
          resolve({
            limaAIVersionId: results[1].parsedBody.version,
            limaBEVersionId: results[0].parsedBody.appVersion,
            limaFEVersionId: limaFEversion,
          });
        })
        .catch((error) => {
          log.error(limaLogTag.LimaServerVersion, `limaServerVersion: Error call get versions`, error);
          reject(limaVersionsDef);
        });
    } catch (error) {
      log.error(limaLogTag.LimaServerVersion, `limaServerVersion: Error call get versions`, error);
      reject(limaVersionsDef);
    } finally {
      limaLoadeStore.remove(loaderID.k);
    }
  });
};
