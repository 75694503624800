export interface getNoteItemFailed {
    message: string;
}

export interface getNoteItemOK {
    _id: string;
    _key: string;
    cIssuedBy: string;
    dIssuedBy: string;
    cTimeStamp: string;
    lcIssuedBy: string;
    dTimeStamp: string;
    lcTimeStamp: string;
    content: string;
    noteShort: string;
    noteAttachment: string;
    actaId: string;
    userId: string;
}

//noteAttachement[];
export interface noteAttachement {
    fileName: string;
    description: string;
    fileUri: string;
}

export const defaultNoteAttachement: noteAttachement = {
    fileName: "",
    description: "",
    fileUri: ""
}

export const defaultNoteItem: getNoteItemOK = {
    _id: "",
    _key: "",
    cIssuedBy: "",
    dIssuedBy: "",
    cTimeStamp: "",
    lcIssuedBy: "",
    dTimeStamp: "",
    lcTimeStamp: "",
    content: "",
    noteShort: "",
    noteAttachment: "",
    actaId: "",
    userId: ""
}




