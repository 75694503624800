import { log } from "missionlog";
import { Moment as MomentObject } from "moment";
// import { Stack } from "office-ui-fabric-react";
import * as React from "react";
import { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
// import Moment from "react-moment";

import { useQuery } from "react-query";
import { urlHistoryGetParaHistoryComments, WORDITEMTYPE } from "../../../../../api/apilinks";
import { httpGetAuth, HttpResponse } from "../../../../../api/httpAPI";
// import { paraHistoryOK } from "../../../../../api/schema/getParaHistoryResp";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import { LimaHideableSection } from "../../../../../limaComponents/LimaHideableSection";
import { LimaLoadingComponnet } from "../../../../../limaComponents/LimaLoadingComponnet";
import { LimaNotice } from "../../../../../limaComponents/LimaNotice";
import limaLoadeStore from "../../../../../store/limaLoaderStore";
import limaStore from "../../../../../store/limaStore";
import { History_comments } from "../../../../../types/LimactaComment";
import { SelectedInWord } from "../../../../../types/wordObjects";
// import CommentGroup from "./comment/CommentGroup";
import CommentTimeGroup from "./comment/CommentTimeGroup";
// import HistParaItemTextRow from "../HistParaItemParaTextRow";

type HistParaItemParaCommentsItemsProps = {
  dateFrom: MomentObject;
  dateTo: MomentObject;
  selectedItem: SelectedInWord | null;
};
//({ logo, message, title }: HistItemProps)
const HistParaItemParaCommentsItems: FunctionComponent<HistParaItemParaCommentsItemsProps> = ({
  dateFrom,
  dateTo,
  selectedItem,
}: HistParaItemParaCommentsItemsProps) => {
  // const [parHistComments, setParHistComments] = useState<paraHistoryOK[]>();
  const { t } = useTranslation(["tpshow"]);
  const { isLoading, isFetching, isError, refetch, data } = useGetCommnetsQuery(
    (selectedItem && selectedItem.id) || null,
    (selectedItem && selectedItem.type) || null,
    (dateFrom && dateFrom.toISOString()) || null,
    (dateTo && dateTo.toISOString()) || null
  );

  useEffect(() => {
    void refetch();
  }, [dateFrom, dateTo, selectedItem]);

  if (isLoading || isFetching) {
    log.info(limaLogTag.HistParaItemParaCommentsItems, "searching for history comments");
    return (
      <LimaHideableSection name={`${t("tpshow:history.commentsLbl")} `}>{LimaLoadingComponnet(3)}</LimaHideableSection>
    );
  }
  if (isError) {
    //return <div>..error loading...</div>;
    return (
      <LimaHideableSection name={`${t("tpshow:history.commentsLbl")} `}>
        <LimaNotice>{t("tpshow:history.errorloadingLbl")}</LimaNotice>
      </LimaHideableSection>
    );
  }

  let sectionName = `${t("tpshow:history.commentsLbl")} `;
  if (data && data.length > 0) sectionName += `[${data.length}]`;
  //`${t("tpshow:history.commentsLbl")} [${data && data.length}]

  return (
    <LimaHideableSection name={sectionName}>
      {data &&
        data.map((item: History_comments, index: number, array: History_comments[]) => {
          log.info(
            limaLogTag.HistParaItemParaCommentsItems,
            "show history",
            index,
            array.length,
            index < array.length - 1
          );

          return <CommentTimeGroup item={item} key={"_comhist_" + String(index)} hidden={index < array.length - 1} />;
        })}
    </LimaHideableSection>
  );
};
export default HistParaItemParaCommentsItems;

//----------------------
//   REACT QUERY
//----------------------

const useGetCommnetsQuery = (
  itemid: string | null,
  itemtype: WORDITEMTYPE | null,
  datefrom: string | null,
  dateto: string | null
) => {
  return useQuery(
    [
      "userGetHistComments",
      {
        itemid: itemid,
        itemtype: itemtype,
        // userid: limaStore.userId || null,
        datefrom: datefrom,
        dateto: dateto,
      },
    ],
    () => getHistoryOfParaComments(itemid, itemtype, datefrom, dateto),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onError: (err) => {
        log.error(limaLogTag.HistParaItemParaCommentsItems, "useGetCommnetsQuery onError", err);
      },
      onSuccess: (data: History_comments[]) => {
        log.info(limaLogTag.HistParaItemParaCommentsItems, "useGetCommnetsQuery success ", data);
      },
    }
  );

  // QUERY

  async function getHistoryOfParaComments(
    itemid: string,
    itemtype: WORDITEMTYPE,
    // userid: string,
    datefrom: string,
    dateto: string
  ): Promise<History_comments[]> {
    log.info(
      limaLogTag.HistParaItemParaCommentsItems,
      "getHistoryOfParaComments: start loafig",
      itemid,
      itemtype,
      datefrom,
      dateto,
      limaStore.xcopyId
    );
    if (itemid === null) {
      log.info(limaLogTag.HistParaItemParaCommentsItems, "getHistoryOfParaComments: itemid is null");
      return null;
    }
    if (itemtype !== WORDITEMTYPE.para) {
      log.info(limaLogTag.HistParaItemParaCommentsItems, "getHistoryOfParaComments: itemtype not para", itemtype);
      return null;
    }
    if (datefrom === null || dateto === null) {
      log.info(
        limaLogTag.HistParaItemParaCommentsItems,
        "getHistoryOfParaComments: datefrom is null or dateto is null"
      );
      return null;
    }
    const loaderID = limaLoadeStore.add("Getting history from DB");
    let response: HttpResponse<History_comments[]>;
    try {
      response = await httpGetAuth<History_comments[]>(
        urlHistoryGetParaHistoryComments(itemid, limaStore.xcopyId, datefrom, dateto),
        loaderID.ac
      );

      log.info(limaLogTag.HistParaItemParaCommentsItems, "getHistoryOfPara: asketo to get something", response);
      if (response.parsedBody !== undefined) {
        log.info(limaLogTag.HistParaItemParaText, "getHistoryOfPara: parsedBody is not null", response.parsedBody);

        return response.parsedBody;
      } else {
        // throw new Error("...No data...");
        log.error(limaLogTag.HistParaItemParaCommentsItems, "getHistoryOfPara no data");
        return null;
      }
    } catch (response) {
      log.error(limaLogTag.HistParaItemParaCommentsItems, "getHistoryOfPara Error call get data", response);
      // throw new Error("Problem geting data");
      return null;
    } finally {
      limaLoadeStore.remove(loaderID.k);
    }
  }
};
