import { Moment } from "moment";
import moment = require("moment");
import { ButtonType, DatePicker, DefaultButton } from "office-ui-fabric-react";
import { FunctionComponent, useEffect, useState } from "react";
import React = require("react");

interface HistParaDateRangeProps {
  fromDate: Moment;
  toDate: Moment;
  setDate: (fromDate: Moment, toDate: Moment) => void;
  setOnChange?: boolean;
  hideUpdateBtn?: boolean;
}

const HistParaDateRange: FunctionComponent<HistParaDateRangeProps> = ({
  fromDate,
  toDate,
  setDate,
  setOnChange,
  hideUpdateBtn,
}: HistParaDateRangeProps) => {
  const [currentFromDate, setCurrentFromDate] = useState<Moment>(fromDate);
  const [currentToDate, setCurrentToDate] = useState<Moment>(toDate);

  useEffect(() => {
    setCurrentFromDate(fromDate);
    setCurrentToDate(toDate);
  }, []);

  const handlerLoadData = () => {
    setDate(currentFromDate, currentToDate);
  };

  useEffect(() => {
    setOnChange && setDate(currentFromDate, currentToDate);
  }, [currentFromDate, currentToDate]);

  return (
    <>
      <DatePicker
        key="dateFrom"
        label="FROM: "
        value={(currentFromDate && currentFromDate.toDate()) || moment().subtract("1", "M").toDate()}
        initialPickerDate={(fromDate && fromDate.toDate()) || moment().toDate()}
        // initialPickerDate={moment(fromDate).toDate()}
        showMonthPickerAsOverlay={true}
        onSelectDate={(date) => {
          console.log("Date change to ", date);
          setCurrentFromDate(moment(date));
          return date;
        }}
        placeholder="Select a date..."
        ariaLabel="Select a date"
      />
      <DatePicker
        key="dateTo"
        label="TO: "
        value={(currentToDate && currentToDate.toDate()) || moment().toDate()}
        initialPickerDate={(toDate && toDate.toDate()) || moment().toDate()}
        //initialPickerDate={moment(toDate).toDate()}
        showMonthPickerAsOverlay={true}
        onSelectDate={(date) => {
          console.log("Date change to ", date);
          setCurrentToDate(moment(date));
          return date;
        }}
        placeholder="Select a date..."
        ariaLabel="Select a date"
      />
      {!hideUpdateBtn && (
        <DefaultButton buttonType={ButtonType.hero} iconProps={{ iconName: "ChevronRight" }} onClick={handlerLoadData}>
          Update
        </DefaultButton>
      )}
    </>
  );
};

export default HistParaDateRange;
