import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";

import { log, tag } from "missionlog";
import { urlGetTaskHistory } from "../../../api/apilinks";
import { httpGetAuth, HttpResponse } from "../../../api/httpAPI";
import { taskHistoryItem } from "../../../api/schema/tasks/taskHistoryRes";
//import TaskHistorySingleItem from "./TaskHistorySingleItem";
import { DefaultButton, ProgressIndicator } from "office-ui-fabric-react";
import TaskHistorySingleItem from "./TaskHistorySingleItem";
import limaStore from "../../../store/limaStore";
import limaLoadeStore, { LoaderItemEnd } from "../../../store/limaLoaderStore";

log.init({ TaskHistoryHolder: "TaskHistoryHolder" });

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TaskHistoryHolderProps {}
//({ logo, message, title }: HistItemProps)

const TaskHistoryHolder: FunctionComponent<TaskHistoryHolderProps> = () => {
  const [taskHistData, setTaskHistData] = useState<taskHistoryItem[] | null>(null);
  const [progress, setProgress] = useState<boolean>(false);

  log.info(tag.TaskHistoryHolder, `TaskHistoryHolder: Mounted for task id [${limaStore.openTaskId}]`);

  useEffect(() => {
    void onReload();
    log.info(tag.TaskHistoryHolder, `useEffect: mounted`);
    //console.log("TaskHistoryHolder: mounted");
  }, []);

  const onReload = async () => {
    if (limaStore.userId == "" || limaStore.openTaskId == "") {
      log.info(tag.TaskHistoryHolder, `onReload: no userId or selectedTask`);
      return;
    }

    setProgress(true);
    let taskHistData: HttpResponse<taskHistoryItem[]>;
    const loaderID = limaLoadeStore.add("task history on reaload");
    try {
      taskHistData = await httpGetAuth(urlGetTaskHistory(limaStore.openTaskId), loaderID.ac);
      log.info(tag.TaskHistoryHolder, `onReload: getting data`, taskHistData);
      if (taskHistData.parsedBody !== undefined) {
        setTaskHistData(taskHistData.parsedBody);
        setProgress(false);
        console.log("TaskHistoryHolder/onReload: have parsed data");
        console.log(taskHistData);
        limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      }
    } catch (response) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      log.error(tag.TaskHistoryHolder, `onReload: ERROR getting data`, taskHistData);
      console.log("TaskHistoryHolder/onReload: ERROR");
      setProgress(false);
    }
  };

  return (
    <>
      Open task hist Detail {limaStore.openTaskId}
      <DefaultButton text="Reload" onClick={onReload} />
      {progress && (
        <ProgressIndicator
          label="Getting data from server"
          description="data is transfered to limacta server now...  "
        />
      )}
      {taskHistData !== null ? (
        taskHistData.map((item: taskHistoryItem, index: number) => (
          <TaskHistorySingleItem key={"taskHisItem_" + String(index)} itemData={item} />
        ))
      ) : (
        <>No Data</>
      )}
    </>
  );
};

export default TaskHistoryHolder;
