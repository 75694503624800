import * as React from "react";
import { useState, FunctionComponent } from "react";
import limaStore from '../../store/limaStore'
import { Separator } from "office-ui-fabric-react"
import { Toggle } from "office-ui-fabric-react/lib/Toggle";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { TooltipHost } from "office-ui-fabric-react/lib/Tooltip";

export interface ParamShowProps {
  message: string;
}

const ParamShow: FunctionComponent<ParamShowProps> = ({ message }: ParamShowProps) => {
  const [showState, setShowState] = useState<boolean>(false);

  //render() {
  const { userId, actaId, selectedId, selectedType, openTaskId } = limaStore;

  const handleChange = (ev: React.MouseEvent<HTMLElement>, checked: boolean): void => {
    //this.setState({ showing: !showing });
    setShowState(!showState);
    console.log("toggle is " + ev.type + (checked ? "checked" : "not checked"));
  };

  return (
    <section className="ms-welcome__paramShow ms-u-fadeIn500">
      <Toggle
        label={
          <div>
            Show help Data{" "}
            <TooltipHost content="When used you will see all params used for this dialog">
              <Icon iconName="Info" aria-label="Info tooltip" />
            </TooltipHost>
          </div>
        }
        inlineLabel
        onText="Show"
        offText="Show"
        onChange={handleChange}
      ></Toggle>
      {showState ? (
        <>
          <section className="LIM_paramData ms-u-fadeIn500">
            <Separator>{message}</Separator>
            <div><b>userId: </b>{userId}</div>
            <div><b>actaId: </b>{actaId}</div>
            <div><b>selectedType: </b>{selectedType}</div>
            <div><b>selecteId: </b>{selectedId}</div>
            <div><b>selectedTask: </b>{openTaskId}</div>
          </section>
        </>
      ) : null}
    </section>
  );
  //}
};

export default ParamShow;
