export enum HIGHLIGHT {
  yellow = "Yellow",
  lime = "Lime",
  turquoise = "Turquoise",
  pink = "Pink",
  blue = "Blue",
  red = "Red",
  darkBlue = "DarkBlue",
  teal = "Teal",
  green = "Green",
  purple = "Purple",
  drakRed = "DarkRed",
  olive = "Olive",
  gray = "Gray",
  lightGray = "LightGray",
  black = "Black",
}

export enum LIMsel {
  LIMsel01 = "LIMsel01",
  LIMsel02 = "LIMsel02",
  LIMsel03 = "LIMsel03",
  LIMsel04 = "LIMsel04",
  LIMsel05 = "LIMsel05",
}

export interface LimSelTemp {
  limaSel: string;
  highLight: string;
  fontColor: string;
  backgroundColor: string;
  style: string;
}

//TODO: in future when we import style than style will be used also
export const LIMselTemp: LimSelTemp[] = [
  { limaSel: "LIMsel00", highLight: null, fontColor: "#000000", backgroundColor: "", style: null },
  { limaSel: "LIMsel01", highLight: "LightGray", fontColor: "#000000", backgroundColor: "#F5DCB4", style: "LIMsel01" },
  { limaSel: "LIMsel02", highLight: "LightGray", fontColor: "#000000", backgroundColor: "#DCF5B4", style: "LIMsel02" },
  { limaSel: "LIMsel03", highLight: "LightGray", fontColor: "#000000", backgroundColor: "#B4F5DC", style: "LIMsel03" },
  { limaSel: "LIMsel04", highLight: "LightGray", fontColor: "#000000", backgroundColor: "#B4B4F5", style: "LIMsel04" },
  { limaSel: "LIMsel05", highLight: "LightGray", fontColor: "#000000", backgroundColor: "#F5B4F5", style: "LIMsel05" },
];

export const defBackGroundColor = "#F5DCB4";
/*

{limaSel:"LIMsel01", highLight:"LightGray", fontColor: "#F5DCB4"},
    {limaSel:"LIMsel02", highLight:"LightGray", fontColor: "#DCF5B4"},
    {limaSel:"LIMsel03", highLight:"LightGray", fontColor: "#B4F5DC"},
    {limaSel:"LIMsel04", highLight:"LightGray", fontColor: "#B4F5F5"},
    {limaSel:"LIMsel05", highLight:"LightGray", fontColor: "#F5B4F5"}

com.aspose.words.Style style = doc.getStyles().add(StyleType.CHARACTER, "LIMsel01");
style.getFont().getShading().setBackgroundPatternColor(new Color(245, 220, 180));

com.aspose.words.Style style2 = doc.getStyles().add(StyleType.CHARACTER, "LIMsel02");
style2.getFont().getShading().setBackgroundPatternColor(new Color(220, 245, 180));

com.aspose.words.Style style3 = doc.getStyles().add(StyleType.CHARACTER, "LIMsel03");
style3.getFont().getShading().setBackgroundPatternColor(new Color(180, 245, 220));

com.aspose.words.Style style4 = doc.getStyles().add(StyleType.CHARACTER, "LIMsel04");
style4.getFont().getShading().setBackgroundPatternColor(new Color(180, 180, 245));

com.aspose.words.Style style5 = doc.getStyles().add(StyleType.CHARACTER, "LIMsel05");
style5.getFont().getShading().setBackgroundPatternColor(new Color(245, 180, 245));
*/
