import { log } from "missionlog";
import { reaction } from "mobx";
import { Moment } from "moment";
import moment = require("moment");
import { Separator, Stack } from "office-ui-fabric-react";
import { FunctionComponent, useEffect, useState } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import { LimeBreadCrumb } from "../../../../limaComponents/LimeBreadCrumb";
import limaStore from "../../../../store/limaStore";
import { SelectedInWord } from "../../../../types/wordObjects";
import HistParaDateRange from "./components/HistParaDateRange";
import HistParaItemParaCommentsItems from "./components/HistParaItemParaCommentsItems";
import HistParaItemParaTextItems from "./components/HistParaItemParaTextItems";
import HistParaItemTaskItems from "./components/HistParaItemTaskItems";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface HistParaItemPageProps {}

const HistParaItemPage: FunctionComponent<HistParaItemPageProps> = () => {
  const [dateFrom, setDateFrom] = useState<Moment>(moment().subtract("1", "M"));
  const [dateTo, setDateTo] = useState<Moment>(moment());
  const [selectedItem, setSelectedItem] = useState<SelectedInWord | null>(
    (limaStore.selectedInWord && limaStore.selectedInWord[0]) || null
  );
  const { t } = useTranslation();

  const updateDate = (fromDate: Moment, toDate: Moment) => {
    setDateFrom(fromDate);
    setDateTo(toDate);
  };

  useEffect(() => {
    const disposer = reaction(
      () => limaStore.selectedInWord,
      (selected) => {
        log.info(limaLogTag.HistParaItemPage, "HistParaItemTaskRow show data: ", selected);
        selected && setSelectedItem(selected[0]);
      }
    );
    return function cleanup() {
      disposer();
    };
  }, []);

  return (
    <>
      <LimeBreadCrumb bradCrumbsStr={[t("tpmain:menu.showLbl"), t("tpmain:menu.show.showHistoryLbl")]} />
      <Stack>
        <Stack.Item>
          <HistParaDateRange fromDate={dateFrom} toDate={dateTo} setDate={updateDate} />
        </Stack.Item>

        <Separator></Separator>
        <Stack.Item>
          {/* call history {moment(dateFrom).toISOString()} - {moment(dateTo).toISOString()} */}
          <HistParaItemParaTextItems
            dateFrom={dateFrom}
            dateTo={dateTo}
            //selectedItem={(limaStore.selectedInWord && limaStore.selectedInWord[0]) || null}
            selectedItem={selectedItem}
          />
        </Stack.Item>
        <Stack.Item>
          <HistParaItemParaCommentsItems
            dateFrom={dateFrom}
            dateTo={dateTo}
            //selectedItem={(limaStore.selectedInWord && limaStore.selectedInWord[0]) || null}
            selectedItem={selectedItem}
          />
        </Stack.Item>
        <Stack.Item>
          <HistParaItemTaskItems dateFrom={dateFrom} dateTo={dateTo} selectedItem={selectedItem} />
        </Stack.Item>
      </Stack>
    </>
  );
};

export default HistParaItemPage;
