import { Text, mergeStyleSets } from "@fluentui/react";
import { ReactElement, ReactNode } from "react";
import React = require("react");

type LimaNoticeProps = {
  noticeType?: LIMA_TEXTNOTICETYPE;
  children?: ReactNode;
};

const classNames = mergeStyleSets({
  basenotice: {
    color: "gray",
  },
  rednotice: {
    color: "red",
  },
  warnnotice: {
    color: "orange",
  },
});

export enum LIMA_TEXTNOTICETYPE {
  ERROR = "error",
  WARN = "warn",
  INFO = "info",
}

export const LimaNotice = ({ noticeType, children }: LimaNoticeProps): ReactElement => {
  const classNameVal = (): string => {
    if (noticeType === undefined || noticeType === LIMA_TEXTNOTICETYPE.INFO) return classNames.basenotice;
    else if (noticeType === LIMA_TEXTNOTICETYPE.WARN) return classNames.warnnotice;
    else if (noticeType === LIMA_TEXTNOTICETYPE.ERROR) return classNames.rednotice;
    else return classNames.basenotice;
  };
  return (
    <Text variant="small" className={classNameVal()}>
      {children}
    </Text>
  );
};
