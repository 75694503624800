import {
  CommandBar,
  getFocusStyle,
  getTheme,
  ICommandBarItemProps,
  ICommandBarStyles,
  ITheme,
  mergeStyleSets,
} from "@fluentui/react";
import { log } from "missionlog";
import { observer } from "mobx-react-lite";
// import { observer } from "mobx-react";
import * as React from "react";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { limaLogTag } from "../limaCommon/limaLog";
import limaBreadCrumbStore from "../store/limaBreadCrumbStore";

import limaStore from "../store/limaStore";
import Routes from "../taskpane/Menu/routes";
import { XCOPY_TYPE } from "../types/Task";
// import Routes from "../taskpane/Menu/routes";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
// export interface ILimaMenuProps {}

export enum MenuSelectedENUM {
  HOME = "home",
  TOOLS = "tools",
  FILE = "file",
  NONE = "none",
}

const theme: ITheme = getTheme();
// const { palette, semanticColors, fonts } = theme;
const { palette, semanticColors } = theme;

const classNames = mergeStyleSets({
  selectedMenu: [
    getFocusStyle(theme, { inset: -1 }),
    {
      // minHeight: 40,
      // padding: 2,
      boxSizing: "border-box",
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      display: "flex",
      background: "#D4D6D9",
      color: "#0078D4",
      selectors: {
        "&:hover": {
          background: palette.neutralLight,
          color: "#0078D4",
        },
        " .ms-Button-label": {
          fontWeight: 600,
        },
      },
    },
  ],
  notSelectedMenu: [
    getFocusStyle(theme, { inset: -1 }),
    {
      boxSizing: "border-box",
      background: "#f5f7fa",
      selectors: {
        "&:hover": {
          background: palette.neutralLight,
        },
      },
    },
  ],
});

const commandBarStyle: ICommandBarStyles = {
  root: { height: 40, background: "#f5f7fa" },
  // primarySet: { color: "#FF0000" },
  // secondarySet: { background: "#f5f7fa" },
};

export const LimaMenu = (): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();

  const items = (
    selectedMenu: MenuSelectedENUM,
    menu_fileName?: string,
    menu_fileType?: XCOPY_TYPE,
    menu_fileId?: string
  ): ICommandBarItemProps[] => {
    log.info(
      limaLogTag.LimaMenu,
      `items redraw main menu ${limaStore.actaId}`,
      limaStore.mainCopyIsOpen === true,
      selectedMenu,
      limaStore.logged,
      menu_fileName,
      menu_fileType,
      menu_fileId
    );
    const items: ICommandBarItemProps[] = [];
    if (limaStore.logged === true) {
      items.push({
        key: Routes.HomeV2Route,
        name: t("tpmain:menu.homeLbl"),
        disabled: !limaStore.logged,
        onClick: () => {
          // limaStore.setMenuOpen(MenuSelectedENUM.HOME);
          limaBreadCrumbStore.setBreadCumberCount(1);
          history.push(`${Routes.HomeV2Route}`);
        },
        className: selectedMenu === MenuSelectedENUM.HOME ? classNames.selectedMenu : classNames.notSelectedMenu,
      });

      items.push({
        key: Routes.ToolsV2Route,
        name: t("tpmain:menu.toolsLbl"),
        disabled: !limaStore.logged,
        split: menu_fileName !== undefined,
        onClick: () => {
          history.push(`${Routes.ToolsV2Route}`);
        },
        className: selectedMenu === MenuSelectedENUM.TOOLS ? classNames.selectedMenu : classNames.notSelectedMenu,
      });
    }
    if (menu_fileType === XCOPY_TYPE.MAINXCOPY) {
      log.info(
        limaLogTag.LimaMenu,
        `useMemo reacalculate actaId: ${limaStore.actaId} - push item`,
        limaStore.mainCopyIsOpen === true
      );
      items.push({
        key: Routes.ActaV2Route,
        name: menu_fileName,
        iconProps: { iconName: "Page" },
        onClick: () => {
          limaStore.setMenuOpen(MenuSelectedENUM.FILE);
          limaBreadCrumbStore.addNewBreadCrumbExt({
            key: `file_${limaStore.actaId}`,
            lima_itemid: menu_fileId,
            text: menu_fileName,
            data: `${Routes.ActaDetailRoute_base}${limaStore.actaId}/${XCOPY_TYPE.MAINXCOPY}`,
            lima_Actions: [],
          });

          //history.push(`${Routes.ActaDetailRoute_base}${limaStore.actaId}`);
          history.push(`${Routes.ActaDetailRoute_base}${limaStore.actaId}/${XCOPY_TYPE.MAINXCOPY}`);
        },
        className: selectedMenu === MenuSelectedENUM.FILE ? classNames.selectedMenu : classNames.notSelectedMenu,
      });
    } else if (menu_fileType === XCOPY_TYPE.FULLXCOPY || menu_fileType === XCOPY_TYPE.TRIMXCOPY) {
      log.info(
        limaLogTag.LimaMenu,
        `useMemo reacalculate actaId: ${limaStore.xcopyId} - push item`,
        limaStore.xcopyIsOpen === true
      );
      items.push({
        key: Routes.ActaV2Route,
        name: menu_fileName,
        iconProps: { iconName: "Copy" },
        onClick: () => {
          limaStore.setMenuOpen(MenuSelectedENUM.FILE);
          limaBreadCrumbStore.addNewBreadCrumbExt({
            key: `copy_${limaStore.xcopyId}`,
            lima_itemid: menu_fileType,
            text: `copy ${menu_fileName}`,
            data: `${Routes.ActaDetailRoute_base}${limaStore.xcopyId}/${XCOPY_TYPE.FULLXCOPY}`,
            lima_Actions: [],
          });

          history.push(`${Routes.ActaDetailRoute_base}${limaStore.xcopyId}/${XCOPY_TYPE.FULLXCOPY}`);
        },
        className: selectedMenu === MenuSelectedENUM.FILE ? classNames.selectedMenu : classNames.notSelectedMenu,
      });
    }
    return items;
  };

  const CommandBarObserver = observer(() => {
    window.console.log(">>>>>>> COMMAND BAR OBSERVE");
    window.console.log(">>>>>>> COMMAND BAR OBSERVE", limaStore.menuOpen);
    window.console.log(">>>>>>> COMMAND BAR OBSERVE", limaStore.menu_fileName);
    window.console.log(">>>>>>> COMMAND BAR OBSERVE", limaStore.menu_fileType);
    window.console.log(">>>>>>> COMMAND BAR OBSERVE", limaStore.menu_fileId);
    return (
      <CommandBar
        ariaLabel="Use left and right arrow keys to navigate between commands"
        items={items(limaStore.menuOpen, limaStore.menu_fileName, limaStore.menu_fileType, limaStore.menu_fileId)}
        styles={commandBarStyle}
      />
    );
  });

  return <CommandBarObserver />;
};
