//TODO: TO moment
export const convertDate = (timestamp: string): string => {
  const day = timestamp.slice(8, 10);
  const month = timestamp.slice(5, 7);
  const year = timestamp.slice(0, 4);
  const hours = timestamp.slice(11, 13);
  const minutes = timestamp.slice(14, 16);

  return `${day}.${month}.${year} ${hours}:${minutes}`;
};

export const isNumeric = (value: string): boolean => {
  return !isNaN(Number(value));
};
