import { TextField } from "@fluentui/react";
import { log } from "missionlog";
import { FunctionComponent, useState } from "react";
import React = require("react");
import { useDebounceFn } from "../../../../../limaCommon/customHooks/useDebounce";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import { useTranslation } from "react-i18next";

interface AccessFilterV4Props {
  label?: string;
  onChangeClb: (filterTxt: string) => void;
}

export const AccessFilterV4: FunctionComponent<AccessFilterV4Props> = ({ label, onChangeClb }: AccessFilterV4Props) => {
  const [filterText, setFilterText] = useState<string>("");
  const { t } = useTranslation();

  //-------------------------
  //---- REACTIONS     ------
  //-------------------------

  //-------------------------
  //---- DEBAUNCERS    ------
  //-------------------------
  useDebounceFn(
    filterText,
    () => {
      log.info(limaLogTag.AccessFilterV4, `debounced: Search text ${filterText}`);
      onChangeClb(filterText);
    },
    500
  );

  //-------------------------
  //---- Handlers    ------
  //-------------------------
  const searchTextChangedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    log.info(limaLogTag.AccessFilterV4, `searchTextChanged: Search text ${event.target.value}`);
    setFilterText(event.target.value);
    // setSearchText(event.target.value);
  };

  return (
    <TextField
      label={label ? label : t("tpmain:component.access.filterLbl")}
      key="searchMenuSearchFieldTxt"
      // onKeyPress={(ev: React.KeyboardEvent<HTMLInputElement>) =>
      //   keyPressHandler(ev, "Enter", () => searchTextChangedHandler(ev))
      // }
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        window.console.log(":AAAAAA", event);
        searchTextChangedHandler(event);
      }}
      //onChange={onChangeHandler}
    />
  );
};
