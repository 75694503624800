import { urlGetUserPerm } from "../api/apilinks";
import {
  ILimaPermission,
  is_limaPermissionsActa,
  is_limaPermissionsCompany,
  limaPermissions,
  limaPermissionsActa,
  limaPermissionsCompany,
  UserPermissions,
} from "../types/UserPermissions";
import limaLoadeStore, { LoaderItemEnd } from "./limaLoaderStore";
import { httpGetAuth, HttpResponse } from "../api/httpAPI";
import { limaLogTag } from "../limaCommon/limaLog";
import { log } from "missionlog";
import limaStore from "./limaStore";
import {
  LIMAUSER_PERMISSIONS_TYPE,
  LIMAUSER_GENERAL_PERMISSIONS,
  LIMAUSER_COMPANY_PERMISSIONS,
  LIMAUSER_ACTA_PERMISSIONS,
  identPermission,
  LIMAENUM_AccesLicPermIdent,
  IAccesLicPerm,
} from "../limaCommon/limaPermissions";
import { makeAutoObservable, observable, action } from "mobx";
import { ILimaUserLicences, LimaEnum_licenceType } from "../types/LimaLicences";

export class LimaPermissionStore {
  userPermissons: ILimaPermission = {
    acta_permissions: [],
    company_permissions: [],
    general_permissions: [],
    isSet: false,
  };
  useUsedLicence: ILimaUserLicences = { companyKey: null, licenceid: null, licenceType: LimaEnum_licenceType.NONE };

  constructor() {
    makeAutoObservable(this, {
      userPermissons: observable,

      // updatePermission: action,
      setPermissions: action,
    });

    //TODO rewrite to better
  }

  /**
   * Get all permissions from Backend
   * @returns nothing
   * @author MS
   *
   * @version 1.1
   *
   *    version 1.1 add company
   */
  async getPermissions(actaId?: string): Promise<void> {
    const loaderID = limaLoadeStore.add(`Get permissions 2`);
    let data: HttpResponse<UserPermissions>;
    log.info(limaLogTag.LimaStore, "getPermissions: starts");
    try {
      const dataReply = await httpGetAuth<UserPermissions>(
        urlGetUserPerm(limaStore.userId, limaStore.userCompanyKey, actaId),
        loaderID.ac
      );
      if ("parsedBody" in dataReply) {
        log.info(limaLogTag.LimaStore, "getPermissions:looks like get some data ", dataReply.parsedBody);

        this.setPermissions(
          dataReply.parsedBody.general_permissions,
          dataReply.parsedBody.company_permissions,
          dataReply.parsedBody.acta_permissions
        );
        limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      } else {
        log.error(limaLogTag.LimaStore, "getPermissions:looks like no data ", dataReply);
        limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
        // return null;
      }
    } catch (response) {
      log.error(limaLogTag.LimaStore, "getPermissions: Error", data);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      // return null;
    } finally {
      log.info(limaLogTag.LimaStore, "getPermissions: CLOSE LOADER");
      // limaLoadeStore.remove(loaderID.k);
    }
  }

  /**
   * remove all permisssion
   */
  clearPermissions(): void {
    this.userPermissons.general_permissions = [];
    this.userPermissons.company_permissions = [];
    this.userPermissons.acta_permissions = [];
    this.userPermissons.isSet = false;
  }

  /**
   * remove/Clear permission od specifi type
   *  @param type
   */
  clearPermissionsType(type: LIMAUSER_PERMISSIONS_TYPE): void {
    if (type === LIMAUSER_PERMISSIONS_TYPE.ACTA)
      this.setPermissions(this.userPermissons.general_permissions, this.userPermissons.company_permissions, []);
    else if (type === LIMAUSER_PERMISSIONS_TYPE.COMPANY)
      this.setPermissions(this.userPermissons.general_permissions, [], this.userPermissons.acta_permissions);
    else if (type === LIMAUSER_PERMISSIONS_TYPE.GENERAL)
      this.setPermissions([], this.userPermissons.company_permissions, this.userPermissons.acta_permissions);
  }

  /**
   * Update single permisssion
   * @param type
   * @param permissionData
   */
  updatePermission(
    type: LIMAUSER_PERMISSIONS_TYPE,
    permissionData: limaPermissionsActa | limaPermissions | limaPermissionsCompany,
    permissionList?: ILimaPermission
  ): ILimaPermission {
    const usedPermission = permissionList !== undefined ? permissionList : this.userPermissons;

    window.console.log("PERMISSSION ", type, permissionData, usedPermission);

    let newPermData = null;
    let newPermDataFull: ILimaPermission | null = null;

    if (type === LIMAUSER_PERMISSIONS_TYPE.ACTA && is_limaPermissionsActa(permissionData)) {
      if (
        usedPermission.acta_permissions.filter((item) => item.permissionIdent === permissionData.permissionIdent)
          .length > 0
      ) {
        newPermData = usedPermission.acta_permissions.map((permDataItem) =>
          permissionData.permissionIdent === permDataItem.permissionIdent ? permissionData : permDataItem
        );
      } else {
        newPermData = [...usedPermission.acta_permissions, permissionData];
      }

      newPermDataFull = { ...usedPermission, acta_permissions: newPermData };
    } else if (type === LIMAUSER_PERMISSIONS_TYPE.COMPANY && is_limaPermissionsCompany(permissionData)) {
      if (
        usedPermission.company_permissions.filter((item) => item.permissionIdent === permissionData.permissionIdent)
          .length > 0
      ) {
        newPermData = usedPermission.company_permissions.map((permDataItem) =>
          permissionData.permissionIdent === permDataItem.permissionIdent ? permissionData : permDataItem
        );
      } else {
        newPermData = [...usedPermission.company_permissions, permissionData];
      }
      newPermDataFull = { ...usedPermission, company_permissions: newPermData };
    } else if (type === LIMAUSER_PERMISSIONS_TYPE.GENERAL) {
      if (
        usedPermission.general_permissions.filter((item) => item.permissionIdent === permissionData.permissionIdent)
          .length > 0
      ) {
        newPermData = usedPermission.general_permissions.map((permDataItem) =>
          permissionData.permissionIdent === permDataItem.permissionIdent ? permissionData : permDataItem
        );
      } else {
        newPermData = [...usedPermission.general_permissions, permissionData];
      }
      newPermDataFull = { ...usedPermission, general_permissions: newPermData };
    }
    window.console.log("PERMISSSION 2", type, permissionData, newPermDataFull);
    // if (permissionList !== undefined) {
    //   this.userPermissons = newPermDataFull;
    // }
    return newPermDataFull;
  }

  /**
   * Main utility checking if user have specific permission
   *
   * @param type
   * @param permIdent
   * @returns
   */
  checkPermissionIdent(
    type: LIMAUSER_PERMISSIONS_TYPE,
    permIdent: LIMAUSER_GENERAL_PERMISSIONS | LIMAUSER_COMPANY_PERMISSIONS | LIMAUSER_ACTA_PERMISSIONS
  ): boolean {
    log.info(limaLogTag.LimaPermissions, `checkPermissionIdent: type [${type}] permIdent [${permIdent}]   `);
    // log.info(limaLogTag.LimaPermissions, `checkPermissionIdent: out TESTING TRUE`);
    // return true;

    let out: boolean | null = null;
    let permissionObjDefault: boolean | null = null;
    let searchPermissionObj: limaPermissions[] = null;

    if (type === LIMAUSER_PERMISSIONS_TYPE.GENERAL) {
      searchPermissionObj = this.userPermissons.general_permissions;
    } else if (type === LIMAUSER_PERMISSIONS_TYPE.COMPANY) {
      searchPermissionObj = this.userPermissons.company_permissions;
    } else if (type === LIMAUSER_PERMISSIONS_TYPE.ACTA) {
      searchPermissionObj = this.userPermissons.acta_permissions;
    }

    log.info(limaLogTag.LimaPermissions, `checkPermissionIdent: search in `, searchPermissionObj);
    if (searchPermissionObj !== null) {
      //log.info(limaLogTag.LimaPermissions, `checkPermissionIdent: start seach`, searchPermissionObj);
      searchPermissionObj.forEach((singleItem: limaPermissions) => {
        log.info(limaLogTag.LimaPermissions, `checkPermissionIdent: check single perm `, singleItem);
        if (singleItem.permissionIdent === permIdent) out = singleItem.actualState;
        if (singleItem.permissionIdent === "default") permissionObjDefault = singleItem.actualState;
      });

      if (out === null && permissionObjDefault !== null) out = permissionObjDefault;
    }

    if (out === null) out = false;

    log.info(limaLogTag.LimaPermissions, `checkPermissionIdent: out state [${String(out)}]`);
    return out;
  }

  checkAccLicenceAndPermissions(accIdent: LIMAENUM_AccesLicPermIdent): boolean {
    const reqLicPermissions = identPermission.filter((item: IAccesLicPerm) => item.ident === accIdent);
    if (reqLicPermissions.length === 0) return false;
    let accResult = false;
    log.info(
      limaLogTag.LimaPermissions,
      "checkAccLicenceAndPermissions: reqLicPermissions 1:   ",
      accIdent,
      identPermission,
      reqLicPermissions
    );
    log.info(
      limaLogTag.LimaPermissions,
      "checkAccLicenceAndPermissions: reqLicPermissions 2:   ",
      this.useUsedLicence,
      this.userPermissons
    );

    for (let i = 0; i < reqLicPermissions.length && accResult === false; i++) {
      const item: IAccesLicPerm = reqLicPermissions[i];
      for (let j = 0; j < item.accesLicPerm.length && accResult === false; j++) {
        const accListPermItem = item.accesLicPerm[j];
        if (accListPermItem.reqlicenceType === this.useUsedLicence.licenceType) {
          log.info(
            limaLogTag.LimaPermissions,
            `checkAccLicenceAndPermissions: IS same licence type: ${accListPermItem.reqlicenceType} nad  ${this.useUsedLicence.licenceType} `
          );
          for (let k = 0; k < accListPermItem.reqPermissionTypes.length && accResult === false; k++) {
            const accListPermItemPerType = accListPermItem.reqPermissionTypes[k];
            log.info(
              limaLogTag.LimaPermissions,
              `checkAccLicenceAndPermissions: search for perm type: `,
              accListPermItemPerType
            );
            if (
              accListPermItemPerType !== undefined &&
              accListPermItemPerType.permType === LIMAUSER_PERMISSIONS_TYPE.GENERAL
            ) {
              log.info(
                limaLogTag.LimaPermissions,
                `checkAccLicenceAndPermissions: search for  same perm type: ${accListPermItemPerType.permType} `
              );
              for (let la = 0; la < this.userPermissons.general_permissions.length && accResult === false; la++) {
                if (
                  this.userPermissons.general_permissions[la].permissionIdent === accListPermItemPerType.permIdent &&
                  this.userPermissons.general_permissions[la].actualState === true
                ) {
                  log.info(
                    limaLogTag.LimaPermissions,
                    `checkAccLicenceAndPermissions: userLict ${this.useUsedLicence.licenceType} perm ident ${
                      this.userPermissons.general_permissions[la].permissionIdent
                    } state ${String(this.userPermissons.general_permissions[la].actualState)} req: LIC: ${
                      accListPermItem.reqlicenceType
                    } TYPE:${accListPermItemPerType.permType} IDENT:${accListPermItemPerType.permIdent}`
                  );
                  accResult = true;
                } else {
                  log.info(
                    limaLogTag.LimaPermissions,
                    `checkAccLicenceAndPermissions: NOT SAME userLict ${this.useUsedLicence.licenceType} perm ident ${
                      this.userPermissons.general_permissions[la].permissionIdent
                    } state ${String(this.userPermissons.general_permissions[la].actualState)} req: LIC: ${
                      accListPermItem.reqlicenceType
                    } TYPE:${accListPermItemPerType.permType} IDENT:${accListPermItemPerType.permIdent}`
                  );
                }
              }
            } else if (
              accListPermItemPerType !== undefined &&
              accListPermItemPerType.permType === LIMAUSER_PERMISSIONS_TYPE.COMPANY
            ) {
              for (let l = 0; l < this.userPermissons.company_permissions.length && accResult === false; l++) {
                if (
                  this.userPermissons.company_permissions[l].permissionIdent === accListPermItemPerType.permIdent &&
                  this.userPermissons.company_permissions[l].actualState === true
                ) {
                  log.info(
                    limaLogTag.LimaPermissions,
                    `checkAccLicenceAndPermissions: userLict ${this.useUsedLicence.licenceType} perm ident ${
                      this.userPermissons.company_permissions[l].permissionIdent
                    } state ${String(this.userPermissons.company_permissions[l].actualState)} req: LIC: ${
                      accListPermItem.reqlicenceType
                    } TYPE:${accListPermItemPerType.permType} IDENT:${accListPermItemPerType.permIdent}`
                  );
                  accResult = true;
                }
              }
            } else if (
              accListPermItemPerType !== undefined &&
              accListPermItemPerType.permType === LIMAUSER_PERMISSIONS_TYPE.ACTA
            ) {
              for (let l = 0; l < this.userPermissons.acta_permissions.length && accResult === false; l++) {
                if (
                  this.userPermissons.acta_permissions[l].permissionIdent === accListPermItemPerType.permIdent &&
                  this.userPermissons.acta_permissions[l].actualState === true
                ) {
                  log.info(
                    limaLogTag.LimaPermissions,
                    `checkAccLicenceAndPermissions: TRUE userLict ${this.useUsedLicence.licenceType} perm ident ${
                      this.userPermissons.acta_permissions[l].permissionIdent
                    } state ${String(this.userPermissons.acta_permissions[l].actualState)} req: LIC: ${
                      accListPermItem.reqlicenceType
                    } TYPE:${accListPermItemPerType.permType} IDENT:${accListPermItemPerType.permIdent}`
                  );
                  accResult = true;
                }
              }
            }
          }
        } else {
          log.info(
            limaLogTag.LimaPermissions,
            `checkAccLicenceAndPermissions: not same licence type: ${accListPermItem.reqlicenceType} nad  ${this.useUsedLicence.licenceType} `
          );
        }
      }
    }
    log.info(limaLogTag.LimaPermissions, `checkAccLicenceAndPermissions: RESULT: ${String(accResult)} `);
    return accResult;
  }

  listPermissions(): void {
    log.info(
      limaLogTag.LimaPermissions,
      "listPermissions general:   ",
      JSON.stringify(this.userPermissons.general_permissions)
    );
  }

  /**
   * Setting persmission for user
   * @param {limaPermissions[]} general_permissions general persmiison
   * @param {limaPermissionsCompany[]} company_permissions company permissions
   * @param {limaPermissionsActa[]} acta_permissions acta permission
   */
  setPermissions(
    general_permissions: limaPermissions[],
    company_permissions: limaPermissionsCompany[],
    acta_permissions: limaPermissionsActa[]
  ): void {
    log.info(limaLogTag.LimaPermissions, "setPermissions   ", this.userPermissons);
    this.userPermissons = {
      general_permissions: general_permissions,
      company_permissions: company_permissions,
      acta_permissions: acta_permissions,
      isSet: true,
    };
    log.info(limaLogTag.LimaPermissions, "setPermissions   ", this.userPermissons);
  }

  /**
   * set licence used for user
   * @param newUsedLicence set new used licence for user
   */
  setLicence(newUsedLicence: ILimaUserLicences): void {
    this.useUsedLicence = newUsedLicence;
  }

  /**
   * Processing cleaning on logout
   */
  onLogoutClear(): void {
    this.clearPermissions();
    this.useUsedLicence = { companyKey: null, licenceid: null, licenceType: LimaEnum_licenceType.NONE };
  }
}

const limaPermissionStore = new LimaPermissionStore();

export default limaPermissionStore;
