/**
 * Method used to remove last one paragraph in document
 *
 * @deprecated in version 0.7.11 and replaced with removeTechnicalParas
 */
export const removeLastPara = async () => {
  await Word.run(async (context) => {
    // Centers last paragraph alignment.
    //context.document.body.paragraphs.getLast().delete();
    context.document.body.paragraphs.getLast().getRange().delete();

    await context.sync();
  }).catch(function (error) {
    console.log("Error: " + JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      console.log("Debug info: " + JSON.stringify(error.debugInfo));
    }
  });
};

/**
 * Method that remove any paragraphs that starts with '>>>LIM_HELP>>>'
 *
 * @author MS
 */
export const removeTechnicalParas = async (last: boolean = true) => {
  if (last === true) {
    console.log("remove last");
    RemoveLastSection();
  } else {
    console.log("remove >>LIM");
    await Word.run(async (context) => {
      // Centers last paragraph alignment.
      var pars = context.document.body.paragraphs;

      pars.load();
      return context.sync().then(function () {
        for (var i = 0; i < pars.items.length; i++) {
          if (pars.items[i].text.startsWith(">>>LIM_HELP>>>")) {
            pars.items[i].delete();
            console.log("removeTechnicalParas: ", i);
          }
        }

        return context.sync().then(() => {
          // context.document.body.paragraphs.getLast().delete();
          // console.log("removeTechnicalParas: last para");
          // return context.sync();
        });
      });
    }).catch(function (error) {
      console.log("Error: " + JSON.stringify(error));
      if (error instanceof OfficeExtension.Error) {
        console.log("Debug info: " + JSON.stringify(error.debugInfo));
      }
    });
  }
};

/**
 * Method that remove any paragraphs that Ends with 'Aspose Pty Ltd.'
 *
 * @author MS
 */
export const removeEvalParas = async () => {
  await Word.run(async (context) => {
    // Centers last paragraph alignment.
    //context.document.body.paragraphs.getLast().delete();
    var pars = context.document.body.paragraphs;
    pars.load();
    return context.sync().then(function () {
      for (var i = 0; i < pars.items.length; i++) {
        if (pars.items[i].text.endsWith("Aspose Pty Ltd.")) {
          pars.items[i].delete();
        }
      }
      return context.sync();
    });
  }).catch(function (error) {
    console.log("Error: " + JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      console.log("Debug info: " + JSON.stringify(error.debugInfo));
    }
  });
};

function RemoveLastSection() {
  Word.run(function (context) {
    var doc = context.document;
    var secs = doc.sections;
    secs.load("items");
    context.sync().then(function () {
      if (secs.items.length > 1) {
        var nrSecs = secs.items.length - 1;
        var sect = secs.items[nrSecs];
        var secPrev = secs.items[nrSecs - 1];
        var sectRng = sect.body.getRange("Content");
        var secStart = secPrev.body.getRange("End");
        var sectAll = secStart.expandTo(sectRng);
        sectAll["delete"]();
      }
      return context.sync();
    });
    return context.sync();
  }).catch(function (error) {
    console.log("Error: " + JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      console.log("Debug info: " + JSON.stringify(error.debugInfo));
    }
  });
}
