export interface KCTokens {
  token: string;
  refreshToken: string;
  idtoken: string;
  // clientID: string;
  userID: string;
  // userEmail: string;
}

export function isKCTokens(item: unknown): item is KCTokens {
  return (item as KCTokens).token !== undefined && (item as KCTokens).refreshToken !== undefined;
}
