import { useState, useEffect } from "react";

function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

export const useDebounceFn = <T>(value: T, fn: () => void, delay?: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
      fn();
    }, delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
};

// export const useDebouncedCallback = (callback: any, wait: number) => {
//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   const debouncedFunction = useCallback(debounce(callback, wait), []);
//   return [debouncedFunction];
// };

export default useDebounce;
