import {
  getFocusStyle,
  getTheme,
  IIconProps,
  IStackProps,
  ITheme,
  mergeStyleSets,
  // Separator,
  Stack,
} from "@fluentui/react";
import { log } from "missionlog";
import { FunctionComponent, useEffect, useState } from "react";
import React = require("react");
import { useMutation, useQuery } from "react-query";

import { useTranslation } from "react-i18next";
import { Task, Task2Task } from "../../../../types/Task";
import limaStore from "../../../../store/limaStore";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import {
  convert_Task_withActaXcopyParasCommentsTasksMessagesAttachements,
  Task_withActaXcopyParasCommentsTasksMessagesAttachements,
} from "../../../../types/Task_withActaXcopy";
import TaskListItemV4, { E_TASKPARAMS } from "./TaskListItemV4";
import { LimaLoadingComponnet } from "../../../../limaComponents/LimaLoadingComponnet";
import { LimaNotice } from "../../../../limaComponents/LimaNotice";
import { LimaHideableSection } from "../../../../limaComponents/LimaHideableSection";
import { User2Task_withActaXcopyParasCommentsTasksMessagesAttachements } from "../../../../types/User2Task_withActaXcopy";
import { LIMA_ITEM_TYPE } from "../../../../types/BASE_Enums";
import limaLoadeStore, { LoaderItemEnd } from "../../../../store/limaLoaderStore";
import { httpGetAuth, httpPostAuth, httpPutAuth, HttpResponse } from "../../../../api/httpAPI";
import { urlGetTaskLinkedToTaskList, urlPostDeleteTaskLinkedToTask } from "../../../../api/apilinks";
import { ItemToItem } from "../../../../types/ItemToItem";
import limaTasksStoreV4 from "../../../../store/limaTasksStoreV4";

interface Task2TaskComponentProps {
  taskId: string;
  showComponent?: boolean;
  updateTaskLinkedCount: (number) => void;
}

const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 0 },
  styles: { root: { width: "100%" } },
};

const theme: ITheme = getTheme();
const { palette, semanticColors, fonts } = theme;
const RemoveLinkIcon: IIconProps = { iconName: "RemoveLink" };
const AddLinkIcon: IIconProps = { iconName: "AddLink" };

const classNames = mergeStyleSets({
  // wrapper: {
  //   background: "lightgrey",
  // },
  itemCell: [
    getFocusStyle(theme, { inset: -1 }),
    {
      minHeight: 36,
      paddingBottom: 10,
      boxSizing: "border-box",
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      display: "flex",
      selectors: {
        "&:hover": { background: palette.neutralLight },
      },
    },
  ],
  listWrapper: {
    width: "100%",
    // maxHeight: "40vh",
    overflow: "auto",
  },
  itemImage: {
    flexShrink: 0,
  },
  itemContent: {
    marginLeft: 10,
    overflow: "hidden",
    flexGrow: 1,
  },
  itemName: [
    fonts.small,
    {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      // textDecoration: "line-through",
      fontWeight: "bold",
    },
  ],
  itemIndex: {
    fontSize: fonts.small.fontSize,
    color: palette.neutralTertiary,
    marginBottom: 10,
  },
  chevron: {
    alignSelf: "center",
    marginLeft: 10,
    color: palette.neutralTertiary,
    fontSize: fonts.large.fontSize,
    flexShrink: 0,
    cursor: "pointer",
  },
});

const Task2TaskComponentV4: FunctionComponent<Task2TaskComponentProps> = ({
  taskId,
  showComponent,
  updateTaskLinkedCount,
}: Task2TaskComponentProps) => {
  const { mutateAsync: mutateAddRemoveLinking } = useAddTask2TaskQuery();
  const [linkedTasksInner, setLinkedTasksInner] = useState<Task[] | null>(null);
  const { isLoading, isFetching, isError, refetch } = useQuery(
    ["getTaskLinked", taskId],
    () => getTaskLinked(limaStore.userId, taskId),
    {
      enabled: false,
      onSuccess: (data: Task[]) => {
        log.info(limaLogTag.UserAddEdit, "useQuery/onSuccess data,type ", data);
        updateTaskLinkedCount(data?.length);
        setLinkedTasksInner(data);
      },
    }
  );
  const { t } = useTranslation(["tptasks"]);

  const onAddHandler = (taskIdOnAddHndlr: string, taskTo: Task) => {
    log.info(limaLogTag.Task2Task, `call onAddHandler ${taskIdOnAddHndlr} -> ${taskTo._key}`);
    void mutateAddRemoveLinking({
      taskId: taskIdOnAddHndlr,
      toTaskId: taskTo._key,
      userId: limaStore.userId,
      createOrDelete: true,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    }).then((_data: Task2Task) => {
      setLinkedTasksInner([...linkedTasksInner, taskTo]);
    });
  };

  const onRemoveHandler = (taskIdOnRemoveHndlr: string, taskToId: string) => {
    log.info(limaLogTag.Task2Task, `call onRemoveHandler ${taskIdOnRemoveHndlr} -> ${taskToId}`);
    void mutateAddRemoveLinking({
      taskId: taskIdOnRemoveHndlr,
      toTaskId: taskToId,
      userId: limaStore.userId,
      createOrDelete: false,
    }).then((data: Task2Task) => {
      setLinkedTasksInner(linkedTasksInner.filter((item: Task) => item._key !== data.taskto._key));
    });
  };

  const onRenderCellTaskToAdd = (
    taskIdOnRenderTaskAdd: string,
    item: Task_withActaXcopyParasCommentsTasksMessagesAttachements
  ): JSX.Element => {
    return (
      <TaskListItemV4
        actionsList={[
          {
            action_ClickClb: () => onAddHandler(taskIdOnRenderTaskAdd, item),
            action_icon: AddLinkIcon,
            disabled: false,
            label: "Add linking",
            tooltip: "Add linking task to task",
          },
        ]}
        item={item}
        key={item._key}
        actionEnabled={true}
        hideTaskParams={[E_TASKPARAMS.DETAILINFOBITS, E_TASKPARAMS.CREATEDATE, E_TASKPARAMS.DUEDATE]}
      />
    );
  };

  const onRenderCellTaskToRemove = (
    taskIdOnRender: string,
    item: Task_withActaXcopyParasCommentsTasksMessagesAttachements
  ): JSX.Element => {
    return (
      <TaskListItemV4
        // onAction={() => onRemoveHandler(taskIdOnRender, item._key)}
        item={item}
        actionsList={[
          {
            action_ClickClb: () => onRemoveHandler(taskIdOnRender, item._key),
            action_icon: RemoveLinkIcon,
            disabled: false,
            label: "Remove linking",
            tooltip: "Remove linking task to task",
          },
        ]}
        key={item._key}
        actionEnabled={true}
        hideTaskParams={[E_TASKPARAMS.DETAILINFOBITS, E_TASKPARAMS.CREATEDATE, E_TASKPARAMS.DUEDATE]}
      />
    );
  };

  //-------------------------
  // Reactions
  //-------------------------
  useEffect(() => {
    void refetch();
  }, [taskId]);

  useEffect(() => {
    if (updateTaskLinkedCount !== undefined && linkedTasksInner !== null)
      updateTaskLinkedCount(linkedTasksInner.length);
  }, [linkedTasksInner]);

  return (
    <>
      {showComponent ? (
        <Stack horizontalAlign="start" {...columnProps} className={classNames.listWrapper}>
          <Stack.Item className={classNames.listWrapper}>
            {isLoading || isFetching ? (
              LimaLoadingComponnet(3)
            ) : isError ? (
              <LimaNotice>ERROR LOADING LINKED TASKS</LimaNotice>
            ) : (
              linkedTasksInner &&
              linkedTasksInner.map((item: Task) => {
                return onRenderCellTaskToRemove(
                  taskId,
                  convert_Task_withActaXcopyParasCommentsTasksMessagesAttachements(item)
                );
              })
            )}
          </Stack.Item>
          <Stack.Item className={classNames.listWrapper}>
            <LimaHideableSection name={t("tptasks:taskedit.taskitem2.linking.showhidelinkedLbl")}>
              {limaTasksStoreV4.tasksList?.map(
                (item: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements) => {
                  if (taskId === item.task_withActaXcopyParasCommentsTasksMessagesAttachements._key) {
                    log.info(limaLogTag.Task2Task, `onRenderCellTaskToAdd remove self `);
                    return null;
                  } else if (
                    linkedTasksInner &&
                    linkedTasksInner.filter(
                      (innerTaskLinkedItem: Task) =>
                        innerTaskLinkedItem._key === item.task_withActaXcopyParasCommentsTasksMessagesAttachements._key
                    ).length > 0
                  ) {
                    log.info(limaLogTag.Task2Task, `onRenderCellTaskToAdd remove already linked `);
                    return null;
                  } else {
                    log.info(limaLogTag.Task2Task, `onRenderCellTaskToAdd should render non linked and not self `);
                    return onRenderCellTaskToAdd(taskId, item.task_withActaXcopyParasCommentsTasksMessagesAttachements);
                  }
                }
              )}
            </LimaHideableSection>
          </Stack.Item>
        </Stack>
      ) : (
        <></>
      )}
    </>
  );
};

export default Task2TaskComponentV4;

//----------------------
//   REACT QUERY
//----------------------

type Task2taskUser = {
  taskId: string;
  toTaskId: string;
  userId: string;
  createOrDelete: boolean;
};

const useAddTask2TaskQuery = () => {
  return useMutation(createOrDeleteTask2Task, {
    onMutate: ({ taskId, toTaskId, userId, createOrDelete }: Task2taskUser) => {
      // A mutation is about to happen!
      log.info(limaLogTag.Task2Task, "call mutate useAskToPathUserQuery ", taskId, toTaskId, userId, createOrDelete);
      return { id: 1 };
    },
    onSuccess: (data: Task2Task) => {
      log.info(limaLogTag.Task2Task, "call mutate useAskToPathUserQuery ", data);
    },
  });
};

//------ QUERY ---------------

const createOrDeleteTask2Task = async ({
  taskId,
  toTaskId,
  userId,
  createOrDelete,
}: Task2taskUser): Promise<Task2Task> => {
  log.info(limaLogTag.Task2Task, "fetching createTask2Task", taskId, toTaskId, userId);

  if (
    taskId === undefined ||
    taskId === null ||
    taskId === "" ||
    toTaskId === undefined ||
    toTaskId === null ||
    toTaskId === "" ||
    userId === undefined ||
    userId === null ||
    userId === ""
  ) {
    log.warn(limaLogTag.Task2Task, "no taskId or toTaskId or userId", taskId, toTaskId, userId);
    return null;
  }

  const item2Item: ItemToItem = {
    itemtype_from: LIMA_ITEM_TYPE.TASK,
    itemid_from: taskId,
    itemtype_to: LIMA_ITEM_TYPE.TASK,
    itemid_to: toTaskId,
    userid: userId,
  };
  const loaderID = limaLoadeStore.add(`ask to create task to task linking`);

  let response: HttpResponse<Task2Task>;
  try {
    if (createOrDelete === true) {
      response = await httpPostAuth<Task2Task>(urlPostDeleteTaskLinkedToTask(taskId), item2Item, loaderID.ac);
    } else {
      response = await httpPutAuth<Task2Task>(urlPostDeleteTaskLinkedToTask(taskId), item2Item, loaderID.ac);
    }
    log.info(limaLogTag.UserLinkingPage, "fetching data createTask2Task response", response);
    if (!response.ok) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw response;
    }
    if (response.parsedBody !== undefined) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return response.parsedBody;
    }
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error("no data");
  } catch (error) {
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error(error);
  } finally {
    // limaLoadeStore.remove(loaderID.k);
  }
};

const getTaskLinked = async (userId: string, taskId: string): Promise<Task[]> => {
  log.info(limaLogTag.TaskItem, "fetching data getTaskLinked", userId, taskId);
  if (userId === undefined || taskId === null) {
    log.warn(limaLogTag.TaskItem, "getTaskLinked: no task id or userid");
    return new Promise((reject) => {
      reject(null);
    });
  }
  const loaderID = limaLoadeStore.add("Getting getTaskLinked from DB");
  try {
    const response = await httpGetAuth<Task2Task[]>(urlGetTaskLinkedToTaskList(taskId), loaderID.ac);

    log.info(limaLogTag.TaskItem, "fetching data getTaskLinked response ...");
    if (!response.ok) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw response;
    }
    if (response.parsedBody !== undefined) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      return response.parsedBody.map((item: Task2Task): Task => item.taskto);
    }
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error("no data");
  } catch (error) {
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error(error);
  } finally {
    // limaLoadeStore.remove(loaderID.k);
  }
};
