import { log, tag } from "missionlog";
import { action, makeObservable, observable } from "mobx";
import { ActParaItemOK } from "../api/schema/getActsParaResp";
import { judgeActParaItemOK } from "../api/schema/getJudgeActParaResp";
import { actPar } from "../types/dialogData";
import { judgeData } from "../types/keywords";

log.init({ LimaDialogStore: "LimaDialogStore" });

class LimaDialogStore {
  stringifyJson = "";
  dataJSON: actPar | null = null;
  actPar: ActParaItemOK | null = null;
  judge: judgeActParaItemOK | judgeData | null = null;
  isLoading = true;

  constructor() {
    makeObservable(this, {
      stringifyJson: observable,
      dataJSON: observable,
      actPar: observable,
      judge: observable,
      isLoading: observable,

      //setActVal: action,
      setStringifyJson: action,
      setIsLoading: action,
      setActPar: action,
      setJudge: action,
      setDataJSON: action,
    });
  }

  setStringifyJson(newValue: string) {
    log.info(tag.LimaDialogStore, "setStringifyJson: cahge value to ", newValue);

    this.stringifyJson = newValue;
    this.setDataJSON(JSON.parse(newValue));
  }

  setIsLoading(newState: boolean) {
    this.isLoading = newState;
  }
  setDataJSON(newValue: actPar) {
    this.dataJSON = newValue;
  }
  setActPar(newActPar: ActParaItemOK | null) {
    this.actPar = newActPar;
  }
  setJudge(newjudge: judgeActParaItemOK | judgeData | null) {
    this.judge = newjudge;
  }

  /**
   * Cleanup on logout button
   *
   * @autor MS
   */
  onLogoutClear() {
    this.stringifyJson = "";
    this.dataJSON = null;
    this.actPar = null;
    this.judge = null;
    this.isLoading = true;
  }
}

const limaDialogStore = new LimaDialogStore();
export default limaDialogStore;
