import { WORDITEMTYPE } from "../api/apilinks";
import { DEF_comBkmID, DEF_parBkmID } from "../limaCommon/limaEnv";

/**
 * Used for selection object in Word
 * ex. SelectedInWord[] = {itemType.para,xxxxx}
 */
export interface SelectedInWord {
  type: WORDITEMTYPE;
  id: string;
}

export const getItemTypeFromPrefix = (prefix: string): WORDITEMTYPE => {
  if (prefix == DEF_parBkmID) return WORDITEMTYPE.para;
  else if (prefix == DEF_comBkmID) return WORDITEMTYPE.comm;
  else return WORDITEMTYPE.other;
};
