import React = require("react");
import { FC, useEffect, useState } from "react";
import { mergeStyleSets } from "@fluentui/react";
import { log } from "missionlog";
import { limaLogTag } from "../limaCommon/limaLog";

export type ControlledSwitchDataItem = {
  value: string;
  key: string;
  name: string;
};

type LimaControlledTextSwitchProp = {
  values: ControlledSwitchDataItem[];
  defaultValue: string;
  callBack: (val: ControlledSwitchDataItem) => void;
};

type LimaRadioTextBtnProp = {
  value: string;
  name: string;
  checked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

const classNames = mergeStyleSets({
  wrapper: {
    cursor: "pointer",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  selectedLabel: {
    color: "blue",
    fontWeight: "400",
  },
  hiddenRadio: {
    opacity: 0,
    display: "none",
  },
});

const LimaRadioTextBtn = (props: LimaRadioTextBtnProp): JSX.Element => (
  <label className={`${classNames.wrapper} ${props.checked ? classNames.selectedLabel : ""}`}>
    <input
      type="radio"
      value={props.value}
      onChange={props.onChange}
      checked={props.checked}
      className={classNames.hiddenRadio}
    />
    <>{props.name}</>
  </label>
);

// class LimaControlledTextSwitch extends Component {
/**
 *
 * @param props
 * @returns
 *
 * @author MS
 *
 * @example
 * ```
 * import {LimaControlledTextSwitch,ControlledSwitchDataItem} from "./.......";
 *
 * ...
 * const data:ControlledSwitchDataItem[] = [{},{}....]
 * ...
 * <LimaControlledTextSwitch values={data} callBack={(dataItem:ControlledSwitchDataItem)=>{.....}}/>
 * ```
 */
export const LimaControlledTextSwitch: FC<LimaControlledTextSwitchProp> = (props: LimaControlledTextSwitchProp) => {
  const [outValue, setOutValue] = useState<string>(props.defaultValue);

  useEffect(() => {
    setOutValue(props.defaultValue);
  }, [props.defaultValue]);

  const _RadioClickHandler = (_e: React.ChangeEvent<HTMLInputElement>, item: ControlledSwitchDataItem) => {
    log.info(limaLogTag.LimaControlledTextSwitch, "change to ", item.name);
    //setOutValue(e.target.value);
    props.callBack(item);
  };

  return (
    <div>
      {props.values.map((item: ControlledSwitchDataItem) => (
        <>
          {/* {index > 0 && <>{" | "}</>} */}
          <LimaRadioTextBtn
            value={item.value}
            key={item.key}
            name={item.name}
            checked={outValue === item.value}
            onChange={(e) => _RadioClickHandler(e, item)}
          />
        </>
      ))}
    </div>
  );
};
