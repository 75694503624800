import {
  FontIcon,
  getFocusStyle,
  getTheme,
  IconButton,
  IIconProps,
  IStackTokens,
  ITheme,
  mergeStyles,
  mergeStyleSets,
  Persona,
  PersonaSize,
  Stack,
} from "@fluentui/react";
import { log } from "missionlog";
import { FunctionComponent, useEffect } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import limaStore from "../../../../../store/limaStore";
import limaUsersStore from "../../../../../store/limaUsersStore";
import { Task, TaskTypeEnum } from "../../../../../types/Task";
// import { User2Task_withActaXcopy } from "../../../../../types/User2Task_withActaXcopy";

//const RemoveLinkIcon: IIconProps = { iconName: "RemoveLink" };

interface TaskListItemProps {
  item: Task;
  onClickHandler?: (task: Task) => void;
  onAction?: (taskId: string) => void;
  actionIcon?: IIconProps;
  actionEnabled?: boolean;
}

const taskStateIconClass = mergeStyles({
  fontSize: 25,
  height: 25,
  width: 25,
  // margin: "0 25px",
});

const theme: ITheme = getTheme();
const { palette, semanticColors, fonts } = theme;

// const sectionStackTokens: IStackTokens = { childrenGap: 10 };
const numericalSpacingStackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const classNames = mergeStyleSets({
  itemRowWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    padding: 0,
  },
  itemRowTaskInfoPart: [
    getFocusStyle(theme, { inset: -1 }),
    {
      minHeight: 54,
      paddingTop: 10,
      paddingBottom: 5,
      boxSizing: "border-box",
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      display: "flex",
      flexDirection: "row",
      width: "80%",
      selectors: {
        "&:hover": {
          background: palette.neutralLight,
          cursor: "pointer",
        },
      },
    },
  ],
  itemRowTaskTypeIconPart: {
    display: "flex",
    flexDirection: "row",
    width: 35,
    paddingLeft: 5,
  },
  itemRowTaskStateIconsPart: {
    display: "flex",
    flexDirection: "row",
    width: 35,
  },
  itemRowTaskResponsPart: {
    display: "flex",
    flexDirection: "row",
    // width: "20%",
    width: 35,
  },
  itemRowTaskPartContentWrapper: {
    marginLeft: 10,
    overflow: "hidden",
    textOverflow: "ellipsis",
    // flexGrow: 1,
  },
  iconGreen: [{ color: "green" }, taskStateIconClass],
  iconBlue: [{ color: "blue" }, taskStateIconClass],
  iconGrey: [{ color: "grey" }, taskStateIconClass],
  iconBasic: [{ color: "grey" }, taskStateIconClass],
  itemActionIcon: [
    getFocusStyle(theme),
    {
      minHeight: 30,
      verticalAlign: "left",
    },
  ],
  taskName: [
    fonts.medium,
    {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: "bold",
    },
  ],
  taskInfo: [
    fonts.smallPlus,
    {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  ],
  taskDescription: [
    fonts.smallPlus,
    {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "60%",
    },
  ],
});

const TaskListItem: FunctionComponent<TaskListItemProps> = ({
  item,
  onAction,
  onClickHandler,
  actionIcon,
  actionEnabled,
}: TaskListItemProps) => {
  const { t } = useTranslation();

  const TaskTypeIcon = (): JSX.Element => {
    if (item.type === TaskTypeEnum.TASK)
      return (
        <FontIcon
          title={t("tptasks:taskList.taskItem.typeTaskIconTitle")}
          aria-label="Task"
          iconName="TaskSolid"
          className={classNames.iconBasic}
        />
      );
    else if (item.type === TaskTypeEnum.NOTE)
      return (
        <FontIcon
          title={t("tptasks:taskList.taskItem.typeNoteIconTitle")}
          aria-label="Note"
          iconName="EditNote"
          className={classNames.iconBasic}
        />
      );
    else if (item.type === TaskTypeEnum.POINT)
      return (
        <FontIcon
          title={t("tptasks:taskList.taskItem.typePointIconTitle")}
          aria-label="Point"
          iconName="StatusCircleExclamation"
          className={classNames.iconBasic}
        />
      );

    return <></>;
  };

  const TaskStateIcon = (): JSX.Element => {
    if (item.type === TaskTypeEnum.NOTE) return <></>;

    if (item.taskState && item.taskState.toLowerCase() === "close") {
      return (
        <FontIcon
          title={t("tptasks:taskList.taskItem.stateClosedLbl")}
          aria-label="Closed"
          iconName="Accept"
          className={classNames.iconGreen}
        />
      );
    } else if (item.taskState && item.taskState.toLowerCase() === "open") {
      return (
        <FontIcon
          title={t("tptasks:taskList.taskItem.stateOpenLbl")}
          aria-label="Running"
          iconName="Running"
          className={classNames.iconBlue}
        />
      );
    } else {
      return (
        <FontIcon
          title={t("tptasks:taskList.taskItem.stateUnknwLbl")}
          aria-label="Unknow"
          iconName="StatusCircleQuestionMark"
          className={classNames.iconGrey}
        />
      );
    }
  };

  const Responsible = (): JSX.Element => {
    if (!item || item.type === TaskTypeEnum.POINT || !item.taskAssignedTo || !item.taskAssignedTo._key) {
      // item.taskAssignedTo.userName === undefined || item.taskAssignedTo.userName === null) {
      return <></>;
    }
    const initials: string[] | null = limaUsersStore.getUserInitialsAndName(item.taskAssignedTo._key);
    if (initials === null) return <></>;

    return <Persona imageInitials={initials[0]} size={PersonaSize.size24} title={initials[1]} />;
  };

  useEffect(() => {
    log.info(limaLogTag.TasksListItem, `useEffect: rendering item - `, item);
    log.info(limaLogTag.TasksListItem, `useEffect: rendering item - `, JSON.stringify(item.taskData));
  }, []);

  const onMouseEnterTask = () => {
    // log.info(limaLogTag.TasksListItem, `onMouseEnterTask:  - `, item);
  };

  const onMouseLeaveTask = () => {
    // log.info(limaLogTag.TasksListItem, `onMouseLeaveTask:  - `, item);
  };

  const descriptionShort = () => {
    //{item.taskDescription != undefined && item.taskDescription}
    const trimmedString =
      item.taskDescription.length == 0
        ? "..."
        : item.taskDescription.length > 20
        ? item.taskDescription.substring(0, 100 - 3) + "..."
        : item.taskDescription;
    return trimmedString;
  };

  const DueDate = (): JSX.Element => {
    //{item.taskDescription != undefined && item.taskDescription}
    log.info(limaLogTag.TasksListItem, `DueDate: rendering item - `, item);
    if (item.type !== TaskTypeEnum.NOTE && item.taskDueDate) {
      return (
        <div className={classNames.taskInfo}>
          {t("tptasks:taskList.taskItem.duedateLbl")}:{" "}
          <Moment format={limaStore.userDateTimeFormat}>{item.taskDueDate}</Moment>
        </div>
      );
    } else {
      return <></>;
    }
  };

  if (item === undefined || item === null) return <></>;
  else
    return (
      <Stack
        horizontal
        className={classNames.itemRowWrapper}
        onMouseEnter={onMouseEnterTask}
        onMouseLeave={onMouseLeaveTask}
        tokens={numericalSpacingStackTokens}
      >
        <Stack.Item data-is-focusable={true} className={classNames.itemRowTaskInfoPart}>
          <Stack
            horizontal
            onClick={() => {
              log.info(limaLogTag.TasksListItem, `onClick: clicked on task `, item);
              if (onClickHandler !== undefined) onClickHandler(item);
            }}
            id={item._key}
          >
            <Stack.Item className={classNames.itemRowTaskTypeIconPart}>
              <TaskTypeIcon />
            </Stack.Item>
            <Stack.Item className={classNames.itemRowTaskStateIconsPart}>
              <TaskStateIcon />
            </Stack.Item>
            <Stack.Item className={classNames.itemRowTaskResponsPart}>
              <Responsible />
            </Stack.Item>
            <Stack.Item className={classNames.itemRowTaskPartContentWrapper} title={descriptionShort()}>
              <div className={classNames.taskName}>{item.taskName != undefined && item.taskName}</div>
              <div className={classNames.taskInfo}></div>
              <DueDate />
              <div className={classNames.taskDescription}>
                {item.taskDescription != undefined && descriptionShort()}
              </div>
            </Stack.Item>
          </Stack>
        </Stack.Item>
        {actionEnabled && (
          <Stack.Item className={classNames.itemRowTaskStateIconsPart}>
            <IconButton
              className={classNames.itemActionIcon}
              iconProps={actionIcon}
              onClick={() => item._key !== undefined && onAction(item._key)}
            />
          </Stack.Item>
        )}
      </Stack>
    );
};

export default TaskListItem;
