import { log, LogLevel } from "missionlog";
// const { appendFileSync } = require("fs");
///import { appendFileSync } from "node:fs";

export enum limaLogTag {
  httpAPI = "httpAPI",
  //--CALLS
  CallUpdateTaskData = "CallUpdateTaskData",
  LimaFileCalls = "LimaFileCalls",
  LimaGetTasksCall = "LimaGetTasksCall",
  LimaLicenceCalls = "LimaLicenceCalls",
  LimaUserCalls = "LimaUserCalls",
  LimaUse2UserCalls = "LimaUse2UserCalls",
  limaTimersCalls = "limaTimersCalls",
  limaCompaniesCalls = "limaCompaniesCalls",
  //--COMMON
  Index = "Index",
  APISwitch = "APISwitch",

  LimaActaList = "LimaActaList",
  limaActaCallHandler = "limaActaCallHandler",
  LimaDialog = "LimaDialog",
  LimaEditItemBaseV4 = "LimaEditItemBaseV4",
  LimaFileOpen = "LimaFileOpen",
  LimaGetForDownload = "LimaGetForDownload",
  LimaInsertDoc = "LimaInsertDoc",
  LimaPermissions = "LimaPermissions",
  LimaSaveDoc = "LimaSaveDoc",
  LimaServerVersion = "LimaServerVersion",
  LimaStat = "LimaStat",
  LimaTaskHelpers = "LimaTaskHelpers",
  limaUploadDoc = "limaUploadDoc",

  LimaVisibilityCalls = "LimaVisibilityCalls",
  LimaWordInfo = "LimaWordInfo",
  LimaWordInteract = "LimaWordInteract",
  LimaXcopyFile = "LimaXcopyFile",
  SelectHandler = "SelectHandler",
  UniversalAccess = "UniversalAccess",
  LimaUser = "LimaUser",
  //--COMMON COMPONENTS
  LimaControlledTextSwitch = "LimaControlledTextSwitch",
  UserStatisticHMcomp = "UserStatisticHMcomp",
  //--PAGES AN COMPONENTS
  AccessComponent = "AccessComponent",
  AccessItemChooser = "AccessItemChooser",
  AccessFilterV4 = "AccessFilterV4",
  ActaListV4 = "ActaListV4",
  ActsParaList = "ActsParaList",
  AdviceCheckDocPage = "AdviceCheckDocPage",
  AttachementAdd = "AttachementAdd",
  AttachementAddV4 = "AttachementAddV4",
  AttachmentItem = "AttachmentItem",
  AttachmentItemV4 = "AttachmentItemV4",
  AttachmentDDFileItem = "AttachmentDDFileItem",
  AttachmentDDFileItemV4 = "AttachmentDDFileItemV4",
  AttachementComponent = "AttachementComponent",
  AttachementComponentV4 = "AttachementComponentV4",
  CompaniesItem = "CompaniesItem",
  CompaniesPage = "CompaniesPage",
  DetailActaInfo = "DetailActaInfo",
  DragAndDropV4 = "DragAndDropV4",
  EditStatesPage = "EditStatesPage",
  FileOpen = "FileOpen",
  FileInfoBasicStat = "FileInfoBasicStat",
  FileDataItem = "FileDataItem",
  FileItemHistItemV4 = "FileItemHistItemV4",
  FileItemV4 = "FileItemV4",
  FileItemOpenList = "FileItemOpenList",
  FileItemOpenHistItem = "FileItemOpenHistItem",
  FileItemOpenHistFileName = "FileItemOpenHistFileName",
  FileItemOpenCopyItem = "FileItemOpenCopyItem",
  FileItemChangeOwner = "FileItemChangeOwner",
  FileItemChangeOwnerV4 = "FileItemChangeOwnerV4",
  FileListCopies = "FileListCopies",
  FileListVersions = "FileListVersions",
  FileItemImport = "FileItemImport",
  FileSaveToCopy = "FileSaveToCopy",
  FileSaveToXCopy = "FileSaveToXCopy",
  FileSaveToExists = "FileSaveToExists",
  FileSaveNew = "FileSaveNew",
  FileVersionsV4 = "FileVersionsV4",
  FoundUserToLink = "FoundUserToLink",
  Header = "Header",
  HistParaItemPage = "HistParaItemPage",
  HistParaItemParaText = "HistParaItemParaText",
  HistParaItemParaTaskItems = "HistParaItemParaTaskItems",
  HistParaItemParaTextItems = "HistParaItemParaTextItems",
  HistParaItemParaCommentsItems = "HistParaItemParaCommentsItems",
  HistParaItemParaTaskRow2 = "HistParaItemParaTaskRow2",
  HistParaItemTaskRow = "HistParaItemTaskRow",
  HistParaItemTasks = "HistParaItemTasks",
  Home2 = "Home2",
  HomeCompLic = "HomeCompLic",
  ItemLinkingV4 = "ItemLinkingV4",
  JudgeActParaList = "JudgeActParaList",
  KeywordsList = "KeywordsList",
  KnowledgetemV4 = "KnowledgetemV4",
  KnowledgeListV4 = "KnowledgeListV4",
  KnowledgePageV4 = "KnowledgePageV4",
  LanguageSwitcher = "LanguageSwitcher",
  LimaActaWordDocCompare = "LimaActaWordDocCompare",
  LimaActaWordOpenNewWindow = "LimaActaWordOpenNewWindow",
  LimaActionIconCompareCopy = "LimaActionIconCompareCopy",
  LimaBreadCrumb4 = "LimaBreadCrumb4",
  LimaBreadCrumb4Ext = "LimaBreadCrumb4Ext",
  LimaEqualChangePage = "LimaEqualChangePage",
  LimaEqualParasList = "LimaEqualParasList",
  LimaFileList = "LimaFileList",
  LimaHideableSection2 = "LimaHideableSection2",
  LimaLinkignItemActionsV4 = "LimaLinkignItemActionsV4",
  LimaMenu = "LimaMenu",
  TimersV4report = "TimersV4report",
  LoaderComponent = "LoaderComponent",
  LoginSheet = "LoginSheet",
  Login = "Login",
  LoginProcessing = "LoginProcessing",
  MainCopyNewVersionV4 = "MainCopyNewVersionV4",
  MessageComponent = "MessageComponent",
  NewVersionFormV4 = "NewVersionFormV4",
  ShowUserAccessPage = "ShowUserAccessPage",
  ShowUserAccessItem = "ShowUserAccessItem",
  ShowUserAccessList = "ShowUserAccessList",
  ShowUserAccessListItem = "ShowUserAccessListItem",
  TagsComponent = "TagsComponent",
  TagsAddComponent = "TagsAddComponent",
  TagsSelectColor = "TagsSelectColor",
  TaskEditPage = "TaskEditPage",
  TaskEditPageV2 = "TaskEditPageV2",
  TaskEditPageV4 = "TaskEditPageV4",
  TasksListFilter = "TasksListFilter",
  TasksList = "TasksList",
  TaskHistoryComponent = "TaskHistoryComponent",
  TaskItem = "TaskItem",
  TaskItemEdit = "TaskItemEdit",
  TaskItemEditV2 = "TaskItemEditV2",
  TaskItemV4Single = "TaskItemV4Single",
  TaskItemGetStoreDoc = "TaskItemGetStoreDoc",
  TaskItemAssigned = "TaskItemAssigned",
  TaskLinking = "TaskLinking",
  TasksListItem = "TasksListItem",
  TasksListItemV3 = "TasksListItemV3",
  TaskXcopyItem = "TaskXcopyItem",
  Task2Task = "Task2Task",
  ToolsActionsPart = "ToolsActionsPart",
  ToolActionAddTask = "ToolActionAddTask",
  User2UserLinkComponent = "User2UserLinkComponent",
  User2UserLinksComponentV4 = "User2UserLinksComponentV4",
  User2UserLinkItem = "User2UserLinkItem",
  UserAddEdit = "UserAddEdit",
  UserDetailV4 = "UserDetailV4",
  UserEditContext = "UserEditContext",
  UserInviteV4 = "UserInviteV4",
  UserLicencesPage = "UserLicencesPage",
  UserLinkingPage = "UserLinkingPage",
  UsersList = "UsersList",
  UsersListV4 = "UsersListV4",
  UserPermissionsEditPage = "UserPermissionsEditPage",
  UserPermissionsList = "UserPermissionsList",
  UserPermissionsAdd = "UserPermissionsAdd",
  UserSetting = "UserSetting",
  UserStatisticList = "UserStatisticList",
  UserStatisticActivity = "UserStatisticActivity",
  UserAccessCopyPage = "UserAccessCopyPage",
  UsersLinkingPage = "UsersLinkingPage",
  VersionsInfo = "VersionsInfo",
  WelcomePage = "WelcomePage",
  WorkingCopiesDetailV4 = "WorkingCopiesDetailV4",
  WorkingCopyListItemV4 = "WorkingCopyListItemV4",
  WorkingCopySaveExistV4 = "WorkingCopySaveExistV4",
  //--STORES
  LimaActasStore = "LimaActasStore",
  LimaObjTagsStore = "LimaObjTagsStore",
  LimaStore = "LimaStore",
  LimaVisibleStore = "LimaVisibleStore",
  LimaTasksStore = "LimaTasksStore",
  LimaTasksStoreV4 = "LimaTasksStoreV4",
  LimaTagsStore = "LimaTagsStore",
  LimaTimerStore = "LimaTimerStore",
  LimaUserStore = "LimaUserStore",
  LimaLogsStore = "LimaLogsStore",
}

export const limaLogInit = (): void => {
  log.init(
    {
      httpAPI: "INFO",
      //--CALLS
      CallUpdateTaskData: "INFO",
      LimaFileCalls: "INFO",
      LimaGetTasksCall: "INFO",
      LimaLicenceCalls: "INFO",
      LimaUserCalls: "INFO",
      LimaUse2UserCalls: "INFO",
      limaTimersCalls: "INFO",
      limaCompaniesCalls: "INFO",
      //--COMMON
      Index: "INFO",
      APISwitch: "INFO",
      limaActaCallHandler: "INFO",
      LimaActionIconCompareCopy: "INFO",
      LimaDialog: "INFO",
      LimaActaList: "OFF",
      LimaEditItemBaseV4: "INFO",
      LimaFileOpen: "INFO",
      LimaGetForDownload: "INFO",
      LimaInsertDoc: "INFO",
      LimaPermissions: "ERROR",
      LimaSaveDoc: "ERROR",
      LimaServerVersion: "INFO",
      LimaStat: "ERROR",
      LimaTaskHelpers: "INFO",
      limaUploadDoc: "DEBUG",
      LimaVisibilityCalls: "INFO",
      LimaWordInfo: "INFO",
      LimaWordInteract: "INFO",
      LimaXcopyFile: "INFO",
      SelectHandler: "INFO",
      UniversalAccess: "INFO",
      LimaUser: "INFO",
      //--COMMON COMPONENTS
      LimaControlledTextSwitch: "ERROR",
      UserStatisticHMcomp: "INFO",

      //--PAGES AN COMPONENTS
      AccessComponent: "INFO",
      AccessItemChooser: "INFO",
      AccessFilterV4: "INFO",
      ActaListV4: "INFO",
      ActsParaList: "ERROR",
      AdviceCheckDocPage: "INFO",
      AttachementAdd: "INFO",
      AttachementAddV4: "INFO",
      AttachmentItem: "INFO",
      AttachmentItemV4: "INFO",
      AttachmentDDFileItem: "INFO",
      AttachmentDDFileItemV4: "INFO",
      AttachementComponent: "INFO",
      AttachementComponentV4: "INFO",
      CompaniesItem: "INFO",
      CompaniesPage: "INFO",
      DetailActaInfo: "INFO",
      DragAndDropV4: "INFO",
      EditStatesPage: "INFO",
      FileOpen: "INFO",
      FileInfoBasicStat: "INFO",
      FileDataItem: "ERROR",
      FileItemHistItemV4: "INFO",
      FileItemV4: "INFO",
      FileItemOpenList: "INFO",
      FileItemOpenHistItem: "ERROR",
      FileItemOpenHistFileName: "INFO",
      FileItemOpenCopyItem: "ERROR",
      FileItemChangeOwner: "ERROR",
      FileItemChangeOwnerV4: "INFO",
      FileListCopies: "INFO",
      FileListVersions: "DEBUG",
      FileItemImport: "INFO",
      FileSaveToCopy: "ERROR",
      FileSaveToExists: "ERROR",
      FileSaveToXCopy: "INFO",
      FileSaveNew: "INFO",
      FileVersionsV4: "INFO",
      FoundUserToLink: "INFO",
      Header: "INFO",
      HistParaItemPage: "INFO",
      HistParaItemParaText: "INFO",
      HistParaItemParaTaskItems: "INFO",
      HistParaItemParaTextItems: "INFO",
      HistParaItemParaCommentsItems: "INFO",
      HistParaItemParaTaskRow2: "INFO",
      HistParaItemTaskRow: "INFO",
      HistParaItemTasks: "INFO",
      Home2: "INFO",
      HomeCompLic: "INFO",
      ItemLinkingV4: "INFO",
      JudgeActParaList: "ERROR",
      KeywordsList: "ERROR",
      KnowledgetemV4: "INFO",
      KnowledgeListV4: "INFO",
      KnowledgePageV4: "INFO",
      LanguageSwitcher: "INFO",
      LimaActaWordDocCompare: "INFO",
      LimaActaWordOpenNewWindow: "INFO",
      LimaBreadCrumb4: "INFO",
      LimaBreadCrumb4Ext: "INFO",
      LimaEqualChangePage: "INFO",
      LimaEqualParasList: "INFO",
      LimaFileList: "ERROR",
      LimaHideableSection2: "INFO",
      LimaLinkignItemActionsV4: "INFO",
      LimaMenu: "INFO",
      TimersV4report: "INFO",
      LoaderComponent: "ERROR",
      LoginSheet: "INFO",
      Login: "INFO",
      LoginProcessing: "INFO",
      MainCopyNewVersionV4: "INFO",
      MessageComponent: "INFO",
      NewVersionFormV4: "INFO",
      ShowUserAccessPage: "ERROR",
      ShowUserAccessItem: "OFF",
      ShowUserAccessList: "INFO",
      ShowUserAccessListItem: "ERROR",
      TagsComponent: "ERROR",
      TagsAddComponent: "ERROR",
      TagsSelectColor: "INFO",
      TaskEditPage: "ERROR",
      TaskEditPageV2: "ERROR",
      TasksListFilter: "INFO",
      TasksList: "ERROR",
      TaskHistoryComponent: "INFO",
      TaskItem: "INFO",
      TaskItemEdit: "ERROR",
      TaskItemEditV2: "INFO",
      TaskItemV4Single: "INFO",
      TaskEditPageV4: "INFO",
      TaskItemGetStoreDoc: "ERROR",
      TaskLinking: "INFO",
      TaskItemAssigned: "ERROR",
      TasksListItem: "ERROR",
      TasksListItemV3: "ERROR",
      TaskXcopyItem: "ERROR",
      Task2Task: "INFO",
      ToolsActionsPart: "INFO",
      ToolActionAddTask: "INFO",
      User2UserLinkComponent: "INFO",
      User2UserLinksComponentV4: "INFO",
      User2UserLinkItem: "INFO",
      UserAddEdit: "INFO",
      UserDetailV4: "INFO",
      UserEditContext: "INFO",
      UserLinkingPage: "INFO",
      UserInviteV4: "INFO",
      UserLicencesPage: "INFO",
      UsersList: "INFO",
      UsersListV4: "INFO",
      UserPermissionsEditPage: "ERROR",
      UserPermissionsList: "INFO",
      UserPermissionsAdd: "ERROR",
      UserSetting: "ERROR",
      UserStatisticList: "INFO",
      UserStatisticActivity: "INFO",
      UserAccessCopyPage: "ERROR",
      UsersLinkingPage: "ERROR",
      VersionsInfo: "OFF",
      WelcomePage: "OFF",
      WorkingCopiesDetailV4: "INFO",
      WorkingCopyListItemV4: "INFO",
      WorkingCopySaveExistV4: "INFO",
      //--STORES
      LimaActasStore: "INFO",
      LimaObjTagsStore: "INFO",
      LimaStore: "INFO",
      LimaVisibleStore: "INFO",
      LimaTasksStore: "INFO",
      LimaTasksStoreV4: "ERROR",
      LimaTagsStore: "ERROR",
      LimaTimerStore: "INFO",
      LimaUserStore: "ERROR",
      LimaLogsStore: "INFO",
    },
    (level, tag, msg, params) => {
      const prefix = `${level}: [${tag}] `;
      switch (level) {
        case LogLevel.ERROR:
          window.console.error(prefix, msg, ...params);
          break;
        case LogLevel.WARN:
          window.console.warn(prefix, msg, ...params);
          break;
        case LogLevel.DEBUG:
          window.console.debug(prefix, `....${String(msg)}`, ...params);
          break;
        case LogLevel.INFO:
          window.console.info(prefix, msg, ...params);
          // IN CASE THAT WE WILL SEND SOMEWHERE httpPostLog(urlFELogging(), `[${level}:${tag}] msg: '${msg}' params:'${params}'`);
          //fs.appendFileSync("V:/var/log/logresults.txt", msg + " ---- " + params.join("\n") + "\n");
          break;
      }
    }
  );
};
