import { IChoiceGroupOption } from "@fluentui/react";
import { is_SameStringArray } from "../limaCommon/limaArrayHelpers";
import { BASE_LimaData } from "./BASE_LimaData";
import { Task_withActaXcopy, toTask } from "./Task_withActaXcopy";
import { IUser, User_clone, User_compare, User_getNull } from "./User";

export enum XCOPY_TYPE {
  NOXCOPY = "NOXCOPY",
  FULLXCOPY = "FULLXCOPY",
  TRIMXCOPY = "TRIMXCOPY",
  MAINXCOPY = "MAINXCOPY",
}

export const Xcopy_options: IChoiceGroupOption[] = [
  { key: XCOPY_TYPE.NOXCOPY, text: "No Xcopy", defaultChecked: true },
  { key: XCOPY_TYPE.FULLXCOPY, text: "Full Xcopy" },
  { key: XCOPY_TYPE.TRIMXCOPY, text: "Just showned" },
];

export const Xcopy_options_notNoCopy: IChoiceGroupOption[] = [
  // { key: XCOPY_TYPE.NOXCOPY, text: "No Xcopy", disabled: true },
  { key: XCOPY_TYPE.FULLXCOPY, text: "Full Xcopy", defaultChecked: true },
  { key: XCOPY_TYPE.TRIMXCOPY, text: "Just showned" },
];

export type TaskData = {
  commentIds: string[];
  paraIds: string[];
  actaKey: string;
  xcopyKey: string;
  xcopytype: XCOPY_TYPE;
  attachements: string[];
};

export enum TaskTypeEnum {
  TASK = "TASK",
  NOTE = "NOTE",
  POINT = "POINT",
}

export interface Task extends BASE_LimaData {
  taskDescription: string;
  taskDueDate: string;
  taskAssignedTo: IUser;
  taskName: string;
  taskData: TaskData;
  taskState: string;
  luIssuedBy: string;
  luTimeStamp: string;
  type: TaskTypeEnum;
}

export const Task_getNull = (): Task => {
  return {
    _id: null,
    _key: null,
    cIssuedBy: null,
    cTimeStamp: null,
    dIssuedBy: null,
    dtimestamp: null,
    luIssuedBy: null,
    luTimeStamp: null,
    taskAssignedTo: User_getNull(),
    taskData: {
      actaKey: null,
      commentIds: [],
      paraIds: [],
      xcopyKey: null,
      xcopytype: XCOPY_TYPE.NOXCOPY,
      attachements: [],
    },
    taskDescription: null,
    taskDueDate: null,
    taskName: null,
    taskState: null,
    type: TaskTypeEnum.TASK,
  };
};

export const Task_clone = (taskToClone: Task): Task => {
  if (taskToClone == undefined) {
    return Task_getNull();
  }
  return {
    _id: taskToClone._id,
    _key: taskToClone._key,
    cIssuedBy: taskToClone.cIssuedBy,
    cTimeStamp: taskToClone.cTimeStamp,
    dIssuedBy: taskToClone.dIssuedBy,
    dtimestamp: taskToClone.dtimestamp,
    luIssuedBy: taskToClone.luIssuedBy,
    luTimeStamp: taskToClone.luTimeStamp,
    taskAssignedTo: User_clone(taskToClone.taskAssignedTo),
    taskData: TaskData_clone(taskToClone.taskData),
    taskDescription: taskToClone.taskDescription,
    taskDueDate: taskToClone.taskDueDate,
    taskName: taskToClone.taskName,
    taskState: taskToClone.taskState,
    type: taskToClone.type,
  };
};

export const Task_compare = (taskOrigin: Task, taskToCompare: Task): boolean => {
  if (taskOrigin._id !== taskToCompare._key) return false;
  if (taskOrigin._key !== taskToCompare._key) return false;
  if (taskOrigin.cIssuedBy !== taskToCompare.cIssuedBy) return false;
  if (taskOrigin.cTimeStamp !== taskToCompare.cTimeStamp) return false;
  if (taskOrigin.dIssuedBy !== taskToCompare.dIssuedBy) return false;
  if (taskOrigin.dtimestamp !== taskToCompare.dtimestamp) return false;
  if (taskOrigin.luIssuedBy !== taskToCompare.luIssuedBy) return false;
  if (taskOrigin.luTimeStamp !== taskToCompare.luTimeStamp) return false;
  if (taskOrigin.taskDescription !== taskToCompare.taskDescription) return false;
  if (taskOrigin.taskDueDate !== taskToCompare.taskDueDate) return false;
  if (taskOrigin.taskName !== taskToCompare.taskName) return false;
  if (taskOrigin.taskState !== taskToCompare.taskState) return false;
  if (taskOrigin.type !== taskToCompare.type) return false;
  //sum structures
  if (User_compare(taskOrigin.taskAssignedTo, taskToCompare.taskAssignedTo) !== true) return false;
  if (TaskData_compare(taskOrigin.taskData, taskToCompare.taskData) !== true) return false;

  return true;
};

export const TaskData_getNull = (): TaskData => {
  return {
    actaKey: null,
    commentIds: [],
    paraIds: [],
    xcopyKey: null,
    xcopytype: XCOPY_TYPE.NOXCOPY,
    attachements: [],
  };
};

export const TaskData_clone = (taskDataToClone: TaskData): TaskData => {
  if (taskDataToClone == undefined) {
    return TaskData_getNull();
  }
  return {
    actaKey: taskDataToClone.actaKey,
    commentIds: taskDataToClone.commentIds,
    paraIds: taskDataToClone.paraIds,
    xcopyKey: taskDataToClone.xcopyKey,
    xcopytype: taskDataToClone.xcopytype,
    attachements: taskDataToClone.attachements,
  };
};

export const TaskData_compare = (taskDataOrigin: TaskData, taskDataToCompare: TaskData): boolean => {
  if (taskDataOrigin.actaKey !== taskDataToCompare.actaKey) return false;
  if (taskDataOrigin.xcopyKey !== taskDataToCompare.xcopyKey) return false;
  if (taskDataOrigin.xcopytype !== taskDataToCompare.xcopytype) return false;
  //arrays

  if (
    taskDataOrigin.commentIds.length !== taskDataToCompare.commentIds.length ||
    taskDataOrigin.commentIds.every((value) => taskDataToCompare.commentIds.includes(value)) !== true
  )
    return false;
  if (
    taskDataOrigin.paraIds.length !== taskDataToCompare.paraIds.length ||
    taskDataOrigin.paraIds.every((value) => taskDataToCompare.paraIds.includes(value)) !== true
  )
    return false;
  if (
    taskDataOrigin.attachements.length !== taskDataToCompare.attachements.length ||
    taskDataOrigin.attachements.every((value) => taskDataToCompare.attachements.includes(value)) !== true
  )
    return false;

  // window.console.log("COMAPRE TaskData_compare", true);
  return true;
};

export enum TASKSTATE {
  NONE = "",
  OPEN = "open",
  CLOSE = "close",
}

export const dropdownControlledTaskStateOptions = [
  { key: "", text: "Select state" },
  { key: "open", text: "Open" },
  { key: "close", text: "Close" },
];

export type TaskForm = {
  taskname: string;
  taskdescription: string;
  taskduedate: Date;
  taskstate: string;
  taskassigned: string;
  type: TaskTypeEnum;
};

export const compareTaskFormToTask_withActaXcopy = (
  taskForm: TaskForm,
  taskData: TaskData,
  taskWithActaXcopy: Task_withActaXcopy
): boolean => {
  const taskFromTaskWithXcopy: Task = toTask(taskWithActaXcopy);
  window.console.log("TASK COMPARE task - taskData", taskData);
  window.console.log("TASK COMPARE task - ", taskWithActaXcopy);
  window.console.log("TASK COMPARE task - taskname?");
  if (taskForm.taskname !== taskFromTaskWithXcopy.taskName) return false;
  window.console.log("TASK COMPARE task - same taskname, taskDescription?");
  if (taskForm.taskdescription !== taskFromTaskWithXcopy.taskDescription) return false;
  window.console.log("TASK COMPARE task - same taskname, taskDescription, taskDueDate?");
  if (
    taskFromTaskWithXcopy.taskDueDate !== undefined &&
    taskFromTaskWithXcopy.taskDueDate !== null &&
    taskForm.taskduedate.toISOString() !== new Date(taskFromTaskWithXcopy.taskDueDate).toISOString()
  )
    return false;
  window.console.log("TASK COMPARE task - same taskname, taskDescription, taskDueDate, taskState?");
  if (taskForm.taskstate !== taskFromTaskWithXcopy.taskState) return false;
  window.console.log(
    "TASK COMPARE task - same taskname, taskDescription, taskDueDate, taskState, taskAssignedTo?",
    taskForm.taskassigned
  );
  taskFromTaskWithXcopy.taskAssignedTo &&
    window.console.log(
      "TASK COMPARE task - same taskname, taskDescription, taskDueDate, taskState, taskAssignedTo?",
      taskForm.taskassigned,
      taskFromTaskWithXcopy.taskAssignedTo._key
    );
  // if((taskFromTaskWithXcopy.taskAssignedTo === undefined ||
  //   taskFromTaskWithXcopy.taskAssignedTo === null) && taskForm.taskassigned!==null)
  //   return false;
  if (
    taskFromTaskWithXcopy.taskAssignedTo !== undefined &&
    taskFromTaskWithXcopy.taskAssignedTo !== null &&
    taskForm.taskassigned !== taskFromTaskWithXcopy.taskAssignedTo._key
  )
    return false;
  window.console.log("TASK COMPARE task - same taskname, taskDescription, taskDueDate,  taskAssignedTo, type?");
  if (taskForm.type !== taskFromTaskWithXcopy.type) return false;

  // ---- TASK DATA ----
  window.console.log(
    "TASK COMPARE task - same taskname, taskDescription, taskDueDate,  taskAssignedTo, type, xcopytype?"
  );
  if (taskData.xcopyKey !== taskWithActaXcopy.taskData.xcopyKey) return false;
  window.console.log(
    "TASK COMPARE task - same taskname, taskDescription, taskDueDate,  taskAssignedTo, type, xcopyKey, xcopytype?"
  );
  if (taskData.xcopytype !== taskWithActaXcopy.taskData.xcopytype) return false;
  window.console.log(
    "TASK COMPARE task - same taskname, taskDescription, taskDueDate,  taskAssignedTo, type, xcopyKey, xcopytype, paraIds?"
  );
  if (!is_SameStringArray(taskData.paraIds, taskWithActaXcopy.taskData.paraIds)) return false;
  window.console.log(
    "TASK COMPARE task - same taskname, taskDescription, taskDueDate,  taskAssignedTo, type, xcopyKey, xcopytype, paraIds, commentIds?"
  );
  if (!is_SameStringArray(taskData.commentIds, taskWithActaXcopy.taskData.commentIds)) return false;
  window.console.log(
    "TEST COMPARE task - same taskname, taskDescription, taskDueDate,  taskAssignedTo, type, xcopyKey, xcopytype, paraIds, commentIds, attachements?"
  );
  if (!is_SameStringArray(taskData.attachements, taskWithActaXcopy.taskData.attachements)) return false;

  window.console.log("TASK COMPARE task - taskData all same");
  return true;
};

export function isTask(item: unknown): item is Task {
  return (item as Task).taskName !== undefined;
}

export interface Task2Task extends BASE_LimaData {
  task: Task;
  taskto: Task;
}
