/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { limaLogTag } from "../limaCommon/limaLog";
import limaLogsStore, { ENUM_LogstoreLogType } from "../store/limaLogsStore";
import limaStore from "../store/limaStore";
import { urlServerBaseNoChangePlease } from "./apilinks";

export interface HttpResponse<T> extends Response {
  parsedBody?: T;
}

export async function httpGet<T>(
  path: string,
  ac: AbortController,
  headers?: any,
  args: RequestInit = {
    method: "get",
    mode: "cors",
    headers: {
      ...headers,
      "cache-control": "no-cache, must-revalidate, post-check=0, pre-check=0, max-age=0",
      "Content-Type": "application/json",
      "Sec-Fetch-Site": "cross-site",
    },
  }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(updateURL(path), args), ac);
}

export async function httpGetAuth<T>(
  path: string,
  ac: AbortController,
  headers?: any,
  args: RequestInit = {
    method: "get",
    mode: "cors",
    headers: {
      ...headers,
      Authorization: `Bearer ${limaStore.access_token}`, //TODO: future add
      //'Content-Type': 'multipart/form-data',
      "cache-control": "no-cache, must-revalidate, post-check=0, pre-check=0, max-age=0",
      "Content-Type": "application/json",
      "Sec-Fetch-Site": "cross-site",
    },
  }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(updateURL(path), args), ac);
}

export async function httpGetAuth_noParse(
  path: string,
  ac: AbortController,
  headers?: any,
  args: RequestInit = {
    method: "get",
    mode: "cors",
    headers: {
      ...headers,
      Authorization: `Bearer ${limaStore.access_token}`, //TODO: future add
      //'Content-Type': 'multipart/form-data',
      "cache-control": "no-cache, must-revalidate, post-check=0, pre-check=0, max-age=0",
      "Content-Type": "application/json",
    },
  }
): Promise<Response> {
  return await http_noParse(new Request(updateURL(path), args), ac);
}

/**
 * Coommon funciton that send Fetch using post.. innert processing is by await
 * @param path string define URL
 * @param body body that will by stringify to send
 * @param loadaderID Abbortcontroller
 * @param headers json object of headers that will added to common headers
 * @param args this are argument
 *
 * //TODO: change to promiss
 */

export async function httpPost<T>(
  path: string,
  body: any,
  ac: AbortController,
  headers?: any,
  args: RequestInit = {
    method: "post",
    mode: "cors",
    headers: {
      ...headers,
      //'Authorization': 'Bearer ' + Auth.getToken(), //TODO: future add
      //'Content-Type': 'multipart/form-data',
      "cache-control": "no-cache, must-revalidate, post-check=0, pre-check=0, max-age=0",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(updateURL(path), args), ac);
}

export async function httpPostAuth<T>(
  path: string,
  body: any,
  ac: AbortController,
  headers?: any,
  args: RequestInit = {
    method: "post",
    mode: "cors",
    headers: {
      ...headers,
      Authorization: `Bearer ${limaStore.access_token}`,
      //'Content-Type': 'multipart/form-data',
      "cache-control": "no-cache, must-revalidate, post-check=0, pre-check=0, max-age=0",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(updateURL(path), args), ac);
}

/**
 *
 * @param path
 * @param body
 * @param ac
 * @param headers
 * @param args
 * @returns
 *
 * @depreciate
 */
export async function httpPut<T>(
  path: string,
  body: any,
  ac: AbortController,
  headers?: any,
  args: RequestInit = {
    method: "put",
    mode: "cors",
    headers: {
      ...headers,
      "cache-control": "no-cache, must-revalidate, post-check=0, pre-check=0, max-age=0",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(updateURL(path), args), ac);
}

export async function httpPutAuth<T>(
  path: string,
  body: any,
  ac: AbortController,
  headers?: any,
  args: RequestInit = {
    method: "put",
    mode: "cors",
    headers: {
      ...headers,
      Authorization: `Bearer ${limaStore.access_token}`,
      "cache-control": "no-cache, must-revalidate, post-check=0, pre-check=0, max-age=0",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(updateURL(path), args), ac);
}

export async function httpPatch<T>(
  path: string,
  body: any,
  ac: AbortController,
  headers?: any,
  args: RequestInit = {
    method: "patch",
    mode: "cors",
    headers: {
      ...headers,
      "cache-control": "no-cache, must-revalidate, post-check=0, pre-check=0, max-age=0",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(updateURL(path), args), ac);
}

export async function httpPatchAuth<T>(
  path: string,
  body: any,
  ac: AbortController,
  headers?: any,
  args: RequestInit = {
    method: "patch",
    mode: "cors",
    headers: {
      ...headers,
      Authorization: `Bearer ${limaStore.access_token}`,
      //'Content-Type': 'multipart/form-data',
      "cache-control": "no-cache, must-revalidate, post-check=0, pre-check=0, max-age=0",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(updateURL(path), args), ac);
}

/**
 *
 * @param path
 * @param body
 * @param ac
 * @param headers
 * @param args
 * @returns
 *
 * @depreciate
 */

export async function httpDelete<T>(
  path: string,
  body: any,
  ac: AbortController,
  headers?: any,
  args: RequestInit = {
    method: "delete",
    mode: "cors",
    headers: {
      ...headers,
      //'Authorization': 'Bearer ' + Auth.getToken(), //TODO: future add
      //'Content-Type': 'multipart/form-data',
      "cache-control": "no-cache, must-revalidate, post-check=0, pre-check=0, max-age=0",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(updateURL(path), args), ac);
}

export async function httpDeleteByPUTAuth<T>(
  path: string,
  body: any,
  ac: AbortController,
  headers?: any,
  args: RequestInit = {
    method: "put",
    mode: "cors",
    headers: {
      ...headers,
      Authorization: `Bearer ${limaStore.access_token}`,
      "cache-control": "no-cache, must-revalidate, post-check=0, pre-check=0, max-age=0",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(updateURL(path), args), ac);
}

export async function httpDeleteDeleteAuth<T>(
  path: string,
  // body: any,
  ac: AbortController,
  headers?: any,
  args: RequestInit = {
    method: "delete",
    mode: "cors",
    headers: {
      ...headers,
      Authorization: `Bearer ${limaStore.access_token}`,
      "cache-control": "no-cache, must-revalidate, post-check=0, pre-check=0, max-age=0",
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(body),
  }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(updateURL(path), args), ac);
}

export async function httpPostMulipart<T>(
  path: string,
  formData: FormData,
  ac: AbortController,
  headers?: any,
  args: RequestInit = {
    method: "post",
    mode: "cors",
    redirect: "follow",
    headers: {
      ...headers,
      //'Authorization': 'Bearer ' + Auth.getToken(), //TODO: future add
      "cache-control": "no-cache, must-revalidate, post-check=0, pre-check=0, max-age=0",
    },
    body: formData,
  }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(updateURL(path), args), ac);
}

//TODO: add authorization
export async function httpPostMulipartAuth<T>(
  path: string,
  formData: FormData,
  ac: AbortController,
  parse: boolean,
  headers?: any,
  args: RequestInit = {
    method: "post",
    mode: "cors",
    redirect: "follow",
    // referrer: "no-referrer",
    // credentials: "same-origin",
    headers: {
      ...headers,
      Authorization: `Bearer ${limaStore.access_token}`,
      "Content-Disposition": "form-data",
      "cache-control": "no-cache, must-revalidate, post-check=0, pre-check=0, max-age=0",
    },
    body: formData,
  }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(updateURL(path), args), ac, parse);
}

/*
const response = await post<{ id: number }>(
  "https://jsonplaceholder.typicode.com/posts",
  { title: "my post", body: "some content" }
);
*/
export async function httpPostLog<T>(
  path: string,
  body: any,
  ac: AbortController,
  headers?: any,
  args: RequestInit = {
    method: "post",
    mode: "cors",
    headers: {
      ...headers,
      //'Authorization': 'Bearer ' + Auth.getToken(), //TODO: future add
      //'Content-Type': 'multipart/form-data',
      "cache-control": "no-cache, must-revalidate, post-check=0, pre-check=0, max-age=0",
      "Content-Type": "text/plain",
    },
    body: JSON.stringify(body),
  }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(updateURL(path), args), ac);
}

export async function put<T>(
  path: string,
  body: any,
  ac: AbortController,
  args: RequestInit = { method: "put", body: JSON.stringify(body) }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(updateURL(path), args), ac);
}

export function updateURL(requestURL: string): string {
  //TODO: TESTINGONLY
  return requestURL.replace(urlServerBaseNoChangePlease, limaStore.baseURL);
}

export async function http_noParse(request: Request, ac: AbortController): Promise<Response> {
  try {
    const signal = ac.signal;
    const response: Response = await fetch(request, { signal });
    // //const response2 = response.clone();
    // try {
    //   // may error if there is no body
    //   response.parsedBody = await response2.json();
    // } catch (ex) {
    //   window.console.log("http > ERRORRR");
    // }
    // if (!response.ok) {
    //   throw new Error(response.statusText);
    // }
    return response;
  } catch (ex: unknown) {
    window.console.log("CATCH ON HTTP");
    if (isAbortError(ex)) {
      window.console.log(ex.message);
      return null;
    }
    // throw new Error(ex as string);
    return null;
  }
}

export async function http<T>(request: Request, ac: AbortController, convert2Json?: boolean): Promise<HttpResponse<T>> {
  try {
    const signal = ac.signal;
    const response: HttpResponse<T> = await fetch(request, { signal });
    const response2 = response.clone();
    if (convert2Json === undefined || convert2Json === true) {
      try {
        // may error if there is no body
        response.parsedBody = await response2.json();
      } catch (ex) {
        window.console.log("http > ERRORRR");
      }
    }

    if (!response.ok) {
      window.console.log("http > ERRORRR - no resposne OK");
      throw new Error(response.statusText);
    }
    return response;
  } catch (ex: unknown) {
    window.console.log("CATCH ON HTTP");
    if (isAbortError(ex)) {
      limaLogsStore.add(
        "HTTP call error",
        "ther was na error on HTTP call - Abort",
        ENUM_LogstoreLogType.ERROR,
        limaLogTag.httpAPI
      );
      window.console.log(ex.message);
      return null;
    }
    // throw new Error(ex as string);
    limaLogsStore.add(
      "HTTP call error",
      "ther was na error on HTTP call some err",
      ENUM_LogstoreLogType.ERROR,
      limaLogTag.httpAPI
    );
    return null;
  }
}

function isAbortError(error: any): error is DOMException {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (error && error.name === "AbortError") {
    return true;
  }
  return false;
}
