import * as React from "react";
import { FunctionComponent, useEffect } from "react";

// import { getTheme, ITheme } from "office-ui-fabric-react";
// import { User2Task_withActaXcopy } from "../../../../../types/User2Task_withActaXcopy";
import {
  isTask_withActaXcopyParasCommentsTasksMessagesAttachements,
  Task_withActaXcopy,
  Task_withActaXcopyParasCommentsTasksMessagesAttachements,
} from "../../../../../types/Task_withActaXcopy";
// import Moment from "react-moment";
// import limaUsersStore from "../../../../../store/limaUsersStore";
import { log } from "missionlog";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import Routes from "../../../routes";
import { useHistory } from "react-router-dom";
import { highlightTaskLinks3, HIGHLIGHT_TYPE } from "../../../../../limaCommon/limaWordInteract";
import { SelectedInWord } from "../../../../../types/wordObjects";
import { WORDITEMTYPE } from "../../../../../api/apilinks";
import TaskListItem from "../../../Edit/Tasks/components/TaskListItem";
import { IStackTokens, mergeStyleSets, Stack, StackItem } from "office-ui-fabric-react";
import { LimaInfoIconItem } from "../../../../../limaComponents/LimaInfoIconItem";

export interface HistParaItemParaTaskRow2Props {
  itemKey: string;
  index?: number;
  showHighlight: boolean;
  paraHistoryTaskRow: Task_withActaXcopy | Task_withActaXcopyParasCommentsTasksMessagesAttachements;
}

//TODO: show only around changes
// const theme: ITheme = getTheme();
// const { palette } = theme;

const classNames = mergeStyleSets({
  mainTaskPart: {
    minWidth: "80%",
    maxWidth: "80%",
  },
  wrapper: {
    borderBottom: "1px solid rgb(237, 235, 233)",
  },
});

const numericalSpacingStackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 0,
  maxWidth: "95%",
};

const HistParaItemParaTaskRow2: FunctionComponent<HistParaItemParaTaskRow2Props> = ({
  itemKey,
  index,
  showHighlight,
  paraHistoryTaskRow,
}: HistParaItemParaTaskRow2Props) => {
  const history = useHistory();

  useEffect(() => {
    log.info(limaLogTag.HistParaItemParaTaskRow2, `mounted as: `, paraHistoryTaskRow);
  }, []);

  const onMouseEnterTask = () => {
    if (showHighlight) {
      const words: SelectedInWord[] = paraHistoryTaskRow.taskData.paraIds.map((item: string): SelectedInWord => {
        return { type: WORDITEMTYPE.para, id: item };
      });
      log.info(limaLogTag.HistParaItemParaTaskRow2, `onMouseEnterTask ${itemKey}`, paraHistoryTaskRow, words);
      void highlightTaskLinks3(words, true, HIGHLIGHT_TYPE.HIGHLIGHT);
    }
  };
  const onMouseLeaveTask = () => {
    if (showHighlight) {
      const words: SelectedInWord[] = paraHistoryTaskRow.taskData.paraIds.map((item: string): SelectedInWord => {
        return { type: WORDITEMTYPE.para, id: item };
      });
      log.info(limaLogTag.HistParaItemParaTaskRow2, `onMouseLeaveTask ${itemKey}`, paraHistoryTaskRow, words);
      void highlightTaskLinks3(words, false, HIGHLIGHT_TYPE.HIGHLIGHT);
    }
  };

  return (
    <section key={index} className="paraHistory__rowItem">
      <div onMouseEnter={onMouseEnterTask} onMouseLeave={onMouseLeaveTask}>
        {paraHistoryTaskRow && (
          // <ActivityItem {...activityItemTaskSingle} key={paraHistoryTaskRow._key} className={classNames.itemStyle} />
          <Stack horizontal className={classNames.wrapper}>
            {/* <Stack horizontal> */}
            <StackItem grow disableShrink className={classNames.mainTaskPart}>
              <TaskListItem
                onClickHandler={() => {
                  //console.log("A task was clicked.", paraHistoryTaskRow.key);
                  log.info(limaLogTag.HistParaItemParaTaskRow2, `onClick: clicked ${itemKey}`);
                  history.push(Routes.EditMenuTasks2Route_base + itemKey);
                }}
                item={paraHistoryTaskRow}
                actionEnabled={false}
              />
            </StackItem>
            {isTask_withActaXcopyParasCommentsTasksMessagesAttachements(paraHistoryTaskRow) && (
              <StackItem grow>
                <Stack horizontal verticalFill wrap tokens={numericalSpacingStackTokens}>
                  <StackItem>
                    <LimaInfoIconItem
                      count={paraHistoryTaskRow.linkedMessagesCount}
                      iconName="Message"
                      highlightOnValue={true}
                      hideIfNull={true}
                      tooltip="Messages"
                    />
                  </StackItem>
                  <StackItem title="Attachments">
                    <LimaInfoIconItem
                      count={paraHistoryTaskRow.linkedAttachmentsCount}
                      iconName="Attach"
                      highlightOnValue={true}
                      hideIfNull={true}
                      tooltip="Attachments"
                    />
                  </StackItem>
                  <StackItem title="Tasks">
                    <LimaInfoIconItem
                      count={paraHistoryTaskRow.linkedTasks.length}
                      iconName="TaskLogo"
                      highlightOnValue={true}
                      hideIfNull={true}
                      tooltip="Linked tasks"
                    />
                  </StackItem>
                  <StackItem title="Linked paras">
                    <LimaInfoIconItem
                      count={paraHistoryTaskRow.linkedPara.length}
                      iconName="TextOverflow"
                      highlightOnValue={true}
                      hideIfNull={true}
                      tooltip="Linked paras"
                    />
                  </StackItem>
                  <StackItem title="Linked comments">
                    <LimaInfoIconItem
                      count={paraHistoryTaskRow.linkedComment.length}
                      iconName="FileComment"
                      highlightOnValue={true}
                      hideIfNull={true}
                      tooltip="Linked comments"
                    />
                  </StackItem>
                </Stack>
              </StackItem>
            )}
          </Stack>
        )}
      </div>
    </section>
  );
};
export default HistParaItemParaTaskRow2;
