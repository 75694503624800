import { log } from "missionlog";
import * as React from "react";
import { useMutation, useQuery } from "react-query";
import { useEffect } from "react";
import {
  limaQuery_getUser2UserList,
  limaQuery_putUser2UserLinkinQuery,
} from "../../../../../api/calls/limaUse2UserCalls";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import { LimaLoadingComponnet } from "../../../../../limaComponents/LimaLoadingComponnet";
import { User2User } from "../../../../../types/User2User";
import User2UserLinkItemV4 from "./User2UserLinkItemV4";
import limaStore from "../../../../../store/limaStore";
import { LIMA_TEXTNOTICETYPE, LimaNotice } from "../../../../../limaComponents/LimaNotice";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IUser2UserLinksComponentV4Props {
  type: TOorFROM;
}

export enum TOorFROM {
  TO = "TO",
  FROM = "FROM",
  BOTH = "BOTH",
  COUNT = "COUNT",
}
export enum CALLUSERLINK {
  ASK,
  ACCEPT,
  REJECT,
  CANCEL,
  DELETE,
  OTHER,
}

export type AcceptLinkingObject = {
  user2user: User2User;
  type: CALLUSERLINK;
};

const User2UserLinksComponentV4: React.FunctionComponent<IUser2UserLinksComponentV4Props> = ({
  type,
}: IUser2UserLinksComponentV4Props) => {
  const { isLoading, error, refetch, data } = useGetUser2UserListQuery(type);
  const { mutateAsync } = useAcceptLinkingQuery();

  //----------------------
  // Reactions
  //----------------------

  useEffect(() => {
    void refetch();
  }, []);

  useEffect(() => {
    log.warn(limaLogTag.User2UserLinksComponentV4, "no data yet: ");
  }, [data]);

  //-----------------------
  // Handlers
  //----------------------

  //---------------------
  // Renders
  //--------------------
  if (isLoading === true) {
    return LimaLoadingComponnet(3);
  }

  if (error) {
    log.error(limaLogTag.User2UserLinksComponentV4, "stat loaded error: ", error);
    return <LimaNotice noticeType={LIMA_TEXTNOTICETYPE.ERROR}>Error Getting data </LimaNotice>;
  }

  return (
    <>
      {data && data.users !== undefined ? (
        data.users.map((item: User2User, index: number) => (
          <User2UserLinkItemV4
            key={`u2u_${type}_${item._key ? item._key : index}`}
            item={item}
            type={type}
            onCallBack={mutateAsync}
          />
        ))
      ) : (
        <></>
      )}
    </>
  );
};

export default User2UserLinksComponentV4;

//----------------------
//   REACT QUERY
//----------------------

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetUser2UserListQuery = (type: TOorFROM) => {
  return useQuery(["u2ulist", String(type)], () => limaQuery_getUser2UserList(limaStore.userId, type), {
    enabled: false,
    onSuccess: (data) => {
      log.info(limaLogTag.User2UserLinksComponentV4, "useQuery/onSuccess data,type ", data, type);
    },
  });
};

const useAcceptLinkingQuery = () => {
  return useMutation(limaQuery_putUser2UserLinkinQuery, {
    onMutate: ({ user2user, type }: AcceptLinkingObject) => {
      // A mutation is about to happen!
      log.info(limaLogTag.UserAddEdit, "call mutate ", user2user, type);
      return { id: 1 };
    },
    onSuccess: (data) => {
      log.info(limaLogTag.UserAddEdit, "call mutate ", data);
    },
    onError: (error) => {
      log.error(limaLogTag.UserAddEdit, "call mutate ", error);
    },
  });
};
