import { LIMAUSER_PERMISSIONS_TYPE } from "../limaCommon/limaPermissions";
import { TOorFROM } from "../taskpane/Menu/Users/UserLinking/User2UserLinkComponent";
import { EXPORT_TYPE } from "../types/Acta";
import { LIMA_ITEM_TYPE } from "../types/BASE_Enums";
import { LimaDocTypeENUM } from "../types/docxData";

const baseURL: string = process.env.REACT_APP_SERVER_HOST_START;

const urlServerBase = baseURL;
export const urlServerBaseNoChangePlease = baseURL; //+ "LimactaApp/";

export const urlServerAdvice = process.env.REACT_APP_SERVER_AI_1;

export const urlServerActacontroller = urlServerBase + "Acta/";
export const urlServerParacontroller = urlServerBase + "Para/";
export const urlServerUsercontroller = urlServerBase + "User/";
export const urlServerTaskcontroller = urlServerBase + "Task/";
export const urlServerNotecontroller = urlServerBase + "Note/";
export const urlServerXcopyController = urlServerBase + "Version/";
export const urlServerCompanyController = urlServerBase + "Comp/";
export const urlServerActaHistorycontroller = urlServerBase + "actahistory/";
export const urlServerXcopyHistorycontroller = urlServerBase + "xcopyhist/";

export const urlServerLogging = urlServerBase + "Log/";
export const urlServerStatisticcontroller = urlServerBase + "Stat/";
export const urlServerAnalyzeController = urlServerBase + "Analyze/";
export const urlServerMessageController = urlServerBase + "Message/";
export const urlServerTagsContoller = urlServerBase + "tags/";
export const urlServerAttachmentController = urlServerBase + "att/";

export const urlServerTimersController = urlServerBase + "timers/";

export const enum WORDITEMTYPE {
  acta = "acta",
  para = "para",
  user = "user",
  comm = "comm",
  task = "task",
  note = "note",
  other = "other",
  xcopy = "xcopy",
}

// ---------------------------------------------------------
//          HISTORY
// ---------------------------------------------------------

//get ParaHistory
/**
 * Get para history
 * @param pid
 * @param tstampfrom
 * @param tstampto
 * @returns
 *
 * @version 1.1
 *
 * version 1.1 - authorisation by token
 */
export const urlHistoryGetParaHistory = (pid: string, tstampfrom: string, tstampto: string): string =>
  urlServerParacontroller + `paraHist2/${pid}?tstampfrom=${tstampfrom}&tstampto=${tstampto}`;
//http://localhost:8080/Acta/paraHist2?userid=101&pid=1369327&tstampfrom=2020-01-01+10%3A00%3A00&tstampto=2021-01-01+10%3A00%3A00
//http://localhost:8080/Acta/paraHist2?userid=101&pid=1419586&tstampfrom=2020-01-01+10%3A00%3A00&tstampto=2022-01-01+10%3A00%3A00

//get ParaHistoryComments
// export const urlHistoryGetParaHistoryComments = (userId: string, actaId: string, parId: string): string =>
//   urlServerActacontroller + `getCommentsForPara?userId=${userId}&actaId=${actaId}&parId=${parId}`;
//http://localhost:8080/Acta/getCommentsForPara?actaId=1368209&userId=101&parId=1369327
//http://localhost:8080/Acta/getCommentsForPara?actaId=1368209&userId=101&parId=1419586
/**
 * get comments for paragrf
 * @param paraId
 * @param xcopyId
 * @param timefrom
 * @param timeto
 * @returns
 *
 * @author MS
 *
 * @version 1.1
 *
 * version 1.1 authetication
 */
export const urlHistoryGetParaHistoryComments = (
  // userId: string,
  paraId: string,
  xcopyId: string,
  timefrom: string,
  timeto: string
): string =>
  urlServerActacontroller +
  `getCommentsForPara?paraid=${paraId}&xcopyid=${xcopyId}&timestampfrom=${timefrom}&timestampto=${timeto}`;
// `getCommentsForPara?userid=${userId}&paraid=${paraId}&xcopyid=${xcopyId}&timestampfrom=${timefrom}&timestampto=${timeto}`;

//get ParaHistoryTasks
/**
 * get tasks for paragraph
 * @param parId
 * @param full
 * @param countOnly
 * @returns
 *
 * @author MS
 *
 * @version 1.1
 *
 * VFersion 1.1 ad authentication
 */
export const urlHistoryGetParaHistoryTasks = (
  // userId: string,
  parId: string,
  full?: boolean,
  countOnly?: boolean
): string => {
  let out: string = urlServerTaskcontroller + `?parId=${parId}`;
  if (full !== undefined && full === true) {
    out += `&full=true`;
  }
  if (countOnly !== undefined && countOnly === true) {
    out += `&countonly=true`;
  }
  window.console.log(out);
  return out;
};

//http://localhost:8080/Task/?userId=101&parId=1369327
//http://localhost:8080/Task/?userId=101&parId=1419586

// ---------------------------------------------------------
//          USER
// ---------------------------------------------------------

//getUser
export const urlUserGet = (userId: string): string => urlServerUsercontroller + `get/${userId}`;
// export const urlUserGet = (): string => urlServerUsercontroller + `get`;
//http://localhost:8080/User/get/101

//updateUser
export const urlUserUpdate = (userId: string): string => urlServerUsercontroller + `update/${userId}`;

//addUser
/**
 *
 * @returns
 * @deprecated
 */
export const urlUserAdd = (): string => urlServerUsercontroller + `add/userandvisibility/`;

//get Login to Limacta
export const urlLogin = (userEmail: string): string => urlServerUsercontroller + `getBy/${userEmail}?check=false`;
export const urlGetUserByEmail = (userEmail: string, check?: boolean): string => {
  let outUrl = urlServerUsercontroller + `getBy/${userEmail}`;
  if (check !== undefined) outUrl += `?check=${String(check)}`;

  return outUrl;
};

//get Permissions
export const urlGetUserPerm = (userId: string, companyKey: string, actaId?: string): string => {
  if (actaId == undefined) return urlServerUsercontroller + `getPerm/${userId}/${companyKey}`;
  else return urlServerUsercontroller + `getPerm/${userId}/${companyKey}?actaid=${actaId}`;
};

//update permission
/**
 * update persmissions for user
 * @param type
 * @returns
 *
 * @author MS
 *
 * @version 1.1
 *
 */
export const urlUpdateUserPerm = (userId: string, type: LIMAUSER_PERMISSIONS_TYPE): string => {
  return urlServerUsercontroller + `setPerm/${type}/${userId}`;
};

/**
 * Create new User - POST, update name email - PATHC
 * Data are stored in body
 *
 */
export const urlPostUser = (companyKey: string): string => urlServerUsercontroller + `create/${companyKey}`;

/**
 * Update or delet User - PATHC, DELETE
 * Data are stored in body
 *
 */
export const urlPatchOrDeleteUser = (): string => urlServerUsercontroller + ``;

/**
 * Update user language
 */

export const urlPOSTUpdateLang = (): string => urlServerUsercontroller + `lang`;

//---------------------------------------------------------
//        USER2USER
//---------------------------------------------------------

/**
 * list of asked
 * @param userId is id{key} of user who is askeing
 * @para type is 'FROM'- aka I ask somone or 'TO' - aka Someone ask me
 *
 * @author MS
 *
 * @version 1.1
 *
 * veersion 1.1
 *  removeu user in requests using token
 */
export const urlGetUser2UserJoinList = (type: TOorFROM): string =>
  urlServerUsercontroller + `user2userjoin?type=${type}`;

//Ask user to join
export const urlPOSTUser2UserJoin = (): string => urlServerUsercontroller + `user2userjoin`;

//Accept or Reject join
export const urlPATCHorDELETEUser2UserJoin = (): string => urlServerUsercontroller + `user2userjoin`;

/**
 * Get all users I see and are accepted
 * @param userId
 * @returns
 *
 * @version 1.1 use autheticaiton token
 */
export const urlGetAllLinkedUsers = (): string => urlServerUsercontroller + `users`;

//----------------------------
//           USER SESSIONS/COMPANZ LICENCES
//----------------------------

/**
 * Get active users
 * @param companyKey company used for user
 * @returns
 *
 * /activeUsers/{companykey}
 * return ArrayList<RESToutUserInfo>
 */
export const urlGetActiveUsersList = (companyKey?: string): string => {
  if (companyKey === undefined) {
    return urlServerUsercontroller + `activeUsers/null`;
  }

  return urlServerUsercontroller + `activeUsers/${companyKey}`;
};

/**
 * Login sessions
 * @param companyKey company used for user
 * @returns
 *
 * /loginUser/{companykey}
 * return JSONObject
 */
export const urlPostLoginUser = (companyKey: string): string => urlServerUsercontroller + `loginUser/${companyKey}`;

/**
 * Login sessions update
 * @param companyKey company used for user
 * @returns
 *
 * /loginUser/{companykey}
 * return JSONObject
 */
export const urlPutLoginUser = (companyKey: string): string => urlServerUsercontroller + `loginUser/${companyKey}`;

/**
 * Logout sessions update
 * @param companyId company used for user
 * @returns
 *
 * /logoutUser/{companykey}
 * return JSONObject
 */
export const urlPutLogoutUser = (companyKey: string): string => urlServerUsercontroller + `logoutUser/${companyKey}`;

/**
 * Get users licences for companz
 * @param companyKey company used for user
 * @returns
 *
 * /userLicences/{companykey}
 * return ArrayList<RESToutUserInfo>
 */
export const urlGetCompanyUsersLicences = (companyKey: string): string =>
  urlServerCompanyController + `userLicences/${companyKey}`;

/**
 * Set/update user licence
 * inside body should be whole user2company data
 *
 * /userLicence
 *
 */
export const urlPostUpdateCompanyUserLicence = (companyKey: string): string =>
  urlServerCompanyController + `userLicence/${companyKey}`;

/**
 * Remove user licence
 * inside body should be whole user2company data
 *
 * /userLicence
 *
 */
export const urlPatchRemoveCompanyUserLicence = (companyKey: string): string =>
  urlServerCompanyController + `userLicenceRem/${companyKey}`;

// ---------------------------------------------------------
//          COMPANIES
// ---------------------------------------------------------

/**
 * Get companies list
 * GET /Comp
 *
 */

export const urlGetCompanyList = (): string => urlServerCompanyController;

/**
 * Post new company
 *
 * POST /Comp
 * Data{companyName: string}
 */

export const urlPostNewCompany = (): string => urlServerCompanyController;

/**
 * Post new company
 *
 * PUT /Comp
 * Data{companyName: string}
 */

export const urlPutUpdateCompany = (): string => urlServerCompanyController + "update";

// ---------------------------------------------------------
//          ADVICE
// ---------------------------------------------------------

//get act by paragraph
//const url = "https://limaact.getsandbox.com/act?parid=" + parid;
//https://limaact.getsandbox.com/act2/89/2208
// export const urlGetAdviceActSinglePara = (actId: string, parId: string):string =>
//   `https://limaact.getsandbox.com/actpar/${actId}/${parId}`;

export const urlGetAdviceActSinglePara2 = (actId: string, parId: string): string =>
  urlServerAdvice + `actpar/${encodeURI(actId)}/${parId}`;

export const urlGetAdviceActSinglePara3 = (): string => urlServerAdvice + `actpar2/`;

//get act by paragraph
//const url = "https://limaact.getsandbox.com/act?parid=" + parid;
//https://limaact.getsandbox.com//judge/{act}/{par}
// export const urlGetAdviceJudgeSinglePara = (actId: string, parId: string):string =>
//   `https://limaact.getsandbox.com/judge/${encodeURI(actId)}/${parId}`;

export const urlGetActSigns = (): string => urlServerAdvice + "actsignslist";

export const urlGetAdviceJudgeSinglePara2 = (actId: string, parId: string): string =>
  urlServerAdvice + `judge/${encodeURI(actId)}/${parId}`;

export const urlGetAdviceJudgeAllPara2 = (): string => urlServerAdvice + `judge2/`;

export const urlGetKeywordslist = (): string => urlServerAdvice + "keywordslist";

export const urlGetKeywordsAdvice = (): string => urlServerAdvice + `keywords`;

// ---------------------------------------------------------
//          PARA
// ---------------------------------------------------------

/**
 * Get equal paras
 * getting equal paras by rest code
 * @param parakey
 * @param xcopykey
 * @param actaeqparacount
 * @param xcopyHistoryTimeStamp
 * @returns
 *
 * @author MS
 *
 * @version 1.0
 *
 *
 */
export const urlGetEqualParas = (
  parakey: string,
  xcopykey: string,
  actaeqparacount: boolean,
  xcopyHistoryTimeStamp: string
): string =>
  urlServerParacontroller +
  `equalspara/${parakey}?xcopykey=${xcopykey}&actaeqparacount=${String(
    actaeqparacount
  )}&timestamp=${xcopyHistoryTimeStamp}`;

/**
 * Get equal paras
 * getting equal paras by rest code
 * @param parakey
 * @param xcopykey
 * @param actaeqparacount
 * @param xcopyHistoryTimeStamp
 * @returns SSE
 *
 * @author MS
 *
 * @version 2.0
 * Para/equalspara2/1165366591207878728?userid=101&xcopykey=1165366591207878658&timestamp=2023-10-21T19:12:00.000Z&actaeqparacount=true
 */
export const urlGetEqualParas2SSE = (
  parakey: string,
  xcopykey: string,
  actaeqparacount: boolean,
  xcopyHistoryTimeStamp: string
): string =>
  urlServerParacontroller +
  `equalspara2/${parakey}?xcopykey=${xcopykey}&actaeqparacount=${String(
    actaeqparacount
  )}&timestamp=${xcopyHistoryTimeStamp}`;

export const urlPOSTUpdateSinglePara = (): string => urlServerParacontroller + `updatepara`;

// ---------------------------------------------------------
//          NOTE
// ---------------------------------------------------------

//get Notes4acta
export const urlGetNotes4Acta = (actaId: string, userId: string): string =>
  urlServerNotecontroller + `getNote4Acta?actaId=${actaId}&userId=${userId}`;
//update note
export const urlUpdateNote = (): string => urlServerNotecontroller + "updateNote";
//Add note
export const urlAddNote = (): string => urlServerNotecontroller + "add";
//add attachemnt
export const urlAddAttach2Note = (): string => urlServerNotecontroller + "att/add";
//remove attachemnt
export const urlRemoveAttachFromNote = (): string => urlServerNotecontroller + "att/del";

// ---------------------------------------------------------
//          TASK
// ---------------------------------------------------------

//Task historz
/**
 * Get task history
 * @param taskId
 * @param userId
 * @returns
 *
 * @author MS
 *
 * @version 1.1
 *
 * version 1.1 add authetnication with token
 */
export const urlGetTaskHistory = (taskId: string): string => urlServerTaskcontroller + `history/${taskId}`;

//get Task
// v1: export const urlGetTask = (taskId: string, userId: string):string => urlServerTaskcontroller + `/${taskId}?userId=${userId}`;
/**
 * get task data
 * @param user2taskId
 * @returns
 *
 * @author MS
 * @version 1.1
 *
 * version 1.1 remove userid use authentication token
 */
export const urlGetTask = (user2taskId: string): string => urlServerTaskcontroller + `task/${user2taskId}`;

//store Task
/**
 * Creat new task
 *
 * @returns
 *
 * @author MS
 * @version 1.1
 *
 * version 1.1 authetnication by token
 */
export const urlStoreNewTask = (): string => urlServerTaskcontroller;
//export const urlStoreNewTask = (userId: string): string => urlServerTaskcontroller + `?userId=${userId}`;
//https://app.limacta.com/LimactaApp/Task/?actaId=5916862&userId=101&creator=101&responsible=222365&taskName=Test%20Task&deadline=2020-01-01T155&state=open&description=Helloo

//Delete Task
export const urlDeleteTask = (key: string): string => urlServerTaskcontroller + `${key}`;

//update Task
//export const urlStoreNewTask = ():string => urlServerTaskcontroller;
export const urlUpdateTask = (taskId: string, userId: string): string =>
  urlServerTaskcontroller + `update/${taskId}?userId=${userId}`;

/**
 * Udpate task
 * @param taskId
 * @param userId
 * @returns
 *
 * @author MS
 *
 * @version 1.1 add authentication by token
 */
export const urlUpdateSingleTask2 = (taskId: string): string => urlServerTaskcontroller + `${taskId}`;

export const urlCreateSingleTask2Full = (): string => urlServerTaskcontroller + `createfull`;

//getTasks
//https://app.limacta.com/LimactaApp/Task/?actaId=3535&userId=101
export const urlTasksGetList = (
  actaId: string | null,
  userId: string | null,
  parId: string | null,
  full?: boolean,
  fullCountOnly?: boolean
): string => {
  let url = urlServerTaskcontroller + `?userId=${userId}`;
  if (actaId !== null && actaId !== "") url += `&actaId=${actaId}`;
  if (parId !== null && parId !== "") url += `&parId=${parId}`;
  if (full !== undefined && full === true) url += `&full=${String(full)}`;
  if (fullCountOnly !== undefined && fullCountOnly === true) url += `&fullcountonly=${String(fullCountOnly)}`;
  return url;
};

//history tasks
//let urlCall = urlTasks + "history/" + taskId + "?userId=" + userId;
/**
 * Get task history
 * @param taskId
 * @returns
 *
 * @author MS
 *
 * @version 1.1 ad authentication bz token
 *
 */
export const urlGetTaskHist = (taskId: string): string => urlServerTaskcontroller + `history/${taskId}`;

//simple task slist
//let urlCall = urlTasks + "list?userId=" + userId+"&withlink=true";
/**
 * Get task list for access
 * @param taskId
 * @returns
 *
 * @author MS
 *
 * @version 1.1 ad authentication bz token
 *
 */
export const urlGetSimpleTaskList = (withLink?: boolean): string => {
  let url = urlServerTaskcontroller + `list`;
  if (withLink !== undefined && withLink) url += `?withlink=${String(withLink)}`;
  return url;
};

// ---------------------------------------------------------
//          TASK2TASK
// ---------------------------------------------------------

//Get list of linked tasks
/**
 * Get linked task to task
 * @param taskId
 * @returns
 *
 * @author MS
 *
 * @version 1.1 add authentication by token
 *
 */
export const urlGetTaskLinkedToTaskList = (taskId: string): string =>
  urlServerTaskcontroller + `totask?taskid=${taskId}`;

//Crete linking task to task
/**
 * Create linked task to task
 * @param taskId
 * @returns
 *
 * @author MS
 *
 * @version 1.1 add authentication by token
 *
 */
export const urlPostDeleteTaskLinkedToTask = (taskId: string): string => urlServerTaskcontroller + `totask/${taskId}`;

// ---------------------------------------------------------
//          SEARCH
// ---------------------------------------------------------
//https://app.limacta.com/LimactaApp/Acta/searchFullText4Acta?userid=101&timestampfrom=2020-01-01%2010%3A00&timestampto=2022-01-01%2010%3A00&searchtype=like&searchtext=tes&searchtext=tes
export enum searchTypeENUM {
  FUZZY = "fuzzy",
  STRICT = "strict",
  LIKE = "like",
}

/**
 * Search history for document
 * @param searchType
 * @param searchtStringArray
 * @param timeStamFrom
 * @param timeStampTo
 * @param actaId
 * @returns
 *
 * @author MS
 *
 * @version 1.1
 *
 * version 1.1 use authentication token
 */
export const urlSearch4Text = (
  // userId: string,
  searchType: searchTypeENUM,
  searchtStringArray: string[],
  timeStamFrom?: string,
  timeStampTo?: string,
  actaId?: string
): string => {
  let url = urlServerActacontroller + `searchFullText4Acta?`;
  if (actaId != undefined && actaId != null && actaId !== "") url += `&actaId=${actaId}`;
  if (timeStamFrom != null) url += `&timestampfrom=${timeStamFrom}`;
  if (timeStampTo != null && timeStampTo !== "") url += `&timestampto=${timeStampTo}`;
  switch (searchType) {
    case searchTypeENUM.FUZZY:
      url += `&searchtype=fuzzy`;
      break;
    case searchTypeENUM.STRICT:
      url += `&searchtype=strict`;
      break;
    case searchTypeENUM.LIKE:
      url += `&searchtype=like`;
      break;
    default:
      url += `&searchtype=like`;
      break;
  }

  searchtStringArray.forEach((item: string): string => (url += `&searchtext=${item}`));
  return url;
};
//https://app.limacta.com/LimactaApp/Acta/searchFullText4Acta?userid=101&timestampfrom=2020-01-01%2010%3A00&timestampto=2022-01-01%2010%3A00&actaid=101&searchtype=like&searchtext=tes&searchtext=tes

//get Acta Search Fuzzy | Like
/**
 * Get search for docuemnt
 * @param timeStampFrom
 * @param timeStampTo
 * @param searchText
 * @param searchType
 * @param fuzzy
 * @returns
 *
 * @author MS
 *
 * @version 1.1 add authetnication remove userId
 *
 *
 */
export const urlActaSearchGet = (
  // userId: string,
  timeStampFrom: string,
  timeStampTo: string,
  searchText: string,
  searchType: string,
  fuzzy: string
): string =>
  urlServerActacontroller +
  `search4Acta?searchtype=${searchType}&searchtext=${searchText}&timestampfrom=${timeStampFrom}&timestampto=${timeStampTo}&fuzzy=${fuzzy}`;

// ---------------------------------------------------------
//          VISIBILITY
// ---------------------------------------------------------

//GET request to link
export enum userLinkingState {
  NONE = "NONE",
  OFFERED = "OFFERED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
}

/**
 * get visibility
 *
 * @param userKey
 * @param inorout
 * @returns
 *
 * @author MS
 *
 * @version 1.1 enable using Auth token
 *
 */
export const urlGetVisibilityUsersFiltered = (userKey: string, inorout: string): string =>
  urlServerUsercontroller + `get/visibilityfiltered/${inorout}/${userKey}`;

//UpdateLInking user state
/**
 * Use to add linking user to user?
 *
 * @param visibilityEdge
 * @returns
 *
 * @author MS
 *
 * @version 1.1 enable using auth token
 */
export const urlUpdateVisibility = (visibilityEdge: string): string =>
  urlServerUsercontroller + `update/visibility/${visibilityEdge}`;

//add linking user state\
/**
 * Geqest fro visibility
 *
 * @returns
 *
 * @author MS
 *
 * @version 1.1 using Auth toekn
 *
 */
export const urlAccessAddVisibility = (): string => urlServerUsercontroller + "add/visibility/";
// export const urlAddVisibility = (): string => urlServerUsercontroller + `add/visibility/`;

//setallVisibleForuser
export const linkFullActa2user = (actaId: string): string => urlServerActacontroller + `linkFullActa2user/${actaId}`;

//check if doc import finish
export const urlImportDocControl = (actaId: string): string => urlServerActacontroller + `isImportFinish/${actaId}`;

//getUsersthat I see and can see document
// e.g. get users I can see that are linked to acta
//https://app.limacta.com/LimactaApp/Acta/getUsersForItem?userid=101&itemId=5916862&itemType=acta
export const urlGetUsersForItemISee = (userId: string, itemId: string, itemType: string): string =>
  urlServerActacontroller + `getUsersForItem?userid=${userId}&itemId=${itemId}&itemType=${itemType}`;

//Access add visibility
//params are in body

export const urlAccessSetVisibility2 = (): string => urlServerActacontroller + "setUserForItem";

//Access update visibility
//params are in body
/**
 * universal linking
 *
 * @param visibilityEdge
 * @returns
 *
 * @author MS
 *
 * @version 1.1 enable using auth token
 */
export const urlAccessUpdVisibility = (edgeId: string, actaID?: string): string => {
  let out = urlServerUsercontroller + `update/visibility/`;
  if (edgeId !== undefined || edgeId !== null || edgeId !== "") {
    out += edgeId;
    if (actaID !== undefined || actaID !== null || actaID !== "") out += "/" + actaID;
  }

  return out;
};

/**
 * visibility linking
 *
 * @param visibilityEdge
 * @returns
 *
 * @author MS
 *
 * @version 1.1 enable using auth token
 */
export const urlAccessUpdVisibilityParaAndWords = (edgeId?: string, actaID?: string): string => {
  let out = urlServerUsercontroller + `update/visibilityParaAndWords`;
  if (edgeId !== undefined && edgeId !== null && edgeId !== "") {
    out += "/" + edgeId;
    if (actaID !== undefined && actaID !== null && actaID !== "") out += "/" + actaID;
  }
  return out;
};

/**
 * update visilibit
 * @param edgeId
 * @param actaID
 * @returns
 *
 * @author MS
 *
 * @version 1.1. enalble using Auht code
 */
export const urlAccessUpdVisibilityCommentParaAndWords = (edgeId?: string, actaID?: string): string => {
  let out = urlServerUsercontroller + `update/visibilityCommentParaAndWords`;
  if (edgeId !== undefined && edgeId !== null && edgeId !== "") {
    out += "/" + edgeId;
    if (actaID !== undefined && actaID !== null && actaID !== "") out += "/" + actaID;
  }
  return out;
};

// export const urlAccessAddVisibilityWordsAndActa = (actaID?: string): string => {
//   let out = urlServerUsercontroller + `add/visibilityWordsActa/`;
//   if (actaID !== undefined && actaID !== null && actaID !== "") out += actaID;

//   return out;
// };

//Access get all user for item and type
/**
 * get visibility for user
 * @param itemId
 * @param itemType
 * @returns
 *
 * @author MS
 *
 * @version 1.1 enable using Auht
 */
export const urlAccessGetVisibility = (itemId: string, itemType: string): string =>
  urlServerActacontroller + `getUsersForItem?itemId=${itemId}&itemType=${itemType}`;

//Get all active users for xcopy
export const urlGetUsers4Xcopy = (userId: string, xcopyKey: string): string =>
  urlServerXcopyController + `getUsers/${xcopyKey}?userid=${userId}`;

////----------------
export const urlAccessGetVisibility2 = (itemId: string, itemType: string): string =>
  urlServerUsercontroller + `toitem?itemid=${itemId}&itemtype=${itemType}`;

export const urlAccessPostVisibility2 = (): string => urlServerUsercontroller + `toitem`;

export const urlAccessPutVisibility2 = (): string => urlServerUsercontroller + `toitem`;

// ---------------------------------------------------------
//          ACTA
// ---------------------------------------------------------
//get Acta Doc to Word
export const urlActaInsertDoc = (actaId: string, userId: string): string =>
  urlServerActacontroller + `export2?actaid=${actaId}&userid=${userId}&output=word`;

//get Acta Doc to Word with timeStamo
export const urlActaInsertDocTS = (actaId: string, userId: string, timestamp: string): string =>
  urlServerActacontroller + `export2?actaid=${actaId}&userid=${userId}&output=word&timestamp=${timestamp}`;

//Get history versions of Xcopy
export const urlGetXcopyHistoryList = (
  xcopyid: string,
  order: string,
  userid: string,
  tstampfrom: string,
  tstamptill: string
): string => {
  if (order === undefined || order !== "asc") order = "desc";
  return (
    urlServerXcopyHistorycontroller +
    `xcopyhistory/${xcopyid}/${order}?userid=${userid}&tstampfrom=${tstampfrom}&tstamptill=${tstamptill}`
  );
};

export const urlGetXcopyHistoryListForActa = (
  actaid: string,
  order: string,
  // userid: string,
  tstampfrom: string,
  tstamptill: string
): string => {
  if (order === undefined || order !== "asc") order = "desc";
  return (
    urlServerXcopyHistorycontroller + `actahistory/${actaid}/${order}?tstampfrom=${tstampfrom}&tstamptill=${tstamptill}`
    // `actahistory/${actaid}/${userid}/${order}?tstampfrom=${tstampfrom}&tstamptill=${tstamptill}`
  );
};

export const urlPutXcopyHistItem = (): string => urlServerXcopyHistorycontroller + "xcopyhistory/";

//Get acta historyList
//http://localhost:8080/actahistory/1IDcata
//export const urlGetActaHistList = (actaid: string, userid: string, tstampfrom:string, tstamptill:string):string => urlServerActacontroller + `actHistList/${actaid}?userid=${userid}&tstampfrom=${tstampfrom}&tstamptill=${tstamptill}`;
export const urlGetActaHistList = (
  actaid: string,
  order: string,
  userid: string,
  tstampfrom: string,
  tstamptill: string
): string => {
  if (order === undefined || order !== "asc") order = "desc";
  return (
    urlServerActaHistorycontroller +
    `${actaid}/${order}?userid=${userid}&tstampfrom=${tstampfrom}&tstamptill=${tstamptill}`
  );
};

//Update acta historyList
//PUT http://localhost:8080/actahistory/
//export const urlGetActaHistList = (actaid: string, userid: string, tstampfrom:string, tstamptill:string):string => urlServerActacontroller + `actHistList/${actaid}?userid=${userid}&tstampfrom=${tstampfrom}&tstamptill=${tstamptill}`;
export const urlPutActaHistItem = (): string => urlServerActaHistorycontroller;

//get Acta Doc Appendix
// export const urlGetAppendixInsertDocTS = (
//   actaoriginid: string,
//   actaorigints: string,
//   actafinalid: string,
//   actafinalts: string,
//   userId: string
// ): string =>
//   urlServerActacontroller +
//   `createAppendix?userid=${userId}&actaoriginid=${actaoriginid}&actaorigints=${actaorigints}&actafinalid=${actafinalid}&actafinalts=${actafinalts}`;

/**
 * Call tu get Appendix
 *
 * @param originid
 * @param origintype
 * @param origints
 * @param compareid
 * @param comparetype
 * @param comparets
 * @returns
 *
 * @Author MS
 *
 * @version 1.1
 *
 * Version 1.1
 *  remove user ID due to using keycloack authentication
 */
export const urlGetAppendixInsertDocTS = (
  originid: string,
  origintype: string,
  origints: string,
  compareid: string,
  comparetype: string,
  comparets: string,
  addin: boolean
): string =>
  urlServerActacontroller +
  `createAppendix2?originid=${originid}&origintype=${origintype}&origints=${origints}&compareid=${compareid}&comparetype=${comparetype}&comparets=${comparets}&addin=${String(
    addin
  )}`;

// check docx
export const urlCheckDocx = (): string => urlServerActacontroller + "checkDocx";

// store docx2
export const urlStoreDocx2 = (): string => urlServerActacontroller + "storeDocx2";

// store docx3
export const urlStoreDocx3 = (): string => urlServerActacontroller + "storeDocx3";

/**
 * change ownership of document
 *
 * @param xcopyId
 * @returns
 *
 * @version 1.1 use isted of userID auth token
 */
export const urlUpdateXcopyOwnerShip = (xcopyId: string): string => urlServerXcopyController + `xcopyOwner/${xcopyId}`;

//get Acta All Document's by userId
// export const urlActaListGet = (userId: string, limit?: number): string => {
export const urlActaListGet = (limit?: number): string => {
  // let url = urlServerActacontroller + `listActa?userid=${userId}`;
  // if (limit !== undefined) url += `&limit=${limit}`;
  let url = urlServerActacontroller + `listActa`;
  if (limit !== undefined) url += `?limit=${limit}`;
  return url;
};

//get Acta Document by userId, actaId
export const urlActaDocumentGet = (actaId: string, userId: string): string =>
  urlServerActacontroller + `export2?actaid=${actaId}&userid=${userId}&output=word`;

//check user is owner by userId, actaId
/**
 * Check if is owner of document for import
 *
 * @param actaId
 * @param userId
 * @returns
 *
 * @author MS
 *
 * @version 1.1
 *
 * version 1.1 use authorisation token
 */
export const urlCheckActaOwnerGet = (actaId: string): string =>
  urlServerActacontroller + `checkOwnerImport?actaid=${actaId}`;
export const urlCheckXcopyUserGet = (xcopyId: string): string =>
  urlServerActacontroller + `checkOwnerImport?xcopyid=${xcopyId}`;

/**
 * Check new document anem
 * @param actaname
 * @param userId
 * @returns
 *
 * @author MS
 * @version 1.1
 *
 * version 1.1 use userid as authorisation token
 */
export const checkActanameExists = (actaname: string): string =>
  urlServerActacontroller + `checkActanameExists?name=${actaname}`;

//localhost:8082/Acta/export2?actaid=1023123633738481664&userid=979&timestamp=null&output=word&xcopy=1023123633738481666&justdocx=true&exporttype=PDF&addhelpertext=false
export const urlGetAsPDF = (
  actaid: string,
  userId: string,
  timestamp: string,
  output: string,
  xcopy: string,
  justdocx: boolean,
  exporttype: EXPORT_TYPE,
  addhelpertext: boolean
): string =>
  urlServerActacontroller +
  `export2?actaid=${actaid}&userid=${userId}&timestamp=${timestamp}&output=${output}&xcopy=${xcopy}&justdocx=${String(
    justdocx
  )}&exporttype=${exporttype}&addhelpertext=${String(addhelpertext)}`;

// --------------------------------------------------------
//  USER TO ACTA
// --------------------------------------------------------

// set favorite POST /favset/{actakey} param setfavorite: true/false

export const urlActaPostSetfavorite = (actaid: string): string => urlServerActacontroller + `favset/${actaid}`;

// ---------------------------------------------------------
//          SERVERs VERSIONs
// ---------------------------------------------------------

//get ActaVersion
export const urlLimaBEVersion = (): string => urlServerActacontroller;
export const urlLimaAIVersion = (): string => urlServerAdvice;

// ---------------------------------------------------------
//          OTHER
// ---------------------------------------------------------

//add linking user state
export const urlFELogging = (): string => urlServerLogging + `log2server`;

//-------------------------
//    TASKS VERSIONS
//-------------------------

//get tasks with texts linked to para
export const urlGetTasksAndWordsForPara = (paraId: string, userId: string, actaId: string): string =>
  urlServerTaskcontroller + `getParasWords/${paraId}?userId=${userId}&actaId=${actaId}`;

/**
 * URL to get task Xcopy of paragraph
 * @param paraId
 * @param userId
 * @param actaId
 * @param xcopyId
 * @returns
 */
export const urlGetTaskXcopyParaForInsert = (paraId: string, userId: string, actaId: string, xcopyId: string): string =>
  urlServerTaskcontroller + `getParaVersionCompared/${paraId}?userId=${userId}&actaId=${actaId}&xcopyId=${xcopyId}`;

//---------------------------
//   XCOPY
//----------------------------

//Create new version
//export const urlCreateVersionOnActa = (actaId: string):string => urlServerVersionController + `versionCreate/${actaId}`;
/**
 * Create new  Xcopy form acta
 * @param actaId
 * @returns
 *
 * @author MS
 *
 * @version 1.1
 *
 * version 1.1 remove user using authentication
 */
export const urlCreateXcopyOnActa = (actaId: string): string => urlServerXcopyController + `${actaId}`;

/**
 * Create version
 *
 * @param actaId
 * @param xcopyId
 * @param userId
 * @returns
 *
 * @version 1.1
 *
 * version 1.1 remoe user due to auth
 *
 * @depreciated
 */
export const urlCreateXcopyOnXcopy = (actaId: string, xcopyId: string): string =>
  urlServerXcopyController + `xcopy/${actaId}/${xcopyId}`;

//opend version
//(urlServer + `export?actaid=${actaid}&userid=${userid}&versionid=${version}&output=word`);
// export const urlActaInsertDocVersion = (actaId: string, versionId: string, userId: string): string =>
//   urlServerActacontroller + `export2?actaid=${actaId}&userid=${userId}&versionid=${versionId}&output=word`;

export const urlActaInsertDocVersion = (
  actaId: string,
  xcopyId: string,
  userId: string,
  timestamp: string,
  runLimaOnOpenNewWindow: boolean
): string => {
  let url = urlServerActacontroller + `export2?actaid=${actaId}&userid=${userId}&timestamp=${timestamp}&output=word`;
  if (xcopyId !== undefined && xcopyId !== null) url += `&xcopy=${xcopyId}`;
  if (runLimaOnOpenNewWindow !== undefined && runLimaOnOpenNewWindow !== null)
    url += `&addin=${String(runLimaOnOpenNewWindow)}`;
  return url;
};

//Get all Xcopy for acta
// export const getAllCopy4Acta = (actaId: string, userId: string): string =>
//   urlServerXcopyController + `get4Acta/${actaId}?userid=${userId}`;
export const getAllCopy4Acta = (actaId: string): string => urlServerXcopyController + `get4Acta/${actaId}`;

//Get all active users for xcopy
/**
 * Get copies for user linking access
 *
 * @param actaOwnerOnly
 * @param actaNames
 * @returns
 *
 * @author MS
 *
 * @version 1.1 add authetnicarti by token
 */
export const getAllCopy4User = (actaOwnerOnly?: boolean, actaNames?: boolean): string => {
  let url = urlServerXcopyController + `getCopies?`;
  if (actaOwnerOnly !== undefined && actaOwnerOnly !== null) url += `&actaowneronly=${String(actaOwnerOnly)}`;
  if (actaNames !== undefined && actaNames !== null) url += `&actanames=${String(actaNames)}`;

  return url;
};

//---------------------------
//   Statistic
//----------------------------

//get basic statistic 4 acta
// export const urlGetActaBasicStatistic = (actaId: string, userId: string): string =>
//   urlServerStatisticcontroller + `getactabasicstat?acatid=${actaId}&userid=${userId}`;
export const urlGetActaBasicStatistic = (actaId: string): string =>
  urlServerStatisticcontroller + `getactabasicstat?acatid=${actaId}`;

/**
 *
 * @param foruserid
 * @param startdate
 * @param start
 * @param offset
 * @returns
 *
 * @Version 1.1
 *
 * @author MS
 *
 * version 1.1 remove user duw to keycalock
 *
 */
//get list statistic 4 user start and offset
export const urlGetUserListStatistic = (
  // userid: string,
  foruserid: string,
  startdate: string,
  start: number,
  offset: number
): string =>
  urlServerStatisticcontroller +
  `getuserliststat?foruserid=${foruserid}&startdate=${startdate}&start=${start}&offset=${offset}`;

//get activity statistic 4 user start and offset
/**
 *
 * @param userid
 * @param foruserid
 * @param monthsstart calculated from now
 * @param monthsoffset calculated from now
 * @returns
 *
 * @Version 1.1
 *
 * @author MS
 *
 * version 1.1 remove user duw to keycalock
 */
export const getuseractivitystatoffset = (
  // userid: string,
  foruserid: string,
  monthsstart: number,
  monthsoffset: number
): string =>
  urlServerStatisticcontroller +
  `getuseractivitystatoffset?foruserid=${foruserid}&monthsstart=${monthsstart}&offset=${monthsoffset}`;

//get activity statistic 4 user montdate
export const getuseractivitystatdate = (userid: string, foruserid: string, date: string): string =>
  urlServerStatisticcontroller + `getuseractivitystatdate?userid=${userid}&foruserid=${foruserid}&date=${date}`;

//-----------------------------
//   Analyze service
//-----------------------------

// export const urlGetActaBasicCheck = (actaId: string, userId: string): string =>
// urlServerAnalyzeController + `check?actaid=${actaId}&userid=${userId}`;
// export const urlGetActaBasicCheck = (baseId: string, baseType: LimaDocTypeENUM, userId: string): string =>
//   urlServerAnalyzeController + `check?baseid=${baseId}&basetype=${baseType.toUpperCase()}&userid=${userId}`;
/**
 * Check document
 *
 * @param baseId
 * @param baseType
 * @param fullsent
 * @param subjmis
 * @param objmis
 * @param commits
 * @param timeterms
 * @returns
 *
 * @author MS
 *
 * @version 1.1 add authetnication
 *
 */
export const urlGetActaBasicCheck = (
  baseId: string,
  baseType: LimaDocTypeENUM,
  // userId: string,
  fullsent?: boolean,
  subjmis?: boolean,
  objmis?: boolean,
  commits?: boolean,
  timeterms?: boolean
): string => {
  let call = urlServerAnalyzeController + `check?baseid=${baseId}&basetype=${baseType.toUpperCase()}`;
  if (fullsent !== undefined) {
    call += `&fullsent=${String(fullsent)}`;
  }
  if (subjmis !== undefined) {
    call += `&subjmis=${String(subjmis)}`;
  }
  if (objmis !== undefined) {
    call += `&objmis=${String(objmis)}`;
  }
  if (commits !== undefined) {
    call += `&commits=${String(commits)}`;
  }
  if (timeterms !== undefined) {
    call += `&timeterms=${String(timeterms)}`;
  }

  return call;
};

//-----------------------------
//  Message service
//-----------------------------

/**
 * Get list of messages on task
 * @param linkedId
 * @param linkedType
 * @returns
 *
 * @author MS
 *
 * @version 1.1
 *
 * version 1.1 replace userId wit authentication
 */
export const urlGetMessages = (linkedId: string, linkedType: LIMA_ITEM_TYPE): string =>
  urlServerMessageController + `messages?linkedid=${linkedId}&linkedtype=${String(linkedType).toUpperCase()}`;

/**
 * Create new message on task
 * @returns
 *
 * @author MS
 *
 * @version 1.1
 *
 * version 1.1 replace userId with autheticartion
 */
export const urlStoreMessages = (): string => urlServerMessageController + ``;

/**
 * Get message count
 * @param linkedId
 * @param linkedType
 * @returns
 *
 * @author MS
 *
 * @version 1.1
 *
 * version 1.1 replace userID with auythentication
 */
export const urlGetMessagesCount = (linkedId: string, linkedType: LIMA_ITEM_TYPE): string =>
  urlServerMessageController + `messagescount?linkedid=${linkedId}&linkedtype=${String(linkedType).toUpperCase()}`;

//-----------------------------
//  Attachement service
//-----------------------------

export const urlStoreAttachement = (): string => urlServerAttachmentController + ``;

export const urlGetAttachement = (attKey: string): string => urlServerAttachmentController + `${attKey}`;

export const urlGetAttachementsList = (attKey: string[]): string => {
  let url = urlServerAttachmentController + `atts?`;
  attKey.forEach((item: string): string => (url += `&ids=${item}`));
  return url;
};

//---------------------------------
// TAGS
//---------------------------------

// export const urlTags = (): string =>
// urlServerTagsContoller + `?linkedid=${linkedId}&linkedtype=${String(linkedType).toUpperCase()}`;

export const urlGetAllTags = (): string => urlServerTagsContoller;

export const urlPostTag = (): string => urlServerTagsContoller;

export const urlPatchTagsOnXcopy = (xcopyKey: string): string => urlServerTagsContoller + `xcopy/${xcopyKey}`;

//----------------------------
// TIMERS
//----------------------------

export const urlPostTimers = (): string => urlServerTimersController;

export const urlGetAllTimers = (): string => urlServerTimersController;

export const urlGetAllTimersBetween = (fromTime: string, toTime: string): string =>
  urlServerTimersController + `?from=${fromTime}${toTime ? `&to=${toTime}` : ""}`;
