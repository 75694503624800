import React = require("react");
import { FunctionComponent } from "react";
import { FontIcon, mergeStyleSets, Text } from "@fluentui/react";

type LimaInfoIconItemProps = {
  count: number;
  iconName: string;
  hideIfNull?: boolean;
  highlightOnValue?: boolean;
  tooltip?: string;
};

const classNames = mergeStyleSets({
  itemBasic: {
    color: "darkgray",
  },
  itemHighlighted: {
    color: "dodgerblue",
  },
});

export const LimaInfoIconItem: FunctionComponent<LimaInfoIconItemProps> = ({
  count,
  iconName,
  hideIfNull,
  highlightOnValue,
  tooltip,
}: LimaInfoIconItemProps) => {
  // let classUsed = classNames.itemBasic;

  // useEffect(() => {
  //   if (highlightOnValue && highlightOnValue === true && count && count > 0) {
  //     window.console.log("used class -> highlightOnValue & count is bigger than 0", count);
  //     classUsed = classNames.itemHighlighted;
  //   }
  //   window.console.log("used class", classUsed);
  // }, [, count]);

  if (hideIfNull === undefined && hideIfNull === true && count && count === 0) return <></>;

  const getClass = () => {
    if (highlightOnValue && highlightOnValue === true && count && count > 0) return classNames.itemHighlighted;
    return classNames.itemBasic;
  };
  return (
    <div title={tooltip}>
      <Text variant="small" className={getClass()}>
        {count}
      </Text>
      <FontIcon aria-label={iconName} iconName={iconName} className={getClass()} />
    </div>
  );
};
