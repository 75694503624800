import * as React from "react";
import { FunctionComponent, useEffect, useMemo, useState } from "react";

import { FocusZone, FocusZoneDirection } from "office-ui-fabric-react/lib/FocusZone";
import { List } from "office-ui-fabric-react/lib/List";
import { ITheme, mergeStyleSets, getTheme } from "office-ui-fabric-react/lib/Styling";
import { ScrollablePane } from "office-ui-fabric-react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { log } from "missionlog";
import { limaLogTag } from "../../../limaCommon/limaLog";
import limaStore from "../../../store/limaStore";
import { TASKSTATE } from "../../../types/Task";
import { TaskListV4Filter } from "./TaskListV4Filter";
import { User2Task_withActaXcopyParasCommentsTasksMessagesAttachements } from "../../../types/User2Task_withActaXcopy";
import { LimaLoadingComponnet } from "../../../limaComponents/LimaLoadingComponnet";
import { LimaHideableSection2 } from "../../../limaComponents/LimaHideableSection2";
import { TaskItemV4 } from "./TaskItemV4";
import TaskListItemV4, { E_TASKPARAMS } from "./components/TaskListItemV4";
import { updateTaskData } from "../../../api/calls/updateTaskData";
import {
  convert_Task_withActaXcopyParasCommentsTasksMessagesAttachements2Task_withActaXcopy,
  Task_withActaXcopy,
  Task_withActaXcopy_getNull,
} from "../../../types/Task_withActaXcopy";
import limaTasksStoreV4, { TASK_LINKED2ME_ENUM } from "../../../store/limaTasksStoreV4";
import { LimaSearchTextList } from "../../../limaComponents/LimaSearchTextList";
import { LimaEditItemBaseV4 } from "./LimaEditItemBaseV4";
import { IIconProps } from "@fluentui/react";
import { SelectedInWord } from "../../../types/wordObjects";
import { WORDITEMTYPE } from "../../../api/apilinks";
import { highlightTaskLinks3, HIGHLIGHT_TYPE } from "../../../limaCommon/limaWordInteract";
import { Toggle } from "@fluentui/react";
// import { observer } from "mobx-react-lite";

export interface TaskListV4Pros {
  onClickHandlerCallBack?: (task: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements) => void;
  onClickStateCallBack?: (task: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements) => void;
  onMouseEnterTask?: (taskParaIds: string[]) => void;
  onMouseLeaveTask?: (taskParaIds: string[]) => void;
  onStartFilterOnlyMy?: boolean;
  onStartFilterClosedTasks?: boolean;
  onStartFilterCountTasks?: number | null;
  showFilters?: boolean;
  showSearch?: boolean;
  defaultFilters?: TaskListFilterItem[];
  includeHidable: boolean;
  definedTasksList?: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements[];
  addEnabled?: boolean;
  hideTaskParams?: E_TASKPARAMS[];
  enableHighlight?: boolean;
  highlighTaskMouseOverAlwaysOn?: boolean;
}

export interface TaskListFilterItem {
  taskItemFilterIdent: TASKFILTERITEM_ENUM;
  taksItemFilterValue: TASK_LINKED2ME_ENUM | boolean | TASKSTATE | number | null; //null in case that is not as..
}

export enum TASKFILTERITEM_ENUM {
  FILTER_CREATOR = "FILTER_CREATOR",
  FILTER_LINKTOACTIVEDOC = "FILTER_LINKTOACTIVEDOC",
  FILTER_TASKSTATE = "FILTER_TASKSTATE",
  FILTER_TASKCOUNT = "FILTER_TASKCOUNT",
}

const theme: ITheme = getTheme();
const { palette, fonts } = theme;

const classNames = mergeStyleSets({
  itemIndex: {
    fontSize: fonts.small.fontSize,
    color: palette.neutralTertiary,
    marginBottom: 10,
  },
  itemStyle2: {
    selectors: {
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  scrollPane: {
    position: "relative",
    selectors: {
      " .ms-ScrollablePane--contentContainer": {
        position: "relative",
      },
    },
  },
});

interface IListTasksProps {
  inner_taskList: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements[];
  inner_taskFilters: TaskListFilterItem[];
  inner_showFilters: boolean;
  // textFilter: string; inner_taskList, inner_taskFilters, inner_props.showFilters
}

const detailIcon: IIconProps = { iconName: "ChevronRightMed" };
const removeIcon: IIconProps = { iconName: "Clear" };

const TasksListV4: FunctionComponent<TaskListV4Pros> = (props: TaskListV4Pros) => {
  const { t } = useTranslation();
  // const [showAdd, setShowAdd] = useState<boolean>();
  // const [, updateState] = useState<{}>();
  const [showHighLight, setShowHighLight] = useState<boolean>(false);
  const [taskFilters, setTaskFilters] = useState<TaskListFilterItem[]>([]);
  const [listedItemsCount, setListedItemsCount] = useState<number>(0);
  const [listedTasks, setListedTasks] = useState<User2Task_withActaXcopyParasCommentsTasksMessagesAttachements[]>([]);
  const [storeEditEnabled, setStoreEditEnabled] = useState<boolean>(false);
  const [storeEditSaveLbl, setStoreEditSaveLbl] = useState<string>("");
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [showDetailTask, setShowDetailTask] =
    useState<User2Task_withActaXcopyParasCommentsTasksMessagesAttachements | null>(null);
  const [textFilter, setTextFilter] = useState<string>("");
  const [resultCountText, setResultCountText] = useState<string>("");
  const [onlyActiveFiltered, setOnlyActiveFiltered] = useState<boolean>(false);

  const enableEditStore = useMemo(() => storeEditEnabled, [storeEditEnabled]);

  const { isLoading, isSuccess, isRefetching, error, refetch } = useQuery(["taskslist"], () => fetchTasks(), {
    enabled: false,
    onSuccess: (data) => {
      log.info(limaLogTag.TasksList, "useQuery get tasks ", data);
      // tasks are stored into limaTaskStoreV4
      inner_updateLinkedTask();
    },
  });

  //--------------
  // Reactions
  //--------------
  useEffect(() => {
    log.info(limaLogTag.TasksList, `useEffect: on mount deffilters`, props.defaultFilters);
    if (props.definedTasksList === undefined) {
      void refetch().then(() => {
        log.info(limaLogTag.TasksList, `useEffect after refetch: `);
        // inner_updateLinkedTask();
      });
    } else {
      log.info(limaLogTag.TasksList, `UEF using predefined tasklist`, props.definedTasksList);
      inner_updateLinkedTask();
    }
    if (props.defaultFilters !== undefined) checkTaskFilterForActiveDoc(props.defaultFilters);
  }, []);

  useEffect(() => {
    log.info(limaLogTag.TasksList, `UEF: reaction on default FILTERS changes`, taskFilters, props.defaultFilters);
    //setFilters(props.defaultFilters);
    const setupFilters: TaskListFilterItem[] = [];

    if (props.defaultFilters !== undefined) {
      props.defaultFilters.forEach((item: TaskListFilterItem) => {
        setupFilters.push({
          taskItemFilterIdent: item.taskItemFilterIdent,
          taksItemFilterValue: item.taksItemFilterValue,
        });
      });
    }
    //------------------------------------------------------------------------------------------------------------------
    setTaskFilters([...setupFilters]);
  }, [props.defaultFilters]);

  useEffect(() => {
    log.info(limaLogTag.TasksList, `UEF: reaction on changes`, taskFilters);
    inner_updateLinkedTask();
  }, [taskFilters, isSuccess, textFilter]); //textFilter

  useEffect(() => {
    log.info(limaLogTag.TasksList, `saveLbl memo: on render `, showDetailTask === null, showDetailTask);
    if (showDetailTask === null) {
      //------------------------------------------------------------------------------------------------------------------
      setStoreEditSaveLbl(t("tptasks:taskedit.taskitemV4.saveTaskBtn"));
    } else {
      //------------------------------------------------------------------------------------------------------------------
      setStoreEditSaveLbl(t("tptasks:taskedit.taskitemV4.updateTaskBtn"));
    }
  }, [showDetail]);

  const inner_updateLinkedTask = () => {
    let filterdTasks: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements[] = [];
    if (props.definedTasksList !== undefined) {
      filterdTasks = props.definedTasksList;
    } else {
      filterdTasks = limaTasksStoreV4.getFilterdTasks(taskFilters, textFilter);
    }
    log.info(limaLogTag.TasksList, `inner_updateLinkedTask `, filterdTasks, taskFilters, textFilter);
    window.console.log("I HAVE FILTERD TASKS", filterdTasks);
    //------------------------------------------------------------------------------------------------------------------
    if (filterdTasks !== null) {
      setListedItemsCount(filterdTasks.length);
      setListedTasks([...filterdTasks]);
      setResultCountText(
        t("tptasks:taskList.filterTaskNameCountsLbl", {
          filtercount: String(filterdTasks.length),
          maxcount: String(limaTasksStoreV4.fullTasksListCount),
        })
      );
    } else {
      setListedItemsCount(0);
      setListedTasks([]);
      setResultCountText(
        t("tptasks:taskList.filterTaskNameCountsLbl", {
          filtercount: "-",
          maxcount: String(limaTasksStoreV4.fullTasksListCount),
        })
      );
    }
    // setResultCountText(`(${filterdTasks.length}/${limaTasksStoreV4.fullTasksListCount})`);

    // (${} of ${} shown)`;
    // updateState({});
  };
  //--------------
  // Handlers
  //--------------

  const onAddTaskIconHandler = () => {
    //setShowAdd(!showAdd);
    //------------------------------------------------------------------------------------------------------------------ON CLICK
    setShowDetailTask(null);
    setShowDetail(true);
  };

  const onFilterChangeHandler = (newfilters: TaskListFilterItem[]) => {
    log.info(limaLogTag.TasksList, `onFilterChangeHandler: filters `, newfilters);
    //------------------------------------------------------------------------------------------------------------------ON CLICK
    checkTaskFilterForActiveDoc(newfilters);
    setTaskFilters(newfilters);
  };

  const checkTaskFilterForActiveDoc = (newfilters: TaskListFilterItem[]) => {
    const onlyActiveDocFilter = newfilters.find(
      (item: TaskListFilterItem) => item.taskItemFilterIdent === TASKFILTERITEM_ENUM.FILTER_LINKTOACTIVEDOC
    );
    if (onlyActiveDocFilter !== undefined) setOnlyActiveFiltered(Boolean(onlyActiveDocFilter.taksItemFilterValue));
    else {
      if (onlyActiveFiltered !== false) setOnlyActiveFiltered(false);
    }
    setTaskFilters(newfilters);
  };

  const handleTextFilter = (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    //------------------------------------------------------------------------------------------------------------------ON CLICK
    setTextFilter(newValue);
  };

  const onRemoveLinking = async (taskId: string) => {
    log.info(limaLogTag.TasksList, `onRemove: removeing taskID:'${taskId}'`);
    await limaTasksStoreV4.removeTaskFromList(taskId).then(() => {
      //------------------------------------------------------------------------------------------------------------------ON CLICK
      inner_updateLinkedTask();
    });
  };

  const onStoreTask = () => {
    log.info(limaLogTag.TasksList, "onStore: onDetialSaveUpdateCallBack ", taskFilters);
    // window.console.log("ADDDDDDDDDDDDDDDDDDDDDDD");
    void updateTaskData(limaTasksStoreV4.editTask_withActaXcopy, limaStore.userId).then((task: Task_withActaXcopy) => {
      // window.console.log("I HAVE TASK UPDATED", task, filters);
      log.info(limaLogTag.TasksList, "onStore: updated Task", task, taskFilters);
      if (
        limaTasksStoreV4.editTask_withActaXcopy._key === null ||
        limaTasksStoreV4.editTask_withActaXcopy._key === ""
      ) {
        window.console.log("I HAVE TASK UPDATED reload DB", taskFilters);
        //TODO: In future simple way - it will be hard
        void limaTasksStoreV4.checkLastRead().then(() => {
          // void limaTasksStoreV4.getTasksListFromDB().then(() => {
          window.console.log("LOAD FINISH - DO IT BETTER like push new", taskFilters);
          inner_updateLinkedTask();
        });
      } else {
        window.console.log("I HAVE TASK UPDATED single task", taskFilters);
        limaTasksStoreV4.updateSingleTask_withTask_withActaXcopy(task);
        inner_updateLinkedTask();
      }
    });
  };

  const onStateClick = (task: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements) => {
    log.info(limaLogTag.TasksList, `onStateClikc: switchState:'${task._key}'`);
    if (task.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskState === TASKSTATE.CLOSE) {
      //TODO: limaTasksStore.updateSingleTask({ ...task, taskState: TASKSTATE.OPEN });
      //TODO: Store
    } else {
      //TODO: limaTasksStore.updateSingleTask({ ...task, taskState: TASKSTATE.CLOSE });
      //TODO: Store
    }

    if (props.onClickStateCallBack !== undefined) props.onClickStateCallBack(task);
  };

  const onMouseEnterTask = (taskParaIds: string[]) => {
    if (showHighLight || props.highlighTaskMouseOverAlwaysOn) {
      const words: SelectedInWord[] = taskParaIds.map((item: string): SelectedInWord => {
        return { type: WORDITEMTYPE.para, id: item };
      });
      // log.info(limaLogTag.HistParaItemParaTaskRow2, `onMouseEnterTask ${itemKey}`, paraHistoryTaskRow, words);
      void highlightTaskLinks3(words, true, HIGHLIGHT_TYPE.HIGHLIGHT);
    }
    if (props.onMouseEnterTask !== undefined) props.onMouseEnterTask(taskParaIds);
  };

  const onMouseLeaveTask = (taskParaIds: string[]) => {
    if (showHighLight || props.highlighTaskMouseOverAlwaysOn) {
      const words: SelectedInWord[] = taskParaIds.map((item: string): SelectedInWord => {
        return { type: WORDITEMTYPE.para, id: item };
      });
      // log.info(limaLogTag.HistParaItemParaTaskRow2, `onMouseLeaveTask ${itemKey}`, paraHistoryTaskRow, words);
      void highlightTaskLinks3(words, false, HIGHLIGHT_TYPE.HIGHLIGHT);
    }
    if (props.onMouseLeaveTask !== undefined) props.onMouseLeaveTask(taskParaIds);
  };

  /**
   * RENDERING SINGLE ITEM IN LIST
   * @param item
   * @returns
   */
  const onRenderCell = (item: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements): JSX.Element => {
    log.info(limaLogTag.TasksList, `onRenderCell: on render `, item);
    return (
      <TaskListItemV4
        key={`tli_${item._key}`}
        actionsList={[
          {
            action_ClickClb: () => onRemoveLinking(item.task_withActaXcopyParasCommentsTasksMessagesAttachements._key),
            action_icon: removeIcon,
            disabled: false,
            label: "Remove from list",
            tooltip: "Remove item from your task list",
          },
          {
            action_ClickClb: () => {
              log.info(limaLogTag.TasksList, `TaskListItem clicked `, item);
              //------------------------------------------------------------------------------------------------------------------
              setShowDetailTask(item);
              setShowDetail(true);
              if (props.onClickHandlerCallBack !== undefined) props.onClickHandlerCallBack(item);
            },
            action_icon: detailIcon,
            disabled: false,
            label: "Show detail",
            tooltip: "Show task detail",
          },
        ]}
        // onRemoveAction={() => onRemoveLinking(item.task_withActaXcopyParasCommentsTasksMessagesAttachements._key)}
        onStateClick={() => {
          onStateClick(item);
        }}
        // onDetailClickHandler={() => {
        //   log.info(limaLogTag.TasksList, `TaskListItem clicked `, item);
        //   //------------------------------------------------------------------------------------------------------------------
        //   setShowDetailTask(item);
        //   setShowDetail(true);
        //   if (onClickHandlerCallBack !== undefined) onClickHandlerCallBack(item);
        // }}
        // actionIcon={RemoveLinkIcon}
        item={item.task_withActaXcopyParasCommentsTasksMessagesAttachements}
        actionEnabled={true}
        hideTaskParams={props.hideTaskParams === undefined ? [] : props.hideTaskParams}
        onMouseEnterTask={onMouseEnterTask}
        onMouseLeaveTask={onMouseLeaveTask}
      />
    );
  };

  if (isLoading || isRefetching) {
    if (props.includeHidable === true) {
      return LimaLoadingComponnet(2, "TASKS", true);
    } else {
      return LimaLoadingComponnet(3, "TASKS");
    }
  }

  if (error) {
    log.error(limaLogTag.TasksList, "stat loaded error: ", error);
    return <>Error Getting data </>;
  }

  const getFilters = () => {
    return taskFilters;
  };

  const TaskListV4List = ({ inner_taskList, inner_taskFilters, inner_showFilters }: IListTasksProps) => {
    log.info(limaLogTag.TasksList, `TaskListV4List: redraw tasklist`);
    return (
      <>
        {inner_showFilters === undefined || inner_showFilters === true ? (
          <TaskListV4Filter filter={inner_taskFilters} onFilterUpdate={onFilterChangeHandler} />
        ) : (
          <></>
        )}
        <FocusZone direction={FocusZoneDirection.vertical}>
          <ScrollablePane className={classNames.scrollPane}>
            <List items={inner_taskList} onRenderCell={onRenderCell} />
          </ScrollablePane>
        </FocusZone>
      </>
    );
  };

  return (
    <>
      {props.includeHidable === false ? (
        <>
          {(props.showSearch === undefined || props.showFilters === true) && (
            <LimaSearchTextList
              // minLength={3}
              limaLabel={`${t("tptasks:taskList.filterTaskNameLbl")}  ${resultCountText}`}
              onChange={handleTextFilter}
              value={textFilter}
            />
          )}
          <TaskListV4List
            inner_taskList={listedTasks}
            inner_taskFilters={taskFilters}
            inner_showFilters={props.showFilters}
          />
          {/* {taskListV4List(listedTasks)} */}
        </>
      ) : (
        <LimaHideableSection2
          name={t("tptasks:taskList.tasknotesLbl")}
          headerCount={listedItemsCount && String(listedItemsCount)}
          addCallback={onAddTaskIconHandler}
          filters={taskFilters}
          addEnabled={props.addEnabled !== undefined ? props.addEnabled : true}
          editPanelName={t("tptasks:taskList.taskItemDetail.panelNamelbl")}
          passShowHideToContent={false}
          onDetialSaveUpdateCallBack={() => {
            log.info(limaLogTag.TasksList, "onDetialSaveUpdateCallBack: ", taskFilters, getFilters());
            onStoreTask();
          }}
          editDetail={
            <TaskItemV4
              task={
                showDetailTask
                  ? convert_Task_withActaXcopyParasCommentsTasksMessagesAttachements2Task_withActaXcopy(
                      showDetailTask?.task_withActaXcopyParasCommentsTasksMessagesAttachements
                    )
                  : Task_withActaXcopy_getNull()
              }
              enableStore={setStoreEditEnabled}
            />
          }
          editDetailOpen={showDetail}
          editDetailEnableStore={enableEditStore}
          editDetailSaveLbl={storeEditSaveLbl}
          editDetailCancelLbl={t("tptasks:taskedit.taskitemV4.cancelTaskBtn")}
          onDetailBackCallBack={() => {
            log.info(limaLogTag.TasksList, "onDetailCallback: onDetailBackCallBack ", taskFilters, getFilters());
            setShowDetail(false);
            // updateTaskList();
          }}
        >
          <>
            {(props.enableHighlight === undefined || props.enableHighlight === true) && (
              <Toggle
                label={t("tpshow:history.tasks.highlightLbl")}
                inlineLabel
                checked={showHighLight}
                disabled={!onlyActiveFiltered}
                onClick={() => {
                  setShowHighLight(!showHighLight);
                }}
                style={{ fontSize: 14, fontWeight: 400 }}
              />
            )}
            {(props.showSearch === undefined || props.showSearch === true) && (
              <LimaSearchTextList
                // minLength={3}
                limaLabel={`${t("tptasks:taskList.filterTaskNameLbl")}  ${resultCountText}`}
                onChange={handleTextFilter}
                value={textFilter}
              />
            )}
            <TaskListV4List
              inner_taskList={listedTasks}
              inner_taskFilters={taskFilters}
              inner_showFilters={props.showFilters}
            />
          </>

          {/* <>{TaskListV4List}</> */}
        </LimaHideableSection2>
      )}
      {props.includeHidable === false && (
        <LimaEditItemBaseV4
          saveLbl={storeEditSaveLbl}
          detail={
            <TaskItemV4
              task={
                showDetailTask
                  ? convert_Task_withActaXcopyParasCommentsTasksMessagesAttachements2Task_withActaXcopy(
                      showDetailTask?.task_withActaXcopyParasCommentsTasksMessagesAttachements
                    )
                  : Task_withActaXcopy_getNull()
              }
              enableStore={setStoreEditEnabled}
            />
          }
          open={showDetail}
          panelName={t("tptasks:taskedit.taskitemV4.editPanelLbl")}
          editDetailEnableStore={enableEditStore} //{editDetailEnableStore}
          onSaveUpdateCallBack={() => {
            log.info(limaLogTag.UsersListV4, "LimaEditItemBaseV4 onSaveUpdateCallBack ....");
            onStoreTask();
          }}
          onDetialDismissCallBack={() => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            log.info(limaLogTag.UsersListV4, "LimaEditItemBaseV4 onDetialDismissCallBack ....");
            setShowDetail(false);
          }}
        />
      )}
    </>
  );
};

export default TasksListV4;

//----------------------
//   REACT QUERY
//----------------------

/**
 * Load taskslist from DB using STORE
 * @returns
 */
// const fetchTasks = async (): Promise<User2Task_withActaXcopyParasCommentsTasksMessagesAttachements[]> => {
const fetchTasks = async (): Promise<any> => {
  log.info(limaLogTag.TasksList, "fetching data fetchTasks");
  await limaTasksStoreV4.checkLastRead();
};
