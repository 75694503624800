import { FontIcon, getFocusStyle, getTheme, IIconProps, ITheme, mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { FunctionComponent } from "react";
import { LimaIconProps } from "../../../../assets/icons/DefLimaIconType";

interface ILimactaDetailActionItemV4Props {
  toolIcon?: IIconProps;
  toolName: string;
  notYetImplemented?: boolean;
  toolItemWidth?: number;
  minWidth?: number;
  disabled?: () => boolean;
  toolTip?: string;
  count?: string | number;
  info?: string;
  onClickClb?: () => void;
  // children?: ReactNode;
  children?: (childProps: LimaIconProps) => JSX.Element;
}

const theme: ITheme = getTheme();
const { palette, fonts } = theme;

const classNames = mergeStyleSets({
  toolItemCell: [
    getFocusStyle(theme, { inset: -1 }),
    {
      maxWidth: 48,
      minWidht: 48,
      minHeight: 80,
      height: "100%",
      padding: 3,
      paddingTop: 10,
      borderRadius: 5,
      margin: 5,
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      cursor: "default",
    },
  ],
  toolItemCellActive: [
    {
      selectors: {
        "&:hover": {
          background: palette.neutralLight,
          cursor: "pointer",
        },
      },
    },
  ],
  toolItemIconCell: {},
  toolItemIcon: {
    fontSize: 25,
    // margin: "5px 15px",
  },
  toolItemName: [
    fonts.medium,
    {
      // whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: 400,
      fontColor: "#333333",
      textAlign: "center",
      lineHeight: "1.1em",
    },
  ],
  toolItemCount: [
    fonts.small,
    {
      background: "#490070",
      color: palette.white,
      borderRadius: 10,
      position: "absolute",
      top: 0,
      right: 10,
      minWidth: 16,
      textAlign: "center",
    },
  ],
});

const LimactaDetailActionItemV4: FunctionComponent<ILimactaDetailActionItemV4Props> = observer(
  ({
    toolIcon,
    toolName,
    notYetImplemented,
    disabled,
    onClickClb,
    count,
    children,
    // toolItemWidth,
    minWidth,
  }: ILimactaDetailActionItemV4Props) => {
    const disabledInner = disabled();

    //------------------------
    // Handlers
    //-------------------------

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onClickHandler = (_e: unknown) => {
      if (onClickClb !== undefined && notYetImplemented !== true && disabledInner !== true) onClickClb();
    };

    return (
      <div
        className={`${classNames.toolItemCell} ${
          notYetImplemented !== true && disabledInner !== true ? classNames.toolItemCellActive : ""
        }`}
        style={minWidth !== undefined ? { minWidth: minWidth } : {}}
        data-is-focusable={true}
        onClick={onClickHandler}
      >
        {toolIcon !== undefined ? (
          <div className={classNames.toolItemIconCell}>
            <FontIcon {...toolIcon} className={classNames.toolItemIcon}></FontIcon>
          </div>
        ) : (
          <>{children({ color: disabledInner ? "#e7e7e7" : "#2f2f2f" })}</>
        )}
        <div className={classNames.toolItemName} style={{ color: disabledInner ? "#e7e7e7" : "#2f2f2f" }}>
          {toolName}
        </div>
        <div>{disabledInner}</div>
        {count !== undefined && <div className={classNames.toolItemCount}>{count}</div>}
      </div>
    );
  }
);

export default LimactaDetailActionItemV4;
