import { log } from "missionlog";
import { FunctionComponent, useEffect } from "react";
import React = require("react");
import { useMutation, useQuery } from "react-query";
// import { urlGetUser2UserJoinList, urlPATCHorDELETEUser2UserJoin } from "../../../../api/apilinks";
import { limaQuery_getUser2UserList, limaQuery_putUser2UserLinkinQuery } from "../../../../api/calls/limaUse2UserCalls";
// import { httpDeleteAuth, httpGetAuth, httpPutAuth, HttpResponse } from "../../../../api/httpAPI";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import { LimaHideableSection } from "../../../../limaComponents/LimaHideableSection";
import { LimaLoadingComponnet } from "../../../../limaComponents/LimaLoadingComponnet";
// import limaLoadeStore from "../../../../store/limaLoaderStore";
import limaStore from "../../../../store/limaStore";
// import { LIMA_ITEM_TYPE } from "../../../../types/BASE_Enums";
import { User2User } from "../../../../types/User2User";
// import { Visibilitor } from "../../../../types/Visibilitor";
import User2UserLinkItem from "./component/User2UserLinkItem";

interface User2UserLinkComponentProps {
  type: TOorFROM;
}

export enum TOorFROM {
  TO = "TO",
  FROM = "FROM",
  BOTH = "BOTH",
  COUNT = "COUNT",
}
export enum CALLUSERLINK {
  ASK,
  ACCEPT,
  REJECT,
  CANCEL,
  DELETE,
  OTHER,
}

export type AcceptLinkingObject = {
  user2user: User2User;
  type: CALLUSERLINK;
};

const User2UserLinkComponent: FunctionComponent<User2UserLinkComponentProps> = ({
  type,
}: User2UserLinkComponentProps) => {
  // const [u2uList, setU2UList] = useState<User2User[]>();

  const { isLoading, error, refetch, data } = useGetUser2UserListQuery(type);
  const { mutateAsync } = useAcceptLinkingQuery();

  useEffect(() => {
    void refetch();
  }, []);

  useEffect(() => {
    log.warn(limaLogTag.User2UserLinkComponent, "no data yet: ");
  }, [data]);

  if (isLoading == true) {
    return LimaLoadingComponnet(3);
  }

  if (error) {
    log.error(limaLogTag.User2UserLinkComponent, "stat loaded error: ", error);
    return <>Error Getting data </>;
  }

  const hideSectionName = (): string => {
    if (type === TOorFROM.FROM) return `Users I ask to link${data && "(" + String(data.users.length) + ")"}`;
    else return `Users wants me to link${data && "(" + String(data.users?.length) + ")"}`;
  };

  return (
    <LimaHideableSection name={hideSectionName()}>
      {data && data.users !== undefined ? (
        data.users.map((item: User2User, index: number) => (
          <User2UserLinkItem
            key={`u2u_${type}_${item._key ? item._key : index}`}
            item={item}
            type={type}
            onCallBack={mutateAsync}
          />
        ))
      ) : (
        <>NO DATA YET</>
      )}
    </LimaHideableSection>
  );
};

export default User2UserLinkComponent;

//----------------------
//   REACT QUERY
//----------------------

const useGetUser2UserListQuery = (type: TOorFROM) => {
  return useQuery(["u2ulist", String(type)], () => limaQuery_getUser2UserList(limaStore.userId, type), {
    enabled: false,
    onSuccess: (data) => {
      log.info(limaLogTag.User2UserLinkComponent, "useQuery/onSuccess data,type ", data, type);
    },
  });
};

const useAcceptLinkingQuery = () => {
  return useMutation(limaQuery_putUser2UserLinkinQuery, {
    onMutate: ({ user2user, type }: AcceptLinkingObject) => {
      // A mutation is about to happen!
      log.info(limaLogTag.UserAddEdit, "call mutate ", user2user, type);
      return { id: 1 };
    },
    onSuccess: (data) => {
      log.info(limaLogTag.UserAddEdit, "call mutate ", data);
    },
    onError: (error) => {
      log.error(limaLogTag.UserAddEdit, "call mutate ", error);
    },
  });
};

//--------------QUERIES -------------
