import Routes from './routes'
// IMPORTANT - CHECK OUT THE HREF PROP

const FileMenuOpen = { key: Routes.FileMenuOpenRoute, name: 'Open', href: `#${Routes.FileMenuOpenRoute}` };
const FileMenuImport = { key: Routes.FileMenuImportRoute, name: 'Import', href: `#${Routes.FileMenuImportRoute}` };
const FileMenuSave = { key: Routes.FileMenuSaveRoute, name: 'Save', href: `#${Routes.FileMenuSaveRoute}` };
const FileMenu = { key: Routes.FileMenuSub, name: 'File', href: `#${Routes.FileMenuSub}`,
subMenuProps: {
    items: [FileMenuOpen,FileMenuImport,FileMenuSave]}
 };

 const UserMenuAdduser = { key: Routes.userMenuAddRoute, name: 'Add new', href: `#${Routes.userMenuAddRoute}` };
 const UserMenuListUser = { key: Routes.userMenuListRoute, name: 'List', href: `#${Routes.userMenuListRoute}` };
const UserMenu = { key: Routes.UserMenuSub, name: 'Users', href: `#${Routes.UserMenuSub}`,
subMenuProps: {
    items: [UserMenuAdduser,UserMenuListUser]}
 };

const CommandBarItems = { menu: [FileMenu,UserMenu],farItems:[] }

export default CommandBarItems;