/**
 * LINKING COMPONENT BASE COMPONENT THAT CAN LINK ITEM TO ANYTHING..
 *
 * @TODO REWRITE
 */

import {
  ActionButton,
  // ChoiceGroup,
  // IChoiceGroupOption,
  IIconProps,
  Label,
  Stack,
  StackItem,
  Toggle,
} from "@fluentui/react";
import { log } from "missionlog";
import { useTranslation } from "react-i18next";
import { reaction } from "mobx";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import React = require("react");
// import { observer } from "mobx-react-lite";
import { TaskData, XCOPY_TYPE } from "../../../../types/Task";
import limaStore, { OPENEDFILETYPE_ENUM } from "../../../../store/limaStore";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import { SelectedInWord } from "../../../../types/wordObjects";
import { WORDITEMTYPE } from "../../../../api/apilinks";
import { highlightTaskLinks3, HIGHLIGHT_TYPE } from "../../../../limaCommon/limaWordInteract";
import { LimaNotice } from "../../../../limaComponents/LimaNotice";
import {
  isTaskDataLinkedDocOpen,
  isTaskLinked,
  taskLinkedItems,
  taskLinkedTo,
} from "../../../../limaCommon/limaTaskHelpers";
import { isNotNullOrUndefined } from "../../../../limaCommon/utils";
import { ChooseXcopyTypV4 } from "./ChooseXcopyTypeV4";
import { LimaLinkignItemActionsV4 } from "./LimaLinkignItemActionsV4";
import limaActasStore from "../../../../store/limaActasStore";
import { observer } from "mobx-react-lite";
import EditStates from "../../../Menu/Edit/States/components/EditStates";
import { ItemDebugInfoV4 } from "../../FileItem/components/ItemDebugInfoV4";

interface ItemLinkingV4Props {
  itemData: TaskData | null;
  itemKey?: string;
  isItemOwner?: boolean;
  onlyCreateXcopy?: boolean; // just for copy create
  enableAlwaysLinkParts?: boolean; // enable to link parts even not partCopy
  itemsLinkingCallBack: (newTaskDate: TaskData) => void;
}

const removeIcon: IIconProps = { iconName: "ReceiptReply" }; //ReceiptReply//RemoveContent
const removeAllIcon: IIconProps = { iconName: "ReceiptUndelivered" }; //ReceiptUndelivered//DependencyRemove

const addIcon: IIconProps = { iconName: "ReceiptForward" }; //ReceiptForward//RectangularClipping
const docLinkedIcon: IIconProps = { iconName: "PageLink" };

/**
 * Task linking component care about linking para na comments
 * enable add linkign and remove only for new object, or when acta is open an user is doc owner
 * or when xcopy is open and already linked
 * @param param0
 * @returns JSX copoment
 */
const ItemLinkingV4: FunctionComponent<ItemLinkingV4Props> = ({
  itemData,
  itemKey,
  isItemOwner,
  onlyCreateXcopy,
  enableAlwaysLinkParts,
  itemsLinkingCallBack, //TODO: cahnge to special item ...Origunaly only from task Data so taskDataReturn
}: ItemLinkingV4Props) => {
  const [selectedItems, setselectedItems] = useState<boolean>(false);
  const [enabledAddLinking, setEnabledAddLinking] = useState<boolean>(false); //IF document is open and not yet lined
  const [inner_itemData, setInner_itemData] = useState<TaskData | null>(itemData);
  const [innerShowHighLighted, setInnerShowHightLighted] = useState<boolean>(false);
  const [linkedItemsCount, setLinkedItemsCount] = useState<number>(0);
  const [xcopyType, setXcopyType] = useState<XCOPY_TYPE>(itemData.xcopytype ? itemData.xcopytype : XCOPY_TYPE.NOXCOPY);
  const [docLinked, setDocLinked] = useState<boolean>(false);

  const [innerEnabledXcopyCreateParam, setInnerEnabledXcopyCreateParams] = useState<boolean>(false);

  const { t } = useTranslation();

  //---------------------
  // Reactions
  //---------------------

  useEffect(() => {
    const disposer1 = reaction(
      () => limaStore.selectedInWord,
      (selectedInWord) => {
        log.info(limaLogTag.ItemLinkingV4, `UEF: reaction on change selection `, selectedInWord);
        if (isNotNullOrUndefined(inner_itemData) && inner_itemData !== null && enabledAddLinking) {
          setselectedItems(limaStore.selectedInWord.length > 0 ? true : false);
        } else if (selectedItems) setselectedItems(false);
      }
    );

    const disposer2 = reaction(
      () => limaStore.docxIsLoading,
      (docxIsLoading) => {
        log.info(limaLogTag.ItemLinkingV4, `UEF: reaction on change docxIsLoading`, docxIsLoading);
        if (docxIsLoading === false) checkDefaultSetup();
      }
    );

    log.info(limaLogTag.ItemLinkingV4, `UEF: cehck enable linking `, inner_itemData);

    if (inner_itemData === null) {
      log.info(limaLogTag.ItemLinkingV4, `UEF: reseting task data data===null `, inner_itemData);
      itemsLinkingCallBack({
        ...inner_itemData,
        actaKey: "",
        xcopyKey: "",
        commentIds: [],
        paraIds: [],
        xcopytype: XCOPY_TYPE.NOXCOPY,
      });
    }
    checkDefaultSetup();
    return function cleanup() {
      disposer1();
      disposer2();
    };
  }, []);

  useEffect(() => {
    log.info(limaLogTag.ItemLinkingV4, "UEF: somethign changed", limaStore.xcopyId, itemKey, inner_itemData);
    checkDefaultSetup();
  }, [limaStore.xcopyId, itemKey, inner_itemData?.xcopyKey, inner_itemData?.xcopytype]);

  /**
   * update highlihgt para in document
   * @param {boolean} checked optional if provided is highlighted defined by param, otherwise it get from state
   */
  const updateHightligts = (checked: boolean) => {
    const hightlightItems: SelectedInWord[] = [];
    if (isNotNullOrUndefined(inner_itemData)) {
      inner_itemData.paraIds.forEach((item: string) => hightlightItems.push({ type: WORDITEMTYPE.para, id: item }));
    }
    if (isNotNullOrUndefined(inner_itemData)) {
      inner_itemData.commentIds.forEach((item: string) => hightlightItems.push({ type: WORDITEMTYPE.comm, id: item }));
    }
    const highlight: HIGHLIGHT_TYPE =
      inner_itemData.xcopytype == XCOPY_TYPE.TRIMXCOPY ? HIGHLIGHT_TYPE.TRIM : HIGHLIGHT_TYPE.HIGHLIGHT;
    log.info(limaLogTag.ItemLinkingV4, "updateHightligts: ", highlight, inner_itemData);
    if (checked === true) {
      limaStore.setLocationChangeCallBack(() => {
        void highlightTaskLinks3(hightlightItems, false, highlight);
      });
      return highlightTaskLinks3(hightlightItems, true, highlight);
    } else {
      limaStore.setLocationChangeCallBack(null);
      return highlightTaskLinks3(hightlightItems, false, highlight);
    }
  };

  //Predefined values based on current data
  const checkDefaultSetup = () => {
    log.info(limaLogTag.ItemLinkingV4, "checkDoc: limaStore", limaStore, limaStore.openedDocType);
    log.info(limaLogTag.ItemLinkingV4, "checkDoc: taskData", inner_itemData);
    setEnabledAddLinking(false);

    // on create new xcopy from document - partial or full,
    if (isNotNullOrUndefined(onlyCreateXcopy) && onlyCreateXcopy == true) {
      // statuText = statuText + " - only create copy";
      setInnerEnabledXcopyCreateParams(true);
    }
    if (inner_itemData?.actaKey != null && inner_itemData?.actaKey != "") {
      setDocLinked(true);
    }
    setLinkedItemsCount(isNotNullOrUndefined(inner_itemData) ? taskLinkedItems(inner_itemData) : 0);
    log.info(limaLogTag.ItemLinkingV4, "checkDoc: Finishw >>");
  };

  //--------------------
  //  Handlers
  //--------------------
  //PARA LINKING
  const onAddParaLinking = () => {
    log.info(limaLogTag.ItemLinkingV4, "onAddParaLinking: to link", limaStore.selectedInWord);

    let tempTaskData: TaskData = inner_itemData;
    let update = false;

    if (!limaStore.actaIsOpen && !limaStore.xcopyIsOpen) {
      log.warn(limaLogTag.ItemLinkingV4, "onAddParaLinking: no doc current open", limaStore);
      return;
    }

    //If undefined reset taskData
    if (!inner_itemData) {
      tempTaskData = {
        actaKey: "",
        commentIds: [],
        paraIds: [],
        xcopyKey: "",
        xcopytype: XCOPY_TYPE.NOXCOPY,
        attachements: [],
      };
    }

    log.info(limaLogTag.ItemLinkingV4, "onAddParaLinking: checking actaid, xcopyid, and open state", limaStore);

    //ADD selected Items to linking -> to paraIds, commentsIds
    //check for any change
    if (limaStore.selectedInWord.length > 0) {
      log.info(limaLogTag.ItemLinkingV4, "onAddParaLinking: selected exists", tempTaskData);
      if (!inner_itemData || !tempTaskData.paraIds) tempTaskData.paraIds = [];
      if (!inner_itemData || !inner_itemData.commentIds) tempTaskData.commentIds = [];
      limaStore.selectedInWord.forEach((item: SelectedInWord) => {
        if (item.type === WORDITEMTYPE.para) {
          !tempTaskData.paraIds.includes(item.id) && tempTaskData.paraIds.push(item.id);
        } else if (item.type === WORDITEMTYPE.comm) {
          !tempTaskData.commentIds.includes(item.id) && tempTaskData.commentIds.push(item.id);
        }
      });
      update = true;
    }

    if (update) {
      log.info(limaLogTag.ItemLinkingV4, "onAddParaLinking: store setTaskData", tempTaskData);
      if (tempTaskData.actaKey === null || tempTaskData.actaKey === "") {
        tempTaskData.actaKey = limaStore.actaId;
      }
      // if ( tempTaskData.xcopyKey === null || tempTaskData.xcopyKey === "") {
      //   tempTaskData.xcopyKey = limaStore.xcopyId;
      // }
      // if (tempTaskData.xcopytype === null || tempTaskData.xcopytype === XCOPY_TYPE.NOXCOPY) {
      //   tempTaskData.xcopytype = limaStore.xcopyType;
      // }
      log.info(limaLogTag.ItemLinkingV4, "onAddParaLinking: store setTaskData", tempTaskData);
      itemsLinkingCallBack(tempTaskData);
      setLinkedItemsCount(taskLinkedItems(tempTaskData));

      setDocLinked(true);
    }

    if (!selectedItems) {
      log.info(limaLogTag.ItemLinkingV4, "onAddParaLinking: set selected items true");
      setselectedItems(true);
    }

    if (innerShowHighLighted === true) void updateHightligts(true);
  };

  const onRemoveParaLinking = () => {
    log.info(limaLogTag.ItemLinkingV4, "onRemoveParaLinking: to link", limaStore.selectedInWord);

    // const tempTaskData: TaskData = taskData;
    let update = false;

    if (limaStore.selectedInWord.length > 0) {
      log.info(limaLogTag.ItemLinkingV4, "onRemoveParaLinking: selected exists", inner_itemData);
      if (!inner_itemData || !inner_itemData.paraIds) inner_itemData.paraIds = [];
      if (!inner_itemData || !inner_itemData.commentIds) inner_itemData.commentIds = [];
      limaStore.selectedInWord.forEach((item: SelectedInWord) => {
        if (item.type === WORDITEMTYPE.para) {
          inner_itemData.paraIds = inner_itemData.paraIds.filter((itemId) => itemId !== item.id);
        } else if (item.type === WORDITEMTYPE.comm) {
          inner_itemData.commentIds = inner_itemData.commentIds.filter((itemId) => itemId !== item.id);
        }
      });
      update = true;
    }

    if (update) {
      log.info(limaLogTag.ItemLinkingV4, "onRemoveParaLinking: store setTaskData", inner_itemData);

      itemsLinkingCallBack(inner_itemData);
      setLinkedItemsCount(taskLinkedItems(inner_itemData));
    }
    if (selectedItems && inner_itemData.paraIds.length == 0 && inner_itemData.commentIds.length == 0) {
      log.info(limaLogTag.ItemLinkingV4, "onRemoveParaLinking: set selected items false");
      setselectedItems(false);
    }
    if (innerShowHighLighted === true) {
      if (inner_itemData.paraIds.length == 0 && inner_itemData.commentIds.length == 0) void updateHightligts(false);

      void updateHightligts(true);
    }
  };

  const onClearParaLinking = () => {
    log.info(limaLogTag.ItemLinkingV4, "onClearParaLinking: Clear all");
    itemsLinkingCallBack({ ...inner_itemData, commentIds: [], paraIds: [] });
    if (selectedItems === true) setselectedItems(false);
    if (innerShowHighLighted === true) void updateHightligts(false);
    setLinkedItemsCount(0);
  };

  const onClearDocLinking = () => {
    log.info(limaLogTag.ItemLinkingV4, "onClearDocLinking: Clear DocLinking");

    setDocLinked(false);
    setXcopyType(XCOPY_TYPE.NOXCOPY);
    setInner_itemData({
      ...inner_itemData,
      actaKey: "",
      xcopyKey: "",
      commentIds: [],
      paraIds: [],
      xcopytype: XCOPY_TYPE.NOXCOPY,
    });

    itemsLinkingCallBack({
      ...inner_itemData,
      actaKey: "",
      xcopyKey: "",
      commentIds: [],
      paraIds: [],
      xcopytype: XCOPY_TYPE.NOXCOPY,
    });
    setLinkedItemsCount(0);

    if (selectedItems === true) setselectedItems(false);
    if (innerShowHighLighted === true) void updateHightligts(false);
  };

  const onSetDocLinking = () => {
    log.info(limaLogTag.ItemLinkingV4, "onSetDocLinking: Set DocLinking");
    setInner_itemData({
      ...inner_itemData,
      actaKey: limaStore.actaId,
      xcopyKey: limaStore.xcopyId,
      xcopytype: limaStore.xcopyType,
    });
    if (itemsLinkingCallBack !== undefined) {
      itemsLinkingCallBack({
        ...inner_itemData,
        actaKey: limaStore.actaId,
        xcopyKey: limaStore.xcopyId,
        xcopytype: limaStore.xcopyType,
      });
    }

    setDocLinked(true);

    if (selectedItems) setselectedItems(false);
    if (innerShowHighLighted) void updateHightligts(false);
  };

  const onCreateXcopy = (checked: boolean) => {
    setInnerEnabledXcopyCreateParams(checked);
    if (checked == false) {
      onClearDocLinking();
    }

    //TODO:
    //if set false -> remove linking
    //id set true -> cleanup linking
  };

  const onChangeCreateXcopy = (newType: XCOPY_TYPE) => {
    log.info(
      limaLogTag.ItemLinkingV4,
      "onChangeCreateXcopy: draw xcopyCreate linking",
      newType,
      newType === XCOPY_TYPE.MAINXCOPY ? false : true
    );
    setXcopyType(newType);
    if (newType === XCOPY_TYPE.MAINXCOPY) {
      setInner_itemData({
        ...inner_itemData,
        xcopyKey: limaStore.xcopyId,
        xcopytype: newType,
      });
      itemsLinkingCallBack({
        ...inner_itemData,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        xcopyKey: limaStore.xcopyId,
        xcopytype: newType,
        actaKey: limaStore.actaId,
      });
    } else {
      setInner_itemData({
        ...inner_itemData,
        xcopytype: newType,
      });
      itemsLinkingCallBack({
        ...inner_itemData,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        xcopyKey: null,
        xcopytype: newType,
        actaKey: limaStore.actaId,
      });
    }
    //Update if visible
    if (innerShowHighLighted == true) {
      void updateHightligts(false);
      setInnerShowHightLighted(false);
      // updateHightligts(true);
    }
  };

  /**
   *
   * @returns true if is possible to link to current document
   */
  const showLinking = (): boolean => {
    //is already linked?
    if (inner_itemData?.actaKey != null && inner_itemData.actaKey != "") {
      return false;
    }
    //not yet Linked?
    else {
      log.info(limaLogTag.ItemLinkingV4, "showLinking: not yet linked");
      if (limaStore.isMainCopyOpen() === true || limaStore.isAnyActiveWorkingcopyOpen() === true) {
        log.info(limaLogTag.ItemLinkingV4, "showLinking: is open any document MainCopy or Working Copy");
        return true;
      }
      return false;
    }
  };

  //--------------------------
  //   ELEMENTS
  //--------------------------

  //DOC LINKING
  /**
   *
   * @returns Action button to add link to current document or remove
   *
   */
  const DocLinking = (): JSX.Element => {
    log.info(limaLogTag.ItemLinkingV4, "DocLinking: draw doc linking", docLinked);
    const taskLinkedToItem = taskLinkedTo(inner_itemData);
    //if (docLinked) {
    if (taskLinkedToItem !== null && taskLinkedToItem !== XCOPY_TYPE.NOXCOPY) {
      return (
        <>
          <ActionButton
            iconProps={docLinkedIcon}
            allowDisabledFocus
            disabled={!isRemoveDocLinkingEnabled}
            onClick={onClearDocLinking}
          >
            {taskLinkedToItem == XCOPY_TYPE.MAINXCOPY //limaStore.openedDocType === OPENEDFILETYPE_ENUM.COPY
              ? t("tpmain:component.linking.removelinkingBtnDOCLbl")
              : t("tpmain:component.linking.removelinkingBtnXcopyLbl")}
          </ActionButton>
          {!isRemoveDocLinkingEnabled && (
            <>
              <br />
              <LimaNotice>({t("tpmain:component.linking.removelinkingNoticeLbl")})</LimaNotice>
            </>
          )}
        </>
      );
    } else {
      return isNotNullOrUndefined(inner_itemData) && (limaStore.isMainCopyOpen || limaStore.isAnyXcopyOpen) ? (
        // <StackItem>
        <ActionButton
          iconProps={docLinkedIcon}
          allowDisabledFocus
          // disabled={linkedTypeFixed}
          onClick={onSetDocLinking}
        >
          {limaStore.openedDocType == OPENEDFILETYPE_ENUM.COPY
            ? t("tpmain:component.linking.addlinkingBtnXcopyLbl")
            : t("tpmain:component.linking.addlinkingBtnDOCLbl")}
        </ActionButton>
      ) : (
        <>No document or copy is open to try linking</>
      );
      // </StackItem>
    }
  };

  /**
   * This is responsible for to show or not to show linking to part of document - Paras and comments
   *
   */
  const LinkingToDocParts = observer((): JSX.Element => {
    //
    // const LinkingToDocParts = (): JSX.Element => {
    //Enable linkign
    //Create new copy -> partial copy
    //Task create -> always
    if (isNotNullOrUndefined(inner_itemData?.xcopyKey) || inner_itemData?.xcopyKey === "") {
      //TASK IS NOT LINKED TO XCOPY
      //Task
      if (enableAlwaysLinkParts && inner_itemData?.actaKey !== "" && inner_itemData?.actaKey !== null) {
        log.info(limaLogTag.ItemLinkingV4, "LinkingToDocParts: SHOW - Always link");
        // return <>SHOW - Always link</>;
      } else if (
        innerEnabledXcopyCreateParam &&
        (xcopyType === XCOPY_TYPE.TRIMXCOPY || xcopyType === XCOPY_TYPE.FULLXCOPY)
      ) {
        log.info(limaLogTag.ItemLinkingV4, "LinkingToDocParts: SHOW - link on Trim copy or full copy");
        // return <>SHOW - link on Trim copy</>;
      } else {
        log.info(limaLogTag.ItemLinkingV4, "LinkingToDocParts: DONT SHOW - other");
        return <></>;
      }
    }
    //Task edit -> if is linked
    else {
      //TASK IS LINKED TO XCOPY
      if (onlyCreateXcopy === true) {
        //OUTSIDE REQUEST .. for creating xcopy
        log.info(limaLogTag.ItemLinkingV4, "LinkingToDocParts: SHOW - onlyCreateXcopy");
        // return <>SHOW - onlyCreateXcopy</>;
      } else if (
        isTaskDataLinkedDocOpen(inner_itemData, limaStore.actaId, limaStore.xcopyId) &&
        inner_itemData?.xcopytype !== XCOPY_TYPE.TRIMXCOPY
      ) {
        //je jiz nalinkovz7 dokuemt a typ je XCOPY TRIM
        //je napojen již na kopii a
        log.info(limaLogTag.ItemLinkingV4, "LinkingToDocParts: SHOW - link on document is open");
        // return <>SHOW - link on document is open</>;
      } else if (innerEnabledXcopyCreateParam === true) {
        //je zaskrtnuto vyrobit kopii
        log.info(limaLogTag.ItemLinkingV4, "LinkingToDocParts: SHOW - create copy was requesed");
      } else {
        return <>{t("tpmain:component.linking.linkingDisabledLbl")}</>;
      }
    }

    return (
      <Stack>
        <StackItem>
          <Stack horizontal>
            <StackItem>
              <Label key="linkedParaLbl">
                {t("tpmain:component.linking.linkedparagraphsLbl")} [{linkedItemsCount > 0 ? linkedItemsCount : "-"}]
              </Label>
            </StackItem>
            <StackItem>
              <Toggle
                key="linkedParaShow"
                onText={t("tpmain:component.linking.linkedparagraphsHighlightLbl")}
                offText={t("tpmain:component.linking.linkedparagraphsHiddenLbl")}
                checked={innerShowHighLighted}
                // disabled={!selectedItems && linkedItemsCount <= 0}
                onChange={(_ev: React.FormEvent<HTMLElement>, checked: boolean) => {
                  log.info(limaLogTag.ItemLinkingV4, "on toggle click: checked,taskdata", checked, inner_itemData);
                  setInnerShowHightLighted(checked);
                  void updateHightligts(checked);
                }}
                // role="checkbox"
              />
            </StackItem>
          </Stack>
        </StackItem>

        <Stack horizontal>
          <StackItem>
            <ActionButton
              iconProps={addIcon}
              allowDisabledFocus
              // disabled={!selectedItems}
              onClick={onAddParaLinking}
            >
              {t("tpmain:component.linking.addlinkingBtnLbl")}
            </ActionButton>
          </StackItem>
          <StackItem>
            <ActionButton
              iconProps={removeIcon}
              allowDisabledFocus
              disabled={linkedItemsCount <= 0}
              onClick={onRemoveParaLinking}
            >
              {t("tpmain:component.linking.removelinkingBtnLbl")}
            </ActionButton>
          </StackItem>
          <StackItem>
            <ActionButton
              iconProps={removeAllIcon}
              allowDisabledFocus
              disabled={linkedItemsCount <= 0}
              onClick={onClearParaLinking}
            >
              {t("tpmain:component.linking.removealllinkingBtnLbl")}
            </ActionButton>
          </StackItem>
        </Stack>
      </Stack>
    );
  });

  /**
   *
   * @returns Choice group of creating xcopyType
   */
  const CreateXcopyType = (): JSX.Element => {
    log.info(limaLogTag.ItemLinkingV4, "CreateXcopyType: draw xcopyCreate linking");
    return (
      <ChooseXcopyTypV4
        chooseXcopyLabel={t("tpmain:component.linking.createcopytypeLbl")}
        defaultSelected={xcopyType}
        chooseXcopyOnChange={onChangeCreateXcopy}
      />
    );
  };

  /**
   *
   * @returns Togle switching create xcopy or just link
   */
  const CreateXcopyOrLink = (): JSX.Element => {
    log.info(limaLogTag.ItemLinkingV4, "CreateXcopyOrLink: draw xcopyCreate linking");
    return (
      <Toggle
        key="createXcopy"
        label={t("tpmain:component.linking.createXcopyLinkingLabelLbl")}
        inlineLabel
        onText={t("tpmain:component.linking.createXcopyLinkingLbl")}
        offText={t("tpmain:component.linking.dontCreateXcopyLinkingLbl")}
        checked={innerEnabledXcopyCreateParam}
        onChange={(_ev: React.FormEvent<HTMLElement>, checked: boolean) => {
          log.info(limaLogTag.ItemLinkingV4, "on toggle createXcopy click: checked,taskdata", checked, inner_itemData);
          onCreateXcopy(checked);
        }}
      />
    );
  };

  const LinkedInfo = useMemo((): JSX.Element => {
    let linkedToText = "";
    //let title = "";
    //Document is already linked {` [${inner_itemData?.actaKey}]`}
    if (inner_itemData?.actaKey !== undefined && inner_itemData?.actaKey !== "") {
      if (inner_itemData?.xcopytype === XCOPY_TYPE.MAINXCOPY) {
        linkedToText = `${t("tpmain:component.linking.alreadylinkdtoMainLbl")} '${
          limaActasStore.getActa(inner_itemData.actaKey)?.name
        }'`;
      } else if (inner_itemData?.xcopytype === XCOPY_TYPE.FULLXCOPY) {
        linkedToText = `${t("tpmain:component.linking.alreadylinkdtoFullCLbl")} ${
          limaActasStore.getActa(inner_itemData.actaKey)?.name
        }`;
      } else if (inner_itemData?.xcopytype === XCOPY_TYPE.TRIMXCOPY) {
        linkedToText = `${t("tpmain:component.linking.alreadylinkdtoTrimCLbl")} ${
          limaActasStore.getActa(inner_itemData.actaKey)?.name
        }`;
      }
    } else {
      linkedToText = t("tpmain:component.linking.notlinkedyetLbl");
    }
    return <div>{linkedToText}</div>;
  }, [inner_itemData?.actaKey, inner_itemData?.xcopytype]);

  const LinkingDebug_inner = observer(() => {
    return (
      <ItemDebugInfoV4>
        <StackItem>{`limastore ACTAID: ${limaStore.actaId}`}</StackItem>
        <StackItem>{`limastore ACTANAME: ${limaStore.actaName}`}</StackItem>
        <StackItem>{`limastore XCOPYID: ${limaStore.xcopyId}`}</StackItem>
        <StackItem>{`limastore XCOPYTYPE: ${limaStore.xcopyType}`}</StackItem>
        <StackItem>{`limaStore isMainCopyOpen(): ${limaStore.isMainCopyOpen()}`}</StackItem>
        <StackItem>{`limastore isDocOwner: ${String(limaStore.IamDocumentOwner)}`}</StackItem>
        <StackItem>{`ItemKey: ${itemKey}`}</StackItem>
        <StackItem>{`isItemOwner: ${isItemOwner}`}</StackItem>
        <StackItem>{`onlyCreateXcopy: ${onlyCreateXcopy}`}</StackItem>
        <StackItem>{`enableAlwaysLinkParts: ${enableAlwaysLinkParts}`}</StackItem>
        <StackItem>{`innerEnabledXcopyCreateParam: ${String(innerEnabledXcopyCreateParam)}`}</StackItem>
        <StackItem>{`innerEnabledXcopyCreateParam: ${String(innerEnabledXcopyCreateParam)}`}</StackItem>
      </ItemDebugInfoV4>
    );
  });
  //-----------
  //  Enablors
  //------------
  const isEnabledLinkingFilesActions = useMemo(() => {
    if (onlyCreateXcopy === true) return false;
    if (itemKey === undefined || itemKey === null || itemKey === "") return false;
    return true;
  }, [onlyCreateXcopy, itemKey]);

  const isRemoveDocLinkingEnabled = useMemo(() => {
    if (isItemOwner === true || itemKey === null || itemKey === "") return true;
    return false;
  }, [isItemOwner]);

  const ShowAlreadyLinked = observer((): JSX.Element => {
    return (
      <>
        <StackItem>{LinkedInfo}</StackItem>

        <StackItem>
          <DocLinking />
        </StackItem>
        {isNotNullOrUndefined(inner_itemData) &&
        isTaskDataLinkedDocOpen(inner_itemData, limaStore.actaId, limaStore.xcopyId) ? (
          <StackItem>
            <LinkingToDocParts />
          </StackItem>
        ) : (
          <>{t("tpmain:component.linking.unablechangelinkignLbl")}</>
        )}
        {isEnabledLinkingFilesActions && (
          <Stack.Item>
            <LimaLinkignItemActionsV4
              linkedXcopyKey={inner_itemData.xcopyKey}
              linkedXcopyType={inner_itemData.xcopytype}
              linkedActaname={limaActasStore.getActa(inner_itemData.actaKey)?.name}
              linkedActaKey={inner_itemData.actaKey}
            />
          </Stack.Item>
        )}
        <LinkingCompareStates />
      </>
    );
  });

  const LinkingCompareStates = observer((): JSX.Element => {
    if (limaStore.isComparedResultOpen === true) {
      return <EditStates />;
    }
    return <></>;
  });
  /**
   *
   * @returns return not linked switch
   */
  const ShowNotLinked = (): JSX.Element => {
    return (
      <>
        {showLinking && (limaStore.isMainCopyOpen() === true || limaStore.isAnyActiveWorkingcopyOpen() === true) ? (
          onlyCreateXcopy ? (
            <>
              {/* <StackItem>HELP: Only CREATE XCOPY</StackItem> */}
              <StackItem>
                <CreateXcopyType />
              </StackItem>
              <StackItem>
                <LinkingToDocParts />
              </StackItem>
            </>
          ) : (
            <>
              {/* <StackItem>HELP: Only CREATE XCOPY enable {String(limaStore.isMainCopyOpen())}</StackItem> */}
              {limaStore.isMainCopyOpen() && (
                <StackItem>
                  <CreateXcopyOrLink />
                </StackItem>
              )}
              {innerEnabledXcopyCreateParam === false ? (
                <>
                  {/* <StackItem>HELP: innerEnabledXcopyCreate FALSE</StackItem> */}
                  <StackItem>
                    <DocLinking />
                  </StackItem>
                </>
              ) : (
                <>
                  {/* <StackItem>HELP: innerEnabledXcopyCreate TRUE</StackItem> */}
                  <StackItem>
                    <CreateXcopyType />
                  </StackItem>
                </>
              )}
              <StackItem>
                <LinkingToDocParts />
              </StackItem>
            </>
          )
        ) : (
          <>{t("tpmain:component.linking.nolinkabledocopenLbl")}</>
        )}
      </>
    );
  };

  return (
    <Stack>
      <LinkingDebug_inner />
      {/* <StackItem>{">>>text" + infoStatusText}</StackItem> */}
      {isNotNullOrUndefined(inner_itemData) && isTaskLinked(inner_itemData) ? <ShowAlreadyLinked /> : <ShowNotLinked />}
    </Stack>
  );
};

export default ItemLinkingV4;
