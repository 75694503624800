import { log } from "missionlog";
import { limaLogTag } from "../../limaCommon/limaLog";
import limaLoadeStore, { LoaderItemEnd } from "../../store/limaLoaderStore";
import limaStore from "../../store/limaStore";
import limaTasksStoreV4 from "../../store/limaTasksStoreV4";
import { User2Task_withActaXcopyParasCommentsTasksMessagesAttachements } from "../../types/User2Task_withActaXcopy";
import { urlTasksGetList } from "../apilinks";
import { httpGetAuth, HttpResponse } from "../httpAPI";

export async function limaQuery_getTaskList(): Promise<
  User2Task_withActaXcopyParasCommentsTasksMessagesAttachements[]
> {
  const loaderID = limaLoadeStore.add("Get tasks from DB");
  let response: HttpResponse<User2Task_withActaXcopyParasCommentsTasksMessagesAttachements[]>;
  try {
    const response = await httpGetAuth<User2Task_withActaXcopyParasCommentsTasksMessagesAttachements[]>(
      urlTasksGetList("", limaStore.userId, "", false, true),
      loaderID.ac
    );
    limaTasksStoreV4.isLoading = true;
    if (!response.ok) {
      log.warn(limaLogTag.LimaGetTasksCall, "limaQuery_getTaskList: no ParseBody ", response);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw new Error("limaQuery_getTaskList: ...Some error on get...");
    }
    log.info(limaLogTag.LimaGetTasksCall, "limaQuery_getTaskList: I get Data from call:", response);
    if (response.parsedBody !== undefined) {
      limaTasksStoreV4.setFullTasksList(response.parsedBody);
      limaTasksStoreV4.setTasksList(response.parsedBody);
      limaTasksStoreV4.setFullTaskListCount(response.parsedBody.length);

      log.info(limaLogTag.LimaGetTasksCall, "limaQuery_getTaskList: now have tasksList ", limaTasksStoreV4.tasksList);
      log.info(
        limaLogTag.LimaGetTasksCall,
        "limaQuery_getTaskList: now have fullTasksList: ",
        limaTasksStoreV4.fullTasksList
      );
      return new Promise((resolve) => {
        limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
        resolve(response.parsedBody);
      });
    } else {
      //TODO: error handling
      log.warn(limaLogTag.LimaGetTasksCall, "limaQuery_getTaskList: no ParseBody ", response);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw new Error("limaQuery_getTaskList: ...Some error on store...");
    }
  } catch {
    log.error(limaLogTag.LimaGetTasksCall, "limaQuery_getTaskList: Error call get User", response);
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error("Problem geting data");
  } finally {
    // limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
    limaTasksStoreV4.isLoading = false;
  }

  //return tasksListData.parsedBody;
}
