import {
  CommandButton,
  getTheme,
  // IconButton,
  IContextualMenuProps,
  ITheme,
  mergeStyleSets,
  // IStackTokens,
  // IIconProps,
  Persona,
  PersonaSize,
  Stack,
} from "@fluentui/react";
import { FunctionComponent, useMemo } from "react";
import React = require("react");
import { userAccListDataTypeLinked } from "../../../../../dataModels/userAccListDataType";
import { getInitialsGlued } from "../../../../../limaCommon/limaStringTools";
import { VISIBILITOR_ACCESS_TYPE } from "../../../../../types/Visibilitor";
import { useTranslation } from "react-i18next";

interface AccessItemV4Props {
  userItem: userAccListDataTypeLinked;
  onClickCallback: (userId: string, edgeId: string, accessType: VISIBILITOR_ACCESS_TYPE) => void;
  accessTypes?: VISIBILITOR_ACCESS_TYPE[];
}

// const showIcon: IIconProps = { iconName: "RedEye" };
// const hideIcon: IIconProps = { iconName: "Hide" };
const theme: ITheme = getTheme();
const { palette } = theme;

const classNames = mergeStyleSets({
  root: {
    selectors: {
      "&:hover": {
        background: palette.neutralLight,
        cursor: "pointer",
      },
    },
  },
  button: {
    height: 24,
  },
  linkedItem: {
    maxWidth: "60%",
  },
  settingItem: {
    width: 130,
  },
});
// flex-grow: 1;

const AccessItemV4: FunctionComponent<AccessItemV4Props> = ({
  userItem,
  onClickCallback,
  accessTypes,
}: AccessItemV4Props) => {
  const { t } = useTranslation();
  const menuProps = useMemo((): IContextualMenuProps => {
    const menu: IContextualMenuProps = { items: [] };

    if (accessTypes === undefined || accessTypes.includes(VISIBILITOR_ACCESS_TYPE.CAN_VIEW)) {
      menu.items.push({
        key: "view",
        text: t("tpshow:access.uniaccess.userslistitem.canviewLbl"),
        disabled: userItem.edgeId !== null && userItem.edgeId !== "" ? true : false,
        iconProps: { iconName: "" },
        onClick: () => {
          window.console.log("can view");
          onClickCallback(userItem.userId, userItem.edgeId, VISIBILITOR_ACCESS_TYPE.CAN_VIEW);
        },
      });
    }

    if (accessTypes === undefined || accessTypes.includes(VISIBILITOR_ACCESS_TYPE.CAN_EDIT)) {
      menu.items.push({
        key: "edit",
        text: t("tpshow:access.uniaccess.userslistitem.caneditLbl"),
        disabled: userItem.edgeId !== null && userItem.edgeId !== "" ? true : false,
        iconProps: { iconName: "" },
        onClick: () => {
          window.console.log("can edit");
          onClickCallback(userItem.userId, userItem.edgeId, VISIBILITOR_ACCESS_TYPE.CAN_EDIT);
        },
      });
    }

    if (accessTypes === undefined || accessTypes.includes(VISIBILITOR_ACCESS_TYPE.OWNER)) {
      menu.items.push({
        key: "owner",
        text: t("tpshow:access.uniaccess.userslistitem.ownerLbl"),
        disabled: userItem.edgeId !== null && userItem.edgeId !== "" ? true : false,
        iconProps: { iconName: "" },
        onClick: () => {
          window.console.log("set owner");
          onClickCallback(userItem.userId, userItem.edgeId, VISIBILITOR_ACCESS_TYPE.OWNER);
        },
      });
    }

    if (accessTypes === undefined || accessTypes.includes(VISIBILITOR_ACCESS_TYPE.COMPANY_LINK)) {
      menu.items.push({
        key: "link",
        text: t("tpshow:access.uniaccess.userslistitem.linkLbl"),
        disabled: userItem.edgeId !== null && userItem.edgeId !== "" ? true : false,
        iconProps: { iconName: "" },
        onClick: () => {
          window.console.log("set linked");
          onClickCallback(userItem.userId, userItem.edgeId, VISIBILITOR_ACCESS_TYPE.COMPANY_LINK);
        },
      });
    }

    if (accessTypes === undefined || accessTypes.includes(VISIBILITOR_ACCESS_TYPE._REMOVE)) {
      menu.items.push({
        key: "remove",
        text: t("tpshow:access.uniaccess.userslistitem.removeLbl"),
        disabled: userItem.edgeId !== null && userItem.edgeId !== "" ? false : true,
        iconProps: { iconName: "" },
        onClick: () => {
          window.console.log("remove");
          onClickCallback(userItem.userId, userItem.edgeId, VISIBILITOR_ACCESS_TYPE._REMOVE);
        },
      });
    }

    return menu;
  }, [userItem]);

  const currentState = (): string => {
    // window.console.log("current State", userItem);
    if (userItem.edgeId !== null && userItem.edgeId !== "") {
      if (accessTypes.includes(VISIBILITOR_ACCESS_TYPE.COMPANY_LINK)) {
        return t("tpshow:access.uniaccess.userslistitem.linkLbl");
      } else {
        return t("tpshow:access.uniaccess.userslistitem.caneditLbl");
      }
    } else return t("tpshow:access.uniaccess.userslistitem.noaccessLbl");
  };

  return (
    <Stack horizontal id={`per_${userItem.edgeId}`} horizontalAlign="space-between" className={classNames.root}>
      <Stack.Item className={classNames.linkedItem}>
        <Persona
          imageInitials={getInitialsGlued(userItem.userName)}
          text={userItem.userName}
          size={PersonaSize.size8}
          // presence={PersonaPresence.online}
          // hidePersonaDetails={!renderDetails}
          imageAlt={userItem.userName}
        />
        {/* {userItem.userName}[{userItem.userId}] - {userItem.edgeId} */}
      </Stack.Item>
      <Stack.Item className={classNames.settingItem}>
        <CommandButton menuProps={menuProps} className={classNames.button}>
          {currentState()}
        </CommandButton>
      </Stack.Item>
    </Stack>
  );
};

export default AccessItemV4;
