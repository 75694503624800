/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import "office-ui-fabric-react/dist/css/fabric.min.css";
//import './styles.less';
import * as React from "react";
import * as ReactDOM from "react-dom";
//import { useContext } from 'react';
import { AppContainer } from "react-hot-loader";
import { initializeIcons } from "office-ui-fabric-react/lib/Icons";
import App from "./components/AppDialogFn";
import { DialogType, LimaTitleActPar } from "./limaDialogCommon";
//import { log, tag } from "missionlog";
import limaDialogStore from "../store/limaDialogStore";
import { ActParaItemOK } from "../api/schema/getActsParaResp";
import {
  DIALOGMESSAGETYPE_ENUM,
  DialogParentMessage,
  DIALOGSTATETYPE_ENUM,
  DIALOGTYPE_ENUM,
} from "../types/dialogData";

//log.init({ dialogActPar: "dialogActPar" });

//SETTING
initializeIcons();
let isOfficeInitialized = false;
let platform: Office.PlatformType | null = null;

const title = LimaTitleActPar;
const dialogType = DialogType.actPar;

const render = (Component) => {
  //log.info(tag.commDialogActPar, "Rendering")
  ReactDOM.render(
    <AppContainer>
      <Component title={title} isOfficeInitialized={isOfficeInitialized} dialogType={dialogType} />
    </AppContainer>,
    document.getElementById("container")
  );
};

render(App);

if ((module as any).hot) {
  (module as any).hot.accept("./components/AppDialogFn", () => {
    const NextApp = require("./components/AppDialogFn").default;
    render(NextApp);
  });
}

console.log(`Office.js isnot ready yet`);
void Office.onReady((info) => {
  // If needed, Office.js is ready to be called
  //    log.info(tag.dialogJudge, "office onReady");

  (window as any).Promise = OfficeExtension.Promise;

  if (info.host === Office.HostType.Excel) {
    // Do Excel-specific initialization (for example, make add-in task pane's
    // appearance compatible with Excel "green").
  }
  platform = info.platform;
  if (info.platform === Office.PlatformType.PC) {
    // Make minor layout changes in the task pane.
  }
  console.log(`Office.js is now ready in ${info.host} on ${info.platform}`);
  isOfficeInitialized = true;

  render(App);
}).then(function () {
  console.log(`onReady: register handler on data recived`);
  Office.context.ui.addHandlerAsync(Office.EventType.DialogParentMessageReceived, onMessageFromParent);
  console.log(`onReady: ask parent to get data`);
  if (platform == Office.PlatformType.OfficeOnline) {
    window.opener.postMessage(JSON.stringify(requestData()), "*");
  } else {
    Office.context.ui.messageParent(JSON.stringify(requestData()));
  }
  console.log(`onReady: finish`);
});

function requestData() {
  const request: DialogParentMessage = {
    dialogType: DIALOGTYPE_ENUM.ACTPAR,
    messageData: DIALOGSTATETYPE_ENUM.DATA_REQUESTED,
    messageType: DIALOGMESSAGETYPE_ENUM.STATE,
  };
  return request;
}

function onMessageFromParent(arg) {
  console.log("message", JSON.parse(arg.message));
  limaDialogStore.setDataJSON(JSON.parse(arg.message));
  const messageFromParent: ActParaItemOK = JSON.parse(arg.message);
  limaDialogStore.setActPar(messageFromParent);
  limaDialogStore.isLoading && limaDialogStore.setIsLoading(false);
}
