import { log } from "missionlog";
import React = require("react");
import { FunctionComponent, useEffect, useState } from "react";
import { urlGetTaskHist } from "../../../../../api/apilinks";
import { httpGetAuth, HttpResponse } from "../../../../../api/httpAPI";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import limaLoadeStore from "../../../../../store/limaLoaderStore";
import limaStore from "../../../../../store/limaStore";
import { ItemHistoryItem, TaskHistory } from "../../../../../types/TaskHistory";
import { useQuery } from "react-query";
import { LimaLoadingComponnet } from "../../../../../limaComponents/LimaLoadingComponnet";
import { List } from "@fluentui/react";
import { mergeStyleSets, Stack, Text } from "office-ui-fabric-react";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import limaUsersStore from "../../../../../store/limaUsersStore";

interface TaskHistoryComponentProps {
  taskId: string;
}

const classNames = mergeStyleSets({
  wrapper: {
    marginTop: 2,
  },
  heading: {
    fontWeight: 500,
  },
  lines: {
    marginLeft: 5,
  },
});

const TaskHistoryComponent: FunctionComponent<TaskHistoryComponentProps> = ({ taskId }: TaskHistoryComponentProps) => {
  const [taskhistory, setTaskhistory] = useState<TaskHistory[]>([]);
  const { isLoading, error, refetch } = useQuery(["taskhistory", taskId], () => fetchHistory(taskId), {
    enabled: false,
    onSuccess: (data) => {
      log.info(limaLogTag.TaskHistoryComponent, "useQuery get message ", data);
      setTaskhistory(data);
    },
  });
  const { t } = useTranslation();

  useEffect(() => {
    log.info(limaLogTag.TaskHistoryComponent, "mouted for task history item: ", taskId);
    setTaskhistory([]);
    void refetch();
  }, [taskId]);

  if (isLoading) {
    return LimaLoadingComponnet(3);
  }

  if (error) {
    log.error(limaLogTag.TaskHistoryComponent, "loaded error: ", error);
    return <>Error Getting data </>;
  }

  const RenderDataChange = (itemChange: ItemHistoryItem): JSX.Element => {
    if (itemChange.param == "taskAssignedToKey") {
      return (
        <>
          <Text variant="small">{limaUsersStore.getUserName(itemChange.originValue)}</Text>
          <Text variant="small">{`->`}</Text>
          <Text variant="small">{limaUsersStore.getUserName(itemChange.newValue)}</Text>
        </>
      );
    } else if (itemChange.param == "taskDueDate") {
      return (
        <>
          <Text variant="small">
            <Moment format={limaStore.userDateFormat}>{itemChange.originValue}</Moment>
          </Text>
          <Text variant="small">{`->`}</Text>
          <Text variant="small">
            <Moment format={limaStore.userDateFormat}>{itemChange.newValue}</Moment>
          </Text>
        </>
      );
    } else {
      return (
        <>
          <Text variant="small">{itemChange.originValue}</Text>
          <Text variant="small">{`->`}</Text>
          <Text variant="small">{itemChange.newValue}</Text>
        </>
      );
    }
  };

  const RenderChangItem = (itemChange: ItemHistoryItem) => {
    return (
      <Stack horizontal>
        <Stack.Item>
          <Text variant="small">{t("tptasks:taskedit.taskitem.history.param_" + itemChange.param)}: </Text>
        </Stack.Item>
        {RenderDataChange(itemChange)}
      </Stack>
    );
  };

  const onRenderCell = (item: TaskHistory): JSX.Element => {
    return (
      <Stack className={classNames.wrapper}>
        <Stack.Item className={classNames.heading}>
          <Text variant="smallPlus">
            {t("tptasks:taskedit.taskitem.history.changeonLbl")}{" "}
            <Moment format={limaStore.userDateTimeFormat}>{item.cTimeStamp}</Moment>
          </Text>
        </Stack.Item>
        {item.changes &&
          item.changes.map(
            (itemChanges: ItemHistoryItem, index: number): JSX.Element => (
              <Stack.Item key={`_${item._key}_${index}`} className={classNames.lines}>
                {RenderChangItem(itemChanges)}
              </Stack.Item>
            )
          )}
      </Stack>
    );
  };

  return (
    <>
      <List items={taskhistory} onRenderCell={onRenderCell} />
    </>
  );
};

export default TaskHistoryComponent;

//----------------------
//   REACT QUERY
//----------------------

const fetchHistory = async (taskId: string): Promise<TaskHistory[]> => {
  log.info(limaLogTag.TaskHistoryComponent, "fetching data taskHistory");
  const loaderID = limaLoadeStore.add("Getting taskHistory from DB");
  try {
    const response: HttpResponse<TaskHistory[]> = await httpGetAuth(urlGetTaskHist(taskId), loaderID.ac);
    log.info(limaLogTag.TaskHistoryComponent, "fetching data taskHistory response", response);
    if (!response.ok) {
      throw response;
    }
    if (response.parsedBody !== undefined) {
      if (Array.isArray(response.parsedBody))
        log.info(limaLogTag.TaskHistoryComponent, "fetching data taskHistory response.parsedBody", response.parsedBody);
      //response.parsedBody.forEach((item:any)=>assertIsDB_Survey(item))
      return response.parsedBody;
    }
    throw new Error("no data");
  } catch (error) {
    throw new Error(error);
  } finally {
    limaLoadeStore.remove(loaderID.k);
  }
};
