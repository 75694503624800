import * as React from "react";
import { FunctionComponent } from "react";

import Progress from "./ProgressFn";
import ParamShow from "./ParamShowFn";
import Header from "./HeaderFn";
import { DialogType, LimaLogoDialog } from "../limaDialogCommon";
import InfoMain from "./infoDialog/InfoMain";
import ActParHolder from "./actPar/ActParHolder"; //TODO: correct back
import JudgeHolder from "./judge/JudgeHolder";
import TaskHistoryHolder from "./taskHistory/TaskHistoryHolder";
import limaDialogStore from "../../store/limaDialogStore";
import { observer } from "mobx-react-lite";
import LoginHolder from "./login/LoginHolder";
import { LIMA_TEXTNOTICETYPE, LimaNotice } from "../../limaComponents/LimaNotice";
//import limaStore from "../../store/limaStore";

type AppProps = {
  title: string;
  isOfficeInitialized: boolean;
  dialogType: DialogType;
};

const App: FunctionComponent<AppProps> = ({ title, isOfficeInitialized, dialogType }: AppProps) => {
  window.console.log("App.......");
  if (!isOfficeInitialized) {
    window.console.log("isOfficeInitialized not initialiyerd ", dialogType);
    return <Progress title={title} logo={LimaLogoDialog} message="Please sideload your addin to see app body." />;
  } else {
    window.console.log("isOfficeInitialized is initialiyerd ", dialogType);
  }
  window.console.log("this is ", dialogType);

  //let DialogContent;

  const DialogContent = observer((): JSX.Element => {
    window.console.log("dilalog dialog content observer");
    if (limaDialogStore.isLoading == true) {
      window.console.log("is loading");
      return <LimaNotice noticeType={LIMA_TEXTNOTICETYPE.INFO}>IS LOADING</LimaNotice>;
    }

    if (dialogType == DialogType.actPar) {
      window.console.log("ActParHolder");
      return <ActParHolder />;
    } else if (dialogType == DialogType.judge) {
      window.console.log("JudgeHolder");
      return <JudgeHolder />;
    } else if (dialogType == DialogType.taskhistory) {
      window.console.log("TaskHistoryHolder");
      return <TaskHistoryHolder />;
    } else if (dialogType == DialogType.login) {
      window.console.log("dilalog login holder");
      return <LoginHolder />;
    } else {
      window.console.log("InfoMain holder");
      return <InfoMain />;
    }
  });

  return (
    <div className="ms-welcome">
      {dialogType !== DialogType.login && <Header title={title} logo={LimaLogoDialog} />}
      {dialogType !== DialogType.login && <ParamShow message="Params from Office " />}
      <DialogContent />
    </div>
  );
};

export default App;
