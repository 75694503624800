import { log, tag } from "missionlog";
import { action, makeObservable, observable } from "mobx";
import { urlGetActSigns, urlGetAdviceActSinglePara3 } from "../api/apilinks";
import { httpGet, httpPost, HttpResponse } from "../api/httpAPI";
import { ActParaItemOK, ActPar_multi, ActPar_multiRes, ActPar_multiRes_item } from "../api/schema/getActsParaResp";
import { advice_textsearch, advice_textsearchDEF } from "../types/advice";
import limaLoadeStore from "./limaLoaderStore";
import limaTagsStore from "./limaTagsStore";

log.init({ LimaActParStore: "LimaActParStore" });

class LimaActParStore {
  isInlineSearchActive = false;
  actId = "";
  parId = "";
  list: ActParaItemOK[] = [];
  fullList: ActParaItemOK[] = [];
  loadingData = false;
  totalItemCount = 0;
  actSignsList: string[] = [];
  actSignListString = "";
  searchActPar: advice_textsearch[] = [advice_textsearchDEF];

  constructor() {
    makeObservable(this, {
      actId: observable,
      parId: observable,
      isInlineSearchActive: observable,
      list: observable,
      loadingData: observable,
      totalItemCount: observable,
      actSignsList: observable,

      //setActVal: action,
      setLoadingData: action,
      getActaParaFromDB: action,
      setInlinSearch: action,
      setList: action,
      onFilterChanged: action,
      setTotalItemCount: action,
      getActSigns: action,
      setSearchActPar: action,
    });
  }

  async getActaParaFromDB() {
    if (this.searchActPar.length <= 0) {
      log.warn(tag.LimaJudgeStore, `getActaParaFromDB: no searchActPar`);
      return;
    }
    this.setLoadingData(true);
    let listData: HttpResponse<ActPar_multiRes[]>;
    const callParams: ActPar_multi = { actparIds: this.searchActPar };
    const loaderID = limaLoadeStore.add("get actaParasFromDB");
    try {
      //this.setList([])
      //this.setFullList([])
      this.setTotalItemCount(0);
      log.info(tag.LimaActParStore, `getActaParaFromDB: searchActPar`, this.searchActPar);
      // listData = await httpGet<ActParaItemOK[]>(
      //   //urlGetAdviceJudgeSinglePara(this.actId, this.parId)
      //   urlGetAdviceActSinglePara2(this.searchActPar[0].actID, this.searchActPar[0].parID)
      // );
      listData = await httpPost(urlGetAdviceActSinglePara3(), callParams, loaderID.ac);

      log.info(tag.LimaActParStore, "getActaParaFromDB: asketo to get something");
      if (listData.parsedBody !== undefined) {
        log.info(tag.LimaActParStore, "getActaParaFromDB: parsedBody is not null");
        const list: ActParaItemOK[] = [];
        listData.parsedBody.forEach((item: ActPar_multiRes) => {
          item.item.forEach((singlePar: ActPar_multiRes_item) => {
            list.push({
              actid: item.act.act.actID,
              actname: item.act.act.fullname,
              actyear: item.act.act.year.toString(),
              parid: item.act.parID,
              partext: singlePar.partext,
              supparid: "",
            });
          });
        });

        this.setList(list);
        this.setFullList(list);
        this.setTotalItemCount(list.length);
        log.info(tag.LimaActParStore, "getActaParaFromDB: load finish", list);
      } else {
        this.clearLists();
      }
      this.setLoadingData(false);
    } catch (response) {
      log.error(tag.LimaActParStore, "getActaParaFromDB Error call get User", response);
      this.clearLists();
      this.setLoadingData(false);
    } finally {
      limaLoadeStore.remove(loaderID.k);
    }
  }

  setLoadingData(trueOrfalse: boolean) {
    this.loadingData = trueOrfalse;
  }

  setInlinSearch(val: boolean) {
    this.isInlineSearchActive = val;
  }

  setList(newList: ActParaItemOK[]) {
    log.info(tag.LimaActParStore, "setList: setting  finish", newList);
    this.list = newList;
  }

  setTotalItemCount(newCount: number) {
    this.totalItemCount = newCount;
  }

  setFullList(newList: ActParaItemOK[]) {
    this.fullList = newList;
  }

  clearLists() {
    this.setFullList([]);
    this.setTotalItemCount(0);
    this.setList([]);
  }

  onFilterChanged = (_: never, text: string): void => {
    log.info(tag.LimaActParStore, "onFilterChanged: filter change", text.toLowerCase());
    this.setList(
      this.fullList.filter(function (item) {
        if (item.partext === undefined || item.partext == null || item.partext == "") return false;
        else if (item.partext.toLowerCase().indexOf(text.toLowerCase()) >= 0) return true;
        return false;
      })
    );
  };

  setActSignsList(items: string[]) {
    this.actSignsList = items;
  }

  async getActSigns() {
    this.setLoadingData(true);
    let listData: HttpResponse<any>; //TODO: convert to type
    const loaderID = limaLoadeStore.add("get Act signs");
    try {
      this.setActSignsList([]);
      listData = await httpGet(urlGetActSigns(), loaderID.ac);
      log.info(tag.LimaJudgeStore, "getActSigns: asketo to get something");
      if (listData.parsedBody !== undefined) {
        log.info(tag.LimaJudgeStore, "getActSigns: parsedBody is not null");
        this.setActSignsList(listData.parsedBody.signs);
        limaTagsStore.signAlts = listData.parsedBody.signsStructMain;
        this.actSignListString = "";
        listData.parsedBody.signs.forEach((item) => (this.actSignListString += "|" + String(item)));
        this.actSignListString = this.actSignListString.substring(1);

        log.info(tag.LimaJudgeStore, "getActSigns: load finish", listData);
      }
      this.setLoadingData(false);
    } catch (response) {
      log.error(tag.LimaJudgeStore, "getActSigns Error call get User", response);
      this.setLoadingData(false);
    } finally {
      limaLoadeStore.remove(loaderID.k);
    }
  }

  setSearchActPar(items: advice_textsearch[]) {
    this.searchActPar = items;
  }

  addSearchActPat(item: advice_textsearch) {
    //this.searchActPar.push(item)
    this.searchActPar.findIndex((arrayItem: advice_textsearch) => arrayItem === item) === -1 &&
      this.searchActPar.push(item);
  }

  clearSearchActPar() {
    this.searchActPar = [];
  }

  onLogoutClear() {
    this.isInlineSearchActive = false;
    this.actId = "";
    this.parId = "";
    this.list = [];
    this.fullList = [];
    this.loadingData = false;
    this.totalItemCount = 0;
    this.actSignsList = [];
    this.actSignListString = "";
    this.searchActPar = [advice_textsearchDEF];
  }
}

const limaActParStore = new LimaActParStore();
export default limaActParStore;
