import * as React from "react";
import { FunctionComponent } from "react";

import { log, tag } from "missionlog";
import { histParaTxt, taskHistoryItem } from "../../../api/schema/tasks/taskHistoryRes";
import { DefaultPalette, IStackStyles, IStackTokens, Stack } from "office-ui-fabric-react";
import HistParaItemTextRow from "./HistParaItemParaTextRow";

log.init({ TaskHistorySingleItem: "TaskHistorySingleItem" });

export interface TaskHistorySingleItemProps {
  itemData: taskHistoryItem;
}
//({ logo, message, title }: HistItemProps)

const containerStackTokens: IStackTokens = { childrenGap: 5 };
const stackStyles: IStackStyles = {
  root: {
    background: DefaultPalette.themeTertiary
  }
};

const TaskHistorySingleItem: FunctionComponent<TaskHistorySingleItemProps> = ({
  itemData
}: TaskHistorySingleItemProps) => {
  log.info(tag.TaskHistorySingleItem, "TaskHistorySingleItem: Mounted");

  return (
    <Stack tokens={containerStackTokens}>
      <Stack.Item>
        <b>{itemData.taskDataName}</b>
      </Stack.Item>
      <Stack.Item>Change date: {itemData.taskChangeDate}</Stack.Item>
      <Stack.Item>Change in: {itemData.taskChangeParam}</Stack.Item>
      <Stack horizontal styles={stackStyles}>
        <Stack.Item>{itemData.taskChangeOldVal}</Stack.Item>
        <Stack.Item>{"=>"}</Stack.Item>
        <Stack.Item>{itemData.taskChangeNewVal}</Stack.Item>
      </Stack>
      <Stack.Item>
        {itemData.paras.map((item: histParaTxt) => {
          <HistParaItemTextRow key={"paraHist_" + item.paraId} showChanges={true} paraHistoryRow={item.parasJson} />;
        })}
      </Stack.Item>
    </Stack>
  );
};

export default TaskHistorySingleItem;
