import { getFocusStyle, getTheme, ITheme, mergeStyleSets, Stack, Text } from "@fluentui/react";
import { FunctionComponent } from "react";
import React = require("react");
import Moment from "react-moment";
import { LimaNameInitals } from "../../../../limaComponents/LimaNameInitals";
import limaStore from "../../../../store/limaStore";
import limaUsersStore from "../../../../store/limaUsersStore";
import { LimaMessage } from "../../../../types/Message";

interface MessageItemProps {
  message: LimaMessage;
}

const theme: ITheme = getTheme();
const { palette, semanticColors } = theme;

const classNames = mergeStyleSets({
  stackWrapper: [
    getFocusStyle(theme, { inset: -1 }),
    {
      flexGrow: 1,
      display: "flex",
      paddingRight: 30,
      minHeight: 30,
      paddingLeft: 10,
      boxSizing: "border-box",
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      verticalAlign: "medium",
      selectors: {
        "&:hover": {
          background: palette.neutralLight,
          cursor: "pointer",
        },
      },
    },
  ],
  myMessage: {
    textAlign: "end",
  },
  stackTitle: {},
});

const MessageItem: FunctionComponent<MessageItemProps> = ({ message }: MessageItemProps) => {
  const messageStyle = (): string => {
    if (message.writerUserId == limaStore.userId) return `${classNames.stackWrapper} ${classNames.myMessage}`;
    else return `${classNames.stackWrapper}`;
  };
  return (
    <Stack className={messageStyle()}>
      <Stack.Item className={classNames.stackTitle}>
        <Text variant="smallPlus" nowrap block>
          <LimaNameInitals>{limaUsersStore.getUserName(message.writerUserId)}</LimaNameInitals>[
          <Moment format={limaStore.userDateTimeFormat}>{message.cTimeStamp}</Moment>]
        </Text>
      </Stack.Item>
      <Stack.Item>
        <Text variant="medium" nowrap block>
          {message.message}
        </Text>
      </Stack.Item>
    </Stack>
  );
};

export default MessageItem;
