/**
 * ErrorStore takes care abour manual covered errord
 *
 * @module LimaLogsStore
 *
 * @category mobx Store
 *
 * @version 0.1
 *
 * @author MS
 *
 * @example
 * ```
 * import limaLogsStore from "./limaLogsStore";
 *
 * ...
 * let loaderID = limaLoaderStore.add()
 * ...
 * limaLoaderStore.remove(loaderID)
 * ```
 */
import { action, makeObservable, observable } from "mobx";
import { log } from "missionlog";
import { limaLogTag } from "../limaCommon/limaLog";

export enum LimaLogStoreType {}
/**
 * ErrorStore takes care errors
 *
 * @class ErrorStore
 *
 */
export interface LogstoreListItem {
  key: string;
  title: string;
  msg: string;
  comp: string;
  type: ENUM_LogstoreLogType;
}

export enum ENUM_LogstoreLogType {
  INFO = "INFO",
  WARN = "WARN",
  ERROR = "ERROR",
}
export class LimaLogsStore {
  isErrorInList: boolean | undefined = false;
  errorsList: LogstoreListItem[] = [];

  constructor() {
    makeObservable(this, {
      isErrorInList: observable,

      add: action,
      remove: action,
      setIsErrorList: action,
    });
  }

  /**
   * Set isErrorInList param to true or folse this indicator is linked to frontend action
   * Its not commonly used, better is to use add and remove
   *
   * @param {boolean} isError param to set existence or not of error
   * @memberof limaLogsStore
   *
   */
  setIsErrorList(isError: boolean): void {
    this.isErrorInList = isError;
  }

  /**
   * Update list of queued items in error notifier
   * Its not commonly used, better is to use add and remove
   *
   * @param newList new list of errors items in queue
   * @memberof limaLogsStore
   */
  private setLogsList(newList: LogstoreListItem[]) {
    this.errorsList = newList;
    log.info(limaLogTag.LimaLogsStore, `setErrorList: list is now `, this.errorsList);
    log.info(
      limaLogTag.LimaLogsStore,
      `setErrorList: list tex is now [${this.errorsList.length}]`,
      this.errorsList.map((item: LogstoreListItem) => item.title).toString()
    );
    //console.log("new loaderList",this.loaderList)
    newList.length > 0 ? this.setIsErrorList(true) : this.setIsErrorList(false);
  }
  /**
   * Main function for adding logs into list
   * @param {string} text text of log
   * @param {string} msg message of log
   * @param {ENUM_LogstoreLogType} type type of log
   * @param {string} comp logging component
   * @param {string} key optional id
   * @returns {string} key under what was added to store
   * @memberof limaLogsStore
   * @example
   * ```
   * let errorID = limaLogsStore.add()
   *
   * or
   *
   * let errorID = limaLogsStore.add("Some error", "this is error problem", errir)
   *
   * or
   *
   * let errorID = limaLogsStore.add("THIS IS ERROR", "myKey")
   * //leter remove by loaderStore.remove(loaderID)
   * ```
   *
   */
  add(text: string, msg: string, type: ENUM_LogstoreLogType, comp?: string, key?: string): string {
    let outString = "";
    if (key !== undefined) outString = key;
    else {
      const inOptions = "abcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 10; i++) {
        outString += inOptions.charAt(Math.floor(Math.random() * inOptions.length));
      }
    }

    log.info(limaLogTag.LimaLogsStore, `add: added to list[${outString}]`);
    const newLog: LogstoreListItem = { key: outString, title: text || "", msg: msg, comp: comp, type: type };
    this.errorsList.push(newLog);
    this.setLogsList(this.errorsList);

    return outString;
  }

  /**
   * Main function for adding and removing list od loaders
   * @memberof limaLogsStore
   * @example
   * ```
   *  //prior define let logID = loadeStore.add()
   *  limaLogsStore.remove(logID)
   * ```
   *
   */
  remove(id: string): void {
    log.info(limaLogTag.LimaLogsStore, `remove: added from list[${id}]`);

    this.setLogsList(this.errorsList.filter((item) => item.key !== id));
  }

  /**
   * Cleanup on logout button
   *
   * @autor MS
   */
  onLogoutClear(): void {
    this.isErrorInList = false;
    this.errorsList = [];
  }
}

const limaLogsStore = new LimaLogsStore();
export default limaLogsStore;
