import { log } from "missionlog";
import { urlActaInsertDocVersion, urlCreateXcopyOnActa, urlCreateXcopyOnXcopy } from "../api/apilinks";
import { httpPostAuth, HttpResponse } from "../api/httpAPI";
import { xcopyNewOK } from "../api/schema/xcopyStoreNewResponse";
import limaLoadeStore from "../store/limaLoaderStore";
import limaStore, { OPENEDFILETYPE_ENUM } from "../store/limaStore";
import { LimaDocTypeENUM } from "../types/docxData";
import { XCOPY_TYPE } from "../types/Task";
import { xcopyHistory_null } from "../types/XcopyHistory";
import { InsertDocument_xcopy } from "./limaInsertDoc";
import { limaLogTag } from "./limaLog";
import { getFileAsyncInternal } from "./limaSaveDoc";

/**
 * Storing current docuemtn into xcopy
 * @param actaId optional actaID value, if not set try to get limaStoreActaId
 * @param xcopyId optional xcopyID value, if not set try to get limaStoreXcopyID
 *
 * @author MS
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const storeToXcopy = async (actaId?: string, xcopyId?: string, onBehalf?: string) => {
  if (actaId === undefined || actaId === null || actaId === "") {
    log.info(
      limaLogTag.LimaXcopyFile,
      `onStoreToXcopy: actaId request missing id - going to use actual [${actaId}]/ lismaStore acta[${limaStore.actaId}]`
    );
    actaId = limaStore.actaId;
  }
  if (xcopyId === undefined || xcopyId === null || xcopyId === "") {
    log.info(
      limaLogTag.LimaXcopyFile,
      `onStoreToXcopy: xcopyId request missing id - going to use actual [${xcopyId}]/ lismaStore acta[${limaStore.xcopyId}]`
    );
    xcopyId = limaStore.xcopyId;
  }
  log.info(limaLogTag.LimaXcopyFile, `onStoreToXcopy: start xcopy for xcopy [${xcopyId}]/ acta[${actaId}]`);
  if (
    // actaId === undefined ||
    // actaId === null ||
    // actaId === "" ||
    xcopyId === undefined ||
    xcopyId === null ||
    xcopyId === ""
  ) {
    log.error(limaLogTag.LimaXcopyFile, `onStoreToXcopy: missing xcopy ID [${xcopyId}]/ acta[${actaId}]`);
    return;
  }

  const loaderID = limaLoadeStore.add(`Storing xcopy to server`);

  // log.info(
  //   limaLogTag.LimaXcopyFile,
  //   `onStoreToXcopy: storing xcopy for xcopy [${limaStore.xcopyId}]/ acta[${limaStore.actaId}]`
  // );

  await getFileAsyncInternal(
    actaId,
    xcopyId,
    LimaDocTypeENUM.XCOPY,
    xcopyId,
    LimaDocTypeENUM.XCOPY,
    "",
    limaStore.userId,
    xcopyHistory_null(), //version
    () => {
      limaLoadeStore.remove(loaderID.k);
    },
    () => {
      log.error(limaLogTag.LimaXcopyFile, "onStoreToXcopy: error on store");
    },
    xcopyId,
    onBehalf
  );
};

/**
 * Openning xcopy to current docuemtn
 * @param actaId acta ID
 * @param xcopyId Xcopy ID
 * @param userId id of user
 * @param timestamp timestamp if used
 *
 * @author MS
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getActaXcopyFile = async (actaId: string, xcopyId: string, userId?: string, timestamp?: string) => {
  //Obosliite not zet refactored
  if (actaId === undefined || (actaId === null && actaId === "")) {
    log.error(limaLogTag.LimaXcopyFile, `onGetActaXcopyFile: missing actaId `, actaId);
    return;
  }
  if (xcopyId === undefined || xcopyId === null || xcopyId === "") {
    log.error(limaLogTag.LimaXcopyFile, `onGetActaXcopyFile: missing xcopyID `, xcopyId);
    return;
  }

  //this.setProgress(true);
  const loaderID = limaLoadeStore.add(`Call get xcopy from server`);
  log.info(limaLogTag.LimaXcopyFile, `onGetActaXcopyFile: Call to get getting xcopy [${xcopyId}] for acta[${actaId}]`);
  limaStore.setDocxIsLoading(true);
  try {
    await InsertDocument_xcopy(
      xcopyId,
      urlActaInsertDocVersion(actaId, xcopyId, userId !== undefined ? userId : limaStore.userId, timestamp, true),
      loaderID
    ).then(() => {
      log.info(limaLogTag.LimaXcopyFile, `onGetActaXcopyFile: Finish inserting [${xcopyId}] for acta[${actaId}]`);
      limaStore.setActa(actaId);
      limaStore.setXcopyId(xcopyId);
      limaStore.setActiveOpenType(OPENEDFILETYPE_ENUM.COPY);
    });
  } catch (error) {
    log.error(limaLogTag.LimaXcopyFile, "onGetActaXcopyFile: getting xcopy error", error);
  } finally {
    //this.setProgress(false);
    limaLoadeStore.remove(loaderID.k);
    limaStore.setDocxIsLoading(false);
  }
};

/**
 * create new actaCopy
 * @param actaId ID of acta
 * @param paraIds list of included paraIds
 * @param commentIds list of included commentsIds
 * @returns new xcopy id
 *
 * @author MS
 */
export const createActaXCopy = async (
  actaId: string,
  paraIds: string[],
  commentIds: string[],
  xcopytype: XCOPY_TYPE
): Promise<string | null> => {
  if (actaId === undefined || actaId === "") {
    log.warn(limaLogTag.LimaXcopyFile, "onCreateActaVersion: looks like we dont have any actaID please open doc first");
    return null;
  }

  const urlCall = urlCreateXcopyOnActa(actaId);
  // this.setProgress(true);
  const loaderID = limaLoadeStore.add(`Call create acta xcopy`);
  let data: HttpResponse<xcopyNewOK>; //TODO: change ANY
  log.info(limaLogTag.LimaXcopyFile, "createActaVersion: start");
  try {
    const dataReply = await httpPostAuth<xcopyNewOK>(
      urlCall,
      {
        // userId: limaStore.userId,
        paraIds: paraIds,
        commentIds: commentIds,
        xcopytype: xcopytype,
      },
      loaderID.ac
    );
    if ("parsedBody" in dataReply) {
      log.info(limaLogTag.LimaXcopyFile, "looks like we have new xcopy id", dataReply.parsedBody.id);

      if (dataReply.parsedBody.id != null && dataReply.parsedBody.id != "") {
        return dataReply.parsedBody.id;
        // // this.setIsVersionEnabled(true);
        // //this.setTaskData(JSON.stringify(taskDataJSON));
        // this.setTaskData(taskDataJSON);
        // await this.onUpdate(); //TODO: check if its ok..
      }

      return null;
    } else {
      return null;
    }
    //dataReply.parsedBody.forEach((item) => { log.info(tag.taskEdit, item) });
  } catch (response) {
    log.error(limaLogTag.LimaXcopyFile, "Error", data);

    return null;
  } finally {
    limaLoadeStore.remove(loaderID.k);
  }
};

/**
 * create new actaCopy
 * @param actaId ID of acta
 * @param paraIds list of included paraIds
 * @param commentIds list of included commentsIds
 * @returns new xcopy id
 *
 * @author MS
 */
export const createXcopyFromXCopy = async (
  actaId: string,
  xcopyId: string,
  paraIds: string[],
  commentIds: string[],
  xcopytype: XCOPY_TYPE
): Promise<string | null> => {
  if (xcopyId === undefined || xcopyId === "" || actaId === undefined || actaId === "") {
    log.warn(
      limaLogTag.LimaXcopyFile,
      "createXcopyFromXCopy: looks like we dont have any xcopyId,actaId please open doc first"
    );
    return null;
  }

  const urlCall = urlCreateXcopyOnXcopy(actaId, xcopyId);
  // this.setProgress(true);
  const loaderID = limaLoadeStore.add(`Call create acta xcopy`);
  let data: HttpResponse<xcopyNewOK>; //TODO: change ANY
  log.info(limaLogTag.LimaXcopyFile, "createActaVersion: start");
  try {
    const dataReply = await httpPostAuth<xcopyNewOK>(
      urlCall,
      {
        // userId: limaStore.userId,
        paraIds: paraIds,
        commentIds: commentIds,
        xcopytype: xcopytype,
      },
      loaderID.ac
    );
    if ("parsedBody" in dataReply) {
      log.info(limaLogTag.LimaXcopyFile, "looks like we have new xcopy id", dataReply.parsedBody.id);

      if (dataReply.parsedBody.id != null && dataReply.parsedBody.id != "") {
        return dataReply.parsedBody.id;
        // // this.setIsVersionEnabled(true);
        // //this.setTaskData(JSON.stringify(taskDataJSON));
        // this.setTaskData(taskDataJSON);
        // await this.onUpdate(); //TODO: check if its ok..
      }

      return null;
    } else {
      return null;
    }
    //dataReply.parsedBody.forEach((item) => { log.info(tag.taskEdit, item) });
  } catch (response) {
    log.error(limaLogTag.LimaXcopyFile, "Error", data);

    return null;
  } finally {
    limaLoadeStore.remove(loaderID.k);
  }
};
