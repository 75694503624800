import { log } from "missionlog";
import limaStore from "../store/limaStore";
import { TaskData, XCOPY_TYPE } from "../types/Task";
import {
  convert_Task_withActaXcopyParasCommentsTasksMessagesAttachements2Task_withActaXcopy,
  Task_withActaXcopy,
} from "../types/Task_withActaXcopy";
import { User2Task_withActaXcopyParasCommentsTasksMessagesAttachements } from "../types/User2Task_withActaXcopy";
import { limaLogTag } from "./limaLog";

export const isTaskLinked = (taskData: TaskData): boolean => {
  log.info(limaLogTag.LimaTaskHelpers, "isTaskLinked: taskdata", taskData);
  if (taskData?.actaKey !== undefined && taskData?.actaKey !== null && taskData?.actaKey !== "") {
    log.info(limaLogTag.LimaTaskHelpers, "isTaskLinked: taskdata - has actaKey");
    if (taskData?.xcopytype === XCOPY_TYPE.FULLXCOPY || taskData?.xcopytype === XCOPY_TYPE.TRIMXCOPY) {
      if (taskData?.xcopyKey !== null && taskData?.xcopyKey !== "") {
        return true;
      } else {
        return false;
      }
    } else if (taskData?.xcopytype === XCOPY_TYPE.MAINXCOPY) {
      if (taskData?.xcopyKey !== null && taskData?.xcopyKey !== "") {
        return true;
      } else {
        return false;
      }
    }
  }
  log.info(limaLogTag.LimaTaskHelpers, "isTaskLinked: taskdata - FALSE");
  return false;
};

export const taskLinkedItems = (taskData: TaskData): number => {
  let linkedItems = 0;

  log.info(limaLogTag.LimaTaskHelpers, "taskLinkedItems: taskdata", taskData);
  if (taskData?.paraIds !== undefined) {
    linkedItems += taskData.paraIds.length;
  }
  if (taskData?.commentIds !== undefined) {
    linkedItems += taskData.commentIds.length;
  }
  log.info(limaLogTag.LimaTaskHelpers, "taskLinkedItems: count", linkedItems);
  return linkedItems;
};

export const taskLinkedTo = (taskData: TaskData): XCOPY_TYPE => {
  log.info(limaLogTag.LimaTaskHelpers, "taskLinkedTo: taskdata", taskData);
  if (taskData?.xcopytype !== undefined) {
    return taskData.xcopytype;
  }
  return XCOPY_TYPE.NOXCOPY;
};

export const isTaskDataLinkedDocOpen = (taskData: TaskData, _openActaId: string, openXcopyId: string): boolean => {
  // if (taskData?.actaKey === openActaId && taskData?.xcopyKey === openXcopyId) {
  if (taskData?.xcopyKey === openXcopyId) {
    return true;
  }
  return false;
};

export const isTaskCreator = (taskWithXcopy: Task_withActaXcopy): boolean => {
  if (
    taskWithXcopy === null ||
    taskWithXcopy.cIssuedBy === null ||
    taskWithXcopy.cIssuedBy === "" ||
    taskWithXcopy.cIssuedBy === limaStore.userId
  ) {
    log.info(limaLogTag.TaskItemEditV2, "UEF []: SET CREATOR: TRUE", taskWithXcopy);
    return true;
  } else {
    log.info(limaLogTag.TaskItemEditV2, "UEF taskWithActaXcopy: SET CREATOR: FALSE", taskWithXcopy);
    return false;
  }
};

export const isTaskCreatorU2T = (taskFull: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements): boolean => {
  return isTaskCreator(
    convert_Task_withActaXcopyParasCommentsTasksMessagesAttachements2Task_withActaXcopy(
      taskFull.task_withActaXcopyParasCommentsTasksMessagesAttachements
    )
  );
};
