import Keycloak, { KeycloakConfig } from "keycloak-js";

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
//const keycloak = new Keycloak();
// const keycloak = new Keycloak({
//   url: "http://localhost:8180/auth",
//   realm: "master",
//   clientId: "limacta-client",
// });

const keycloakConfig: KeycloakConfig = {
  url:
    process.env.REACT_APP_KEYCLOAK_URL !== undefined
      ? process.env.REACT_APP_KEYCLOAK_URL
      : "http://localhost:8180/auth", //"https://dev-auth.stimvia.com/auth", //'https://18.220.93.81:8443/auth',
  realm: process.env.REACT_APP_KEYCLOAK_REALM !== undefined ? process.env.REACT_APP_KEYCLOAK_REALM : "master",
  clientId:
    process.env.REACT_APP_KEYCLOAK_CLIENT !== undefined ? process.env.REACT_APP_KEYCLOAK_CLIENT : "limacta-client",
};

// onLoad: "login-required",
// checkLoginIframe: false,
window.console.log(">>>keycloak>>", "KEYCLOACK SETTING", JSON.stringify(keycloakConfig));

const keycloak = new Keycloak(keycloakConfig);

export default keycloak;
