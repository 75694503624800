const Routes = {
  LoginProcessing: "loginProcessingRt",

  HomeCompLic: "/menuHomeCompLicRt",
  HomeV2Route: "/menuHomeRt2",
  ToolsV2Route: "/menuToolsRt",
  ActaV2Route: "/menuActaRt",
  ActaDetailRoute: "/actaDetailRt/:fileitemid/:type?",
  XcopyDetailRoute: "/xcopyDetailRt/:xcopyid",
  ActaDetailRoute_base: "/actaDetailRt/",
  UserInviteRoute: "/toolUserInviteRt",
  UserInitateFinishRoute: "/subtoolUserInviteFinihsRt",
  UserInvitionsRoute: "/toolUserInvitationsRt",
  UserCreateUserFinishRoute: "/subtoolUserCreateFinihsRt",
  UserListRoute: "/toolUserListRt",
  UserLicencesRoute: "/toolUserLicencesRt",
  UserCompaniesRoute: "/toolUserCompaniesRt",
  FileSaveNewVersionRoute: "/fileSaveNewVersionRt",
  FileSaveNewCopyRoute: "/fileSaveNewCopyRt",
  FileSaveExitCopyRoute: "/fileSaveExitCopyRt/:xcopyid",
  FileSaveExitCopyRoute_base: "/fileSaveExitCopyRt/",
  FileSaveFinishRoute: "/fileSaveFinishRt/:documenttype/:storetype",
  FileSaveFinishRoute_base: "/fileSaveFinishRt/",
  FileAddNewActaRoute: "/fileAddNewActaRt",
  LimactaTimerRoute: "/limactaTimerRt",
  LimactaTimersReportRoute: "/limactaTimersReportRt",
  ToolsTaskListRoute: "/limaTaskListV4Rt",
  ToolsTaskAddRoute: "/limaTaskAddV4Rt",
  ShowMenuHistoryRoutev4: "/ShowMenuHistoryv4Rt",
  ToolsLimaEqualChangeRoute: "/LimaEqualChangeRt",
  ToolsTestingCallPageRoute: "/LimaTestCallsPageRt",
  ToolsTestingErrsPageRoute: "/LimaTestErrsPageRt",
  ToolsTestingDataPageRoute: "/LimaTestDataPageRt",

  OLD: "-----OLD-------",

  FileMenuSub: "/fileMenuRt",
  FileMenuOpenRoute: "/fileMenuOpenRt",
  FileMenuImportRoute: "/fileMenuImportRt",
  FileMenuSaveRoute: "/fileMenuSaveRt/:savetype",
  FileMenuSaveRouteBase: "/fileMenuSaveRt/",
  FileMenuDataRoute: "/fileMenuDataRt",
  FileMenuHome: "/fileMenuHomeRt",
  FileMenuAbout: "/fileMenuAboutRt",

  UserMenuSub: "/userMenuRt",
  UserMenuAddRoute: "/userMenuAddRt",
  UserMenuListRoute: "/userMenuListRt",
  UserMenuLinkingRoute: "/userMenuLinkingRt",

  EditMenuSub: "/editMenuRt",
  EditMenuHelperRoute: "/editMenuHelperRt",
  EditMenuTaskAddRoute: "/editMenuTaskAddRt",
  // EditMenuTasksRoute: "editMenuTasksRt/:taskid",
  EditMenuTasks2Route: "/editMenuTasks2Rt/:taskid",
  EditMenuTasksRoute_base: "/editMenuTasksRt/",
  EditMenuTasks2Route_base: "/editMenuTasks2Rt/",
  EditMenuNotesRoute: "/editMenuNotesRt",
  EditMenuSearchRoute: "/editMenuSearchRt",
  EditMenuStatesPage: "/editMenuStatesPage",

  ShowMenuSub: "/showMenuRt",
  // ShowMenuHistoryRoute: "showMenuHistoryRt",
  ShowMenuHistoryRoute2: "/showMenuHistoryRt2",
  // ShowMenuAccessRoute: "showMenuAccessRt",
  // ShowMenuAccessRoute2: "showMenuAccessRt2",
  ShowMenuAccess: "/showMenuAcceShowMenuAccessssRt2",

  // ShowMenuTasksVersions: "showMenuTasksVersions",
  ShowMenuHelper: "/showMenuHelperRt",

  AdviceMenuSub: "/adviceMenuRt",
  AdviceMenuAdviceActSearchRoute: "/adviceMenuAdviceActSearchRt",
  AdviceMenuAdviceJudgeSearchRoute: "/adviceMenuAdviceJudgeSearchRt",
  AdviceMenuAdviceKeywordsRoute: "/adviceMenuAdviceKeywordsSearchRt",
  AdviceMenuAdviceCheckDocRoute: "/adviceMenuAdviceCheckDocRt",
};

export default Routes;
