//export const version = "0.7.6";
import { VERSION } from "../version";

export const limaFEversion = VERSION;

export const DEF_parBkmID = "par";
export const DEF_comBkmID = "com";
export const DEF_delBkmID = "del";
export const DEF_addBkmID = "add";
export const DEF_ccPrefix = "_cc_";

export const limaDocIDLabel = "limactaDocID";
export const limaDocTypeLabel = "limactaDocType";
export const limaDocNameLabel = "limactaDocName";

export const defDialogWidth = 75;
export const defDialogHeight = 100;

export const defWelcomeLastFileLimit = 7;
