import * as React from "react";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { Panel, PanelType } from "@fluentui/react/lib/Panel";
import { useBoolean, useId } from "@fluentui/react-hooks";
// import { Task_withActaXcopy } from "../../../types/Task_withActaXcopy";
import { FunctionComponent, useEffect, useState } from "react";
import { log } from "missionlog";
import { limaLogTag } from "../../../limaCommon/limaLog";
import {
  getTheme,
  ITheme,
  ITooltipHostStyles,
  mergeStyleSets,
  MessageBar,
  MessageBarType,
  ProgressIndicator,
  // Spinner,
  Stack,
  TooltipHost,
} from "@fluentui/react";
import limaLoadeStore from "../../../store/limaLoaderStore";
import { observer } from "mobx-react-lite";
import HistParaItemPage from "../../Menu/Show/history/HistParaItemPage";
import { LimaIconEditPanelData } from "../../../assets/icons/LimaIconEditPanelData";
import { LimaIconEditPanelHistory } from "../../../assets/icons/LimaIconEditPanelHistory";
import { LimaIconDismiss } from "../../../assets/icons/LimaIconDismiss";

const buttonStyles = { root: { marginRight: 8 } };

interface TaskItemEditBaseV4Props {
  detail: React.ReactNode;
  open: boolean;
  panelName?: string;
  onSaveUpdateCallBack?: () => void;
  onDetialDismissCallBack: () => void;
  editDetailEnableStore: boolean;
  saveLbl?: string;
  cancelLbl?: string;
  hideFooter?: boolean;
}

const theme: ITheme = getTheme();
// const { palette, semanticColors, fonts } = theme;
const { fonts } = theme;

const classNames = mergeStyleSets({
  panelBase: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  panelBaseSpinner: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexGrow: 1,
  },
  panelHeaderWrapper: {
    padding: 6,
    paddingRight: 10,
    backgroundColor: "#e7e7e7",
    display: "flex",
    alignItems: "center",
  },
  panelHeaderTitle: [
    fonts.xLarge,
    {
      flexGrow: 1,
      marginLeft: 10,
    },
  ],
  panelHeaderActionIcon: {
    marginRight: 10,
    cursor: "pointer",
  },
  panelHeaderMessage: {
    minHeight: 32,
    backgroundColor: "#e7e7e7",
  },
  panelHeaderProgress: {
    minHeight: 18,
    backgroundColor: "#e7e7e7",
  },
});

const calloutProps = { gapSpace: 0 };
const hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block" } };

export const LimaEditItemBaseV4: FunctionComponent<TaskItemEditBaseV4Props> = (props: TaskItemEditBaseV4Props) => {
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
  const [paraHelperVisible, setParaHelperVisible] = useState<boolean>(false);
  const paraHelperId = useId("parahelp");
  const dismissId = useId("dismiss");
  const editDetailId = useId("detail");
  // const [storeFinish, setStoreFinish] = useState<boolean>(false);

  //--------------
  // REACTIONS
  //--------------
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    log.debug(limaLogTag.LimaEditItemBaseV4, "UEF change open", props.open);
    log.info(limaLogTag.LimaEditItemBaseV4, "UEF change open", props.open);
    if (isOpen === false && props.open == true) openPanel();
    if (isOpen === true && props.open == false) {
      dismissPanel();
      props.onDetialDismissCallBack();
      setParaHelperVisible(false);
    }
  }, [props.open]);

  useEffect(() => {
    if (isOpen === false) {
      props.onDetialDismissCallBack();
      setParaHelperVisible(false);
    }
  }, [isOpen]);

  //------------
  // Handlers
  //-------------

  const onSaveUpdate = () => {
    //Save
    log.debug(limaLogTag.LimaEditItemBaseV4, "onSaveUpdate", open);
    log.info(limaLogTag.LimaEditItemBaseV4, "onSaveUpdate", open);
    window.console.log("ON SAVE UPDATE");
    props.onDetialDismissCallBack();
    if (props.onSaveUpdateCallBack !== undefined) {
      window.console.log("ON SAVE UPDATE - SAVINF");
      props.onSaveUpdateCallBack();
    }
    dismissPanel();
  };

  const onRenderFooterContent = React.useCallback(() => {
    if (paraHelperVisible === true || props.hideFooter === true) return <></>;
    else {
      return (
        <Stack horizontal>
          <Stack.Item>
            <PrimaryButton onClick={onSaveUpdate} styles={buttonStyles} disabled={!props.editDetailEnableStore}>
              {props.saveLbl !== undefined ? props.saveLbl : "Save"}
            </PrimaryButton>
          </Stack.Item>
          <Stack.Item>
            <DefaultButton onClick={dismissPanel}>
              {props.cancelLbl !== undefined ? props.cancelLbl : "Cancel"}
            </DefaultButton>
          </Stack.Item>
        </Stack>
      );
    }
  }, [dismissPanel, props.editDetailEnableStore, props.saveLbl, props.cancelLbl, paraHelperVisible]);

  const SuccessMessage = observer(() => {
    if (limaLoadeStore.isFinishSuccess === true) {
      return (
        <MessageBar messageBarType={MessageBarType.success} isMultiline={false}>
          Working copy saved success.
        </MessageBar>
      );
    } else return <></>;
  });

  const ProgressBar = observer(() => {
    // return <ProgressIndicator />;
    return limaLoadeStore.isLoading ? <ProgressIndicator /> : <></>;
  });

  const onRenderCustomNavi = () => {
    return (
      <Stack>
        <Stack.Item></Stack.Item>
        <Stack horizontal className={classNames.panelHeaderWrapper}>
          <Stack.Item></Stack.Item>
          <Stack.Item className={classNames.panelHeaderTitle}>{props.panelName}</Stack.Item>
          <Stack.Item
            onClick={() => {
              setParaHelperVisible(!paraHelperVisible);
              //limaLoadeStore.setOnFinishSuccess(true);
            }}
            className={classNames.panelHeaderActionIcon}
          >
            {paraHelperVisible === true ? (
              <TooltipHost
                content="Go back to Edit item panel"
                id={paraHelperId}
                calloutProps={calloutProps}
                styles={hostStyles}
                setAriaDescribedBy={false}
              >
                <LimaIconEditPanelData size={24} />
              </TooltipHost>
            ) : (
              <TooltipHost
                content="Show Pragraph helper"
                id={editDetailId}
                calloutProps={calloutProps}
                styles={hostStyles}
                setAriaDescribedBy={false}
              >
                <LimaIconEditPanelHistory size={24} />
              </TooltipHost>
            )}
          </Stack.Item>
          <Stack.Item onClick={dismissPanel} className={classNames.panelHeaderActionIcon}>
            <TooltipHost
              content="Close panel"
              id={dismissId}
              calloutProps={calloutProps}
              styles={hostStyles}
              setAriaDescribedBy={false}
            >
              <LimaIconDismiss size={18} />
            </TooltipHost>
          </Stack.Item>
        </Stack>
        <Stack.Item className={classNames.panelHeaderMessage}>
          <SuccessMessage />
        </Stack.Item>
        <Stack.Item className={classNames.panelHeaderProgress}>
          <ProgressBar />
        </Stack.Item>
      </Stack>
    );
  };

  return (
    <div>
      {/* <DefaultButton text="Open panel" onClick={openPanel} /> */}
      <Panel
        isOpen={isOpen}
        onDismiss={dismissPanel}
        //headerText={panelName ? panelName : "Edit"}
        closeButtonAriaLabel="Close"
        onRenderFooterContent={onRenderFooterContent}
        className={classNames.panelBase}
        type={PanelType.medium}
        styles={{ content: { paddingLeft: 0, paddingRight: 0 }, footer: { background: "#FFFFFF" } }}
        // Stretch panel content to fill the available height so the footer is positioned
        // at the bottom of the page
        isFooterAtBottom={true}
        onRenderNavigation={onRenderCustomNavi}
        // onRenderHeader={onRenderCustomHeader}
      >
        {paraHelperVisible ? <HistParaItemPage /> : props.detail}
      </Panel>
    </div>
  );
};
