import { CALLUSERLINK } from "../taskpane/uiv2/Users/UserInitations/components/User2UserLinksComponentV4";
import { LIMA_ITEM_TYPE } from "./BASE_Enums";

export interface Visibilitor {
  userid: string;
  itemid: string;
  foruser: string;
  itemtype: LIMA_ITEM_TYPE;
  create: boolean; //Create true or Delete false
  state: CALLUSERLINK;
}

export interface VisibilitorUser extends Visibilitor {
  invitemessage: string;
}

export enum VISIBILITOR_ACCESS_TYPE {
  CAN_VIEW = "canview",
  CAN_EDIT = "canedit",
  OWNER = "owner",
  NONE = "none",
  COMPANY_LINK = "link",
  _REMOVE = "_remove",
}
