import * as React from 'react';
import { FunctionComponent } from 'react';

export interface HeaderProps {
    title: string;
    logo: string;
}


const Header: FunctionComponent<HeaderProps> = ({ title, logo }: HeaderProps) => {

    return (
        <section className='ms-welcome__header__backgraund ms-u-fadeIn500'>
            <img width='145' height='32' src={logo} alt={title} title={title} />
        </section>
    );

}

export default Header;
