import { log, tag } from "missionlog";
import { action, makeObservable, observable } from "mobx";
import { urlGetActSigns, urlGetAdviceJudgeAllPara2, urlGetAdviceJudgeSinglePara2 } from "../api/apilinks";
import { httpGet, httpPost, HttpResponse } from "../api/httpAPI";
import {
  callJudiListMulti,
  judgeActParaItemMultiOK,
  judgeActParaItemOK,
  judgeActParaItemOKDef,
} from "../api/schema/getJudgeActParaResp";
import { advice_textsearch, advice_textsearchDEF, WordTextActs } from "../types/advice";
import limaLoadeStore from "./limaLoaderStore";
import limaTagsStore from "./limaTagsStore";

log.init({ LimaJudgeStore: "LimaJudgeStore" });

class LimaJudgeStore {
  isInlineSearchActive: boolean = false;
  actId: string = "";
  parId: string = "";

  list: judgeActParaItemOK[] = [judgeActParaItemOKDef];
  fullList: judgeActParaItemOK[] = [judgeActParaItemOKDef];
  loadingData: boolean = false;
  totalItemCount: number = 0;
  actSignsList: string[] = [];
  actSignListString: string = "";
  searchActPar: advice_textsearch[] = [advice_textsearchDEF];

  wordTextStrings: WordTextActs[] = [];

  constructor() {
    makeObservable(this, {
      actId: observable,
      parId: observable,
      isInlineSearchActive: observable,
      list: observable,
      loadingData: observable,
      totalItemCount: observable,
      actSignsList: observable,
      wordTextStrings: observable,

      //setActVal: action,
      setLoadingData: action,
      getActaParaFromDB: action,
      setInlinSearch: action,
      setList: action,
      onFilterChanged: action,
      setTotalItemCount: action,
      getActSigns: action,
      setSearchActPar: action,
    });
  }

  /**
   * geting actapara fromDB
   *
   * @returns none
   */
  async getActaParaFromDB() {
    if (this.searchActPar.length <= 0) {
      log.warn(tag.LimaJudgeStore, `getActaParaFromDB: no searchActPar`);
      return;
    }
    this.setLoadingData(true);
    let listData: HttpResponse<judgeActParaItemOK[]>;
    const loaderID = limaLoadeStore.add("Get acta par from DB");
    try {
      this.setTotalItemCount(0);
      log.info(tag.LimaJudgeStore, `getActaParaFromDB: searchActPar`, this.searchActPar);
      listData = await httpGet<judgeActParaItemOK[]>(
        urlGetAdviceJudgeSinglePara2(this.searchActPar[0].actID, this.searchActPar[0].parID),
        loaderID.ac
      );
      log.info(tag.LimaJudgeStore, "getActaParaFromDB: asketo to get something");
      if (listData.parsedBody !== undefined) {
        log.info(tag.LimaJudgeStore, "getActaParaFromDB: parsedBody is not null");
        this.setList(listData.parsedBody);
        this.setFullList(listData.parsedBody);
        this.setTotalItemCount(listData.parsedBody.length);
        log.info(tag.LimaJudgeStore, "getActaParaFromDB: load finish", listData);
      }
      this.setLoadingData(false);
    } catch (response) {
      log.error(tag.LimaJudgeStore, "getActaParaFromDB Error call get User", response);
      this.setLoadingData(false);
    } finally {
      limaLoadeStore.remove(loaderID.k);
    }
  }

  /**
   * Get from AI api list of all judicates including for more acta/par at once
   *
   * @returns nothing
   */
  async getActaParaFromDB2() {
    if (this.searchActPar.length <= 0) {
      log.warn(tag.LimaJudgeStore, `getActaParaFromDB2: no searchActPar`);
      return;
    }

    //setup call item
    const callParams: callJudiListMulti = { count: 20, sentanceDetail: false, judiIds: this.searchActPar };

    log.info(tag.LimaJudgeStore, `getActaParaFromDB2:call params`, callParams);

    this.setLoadingData(true);
    let listData: HttpResponse<judgeActParaItemMultiOK[]>;
    const loaderID = limaLoadeStore.add("get Acta para from DB");
    try {
      this.setTotalItemCount(0);
      log.info(tag.LimaJudgeStore, `getActaParaFromDB2: searchActPar`, this.searchActPar);
      listData = await httpPost(urlGetAdviceJudgeAllPara2(), callParams, loaderID.ac);
      log.info(tag.LimaJudgeStore, "getActaParaFromDB2: asketo to get something");
      if (listData.parsedBody !== undefined) {
        log.info(tag.LimaJudgeStore, "getActaParaFromDB2: parsedBody is not null");
        const regexSearchList: advice_textsearch[] = [];
        //let regexList: string[] = [];
        const regexListUniq: advice_textsearch[] = [];

        const finalList: judgeActParaItemOK[] = [];
        log.info(tag.LimaJudgeStore, "getActaParaFromDB2: parsedBody is not null", listData.parsedBody);
        listData.parsedBody.map((item: judgeActParaItemMultiOK) => {
          item.judi.map((judi: judgeActParaItemOK) => {
            log.info(tag.LimaJudgeStore, "getActaParaFromDB2: processing judi ", judi);
            const judiLinksUniq: advice_textsearch[] = [];
            judi.judiLinks.forEach((itemJudiLinks: advice_textsearch) => {
              log.info(tag.LimaJudgeStore, "getActaParaFromDB2: processing judi judiLinks ", itemJudiLinks);
              //Prepare regegex search
              regexSearchList.findIndex(
                (arrayItem: advice_textsearch) =>
                  arrayItem.actID === itemJudiLinks.actID && arrayItem.parID === itemJudiLinks.parID
              ) === -1 &&
                regexSearchList.push(itemJudiLinks) &&
                //&& regexList.push("§?xx?*{1,30}?yy?".replace("?xx?", itemJudiLinks.parID).replace("?yy?", itemJudiLinks.actID));
                //&& regexList.push("?xx?*{1;30}?yy?".replace("?xx?", itemJudiLinks.parID).replace("?yy?", itemJudiLinks.actID));
                //regexList.push(limaTagsStore.judgeItemIdent(itemJudiLinks));
                regexListUniq.push(itemJudiLinks);

              //unique judi links
              judiLinksUniq.findIndex(
                (curentJudiLinksItem: advice_textsearch) =>
                  curentJudiLinksItem.actID === itemJudiLinks.actID && curentJudiLinksItem.parID === itemJudiLinks.parID
              ) === -1 && judiLinksUniq.push(itemJudiLinks);
            });
            //judiLinksUniq.forEach((item:advice_textsearch)=> item.parAlternatives.push("o. s. ř.")) //TODO change from server
            judi.judiLinks = judiLinksUniq;
            finalList.push(judi);
          });
        });
        //console.log("Final list",finalList)
        this.setList(finalList);
        this.setFullList(finalList);
        this.setTotalItemCount(finalList.length);

        //TODO: remove all linking
        limaTagsStore.clearHighLightedTagsR(); //TODO: place better in future

        limaTagsStore.colorizeHighLightedR(regexListUniq);
        //test
        limaTagsStore.dumpHighLigtedR();
        log.info(tag.LimaJudgeStore, "getActaParaFromDB2: load finish", listData);
      } else {
        this.clearLists();
      }
      this.setLoadingData(false);
    } catch (response) {
      log.error(tag.LimaJudgeStore, "getActaParaFromDB2: Error call get User", response);
      this.clearLists();
      this.setLoadingData(false);
    } finally {
      limaLoadeStore.remove(loaderID.k);
    }
  }

  setLoadingData(trueOrfalse: boolean) {
    this.loadingData = trueOrfalse;
  }

  setInlinSearch(val: boolean) {
    this.isInlineSearchActive = val;
  }

  setList(newList: judgeActParaItemOK[]) {
    //log.info(tag.LimaJudgeStore, "setList: setting  finish", newList)
    this.list = newList;
  }

  setTotalItemCount(newCount: number) {
    this.totalItemCount = newCount;
  }

  setFullList(newList: judgeActParaItemOK[]) {
    this.fullList = newList;
  }

  clearLists() {
    this.setFullList([]);
    this.setTotalItemCount(0);
    this.setList([]);
  }

  onFilterChanged = (_: any, text: string): void => {
    log.info(tag.LimaJudgeStore, "onFilterChanged: filter change", text.toLowerCase());

    this.setList(
      this.fullList.filter(function (item: judgeActParaItemOK) {
        if (item.sentences[0] === undefined || item.sentences[0] == null || item.sentences[0] == "") return false;
        else if (
          item.sentences.every(function (singleSentace) {
            return singleSentace.toLowerCase().indexOf(text.toLowerCase()) >= 0;
          })
        )
          //TODO: asi nefunguje spravne
          return true;
        return false;
      })
    );
  };

  setActSignsList(items: string[]) {
    this.actSignsList = items;
  }

  async getActSigns() {
    //TODO: cover error or no response

    this.setLoadingData(true);
    let listData: HttpResponse<any>; //TODO: convert to type
    const loaderID = limaLoadeStore.add("get Acts Signs");
    try {
      listData = await httpGet(urlGetActSigns(), loaderID.ac);
      log.info(tag.LimaJudgeStore, "getActSigns: asketo to get something");
      if (listData.parsedBody !== undefined) {
        log.info(tag.LimaJudgeStore, "getActSigns: parsedBody is not null");
        this.setActSignsList(listData.parsedBody.signs);
        limaTagsStore.signAlts = listData.parsedBody.signsStructMain;
        this.actSignListString = "";
        listData.parsedBody.signs.forEach((item) => (this.actSignListString += "|" + item));
        this.actSignListString = this.actSignListString.substring(1);

        log.info(tag.LimaJudgeStore, "getActSigns: load finish", listData);
      }
      this.setLoadingData(false);
    } catch (response) {
      log.error(tag.LimaJudgeStore, "getActSigns Error call get User", response);
      this.setLoadingData(false);
    } finally {
      limaLoadeStore.remove(loaderID.k);
    }
  }

  setSearchActPar(items: advice_textsearch[]) {
    this.searchActPar = items;
  }

  addSearchActPat(item: advice_textsearch) {
    this.searchActPar.findIndex(
      (arrayItem: advice_textsearch) => arrayItem.actID === item.actID && arrayItem.parID === item.parID
    ) === -1 && this.searchActPar.push(item);
    //this.searchActPar.push(item)
  }

  clearSearchActPar() {
    this.searchActPar = [];
  }

  setWordTextStrings(searchedString: WordTextActs[]) {
    this.wordTextStrings = searchedString;
  }
  /**
   * Cleanup on logout button
   *
   * @autor MS
   */
  onLogoutClear() {
    this.isInlineSearchActive = false;
    this.actId = "";
    this.parId = "";

    this.list = [judgeActParaItemOKDef];
    this.fullList = [judgeActParaItemOKDef];
    this.loadingData = false;
    this.totalItemCount = 0;
    this.actSignsList = [];
    this.actSignListString = "";
    this.searchActPar = [advice_textsearchDEF];

    this.wordTextStrings = [];
  }
}

const limaJudgeStore = new LimaJudgeStore();
export default limaJudgeStore;
