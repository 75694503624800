/**
 * Sanitize sending data
 *  - remove null
 *
 * @param item any type
 * @returns JSON object to send
 * @author MS
 */
export const sanitizeJSON = (item: any): JSON => {
  let objectToSendText = JSON.stringify(item);
  return JSON.parse(objectToSendText, (_key, value) => {
    if (value !== null) return value;
  });
};
