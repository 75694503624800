import { log } from "missionlog";
import { mergeStyleSets, Stack } from "office-ui-fabric-react";
import { FunctionComponent, useEffect, useState } from "react";
import React = require("react");
import { useMutation, useQuery } from "react-query";
import { urlGetMessages, urlStoreMessages } from "../../../api/apilinks";
import { httpGetAuth, httpPostAuth, HttpResponse } from "../../../api/httpAPI";
import { limaLogTag } from "../../../limaCommon/limaLog";
import { LimaHideableSection } from "../../../limaComponents/LimaHideableSection";
import { LimaLoadingComponnet } from "../../../limaComponents/LimaLoadingComponnet";
import { LimaNotice, LIMA_TEXTNOTICETYPE } from "../../../limaComponents/LimaNotice";
import limaLoadeStore from "../../../store/limaLoaderStore";
// import limaStore from "../../../store/limaStore";
import { LIMA_ITEM_TYPE } from "../../../types/BASE_Enums";
import { LimaMessage } from "../../../types/Message";
import MessageInput from "./components/MessageInput";
import MessageItem from "./components/MessageItem";
import { useTranslation } from "react-i18next";

interface MessageComponentProps {
  linkedId: string;
  hidable?: boolean;
  linkedType: LIMA_ITEM_TYPE;
  showComponent?: boolean;
  onAddMessageClb?: () => void;
  onMsgCountClb?: (count: number) => void;
}

const classNames = mergeStyleSets({
  messageList: {
    maxHeight: 300,
    overflow: "auto",
  },
});

const MessageComponent: FunctionComponent<MessageComponentProps> = ({
  linkedId,
  linkedType,
  hidable,
  showComponent,
  onAddMessageClb,
  onMsgCountClb,
}: MessageComponentProps) => {
  const [messages, setMessages] = useState<LimaMessage[]>([]);
  const { t } = useTranslation();

  const { isLoading, isRefetching, error, refetch } = useQuery(
    ["messages", linkedId],
    () => fetchMessages(linkedId, linkedType),
    {
      enabled: false,
      onSuccess: (data) => {
        log.info(limaLogTag.MessageComponent, "useQuery get message ", data);
        setMessages(data);
      },
    }
  );

  const { mutate } = useMutation(
    async (message: LimaMessage) => {
      const loaderID = limaLoadeStore.add("post message");
      try {
        const response: HttpResponse<LimaMessage> = await httpPostAuth<LimaMessage>(
          urlStoreMessages(),
          message,
          loaderID.ac
        );
        if (!response.ok) {
          log.error(limaLogTag.MessageComponent, "useMutation post message ", response);
          throw response;
        }
        log.info(limaLogTag.MessageComponent, "fetching post message response.parsedBody", response.parsedBody);

        const newMessage = response.parsedBody;
        log.info(limaLogTag.MessageComponent, "fetching post message ", newMessage);

        return newMessage;
      } finally {
        limaLoadeStore.remove(loaderID.k);
      }
    },
    {
      onSuccess: (data) => {
        setMessages([...messages, data]);

        window.console.log("Message>> useMutation onSuccess", data, messages);
      },
    }
  );

  //---------------------
  // Reactions
  //---------------------

  useEffect(() => {
    log.info(limaLogTag.MessageComponent, "mouted for linked item: ", linkedId);

    if (linkedId !== undefined && linkedId !== null && linkedId !== "" && linkedId !== ":taskid") {
      setMessages([]);
      void refetch();
    } else {
      log.info(limaLogTag.MessageComponent, "moutedbut missing linkedID ");
    }
  }, [linkedId]);

  useEffect(() => {
    if (onMsgCountClb !== undefined) onMsgCountClb(messages.length);
    if (onAddMessageClb !== undefined) onAddMessageClb();
  }, [messages]);

  if (linkedId === undefined || linkedId === null || linkedId === "") {
    return <>Not linked</>;
  }

  if (isLoading || isRefetching) {
    return LimaLoadingComponnet(3);
  }

  if (error) {
    log.error(limaLogTag.FileInfoBasicStat, "stat loaded error: ", error);
    return <>Error Getting data </>;
  }

  const Content = (): JSX.Element => {
    return (
      <Stack>
        <Stack.Item>
          <LimaNotice noticeType={LIMA_TEXTNOTICETYPE.INFO}>
            {t("tptasks:taskedit.taskitemV4.messages.notifyimmediatechangeLbl")}
          </LimaNotice>
        </Stack.Item>
        <Stack.Item className={classNames.messageList}>
          {messages &&
            messages.map((item: LimaMessage) => <MessageItem key={"me_" + String(item._key)} message={item} />)}
        </Stack.Item>
        <Stack.Item>
          <MessageInput
            linkedId={linkedId}
            callBack={(item: LimaMessage) =>
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              mutate(item)
            }
          />
        </Stack.Item>
      </Stack>
    );
  };

  return (
    <>
      {(showComponent === undefined || showComponent == true) && (
        <>
          {hidable === undefined || hidable == true ? (
            <LimaHideableSection name={"Messages " + (messages ? String(messages.length) : "0")}>
              <Content />
            </LimaHideableSection>
          ) : (
            <Content />
          )}
        </>
      )}
    </>
  );
};

export default MessageComponent;

//----------------------
//   REACT QUERY
//----------------------

const fetchMessages = async (linkedId: string, linkedType: LIMA_ITEM_TYPE): Promise<LimaMessage[]> => {
  log.info(limaLogTag.MessageComponent, "fetching data fetchMessages");
  const loaderID = limaLoadeStore.add("Getting messages from DB");
  try {
    const response: HttpResponse<LimaMessage[]> = await httpGetAuth(urlGetMessages(linkedId, linkedType), loaderID.ac);
    log.info(limaLogTag.MessageComponent, "fetching data fetchMessages response", response);
    if (!response.ok) {
      throw response;
    }
    if (response.parsedBody !== undefined) {
      if (Array.isArray(response.parsedBody))
        log.info(limaLogTag.MessageComponent, "fetching data fetchMessages response.parsedBody", response.parsedBody);
      return response.parsedBody;
    }
    throw new Error("no data");
  } catch (error) {
    throw new Error(error);
  } finally {
    limaLoadeStore.remove(loaderID.k);
  }
};
