import { Stack } from "@fluentui/react";
import { log } from "missionlog";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { LimaIconProps } from "../../../../assets/icons/DefLimaIconType";
import { LimaIconCompareActa } from "../../../../assets/icons/LimaIconCompareActa";
import { LimaIconOpenActa } from "../../../../assets/icons/LimaIconOpenActa";
import { LimaIconOpenCopy } from "../../../../assets/icons/LimaIconOpenCopy";
import { LimaIconSaveActa } from "../../../../assets/icons/LimaIconSaveActa";
import { LimaIconSaveCopy } from "../../../../assets/icons/LimaIconSaveCopy";
import { onFileOpenHandlerWrapper } from "../../../../limaCommon/limaFileOpen";
import { compareXcopy2XcopyByActaKey } from "../../../../limaCommon/limaInsertDoc";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import { getFileAsyncInternal } from "../../../../limaCommon/limaSaveDoc";
import limaActasStore from "../../../../store/limaActasStore";
import limaBreadCrumbStore from "../../../../store/limaBreadCrumbStore";
import limaLogsStore, { ENUM_LogstoreLogType } from "../../../../store/limaLogsStore";
import limaLoadeStore from "../../../../store/limaLoaderStore";
import limaStore from "../../../../store/limaStore";
import { LimaDocTypeENUM } from "../../../../types/docxData";
import { XCOPY_TYPE } from "../../../../types/Task";
import { xcopyHistory_null } from "../../../../types/XcopyHistory";
import Routes from "../../../Menu/routes";
import LimactaDetailActionItemV4 from "./LimactaDetailActionItemV4";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ILimaLinkignItemActionsV4Props {
  linkedXcopyKey: string;
  linkedXcopyType: XCOPY_TYPE;
  linkedActaname: string;
  linkedActaKey: string;
}

export const LimaLinkignItemActionsV4: React.FunctionComponent<ILimaLinkignItemActionsV4Props> = observer((props) => {
  const { t } = useTranslation();
  const history = useHistory();

  //--------------------
  // Reactions
  //--------------------

  //----------------
  // Handlers
  //----------------
  const onXcopyOpen = () => {
    log.info(limaLogTag.LimaLinkignItemActionsV4, "onXcopyOpen: on click ");
    // console.log("HEEERREEE2 ", xCopy2ActaItem, XCOPY_TYPE.TRIMXCOPY === xCopy2ActaItem.xcopy.xcopytype);

    onFileOpenHandlerWrapper(
      props.linkedActaKey,
      props.linkedActaname, //name
      props.linkedXcopyKey,
      null,
      null,
      props.linkedXcopyType
    )
      .then(() => {
        log.info(
          limaLogTag.FileItemV4,
          "onCopyFileClickHandler: ",
          props.linkedActaKey,
          props.linkedActaname, //name
          props.linkedXcopyKey,
          null,
          null,
          props.linkedXcopyType
        );
        if (limaStore.isComparedResultOpen !== false) limaStore.setIsComparedResultOpen(false);
      })
      .catch((e) => {
        log.info(
          limaLogTag.FileItemV4,
          "onCopyFileClickHandler: Error",
          e,
          props.linkedActaKey,
          props.linkedActaname, //name
          props.linkedXcopyKey,
          null,
          null,
          props.linkedXcopyType
        );
      });

    limaBreadCrumbStore.addNewBreadCrumbExt({
      key: `copy_${props.linkedXcopyKey}`,
      lima_itemid: props.linkedXcopyKey,
      text: `copy ${props.linkedActaname}`,
      // data: `${Routes.ActaDetailRoute_base}${props.linkedXcopyKey}/${OPENEDFILETYPE_ENUM.COPY}`,
      data: `${Routes.ActaDetailRoute_base}${props.linkedXcopyKey}/${props.linkedXcopyType}`,
      lima_Actions: [],
    });
  };

  const onMainCopyOpen = () => {
    log.info(limaLogTag.LimaLinkignItemActionsV4, "onXcopyOpen: on click ");
    void onFileOpenHandlerWrapper(
      props.linkedActaKey,
      props.linkedActaname,
      props.linkedXcopyType === XCOPY_TYPE.MAINXCOPY
        ? props.linkedXcopyKey
        : limaActasStore.getActa(props.linkedActaKey).mainXcopy,
      null,
      null,
      XCOPY_TYPE.MAINXCOPY,
      false,
      true
    ).then(() => {
      if (limaStore.isComparedResultOpen !== false) limaStore.setIsComparedResultOpen(false);
    });
  };

  const onXcopyCompareIntoMain = () => {
    // eslint-disable-next-line react/prop-types
    log.info(limaLogTag.LimaLinkignItemActionsV4, "OpenFileComponent: on click ");
    const loaderID = limaLoadeStore.add(`Call to compare acta and xcopy`);
    compareXcopy2XcopyByActaKey(props.linkedActaKey, props.linkedXcopyKey, limaStore.userId, loaderID)
      .then(() => {
        if (limaStore.isComparedResultOpen !== true) limaStore.setIsComparedResultOpen(true);
      })
      .finally(() => {
        limaLoadeStore.remove(loaderID.k);
      });
  };

  const onLinkedMainCopyStore = () => {
    // eslint-disable-next-line react/prop-types
    log.info(limaLogTag.LimaLinkignItemActionsV4, "onLinkedMainCopyStore: on click ");
    history.push(Routes.FileSaveNewVersionRoute);
  };

  const onLinkedCopyStore = async () => {
    log.info(limaLogTag.LimaLinkignItemActionsV4, "onLinkedCopyStore: on click ");
    log.info(limaLogTag.LimaLinkignItemActionsV4, "Saving existing file");

    const loaderID = limaLoadeStore.add(`Storing xcopy to server`);

    await getFileAsyncInternal(
      limaStore.actaId,
      props.linkedXcopyKey,
      LimaDocTypeENUM.XCOPY,
      props.linkedXcopyKey,
      LimaDocTypeENUM.XCOPY,
      "",
      limaStore.userId,
      xcopyHistory_null(), //version
      () => {
        limaLoadeStore.remove(loaderID.k);
        limaLoadeStore.setOnFinishSuccess(true);
      },
      () => {
        limaLoadeStore.remove(loaderID.k);
        limaLogsStore.add(
          "error storing linkign item",
          "Some error on storing linking item",
          ENUM_LogstoreLogType.ERROR,
          limaLogTag.LimaLinkignItemActionsV4
        );
        log.error(limaLogTag.LimaLinkignItemActionsV4, "onStoreToXcopy: error on store");
      },
      limaStore.xcopyId
      //onBehalf
    );
  };
  // linkedXcopyKey: string;
  // linkedXcopyType: XCOPY_TYPE;
  // linkedActaname: string;
  // linkedActaKey: string;
  const isDisableOpenCopy = useMemo(() => {
    if (props.linkedActaKey === null || props.linkedActaKey === "") return true;
    if (props.linkedXcopyType === XCOPY_TYPE.TRIMXCOPY || props.linkedXcopyType === XCOPY_TYPE.FULLXCOPY) return false;
    return true;
  }, [props.linkedXcopyType]);

  const isDisableOpenMainCopy = useMemo(() => {
    if (props.linkedActaKey === null || props.linkedActaKey === "") return true;
    log.info(limaLogTag.LimaLinkignItemActionsV4, "isDisableOpenMainCopy: check owner");
    if (limaActasStore.isOwnerActaId(props.linkedActaKey) === false) return true;
    // log.info(limaLogTag.LimaLinkignItemActionsV4, "isDisableOpenMainCopy: check type");
    // if (
    //   props.linkedXcopyType === XCOPY_TYPE.TRIMXCOPY ||
    //   props.linkedXcopyType === XCOPY_TYPE.FULLXCOPY ||
    //   props.linkedXcopyType === XCOPY_TYPE.MAINXCOPY
    // )
    //   return true;
    else return false;
  }, [props.linkedActaKey]);

  const isDisableCompareMainCopy = useMemo(() => {
    if (props.linkedActaKey === null || props.linkedActaKey === "") return true;
    log.info(limaLogTag.LimaLinkignItemActionsV4, "isDisableCompareMainCopy: check owner");
    if (limaActasStore.isOwnerActaId(props.linkedActaKey) === false) return true;
    if (props.linkedXcopyType === XCOPY_TYPE.MAINXCOPY) return true;
    return false;
  }, [props.linkedXcopyType, props.linkedActaKey]);

  return (
    <>
      <>
        {limaStore.showTestingInfo &&
          `TESTING ACTIONS TASKSDATA: XCOPYKEY: ${props.linkedXcopyKey} XCOPYTYPE: ${props.linkedXcopyType} ACTANAME: ${props.linkedActaname} ACTAKEY: ${props.linkedActaKey}`}
      </>
      {/* <CurrentLimaStoreData /> */}
      <Stack horizontal wrap={true}>
        <Stack.Item>
          <LimactaDetailActionItemV4
            toolName={t("tptasks:taskedit.taskitemV4.openLinkedCopyBtnLbl")}
            toolItemWidth={60}
            minWidth={80}
            disabled={() => isDisableOpenCopy}
            // notYetImplemented
            onClickClb={onXcopyOpen}
          >
            {/* <LimaIconOpenCopy title={t("tptasks:taskedit.taskitemV4.openLinkedCopyBtnTitle")} size={40} /> */}
            {(childProps: LimaIconProps): JSX.Element => {
              return (
                <LimaIconOpenCopy
                  title={t("tptasks:taskedit.taskitemV4.openLinkedCopyBtnTitle")}
                  {...childProps}
                  size={40}
                />
              );
            }}
          </LimactaDetailActionItemV4>
        </Stack.Item>
        <Stack.Item>
          <LimactaDetailActionItemV4
            toolName={t("tptasks:taskedit.taskitemV4.openLinkedMainXcopyBtnLbl")}
            toolItemWidth={60}
            minWidth={80}
            disabled={() => isDisableOpenMainCopy} //TESK IS OWNER
            // notYetImplemented
            onClickClb={onMainCopyOpen}
          >
            {/* <LimaIconOpenActa title={t("tptasks:taskedit.taskitemV4.openLinkedMainXcopyBtnTitle")} size={40} /> */}
            {(childProps: LimaIconProps): JSX.Element => {
              return (
                <LimaIconOpenActa
                  title={t("tptasks:taskedit.taskitemV4.openLinkedMainXcopyBtnTitle")}
                  {...childProps}
                  size={40}
                />
              );
            }}
          </LimactaDetailActionItemV4>
        </Stack.Item>

        <Stack.Item>
          <LimactaDetailActionItemV4
            toolName={t("tptasks:taskedit.taskitemV4.saveToXcopyBtnLbl")}
            toolItemWidth={60}
            minWidth={80}
            onClickClb={onLinkedCopyStore}
            disabled={() => {
              log.info(
                limaLogTag.LimaLinkignItemActionsV4,
                "disabledTO save Copy: storxcopy / taskxcopy",
                limaStore.xcopyId,
                props.linkedXcopyKey,
                !(limaStore.xcopyId === props.linkedXcopyKey)
              );
              return !(limaStore.xcopyId === props.linkedXcopyKey && limaStore.xcopyType !== XCOPY_TYPE.MAINXCOPY);
            }}
          >
            {/* <LimaIconSaveCopy title={t("tptasks:taskedit.taskitemV4.compareToMainXcopyBtnTitle")} size={40} /> */}
            {(childProps: LimaIconProps): JSX.Element => {
              return (
                <LimaIconSaveCopy
                  title={t("tptasks:taskedit.taskitemV4.saveToXcopyBtnTitle")}
                  {...childProps}
                  size={40}
                />
              );
            }}
          </LimactaDetailActionItemV4>
        </Stack.Item>
        <Stack.Item>
          <LimactaDetailActionItemV4
            toolName={t("tptasks:taskedit.taskitemV4.saveToMainCopyBtnLbl")}
            toolItemWidth={60}
            minWidth={80}
            onClickClb={onLinkedMainCopyStore}
            disabled={() => {
              log.info(
                limaLogTag.LimaLinkignItemActionsV4,
                "disabledTO save ACTA: actaId / linkedActaKey / limastor xckopyType - result, isOwner",
                limaStore.actaId,
                props.linkedActaKey,
                limaStore.xcopyType === XCOPY_TYPE.MAINXCOPY,
                limaStore.IamDocumentOwner,
                !(
                  limaStore.actaId === props.linkedActaKey &&
                  limaStore.xcopyType === XCOPY_TYPE.MAINXCOPY &&
                  limaStore.IamDocumentOwner
                )
              );
              return !(
                limaStore.actaId === props.linkedActaKey &&
                limaStore.xcopyType === XCOPY_TYPE.MAINXCOPY &&
                limaStore.IamDocumentOwner
              );
            }}
          >
            {(childProps: LimaIconProps): JSX.Element => {
              return (
                <LimaIconSaveActa
                  title={t("tptasks:taskedit.taskitemV4.saveToMainCopyBtnTitle")}
                  {...childProps}
                  size={40}
                ></LimaIconSaveActa>
              );
            }}
          </LimactaDetailActionItemV4>
        </Stack.Item>
        <Stack.Item>
          <LimactaDetailActionItemV4
            toolName={t("tptasks:taskedit.taskitemV4.compareToMainXcopyBtnLbl")}
            toolItemWidth={60}
            minWidth={80}
            disabled={() => isDisableCompareMainCopy} //TESK IS OWNER
            // notYetImplemented
            onClickClb={onXcopyCompareIntoMain}
          >
            {/* <LimaIconCompareActa title={t("tptasks:taskedit.taskitemV4.compareToMainXcopyBtnTitle")} size={40} /> */}
            {(childProps: LimaIconProps): JSX.Element => {
              return (
                <LimaIconCompareActa
                  title={t("tptasks:taskedit.taskitemV4.compareToMainXcopyBtnTitle")}
                  {...childProps}
                  size={40}
                />
              );
            }}
          </LimactaDetailActionItemV4>
        </Stack.Item>
      </Stack>
    </>
  );
});
