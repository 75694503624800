import { mergeStyleSets, Spinner, Stack, TextField } from "@fluentui/react";
import { log } from "missionlog";
import { FormEvent, FunctionComponent, useState } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
// import { LimaIconDismiss } from "../../../../../assets/icons/LimaIconDismiss";
import { LimaIconUpload } from "../../../../../assets/icons/LimaIconUpload";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import { IAttachmentFileWithInfo } from "../../../../../types/LimaAttachement";
import LimaActionButtonV4 from "../../../Components/LimaActionButtonV4";
import AttachementItemV4 from "./AttachementItemV4";

interface IAttachmentDDFileItemV4Props {
  file: IAttachmentFileWithInfo;
  removeCallBack: () => void;
  uploadCallBack?: (name: string, descr: string) => void;
  onUpdateNameDesc?: (name: string, descr: string) => void;
  enableUploadSingleFile: boolean;
}

// const removeFileIcon: IIconProps = { iconName: "Cancel" };
// const uploadFileIcon: IIconProps = { iconName: "CloudUpload" };

const classNames = mergeStyleSets({
  filename: {
    color: "#bbbbbb",
  },
  listItemWrapper: {
    alignItems: "flex-start",
  },
});

const AttachmentDDFileItemV4: FunctionComponent<IAttachmentDDFileItemV4Props> = (
  props: IAttachmentDDFileItemV4Props
) => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [desc, setDesc] = useState<string>("");
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { t } = useTranslation();

  //-------------
  // Reaction
  //-------------
  React.useEffect(() => {
    setName(props.file.filename);
    setDesc(props.file.filedescription);
  }, []);

  React.useEffect(() => {
    setName(props.file.filename);
    setDesc(props.file.filedescription);
  }, [props.file]);

  //-------------
  // Handlers
  //------------
  const onRemove = () => {
    log.info(limaLogTag.AttachmentDDFileItemV4, "clicked on ", props.file);
    if (props.removeCallBack !== undefined) props.removeCallBack();
  };

  const onUpload = () => {
    log.info(limaLogTag.AttachmentDDFileItemV4, "clicked on ", props.file);
    setIsUploading(true);
    let nameOut = name;
    if (name === "") {
      nameOut = "file " + props.file.file.name;
    }
    if (props.uploadCallBack !== undefined) props.uploadCallBack(nameOut, desc);
  };

  const onNameChange = (ev: FormEvent<HTMLInputElement>) => {
    setName((ev.target as HTMLInputElement).value);
  };

  const onDesrcChange = (ev: FormEvent<HTMLInputElement>) => {
    setDesc((ev.target as HTMLInputElement).value);
  };

  const onUpdate = () => {
    if (props.onUpdateNameDesc !== undefined) props.onUpdateNameDesc(name, desc);
    if (isEdit === true) setIsEdit(false);
  };

  //---------------
  // Renders
  //---------------

  // const EditStateFile = (): JSX.Element => {
  //   return (

  //   );
  // };

  // const ViewStateFile = (): JSX.Element => {
  //   return (
  //     <Stack horizontal className={classNames.listItemWrapper}>

  //     </Stack>
  //   );
  // };

  return (
    <Stack horizontal className={classNames.listItemWrapper}>
      {isEdit ? (
        <>
          <Stack.Item grow={10}>
            <Stack>
              <Stack.Item>
                <TextField
                  placeholder={"attachement name"}
                  // defaultValue={name}
                  value={name}
                  label={t("tptasks:taskedit.taskitemV4.attachments.addfileNameLbl")}
                  onChange={onNameChange}
                />
              </Stack.Item>
              <Stack.Item>
                <TextField
                  placeholder={"attachement description"}
                  onChange={onDesrcChange}
                  value={desc}
                  multiline={true}
                  height={"2em"}
                />
              </Stack.Item>
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <LimaActionButtonV4 onClick={onUpdate} toolTipShow={false} toolTipContent="">
              Ok
            </LimaActionButtonV4>
          </Stack.Item>
        </>
      ) : (
        <>
          <Stack.Item>
            <AttachementItemV4
              attachement={{
                _id: "",
                _key: "",
                cIssuedBy: "",
                cTimeStamp: "",
                fileuri: "",
                fileType: props.file.file.type,
                filename: props.file.file.name,
                name: name,
                filesize: props.file.file.size.toString(),
                description: desc,
              }}
              enableRemove={!isUploading}
              onRemoveAtt={onRemove}
              enableDownload={false}
            />
          </Stack.Item>
          <Stack.Item>
            {/* {isUploading == false && (
            <LimaActionButtonV4 onClick={onRemove} toolTipShow={false} toolTipContent="">
              <LimaIconDismiss
                title={t("tptasks:taskedit.taskitemV4.attachments.dismissActionTitle")}
                size={18}
                fillColor={"rgb(16, 110, 190)"}
              />
            </LimaActionButtonV4>
          )} */}
            <LimaActionButtonV4 onClick={() => setIsEdit(!isEdit)} toolTipShow={false} toolTipContent="">
              Change
            </LimaActionButtonV4>
            {props.enableUploadSingleFile === true && isUploading === false && props.uploadCallBack !== undefined && (
              <LimaActionButtonV4 onClick={onUpload} toolTipShow={false} toolTipContent="">
                <LimaIconUpload
                  title={t("tptasks:taskedit.taskitemV4.attachments.uploadActionTitle")}
                  size={24}
                  fillColor={"rgb(16, 110, 190)"}
                />
              </LimaActionButtonV4>
            )}
            {isUploading == true && (
              <Stack.Item grow={2}>
                <Spinner label="uploading..." />
              </Stack.Item>
            )}
          </Stack.Item>
        </>
      )}
    </Stack>
  );
};

export default AttachmentDDFileItemV4;
