import { XCOPY_TYPE } from "../../../types/Task";

export interface searchResult {
  timeStamp: string;
  scoreSUM: number;
  scoreCount: number;
  pId: string;
  aId: string;
  xId: string;
  xType: XCOPY_TYPE;
  xIdent: string;
  aName: string;
  text: string;
}

export const searchResDefault: searchResult[] = [
  {
    timeStamp: "",
    scoreSUM: 0,
    scoreCount: 0,
    pId: "",
    aId: null,
    xId: "",
    xType: XCOPY_TYPE.NOXCOPY,
    xIdent: "",
    aName: "",
    text: "",
  },
];

/*
[{"timeStamp":"2021-02-03T18:48:00.000Z",
"scoreSUM":400,
"scoreCount":6,
"aName":"aneta01",
"pId":"dPara/1088860",
"text":" Aneta Nováková přednesla svůj návrh protistraně , která jej a p řidala ale však zamítla <span class='searchWord'> <span class='searchWord'> test<\/span>  <span class='searchWord'> <span class='searchWord'> test<\/span>  <span class='searchWord'> <span class='searchWord'>test<\/span> ",
"aId":"dActa/1088852"},
...]
*/
